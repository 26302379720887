import { useState, useCallback, useMemo, useEffect } from "react";
import { bool, func, object } from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Box, Menu } from "@mui/material";
import { OrderDirectApplicationDialog } from "../OrderDirectApplicationDialog/OrderDirectApplicationDialog";
import { cl } from "./NotificationsMenu.styles";
import { Header, ItemsList, Search } from "./components";
import { defaultValues, TABS } from "./NotificationsMenu.constants";
import { validationSchema } from "./NotificationsMenu.validations";

import {
  invitesCountSelector,
  invitesListSelector,
  invitesloadingSelector,
  receivedCountSelector,
  receivedListSelector,
  notificationsCountSelector,
  notificationReceivedCustomersSelector,
} from "../../redux/selectors/order-directs";
import { DATA_KEYS, SCROLL_LIMIT_INVITES } from "../../utils/constants";
import {
  getInvitesListAction,
  getReceivedListAction,
} from "../../redux/actions/order-directs";
import {
  approveOrderDirectAccessService,
  sendInviteService,
} from "../../services/order-directs";
import { error, success } from "../../utils/notifications";
import { getAppCustomerByIdService } from "../../services/customers";
import { useDebounce, useRepsPermissions } from "helpers/hooks";

const selector = createSelector(
  invitesloadingSelector,
  invitesListSelector,
  invitesCountSelector,
  receivedListSelector,
  receivedCountSelector,
  notificationsCountSelector,
  notificationReceivedCustomersSelector,
  (
    invitesloading,
    invitesList,
    invitesCount,
    receivedList,
    receivedCount,
    notificationsCount,
    notificationReceivedCustomers
  ) => ({
    invitesloading,
    invitesList,
    invitesCount,
    receivedList,
    receivedCount,
    notificationsCount,
    notificationReceivedCustomers,
  })
);

export const NotificationsMenu = ({
  anchorEl,
  open,
  handleClose,
  handleOpenMenu,
  handleUpdateReceivedCount,
}) => {
  const {
    invitesloading,
    invitesList,
    invitesCount,
    receivedList,
    receivedCount,
    notificationsCount,
    notificationReceivedCustomers,
  } = useSelector(selector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const repPermissions = useRepsPermissions();
  const [openDialog, setOpenDialog] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [isLoading, setLoading] = useState("");
  const [limitCountInvites, setLimitCountInvites] = useState(
    2 * SCROLL_LIMIT_INVITES
  );
  const [limitCountReceived, setLimitCountReceived] = useState(
    2 * SCROLL_LIMIT_INVITES
  );

  const handleClickOpenDialog = useCallback(() => setOpenDialog(true), []);
  const handleCloseDialog = useCallback(() => {
    setOpenDialog(false);
    setCurrentOrder(null);
  }, []);

  const { control, setValue } = useForm({
    mode: "onChange",
    defaultValues: { ...defaultValues },
    resolver: yupResolver(validationSchema()),
  });

  const { currentTab, currentSwitch, search } = useWatch({ control });
  const [params] = useSearchParams();
  const paramsId = params.get("app_customer_id");
  const searchInputDebounced = useDebounce(search, 500);

  const getStatus = useMemo(() => {
    if (currentSwitch === "accepted") return "APPROVED";
    if (currentSwitch !== "all") return currentSwitch.toUpperCase();
    return "";
  }, [currentSwitch]);

  const handleClickReview = useCallback(
    (item) => {
      setLoading(item?.appCustomer?.id);
      getAppCustomerByIdService(item?.appCustomer?.id)
        .then((res) => {
          setCurrentOrder({
            ...res,
            appOrderDirect: item,
          });
          handleClickOpenDialog();
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err.message);
          error(err.response?.data?.message || "Something went wrong.");
        })
        .finally(() => setLoading(""));
    },
    [handleClickOpenDialog]
  );

  useEffect(() => {
    const localStorageId = localStorage.getItem(DATA_KEYS.APP_CUSTOMER_ID);
    const appCustomerId = JSON.parse(localStorageId)?.appCustomerId || paramsId;
    if (appCustomerId && receivedList.length) {
      const foundItem = receivedList?.find(
        (item) => appCustomerId === item.appCustomer.id
      );
      handleOpenMenu();
      handleClickReview(foundItem);
      navigate("/");
      localStorage.removeItem(DATA_KEYS.APP_CUSTOMER_ID);
    }
  }, [handleClickReview, handleOpenMenu, receivedList, navigate, paramsId]);

  const handleClickResend = useCallback((order) => {
    setLoading(order?.customer?.id);
    sendInviteService({
      email: order?.email,
      customerId: order?.customer?.id,
    })
      .then((res) => {
        if (res?.status === 200) success("Invitation sent");
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err.message);
        error(err.response?.data?.message || "Something went wrong.");
      })
      .finally(() => setLoading(""));
  }, []);

  const handleClickPrint = useCallback((orderId) => {
    // eslint-disable-next-line no-console
    console.log("Print", orderId);
  }, []);

  const handleClickReject = useCallback(
    (queryData) => {
      setLoading("REJECTED");
      approveOrderDirectAccessService(currentOrder?.id, {
        status: "REJECTED",
        ...queryData,
      })
        .then((res) => {
          if (res?.status === 200) {
            handleUpdateReceivedCount(
              currentOrder?.appOrderDirect?.appCustomer?.id
            );
            success("Order direct request rejected");
            if (!repPermissions)
              dispatch(
                getReceivedListAction({
                  limit: SCROLL_LIMIT_INVITES,
                  status: getStatus,
                })
              );
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err.message);
          error(err.response?.data?.message || "Something went wrong.");
        })
        .finally(() => {
          setLoading("");
          setCurrentOrder(null);
          setOpenDialog(false);
        });
    },
    [
      currentOrder?.appOrderDirect?.appCustomer?.id,
      currentOrder?.id,
      dispatch,
      getStatus,
      handleUpdateReceivedCount,
      repPermissions,
    ]
  );

  const onSubmit = useCallback(
    (data) => {
      const { paymentTermsId, representativeId, territoryId, note } = data;
      setLoading("APPROVED");

      const queryData = {
        status: "APPROVED",
        paymentTermsId,
        representativeId,
        territoryId,
        note,
      };

      approveOrderDirectAccessService(currentOrder?.id, queryData)
        .then((res) => {
          if (res?.status === 200) {
            success("Order direct request approved");
            handleUpdateReceivedCount(
              currentOrder?.appOrderDirect?.appCustomer?.id
            );
            if (!repPermissions)
              dispatch(
                getReceivedListAction({
                  limit: SCROLL_LIMIT_INVITES,
                  status: getStatus,
                })
              );
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err.message);
          error(err.response?.data?.message || "Something went wrong.");
        })
        .finally(() => {
          setLoading("");
          setCurrentOrder(null);
          setOpenDialog(false);
        });
    },
    [
      currentOrder?.appOrderDirect?.appCustomer?.id,
      currentOrder?.id,
      dispatch,
      getStatus,
      handleUpdateReceivedCount,
      repPermissions,
    ]
  );

  const handleSwitchClick = useCallback(
    (params) => {
      if (currentTab === TABS[0])
        if (!repPermissions)
          return dispatch(
            getInvitesListAction({
              ...params,
              search: searchInputDebounced,
            })
          );
      if (currentTab === TABS[1]) {
        if (!repPermissions)
          return dispatch(
            getReceivedListAction({
              ...params,
              search: searchInputDebounced,
            })
          );
      }
    },
    [currentTab, dispatch, repPermissions, searchInputDebounced]
  );

  const handleTabsClick = useCallback(
    (tab) => {
      if (tab === TABS[0])
        if (!repPermissions)
          return dispatch(
            getInvitesListAction({
              limit: limitCountInvites,
              status: getStatus,
              search: searchInputDebounced,
            })
          );
      if (tab === TABS[1])
        if (!repPermissions)
          return dispatch(
            getReceivedListAction({
              limit: limitCountReceived,
              status: getStatus,
              search: searchInputDebounced,
            })
          );
    },
    [
      dispatch,
      getStatus,
      limitCountInvites,
      limitCountReceived,
      repPermissions,
      searchInputDebounced,
    ]
  );

  useEffect(() => {
    if (
      typeof notificationsCount === "number" &&
      typeof limitCountInvites === "number"
    ) {
      if (!repPermissions)
        dispatch(
          getInvitesListAction({
            limit: limitCountInvites,
            status: getStatus,
            search: searchInputDebounced,
          })
        );

      if (!repPermissions)
        dispatch(
          getReceivedListAction({
            limit: limitCountReceived,
            status: getStatus,
            search: searchInputDebounced,
          })
        );
    }
  }, [
    dispatch,
    getStatus,
    limitCountInvites,
    notificationsCount,
    searchInputDebounced,
    repPermissions,
    limitCountReceived,
  ]);

  const handleFetchInvites = useCallback(() => {
    if (!repPermissions)
      dispatch(
        getInvitesListAction({
          limit: limitCountInvites,
          status: getStatus,
          search: searchInputDebounced,
        })
      ),
        setLimitCountInvites(limitCountInvites + SCROLL_LIMIT_INVITES);
  }, [
    dispatch,
    getStatus,
    limitCountInvites,
    searchInputDebounced,
    repPermissions,
  ]);

  const handleFetchReceived = useCallback(() => {
    if (!repPermissions)
      dispatch(
        getReceivedListAction({
          limit: limitCountReceived,
          status: getStatus,
          search: searchInputDebounced,
        })
      ),
        setLimitCountReceived(limitCountReceived + SCROLL_LIMIT_INVITES);
  }, [
    dispatch,
    getStatus,
    limitCountReceived,
    repPermissions,
    searchInputDebounced,
  ]);

  const onFetchList = useCallback(
    (tab) => {
      if (tab === TABS[0]) handleFetchInvites();
      if (tab === TABS[1]) handleFetchReceived();
    },
    [handleFetchInvites, handleFetchReceived]
  );

  return (
    <Menu
      sx={cl.menu}
      id="notifications-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={(e) => handleClose(e)}
      PaperProps={{
        sx: {
          boxShadow: "0px 6px 10px rgba(0,0,0,16%)",
        },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <OrderDirectApplicationDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        item={currentOrder}
        onClickPrint={handleClickPrint}
        onClickReject={handleClickReject}
        onSubmit={onSubmit}
        loading={isLoading}
      />

      <Box sx={cl.wrapper}>
        <Header
          setValue={setValue}
          control={control}
          onTabsClick={handleTabsClick}
        />
        <Search
          control={control}
          handleSwitchClick={handleSwitchClick}
          setValue={setValue}
          hasAnyInvites={!!invitesList.length}
          hasAnyReceived={!!receivedList.length}
        />
        <ItemsList
          invitesList={invitesList}
          receivedList={receivedList}
          control={control}
          handleClickReview={handleClickReview}
          handleClickResend={handleClickResend}
          invitesCount={invitesCount}
          receivedCount={receivedCount}
          onFetchList={onFetchList}
          invitesloading={invitesloading}
          hasAnyInvites={!!invitesList.length}
          hasAnyReceived={!!receivedList.length}
          loading={isLoading}
          handleClose={handleClose}
          notificationReceivedCustomers={notificationReceivedCustomers?.filter(
            (c) => !c?.read
          )}
        />
        {/* <Footer onClickSeeAll={handleClickSeeAll} /> */}
      </Box>
    </Menu>
  );
};

NotificationsMenu.propTypes = {
  anchorEl: object,
  open: bool,
  handleClose: func,
  handleOpenMenu: func,
  handleUpdateReceivedCount: func,
};
NotificationsMenu.defaultProps = {
  open: false,
  handleClose: () => {},
  handleOpenMenu: () => {},
  handleUpdateReceivedCount: () => {},
};
