export const confirmDialogIsOpenSelector = (state) =>
  state.confirmDialogs.isOpen;

export const confirmDialogTitleSelector = (state) => state.confirmDialogs.title;
export const confirmDialogText1Selector = (state) => state.confirmDialogs.text1;
export const confirmDialogUserNameSelector = (state) =>
  state.confirmDialogs.userName;
export const confirmDialogText2Selector = (state) => state.confirmDialogs.text2;

export const confirmDialogIconSelector = (state) => state.confirmDialogs.isIcon;
export const confirmDialogButtonsSelector = (state) =>
  state.confirmDialogs.buttons;
export const confirmDialogPropBtnsSelector = (state) =>
  state.confirmDialogs.propBtns;
export const confirmDialogCenteredSelector = (state) =>
  state.confirmDialogs.centered;

export const confirmDialogFormChangedSelector = (state) =>
  state.confirmDialogs.formChanged;

export const confirmPathSelector = (state) => state.confirmDialogs.path;

export const editItemSelector = (state) => state.confirmDialogs.editItem;
export const editTypeSelector = (state) => state.confirmDialogs.editType;
export const createModeSelector = (state) => state.confirmDialogs.createMode;

export const confirmDialogStylesSelector = (state) =>
  state.confirmDialogs.dialogStyles;

export const confirmDialogButtonsStylesSelector = (state) =>
  state.confirmDialogs.dialogButtonsStyles;

export const confirmDialogIsCancelSelector = (state) =>
  state.confirmDialogs.isCancel;

export const confirmDialogConfirmDataSelector = (state) =>
  state.confirmDialogs.confirmData;
