import { Box, Grid, Typography } from "@mui/material";
import { TrashTaskIcon } from "components/Icons";
import React from "react";
import { bool, func, object, shape, string } from "prop-types";
import { useAdmin } from "helpers/helpers.js";

export const SelectedCustomerItem = ({
  customer,
  deleteCustomer,
  adminIsAllowed,
}) => {
  const isAdmin = useAdmin(adminIsAllowed);
  return (
    <Grid
      key={customer?.id}
      container
      flexWrap="nowrap"
      justifyContent="space-between"
      sx={{
        borderBottom: "1px solid #D5D9D9",
        py: "4.7px",
        pr: "10px",
        alignItems: "center",

        "&:last-child": {
          borderBottom: "none",
        },
      }}
    >
      <Grid width="calc(100% - 35px)">
        <Typography fontSize="13px" color="#1C1C19" noWrap lineHeight="18px">
          {customer?.displayName || customer?.name}
        </Typography>
        <Typography
          lineHeight="18px"
          fontSize="13px"
          color="#1C1C19"
          fontWeight={300}
          noWrap
        >
          {customer?.shippingAddress?.formatted_address ||
            customer?.billingAddress?.formatted_address}
        </Typography>
      </Grid>
      {!isAdmin && (
        <Box
          sx={{ cursor: "pointer" }}
          onClick={() => deleteCustomer(customer, true)}
        >
          <TrashTaskIcon width={16} height={17} color="#A5A5A5" />
        </Box>
      )}
    </Grid>
  );
};

SelectedCustomerItem.propTypes = {
  customer: shape({
    id: string,
    name: string,
    shippingAddress: object,
    billingAddress: object,
  }),
  deleteCustomer: func,
  adminIsAllowed: bool,
};

SelectedCustomerItem.defaultProps = {
  adminIsAllowed: false,
};
