import { useEffect, useMemo, useState } from "react";
import PropTypes, { object, func, array, string, bool } from "prop-types";
import moment from "moment-timezone";
import {
  LocalizationProvider,
  StaticDateRangePicker,
  DateRangePickerDay,
  CalendarPicker,
} from "@mui/lab";
import { Menu, Box, Stack } from "@mui/material";
import CustomDateAdapter from "../../utils/CustomDateAdapter";
import AdapterMoment from "@mui/lab/AdapterMoment";
import StyledButton from "../StyledButton";
import { StyledTextField } from "../TextFields/TextFields";
import useStyles, { cl } from "./styles";
import { useSelector } from "react-redux";

const StyledDateRangePicker = ({
  anchorEl,
  handleDateInput,
  date,
  handleClose,
  isCustomDate,
  showSingleDataRange = false,
  newStyledPicker = false,
}) => {
  const classes = useStyles();

  const currentUser = useSelector(({ auth }) => auth?.currentUser);

  const { timeZone } = currentUser || {};

  const [pickDate, setPickDate] = useState(
    typeof date === "string" ? [null, null] : date
  );

  const dateValue = useMemo(
    () => (typeof date === "string" ? [null, null] : date),
    [date]
  );

  useEffect(() => {
    if (typeof date !== "string") setPickDate(date);
  }, [date]);

  const [dueDate, setDueDate] = useState(
    typeof date !== "string" && date?.[0] ? date?.[0] : ""
  );

  const handleChangeCalendarPicker = (date) => {
    const formattedDate = moment
      .tz(date, timeZone)
      .hours(0)
      .minutes(0)
      .seconds(0)
      .utc();
    setDueDate(formattedDate);

    handleDateInput([formattedDate, null]);
    handleClose();
  };
  const handleCancel = () => handleClose();

  return (
    <>
      {showSingleDataRange ? (
        <Menu
          sx={{ "& .MuiList-root": { p: 0 } }}
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleClose}
        >
          <Box sx={cl.menuWrapper}>
            <Box>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <CalendarPicker
                  timezone={timeZone}
                  date={dueDate ? moment.tz(dueDate, timeZone) : null}
                  onChange={(date) => handleChangeCalendarPicker(date)}
                />
              </LocalizationProvider>
              <Stack
                flexDirection="row"
                justifyContent="flex-end"
                sx={{ px: "8px", pb: "16px" }}
              >
                <StyledButton
                  sx={{ width: "64px", height: "28px" }}
                  fontWeight={500}
                  label="Cancel"
                  onClick={handleCancel}
                />

                <StyledButton
                  sx={{ width: "64px", height: "28px" }}
                  label="Today"
                  fontWeight={500}
                  onClick={() => {
                    const formattedDate = moment()
                      .tz(timeZone)
                      .hours(0)
                      .minutes(0)
                      .seconds(0)
                      .utc();
                    setDueDate(formattedDate);
                    handleDateInput([formattedDate, null]);
                    handleClose();
                  }}
                />
              </Stack>
            </Box>
          </Box>
        </Menu>
      ) : newStyledPicker ? (
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={() => {
            handleDateInput(pickDate[0] || pickDate[1] ? pickDate : date);
            handleClose();
          }}
          sx={{
            mt: "3px",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box sx={cl.newStyledRangeMenuWrapper}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <StaticDateRangePicker
                displayStaticWrapperAs="desktop"
                value={dateValue}
                onChange={(newDate) => {
                  if (newDate[1] && newDate[1] <= newDate[0])
                    return setPickDate([newDate[1], null]);
                  setPickDate(newDate);
                }}
                showDaysOutsideCurrentMonth
                calendars={1}
                renderDay={(dateStr, dayProps) => {
                  const day = dateStr.date();

                  return (
                    <DateRangePickerDay
                      {...dayProps}
                      sx={{
                        fontWeight: dayProps?.today ? "700" : "500",
                        color: dayProps?.outsideCurrentMonth
                          ? "#BDBDBD"
                          : "#333333",
                        "&.Mui-selected": {
                          backgroundColor: "#409A65 !important",
                        },
                      }}
                    >
                      {day}
                    </DateRangePickerDay>
                  );
                }}
                renderInput={(inputProps) => (
                  <StyledTextField {...inputProps} />
                )}
              />
            </LocalizationProvider>
            <Stack
              flexDirection="row"
              justifyContent="flex-end"
              sx={{ pr: "17px" }}
            >
              <StyledButton
                sx={{ width: "64px", height: "28px" }}
                fontSize={"12px !important"}
                fontWeight={500}
                label="Cancel"
                onClick={handleCancel}
              />

              <StyledButton
                sx={{ width: "64px", height: "28px" }}
                label="Today"
                fontSize={"12px !important"}
                fontWeight={500}
                onClick={() => {
                  const formattedDate = moment()
                    .tz(timeZone)
                    .hours(0)
                    .minutes(0)
                    .seconds(0)
                    .utc();
                  setDueDate(formattedDate);
                  handleDateInput([formattedDate, null]);
                  handleClose();
                }}
              />
            </Stack>
          </Box>
        </Menu>
      ) : (
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleClose}
          PaperProps={{ className: classes.menu }}
        >
          <Box
            className={classes.tailBackground}
            sx={{ left: isCustomDate ? "75px !important" : "0px" }}
          />
          <Box
            className={classes.tail}
            sx={{ left: isCustomDate ? "76px !important" : "0px" }}
          />
          <LocalizationProvider dateAdapter={CustomDateAdapter}>
            <StaticDateRangePicker
              displayStaticWrapperAs="desktop"
              label="Date"
              renderDay={(dateStr, dayProps) => {
                const dayDate = new Date(dateStr);
                const day = dayDate.getDate();
                const today = new Date();
                today.setDate(today.getDate() - 1);
                return (
                  <DateRangePickerDay
                    sx={{
                      width: "22px",
                      height: "20px",
                      transform: "none",
                      fontWeight: dayProps.today ? "700" : "500",
                      color: dayDate < today ? "#ABABAB" : "#5F6267",
                      "& .div": {
                        border: "none",
                        display: "flex",
                      },
                      "&.Mui-selected": {
                        backgroundColor: "#42A57F!important",
                        borderRadius: pickDate[1]
                          ? dayProps.isEndOfHighlighting
                            ? "0px 3px 3px 0px!important"
                            : "3px 0px 0px 3px"
                          : "3px",
                      },
                    }}
                    {...dayProps}
                  >
                    {day}
                  </DateRangePickerDay>
                );
              }}
              value={dateValue}
              calendars={2}
              renderInput={(inputProps) => <StyledTextField {...inputProps} />}
              onChange={(newDate) => {
                if (newDate[1] && newDate[1] <= newDate[0])
                  return setPickDate([newDate[1], null]);
                setPickDate(newDate);
              }}
            />
          </LocalizationProvider>
          <Box
            display="flex"
            justifyContent="flex-end"
            mt="8.5px"
            height="19px"
            pr="14px"
          >
            <StyledButton
              label="Cancel"
              variant="contained"
              color="greyBtn"
              onClick={() => {
                handleClose();
                setPickDate([null, null]);
              }}
              sx={{
                ml: "6px",
                fontSize: "9px",
                textTransform: "uppercase",
                px: "18px",
                color: "#ffffff",
              }}
            />
            <StyledButton
              label="Apply"
              variant="contained"
              onClick={() => {
                handleDateInput(
                  pickDate?.[0] === null ? [moment(), null] : pickDate
                );
                handleClose();
                setPickDate([null, null]);
              }}
              sx={{
                ml: "6px",
                fontSize: "9px",
                textTransform: "uppercase",
                px: "18px",
              }}
            />
          </Box>
        </Menu>
      )}
    </>
  );
};

StyledDateRangePicker.propTypes = {
  anchorEl: object,
  handleDateInput: func,
  date: PropTypes.oneOfType([array, string]),
  handleClose: func,
  isCustomDate: bool,
  showSingleDataRange: bool,
  newStyledPicker: bool,
};

StyledDateRangePicker.defaultProps = {
  anchorEl: null,
};

export default StyledDateRangePicker;
