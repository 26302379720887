import { func, object } from "prop-types";
import { Grid, InfiniteLoader } from "react-virtualized";
import { Box, CircularProgress, Collapse } from "@mui/material";
import { ListRowWrapper } from "components/InfiniteLoaderWrapper/components";
import { GalleryFilter } from "./components/GalleryFilter/GalleryFilter";
import PhotoGroupCard from "./components/PhotoGroupCard/PhotoGroupCard";
import PhotoGroupPopup from "./components/PhotoGroupPopup/PhotoGroupPopup";
import {
  EmptyScreen,
  InfiniteLoadMoreBtn,
  TableHeaderControlPanel,
  TagsDrawer,
} from "components";
import { TAG_TYPES } from "helpers/useTagsActions";
import { useGalleryTab } from "./useGalleryTab";

const GalleryTab = ({ setDownloadProgress }) => {
  const {
    openTagsDrawer,
    handleCloseTagsDrawer,
    handleSaveTags,
    loadingTagsDrawer,
    checkedPhotos,
    setCheckedPhotos,
    isOpenProductDialog,
    setPage,
    productTags,
    currentPage,
    setCurrentPage,
    handleCheckPhoto,
    currentUser,
    actionList,
    dropDownActionsList,
    galleryLoading,
    loadingPhotos,
    handleSetCheckAllCustomers,
    galleryCount,
    handleSetCheckedPhotosHeader,
    galleryList,
    columnCount,
    windowWidth,
    handleFetch,
    rowCount,
    COLUMN_WIDTH,
    calculateWidth,
    PHOTO_GAP,
    handleSetProduct,
    handleCloseProductDialog,
    showControlPanel,
    COLLAPSE_MS,
    handleAddEndListener,
    height,
    fetchingData,
  } = useGalleryTab({ setDownloadProgress });

  return (
    <>
      <GalleryFilter
        {...{ setPage, currentUser, loading: fetchingData, setCheckedPhotos }}
      />

      <TagsDrawer
        type={TAG_TYPES.product}
        open={openTagsDrawer}
        handleClose={handleCloseTagsDrawer}
        productTags={productTags || []}
        isBulk={checkedPhotos.length > 1}
        loading={loadingTagsDrawer}
        handleSave={({ tags }) => {
          handleSaveTags(tags);
        }}
        hideAdvanced
        setCheckedCustomers={() => {}}
      />

      <Box my={1} mx={4}>
        <TableHeaderControlPanel
          actionWrapperProps={{ sx: { padding: "0 12px 0 20px !important" } }}
          checkedCount={checkedPhotos?.length}
          actionsList={actionList}
          dropDownActions={dropDownActionsList}
          loading={galleryLoading || loadingPhotos}
          onSelectAll={(value) => handleSetCheckAllCustomers(value)}
          hasCheckedItems={!!checkedPhotos?.length && showControlPanel}
          availableSelectCount={galleryCount}
          selectName="photo"
          onSelectVisible={handleSetCheckedPhotosHeader}
          cancelSelection={() => setCheckedPhotos([])}
          headerComponent={
            <Collapse
              in={checkedPhotos?.length > 0}
              timeout={COLLAPSE_MS}
              addEndListener={handleAddEndListener}
            >
              <Box width="100%" height="39px" />
            </Collapse>
          }
        />
      </Box>

      {galleryList.length ? (
        <InfiniteLoader
          isRowLoaded={({ index }) => !!galleryList[index * columnCount]}
          loadMoreRows={handleFetch}
          rowCount={rowCount}
          threshold={1}
        >
          {({ onRowsRendered, registerChild }) => {
            return (
              <Box>
                <Grid
                  width={windowWidth}
                  id="gallery-table"
                  height={height}
                  ref={registerChild}
                  columnWidth={COLUMN_WIDTH}
                  columnCount={columnCount}
                  rowCount={Math.ceil(galleryList?.length / columnCount)}
                  rowHeight={416}
                  overscanRowCount={10}
                  style={{
                    padding: "0px 32px 20px",
                    overflowY: "overlay",
                    overflowX: "visible",
                  }}
                  cellRenderer={({ key, rowIndex, columnIndex, style }) => {
                    const productIndex = rowIndex * columnCount + columnIndex;
                    const product = galleryList[productIndex];
                    const galleryLastIndex = galleryList?.length - 1;
                    const width = calculateWidth();
                    const left = columnIndex * width + columnIndex * PHOTO_GAP;
                    if (
                      !product &&
                      !galleryLoading &&
                      galleryCount > galleryList.length &&
                      productIndex === galleryLastIndex + 1
                    ) {
                      return (
                        <Box
                          style={{
                            ...style,
                            top: style.top + 415,
                            left: "50%",
                            transform: "translateX(-50%)",
                          }}
                          sx={{
                            "& svg": {
                              width: "50px",
                              height: "50px",
                            },
                          }}
                        >
                          <InfiniteLoadMoreBtn onClick={handleFetch} />
                        </Box>
                      );
                    }
                    if (!product) {
                      if (galleryLoading)
                        return (
                          <ListRowWrapper
                            style={{
                              ...style,
                              border: "1px solid #d5d9d9",
                              borderRadius: "8px",
                              width,
                              left,
                            }}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            key={key}
                          >
                            <CircularProgress size={30} />
                          </ListRowWrapper>
                        );

                      return <></>;
                    }
                    return (
                      <ListRowWrapper
                        style={{
                          ...style,
                          width,
                          left,
                        }}
                        key={key}
                      >
                        <PhotoGroupCard
                          product={product}
                          handleSetProduct={(item) => {
                            setCurrentPage(productIndex + 1);
                            handleSetProduct(item);
                          }}
                          handleCheckPhoto={handleCheckPhoto}
                          timeZone={currentUser.timeZone}
                          isChecked={checkedPhotos.some(
                            (p) => p.id === product.id
                          )}
                        />
                      </ListRowWrapper>
                    );
                  }}
                  onSectionRendered={({ rowStartIndex, rowStopIndex }) => {
                    onRowsRendered({
                      startIndex: rowStartIndex,
                      stopIndex: rowStopIndex,
                    });
                  }}
                />
                {galleryLoading && (
                  <Box display="flex" justifyContent="center">
                    <CircularProgress size={30} />
                  </Box>
                )}
              </Box>
            );
          }}
        </InfiniteLoader>
      ) : (
        <EmptyScreen
          type="photos"
          height="calc(100vh - 210px)"
          showAction={false}
          loading={galleryLoading}
        />
      )}
      <PhotoGroupPopup
        user={{
          avatar: currentUser.profilePhoto,
          name: currentUser.name,
          id: currentUser.id,
        }}
        isOpenProductDialog={isOpenProductDialog}
        handleCloseProductDialog={handleCloseProductDialog}
        currentPage={currentPage}
      />
    </>
  );
};

GalleryTab.propTypes = {
  downloadProgress: object,
  setDownloadProgress: func,
};

export default GalleryTab;
