import { Button } from "@mui/material";
import { StyledButton } from "components";
import {
  ChangeCategoryIcon,
  PenIcon,
  TrashIcon,
  ArchiveIcon,
  MenuListIcon,
  TagIcon,
} from "components/Icons";
import { ReportIcon } from "components/Icons/MenuIcons";
import { reportsList } from "Pages/ReportsPage/ReportsPage.constants";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  openConfirmDialogAction,
  resetConfirmDialogAction,
  setEditItemAction,
  setFormChangedAction,
} from "redux/actions/confirmDialogs";
import {
  archiveProductsAction,
  bulkDeleteProductsAction,
  deleteMultipleProductAction,
  selectedChildrensOfProductsAction,
  selectedProductsAction,
} from "redux/actions/products";

import {
  getParentProductSelector,
  productsShowInactiveSelector,
  selectedChildrensOfProductsSelector,
} from "redux/selectors/products";
import { createSelector } from "reselect";
import {
  ADMIN_ONLY_VIEW_MESSAGE,
  ADMIN_ONLY_VIEW_MESSAGE_PAGE,
  PRODUCT_TYPE_INVENTORY,
} from "utils/constants";

const selector = createSelector(
  productsShowInactiveSelector,
  getParentProductSelector,
  selectedChildrensOfProductsSelector,
  (showInactive, parent, selectedChildrensOfProducts) => ({
    showInactive,
    parent,
    selectedChildrensOfProducts,
  })
);

export const useProductsActions = ({
  onDelete,
  handleArchive,
  handleOpenProductProfile,
  onOpenChangeCategoriesDialog,
  selectedProducts,
  repPermissions,
  fetchCountryOfOrigin,
}) => {
  const currentUser = useSelector(({ auth }) => auth?.currentUser);

  const editType = useSelector(({ confirmDialogs }) => confirmDialogs.editType);

  const formChanged = useSelector(
    ({ confirmDialogs }) => confirmDialogs.formChanged
  );

  const [isOpenReportDialog, setOpenReportDialog] = useState(false);
  const [currentReport, setCurrentReport] = useState(null);
  const [isCustom, setCustom] = useState(false);
  const [changeAssignedState, setChangeAssignedState] = useState({
    open: false,
    product: null,
  });

  const handleOpenChangeAssignedDialog = (product) => {
    setChangeAssignedState((prev) => ({ ...prev, open: true, product }));
  };

  const handleCloseChangeAssignedDialog = () => {
    setChangeAssignedState((prev) => ({
      ...prev,
      open: false,
      product: null,
    }));
  };

  const handleCloseReportDialog = useCallback(() => {
    setCustom(false);
    setOpenReportDialog(false);
    setCurrentReport(null);
  }, []);

  const handleReport = useCallback((report, isCustom) => {
    setCustom(!!isCustom);
    setCurrentReport(report);
    setOpenReportDialog(true);
  }, []);

  const { showInactive, parent, selectedChildrensOfProducts } =
    useSelector(selector);

  const dispatch = useDispatch();

  const hasQuickBooks = useMemo(
    () => !!currentUser?.quickBooksTokens,
    [currentUser.quickBooksTokens]
  );

  const lastProductChild = useMemo(
    () => parent?.childProducts?.length === selectedProducts?.length,
    [parent?.childProducts?.length, selectedProducts?.length]
  );

  const reportData = useMemo(() => {
    return {
      ...reportsList[2].reports[0],
      selectAndReorderColumnsBottom:
        reportsList[2].reports[0].selectAndReorderColumnsBottom.map(
          (field) => ({
            ...field,
            chosen: true,
            selected: true,
          })
        ),
      config: { ...reportsList[2].config },
    };
  }, []);

  const handleConfirmCheckedItemsDialog = useCallback(
    (item, type) => {
      dispatch(
        openConfirmDialogAction({
          title: "Leaving this page?",
          text: "Your selected list will be discarded.",
          buttons: (
            <>
              <StyledButton
                variant="outlined"
                color="cancel"
                type="submit"
                form={`new-${editType}-form`}
                label="Cancel"
                sx={{ height: "28px" }}
                onClick={() => {
                  // dispatch(setConfirmIsOpenAction(false));
                  dispatch(resetConfirmDialogAction());
                }}
              />
              <Button
                sx={{
                  width: "auto",
                  height: "28px",
                  color: "#FFFFFF",
                  fontSize: "13px",
                  boxShadow: "none",
                }}
                color="confirmDelete"
                onClick={() => {
                  dispatch(selectedProductsAction([]));
                  dispatch(selectedChildrensOfProductsAction([]));

                  dispatch(setFormChangedAction(false));
                  dispatch(setEditItemAction(null));

                  // dispatch(setConfirmIsOpenAction(false));
                  dispatch(resetConfirmDialogAction());

                  handleOpenProductProfile({ product: item, isChildren: type });
                }}
                variant="contained"
              >
                Confirm
              </Button>
            </>
          ),
        })
      );
    },
    [dispatch, editType, handleOpenProductProfile]
  );

  const onBulkDelete = useCallback(() => {
    dispatch(
      openConfirmDialogAction({
        title: "Delete products?",
        text: "Are you sure you want to delete",
        userName: "selected products",
        text2: !lastProductChild
          ? "All data will be erased and this can't be undone."
          : "Deletion of the last variation will delete the parent product. This can’t be undone.",
        isIcon: !lastProductChild,
        buttons: (
          <>
            <Button
              sx={{
                width: "98px",
                color: "#6A6A6A",
                borderColor: "#D4D4D4",
                fontSize: "13px",
                height: "28px",
              }}
              onClick={() => {
                // dispatch(setConfirmIsOpenAction(false));
                dispatch(resetConfirmDialogAction());
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              sx={{
                width: !lastProductChild ? "98px" : "124px",
                color: "#FFFFFF",
                fontSize: "13px",
                height: "28px",
                boxShadow: "none",
              }}
              color="confirmDelete"
              onClick={() => {
                // dispatch(setConfirmIsOpenAction(false));
                dispatch(resetConfirmDialogAction());
                if (lastProductChild)
                  dispatch(deleteMultipleProductAction({ id: parent?.id }));
                if (selectedChildrensOfProducts?.length) {
                  selectedChildrensOfProducts.map((prod) => prod[1]).flat();
                }
                let delProd = [
                  ...selectedProducts.map((items) => items.id),
                  ...selectedChildrensOfProducts.map((prod) => prod[1]).flat(),
                ];
                dispatch(
                  bulkDeleteProductsAction({
                    productsIds: delProd,
                    onSuccess: fetchCountryOfOrigin
                      ? fetchCountryOfOrigin
                      : () => {},
                  })
                );
                dispatch(selectedProductsAction([]));
                dispatch(selectedChildrensOfProductsAction([]));
              }}
              variant="contained"
            >
              {!lastProductChild ? "Delete" : "Delete Product"}
            </Button>
          </>
        ),
      })
    );
  }, [
    dispatch,
    lastProductChild,
    parent?.id,
    selectedChildrensOfProducts,
    selectedProducts,
    fetchCountryOfOrigin,
  ]);

  const hasSelectedChildren = useMemo(
    () => selectedChildrensOfProducts && !!selectedChildrensOfProducts.length,
    [selectedChildrensOfProducts]
  );

  const handleClickOnEditProduct = useCallback(
    (e, product, isChild, type) => {
      e && e.stopPropagation();

      if (formChanged && editType === "checked_items") {
        handleConfirmCheckedItemsDialog(product, isChild);
        return;
      }

      handleOpenProductProfile({ product, isChildren: isChild, dirNav: type });
    },
    [
      editType,
      formChanged,
      handleConfirmCheckedItemsDialog,
      handleOpenProductProfile,
    ]
  );

  const [assignTagsState, setAssignTagsState] = useState({
    open: false,
    product: null,
    products: [],
    isBulk: false,
  });

  const handleCloseAssignTagsDialog = () => {
    setAssignTagsState(() => ({
      open: false,
      product: null,
      products: [],
      isBulk: false,
    }));
  };

  const handleClickOnAssignTags = useCallback(
    (product) => {
      if (product)
        return setAssignTagsState((prev) => ({
          ...prev,
          open: true,
          product,
          products: [],
          isBulk: false,
        }));

      setAssignTagsState((prev) => ({
        ...prev,
        open: true,
        product: null,
        products: selectedProducts,
        isBulk: true,
      }));
    },
    [selectedProducts]
  );

  const handleBulkArchive = () => {
    dispatch(
      openConfirmDialogAction({
        title: `${showInactive ? "Unarchive" : "Archive"} products?`,
        text: showInactive
          ? "Unarchiving these products will show them in your sales channels."
          : "Archiving these products will hide them from your sales channels. You’ll find them using the status filter in your product list.",
        buttons: (
          <>
            <Button
              sx={{
                width: "98px",
                color: "#6A6A6A",
                borderColor: "#D4D4D4",
                fontSize: "13px",
                height: "28px",
              }}
              onClick={() => {
                // dispatch(setConfirmIsOpenAction(false));
                dispatch(resetConfirmDialogAction());
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              sx={{
                color: "#FFFFFF",
                fontSize: "13px",
                height: "28px",
                boxShadow: "none",
              }}
              color={showInactive ? "primary" : "confirmDelete"}
              onClick={() => {
                // dispatch(setConfirmIsOpenAction(false));
                dispatch(resetConfirmDialogAction());
                // dispatch(setFormChangedAction(false));
                dispatch(
                  archiveProductsAction(
                    [...selectedProducts.map((items) => items.id)],
                    showInactive
                  )
                );
              }}
              variant="contained"
            >
              {showInactive ? "Unarchive" : "Archive"} Products
            </Button>
          </>
        ),
      })
    );
  };

  const disabledCreateEdit = useMemo(() => {
    return repPermissions ? !repPermissions?.catalog?.create_edit : false;
  }, [repPermissions]);

  const disabledReportBtn = useMemo(() => {
    return repPermissions ? !repPermissions?.reports?.view : false;
  }, [repPermissions]);

  const disabledTagsBtn = useMemo(() => {
    return repPermissions ? !repPermissions?.catalog?.product_tags : false;
  }, [repPermissions]);

  const handleSelectedChildren = () => {
    if (!selectedProducts.length && selectedChildrensOfProducts.length)
      return selectedChildrensOfProducts.map((item) => item[0]).flat();
    const selectedIds = selectedProducts?.map((item) => item.id);

    const selectedParents = selectedChildrensOfProducts
      .map((item) => {
        return !selectedIds.includes(item[0].id) ? item[0] : [];
      })
      .flat();
    return selectedParents;
  };

  const handleSingleActions = (product, isChild, isTableItem = false) => {
    if (!product) return [];
    const isNonInventory =
      product?.type === PRODUCT_TYPE_INVENTORY.non_inventory;

    return [
      {
        label: "Edit product",
        element: null,
        disabled: disabledCreateEdit,
        icon: <PenIcon size={9.44} color={"#3F3F3F"} />,
        onClick: (e) =>
          !disabledCreateEdit && handleClickOnEditProduct(e, product, isChild),
        show: true,
        withDividerAfter: false,
        withDividerBefore: false,
        tooltip: {
          title:
            repPermissions?.role === "SALES"
              ? ADMIN_ONLY_VIEW_MESSAGE
              : ADMIN_ONLY_VIEW_MESSAGE_PAGE,
          show: disabledCreateEdit,
          props: { isDark: true },
        },
      },
      {
        label: "Edit inventory",
        element: null,
        disabled: disabledCreateEdit,
        icon: <MenuListIcon width={9.6} height={8.363} />,
        onClick: (e) =>
          !disabledCreateEdit &&
          handleClickOnEditProduct(e, product, isChild, "edit_inventory"),
        show: !isNonInventory,
        withDividerAfter: false,
        withDividerBefore: false,
        tooltip: {
          title:
            repPermissions?.role === "SALES"
              ? ADMIN_ONLY_VIEW_MESSAGE
              : ADMIN_ONLY_VIEW_MESSAGE_PAGE,
          show: disabledCreateEdit,
          props: { isDark: true },
        },
      },
      {
        label: "Assign manufacturer",
        element: null,
        disabled: disabledCreateEdit,
        onClick: () => handleOpenChangeAssignedDialog([product]),
        show: !isTableItem,
        tooltip: {
          title:
            repPermissions?.role === "SALES"
              ? ADMIN_ONLY_VIEW_MESSAGE
              : ADMIN_ONLY_VIEW_MESSAGE_PAGE,
          show: disabledCreateEdit,
          props: { isDark: true },
        },
      },
      {
        label: "Assign tags",
        element: null,
        disabled: disabledTagsBtn,
        icon: <TagIcon width={11.75} height={11.75} fill="#3F3F3F" />,
        onClick: () => !disabledTagsBtn && handleClickOnAssignTags(product),
        show: true,
        withDividerAfter: false,
        withDividerBefore: false,
        tooltip: {
          title:
            repPermissions?.role === "SALES"
              ? ADMIN_ONLY_VIEW_MESSAGE
              : ADMIN_ONLY_VIEW_MESSAGE_PAGE,
          show: disabledTagsBtn,
          props: { isDark: true },
        },
      },
      {
        label: "Change category",
        element: null,
        disabled: disabledCreateEdit,
        icon: <ChangeCategoryIcon width={10} height={9.778} />,
        onClick: () =>
          !disabledCreateEdit && onOpenChangeCategoriesDialog(product),
        show: true,
        withDividerAfter: false,
        withDividerBefore: false,
        tooltip: {
          title:
            repPermissions?.role === "SALES"
              ? ADMIN_ONLY_VIEW_MESSAGE
              : ADMIN_ONLY_VIEW_MESSAGE_PAGE,
          show: disabledCreateEdit,
          props: { isDark: true },
        },
      },
      {
        label: "Report",
        element: null,
        disabled:
          disabledReportBtn ||
          (!!repPermissions && !repPermissions?.reports?.view),
        icon: <ReportIcon />,
        onClick: () =>
          handleReport({ ...reportData, products: [product] }, true),
        show: true,
        withDividerAfter: false,
        withDividerBefore: false,
        tooltip: {
          title:
            repPermissions?.role === "SALES"
              ? ADMIN_ONLY_VIEW_MESSAGE
              : ADMIN_ONLY_VIEW_MESSAGE_PAGE,
          show: repPermissions
            ? disabledReportBtn || !repPermissions?.reports?.view
            : false,
          props: { isDark: true },
        },
      },
    ];
  };

  const handleMultipleProductsActions = () => {
    const childParent = handleSelectedChildren();
    return [
      {
        label: "Assign manufacturer",
        element: null,
        disabled: disabledCreateEdit,
        onClick: () =>
          !disabledCreateEdit &&
          handleOpenChangeAssignedDialog(selectedProducts),
        show: true,
        tooltip: {
          title:
            repPermissions?.role === "SALES"
              ? ADMIN_ONLY_VIEW_MESSAGE
              : ADMIN_ONLY_VIEW_MESSAGE_PAGE,
          show: disabledCreateEdit,
          props: { isDark: true },
        },
      },
      {
        label: "Assign tags",
        element: null,
        disabled: disabledTagsBtn,
        onClick: () => !disabledTagsBtn && handleClickOnAssignTags(),
        show: true,
        tooltip: {
          title:
            repPermissions?.role === "SALES"
              ? ADMIN_ONLY_VIEW_MESSAGE
              : ADMIN_ONLY_VIEW_MESSAGE_PAGE,
          show: disabledTagsBtn,
          props: { isDark: true },
        },
      },
      {
        label: "Change category",
        element: null,
        disabled: disabledCreateEdit,
        onClick: () =>
          !disabledCreateEdit && onOpenChangeCategoriesDialog(null),
        show: true,
        tooltip: {
          title:
            repPermissions?.role === "SALES"
              ? ADMIN_ONLY_VIEW_MESSAGE
              : ADMIN_ONLY_VIEW_MESSAGE_PAGE,
          show: disabledCreateEdit,
          props: { isDark: true },
        },
      },
      {
        label: "Report",
        element: null,
        disabled:
          disabledCreateEdit ||
          (!!repPermissions && !repPermissions?.reports?.view),
        onClick: () =>
          !disabledCreateEdit &&
          handleReport(
            { ...reportData, products: [...selectedProducts, ...childParent] },
            true
          ),
        show: true,
        tooltip: {
          title:
            repPermissions?.role === "SALES"
              ? ADMIN_ONLY_VIEW_MESSAGE
              : ADMIN_ONLY_VIEW_MESSAGE_PAGE,
          show: disabledCreateEdit,
          props: { isDark: true },
        },
      },
    ];
  };

  const createEditPermissions = repPermissions
    ? !repPermissions?.catalog?.create_edit
    : false;

  const handleMultipleProductsDropDownActions = () => {
    const disabledArchive = !(selectedProducts?.length && !parent?.id);
    return [
      {
        label: showInactive ? "Unarchive" : "Archive",
        element: null,
        disabled: disabledArchive,
        disabledPermissions: createEditPermissions,
        icon: <ArchiveIcon />,
        onClick:
          disabledArchive || createEditPermissions
            ? () => {}
            : handleBulkArchive,
        show: true,
      },
      {
        label: "Delete",
        element: null,
        sx: {
          "& .MuiListItemText-root span": {
            color: "rgb(255, 98, 84) !important",
          },
        },
        disabled: disabledCreateEdit,
        disabledPermissions: createEditPermissions,
        onClick: disabledCreateEdit ? () => {} : onBulkDelete,
        show: !hasQuickBooks,
      },
    ];
  };

  const handleSingleProductsDropDownActions = (product, lastChild) => {
    if (!product) return [];

    return [
      {
        label: product.status === "INACTIVE" ? "Unarchive" : "Archive",
        element: null,
        disabled: !!repPermissions,
        disabledPermissions: !!repPermissions,
        icon: <ArchiveIcon />,
        onClick: () => !repPermissions && handleArchive(product),
        show: true,
        withDividerAfter: false,
        withDividerBefore: false,
      },
      {
        label: "Delete",
        sx: {
          "& .MuiListItemText-root span": {
            color: "rgb(255, 98, 84) !important",
            ml: "1px",
          },
        },
        element: null,
        disabled: createEditPermissions,
        disabledPermissions: createEditPermissions,
        icon: <TrashIcon fill="#FF6254" height="10.5" width="8.58" />,
        onClick: () => {
          !repPermissions && onDelete(product, parent, lastChild);
        },
        show: !hasQuickBooks,
        withDividerAfter: false,
        withDividerBefore: false,
      },
    ];
  };

  const handleGetActions = (singleParams) => {
    if (!selectedProducts.length && !hasSelectedChildren) return [];
    const selectedChildrenParents = handleSelectedChildren();

    if (selectedProducts.length === 1 && !selectedChildrenParents.length) {
      const { product, isChild } = singleParams;
      return handleSingleActions(product, isChild);
    }
    return handleMultipleProductsActions();
  };

  const handleGetDropDownActions = (product) => {
    if (!selectedProducts.length && !hasSelectedChildren) return [];
    const selectedChildrenParents = handleSelectedChildren();

    if (selectedProducts.length === 1 && !selectedChildrenParents.length) {
      return handleSingleProductsDropDownActions(product, lastProductChild);
    }
    return handleMultipleProductsDropDownActions();
  };

  return {
    isCustom,
    currentReport,
    handleSingleActions,
    handleClickOnEditProduct,
    handleGetActions,
    handleSelectedChildren,
    isOpenReportDialog,
    setOpenReportDialog,
    handleCloseReportDialog,
    handleGetDropDownActions,
    setAssignTagsState,
    changeAssignedState,
    handleCloseChangeAssignedDialog,
    assignTagsState,
    handleCloseAssignTagsDialog,
    handleSingleProductsDropDownActions,
  };
};
