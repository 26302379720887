import { string, func, number, bool } from "prop-types";

import { NoteIcon } from "components/Icons";

import { IconButton, Stack, Typography } from "@mui/material";
import pluralize from "pluralize";
import { StyledTooltip } from "components";

export const SelectedInfoBlock = ({
  count = 0,
  emptyLabel,
  isAdmin = false,
  onEdit,
  type = "category",
  showZero = false,
  disabled = false,
  tooltipText = "",
}) => {
  const text =
    count > 0
      ? `${pluralize(type, count)} selected`
      : showZero
      ? `${pluralize(type, count)} selected`
      : emptyLabel;

  return (
    <StyledTooltip
      arrow
      isDark
      placement="left"
      disableHoverListener={!disabled}
      title={tooltipText}
      sx={{ maxWidth: "235px" }}
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: { offset: [0, -5] },
          },
        ],
      }}
    >
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          px: "12px",
          height: "36px",
          background: "#F8F8F8",
          borderRadius: "4px",
          border: "0.5px #D5D9D9 solid",
          opacity: disabled ? 0.5 : 1,
        }}
      >
        {!disabled ? (
          <Typography fontSize="11px" color="#343434" lineHeight="12px">
            {(count > 0 || showZero) && (
              <span style={{ color: "#409A65" }}>{count}</span>
            )}{" "}
            {text}
          </Typography>
        ) : (
          <Typography fontSize="11px" color="#343434" lineHeight="12px">
            {emptyLabel}
          </Typography>
        )}
        <IconButton
          disableRipple
          disabled={isAdmin || disabled}
          sx={{ p: 0 }}
          onClick={onEdit}
        >
          <NoteIcon color="#707070A3" width={19.5} height={18.5} />
        </IconButton>
      </Stack>
    </StyledTooltip>
  );
};

SelectedInfoBlock.propTypes = {
  emptyLabel: string,
  isAdmin: bool,
  onEdit: func,
  count: number,
  type: string,
  showZero: bool,
  disabled: bool,
  tooltipText: string,
};
