import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./styles";
import {
  defaultRepCustomersFilters,
  FILTER_CUSTOMERS_SWITCHES,
  FILTER_REP_CUSTOMERS_SWITCHES,
} from "./NewTransferFilter/constants";
import { Box, Chip, MenuItem, Typography } from "@mui/material";
import { normalizeSnakeCaseString } from "helpers/helpers";
import lodash from "lodash";
import {
  CUSTOMER_STATUS_FILTERS,
  SCROLL_LIMIT,
  SCROLL_LIMIT_CUSTOMERS,
  UNCATEGORIZED_TERRITORY,
} from "utils/constants";
// import { CUSTOMER_FILTER_TABS } from "../../../../../CustomersPage/components/CustomersTab/components/TabsContent/components/NewCustomersFilter/NewCustomerFilter.constants";
import { FilterSearchTextField } from "components/TextFields/TextFields";
import CloseIcon from "@mui/icons-material/Close";
import StyledButton from "components/StyledButton";
import { FilterIcon } from "components/Icons";
import FilterMenu from "components/FilterMenu";
import { func, string, bool } from "prop-types";
import { createSelector } from "reselect";
import { customersStatusFilterByRepSelector } from "redux/selectors/customers";
import { getRepsService } from "../../../../../../services/reps";
import {
  getSortList,
  iconSort,
  PREFIXES,
} from "../../../../../CustomersPage/components/CustomersTab/components/TabsContent/components/NewCustomersFilter/NewCustomerFilter.constants";
import { getParentCustomersService } from "services/customers";
import { error } from "utils/notifications";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { setCustomerStatusFilterByRep } from "redux/actions/customers";
import { FilterSelect } from "Pages/CustomersPage/components/CustomersTab/components/TabsContent/components/NewCustomersFilter/styles";
import { useTagsActions } from "helpers/useTagsActions";

const selector = createSelector(
  customersStatusFilterByRepSelector,
  (customersStatus) => ({
    customersStatus,
  })
);

export const CustomersTabFilter = ({
  handleCustomersFilter,
  customersSearchInput,
  setCustomersSearchInput,
  viewCustomerPopup,
  setStatus,
  queryStatus,
  isRoute,
  hideActive,
  isEdit,
  loading,
}) => {
  const classes = useStyles();
  const { customersStatus } = useSelector(selector);
  const dispatch = useDispatch();

  const territoryList = useSelector(({ territory }) => territory.territoryList);

  const filterAnchor = useRef();

  const [filterFields, setFilterFields] = useState(defaultRepCustomersFilters);
  const [switches, setSwitches] = useState(
    isRoute ? FILTER_CUSTOMERS_SWITCHES : FILTER_REP_CUSTOMERS_SWITCHES
  );
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [repsList, setRepsList] = useState({
    sales: [],
    merchendiser: [],
    thirdParty: [],
  });

  const [parentsGroupList, setParentsGroupList] = useState({
    list: [],
    count: 0,
    loading: false,
  });

  const [params, setParams] = useState({
    limit: SCROLL_LIMIT_CUSTOMERS,
    page: 1,
    include_uncategorized: true,
    with_candidates: false,
  });

  const filterChipKeys = useMemo(
    () => Object.keys(filterFields).filter((key) => filterFields[key]),
    [filterFields]
  );

  const handleChangeCustomerStatusFilter = (e) => {
    const newStatus = e.target.value;
    if (setStatus) return setStatus(newStatus);
    handleCustomersFilter({ ...filterFields, status: newStatus });
    dispatch(setCustomerStatusFilterByRep(e.target.value));
  };

  const handleDeleteFilter = (key) => {
    const newState = switches;
    const index = switches.findIndex((s) => s.value === key);

    if (index > -1) {
      const insert = {
        ...switches[index],
        checked: false,
      };

      newState.splice(index, 1, insert);
      setSwitches([...newState]);

      const newData = {
        ...filterFields,
        [key]: lodash.isArray(filterFields[key]) ? [] : "",
      };

      setFilterFields(newData);
      handleCustomersFilter(newData);
    }
  };

  const handleApplyFilter = (newSwitches, newFields) => {
    setFilterMenuOpen(false);
    setSwitches([...newSwitches]);
    setFilterFields({ ...newFields });
    handleCustomersFilter({ ...newFields, status: customersStatus });
  };

  const getChipLabel = (key) => {
    const { days, name, value, icon, label } = filterFields[key] || {};
    const prefix = PREFIXES[value];
    return (
      <Box display="flex" alignItems="center" gap="6px">
        <Typography
          sx={{ fontSize: "13px", fontWeight: 500 }}
          color="groundLighter.main"
        >
          {normalizeSnakeCaseString(label || key)}:{" "}
          {!icon && (
            <span style={{ color: "#5F6368", marginTop: !!icon && "5px" }}>
              {prefix
                ? `${prefix} ${days || 1} days`
                : normalizeSnakeCaseString(name || filterFields[key])}
            </span>
          )}
        </Typography>
        {icon}
      </Box>
    );
  };

  const [groupState, setGroupState] = useState({
    search: "",
    searcheble: false,
  });

  const handleFetchGroups = useCallback(
    async (page) => {
      const currentPage = page || 1;

      try {
        const res = await getParentCustomersService({
          ...params,
          page: currentPage,
          limit: SCROLL_LIMIT,
          search: groupState.search,
        });
        const { rows, count } = res || {};

        const preparedList = rows.map((item) => ({
          ...item,
          name: item?.displayName || item?.name,
        }));

        setParentsGroupList((prev) => ({
          ...prev,
          list:
            currentPage > 1 ? [...prev.list, ...preparedList] : preparedList,
          count,
        }));

        setParams((prev) => ({ ...prev, page: currentPage }));
        setGroupState((prev) => ({
          ...prev,
          searcheble: !prev.searcheble ? count >= 10 : prev.searcheble,
        }));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong.");
      } finally {
        setParentsGroupList((prev) => ({ ...prev, loading: false }));
      }
    },
    [groupState.search, params]
  );

  useEffect(() => {
    handleFetchGroups();
    // eslint-disable-next-line
  }, [groupState.search]);

  const [repsState, setRepsState] = useState({ search: "", searcheble: false });

  useEffect(() => {
    if (!isRoute) return;
    Promise.all([
      getRepsService({ role: "sales", search: repsState.search }),
      getRepsService({ role: "merchandiser", search: repsState.search }),
      getRepsService({ role: "third_party", search: repsState.search }),
    ]).then((res) => {
      setRepsList({
        sales: res[0].rows,
        merchendiser: res[1].rows,
        thirdParty: res[2].rows,
      });
      setRepsState((prev) => ({
        ...prev,
        searcheble: !prev.searcheble
          ? res[0].count + res[1].count + res[2].count >= 10
          : prev.searcheble,
      }));
    });
  }, [isRoute, repsState.search]);

  const {
    list: tagsList,
    count: tagsCount,
    handleFetchTags,
    page: tagsPage,
    setTagsParams,
    searcheble,
  } = useTagsActions({
    type: "customer",
    params: {
      used: true,
      sort_by_tag: "asc",
    },
  });

  const [territoriesState, setTerritoriesState] = useState({ search: "" });
  let filteredTerritories = territoryList.filter((territory) =>
    territory.name.toLowerCase().includes(territoriesState.search.toLowerCase())
  );

  const selectMatchData = useMemo(() => {
    const salesCategoryTitle = repsList.sales.length
      ? {
          type: "group",
          name: "Sales",
        }
      : null;
    const merchCategoryTitle = repsList.sales.length
      ? {
          type: "group",
          name: "Merchandisers",
        }
      : null;

    const thirdPartyCategoryTitle = repsList.sales.length
      ? {
          type: "group",
          name: "Third Party",
        }
      : null;

    return [
      {
        id: "group_id",
        childrenList: parentsGroupList?.list,
        handleFetch: () => handleFetchGroups(params?.page + 1),
        setParams: setGroupState,
        dataCount: parentsGroupList?.count,
        dataLength: parentsGroupList?.list?.length,
        searcheble: groupState.searcheble,
        label: "",
      },
      {
        id: "territory_id",
        childrenList: [
          {
            id: UNCATEGORIZED_TERRITORY.id,
            name: UNCATEGORIZED_TERRITORY.name,
          },
          ...filteredTerritories,
        ],
        setParams: setTerritoriesState,
        label: "",
        searcheble: territoryList.length >= 10,
      },
      isRoute && {
        id: "representative_id",
        childrenList: [
          salesCategoryTitle,
          ...repsList.sales,
          merchCategoryTitle,
          ...repsList.merchendiser,
          thirdPartyCategoryTitle,
          ...repsList.thirdParty,
        ],
        label: "",
        setParams: setRepsState,
        searcheble: repsState.searcheble,
      },
      {
        id: "last_visit",
        isVisit: true,
        moreLessFirst: true,
        textFieldProps: {
          inputSx: {
            pr: 0,
          },
          endIcon: (
            <Box
              backgroundColor="#F7F7F7"
              height="32px"
              display="flex"
              alignItems="center"
              px="9px"
              borderLeft="0.5px solid #D5D9D9"
            >
              <Typography fontSize="12px">days</Typography>
            </Box>
          ),
        },
        valuesForTextfield: ["more_than", "less_than"],
        label: "",
        notched: false,
        labelProps: { shrink: false },
      },
      {
        id: "tag_id",
        childrenList: tagsList.map(({ tag, ...item }) => ({
          ...item,
          name: tag,
        })),
        handleFetch: () => handleFetchTags(tagsPage + 1),
        setParams: setTagsParams,
        dataCount: tagsCount,
        dataLength: tagsList?.length,
        label: "",
        searcheble: searcheble,
      },
      {
        id: "sort_by",
        childrenList: getSortList(false),
        label: "",
      },
      {
        id: "last_order",
        moreLessFirst: true,
        isVisit: true,
        isOrder: true,
        childrenlist: [
          {
            iconId: "highestOrderIcon",
            name: "More Than",
            id: "more_than",
          },
          {
            iconId: "lovestOrderIcon",
            name: "Less Than",
            id: "less_than",
          },
        ],

        textFieldProps: {
          inputSx: {
            pr: 0,
          },
          endIcon: (
            <Box
              backgroundColor="#F7F7F7"
              height="32px"
              display="flex"
              alignItems="center"
              px="9px"
              borderLeft="0.5px solid #D5D9D9"
            >
              <Typography fontSize="12px">days</Typography>
            </Box>
          ),
        },
        valuesForTextfield: ["more_than", "less_than"],
        label: "",
        notched: false,
        labelProps: { shrink: false },
      },
      {
        id: "tasks_due",
        textFieldProps: {
          inputSx: { pr: 0 },
          endIcon: (
            <Box
              backgroundColor="#F7F7F7"
              height="32px"
              display="flex"
              alignItems="center"
              px="9px"
              borderLeft="0.5px solid #D5D9D9"
            >
              <Typography fontSize="12px">days</Typography>
            </Box>
          ),
        },
        label: "",
        notched: false,
        labelProps: { shrink: false },
        datesList: [
          { name: "Today", id: "today" },
          { name: "Tomorrow", id: "tomorrow" },
          { name: "This week", id: "this_week" },
          { name: "Next week", id: "next_week" },
        ],
      },
    ];
  }, [
    repsList.sales,
    repsList.merchendiser,
    repsList.thirdParty,
    parentsGroupList?.list,
    parentsGroupList?.count,
    territoryList,
    isRoute,
    tagsList,
    tagsCount,
    handleFetchGroups,
    params?.page,
    handleFetchTags,
    tagsPage,
    searcheble,
    repsState.searcheble,
    filteredTerritories,
    setTagsParams,
    groupState.searcheble,
  ]);

  return (
    <Box className={classes.filterWrapper}>
      <Box sx={{ display: "flex", px: "11px", width: "100%" }}>
        {!viewCustomerPopup && !hideActive && (
          <FilterSelect
            value={setStatus ? queryStatus : customersStatus}
            onChange={handleChangeCustomerStatusFilter}
            formSx={{ width: "100%", maxWidth: "fit-content" }}
            IconComponent={ArrowDropDownIcon}
          >
            <MenuItem value={CUSTOMER_STATUS_FILTERS.active}>Active</MenuItem>
            <MenuItem value={CUSTOMER_STATUS_FILTERS.inactive}>
              Inactive
            </MenuItem>
            <MenuItem value={CUSTOMER_STATUS_FILTERS.prospect}>
              Prospect
            </MenuItem>
          </FilterSelect>
          // <ActiveTabs
          //   value={setShowInactive ? !queryActive : showInactive}
          //   onChange={handleShowInactive}
          // />
        )}

        <FilterSearchTextField
          formSx={{
            ...(hideActive ? { m: "0 11px 0 2px" } : { mx: "11px" }),
            width: "100%",
            backgroundColor: "#fff",
          }}
          adminIsAllowed
          placeholderWidth="298px"
          loading={loading}
          placeholder="Search by ID, customer name or address"
          value={customersSearchInput}
          onChange={(e) => setCustomersSearchInput(e.target.value)}
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          fullWidth
          adornmentProps={{
            sx: {
              justifyContent: "flex-end",
            },
          }}
          customAdornment={
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                overflow: "auto",
                justifyContent: "flex-end",
                py: "4px",
                "&::-webkit-scrollbar": {
                  height: "2px",
                },
              }}
            >
              <Box
                display="flex"
                gap="5px"
                overflow="auto"
                sx={{
                  py: "4px",
                  "&::-webkit-scrollbar": {
                    height: "2px",
                  },
                }}
              >
                {isEdit && (
                  <Chip
                    className={classes.filterChip}
                    sx={{
                      "& span": {
                        fontWeight: 500,
                      },
                    }}
                    size="small"
                    label="Selected"
                    onMouseDown={(e) => e.stopPropagation()}
                  />
                )}
                {filterChipKeys.map((key) => (
                  <Chip
                    key={key}
                    className={classes.filterChip}
                    size="small"
                    label={getChipLabel(key)}
                    deleteIcon={
                      <CloseIcon size="15" style={{ fill: "#707070" }} />
                    }
                    onMouseDown={(e) => e.stopPropagation()}
                    onDelete={() => handleDeleteFilter(key)}
                  />
                ))}
              </Box>
            </Box>
          }
          handleClearValue={() => setCustomersSearchInput("")}
        />
        <StyledButton
          label="Filter"
          startIcon={
            filterChipKeys.length ? (
              <Typography
                fontSize="9px!important"
                color="#ffffff"
                backgroundColor="#47A06D"
                borderRadius="50%"
                width="15px"
                height="15px"
              >
                {filterChipKeys.length}
              </Typography>
            ) : (
              <FilterIcon />
            )
          }
          variant="outlined"
          ref={filterAnchor}
          color="edit"
          sx={{
            height: "39px",
            width: "100%",
            maxWidth: "74px",
            border: "0.5px solid #D5D9D9",
            "& .MuiButton-startIcon": {
              ml: 0,
            },
          }}
          fontSize="15px"
          onClick={() => setFilterMenuOpen(true)}
        />
        <FilterMenu
          anchorEl={filterAnchor.current}
          open={filterMenuOpen}
          iconDict={iconSort}
          onClose={() => setFilterMenuOpen(false)}
          {...{
            selectMatchData,
            handleApplyFilter,
            switches,
            setFilterFields,
            filterFields,
          }}
        />
      </Box>
    </Box>
  );
};

CustomersTabFilter.propTypes = {
  handleCustomersFilter: func,
  customersSearchInput: string,
  setCustomersSearchInput: func,
  setStatus: func,
  queryStatus: string,
  isRoute: bool,
  viewCustomerPopup: bool,
  hideActive: bool,
  isEdit: bool,
  loading: bool,
};

CustomersTabFilter.defaultProps = {
  customersSearchInput: "",
  loading: false,
  isRoute: false,
  hideActive: false,
  isEdit: false,
};
