import * as React from "react";
export const ChatIcon = (props) => (
  <svg
    width={20}
    height={19}
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.75 5.25h8.5M5.75 9.5H10M4.9 1h10.2a3.4 3.4 0 0 1 3.4 3.4v5.95a3.4 3.4 0 0 1-3.4 3.399h-2.55l-5.44 4.08a.85.85 0 0 1-1.36-.68v-3.4H4.9a3.4 3.4 0 0 1-3.4-3.4V4.4A3.4 3.4 0 0 1 4.9 1"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
