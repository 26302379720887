import { useEffect, useMemo, useState } from "react";
import { array, func, object } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Box, MenuItem } from "@mui/material";
import { createSelector } from "reselect";

import {
  manufacturersDiscountsExistSelector,
  manufacturersDiscountsLoadingSelector,
  manufacturersDiscountsShowInactiveSelector,
} from "redux/selectors/manufacturers-discounts";
import {
  bulkActiveInactiveDicountsAction,
  bulkDeleteDiscountsAction,
  getManufacturersDiscountsParamsAction,
  resetDiscountsGetParamsAction,
  setShowInactiveDiscounts,
} from "redux/actions/manufacturers-discounts";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";
import { useDebounce } from "helpers/hooks";
import {
  SquareSelect,
  ActiveTabs,
  FilterSearchTextField,
  ActionRestrictionWrapper,
} from "components";
import { cl } from "./styles";
import { resetScrollHeight, useAdmin } from "helpers/helpers";
import {
  ADMIN_ONLY_VIEW_MESSAGE,
  ADMIN_ONLY_VIEW_MESSAGE_PAGE,
} from "utils/constants";

const selector = createSelector(
  manufacturersDiscountsShowInactiveSelector,
  manufacturersDiscountsExistSelector,
  manufacturersDiscountsLoadingSelector,
  (showInactive, existData, loading) => ({
    showInactive,
    existData,
    loading,
  })
);

export const NewDiscountsFilter = ({
  checkedCustomers,
  handleConfirmCheckedItemsDialog,
  repPermissions,
}) => {
  const isAdmin = useAdmin();
  const {
    NewDiscountsFilter: { wrapper },
  } = cl;

  const disabledCreateEdit = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.discounts?.create_edit;
    }
  }, [repPermissions]);

  const { showInactive, existData, loading } = useSelector(selector);
  const dispatch = useDispatch();

  const [searchInput, setSearchInput] = useState("");
  const searchInputDebounced = useDebounce(searchInput, 500);

  const formChanged = useSelector(
    ({ confirmDialogs }) => confirmDialogs.formChanged
  );
  const editType = useSelector(({ confirmDialogs }) => confirmDialogs.editType);

  const handleShowInactive = (e, newVal) => {
    if (formChanged && editType === "checked_items") {
      handleConfirmCheckedItemsDialog(() =>
        dispatch(setShowInactiveDiscounts(newVal))
      );
      return;
    }
    dispatch(setShowInactiveDiscounts(newVal));
  };

  useEffect(() => {
    return () => {
      dispatch(resetDiscountsGetParamsAction());
    };
  }, [dispatch]);

  const handleDeleteDiscountsBulk = () => {
    if (checkedCustomers?.length) {
      const discountIds = checkedCustomers.map((discount) => discount?.id);
      dispatch(
        openConfirmDialogAction({
          title: "Delete selected discount(s)?",
          text: "Please confirm that you would like to delete selected discount(s)? This cannot be restored.",
          propBtns: {
            left: {
              sx: {
                color: "#6A6A6A",
                borderColor: "#D4D4D4",
                fontSize: "13px",
                height: "28px",
              },
              onClick: () => {
                dispatch(setConfirmIsOpenAction(false));
              },
              variant: "outlined",
              label: "Cancel",
            },

            right: {
              sx: {
                color: "#FFFFFF",
                fontSize: "13px",
                height: "28px",
                boxShadow: "none",
              },
              color: "primary2",
              onClick: () => {
                dispatch(bulkDeleteDiscountsAction(discountIds));
                dispatch(setConfirmIsOpenAction(false));
              },
              variant: "contained",
              label: "Confirm",
            },
          },
        })
      );
    }
  };

  const handleActiveInactiveBulk = (active) => {
    const titleActive = active === "enable" ? "Enable" : "Disable";
    if (checkedCustomers?.length) {
      const discountIds = checkedCustomers.map((discount) => discount?.id);
      dispatch(
        openConfirmDialogAction({
          title: `${titleActive} selected discount(s)?`,
          text: `Please confirm that you would like to ${active} selected discount(s)?`,
          propBtns: {
            left: {
              sx: {
                color: "#6A6A6A",
                borderColor: "#D4D4D4",
                fontSize: "13px",
                height: "28px",
              },
              onClick: () => {
                dispatch(setConfirmIsOpenAction(false));
              },
              variant: "outlined",
              label: "Cancel",
            },

            right: {
              sx: {
                color: "#FFFFFF",
                fontSize: "13px",
                height: "28px",
                boxShadow: "none",
              },
              color: "primary2",
              onClick: () => {
                dispatch(bulkActiveInactiveDicountsAction(discountIds));
                dispatch(setConfirmIsOpenAction(false));
              },
              variant: "contained",
              label: "Confirm",
            },
          },
        })
      );
    }
  };

  useEffect(() => {
    resetScrollHeight("discounts-scroll-table");
    dispatch(
      getManufacturersDiscountsParamsAction({
        search: searchInputDebounced,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInputDebounced, dispatch, showInactive]);

  return (
    <Box px={4} mt="12px">
      <Box sx={wrapper}>
        <ActiveTabs value={showInactive} onChange={handleShowInactive} />

        <FilterSearchTextField
          adminIsAllowed
          formSx={{ minWidth: "380px" }}
          placeholder="Search discounts by name"
          value={searchInput}
          loading={existData && loading}
          onChange={(e) => setSearchInput(e.target.value)}
          handleClearValue={() => setSearchInput("")}
          fullWidth
        />

        <ActionRestrictionWrapper
          disableHoverListener={!disabledCreateEdit}
          title={
            repPermissions?.role === "SALES"
              ? ADMIN_ONLY_VIEW_MESSAGE
              : ADMIN_ONLY_VIEW_MESSAGE_PAGE
          }
        >
          <SquareSelect
            disabled={disabledCreateEdit || isAdmin}
            value=""
            label="Bulk Actions"
            sx={{ backgroundColor: "white" }}
            formSx={{ width: "125px" }}
            radiused
            leftSidedIcon
            unfocused
            fullWidth
          >
            {showInactive ? (
              <MenuItem
                disabled={!checkedCustomers.length}
                onClick={() => handleActiveInactiveBulk("enable")}
              >
                Enable
              </MenuItem>
            ) : (
              <MenuItem
                disabled={!checkedCustomers.length}
                onClick={() => handleActiveInactiveBulk("disable")}
              >
                Disable
              </MenuItem>
            )}
            <MenuItem
              disabled={!checkedCustomers.length}
              value="Delete"
              sx={{ color: "#FF6254" }}
              onClick={handleDeleteDiscountsBulk}
            >
              Delete
            </MenuItem>
          </SquareSelect>
        </ActionRestrictionWrapper>
      </Box>
    </Box>
  );
};

NewDiscountsFilter.propTypes = {
  checkedCustomers: array,
  handleConfirmCheckedItemsDialog: func,
  repPermissions: object,
};
NewDiscountsFilter.defaultProps = { checkedCustomers: [] };
