import axios from "axios";

export const createCategoryService = async (data) => {
  return await axios.post("/categories", data).then((res) => res.data);
};

export const getCategoriesService = async (query) => {
  return await axios
    .get("/categories", { params: query })
    .then((res) => res.data);
};

export const updateCategoryByIdService = async (id, data) => {
  return await axios.put(`/categories/${id}`, data).then((res) => res.data);
};

export const getCategoryByIdService = async (id) => {
  return await axios
    .get(`/categories/with-products/${id}`)
    .then((res) => res.data);
};

export const deleteCategoryByIdService = async (id) => {
  return await axios.delete(`/categories/${id}`).then((res) => res.data);
};

export const undoDeleteCategoryService = async (id) => {
  return await axios.put(`/categories/undo/${id}`).then((res) => res.data);
};

export const checkCategoryInfoAvailabilityService = (params) => {
  return axios
    .get("categories/check-info-availability", { params })
    .then((res) => res.data);
};

export const getCategoriesByCustomerIdService = (id) => {
  return axios.get(`/categories/by-customer/${id}`).then((res) => res.data);
};
