import { bool, func, string } from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { CrossIcon, DangerIcon } from "components/Icons";
import { LargeCheckbox, StyledButton } from "components";
import { useState } from "react";

export const DeleteDialog = ({
  open = false,
  onClose = () => {},
  title = "",
  text = "",
  isShowIcon = false,
  onConfirm = () => {},
}) => {
  const [notifyAssignee, setNotifyAssignee] = useState(false);

  return (
    <Dialog
      sx={{
        ".MuiPaper-root": {
          borderRadius: "8px",
          boxShadow: "none",
          width: "100%",
          maxWidth: "420px",
        },
      }}
      open={open}
    >
      <DialogContent sx={{ p: 0, overflowY: "unset" }}>
        <Stack
          sx={{
            position: "relative",
            flexDirection: "row",
            alignItems: "center",
            gap: "8px",
            p: "16px",
          }}
        >
          {!!isShowIcon && <DangerIcon />}
          <Typography fontSize={18} fontWeight={400} color="#363531">
            {title}
          </Typography>
          <IconButton
            sx={{ position: "absolute", right: 8, top: 8 }}
            onClick={onClose}
          >
            <CrossIcon />
          </IconButton>
        </Stack>
      </DialogContent>

      <DialogContent sx={{ py: 0, px: "16px" }}>
        <Typography fontSize={13} fontWeight={300} color="#363531">
          {text}
        </Typography>
      </DialogContent>

      <DialogContent sx={{ py: 0, px: "16px", mt: "6px", pb: "8px" }}>
        <LargeCheckbox
          adminIsAllowed
          formSx={{ ml: "-8px", mr: 0 }}
          label={
            <Typography ml="0px" fontSize={13} fontWeight={400} color="#565656">
              Notify Assignees
            </Typography>
          }
          size={14}
          checked={notifyAssignee}
          onChange={(e) => setNotifyAssignee(e.target.checked)}
        />
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "6px",
          p: "16px",
          borderTop: "0.5px solid #CCC",
        }}
      >
        <StyledButton
          sx={{ width: "88px", height: "28px" }}
          label="Cancel"
          variant="outlined"
          color="cancel"
          onClick={onClose}
        />
        <StyledButton
          sx={{
            width: "88px",
            height: "28px",
            bgcolor: "#FF6969",
            "&:hover": {
              backgroundColor: "#FF6969",
            },
          }}
          label="Delete"
          variant="contained"
          onClick={() => onConfirm({ notifyAssignee })}
        />
      </DialogActions>
    </Dialog>
  );
};

DeleteDialog.propTypes = {
  open: bool,
  onClose: func,
  title: string,
  text: string,
  isShowIcon: bool,
  onConfirm: func,
};
