import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { object, func, bool } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import lodash from "lodash";
import { createSelector } from "reselect";

import { Box, Chip, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { FilterSearchTextField } from "../../../../../../components/TextFields/TextFields";

import { defaultFilters, FILTER_SWITCHES } from "./GalleryFilter.constants";
import FilterMenu from "../../../../../../components/FilterMenu";
import StyledButton from "../../../../../../components/StyledButton";
import { FilterIcon } from "../../../../../../components/Icons";

import {
  repsWithPhotoCountSelector,
  repsWithPhotoSelector,
} from "../../../../../../redux/selectors/gallery";
import { galleryGetParamsAction } from "../../../../../../redux/actions/gallery";
import {
  manufacturersCountSelector,
  manufacturersListSelector,
} from "../../../../../../redux/selectors/manufacturers";
import { getManufacturersAction } from "../../../../../../redux/actions/manufacturers";
import { getRepsAction } from "../../../../../../redux/actions/reps";

import {
  CUSTOM_FILTERS_TYPES,
  NAME_VIEW_DIALOGS,
  SCROLL_LIMIT,
} from "../../../../../../utils/constants";
import { normalizeSnakeCaseString } from "../../../../../../helpers/helpers";
import { useDebounce } from "../../../../../../helpers/hooks";

import { cl } from "./GalleryFilter.styles";
import { useTagsActions } from "helpers/useTagsActions";
import { useCustomFilterCreate } from "helpers/useCustomFilterCreate";
import {
  checkForDefaultFilter,
  handleSetSwitchesForEdit,
} from "helpers/filters";
import {
  CustomFilterMenu,
  FilterViewSaveDialog,
  ProductsPurchased,
} from "components";
import { setSelectedFilterIdAction } from "redux/actions/savedFilters";
import { PurchasedProductsList } from "Pages/CustomersPage/components/CustomersTab/components/TabsContent/components/NewCustomersFilter/components/PurchasedProductsFilter/PurchasedProductsList";
import { useProducts } from "helpers/useProducts";

const selector = createSelector(
  manufacturersListSelector,
  manufacturersCountSelector,
  repsWithPhotoSelector,
  repsWithPhotoCountSelector,
  (
    manufacturersList,
    manufacturersCount,
    repsWithPhoto,
    repsWithPhotoCount
  ) => ({
    manufacturersList,
    manufacturersCount,
    repsWithPhoto,
    repsWithPhotoCount,
  })
);

export const GalleryFilter = ({
  setPage,
  currentUser,
  loading,
  setCheckedPhotos = null,
}) => {
  const {
    manufacturersList,
    manufacturersCount,
    repsWithPhoto,
    repsWithPhotoCount,
  } = useSelector(selector);
  const {
    GalleryFilter: { wrapper, textFieldWrapper, chip, chipKey, filterBtn },
  } = cl;
  const dispatch = useDispatch();
  const filterAnchor = useRef();

  const [isFilterChanged, setIsFilterChanged] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const searchInputDebounced = useDebounce(searchInput, 500);
  const [filterFields, setFilterFields] = useState(defaultFilters);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [switches, setSwitches] = useState(FILTER_SWITCHES);
  const [isOpenedPurchasedPopup, setOpenPurchasedPopup] = useState(false);

  const filterChipKeys = useMemo(
    () =>
      Object.keys(filterFields).filter((key) => {
        if (
          lodash.isArray(filterFields[key]) &&
          lodash.isEmpty(filterFields[key])
        )
          return;
        return filterFields[key];
      }),

    [filterFields]
  );

  const handleApplyFilter = (newSwitches, newFields) => {
    setFilterMenuOpen(false);
    setSwitches([...newSwitches]);
    setFilterFields({ ...newFields });
    setPage(1);
  };

  const handleDeleteFilter = useCallback(
    (key) => {
      const newState = switches;
      const index = switches.findIndex((s) => s.value === key);
      const insert = {
        ...switches[index],
        checked: false,
      };
      newState.splice(index, 1, insert);
      setSwitches([...newState]);
      setFilterFields((prev) => {
        return {
          ...prev,
          [key]: lodash.isArray(defaultFilters[key]) ? [] : "",
        };
      });
      setPage(1);
      dispatch(setSelectedFilterIdAction(null));
    },
    [dispatch, setPage, switches]
  );

  const [limitManufacturers, setLimitManufacturers] = useState(
    2 * SCROLL_LIMIT
  );

  const [manufacturersState, setManufacturersState] = useState({
    search: "",
    searcheble: false,
  });

  const handleFetchManufacturers = useCallback(() => {
    dispatch(
      getManufacturersAction({
        limit: limitManufacturers,
        search: manufacturersState.search,
      })
    );
    setManufacturersState((prev) => ({
      ...prev,
      searcheble: !prev.searcheble ? manufacturersCount >= 10 : prev.searcheble,
    }));
    setLimitManufacturers(limitManufacturers + SCROLL_LIMIT);
  }, [
    dispatch,
    limitManufacturers,
    manufacturersState.search,
    manufacturersCount,
  ]);

  useEffect(() => {
    handleFetchManufacturers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manufacturersState.search]);

  const [repsState, setRepsState] = useState({ search: "", searcheble: false });

  const handleFetchRepresentatives = useCallback(() => {
    const fetchQuery = {
      limit: SCROLL_LIMIT,
      cursor: repsWithPhoto?.[repsWithPhoto.length - 1]?.id,
      search: repsState.search,
    };
    dispatch(getRepsAction(fetchQuery, { isScrolling: true }));
    setRepsState((prev) => ({
      ...prev,
      searcheble: !prev ? repsWithPhotoCount >= 10 : prev.searcheble,
    }));
  }, [dispatch, repsWithPhoto, repsState.search, repsWithPhotoCount]);

  useEffect(() => {
    handleFetchRepresentatives();
  }, [repsState.search, handleFetchRepresentatives]);

  const {
    list: productTagsList,
    count: productTagsCount,
    handleFetchTags: handleFetchProductTags,
    page: tagsPage,
    setTagsParams,
    searcheble,
  } = useTagsActions({
    type: "product",
    params: {
      used_on_photo_groups: true,
      sort_by_tag: "asc",
    },
    open: filterMenuOpen,
  });

  useEffect(() => {
    setIsFilterChanged(true);
    if (isFilterChanged) return;

    checkForDefaultFilter({
      type: CUSTOM_FILTERS_TYPES.GALLERY,
      list: currentUser.customFilters,
      setFilters: setFilterFields,
      setSwitches,
      switchesList: switches,
      dispatchFunc: (id) => dispatch(setSelectedFilterIdAction(id)),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.customFilters]);

  const {
    handleApplyPurchasedProducts,
    selectedProducts,
    handleRemoveProduct,
    handleProductIds,
  } = useProducts({ switches, filterFields });

  const selectMatchData = useMemo(
    () => [
      {
        id: "date",
        label: "Select Date",
      },
      {
        id: "manufacturer_id",
        childrenList: manufacturersList,
        handleFetch: handleFetchManufacturers,
        setParams: setManufacturersState,
        dataCount: manufacturersCount,
        dataLength: manufacturersList?.length,
        label: "Select Manufacturer",
        searcheble: manufacturersState.searcheble,
      },
      {
        id: "representative_duplicate_id",
        childrenList: repsWithPhoto,
        handleFetch: handleFetchRepresentatives,
        setParams: setRepsState,
        dataCount: repsWithPhotoCount,
        dataLength: repsWithPhoto?.length,
        label: "Select Representative",
        searcheble: repsState.searcheble,
      },
      {
        id: "tags",
        childrenList: productTagsList.map((el) => ({
          ...el,
          name: el.tag,
        })),
        handleFetch: () => handleFetchProductTags(tagsPage + 1),
        setParams: setTagsParams,
        dataCount: productTagsCount,
        dataLength: productTagsList?.length,
        label: "Select Tags",
        searcheble,
      },
      {
        id: "selectedProducts",
        label: "Products Selected",
        elementProps: {
          handleOpen: () => setOpenPurchasedPopup(true),
          productsList: selectedProducts,
          handleRemoveProduct: handleRemoveProduct,
          type: "selected",
        },
        Element: PurchasedProductsList,
      },
    ],
    [
      handleFetchManufacturers,
      handleFetchProductTags,
      handleFetchRepresentatives,
      manufacturersCount,
      manufacturersList,
      productTagsCount,
      productTagsList,
      repsWithPhoto,
      repsWithPhotoCount,
      tagsPage,
      searcheble,
      manufacturersState.searcheble,
      repsState.searcheble,
      selectedProducts,
      handleRemoveProduct,
      setTagsParams,
    ]
  );

  useEffect(
    () => {
      if (!isFilterChanged) return;

      const setdate =
        typeof filterFields?.date?.value === "string"
          ? { date: lodash.snakeCase(filterFields?.date?.value) }
          : { ...filterFields?.date?.value };

      setPage(1);

      dispatch(
        galleryGetParamsAction({
          search: searchInputDebounced,
          manufacturer_id: filterFields.manufacturer_id.value ?? "",
          representative_duplicate_id:
            filterFields.representative_duplicate_id.value ?? "",
          reorder_required: filterFields.reorder_required
            ? filterFields.reorder_required === "yes"
            : "",
          tags: filterFields?.tags?.length
            ? JSON.stringify(filterFields?.tags?.map(({ value }) => value))
            : [],
          products_ids: filterFields?.selectedProducts?.value?.length
            ? JSON.stringify(
                handleProductIds(filterFields?.selectedProducts?.value)
              )
            : "",
          page: 1,
          ...setdate,
        })
      );

      !!setCheckedPhotos && setCheckedPhotos([]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterFields, dispatch, searchInputDebounced, setPage, isFilterChanged]
  );

  const onCustomFilterApply = useCallback((filters) => {
    setFilterFields(filters);
    setSwitches(handleSetSwitchesForEdit(filters, FILTER_SWITCHES));
  }, []);

  const {
    onCloseFilterDialog,
    onFilterViewSave,
    onOpenFilterSaveDialog,
    isOpenedMenu,
  } = useCustomFilterCreate({
    createFilterCallback: onCustomFilterApply,
    menuCallback: () => setFilterMenuOpen(false),
    viewTypeKey: NAME_VIEW_DIALOGS.GALLERY,
    filterType: CUSTOM_FILTERS_TYPES.GALLERY,
  });

  return (
    <Box sx={wrapper}>
      {isOpenedMenu && (
        <FilterViewSaveDialog
          open={isOpenedMenu}
          onClose={onCloseFilterDialog}
          handleSave={onFilterViewSave}
        />
      )}
      <ProductsPurchased
        isOpen={isOpenedPurchasedPopup}
        checkedList={selectedProducts}
        handleClose={() => setOpenPurchasedPopup(false)}
        handleApplyPurchasedProducts={handleApplyPurchasedProducts}
        type="selected"
      />
      <FilterSearchTextField
        adminIsAllowed
        formSx={{ minWidth: "380px" }}
        placeholder="Search by customer name"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        handleClearValue={() => setSearchInput("")}
        fullWidth
        loading={loading}
        adornmentProps={{
          sx: {
            justifyContent: "flex-end",
          },
        }}
        customAdornment={
          <Box sx={textFieldWrapper}>
            {filterChipKeys.map((key) => (
              <Chip
                key={key}
                sx={chip}
                size="small"
                label={
                  <Typography
                    sx={{ fontSize: "13px", fontWeight: 500 }}
                    color="groundLighter.main"
                  >
                    {lodash.isArray(filterFields[key]) ? (
                      <>
                        {normalizeSnakeCaseString(
                          filterFields[key][0]?.label || key
                        )}
                        :{" "}
                        <span style={{ color: "#5F6368" }}>
                          {filterFields[key]
                            .map(({ name }) => normalizeSnakeCaseString(name))
                            .join(", ")}
                        </span>
                      </>
                    ) : (
                      <>
                        {normalizeSnakeCaseString(
                          filterFields[key]?.label || key
                        )}
                        :{" "}
                        <span style={{ color: "#5F6368" }}>
                          {normalizeSnakeCaseString(
                            filterFields[key]?.name || filterFields[key]
                          )}
                        </span>
                      </>
                    )}
                  </Typography>
                }
                deleteIcon={<CloseIcon size="15" style={{ fill: "#707070" }} />}
                onMouseDown={(e) => e.stopPropagation()}
                onDelete={() => handleDeleteFilter(key)}
              />
            ))}
          </Box>
        }
      />

      <CustomFilterMenu
        {...{
          selectMatchData,
          type: CUSTOM_FILTERS_TYPES.GALLERY,
          switches: FILTER_SWITCHES,
          onFilterApply: onCustomFilterApply,
          resetFilterFunc: () => {
            setFilterFields(defaultFilters);
            setSwitches(FILTER_SWITCHES);
          },
        }}
      />

      <StyledButton
        label="Filter"
        startIcon={
          filterChipKeys.length ? (
            <Typography sx={chipKey}>{filterChipKeys.length}</Typography>
          ) : (
            <FilterIcon />
          )
        }
        variant="outlined"
        ref={filterAnchor}
        color="edit"
        sx={filterBtn}
        fontSize="15px"
        onClick={() => setFilterMenuOpen(true)}
        data-testid="filter-button"
      />

      <FilterMenu
        anchorEl={filterAnchor.current}
        open={filterMenuOpen}
        onClose={() => setFilterMenuOpen(false)}
        filterFields={filterFields}
        setFilterFields={setFilterFields}
        switches={switches}
        handleApplyFilter={handleApplyFilter}
        selectMatchData={selectMatchData}
        onViewSave={onOpenFilterSaveDialog}
      />
    </Box>
  );
};

GalleryFilter.propTypes = {
  setPage: func,
  currentUser: object,
  loading: bool,
  setCheckedPhotos: func,
};
