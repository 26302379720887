export const STATE_NAMES = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FM: "Federated States Of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MH: "Marshall Islands",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PW: "Palau",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export const addressFieldOptions = {
  selectProps: {
    color: "rgb(95, 98, 103);",
    height: "43px",
    fontSize: "13px",
    labelSx: {
      fontSize: "13px",
      top: 1,
      color: "#666666",
    },
  },
  autocompleteProps: {
    InputProps: {
      placeholder: "",
      sx: {
        fontSize: "13px",
        height: "43px",
        "& input": {
          animationName: "none!important",
          color: "#5F6267 !important",
        },
      },
    },
    InputLabelProps: {
      sx: {
        mt: "2px",
        fontSize: "13px",
        color: "#5F6267",
      },
    },
    noErrorMessage: false,
  },
  mapButtonProps: {
    height: "43px",
    borderRadius: "0 4px 4px 0",
    borderWidth: "1px",
  },
  textFieldProps: {
    InputProps: {
      size: "small",
      sx: {
        height: "43px",
        fontSize: "16px",
      },
    },
    size: "small",
    InputLabelProps: {
      sx: {
        fontSize: "16px",
        top: 0,
        color: "#666666",
      },
    },
  },
  noToggleLabel: true,
  gap: "0px",
  hideUnit: true,
  curtainProps: { pt: "18px", curtainHeight: "244px" },
  addressFieldsWrapperProps: {
    pb: "16px",
    mb: "25px",
  },
};
