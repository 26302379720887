import { string } from "prop-types";

import Box from "@mui/material/Box";
import { CategoriesFilter, CategoriesList } from "./components";
import { AddCategoryDrawer } from "./components/AddCategoryDrawer";

export const CategoriesTab = () => {
  return (
    <Box p="20px 32px" height="100%">
      <CategoriesFilter />
      <CategoriesList />
      <AddCategoryDrawer />
    </Box>
  );
};

CategoriesTab.propTypes = {
  currentTab: string,
};
export default CategoriesTab;
