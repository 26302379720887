import { func, bool } from "prop-types";

import { StyledButton } from "components";

import { Stack } from "@mui/material";

export const DrawerFooter = ({
  onClose,
  loading,
  isEdit,
  disabledSaveButton,
  onDelete,
}) => {
  return (
    <Stack
      direction="row"
      sx={{
        mt: "auto",
        width: "100%",
        height: "80px",
        justifyContent: "flex-end",
        alignItems: "center",
        borderTop: "0.5px #D5D9D9 solid",
        backgroundColor: "#F8F8F8",
        p: "24px 32px",
        gap: "8px",
      }}
    >
      {isEdit && (
        <StyledButton
          disabled={loading}
          label="Delete"
          sx={{ height: "32px", minWidth: "88px", mr: "auto" }}
          variant="outlinedError"
          onClick={onDelete}
          fontSize="15px"
        />
      )}
      <StyledButton
        disabled={loading}
        label="Cancel"
        sx={{ height: "32px", minWidth: "88px" }}
        color="cancel"
        onClick={onClose}
        fontSize="15px"
      />
      <StyledButton
        disabled={disabledSaveButton || loading}
        label={isEdit ? "Save" : "Add"}
        type="submit"
        variant="contained"
        sx={{
          height: "32px",
          minWidth: "88px",
          "&.Mui-disabled": {
            backgroundColor: "#47A06D !important",
            color: "#ffff",
            opacity: 0.5,
          },
        }}
        fontSize="15px"
      />
    </Stack>
  );
};

DrawerFooter.propTypes = {
  onClose: func,
  onDelete: func,
  loading: bool,
  isEdit: bool,
  disabledSaveButton: bool,
};
