import { isEqual } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { getTagsService } from "services/tags";
import { SCROLL_LIMIT } from "utils/constants";
import { error } from "utils/notifications";

const init = {
  loading: false,
  list: [],
  count: 0,
  firstFetch: false,
  searcheble: false,
};

export const TAG_TYPES = {
  customer: "customer",
  product: "product",
  order: "order",
};

export const useTagsActions = ({ type, params, open = true }) => {
  const [tagsState, setTagsState] = useState(init);

  const paramRef = useRef(null);

  const [tagsParams, setTagsParams] = useState({
    page: 1,
    limit: SCROLL_LIMIT,
    ...params,
  });
  const handleFetchTags = useCallback(
    async (page) => {
      if (!open || tagsState.loading) return;

      setTagsState((prev) => ({ ...prev, loading: true }));

      const reqParams = {
        ...tagsParams,
        page: page || tagsParams.page,
      };

      if (isEqual(paramRef?.current, reqParams))
        return setTagsState((prev) => ({ ...prev, loading: false }));

      paramRef.current = reqParams;

      try {
        const res = await getTagsService(type, reqParams);

        const rows = res?.rows || [];
        const count = res?.count || 0;

        setTagsState((prev) => ({
          ...prev,
          list: page > 1 ? [...prev.list, ...rows] : rows,
          count: count,
          searcheble: !prev.searcheble ? count >= 10 : prev.searcheble,
        }));

        if (page) setTagsParams((prev) => ({ ...prev, page }));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong.");
      } finally {
        setTagsState((prev) => ({ ...prev, loading: false }));
      }
    },
    [tagsParams, type, open, tagsState.loading]
  );

  useEffect(() => {
    if (open) {
      handleFetchTags(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, tagsParams.search]);

  return {
    ...tagsState,
    setTagsParams,
    page: tagsParams.page,
    handleFetchTags,
  };
};
