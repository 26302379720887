import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import PaytermsTab from "./components/SettingsTabs/PaytermsTab/PaytermsTab";
import SettingsSwitcherTab from "./components/SettingsTabSwitch/SettingsSwitcherTab";
import { Box } from "@mui/material";
import {
  getDistributorNotificationsService,
  updateDistributorNotificationsService,
} from "../../services/notifications";
import { error } from "../../utils/notifications";
import NewProfile from "./components/SettingsTabs/ProfileTab/NewProfile";
import { useDispatch, useSelector } from "react-redux";
import { openDiscardChanges } from "../../redux/actions/confirmDialogs";
import {
  CustomersTab,
  ImportExportTab,
  IntegrationsTab,
  InventoryTab,
  OrderDirectTab,
  OrdersTab,
  PaymentsTab,
  RepsTab,
} from "./components/SettingsTabs";
import { useSettings } from "./SettingsPage.hooks";
import { useAdmin } from "helpers/helpers";
import { ORDERS_TABS, ORDER_DIRECT_TABS } from "./SettingsPage.constants";
import { confirmDialogFormChangedSelector } from "redux/selectors/confirmDialogs";
import { createSelector } from "reselect";
import { currentUserSelector } from "redux/selectors/auth";
import { useRepsPermissions } from "helpers/hooks";
import { useBreakpoint } from "helpers/useBreakpoint";

export const ImportContext = createContext();

export const defaultImportState = {
  fileErrorMessage: null,
  isImport: false,
  step: 0,
  uploading: false,
  uploadingProgress: 0,
  sessionsListLoading: true,
  fileUploadSuccess: false,
  file: null,
  schemas: [],
  uploadSession: null,
  currentSession: null,
  confirmLoading: false,
  mappedCols: {},
  reverseMappedCols: {},
  records: [],
  status: "",
  countFetched: false,
  rowsCount: 0,
  errorsCount: 0,
  importType: {},
  allSchemas: [],
  retryLoading: false,
};

const selector = createSelector(
  confirmDialogFormChangedSelector,
  currentUserSelector,
  (formChanged, currentUser) => ({
    formChanged,
    currentUser,
  })
);

const SettingsPage = () => {
  const { SETTINGS_TABS } = useSettings();

  const breakpoint = useBreakpoint();

  const repPermissions = useRepsPermissions();

  const isAdmin = useAdmin();
  const [currentTab, setCurrentTab] = useState(SETTINGS_TABS[0].value);

  const [currentOrdersTab, setCurrentOrdersTab] = useState(
    ORDERS_TABS[0].value
  );
  const [currentOrderDirectTab, setCurrentOrderDirectTab] = useState(
    ORDER_DIRECT_TABS.value
  );

  const [searchParams] = useSearchParams();
  const [notifications, setNotifications] = useState([]);
  const [notificationsLoading, setNotificationsLoading] = useState(false);
  const [importData, setImportData] = useState(defaultImportState);

  const switchTabByQuery = () => {
    const queryTab = searchParams.get("tab");
    if (queryTab) setCurrentTab(queryTab);
  };

  useEffect(switchTabByQuery, [searchParams]);

  const { formChanged, currentUser } = useSelector(selector);

  const dispatch = useDispatch();
  const { state } = useLocation();

  useEffect(() => {
    const { tab, type } = state || {};
    if (!tab) return;
    setCurrentTab(tab);
    if (type !== "onboarding" && tab === "Import/Export")
      setImportData((prev) => ({ ...prev, isImport: true }));
  }, [state]);

  useEffect(() => {
    if (repPermissions) {
      if (!repPermissions?.settings?.representatives) return;
    }

    setNotificationsLoading(true);
    getDistributorNotificationsService()
      .then((res) => {
        setNotificationsLoading(false);
        setNotifications(res);
      })
      .catch(() => {
        setNotificationsLoading(false);
        error("Can't get notifications.");
      });
  }, [repPermissions]);

  const handleOpenDiscardChanges = useCallback(
    (onDiscard) => {
      dispatch(openDiscardChanges(onDiscard));
    },
    [dispatch]
  );

  const handleUpdateNotifications = useCallback(
    (id, data) => {
      if (isAdmin) return;
      setNotificationsLoading(true);
      updateDistributorNotificationsService(id, data)
        .then((res) => {
          const index = notifications.findIndex(
            (notification) => notification.role === res.role
          );
          const newNotifications = [...notifications];
          newNotifications.splice(index, 1, res);
          setNotificationsLoading(false);
          setNotifications([...newNotifications]);
        })
        .catch((err) => {
          error(err?.response?.data?.message || "Something went wrong.");
          setNotificationsLoading(false);
        });
    },
    [isAdmin, notifications]
  );

  const settingsPage = useMemo(() => {
    switch (currentTab) {
      case SETTINGS_TABS[0].value:
        return <NewProfile />;
      // <ProfileTab />;
      case SETTINGS_TABS[1].value:
        return <PaymentsTab />;
      case SETTINGS_TABS[2].value:
        return (
          <RepsTab
            notifications={notifications}
            updateNotifications={handleUpdateNotifications}
            timeZone={currentUser?.timeZone}
          />
        );
      case SETTINGS_TABS[3].value:
        return (
          <OrdersTab
            currentTab={currentOrdersTab}
            setCurrentTab={setCurrentOrdersTab}
          />
        );
      case SETTINGS_TABS[4].value:
        return <InventoryTab />;
      case SETTINGS_TABS[5].value:
        return <PaytermsTab locationState={state} />;
      case SETTINGS_TABS[6].value:
        return <ImportExportTab />;
      case SETTINGS_TABS[7].value:
        return <IntegrationsTab />;
      case SETTINGS_TABS[8].value:
        return (
          <OrderDirectTab
            currentTab={currentOrderDirectTab}
            setCurrentTab={setCurrentOrderDirectTab}
            {...{ breakpoint }}
          />
        );
      case SETTINGS_TABS[9].value:
        return <CustomersTab />;
      default:
        <></>;
    }
  }, [
    currentTab,
    SETTINGS_TABS,
    notifications,
    handleUpdateNotifications,
    currentUser?.timeZone,
    currentOrdersTab,
    state,
    currentOrderDirectTab,
    breakpoint,
  ]);

  useEffect(() => {
    setImportData(defaultImportState);
  }, [currentTab]);

  const setStyles = useMemo(() => {
    let styles = {
      backgroundColor: "#ffffff",
      border: "1px solid #d5d9d9",
      borderRadius: "8px",
      width: "1126px",
      minHeight: "calc(100vh - 210px)",
    };

    if (currentTab === "Import/Export" && importData.isImport) {
      styles.width = "100%";
      styles.mx = 4;
    }

    if (currentTab === "Order Direct") {
      styles = {};
    }

    return styles;
  }, [currentTab, importData.isImport]);

  return (
    <ImportContext.Provider value={{ importData, setImportData }}>
      <SettingsSwitcherTab
        tabs={SETTINGS_TABS}
        currentTab={currentTab}
        handleSetCurrentTab={(tab) => {
          if (formChanged)
            return handleOpenDiscardChanges(() => setCurrentTab(tab));
          setCurrentTab(tab);
        }}
        loading={notificationsLoading}
        goBack={!!state?.goBack}
        // locationState={state}
        showAddTagBtn={currentOrdersTab === ORDERS_TABS[1].value}
      />
      <Box
        sx={{
          paddingTop: "33px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box sx={{ ...setStyles }}>{settingsPage}</Box>
      </Box>
      <Outlet />
    </ImportContext.Provider>
  );
};

export default SettingsPage;
