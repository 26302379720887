import { GlobalPageContext } from "Pages/MasterPage/MasterPage";
import { useCallback, useContext, useEffect, useState } from "react";
import { getLiteProductsService } from "services/products";
import { error } from "utils/notifications";
import { containsEvery } from "./helpers";

const DEFAULT_PARAMS = {
  status: '["active", "inactive_for_customers"]',
};

export const useCategoryProducts = ({ productsList, params }) => {
  const { setGlobalLoading } = useContext(GlobalPageContext);

  const [openAllProducts, setOpenAllProducts] = useState(false);
  const [isAllUnchecked, setIsAllUnchecked] = useState(true);

  const [productsState, setProductsState] = useState({
    availableProducts: [],
    tempCheckedProducts: [],
    changedIds: [],
  });

  const onProductsStateUpdate = useCallback(
    (data) => setProductsState((prev) => ({ ...prev, ...data })),
    [setProductsState]
  );

  useEffect(() => {
    onProductsStateUpdate({
      changedIds: [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllUnchecked]);

  const handleFetchProducts = useCallback(async () => {
    setGlobalLoading(true);

    const preparedParams = {
      ...params,
      ...DEFAULT_PARAMS,
    };

    try {
      const res = await getLiteProductsService(preparedParams);
      const rows = res || [];

      onProductsStateUpdate({ tempCheckedProducts: rows });
      setOpenAllProducts(true);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    } finally {
      setGlobalLoading(false);
    }
  }, [onProductsStateUpdate, params, setGlobalLoading]);

  const onTempProductSelect = useCallback(
    (product) => {
      if (product.isMultiple) {
        const filteredChildren = product.childProducts.filter(
          (childProduct) =>
            !productsList?.some((prod) => prod.id === childProduct.id)
        );
        if (
          containsEvery(filteredChildren, productsState.tempCheckedProducts)
        ) {
          const newChecked = [...productsState.tempCheckedProducts];
          product.childProducts.forEach((childProduct) => {
            const index = newChecked.findIndex(
              (checkedProduct) => checkedProduct.id === childProduct.id
            );
            newChecked.splice(index, 1);
          });

          const updatedExcludeIds = [
            ...productsState.changedIds,
            ...product.childProducts.map((child) => child.id),
          ];
          onProductsStateUpdate({
            changedIds: [...new Set(updatedExcludeIds)],
          });
          return onProductsStateUpdate({
            tempCheckedProducts: [...newChecked],
          });
        }

        const addProducts = filteredChildren.map((childProduct) => ({
          ...childProduct,
          quantity: 1,
          parentProduct: product,
        }));
        const uniqueProducts = [
          ...new Map(
            [...productsState.tempCheckedProducts, ...addProducts].map(
              (item) => [item["id"], item]
            )
          ).values(),
        ];

        const updatedExcludeIds = productsState.changedIds.filter(
          (id) => !addProducts.some((childProduct) => childProduct.id === id)
        );
        onProductsStateUpdate({ changedIds: updatedExcludeIds });
        return onProductsStateUpdate({ tempCheckedProducts: uniqueProducts });
      }

      const index = productsState.tempCheckedProducts.findIndex(
        (item) => item.id === product.id
      );

      if (index > -1) {
        onProductsStateUpdate({
          changedIds: [...new Set([...productsState.changedIds, product.id])],
        });

        const newProducts = productsState.tempCheckedProducts.filter(
          (item) => item.id !== product.id
        );

        return onProductsStateUpdate({ tempCheckedProducts: newProducts });
      }

      onProductsStateUpdate({
        changedIds: productsState.changedIds.filter((id) => id !== product.id),
      });
      onProductsStateUpdate({
        tempCheckedProducts: [...productsState.tempCheckedProducts, product],
      });
    },
    [
      productsState.tempCheckedProducts,
      onProductsStateUpdate,
      productsState.changedIds,
      productsList,
    ]
  );

  useEffect(() => {
    onProductsStateUpdate({
      tempCheckedProducts: productsList,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsList]);

  const checkForProductChange = () => {
    if (
      (!productsList?.length && productsState.tempCheckedProducts?.length) ||
      (productsList?.length && !productsState.tempCheckedProducts?.length)
    )
      return true;
    if (!productsState.tempCheckedProducts?.length && !productsList?.length)
      return false;
    const tempProductsIds = productsState.tempCheckedProducts?.map(
      (product) => product?.product?.id || product?.id
    );
    const productsIds = productsList?.filter((product) =>
      tempProductsIds?.includes(product.id || product.product?.id)
    );
    return !!productsIds?.length;
  };

  const onProductsPopupOpen = () => {
    setOpenAllProducts(true);
  };

  const onCloseAllProducts = () => setOpenAllProducts(false);

  return {
    ...productsState,
    handleFetchProducts,
    onProductsPopupOpen,
    openAllProducts,
    onCloseAllProducts,
    onTempProductSelect,
    onProductsStateUpdate,
    isAllUnchecked,
    setIsAllUnchecked,
    checkForProductChange,
  };
};
