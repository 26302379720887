import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { string } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";

import TabMapComponent from "../CustomersPage/components/CustomersTab/components/TabsContent/TabMapComponent";
import { FiltersBlock, HeaderBlock, StopsTable } from "./components";
import AllCustomersPopup from "./components/AllCustomersPopup";
import RouteWarning from "./components/RouteWarning";
import {
  defaultValues,
  FILTER_FIELDS_INIT,
  iconSort,
  PREFIXES,
  SWITCHES_INIT,
} from "./CreateRoutePage.constants";
import { validationSchema } from "./CreateRoutePage.validations";
import useStyles from "./styles";
import { error, success } from "utils/notifications";

import { Loader } from "components";
import {
  checkRouteAvailabilityService,
  getRouteByIdService,
} from "services/routes";
import {
  setEditTypeAction,
  setFormChangedAction,
} from "redux/actions/confirmDialogs";
import { useRoute } from "./Routes.hooks";
import EditCustomerPopup from "Pages/OrdersPage/components/EditCustomerPopup/EditCustomerPopup";
import { updateCustomerService } from "services/customers";
import { normalizeSnakeCaseString } from "helpers/helpers";
import { useDebounce } from "helpers/hooks";
import { UNCATEGORIZED_TERRITORY } from "utils/constants";
import { isArray, isEmpty, snakeCase } from "lodash";

const CreateRoutePage = ({ navigatePath, navigateState }) => {
  const classes = useStyles();
  const { state: locationState } = useLocation();

  const currentUser = useSelector(({ auth }) => auth?.currentUser);

  const filterAnchor = useRef();

  const [filterMenuOpen, setFilterMenuOpen] = useState(false);

  const [searchInput, setSearchInput] = useState("");
  const searchInputDebounced = useDebounce(searchInput, 600);
  const [filterFields, setFilterFields] = useState(FILTER_FIELDS_INIT);
  const [switches, setSwitches] = useState(SWITCHES_INIT);
  const [showDateChangeConfirm, setShowDateChangeConfirm] = useState(false);

  const defaultDate = useRef();

  const filterChipKeys = useMemo(
    () =>
      Object.keys(filterFields).filter((key) => {
        if (isArray(filterFields[key]) && isEmpty(filterFields[key])) return;

        return filterFields[key];
      }),
    [filterFields]
  );

  const params = useMemo(() => {
    const {
      sort_by,
      representative_id,
      territory_id,
      group_id,
      tag_id,
      last_visit,
      last_order,
      issues,
      tasks_due,
      has_assigned_routes,
    } = filterFields || {};

    const sort = sort_by ? sort_by.value.split(" ") : null;

    let removeSort = null;

    if (sort?.[0] === "sort_name") removeSort = "sort_orders";
    else removeSort = "sort_name";

    const setHasAssignedRoutesValue = (value) => {
      if (value === "ASSIGNED") return true;
      if (value === "NOT_ASSIGNED") return false;
      return undefined;
    };

    return {
      search: searchInputDebounced,
      representative_id: representative_id?.value ?? "",
      territory_id:
        territory_id?.value === UNCATEGORIZED_TERRITORY.id
          ? "null"
          : territory_id?.value,
      parent_customer_id: group_id?.value,
      tag_ids: tag_id?.length
        ? JSON.stringify(tag_id?.map(({ value }) => value))
        : [],
      [removeSort]: null,
      [sort?.[0]]: sort?.[1],
      last_visit: last_visit?.value?.start_date
        ? undefined
        : snakeCase(last_visit?.value),
      last_visit_than: ["more_than", "less_than"].includes(last_visit?.value)
        ? last_visit.days || 0
        : undefined,
      last_visit_start_date: last_visit?.value?.start_date,
      last_visit_end_date: last_visit?.value?.end_date,
      last_order: last_order?.value?.start_date
        ? undefined
        : snakeCase(last_order?.value),
      last_order_than: ["more_than", "less_than"].includes(last_order?.value)
        ? last_order.days || 0
        : undefined,
      last_order_start_date: last_order?.value?.start_date,
      order_direct: !filterFields?.order_direct
        ? null
        : JSON.stringify([filterFields?.order_direct.value]),
      last_order_end_date: last_order?.value?.end_date,
      with_missing_info: issues?.value === "with_missing_info" || undefined,
      with_duplicated_info:
        issues?.value === "with_duplicated_info" || undefined,

      tasks_due: tasks_due?.value?.start_date
        ? undefined
        : snakeCase(tasks_due?.value),
      tasks_due_start_date: tasks_due?.value?.start_date,
      tasks_due_end_date: tasks_due?.value?.end_date,
      has_assigned_routes: setHasAssignedRoutesValue(has_assigned_routes),
    };
  }, [searchInputDebounced, filterFields]);

  const handleApplyFilter = (newSwitches, newFields) => {
    setFilterMenuOpen(false);
    setSwitches([...newSwitches]);
    setFilterFields({ ...newFields });
  };

  const getChipLabel = (key) => {
    const { days, name, value, iconId, label } = filterFields[key] || {};
    const icon = iconSort[iconId];
    const isArr = isArray(filterFields[key]);

    const prefix = PREFIXES[value];
    return (
      <Box display="flex" alignItems="center" gap="6px">
        <Typography
          sx={{ fontSize: "13px", fontWeight: 500 }}
          color="groundLighter.main"
        >
          {isArr ? (
            <>
              {normalizeSnakeCaseString(filterFields[key][0]?.label || key)}:{" "}
              <span style={{ color: "#5F6368" }}>
                {filterFields[key]
                  .map(({ name }) => normalizeSnakeCaseString(name))
                  .join(", ")}
              </span>
            </>
          ) : (
            <>
              {normalizeSnakeCaseString(label || key)}:{" "}
              {!icon && (
                <span style={{ color: "#5F6368", marginTop: !!icon && "5px" }}>
                  {prefix
                    ? `${prefix} ${days || 0} days`
                    : normalizeSnakeCaseString(name || filterFields[key])}
                </span>
              )}
            </>
          )}
        </Typography>
        {icon}
      </Box>
    );
  };

  const handleDeleteFilter = (key) => {
    const newState = switches;
    const index = switches.findIndex((s) => s.value === key);
    const insert = {
      ...switches[index],
      checked: false,
    };
    newState.splice(index, 1, insert);
    setSwitches([...newState]);
    setFilterFields((prev) => {
      return { ...prev, [key]: "" };
    });
  };

  const duplicateId = useMemo(
    () => locationState?.duplicateId,
    [locationState?.duplicateId]
  );

  const checkedCustomers = useMemo(
    () => locationState?.checkedCustomers,
    [locationState?.checkedCustomers]
  );

  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    trigger,
    setValue,
    setError,
    clearErrors,
    reset,
    formState: { isDirty },
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(validationSchema(currentUser?.timeZone)),
  });

  const formField = useWatch({ control });

  const customersByMissingInfoCount =
    formField?.stops?.filter(
      (c) => c.missingFields && c.missingFields.includes("Shipping address")
    )?.length || 0;

  const { routeId, onSubmit, customersState, setCustomersState } = useRoute();

  const [shapes, setShapes] = useState([]);

  const handleAddCustomers = (stops) => {
    setValue("stops", stops, { shouldDirty: true });
    setCustomersState((prev) => ({
      ...prev,
      popupOpen: false,
    }));
  };

  const clearShapes = useCallback(() => {
    shapes.forEach((shape) => shape.setMap(null));
  }, [shapes]);

  const handleCheckCustomer = (customer) => {
    if (formField.stops.some((stop) => stop.id === customer.id))
      return setValue(
        "stops",
        formField.stops.filter((stop) => stop.id !== customer.id),
        { shouldDirty: true }
      );

    setValue("stops", [...formField.stops, customer], { shouldDirty: true });
  };

  const handleDeleteStop = (stop) => {
    setValue(
      "stops",
      formField.stops.filter((item) => item.id !== stop.id),
      { shouldDirty: true }
    );
  };

  const handleAssignReps = (reps) => {
    setValue("assignedReps", reps, { shouldDirty: true, shouldValidate: true });
    success("Reps assigned");
  };

  const fetchRoute = useCallback(() => {
    //setShowDateChangeConfirm(false);
    if (!routeId && !duplicateId) return;
    setCustomersState((prev) => ({ ...prev, loading: true }));
    getRouteByIdService(routeId || duplicateId)
      .then((route) => {
        const {
          assignedRepresentatives,
          name,
          priority,
          customers,
          note,
          mode,
          startLocation,
          endLocation,
          allowAssignedRepsToUpdate,
          activationDate,
        } = route;

        setCustomersState((prev) => ({ ...prev, loading: false }));

        if (activationDate) {
          defaultDate.current = activationDate;
        }

        reset({
          assignedReps: assignedRepresentatives.map(
            (rep) => rep.representative
          ),
          name,
          priority,
          stops: customers.map((c) => c.customer),
          note,
          mode: mode || "driving",
          startLocation,
          endLocation,
          allowAssignedRepsToUpdate,
          activationDate,
        });
      })
      .catch((err) => {
        error(err?.response?.data?.message);
        setCustomersState((prev) => ({ ...prev, loading: false }));
      });
  }, [routeId, reset, duplicateId, setCustomersState]);

  const selectStopsFromCheckedCustomers = useCallback(() => {
    if (checkedCustomers?.length) {
      const sortedCustomersByMissingInfo = checkedCustomers.sort((a, b) => {
        const aMissing =
          a.missingFields && a.missingFields.includes("Shipping address");
        const bMissing =
          b.missingFields && b.missingFields.includes("Shipping address");

        if (aMissing && !bMissing) {
          return -1;
        } else if (!aMissing && bMissing) {
          return 1;
        } else {
          return 0;
        }
      });

      setValue("stops", sortedCustomersByMissingInfo, { shouldDirty: true });
    }
  }, [checkedCustomers, setValue]);

  useEffect(() => fetchRoute(), [fetchRoute]);

  useEffect(() => dispatch(setFormChangedAction(isDirty)), [isDirty, dispatch]);

  useEffect(
    () => dispatch(setEditTypeAction("route", !routeId)),
    [dispatch, routeId]
  );

  useEffect(
    () => selectStopsFromCheckedCustomers(),
    [selectStopsFromCheckedCustomers]
  );

  const handleFilterRoutesList = useCallback((inaccessibleCustomers, stops) => {
    if (!inaccessibleCustomers.length) return stops;
    const filteredMainList = stops.filter(
      ({ id }) => !inaccessibleCustomers.includes(id)
    );

    const filteredListWithInaccessible = stops.filter(({ id }) =>
      inaccessibleCustomers.includes(id)
    );

    return [...filteredListWithInaccessible, ...filteredMainList];
  }, []);

  const handleCheckRouteAvailability = useCallback(async () => {
    try {
      setCustomersState((prev) => ({ ...prev, loading: true }));
      const customerIds = formField?.stops?.map((stop) => stop.id) || [];

      const res = await checkRouteAvailabilityService({
        customerIds,
        travelMode: formField?.mode || "driving",
      });

      setCustomersState((prev) => ({
        ...prev,
        available: res?.available,
        inaccessibleCustomers: res?.inaccessibleCustomers || [],
      }));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    } finally {
      setCustomersState((prev) => ({ ...prev, loading: false }));
    }
  }, [formField?.mode, formField?.stops, setCustomersState]);

  useEffect(() => {
    if (formField?.stops?.length > 1) {
      handleCheckRouteAvailability();
    }
  }, [formField?.stops, handleCheckRouteAvailability]);

  const [editCustomerData, setEditCustomerData] = useState(null);
  const [loadingCustomer, setLoadingCustomer] = useState(false);

  const handleSaveCustomer = async (data) => {
    const { heightOfGoogleAddresses, ...clearData } = data?.data || {};

    const preparedData = { shippingAddress: { ...clearData } };

    if (data?.billingAddress) {
      preparedData.billingAddress = data?.billingAddress;
    }

    setLoadingCustomer(true);
    try {
      const customer = await updateCustomerService(
        preparedData,
        editCustomerData?.id
      );

      const tempStops = formField.stops;

      const index = tempStops.findIndex((stop) => stop.id === customer.id);

      if (index !== -1) {
        tempStops[index] = customer;
        setValue("stops", tempStops, { shouldDirty: true });
        if (tempStops.length > 1) handleCheckRouteAvailability();
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    } finally {
      setLoadingCustomer(false);
      setEditCustomerData(null);
    }
  };

  const list = useMemo(
    () =>
      handleFilterRoutesList(
        customersState.inaccessibleCustomers,
        formField.stops
      ),
    [
      customersState.inaccessibleCustomers,
      formField.stops,
      handleFilterRoutesList,
    ]
  );

  return (
    <>
      <EditCustomerPopup
        isOpen={!!editCustomerData}
        customer={editCustomerData}
        data={editCustomerData?.shippingAddress}
        type={"shipping address"}
        handleClose={() => setEditCustomerData(null)}
        handleSave={(data) => handleSaveCustomer(data)}
        loading={loadingCustomer}
        withoutAbsoluteLabel
        env="route"
      />
      <Box>
        <Loader isLoading={customersState.loading} />
        <RouteWarning
          open={customersState.warningOpen}
          onClose={() =>
            setCustomersState((prev) => ({ ...prev, warningOpen: false }))
          }
        />

        {!!customersState.popupOpen && (
          <AllCustomersPopup
            {...{ handleAddCustomers }}
            addedStops={formField.stops}
            open={customersState.popupOpen}
            viewCustomerPopup={false}
            onClose={() =>
              setCustomersState((prev) => ({ ...prev, popupOpen: false }))
            }
            allowMissingInfo={true}
          />
        )}

        <HeaderBlock
          {...{
            navigatePath,
            navigateState,
            routeId,
            formField,
            customersByMissingInfoCount,
            customersState,
            trigger,
          }}
        />

        <form id="new-route-form" onSubmit={handleSubmit(onSubmit)}>
          <Box className={classes.pageWrapper}>
            <Box className={classes.bodyWrapper}>
              <StopsTable
                stops={list}
                hasStartLoc={!!formField.startLocation}
                reps={formField.assignedReps}
                {...{
                  handleDeleteStop,
                  setEditCustomerData,
                  control,
                  handleAssignReps,
                  customersState,
                  setValue,
                  formField,
                  setError,
                  clearErrors,
                  showDateChangeConfirm,
                  setShowDateChangeConfirm,
                  routeId,
                  defaultDate: defaultDate.current,
                }}
              />
              <Box width="100%">
                <FiltersBlock
                  {...{
                    searchInput,
                    setSearchInput,
                    filterChipKeys,
                    getChipLabel,
                    handleDeleteFilter,
                    setCustomersState,
                    filterAnchor,
                    filterMenuOpen,
                    setFilterMenuOpen,
                    filterFields,
                    switches,
                    handleApplyFilter,
                  }}
                />

                <TabMapComponent
                  {...{ handleCheckCustomer, setShapes, clearShapes, params }}
                  setCheckedCustomers={(addedStops) =>
                    setValue("stops", addedStops, { shouldDirty: true })
                  }
                  styles={{ height: "calc(100% - 66px)" }}
                  canDraw
                  showProspectsToggle
                  checkedCustomers={formField.stops}
                  isCreateRoute
                  isEditRoute={!!routeId}
                />
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
};

CreateRoutePage.propTypes = {
  navigatePath: string,
  navigateState: string,
};

export default CreateRoutePage;
