import { alpha, createTheme } from "@mui/material";

export default createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      light: "#6DCE8D",
      main: "#47A06D",
      transparent: alpha("#47A06D", 0.2),
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    primary2: {
      main: "#409A65",
      contrastText: "#FFFFFF",
    },
    secondary: {
      // light: '#0066ff',
      main: "#FF8000",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#FFFFFF",
    },

    yellow: {
      main: "#fbbc04",
    },

    white: {
      main: "#ffffff",
      contrastText: "#6A6A6A",
    },

    disable: {
      main: "#5F6267",
      contrastText: "#FFFFFF",
    },

    error: {
      light: "#FF6254",
      main: "#FF3737",
    },
    delete: {
      main: "#FC7063",
    },
    confirmDelete: {
      main: "#EB4233",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#F0C401",
    },
    ground: {
      main: "#3F3F3F",
      light: "#AFAFAF",
      contrastText: "#FFFFFF",
    },
    groundLight: {
      main: "#F0F0F0",
      contrastText: "#1C1C19",
    },

    groundLighter: {
      main: "#1C1C19",
    },

    greySecondary: {
      main: "#B5B5AC",
    },

    greyBtn: {
      main: "#D5D9D9",
      contrastText: "#6A6A6A",
    },

    headerGrey: {
      main: "#EEEEEE",
    },

    border: {
      main: "#D5D9D9",
    },

    blackBtn: {
      main: "#363531",
    },

    cancel: {
      main: "#6A6A6A",
    },

    edit: {
      main: "#707070",
    },

    inactive: {
      main: "#CCCCCC",
    },

    red: {
      main: "#FF0000",
    },

    sliderGrey: {
      main: "#939393",
    },

    redText: {
      main: "#FF6254",
    },
    uploadBtn: {
      main: "#F2F2F2",
      contrastText: "#707070",
    },

    greyMarker: {
      main: "#989898",
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  shape: {
    // borderRadius: 8,
  },
  typography: {
    fontFamily: ['"Inter"', "sans-serif"].join(","),
    h6: {
      fontSize: "18px",
      fontWeight: 600,
    },
    header: {
      fontSize: "12px",
      fontWeight: 400,
      color: "#6A6A6A",
    },
    invalid: {
      fontSize: "14px",
      fontWeight: 400,
      color: "#FF6969",
    },
    subtitle1: {
      fontSize: "16px",
      color: "#ffff",
      fontWeight: 400,
    },
  },
  components: {
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "23px!important",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          outline: "none",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F2545B",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          "&.Mui-error": {
            color: "#F2545B",
            fontWeight: "500",
            fontSize: "13px",
          },
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "12px",
          color: "#3F3F3F",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Inter, sans-serif",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "12px",
          lineHeight: "28px",
          textTransform: "none",
          fontFamily: "Inter, sans-serif",
          boxShadow: "none",
        },

        //use it if y need outlined error btn variant
        outlinedError: {
          color: "#EB4233",
          backgroundColor: "rgba(253, 235, 234, 0.80)",
          border: "1px solid #FF6254",
          "&:hover": {
            backgroundColor: "#f5dbdacc !important",
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          fontWeight: "light",
          fontSize: "0.8rem",
        },
      },
    },
  },
});
