import {
  GoogleAutocompleteField,
  StyledTextField,
} from "../../../../components/TextFields/TextFields";
import { Box, MenuItem, Typography, Link } from "@mui/material";
import { bool, func, string, object, array } from "prop-types";
import { Controller } from "react-hook-form";
import CustomCurtainComponent from "../../../../components/CustomCurtainComponent/CustomCurtainComponent";
import { LocationIcon } from "../../../../components/Icons";
import { StyledSelect } from "../../../../components/Selects";
import StyledButton from "../../../../components/StyledButton";
import StyledToggleButton from "../../../../components/StyledToggleButton/StyledToggleButton";
import { sliceCountry, useAdmin } from "../../../../helpers/helpers";
import {
  CANADAS_STATE_NAMES,
  STATE_NAMES,
} from "../../pages/NewCustomerPage/NewCustomerPage.constants";
import {
  getAddressComponents,
  getFormattedAddress,
} from "./AddressField.helpers";
import { ExpandMore } from "@mui/icons-material";
import { useSelector } from "react-redux";

const AddressField = ({
  //isEdit,
  setValue,
  address,
  googleAddress,
  setGoogleAddress,
  fieldName,
  control,
  addressToggled,
  onToggle,
  onMapOpen,
  autocompleteProps,
  label,
  wrapperProps,
  disabled,
  error,
  errorCoordinates,
  noToggleLabel,
  toggleButtonProps,
  mapButtonProps,
  gap,
  addressFieldsWrapperProps,
  mapToggled,
  handleOpenDuplicates,
  duplicates,
  curtainProps,
  curtainPropsStyles,
  textFieldProps,
  selectProps,
  hideUnit,
  formSx,
  unitLabel,
  typingTrigger,
  clearErrors,
  typeSetValue,
  countryList,
  country,
  billingErrorOnly,
  withoutAbsoluteLabel,
  detailInputProps,
  adminIsAllowed,
}) => {
  const currentUser = useSelector(({ auth }) => auth.currentUser);

  const isAdmin = useAdmin(adminIsAllowed);
  const handleDisableField = (field) => {
    if (googleAddress && !googleAddress?.[fieldName]?.[field]) return false;
    return disabled;
  };

  const onFieldChange = (field, e) => {
    const { value } = e.target;
    const textFieldName = field.name.split(".")[1];
    const newAddress = { ...address, [textFieldName]: value };
    const newFormattedAddress = getFormattedAddress(newAddress);

    setValue(`${fieldName}.formatted_address`, newFormattedAddress);
    field?.onChange(e);
  };

  const msgErrorStyles = withoutAbsoluteLabel
    ? {
        position: "relative",
        bottom: 0,
        left: 8,
        margin: 0,
        whiteSpace: "nowrap",
        marginBottom: "0!important",
      }
    : {};

  const selectCityState =
    currentUser && currentUser.country === "US"
      ? STATE_NAMES
      : CANADAS_STATE_NAMES;

  const noErrorMessageProp = billingErrorOnly
    ? true
    : autocompleteProps?.noErrorMessage || false;

  return (
    <Box {...wrapperProps}>
      <Box
        sx={{
          display: "flex",
          gap: gap || "8px",
        }}
      >
        <Box display="flex" width="100%" position="relative">
          <Controller
            render={({ field, fieldState: { error } }) => {
              return (
                <GoogleAutocompleteField
                  adminIsAllowed={adminIsAllowed}
                  disabled={isAdmin || disabled}
                  country={country}
                  errorMsgSx={msgErrorStyles}
                  onPlaceSelected={(place) => {
                    const {
                      street,
                      state,
                      city,
                      zip,
                      formatted_address,
                      appartement,
                    } = getAddressComponents(place);
                    const address = {
                      formatted_address: sliceCountry(formatted_address),
                      lat: place.geometry.location.lat(),
                      lng: place.geometry.location.lng(),
                      street,
                      state,
                      city,
                      zip,
                      appartement,
                    };
                    if (setGoogleAddress)
                      setGoogleAddress((prev) => ({
                        ...prev,
                        [fieldName]: { ...address },
                      }));

                    if (clearErrors) clearErrors(fieldName);

                    setValue(fieldName, address, { shouldDirty: true });
                  }}
                  noErrorMessage
                  formSx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: hideUnit ? "4px" : null,
                      },
                    },
                    "& .MuiFormHelperText-root": {
                      marginBottom: "-4px",
                    },
                    ...formSx,
                  }}
                  size="small"
                  error={
                    error || errorCoordinates
                      ? errorCoordinates
                        ? "Validate address by selecting from the drop down"
                        : error?.message
                      : ""
                  }
                  label={label}
                  {...field}
                  onChange={(e) => {
                    setValue(
                      fieldName,
                      {
                        ...address,
                        formatted_address: e.target.value,
                      },
                      { shouldDirty: true }
                    );
                    if (typingTrigger) typingTrigger();
                  }}
                  setValue={setValue}
                  typeSetValue={typeSetValue}
                  {...textFieldProps}
                  {...autocompleteProps}
                />
              );
            }}
            name={`${fieldName}.formatted_address`}
            control={control}
          />
          {!errorCoordinates && !!duplicates?.length && (
            <Typography
              component={Link}
              fontSize="11px"
              onClick={() => {
                if (isAdmin) return;
                handleOpenDuplicates(duplicates, fieldName);
              }}
              sx={{
                position: "absolute",
                bottom: withoutAbsoluteLabel ? 0 : -23,
                left: 110,
                cursor: "pointer",
              }}
            >
              View here
            </Typography>
          )}
          {!hideUnit && (
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  label="Unit"
                  adminIsAllowed={adminIsAllowed}
                  disabled={isAdmin || disabled}
                  formSx={{
                    maxWidth: "15%",
                    ...formSx,
                  }}
                  labelSx={{ left: -3, top: -1 }}
                  {...textFieldProps}
                  InputProps={{
                    ...textFieldProps?.InputProps,
                    sx: {
                      height: "32px",
                      fontSize: "12px",
                      ...textFieldProps?.InputProps?.sx,
                      "&:hover": {
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#47A06D!important",
                        },
                      },
                      "& fieldset": {
                        borderRadius: "0 4px 4px 0",
                        borderLeft: "none",
                      },
                    },
                  }}
                  error={error?.message || !!duplicates?.length || ""}
                  {...field}
                  value={field.value || ""}
                />
              )}
              name={`${fieldName}.appartement`}
              control={control}
            />
          )}
        </Box>
        {onToggle && (
          <StyledToggleButton
            value={fieldName}
            selected={addressToggled}
            onChange={onToggle}
            error={error}
            {...toggleButtonProps}
          >
            <Box display="flex" alignItems="center">
              {!noToggleLabel && (
                <Typography
                  fontSize="10px"
                  sx={{ textTransform: "none" }}
                  mr="5px"
                >
                  Details
                </Typography>
              )}
              <ExpandMore sx={{ fontSize: 20, color: "rgba(0, 0, 0, 0.54)" }} />
            </Box>
          </StyledToggleButton>
        )}
        {onMapOpen && (
          <StyledButton
            sx={{
              color: "#9C9C94",
              minWidth: "26px",
              width: "26px",
              height: "32px",
              borderColor:
                error || errorCoordinates
                  ? "#F2545B !important"
                  : mapToggled
                  ? "#409A65"
                  : "#D5D9D9",
              pt: "8px",
              backgroundColor: errorCoordinates
                ? "rgba(236,66,51,0.15)"
                : mapToggled && "rgba(64, 154, 101, 0.1)",
              ...mapButtonProps,
            }}
            disabled={isAdmin || disabled}
            label={
              <LocationIcon
                fill={mapToggled ? "#409A65" : "#707070"}
                height="13.33px"
                width="9px"
              />
            }
            variant="outlined"
            onClick={onMapOpen}
          />
        )}
      </Box>
      <CustomCurtainComponent
        toggled={addressToggled}
        curtainHeight="178px"
        display="flex"
        flexDirection="column"
        gap="23px"
        pt={addressToggled ? "23px" : 0}
        {...{ curtainPropsStyles }}
        {...curtainProps}
      >
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledTextField
              label="Street Address"
              error={error?.message || ""}
              adminIsAllowed={adminIsAllowed}
              disabled={handleDisableField("street")}
              {...field}
              value={field.value || ""}
              {...textFieldProps}
              formSx={formSx}
              errorMsgSx={msgErrorStyles}
              {...autocompleteProps}
              noErrorMessage={noErrorMessageProp}
              {...detailInputProps}
              onChange={(e) => {
                onFieldChange(field, e);
                if (typingTrigger) typingTrigger();
              }}
            />
          )}
          name={`${fieldName}.street`}
          control={control}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledTextField
              label={unitLabel}
              error={error?.message || ""}
              adminIsAllowed={adminIsAllowed}
              disabled={disabled}
              errorMsgSx={msgErrorStyles}
              {...field}
              value={field.value || ""}
              {...textFieldProps}
              {...autocompleteProps}
              noErrorMessage={noErrorMessageProp}
              formSx={formSx}
              {...detailInputProps}
              onChange={(e) => {
                onFieldChange(field, e);
                if (typingTrigger) typingTrigger();
              }}
            />
          )}
          name={`${fieldName}.appartement`}
          control={control}
        />
        <Box display="flex" gap="9.4px" {...addressFieldsWrapperProps}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                label="City"
                error={error?.message || ""}
                adminIsAllowed={adminIsAllowed}
                disabled={handleDisableField("city")}
                {...field}
                value={field.value || ""}
                {...textFieldProps}
                {...autocompleteProps}
                errorMsgSx={msgErrorStyles}
                noErrorMessage={noErrorMessageProp}
                formSx={formSx}
                {...detailInputProps}
                onChange={(e) => {
                  onFieldChange(field, e);
                  if (typingTrigger) typingTrigger();
                }}
              />
            )}
            name={`${fieldName}.city`}
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledSelect
                noErrorMessage
                label="State"
                fullWidth
                adminIsAllowed={adminIsAllowed}
                disabled={handleDisableField("state")}
                error={error?.message || ""}
                {...field}
                value={field.value || ""}
                {...selectProps}
                IconComponent={(props) => (
                  <ExpandMore sx={{ fontSize: 27 }} {...props} />
                )}
                formSx={formSx}
                {...detailInputProps}
                onChange={(e) => {
                  onFieldChange(field, e);
                  if (typingTrigger) typingTrigger();
                }}
              >
                {Object.entries(
                  currentUser ? selectCityState : countryList
                ).map(([key, value]) => {
                  return (
                    <MenuItem key={key} value={key}>
                      ({key}) {value}
                    </MenuItem>
                  );
                })}
              </StyledSelect>
            )}
            name={`${fieldName}.state`}
            control={control}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                label="ZIP Code"
                adminIsAllowed={adminIsAllowed}
                disabled={handleDisableField("zip")}
                errorMsgSx={msgErrorStyles}
                error={error?.message || ""}
                {...field}
                value={field.value || ""}
                {...textFieldProps}
                {...autocompleteProps}
                noErrorMessage={noErrorMessageProp}
                formSx={formSx}
                {...detailInputProps}
                onChange={(e) => {
                  onFieldChange(field, e);
                  if (typingTrigger) typingTrigger();
                }}
              />
            )}
            name={`${fieldName}.zip`}
            control={control}
          />
        </Box>
      </CustomCurtainComponent>
    </Box>
  );
};

AddressField.propTypes = {
  isEdit: bool,
  setValue: func,
  address: object,
  googleAddress: object,
  setGoogleAddress: func,
  autocompleteProps: object,
  fieldName: string,
  control: object,
  onToggle: func,
  addressToggled: bool,
  onMapOpen: func,
  label: string,
  wrapperProps: object,
  disabled: bool,
  error: bool,
  errorCoordinates: bool,
  clearErrors: func,
  noToggleLabel: bool,
  toggleButtonProps: object,
  mapButtonProps: object,
  gap: string,
  addressFieldsWrapperProps: object,
  mapToggled: bool,
  handleOpenDuplicates: func,
  duplicates: array,
  curtainProps: object,
  curtainPropsStyles: object,
  textFieldProps: object,
  selectProps: object,
  hideUnit: bool,
  formSx: object,
  unitLabel: string,
  typingTrigger: func,
  typeSetValue: string,
  countryList: object,
  country: string,
  billingErrorOnly: bool,
  withoutAbsoluteLabel: bool,
  detailInputProps: object,
  adminIsAllowed: bool,
};

AddressField.defaultProps = {
  duplicates: [],
  textFieldProps: {},
  selectProps: {},
  country: "",
  hideUnit: false,
  countryList: STATE_NAMES,
  unitLabel: "Apt, Suite, Unit, Floor etc",
  billingErrorOnly: false,
  withoutAbsoluteLabel: false,
  detailInputProps: {},
  adminIsAllowed: false,
};

export default AddressField;
