import * as React from "react";
export const CalendarIconNew = (props) => (
  <svg
    width={18}
    height={20}
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17 9.526v.75h.75v-.75zm-16 0H.25v.75H1zM5.75 1a.75.75 0 0 0-1.5 0zm8 0a.75.75 0 0 0-1.5 0zM13 14.46a.75.75 0 0 0 0 1.5zm1.6 1.5a.75.75 0 0 0 0-1.5zM4.2 4.592h9.6v-1.5H4.2zm12.05 3.04v7.579h1.5V7.63zM13.8 18.25H4.2v1.5h9.6zM1.75 15.21V7.632H.25v7.579zm2.45 3.04c-.78 0-1.275-.002-1.638-.06-.327-.052-.44-.134-.52-.229l-1.146.968c.388.46.886.656 1.43.742.509.081 1.145.079 1.874.079zM.25 15.21c0 .876-.001 1.596.063 2.164.066.579.21 1.113.583 1.555l1.146-.968c-.096-.113-.187-.303-.238-.756-.053-.464-.054-1.083-.054-1.994zm16 0c0 .912-.001 1.531-.054 1.995-.051.453-.142.643-.238.756l1.146.968c.373-.442.517-.976.583-1.555.064-.568.063-1.288.063-2.163zm-2.45 4.54c.73 0 1.365.002 1.874-.079.544-.086 1.042-.282 1.43-.742l-1.146-.968c-.08.095-.194.177-.52.229-.364.058-.859.06-1.638.06zm0-15.158c.78 0 1.274.002 1.638.06.326.052.44.134.52.229l1.146-.968c-.388-.46-.886-.655-1.43-.742-.509-.081-1.145-.079-1.874-.079zm3.95 3.04c0-.876.001-1.596-.063-2.164-.066-.579-.21-1.113-.583-1.555l-1.146.968c.096.113.187.303.238.756.053.464.054 1.083.054 1.995zM4.2 3.092c-.73 0-1.365-.002-1.874.079-.544.087-1.042.282-1.43.742l1.146.968c.08-.095.193-.177.52-.229.363-.058.859-.06 1.638-.06zm-2.45 4.54c0-.912.001-1.531.054-1.995.051-.453.142-.643.238-.756L.896 3.913c-.373.442-.517.976-.583 1.555C.25 6.036.25 6.756.25 7.632zm14.5 0v1.894h1.5V7.632zM17 8.776H1v1.5h16zm-15.25.75V7.632H.25v1.894zM4.25 1v2.842h1.5V1zm8 0v2.842h1.5V1zM13 15.96h1.6v-1.5H13z"
      fill="#A5A5A5"
    />
  </svg>
);
