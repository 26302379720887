import { useMemo, useState, useEffect, useCallback } from "react";
import { array, func, string, object, oneOf, bool } from "prop-types";
import { Chip, InputAdornment, Paper, Typography, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { OutlinedPlusIcon, SearchIcon } from "../../Icons";
import StyledButton from "../../StyledButton";
import { StyledTextField } from "../../TextFields/TextFields";
import useStyles from "./styles";
import { useCheckUniqueTag } from "components/NewTagComponent/useCheckUniqueTag";
import { generateUUID, useAdmin } from "helpers/helpers";
import { useTagsActions } from "helpers/useTagsActions";
import { useRepsPermissions } from "helpers/hooks";

export const TagField = ({
  setValue,
  setError,
  clearErrors,
  fieldName,
  placeholder,
  handleViewAll,
  chosenTags,
  fontSize,
  type,
  hideViewAllBtns,
  placeholderFontSize,
  disabled,
  textFieldProps,
  fieldSx = {},
  adminIsAllowed,
}) => {
  const isAdmin = useAdmin(adminIsAllowed);
  const repPermissions = useRepsPermissions();

  const [tagInput, setTagInput] = useState("");
  const [searchTags, setSearchTags] = useState([]);
  const classes = useStyles({ tagInput, placeholderFontSize });

  const disableAddBtn = useMemo(() => {
    if (type === "order")
      return !!repPermissions && !repPermissions?.settings?.orders;
    if (type === "customer")
      return !!repPermissions && !repPermissions?.customer?.customer_tags;

    return false;
  }, [repPermissions, type]);

  const { isUnique } = useCheckUniqueTag({
    tag: tagInput,
    setError,
    type,
    chosenTags,
    disableAddBtn,
  });

  const { list: tagsList } = useTagsActions({
    open,
    type,
    params: {
      sort_by_tag: "asc",
      limit: null,
      page: null,
    },
  });

  useEffect(() => {
    clearErrors("tag");

    setSearchTags(
      tagsList.filter(
        (tag) =>
          tag?.tag && tag?.tag?.toLowerCase()?.includes(tagInput?.toLowerCase())
      )
    );
  }, [tagsList, tagInput, isUnique, clearErrors, disableAddBtn]);

  const handleDeleteTag = useCallback(
    (tag) => {
      const tags = [...chosenTags];
      const newTags = tags.filter((item) => item !== tag);
      setValue(fieldName, newTags, { shouldDirty: true });
    },
    [fieldName, setValue, chosenTags]
  );

  const hideAlreadyAdded = useCallback(
    (el) => {
      const foundTagName = el?.tag?.toLowerCase().trim();
      return !chosenTags?.some((item) => {
        const chosenTagName = (item?.tag || item)?.toLowerCase();
        return foundTagName === chosenTagName;
      });
    },
    [chosenTags]
  );

  const isAlreadyAdded = useMemo(() => {
    const isAdded = chosenTags.find((item) => {
      const tag = item?.tag?.tag ?? item?.tag ?? item ?? "";
      return tag?.toLowerCase()?.trim() === tagInput?.toLowerCase()?.trim();
    });
    return isAdded ? (
      <Typography className={classes.searchText} sx={{ opacity: 0.5 }}>
        {isAdded?.tag ?? isAdded} - This tag is already added
      </Typography>
    ) : null;
  }, [classes.searchText, chosenTags, tagInput]);

  const newTag = (tagName) => ({
    id: generateUUID(),
    tag: tagName,
    newTag: true,
  });

  const tagField = useMemo(
    () =>
      tagInput && (isAlreadyAdded || isUnique) ? (
        <Paper className={classes.fieldWrapper}>
          {isAlreadyAdded}
          {isUnique && (
            <StyledButton
              disabled={!isUnique || disableAddBtn}
              startIcon={<OutlinedPlusIcon />}
              sx={{
                height: "20px",
                opacity: isUnique || !disableAddBtn ? 1 : 0.5,
              }}
              onClick={() => {
                if (disabled || isAdmin || disableAddBtn) return;
                setTagInput("");
                setValue(fieldName, [...chosenTags, newTag(tagInput)], {
                  shouldDirty: true,
                });
              }}
              label={
                <Typography fontSize="12px" fontWeight="400" color="#42A57F">
                  {`Add "${tagInput}"`}
                </Typography>
              }
            />
          )}
          {searchTags.length
            ? searchTags.filter(hideAlreadyAdded).map((tag) => (
                <Typography
                  key={tag.id}
                  className={classes.searchText}
                  onClick={() => {
                    setTagInput("");
                    setValue(fieldName, [...chosenTags, tag], {
                      shouldDirty: true,
                    });
                  }}
                >
                  # {tag.tag}
                </Typography>
              ))
            : null}
        </Paper>
      ) : (
        <>
          {!!chosenTags?.length && (
            <Box className={classes.chipField}>
              {chosenTags.map((item) => (
                <Chip
                  disabled={disabled || isAdmin}
                  className={classes.tagChip}
                  deleteIcon={
                    <CloseIcon style={{ fontSize: "10px", color: "#707070" }} />
                  }
                  label={item.tag?.tag ?? item?.tag ?? item}
                  key={item.tag?.id ?? item?.id ?? item}
                  onDelete={() => handleDeleteTag(item)}
                />
              ))}
            </Box>
          )}
        </>
      ),
    [
      tagInput,
      isAlreadyAdded,
      isUnique,
      classes.fieldWrapper,
      classes.chipField,
      classes.searchText,
      classes.tagChip,
      disableAddBtn,
      searchTags,
      hideAlreadyAdded,
      chosenTags,
      disabled,
      isAdmin,
      setValue,
      fieldName,
      handleDeleteTag,
    ]
  );

  return (
    <>
      <StyledTextField
        adminIsAllowed={adminIsAllowed}
        disabled={disabled}
        fullWidth
        size="small"
        InputProps={{
          className: classes.textInput,
          endAdornment: hideViewAllBtns ? null : (
            <StyledButton
              className={classes.fieldButton}
              onClick={() => {
                if (disabled || isAdmin) return;
                handleViewAll();
              }}
              label={
                <Typography color="primary" fontSize={fontSize}>
                  View all
                </Typography>
              }
            />
          ),
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          ...textFieldProps,
        }}
        InputLabelProps={{
          className: classes.inputLabel,
        }}
        {...fieldSx}
        value={tagInput}
        onChange={(e) => setTagInput(e.target.value)}
        placeholder={placeholder || "Tags"}
      />
      {tagField}
    </>
  );
};

TagField.propTypes = {
  setValue: func,
  errors: object,
  textFieldProps: object,
  fieldSx: object,
  setError: func,
  clearErrors: func,
  handleViewAll: func,
  fieldName: string,
  placeholder: oneOf(["Order tag", "Tags"]),
  chosenTags: array,
  fontSize: string,
  type: oneOf(["customer", "product", "order"]),
  hideViewAllBtns: bool,
  placeholderFontSize: string,
  disabled: bool,
  adminIsAllowed: bool,
};

TagField.defaultProps = {
  fieldName: "tags",
  placeholder: "Tags",
  chosenTags: [],
  fontSize: "10px",
  errors: {},
  setError: () => {},
  clearErrors: () => {},
  hideViewAllBtns: false,
  adminIsAllowed: false,
};

export default TagField;
