import { useCallback, useEffect, useMemo, useState } from "react";
import { bool, func, array, string } from "prop-types";

import { Drawer } from "@mui/material";
import { useRepsListAction } from "./useRepsListAction";
import {
  RepsAssignedDrawerActions,
  RepsAssignedDrawerBody,
  RepsAssignedDrawerHeader,
} from "./components";
import { useDispatch, useSelector } from "react-redux";
import { openDiscardChanges } from "redux/actions/confirmDialogs";
import { isEqual } from "lodash";

export const RepsAssignedDrawer = ({
  isOpen,
  handleClose,
  assignedRepresentatives,
  handleSave,
  hasCustomerPendingOrder,
  handleSetData,
  editAssignedReps,
  isEditMode,
  withoutThirdParty,
  handleDeleteAllReps,
  submitBtnLabel,
  skipDisabledBtn,
  singleAddRep,
}) => {
  const dispatch = useDispatch();

  const { repsList, loading } = useRepsListAction({
    isOpen,
    withoutThirdParty,
  });
  const currentUser = useSelector(({ auth }) => auth?.currentUser);

  const [preparedRepsList, setPreparedRepsList] = useState([]);

  const [checkedRep, setCheckedRep] = useState({});

  const handleCheckRep = (rep) => {
    if (!rep) return;

    setCheckedRep(rep);
  };

  useEffect(() => {
    if (currentUser && repsList?.length) {
      setCheckedRep(
        singleAddRep
          ? { id: currentUser?.id, role: "distributor" }
          : repsList[0]
      );
    }
  }, [repsList, singleAddRep, currentUser]);

  const selectedReps = useMemo(() => {
    return preparedRepsList.filter((r) => r?.checked);
  }, [preparedRepsList]);

  const availableReps = useMemo(() => {
    return preparedRepsList.filter((r) => !r?.checked);
  }, [preparedRepsList]);

  const handleCheckboxChange = useCallback(
    (rep) => {
      if (!rep) return;

      const tempList = [...preparedRepsList];
      const changedRep = { ...rep, checked: !rep?.checked };

      tempList.splice(rep?.index, 1, changedRep);
      setPreparedRepsList([...tempList]);
    },
    [preparedRepsList]
  );

  useEffect(() => {
    setPreparedRepsList(
      repsList.map((r, i) => {
        const isEditAssignedRep = editAssignedReps?.some(
          (ear) => ear?.id === r?.id
        );
        return {
          ...r,
          checked:
            assignedRepresentatives?.some((ar) => ar?.id === r?.id) ||
            isEditAssignedRep,
          index: i,
        };
      })
    );
  }, [assignedRepresentatives, repsList, editAssignedReps]);

  useEffect(() => {
    if (!isOpen) {
      setPreparedRepsList([]);
      setCheckedRep({});
    }
  }, [isOpen]);

  const setDisabledBtn = useMemo(() => {
    if (singleAddRep) {
      return !checkedRep?.id;
    }

    return !skipDisabledBtn ? selectedReps.length === 0 : false;
  }, [checkedRep?.id, selectedReps.length, singleAddRep, skipDisabledBtn]);

  const onSave = useCallback(() => {
    if (singleAddRep && checkedRep?.id) {
      handleSetData(checkedRep);
      handleClose();
      return;
    }

    (isEditMode || editAssignedReps) &&
      handleSave(selectedReps.map((r) => r.id));

    handleSetData(selectedReps);
    handleClose();
  }, [
    checkedRep,
    editAssignedReps,
    handleClose,
    handleSave,
    handleSetData,
    isEditMode,
    selectedReps,
    singleAddRep,
  ]);

  const preventCloseDrawer = useCallback(
    ({ onClose }) => {
      // eslint-disable-next-line no-console
      if (!onClose) return console.error("onClose is a required parameter");

      return dispatch(
        openDiscardChanges(
          () => onClose(),
          () => onSave()
        )
      );
    },
    [dispatch, onSave]
  );

  const checkChangedData = () => {
    const one = assignedRepresentatives?.map((r) => r?.id);
    const two = selectedReps?.map((r) => r?.id);
    if (!isEqual(one, two)) {
      return preventCloseDrawer({ onClose: handleClose });
    }

    handleClose();
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={checkChangedData}>
      <RepsAssignedDrawerHeader
        onClose={checkChangedData}
        singleAddRep={singleAddRep}
      />
      <RepsAssignedDrawerBody
        list={preparedRepsList}
        selectedReps={selectedReps}
        availableReps={availableReps}
        hasCustomerPendingOrder={hasCustomerPendingOrder}
        handleCheckboxChange={handleCheckboxChange}
        singleAddRep={singleAddRep}
        checkedRep={checkedRep}
        handleCheckRep={handleCheckRep}
        loading={loading}
      />
      <RepsAssignedDrawerActions
        onClose={checkChangedData}
        onSave={onSave}
        hasCheckedRep={!!selectedReps.length}
        // onDeleteAllDisabled={!selectedReps.length}
        disabled={setDisabledBtn}
        onDeleteAllReps={handleDeleteAllReps}
        submitBtnLabel={submitBtnLabel}
      />
    </Drawer>
  );
};

RepsAssignedDrawer.propTypes = {
  isOpen: bool,
  handleClose: func,
  assignedRepresentatives: array,
  handleSave: func,
  hasCustomerPendingOrder: bool,
  handleSetData: func,
  editAssignedReps: array,
  isEditMode: bool,
  withoutThirdParty: bool,
  handleDeleteAllReps: func,
  submitBtnLabel: string,
  skipDisabledBtn: bool,
  singleAddRep: bool,
};

RepsAssignedDrawer.defaultProps = {
  handleSetData: () => {},
  withoutThirdParty: false,
  skipDisabledBtn: null,
  singleAddRep: false,
};

export default RepsAssignedDrawer;
