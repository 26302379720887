export const cl = {
  leftHeaderItem: {
    justifyContent: "flex-start",
    display: "flex",
    alignItems: "center",
  },

  tableHeaderSx: {
    borderRadius: 0,
    borderTop: "none",
    borderWidth: "0.5px",
    px: "16px",
  },
};
