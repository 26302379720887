import { bool, array, func, object, string } from "prop-types";
import { Box, Grid, Typography } from "@mui/material";
import { CustomerSearch } from "./CustomerSearch";
import useStyles from "Pages/CustomersPage/pages/CustomerProfilePage/components/TaskDrawer/styles";
import { SelectedCustomerWrapper } from "./SelectedCustomerWrapper";

export const AdvancedDrawerContent = ({
  selectedCustomers,
  customers,
  setCheckedCustomers,
  titleStyles = {},
  adminIsAllowed,
  setAllCustomersOpen,

  customersList,
  checkedTaskCustomers,
  customersLoading,
  customersSearchInput,
  setCustomersList,
  handleCheckCustomer,
  setCheckedTaskCustomers,
  setCustomersSearchInput,
  errors,
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Typography sx={{ ...titleStyles }}>Assigned Customers</Typography>

        {selectedCustomers.length > 0 && (
          <Grid container gap="9px" width="fit-content" alignItems="center">
            <Box className={classes.numberBox}>
              <Typography fontSize="14px" color="primary">
                {selectedCustomers.length}
              </Typography>
            </Box>
            <Typography fontSize="14px" color="primary">
              SELECTED
            </Typography>
          </Grid>
        )}
      </Grid>

      <Box>
        <CustomerSearch
          customersList={customersList}
          checkedTaskCustomers={checkedTaskCustomers}
          customersLoading={customersLoading}
          customersSearchInput={customersSearchInput}
          setCustomersList={setCustomersList}
          handleCheckCustomer={handleCheckCustomer}
          setCheckedTaskCustomers={setCheckedTaskCustomers}
          setCustomersSearchInput={setCustomersSearchInput}
          errors={errors}
          customers={customers}
          setCheckedCustomers={setCheckedCustomers}
          adminIsAllowed={adminIsAllowed}
          setAllCustomersOpen={setAllCustomersOpen}
        />

        {!!selectedCustomers.length && (
          <SelectedCustomerWrapper
            selectedCustomers={selectedCustomers}
            deleteCustomer={handleCheckCustomer}
          />
        )}
      </Box>
    </>
  );
};

AdvancedDrawerContent.propTypes = {
  isAdvancedDrawer: bool,
  selectedCustomers: array,
  customers: array,
  setCheckedCustomers: func,
  titleStyles: object,
  adminIsAllowed: bool,
  setAllCustomersOpen: func,
  customersList: array,
  checkedTaskCustomers: array,
  customersLoading: bool,
  customersSearchInput: string,
  setCustomersList: func,
  handleCheckCustomer: func,
  setCheckedTaskCustomers: func,
  setCustomersSearchInput: func,
  errors: object,
};

AdvancedDrawerContent.defaultProps = {
  isAdvancedDrawer: false,
  selectedCustomers: [],
  customers: [],
  setCheckedCustomers: () => {},
  titleStyles: {},
  adminIsAllowed: false,
  setAllCustomersOpen: () => {},
  customersList: [],
  checkedTaskCustomers: [],
  customersLoading: false,
  customersSearchInput: "",
  setCustomersList: () => {},
  handleCheckCustomer: () => {},
  setCheckedTaskCustomers: () => {},
  setCustomersSearchInput: () => {},
};
