import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { bool, func, object, string } from "prop-types";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import { CrossBigIcon } from "components/Icons";

import {
  LargeCheckbox,
  StyledButton,
  StyledSelect,
  StyledDatePicker,
  TypographyAddress,
} from "components";
import DeliveryProductsHeader from "../DeliveryProductsTable/DeliveryProductsHeader";
import DeliveryProductsItem from "../DeliveryProductsTable/DeliveryProductsItem";
import useStyles, { cl } from "./ConfirmDeliveryPopup.styles";

import { validationSchema } from "./ConfirmDelivery.validations";
import { defaultValues } from "./ConfirmDelivery.constants";

import {
  confirmOrderDeliveryAction,
  updateOrderDeliveryAction,
} from "redux/actions/orders";
import { error } from "utils/notifications";
import { createSelector } from "reselect";
import { updateCustomerAction } from "redux/actions/customers";
import StyledPopper from "components/StyledPopper/StyledPopper";
import { currentUserSelector } from "redux/selectors/auth";
import { StyledTooltip } from "components";
import { ROLES, TYPES, useRepsActions } from "helpers/useRepsActions";

const selector = createSelector(currentUserSelector, (currentUser) => ({
  currentUser,
}));

const ConfirmDeliveryPopup = ({
  isOpen,
  handleClose,
  order,
  editDelivery,
  type,
  handleGetOrder,
  isOrderPage,
  successCallback,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const btnRef = useRef();
  const notificationRef = useRef();
  const [openTooltipNotification, setOpenTooltipNotification] = useState(false);
  const { currentUser } = useSelector(selector);
  const { shippingCarriers, sendNotifications, defaultDeliveryMethod } =
    currentUser;
  const [isDisableBtn, setIsDisableBtn] = useState(true);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [openPopper, setOpenPopper] = useState(false);

  const {
    list: salesList,
    count: countSales,
    handleFetchReps,
    page: salesPage,
  } = useRepsActions({
    type: TYPES.reps,
    params: {
      exclude_roles: JSON.stringify([ROLES.third_party]),
      active: true,
    },
    open: isOpen,
  });

  const getDeliveryMethod = useCallback((type) => {
    switch (type) {
      case "Confirm shipment":
        return "SHIPPING_CARRIER";
      case "Confirm delivery":
        return "LOCAL_DELIVERY";

      default:
        return "";
    }
  }, []);

  const hasCustomerEmail = useMemo(() => {
    return (
      !!order?.customer?.email ||
      order?.customer?.contacts?.some((contact) => contact?.email)
    );
  }, [order?.customer?.contacts, order?.customer?.email]);

  const setDeliveryMethod = useMemo(() => {
    if (!editDelivery && defaultDeliveryMethod) {
      return defaultDeliveryMethod;
    }
    return type && salesList?.length > 0
      ? getDeliveryMethod(type)
      : defaultValues.deliveryMethod;
  }, [
    defaultDeliveryMethod,
    editDelivery,
    getDeliveryMethod,
    salesList?.length,
    type,
  ]);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    trigger,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      ...defaultValues,
      deliveryMethod: setDeliveryMethod,
      delivererId:
        editDelivery?.delivererDuplicate?.representativeId ||
        order?.customer?.assignedRepresentatives?.[0]?.representative?.id ||
        salesList?.[0]?.id ||
        defaultValues.delivererId,
      date: editDelivery?.date || defaultValues.date,
      shippingCarrier:
        editDelivery?.shippingCarrier?.id ||
        editDelivery?.shippingCarrier ||
        editDelivery?.defaultValues ||
        editDelivery?.shippingCarrierDuplicate?.id,
      sendNotification: sendNotifications && hasCustomerEmail,
      shippingCarrierDuplicate: editDelivery?.shippingCarrierDuplicate,
    },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({ control });

  const optionsShippingCarrierSelect = {
    notched: false,
    fullWidth: true,
    formSx: {
      ...cl.label,
      justifyContent: "space-between",
      "& .MuiOutlinedInput-input": {
        padding: "0 8px !important",
      },
      "& .MuiSvgIcon-root": {
        right: "4px",
      },
    },
    height: "28px",
    size: "small",
    color: "#000000",
    displayEmpty: true,
    label: "",
    defaultValue: "",
    noErrorMessage: true,
    border: "0.5px solid #D5D9D9",
  };

  const productsListWithGroups = useMemo(() => {
    if (formField?.products?.length) {
      const groups = formField?.products?.reduce(
        (acc, p, i) => {
          if (p?.product?.parentProduct) {
            if (acc[p?.product?.parentProduct?.id]) {
              acc[p?.product?.parentProduct?.id].push({ ...p, index: i });
            } else {
              acc[p?.product?.parentProduct?.id] = [
                {
                  parentId: p?.product?.parentProduct?.id,
                  id: p?.name,
                  name: p?.name,
                  manufacturerName: p?.manufacturerName,
                  photo: p?.product?.parentProduct?.photos?.[0],
                },
                { ...p, index: i },
              ];
            }
          } else {
            acc.without_group.push({ ...p, index: i });
          }
          return acc;
        },
        { without_group: [] }
      );

      const addedLastEl = Object.entries(groups).map((el) => {
        if (el[0] === "without_group") {
          return el;
        } else {
          const indexLastEl = el[1].length - 1;
          let newEl = { ...el };
          newEl[1][indexLastEl].isLastEl = true;
          return newEl;
        }
      });

      return addedLastEl?.map((group) => group[1])?.flat();
    }
    return [];
  }, [formField?.products]);

  useEffect(() => {
    if (formField.deliveryMethod === "LOCAL_DELIVERY") {
      if (
        !!formField?.delivererId &&
        formField?.delivererId !== "Select reps" &&
        !!formField?.products?.filter((prod) => prod.deliverQuantity > 0).length
      ) {
        setIsDisableBtn(false);
      } else {
        setIsDisableBtn(true);
      }
    }
    if (formField?.deliveryMethod === "SHIPPING_CARRIER") {
      if (
        !!formField?.trackingId &&
        !!formField?.shippingCarrier &&
        !!formField?.products?.filter((prod) => prod.deliverQuantity > 0).length
      ) {
        setIsDisableBtn(false);
      } else {
        setIsDisableBtn(true);
      }
    }
  }, [errors, formField]);

  useEffect(() => {
    if (!editDelivery)
      return reset({
        ...defaultValues,
        products: order?.products?.map((prod) => ({
          ...prod,
          deliverQuantity: prod?.quantity - prod?.totalDelivered ?? 0,
        })),
        deliveryMethod: setDeliveryMethod,
        delivererId:
          editDelivery?.delivererDuplicate?.representativeId ||
          order?.customer?.assignedRepresentatives?.[0]?.representative?.id ||
          salesList?.[0]?.id ||
          defaultValues.delivererId,
        shippingCarrier:
          editDelivery?.shippingCarrier?.id ||
          editDelivery?.shippingCarrier ||
          editDelivery?.defaultValues ||
          editDelivery?.shippingCarrierDuplicate?.id,
        sendNotification: sendNotifications && hasCustomerEmail,
        shippingCarrierDuplicate: editDelivery?.shippingCarrierDuplicate,
      });

    const products = editDelivery?.productDeliveries?.map((deliveryProduct) => {
      const matchedProduct = order?.products?.find(
        (product) => product.id === deliveryProduct.orderProductId
      );
      return {
        ...matchedProduct,
        deliverQuantity: deliveryProduct.quantity,
        originalDeliveryProduct: deliveryProduct,
      };
    });

    reset({
      ...defaultValues,
      ...editDelivery,
      date: editDelivery.date || defaultValues.date,
      products,
      delivererId: editDelivery?.delivererDuplicate?.representativeId,
      shippingCarrier:
        editDelivery?.shippingCarrier?.id ||
        editDelivery?.shippingCarrier ||
        editDelivery?.defaultValues ||
        editDelivery?.shippingCarrierDuplicate?.id,
      sendNotification: sendNotifications && hasCustomerEmail,
      shippingCarrierDuplicate: editDelivery?.shippingCarrierDuplicate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, order, isOpen, editDelivery, type]);

  const onSubmit = useCallback(
    (data) => {
      const {
        id,
        products,
        delivererDuplicate,
        deliveryMethod,
        totalQuantity,
        trackingId,
        shippingCarrier,
        delivererId,
        titlePopUp,
        type,
        date,
        shippingCarrierDuplicate,
        ...cleanData
      } = data;
      const productDeliveries = data.products
        .filter((product) => product.deliverQuantity)
        .map((product) => {
          let res = {
            orderProductId: editDelivery
              ? product?.originalDeliveryProduct?.orderProductId
              : product?.id,
            quantity: product?.deliverQuantity,
          };
          if (editDelivery) res.id = product?.originalDeliveryProduct?.id;
          return res;
        });
      if (!productDeliveries.length)
        return error("Please provide at least 1 delivered product.");
      const uploadData = {
        ...cleanData,
        productDeliveries,
        orderId: order.id,
        // date: new Date(data.date).toISOString(),
      };

      if (
        editDelivery &&
        editDelivery?.delivererDuplicate?.representativeId !== delivererId &&
        deliveryMethod === "LOCAL_DELIVERY"
      ) {
        uploadData.deliveryMethod = deliveryMethod;
        uploadData.delivererId = delivererId;
      }
      if (editDelivery && deliveryMethod === "SHIPPING_CARRIER") {
        uploadData.trackingId = trackingId;
        uploadData.shippingCarrierId =
          editDelivery?.shippingCarrierDuplicate?.id &&
          shippingCarrier == editDelivery?.shippingCarrierDuplicate?.id
            ? null
            : shippingCarrier;
        uploadData.deliveryMethod = deliveryMethod;
      }
      if (
        editDelivery &&
        moment(formField?.date).format("YYYY-MM-DD") !==
          moment(editDelivery?.date).format("YYYY-MM-DD")
      ) {
        uploadData.date = new Date(formField.date).toISOString();
      }

      const { orderId, shipEngineNotificationData, ...updateData } = uploadData;

      if (data.deliveryMethod === "LOCAL_DELIVERY") {
        uploadData.delivererId = data.delivererId;
      }
      if (!editDelivery && data.deliveryMethod === "SHIPPING_CARRIER") {
        uploadData.trackingId = data.trackingId;
        uploadData.shippingCarrierId = data.shippingCarrier;
      }

      if (!editDelivery && !formField.date) {
        uploadData.date = new Date().toISOString();
      } else {
        uploadData.date = new Date(formField.date).toISOString();
      }

      if (editDelivery) {
        delete updateData.isDeliveryDisabled;
        delete updateData.isReturnDisabled;
        return dispatch(
          updateOrderDeliveryAction({
            data: updateData,
            id: data.id,
            handleClose,
            handleGetOrder,
            isOrderPage,
            onSuccess: successCallback,
          })
        );
      }
      dispatch(
        confirmOrderDeliveryAction(
          { ...uploadData, deliveryMethod: data.deliveryMethod },
          handleClose,
          handleGetOrder,
          isOrderPage,
          successCallback
        )
      );
    },
    [
      dispatch,
      editDelivery,
      formField.date,
      handleClose,
      order.id,
      isOrderPage,
      handleGetOrder,
      successCallback,
    ]
  );

  const updateCustomer = useCallback(
    (e, rep) => {
      e.stopPropagation();
      const isNew = order?.customer?.assignedRepresentatives?.filter(
        (reps) => reps?.representative?.id === rep?.id
      );
      if (!isNew?.length > 0) {
        const representatives = [
          ...order?.customer?.assignedRepresentatives?.map(
            (reps) => reps?.representative?.id
          ),
          rep?.id,
        ];
        dispatch(
          updateCustomerAction({
            data: { representatives },
            id: order?.customer?.id,
            skipSuccessMsg: true,
          })
        );
      }
    },
    [dispatch, order?.customer]
  );

  const setBtnLabel = useMemo(() => {
    if (editDelivery?.titlePopUp === "Edit delivery") return "Edit fulfillment";

    if (
      editDelivery?.titlePopUp !== "Edit delivery" &&
      formField.deliveryMethod === "LOCAL_DELIVERY"
    )
      return "Fulfill items";

    if (
      editDelivery?.titlePopUp !== "Edit delivery" &&
      formField.deliveryMethod === "SHIPPING_CARRIER"
    )
      return "Confirm shipment";

    return "";
  }, [editDelivery?.titlePopUp, formField.deliveryMethod]);

  return (
    <>
      <StyledPopper
        style={{ zIndex: 1401 }}
        open={openTooltipNotification}
        anchorEl={notificationRef.current}
        text="No email address found for this customer"
        modifiers={[{ name: "offset", options: { offset: [-102, 0] } }]}
        placement="top"
        transition
        aria-hidden="true"
      />
      <Dialog maxWidth="lg" scroll="body" open={isOpen} onClose={handleClose}>
        <DialogTitle sx={cl.blockTitle}>
          <Box sx={cl.blockTitleWrapper}>
            <Typography sx={cl.textTitle}>
              {editDelivery?.titlePopUp === "Edit delivery"
                ? "Edit fulfillment"
                : null}

              {editDelivery?.titlePopUp !== "Edit delivery" &&
              formField.deliveryMethod === "LOCAL_DELIVERY"
                ? "Fulfill items"
                : null}

              {editDelivery?.titlePopUp !== "Edit delivery" &&
              formField.deliveryMethod === "SHIPPING_CARRIER"
                ? "Confirm shipment"
                : null}
            </Typography>
            <Box display="flex" gap="10px" alignItems="center">
              <Typography sx={cl.textSubTitle}>
                Order ID: {order?.customId?.customId || "-"}
              </Typography>
            </Box>
          </Box>
          <IconButton sx={{ mt: 2, mr: 2 }} onClick={handleClose}>
            <CrossBigIcon size={20} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={cl.blockDialog}>
          <Box borderRadius="4px">
            <form id="confirm-delivery-form" onSubmit={handleSubmit(onSubmit)}>
              <Grid sx={cl.contentWrapper} container>
                <Grid xs={3.6} item>
                  <Box>
                    <Typography sx={cl.textBody}>Fulfill VIA</Typography>
                  </Box>
                  <Box sx={cl.block}>
                    <Controller
                      render={({ field, fieldState: { error } }) => (
                        <StyledSelect
                          notched={false}
                          fullWidth
                          formSx={{
                            ...cl.label,
                            justifyContent: "space-between",
                            "& .MuiOutlinedInput-input": {
                              padding: "0 8px 0 2px !important",
                            },
                            "& .MuiSvgIcon-root": {
                              right: "4px",
                            },
                          }}
                          size="small"
                          color="#000000"
                          disabled={!!editDelivery}
                          displayEmpty
                          noErrorMessage
                          border="0.5px solid #D5D9D9"
                          error={error?.message || ""}
                          {...field}
                        >
                          {salesList.length > 0 ? (
                            <MenuItem value="LOCAL_DELIVERY">
                              Local Delivery
                            </MenuItem>
                          ) : (
                            <StyledTooltip
                              key={1}
                              title={
                                "No reps found in the system, in order to fulfill order " +
                                "via local delivery, please add at least 1 rep."
                              }
                              placement="top"
                              arrow
                              open={openTooltip && !salesList.length > 0}
                              onOpen={() => setOpenTooltip(true)}
                              onClose={() => setOpenTooltip(false)}
                            >
                              <Box>
                                <MenuItem
                                  disabled={!salesList.length > 0}
                                  value="LOCAL_DELIVERY"
                                >
                                  Local Delivery
                                </MenuItem>
                              </Box>
                            </StyledTooltip>
                          )}
                          <MenuItem value="SHIPPING_CARRIER">
                            Shipping carrier
                          </MenuItem>
                        </StyledSelect>
                      )}
                      name="deliveryMethod"
                      control={control}
                    />
                  </Box>
                </Grid>
                <Grid
                  xs={0.6}
                  item
                  container
                  alignItems="flex-end"
                  justifyContent="center"
                />
                {type === "Confirm delivery" &&
                  formField.deliveryMethod === "LOCAL_DELIVERY" && (
                    <>
                      <Grid xs={3.6} item>
                        <Box>
                          <Typography sx={cl.textBody}>Date</Typography>
                        </Box>
                        <Box sx={cl.block} width="134px">
                          <Controller
                            render={({ field, fieldState: { error } }) => (
                              <StyledDatePicker
                                adminIsAllowed
                                error={error}
                                inputSx={{ fontSize: "12px" }}
                                textFieldProps={{
                                  formSx: { maxWidth: "134px" },
                                }}
                                {...field}
                                value={field.value || new Date()}
                              />
                            )}
                            name="date"
                            control={control}
                          />
                        </Box>
                      </Grid>
                      <Grid xs={0.6} item />
                      <Grid xs={3.6} item>
                        <Box>
                          <Typography sx={cl.textBody}>Fulfill by</Typography>
                        </Box>
                        <Box sx={cl.block}>
                          <Controller
                            render={({ field, fieldState: { error } }) => (
                              <StyledSelect
                                adminIsAllowed
                                notched={false}
                                fullWidth
                                formSx={{
                                  ...cl.label,
                                  "& .MuiOutlinedInput-input": {
                                    padding: "0 22px 0 2px !important",
                                  },
                                }}
                                PaperPropsSx={{ maxWidth: "300px" }}
                                size="small"
                                color="#000000"
                                error={error?.message || ""}
                                noErrorMessage
                                border="0.5px solid #D5D9D9"
                                dataLength={salesList?.length}
                                dataCount={countSales}
                                handleFetch={() =>
                                  handleFetchReps(salesPage + 1)
                                }
                                {...field}
                              >
                                <MenuItem
                                  value={field.value}
                                  sx={{ display: "none" }}
                                >
                                  {editDelivery?.delivererDuplicate?.name ||
                                    order?.customer
                                      ?.assignedRepresentatives?.[0]
                                      ?.representative?.name}
                                </MenuItem>
                                <MenuItem
                                  sx={{
                                    display:
                                      !salesList.length > 0 ? "block" : "none",
                                  }}
                                  value="Select reps"
                                >
                                  <Typography
                                    sx={{
                                      fontSize: 12,
                                      fontWeight: 400,
                                      color: "#5F6267",
                                    }}
                                  >
                                    Select reps
                                  </Typography>
                                </MenuItem>
                                {salesList?.map((rep) => (
                                  <MenuItem
                                    key={rep?.id}
                                    value={rep?.id}
                                    onClick={(e) => updateCustomer(e, rep)}
                                  >
                                    <Typography
                                      fontSize={12}
                                      color="#3F3F3F"
                                      noWrap
                                    >
                                      {rep?.name}
                                    </Typography>
                                  </MenuItem>
                                ))}
                              </StyledSelect>
                            )}
                            name="delivererId"
                            control={control}
                          />
                        </Box>
                      </Grid>
                    </>
                  )}
              </Grid>
              {formField.deliveryMethod !== "LOCAL_DELIVERY" && (
                <Grid sx={cl.localDelivery} container>
                  <Grid xs={5.5} item container>
                    <Grid marginBottom={1} item container>
                      <Typography sx={cl.localDelivery.text}>
                        SHIPPING ADDRESS
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography sx={cl.textBody}>
                        {order?.customer?.name}
                      </Typography>
                      <TypographyAddress
                        address={
                          order?.customer?.shippingAddress?.formatted_address
                        }
                        styles={{
                          ...cl.textBody,
                          fontWeight: 400,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid xs item>
                    <Box>
                      <Typography sx={{ ...cl.textBody, fontWeight: 400 }}>
                        SHIP DATE:
                      </Typography>
                    </Box>
                    <Box sx={cl.block} width="115px">
                      <Controller
                        render={({ field, fieldState: { error } }) => (
                          <StyledDatePicker
                            adminIsAllowed
                            error={error}
                            textFieldProps={{
                              formSx: { maxWidth: "120px" },
                            }}
                            {...field}
                            inputSx={{ fontSize: "12px" }}
                            value={field.value || new Date()}
                          />
                        )}
                        name="date"
                        control={control}
                      />
                    </Box>
                  </Grid>
                </Grid>
              )}
              <Box sx={cl.stackWrapper}>
                <DeliveryProductsHeader />
                <Stack sx={cl.stack}>
                  {productsListWithGroups.map((product) => {
                    return (
                      <DeliveryProductsItem
                        key={product.id}
                        order={order}
                        product={product}
                        products={formField?.products}
                        showTitleForProduct={formField?.products
                          ?.filter(
                            (el) => !(el?.quantity === el?.totalDelivered)
                          )
                          ?.some(
                            (prod) =>
                              prod?.product?.parentProduct?.id ===
                              product?.parentId
                          )}
                        setValue={setValue}
                        isEdit
                        discount={order?.customerDiscount}
                        titleType={
                          editDelivery?.titlePopUp || "Confirm delivery"
                        }
                        error={!!errors?.products}
                        control={control}
                      />
                    );
                  })}
                </Stack>
              </Box>
              {formField.deliveryMethod === "SHIPPING_CARRIER" && (
                <Box sx={cl.shippingCarrierWrapper}>
                  <Grid sx={cl.shippingCarrier} container>
                    <Grid item xs={12} container>
                      <Grid sx={{ marginLeft: 2 }} item xs={4.6}>
                        <Typography sx={cl.shippingCarrier.text}>
                          Tracking ID
                        </Typography>
                      </Grid>
                      <Grid item xs />
                      <Grid sx={{ marginRight: 2 }} item xs={4.6}>
                        <Typography sx={cl.shippingCarrier.text}>
                          Shipping carrier
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} container>
                      <Grid sx={{ marginLeft: 2 }} item xs={4.6}>
                        <Box>
                          <Controller
                            render={({ field, fieldState: { error } }) => (
                              <TextField
                                fullWidth
                                InputProps={{
                                  className: classes.textInput,
                                }}
                                placeholder="Tracking ID"
                                error={!!error}
                                {...field}
                              />
                            )}
                            name="trackingId"
                            control={control}
                          />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Divider orientation="vertical" />
                      </Grid>
                      <Grid mr={2} item xs={4.6}>
                        <Box>
                          <Controller
                            render={({ field, fieldState: { error } }) => (
                              <StyledSelect
                                adminIsAllowed
                                {...optionsShippingCarrierSelect}
                                error={error?.message || ""}
                                {...field}
                              >
                                <MenuItem sx={{ display: "none" }} value="">
                                  <Box component="span" color="#ACACAC">
                                    Shipping carrier
                                  </Box>
                                </MenuItem>

                                {/* Show this item if the carrier has been removed */}
                                {editDelivery?.shippingCarrier === null &&
                                  formField?.shippingCarrierDuplicate?.id && (
                                    <MenuItem
                                      value={
                                        formField?.shippingCarrierDuplicate?.id
                                      }
                                    >
                                      {
                                        formField?.shippingCarrierDuplicate
                                          ?.name
                                      }
                                    </MenuItem>
                                  )}

                                {shippingCarriers.map(({ id, name }) => (
                                  <MenuItem key={id} value={id}>
                                    {name}
                                  </MenuItem>
                                ))}
                              </StyledSelect>
                            )}
                            name="shippingCarrier"
                            control={control}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </form>
          </Box>
        </DialogContent>
        <DialogActions sx={cl.action}>
          <Box sx={cl.action.checkbox}>
            <Controller
              render={({ field }) => (
                <Box
                  sx={{
                    flexGrow: 1,
                  }}
                  ref={notificationRef}
                  onMouseEnter={() => {
                    if (!hasCustomerEmail) {
                      setOpenTooltipNotification(true);
                    }
                  }}
                  onMouseLeave={() => {
                    setOpenTooltipNotification(false);
                  }}
                >
                  <LargeCheckbox
                    disabled={!hasCustomerEmail}
                    formSx={{
                      mr: 0,
                      color: hasCustomerEmail ? "#000" : "#B9B9B9",
                    }}
                    label={
                      <Typography fontSize="12px" fontWeight="400">
                        Send a notification to the customer
                      </Typography>
                    }
                    size={16}
                    checked={!!field.value}
                    {...field}
                    adminIsAllowed
                  />
                </Box>
              )}
              name="sendNotification"
              control={control}
            />
          </Box>
          <Box sx={cl.action.btnWrapper}>
            <StyledButton
              sx={{ color: "#6A6A6A" }}
              fontSize="13px"
              label="Cancel"
              color="groundLighter"
              onClick={handleClose}
            />

            <Box
              ref={btnRef}
              onMouseEnter={() => {
                trigger();
                setOpenPopper(true);
              }}
              onMouseLeave={() => {
                setOpenPopper(false);
              }}
            >
              <StyledButton
                disabled={isDisableBtn}
                sx={{ paddingX: 1, ml: 1, whiteSpace: "nowrap" }}
                fontSize="15px"
                label={setBtnLabel}
                variant="contained"
                type="submit"
                form="confirm-delivery-form"
              />
            </Box>
          </Box>
        </DialogActions>
      </Dialog>

      <StyledPopper
        style={{ zIndex: 1401, whiteSpace: "pre-wrap" }}
        open={openPopper && !!Object.keys(errors).length}
        anchorEl={btnRef.current || null}
        text={Object.entries(errors)
          .map((er) => er[1].message)
          .join("\n")}
        modifiers={[
          {
            name: "offset",
            options: { offset: [10, 10] },
          },
        ]}
        placement="top"
        transition
        aria-hidden="true"
      />
    </>
  );
};

ConfirmDeliveryPopup.propTypes = {
  isOpen: bool,
  handleClose: func,
  order: object,
  editDelivery: object,
  type: string,
  handleGetOrder: func,
  successCallback: func,
  isOrderPage: bool,
};

export default ConfirmDeliveryPopup;
