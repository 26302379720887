import { toast } from "react-toastify";
import { ActiveIcon, InformationIcon } from "../components/Icons";
import { getTokenFromLocalStorage } from "../helpers/auth";
import store from "redux/store";
import { setCurrentUserAction } from "redux/actions/auth";

const success = (msg, op) => {
  const { options } = op || {};

  return toast.success(msg, {
    position: "bottom-center",
    progress: undefined,
    autoClose: 2000,
    hideProgressBar: true,
    theme: "dark",
    ...options,
  });
};

const successMiddle = (msg, options = {}) =>
  toast.success(msg, {
    position: "bottom-center",
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    className: "import-toast",
    icon: <ActiveIcon />,
    ...options,
  });

const errorConfig = {
  position: "bottom-center",
  theme: "colored",
  hideProgressBar: true,
  progress: undefined,
  autoClose: 2000,
};

const warningMiddle = (msg) =>
  toast.success(msg, {
    position: "bottom-center",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    className: "middle-toast",
    icon: <InformationIcon size="20" stroke="#fff" />,
  });

const error = (err) => {
  const msg = err?.response?.data?.message || err?.message || err;

  const state = store.getState();

  if (
    typeof msg === "string" &&
    (msg === "Request failed with status code 403" ||
      msg.includes("You don't have permission to access the portal.") ||
      msg.includes("You don't have permission to access that resource."))
  ) {
    if (!state?.auth?.loading) store.dispatch(setCurrentUserAction());
    return;
  }

  if (msg === "Something went wrong." && state?.auth?.loading) return;

  const token = getTokenFromLocalStorage();
  if (token && window.navigator.onLine)
    return toast.error(msg || "Something went wrong!", errorConfig);
  return;
};

const warning = (msg, op) => {
  const { options } = op || {};

  return toast.warning(msg, {
    position: "bottom-center",
    progress: undefined,
    autoClose: 2000,
    hideProgressBar: true,
    theme: "dark",
    ...options,
  });
};

export { success, error, warning, successMiddle, warningMiddle, errorConfig };
export default {
  success,
  error,
  warning,
  successMiddle,
  warningMiddle,
};
