import GoogleMapReact from "google-map-react";
import { Box, CircularProgress, Slider, Typography } from "@mui/material";
import { MARKER_TYPE } from "Pages/CustomersPage/components/CustomersTab/components/TabsContent/TabMapComponent/TabMapComponent.constants";
import { StyledButton, StyledTextField, StyledTooltip } from "components";
import MapPoint from "components/MapPoint/MapPoint";
import { mapOptions, polygonOptions } from "utils/mapOptions";
import NumberFormat from "react-number-format";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useSupercluster from "use-supercluster";
import { array, func } from "prop-types";
import useStyles from "./styles";
import AdjustIcon from "@mui/icons-material/Adjust";
import {
  saveProspectsSearchResultsService,
  createProspectsFromSearchResultsService,
} from "services/prospects";
import { error, success } from "utils/notifications";
import { getFourthSquarePlaces } from "services/fourthsquare";
import {
  FOURSQUARE_RESPONSE_FIELDS,
  WORKING_DAYS,
} from "../../ProspectsPage.constants";
import { useDebounce } from "helpers/hooks";
import { CreateProspectsPanel, MapCheckLabel } from "./components";
import pluralize from "pluralize";
import { useNavigate } from "react-router-dom";
import {
  CIRCLE_MAX_RADIUS,
  CIRCLE_MIN_RADIUS,
  METER_TO_MILE_COEFFICIENT,
  MILE_TO_METER_COEFFICIENT,
} from "./ProspectsMap.constants";
import theme from "theme";
import { MAP_PROSPECT_TYPES } from "../../../ProspectsFilter/ProspectsFilter.constants";
import { ProspectsPageContext } from "Pages/CustomersPage/components/ProspectsTab/ProspectsTab";
import { normalizeCamelCase } from "helpers/helpers";
import { CUSTOMER_STATUS_FILTERS } from "utils/constants";
import { bulkActivationService } from "services/customers";

const defaultMapProps = {
  center: {
    lat: 40.72316765399142,
    lng: -97.99994350677083,
  },
  zoom: 4,
};

const createMapOptions = () => {
  return {
    styles: mapOptions,
    fullscreenControl: true,
    fullscreenControlOptions: {
      position: 9,
    },
  };
};

const ProspectsMap = ({
  checkedProspects,
  setProspects,
  handleCheckProspect,
  categories,
  handleOpenFilters,
  setCheckedProspects,
  setFilter,
  mapMarkers,
}) => {
  const {
    setListToListState,
    onShowActiveCustomersChange,
    activeCustomersState,
    currentMarkerType,
    handleReplaceOldData,
    handleAddProspectsToState,
    setLoadingToListState,
    listState,
    filtersState,
    handleAddCustomersToState,
    onOpenReceivingHours,
    handleOpenDrawer,
    handleOpenProductDialog,
    handleOpenCustomerNotes,
    handleCustomerTaskDrawer,
    setLocationBias,
  } = useContext(ProspectsPageContext);

  const navigate = useNavigate();
  const classes = useStyles({
    showDrawTools: !checkedProspects.length,
    showSelectedDrawing: true,
  });

  const [mapState, setMapState] = useState({
    zoom: defaultMapProps.zoom,
    bounds: null,
  });

  const [drawState, setDrawState] = useState({
    isDrawing: false,
    loading: false,
    circle: null,
    radius: CIRCLE_MIN_RADIUS,
  });

  const [numberFormatValue, setNumberFormatValue] = useState(CIRCLE_MIN_RADIUS);

  const mapDrawShapeManagerRef = useRef(null);
  const mapsRef = useRef(null);
  const mapRef = useRef(null);

  const setLoading = (loading) => {
    setDrawState((prev) => ({
      ...prev,
      loading,
    }));
  };

  const setRadius = (radius) => {
    setDrawState((prev) => ({
      ...prev,
      radius,
    }));
  };

  const setCircle = (circle) => {
    setDrawState((prev) => ({
      ...prev,
      circle,
    }));
  };

  const prepareDataForCluster = useCallback((arr) => {
    return arr.map((item) => ({
      type: item.type ?? MARKER_TYPE.customer,
      properties: { cluster: false, ...item },
      geometry: {
        type: "Point",
        coordinates: [
          parseFloat(item.shippingAddress?.lng),
          parseFloat(item.shippingAddress?.lat),
        ],
      },
    }));
  }, []);

  const clusterPoints = useMemo(
    () => prepareDataForCluster(mapMarkers),
    [mapMarkers, prepareDataForCluster]
  );

  const activeCustomerClusterPoints = useMemo(
    () => prepareDataForCluster(activeCustomersState.list),
    [activeCustomersState.list, prepareDataForCluster]
  );

  const { clusters, supercluster } = useSupercluster({
    points: clusterPoints,
    bounds: mapState.bounds,
    zoom: mapState.zoom,
    options: { radius: 75, maxZoom: 13, minPoints: 5 },
  });

  const {
    clusters: activeCustomerClusters,
    supercluster: activeCustomerSupercluster,
  } = useSupercluster({
    points: activeCustomerClusterPoints,
    bounds: mapState.bounds,
    zoom: mapState.zoom,
    options: { radius: 75, maxZoom: 13, minPoints: 5 },
  });

  const milesToMeters = (miles) =>
    parseInt(miles * MILE_TO_METER_COEFFICIENT, 10);
  const metersToMiles = (meters) => meters * METER_TO_MILE_COEFFICIENT;

  const onDrawCircleCallback = useCallback(
    (circle) => {
      circleRef.current?.setMap(null);
      if (drawState.circle) {
        drawState.circle.setMap(null);
      }
      const circleRadius = metersToMiles(circle.getRadius());

      if (circleRadius > CIRCLE_MAX_RADIUS) {
        circle.setRadius(milesToMeters(CIRCLE_MAX_RADIUS));
        setRadius(CIRCLE_MAX_RADIUS);
        setNumberFormatValue(CIRCLE_MAX_RADIUS);
      } else if (circleRadius < CIRCLE_MIN_RADIUS) {
        circle.setRadius(milesToMeters(CIRCLE_MIN_RADIUS));
        setRadius(CIRCLE_MIN_RADIUS);
        setNumberFormatValue(CIRCLE_MIN_RADIUS);
      } else {
        setRadius(circleRadius);
        setNumberFormatValue(circleRadius);
      }

      setCircle(circle);
      circleRef.current = circle;
      handleOpenFilters();
      radiusRef.current = drawState.radius;
    },
    [drawState.circle, drawState.radius, handleOpenFilters]
  );

  const onGoogleApiLoaded = useCallback(
    ({ map, maps }) => {
      map.setClickableIcons(false);
      mapRef.current = map;
      mapsRef.current = maps;
      mapDrawShapeManagerRef.current = new maps.drawing.DrawingManager({
        polygonOptions,
        circleOptions: polygonOptions,
        drawingControl: false,
      });

      mapDrawShapeManagerRef.current.setMap(map);

      mapsRef.current.event.addListener(
        mapDrawShapeManagerRef.current,
        "circlecomplete",
        onDrawCircleCallback
      );
      // setDrawState((prev) => ({ ...prev, mapApiLoaded: true }));
    },
    [onDrawCircleCallback]
  );

  const getCheckedCount = (cluster, type) => {
    const currentSuperCluster =
      type === MARKER_TYPE.customer ? activeCustomerSupercluster : supercluster;

    const clusterCustomers = currentSuperCluster
      .getLeaves(cluster.id, Infinity)
      .map((sc) => sc.properties);
    const checkedLength = checkedProspects.filter((checkedCustomer) =>
      clusterCustomers.some(
        (clusterCustomer) =>
          (clusterCustomer.fsq_id ??
            clusterCustomer.foursquareId ??
            clusterCustomer.id) ===
          (checkedCustomer.fsq_id ??
            checkedCustomer.foursquareId ??
            checkedCustomer.id)
      )
    ).length;

    return checkedLength;
  };

  const getProspectPercentage = (cluster, type) => {
    const currentSuperCluster =
      type === MARKER_TYPE.customer ? activeCustomerSupercluster : supercluster;
    const clusterCustomers = currentSuperCluster
      .getLeaves(cluster.id, Infinity)
      .map((sc) => sc.properties);
    const prospectsCount = clusterCustomers.filter(
      ({ type }) => type === MARKER_TYPE.new
    ).length;
    return (prospectsCount / clusterCustomers?.length) * 100;
  };

  const onRadiusChange = (event, newValue) => {
    if (!drawState) return;
    setRadius(newValue);
    setNumberFormatValue(newValue);
    drawState.circle.setRadius(milesToMeters(newValue));
  };

  const setDrawingMode = () => {
    mapDrawShapeManagerRef.current.setDrawingMode(
      drawState.isDrawing ? null : "circle"
    );

    setDrawState((prev) => ({
      ...prev,
      isDrawing: !prev.isDrawing,
    }));
  };

  const saveSearchResults = useCallback(
    async (prospectsToSave) => {
      if (!prospectsToSave?.length) return;

      const formattedProspectsToSave = prospectsToSave.map((c) => ({
        ...c,
        type: undefined,
        //stats: undefined,
        fsq_id: undefined,
        id: undefined,
        fax: undefined,
        foursquareId: c.fsq_id,
        businessFax: c.fax,
        categories: c.categories.map((cat) => ({
          foursquareId: cat.id,
          name: cat.name,
        })),
      }));
      try {
        setLoadingToListState("newWithIds", true);
        const results = await saveProspectsSearchResultsService({
          prospects: formattedProspectsToSave,
        });
        const savedResultsWithIds = prospectsToSave.map((p, index) => ({
          ...p,
          foursquareId: p.id,
          id: results[index].id,
        }));
        setFilter(MAP_PROSPECT_TYPES.new);
        setListToListState(MAP_PROSPECT_TYPES.new, prospectsToSave);
        setListToListState("newWithIds", savedResultsWithIds);
        setLoadingToListState("newWithIds", false);
      } catch (err) {
        error(err?.response?.data?.message);
      }
    },
    [setFilter, setListToListState, setLoadingToListState]
  );

  const normalizeStatsObject = (obj) => {
    const newObj = {};
    for (const key in obj) {
      const newKey = normalizeCamelCase(key);
      newObj[newKey] = obj[key];
    }
    return newObj;
  };

  const convertParamsIntoLinks = (list) => {
    if (!list || !list.length) return [];

    return list.map(({ id, prefix, suffix, height, width }) =>
      id ? `${prefix}${width}x${height}${suffix}` : ""
    );
  };

  const getProspects = useCallback(
    async (searchParams) => {
      setLoading(true);
      try {
        const { results, message } = await getFourthSquarePlaces(searchParams);
        if (!results && message) {
          error(message);
        }
        const formattedProspects = results
          .filter(({ location }) => !!location.formatted_address)
          .map(({ geocodes, ...place }) => {
            const { longitude: lng, latitude: lat } = geocodes.main;

            const {
              location,
              name,
              stats,
              rating,
              price,
              fsq_id,
              social_media: socialMedia,
              hours,
              tel,
              fax,
              website,
              photos,
            } = place;
            const normalizedStats = normalizeStatsObject(stats);

            const {
              formatted_address,
              address: street,
              dma,
              postcode: zip,
              region: state,
              cross_street,
              locality,
              country,
            } = location;

            const address = {
              lng,
              lat,
              formatted_address,
              street: street || cross_street || undefined,
              city: country === "CA" ? locality : dma,
              zip,
              state,
            };

            return {
              name: name.replace(/:/g, ""),
              shippingAddress: { ...address },
              billingAddress: { ...address },
              categories: place.categories,
              type: MARKER_TYPE.new,
              stats: normalizedStats,
              rating: rating && +(rating / 2).toFixed(1),
              price,
              fsq_id,
              id: fsq_id,
              socialMedia,
              images: convertParamsIntoLinks(photos),
              receivingHours: WORKING_DAYS.map((day) => {
                const prospectWorkingDay = hours?.regular?.find(
                  (h) => day === WORKING_DAYS[h.day - 1]
                );
                const open = prospectWorkingDay?.open;
                const close = prospectWorkingDay?.close;

                return {
                  day,
                  open: open
                    ? `${open?.slice(0, 2)}:${open?.slice(2)}`
                    : undefined,
                  closed: close
                    ? `${close?.slice(0, 2)}:${close?.slice(2)}`
                    : undefined,
                  working: !!prospectWorkingDay,
                };
              }),
              phone: tel,
              fax,
              website,
            };
          });

        setLoading(false);
        saveSearchResults(formattedProspects);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    },
    [saveSearchResults]
  );

  useEffect(() => {
    if (drawState.circle) {
      const radius = parseInt(drawState.circle.getRadius(), 10);
      const center = drawState.circle.getCenter().toJSON();
      const { lat, lng } = center;

      setLocationBias({ center, radius });

      if (!categories.length) {
        handleOpenFilters();
      } else {
        const params = {
          ll: `${lat},${lng}`,
          radius,
          categories: categories.map(({ id }) => id).join(","),
          fields: FOURSQUARE_RESPONSE_FIELDS.join(","),
          limit: 50,
          locale: "en",
        };

        const searchParams = new URLSearchParams(params);

        getProspects(searchParams);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, categories.length, getProspects]);

  const radiusRef = useRef(50);
  const circleRef = useRef(null);

  const radiusDebounced = useDebounce(drawState.radius, 500);

  useEffect(() => {
    if (radiusRef.current !== radiusDebounced && !!drawState.circle) {
      mapDrawShapeManagerRef.current.setDrawingMode(null);
      setDrawState((prev) => ({ ...prev, isDrawing: false }));
      handleOpenFilters();
      radiusRef.current = radiusDebounced;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleOpenFilters, radiusDebounced]);

  const enableDrawModeOnNewSearch = useCallback(() => {
    if (filtersState.filter === MAP_PROSPECT_TYPES.new) {
      setDrawState((prev) => ({ ...prev, isDrawing: true }));
      mapDrawShapeManagerRef.current.setDrawingMode("circle");
    }
  }, [filtersState.filter]);

  useEffect(enableDrawModeOnNewSearch, [enableDrawModeOnNewSearch]);

  const handleCancelCreateProspects = useCallback(() => {
    setCheckedProspects([]);
  }, [setCheckedProspects]);

  const handleAddProspects = useCallback(async () => {
    try {
      setLoading(true);
      const filteredProspects = checkedProspects?.filter(
        ({ status }) => status !== CUSTOMER_STATUS_FILTERS.active
      );
      if (filtersState.filter === MAP_PROSPECT_TYPES.prospects) {
        const data = {
          customerIds: filteredProspects.map(({ id }) => id),
          status: CUSTOMER_STATUS_FILTERS.active,
        };
        await bulkActivationService(data);
        success(
          `${pluralize(
            "Prospect",
            filteredProspects.length,
            true
          )} converted to customers`
        );
        handleReplaceOldData();
        handleAddCustomersToState(filteredProspects);

        setLoading(false);
        setCheckedProspects([]);
      } else {
        const prospectsIds = filteredProspects.map(({ id }) => id);
        const result = await createProspectsFromSearchResultsService({
          prospectsIds,
        });
        setLoading(false);
        success(`${pluralize("Prospect", result.length, true)} added`);

        handleReplaceOldData();
        handleAddProspectsToState(result);
        setCheckedProspects([]);
        return result;
      }
    } catch (err) {
      setLoading(false);

      error(err?.response?.data?.message);
    }
  }, [
    checkedProspects,
    filtersState.filter,
    handleAddProspectsToState,
    handleReplaceOldData,
    setCheckedProspects,
    handleAddCustomersToState,
  ]);

  const handleCreateRoute = useCallback(async () => {
    if (filtersState.filter === MAP_PROSPECT_TYPES.prospects) {
      navigate("/routes/new", {
        state: { checkedCustomers: checkedProspects },
      });
    } else {
      const checkedCustomers = await handleAddProspects();
      navigate("/routes/new", { state: { checkedCustomers } });
    }
  }, [checkedProspects, filtersState.filter, handleAddProspects, navigate]);

  const currentClusters = useMemo(
    () =>
      activeCustomersState.show
        ? [
            ...clusters.map((c) => ({ ...c, type: currentMarkerType })),
            ...activeCustomerClusters.map((c) => ({
              ...c,
              type: MARKER_TYPE.customer,
            })),
          ]
        : clusters.map((c) => ({ ...c, type: currentMarkerType })),
    [
      activeCustomerClusters,
      activeCustomersState.show,
      clusters,
      currentMarkerType,
    ]
  );

  return (
    <Box
      sx={{
        width: "100%",
        mb: "-70px",
        position: "relative",
        overflow: "hidden",
        height: "100%",
        borderRadius: "4px",
        border: `0.5px solid #d5d9d9`,
      }}
    >
      {(drawState.loading ||
        activeCustomersState.loading ||
        listState[MAP_PROSPECT_TYPES.history].loading) && (
        <CircularProgress
          size="50px"
          sx={{
            position: "absolute",
            zIndex: "100",
            top: "calc(50% - 25px)",
            left: "calc(50% - 25px)",
          }}
        />
      )}
      <GoogleMapReact
        bootstrapURLKeys={{
          // eslint-disable-next-line no-undef
          key: process.env.REACT_APP_MAP_KEY,
          libraries: ["drawing", "geometry", "directions", "places"],
        }}
        defaultCenter={defaultMapProps.center}
        defaultZoom={defaultMapProps.zoom}
        options={createMapOptions}
        yesIWantToUseGoogleMapApiInternals
        shouldUnregisterMapOnUnmount
        onGoogleApiLoaded={onGoogleApiLoaded}
        onChange={({ zoom, bounds }) => {
          setMapState((prev) => ({
            ...prev,
            zoom,
            bounds: [
              bounds.nw.lng,
              bounds.se.lat,
              bounds.se.lng,
              bounds.nw.lat,
            ],
          }));
        }}
      >
        {currentClusters.map((cluster) => {
          const {
            cluster: isCluster,
            point_count: pointCount,
            id,
            type: propertiesType,
          } = cluster.properties;
          const type = propertiesType ?? cluster.type;
          const customer = cluster.properties;
          const [lng, lat] = cluster.geometry.coordinates;

          const primaryColor = theme.palette.primary.main;
          const greyColor = theme.palette.greyMarker.main;
          const yellowColor = theme.palette.yellow.main;
          const isProspect =
            type === MARKER_TYPE.prospect || type === MARKER_TYPE.new;
          const isActiveCustomer = type === MARKER_TYPE.customer;

          const length = isActiveCustomer
            ? activeCustomerClusterPoints.length
            : clusterPoints.length;
          if (isCluster) {
            const prospectPercentage = getProspectPercentage(cluster, type);
            const checkedCount = getCheckedCount(cluster, type);
            const color = isActiveCustomer
              ? primaryColor
              : isProspect
              ? yellowColor
              : prospectPercentage === 100
              ? primaryColor
              : greyColor;
            return (
              <MapPoint
                key={cluster.id || id}
                isCluster
                count={pointCount}
                lng={lng}
                lat={lat}
                length={length}
                checkedCount={checkedCount}
                prospectPercentage={prospectPercentage}
                color={color}
              />
            );
          }

          return (
            <MapPoint
              isCluster={false}
              count={0}
              length={0}
              key={customer?.id}
              isChecked={checkedProspects.some(({ id, foursquareId }) =>
                foursquareId && customer.fsq_id
                  ? foursquareId === customer.fsq_id
                  : id === customer.id
              )}
              onMarkerClick={(prospect) => {
                handleCheckProspect(prospect);
              }}
              address={customer?.shippingAddress?.formatted_address}
              customer={customer}
              color={isProspect ? yellowColor : primaryColor}
              completed
              handleOpenReceivingHours={onOpenReceivingHours}
              handleOpenRoutesDrawer={handleOpenDrawer}
              lat={customer?.shippingAddress?.lat}
              lng={customer?.shippingAddress?.lng}
              type={type}
              handleOpenNotes={handleOpenCustomerNotes}
              handleOpenProductDialog={handleOpenProductDialog}
              handleCustomerTaskDrawer={handleCustomerTaskDrawer}
              index={
                type === MARKER_TYPE.new
                  ? mapMarkers.findIndex(
                      (m) =>
                        customer.id === m.id ||
                        m.foursquareId === customer.fsq_id
                    ) + 1
                  : undefined
              }
            />
          );
        })}
      </GoogleMapReact>

      <Box
        className={classes.drawMenuWrapper}
        //    ref={drawMenuRef}
      >
        <Box className={`${classes.drawCreateBlockWrapper} ${classes.tools}`}>
          <StyledButton
            label="Select"
            endIcon={<AdjustIcon />}
            sx={{
              width: "91px",
              height: "44px",
              borderRadius: "13px 0 0 13px",
              border: "0.5px solid #d5d9d9",
              background: drawState.isDrawing ? "#EB4233" : "#47A06D",
              "&:hover": {
                background: drawState.isDrawing ? "#EB4233" : "#47A06D",
              },
              gap: "9px",
            }}
            color="white"
            fontSize="13px"
            onClick={setDrawingMode}
            // {...drawButtonProps}
          />
        </Box>
        <Box className={`${classes.drawBlockWrapper} ${classes.tools}`}>
          <StyledTooltip
            arrow
            placement="top"
            title="Draw circle first to use radius slider"
            PopperProps={{
              modifiers: [
                {
                  name: "offset",
                  options: { offset: [0, 3] },
                },
              ],
            }}
            disableHoverListener={!!drawState.circle}
            disableFocusListener={!!drawState.circle}
            isDark
          >
            <Box className={classes.radiusSliderGroup}>
              <Slider
                value={drawState.radius}
                onChange={onRadiusChange}
                sx={{
                  width: "200px",
                  py: "5px",
                  "& .MuiSlider-rail": {
                    color: "#FFFFFF",
                    opacity: 1,
                  },

                  "& .MuiSlider-thumb:before": {
                    background: "#fff",
                    border: "3px solid #939393",
                  },
                }}
                max={CIRCLE_MAX_RADIUS}
                min={CIRCLE_MIN_RADIUS}
                disabled={!drawState.circle}
                color="sliderGrey"
              />
              <NumberFormat
                customInput={StyledTextField}
                disabled={!drawState.circle}
                thousandSeparator=","
                value={numberFormatValue}
                formSx={{ maxWidth: "90px" }}
                inputSx={{
                  height: "24px",
                  background: "white",
                  textAlign: "right",
                }}
                isAllowed={({ floatValue }) => {
                  return !!drawState.circle && floatValue <= CIRCLE_MAX_RADIUS;
                }}
                endIcon={
                  <Typography fontSize="13px" color="border.main">
                    mi
                  </Typography>
                }
                decimalScale={2}
                onValueChange={({ floatValue }) => {
                  setNumberFormatValue(floatValue);
                  drawState.circle?.setRadius(milesToMeters(floatValue));
                }}
                onFocus={() => {
                  setDrawState((prev) => ({
                    ...prev,
                    drawingMode: false,
                  }));
                  mapDrawShapeManagerRef.current.setDrawingMode(null);
                }}
                onBlur={() => {
                  const { circle } = drawState;
                  if (!circle) return;
                  if (
                    !numberFormatValue ||
                    numberFormatValue < CIRCLE_MIN_RADIUS
                  ) {
                    setRadius(CIRCLE_MIN_RADIUS);
                    circle.setRadius(milesToMeters(CIRCLE_MIN_RADIUS));
                    setCircle(circle);
                  } else {
                    setRadius(numberFormatValue);
                    circle.setRadius(milesToMeters(numberFormatValue));
                    setCircle(circle);
                  }
                }}
              />
              <StyledButton
                color="confirmDelete"
                label="Cancel"
                sx={{ ml: "-13px" }}
                onClick={() => {
                  setProspects([]);
                  setCheckedProspects([]);
                  drawState.circle?.setMap(null);
                  setDrawState({ circle: null, radius: 50 });
                  setLocationBias(null);
                }}
              />
            </Box>
          </StyledTooltip>
        </Box>
      </Box>
      <CreateProspectsPanel
        isOpen={!!checkedProspects.length}
        onCancel={handleCancelCreateProspects}
        onAddProspect={handleAddProspects}
        onCreateRoute={handleCreateRoute}
        type={filtersState.filter}
      />
      <MapCheckLabel
        checked={activeCustomersState.show}
        label="Show existing customers"
        onChange={onShowActiveCustomersChange}
        disabled={activeCustomersState.loading}
      />
    </Box>
  );
};

ProspectsMap.propTypes = {
  prospects: array,
  checkedProspects: array,
  handleCheckProspect: func,
  categories: array,
  setProspects: func,
  handleOpenFilters: func,
  setCheckedProspects: func,
  setTableSearchResults: func,
  setProspectsWithIds: func,
  setFilter: func,
  mapMarkers: array,
};

ProspectsMap.defaultProps = {
  prospects: [],
  checkedProspects: [],
  handleCheckProspect: () => {},
  categories: [],
  setProspects: () => {},
  handleOpenFilters: () => {},
  setCheckedProspects: () => {},
  setTableSearchResults: () => {},
  setProspectsWithIds: () => {},
  setFilter: () => {},
  mapMarkers: [],
};

export default ProspectsMap;
