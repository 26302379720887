import {
  number,
  func,
  bool,
  array,
  object,
  oneOfType,
  string,
} from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { InfiniteLoader, List } from "react-virtualized";
import { CircularProgress, Stack } from "@mui/material";
import { useWindowSize } from "helpers/hooks";
import { ListRowWrapper } from "./components";
import { InfiniteLoadMoreBtn, ScrollControlWrapper } from "components";

const InfiniteLoaderWrapper = ({
  itemsList,
  handleFetch,
  itemsCount,
  loading,
  renderChildren,
  offsetHeight,
  offsetWidth,
  itemHeight,
  loaderProps,
  listProps,
  tableStyle,
  clientHeight,
  dynamicId,
  hideScroll,
  bordersWidthProp,
  resetCallback,
  contentParams,
  wrapperWidth,
  testid,
}) => {
  const [windowWidth, windowHeight] = useWindowSize();
  const [listRef, setListRef] = useState(null);

  const bordersWidth =
    typeof bordersWidthProp === "number" ? bordersWidthProp : 3;

  const wrapperHeight = useMemo(
    () => (clientHeight || windowHeight) - offsetHeight - bordersWidth,
    [clientHeight, windowHeight, offsetHeight, bordersWidth]
  );

  const calculatedListHeight = useMemo(
    () => itemsList.length * itemHeight,
    [itemsList, itemHeight]
  );

  const listHeight = useMemo(
    () =>
      calculatedListHeight > wrapperHeight
        ? wrapperHeight
        : calculatedListHeight,
    [calculatedListHeight, wrapperHeight]
  );

  useEffect(() => {
    if (!contentParams) return;
    listRef && listRef.scrollToRow(0);
    resetCallback && resetCallback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentParams]);

  return (
    <>
      {listHeight > 0 && (
        <InfiniteLoader
          isRowLoaded={({ index }) => {
            return !!itemsList[index];
          }}
          loadMoreRows={handleFetch}
          rowCount={itemsCount}
          overscanRowCount={45}
          threshold={1}
          {...loaderProps}
        >
          {({ onRowsRendered }) => (
            <Stack
              sx={{
                borderRadius: "0 0 4px 4px",
                border: "1px solid #d5d9d9",
                borderTop: "none",
                overflow: "hidden",
                maxHeight: `${listHeight}px`,
                width: wrapperWidth
                  ? wrapperWidth
                  : windowWidth - offsetWidth - bordersWidth,
                ...tableStyle,
              }}
            >
              <ScrollControlWrapper
                documentId={dynamicId || "content-wrapper"}
                dataLength={itemsList.length}
                loading={loading}
                maxHeight={`${listHeight}px`}
                data-testid={testid}
              >
                <List
                  id={dynamicId || "content-wrapper"}
                  style={{
                    overflowY: "scroll",
                  }}
                  className="infinite-scroll-custom-scrollbar"
                  height={listHeight}
                  width={
                    wrapperWidth
                      ? wrapperWidth
                      : windowWidth -
                        offsetWidth -
                        bordersWidth -
                        2 /*additional borders inside this component*/
                  }
                  onRowsRendered={onRowsRendered}
                  ref={setListRef}
                  rowCount={
                    itemsList.length
                    // itemsList.length + (itemsCount > itemsList.length ? 1 : 0)
                  }
                  rowHeight={itemHeight}
                  rowRenderer={({ key, index, style }) => {
                    if (!itemsList[index]) {
                      if (!loading && itemsCount > itemsList.length)
                        return (
                          <ListRowWrapper key={key} style={style}>
                            <InfiniteLoadMoreBtn onClick={handleFetch} />
                          </ListRowWrapper>
                        );

                      return (
                        <ListRowWrapper
                          style={style}
                          display="flex"
                          justifyContent="center"
                          mt="5px"
                          key={key}
                        >
                          <CircularProgress size={30} />
                        </ListRowWrapper>
                      );
                    }
                    return (
                      <ListRowWrapper
                        key={key}
                        style={style}
                        overflow={hideScroll && "hidden !important"}
                      >
                        {renderChildren(index)}
                      </ListRowWrapper>
                    );
                  }}
                  {...listProps}
                />
              </ScrollControlWrapper>
            </Stack>
          )}
        </InfiniteLoader>
      )}
    </>
  );
};

InfiniteLoaderWrapper.propTypes = {
  itemsList: array,
  handleFetch: func,
  itemsCount: number,
  clientHeight: number,
  loading: bool,
  renderChildren: func,
  resetCallback: func,
  offsetHeight: number,
  offsetWidth: number,
  itemHeight: oneOfType([number, func]),
  loaderProps: object,
  listProps: object,
  tableStyle: object,
  contentParams: object,
  dynamicId: string,
  hideScroll: bool,
  bordersWidthProp: number,
  testid: string,
  wrapperWidth: number,
};

InfiniteLoaderWrapper.defaultProps = {
  itemsList: [],
  handleFetch: () => {},
  itemsCount: 0,
  loading: false,
  renderChildren: () => {},
  offsetHeight: 0,
  offsetWidth: 0,
  itemHeight: 48,
  loaderProps: {},
  listProps: {},
  tableStyle: {},
  dynamicId: "",
  testid: "",
};

export default InfiniteLoaderWrapper;
