export const SALES_PERMISSIONS = {
  role: "SALES",
  customers: {
    create_edit: true,
    view: true,
    delete: true,
    import: true,
    export: true,
    gallery: true,
    prospects: true,
    territories: true,
    customer_tags: true,
  },
  orders: {
    view: true,
    // drafts: true,
    create_edit: true,
    // third_party: true,
    import: true,
    export: true,
  },
  catalog: {
    view: true,
    import: true,
    export: true,
    create_edit: true,
    product_tags: true,
    manufacturers: true,
    view_price_lists: true,
    create_edit_price_lists: true,
    delete_price_lists: true,
  },
  representatives: {
    view: true,
    create_edit: true,
  },
  discounts: {
    view: true,
    create_edit: true,
  },
  tasks: {
    view: true,
    create_edit: true,
    delete: true,
  },
  forms: {
    view: true,
    create_edit: true,
    delete: true,
  },
  routes: {
    view: true,
    assign: true,
    create_edit: true,
  },
  reports: {
    view: true,
  },
  settings: {
    orders: true,
    profile: true,
    payments: true,
    customers: true,
    inventory: true,
    // import_export: true, // temporarily solution - SD-7621
    payment_terms: true,
    representatives: true,
  },
};

export const MERCHANDISER_PERMISSIONS = {
  role: "MERCHANDISER",
  customers: {
    create_edit: true,
    view: true,
    delete: true,
    import: true,
    export: true,
    gallery: true,
    prospects: true,
    territories: true,
    customer_tags: true,
  },
  orders: {
    view: true,
    // import: true,
    export: true,
  },
  catalog: {
    view: true,
    // import: true,
    export: true,
  },
  representatives: {
    view: true,
    create_edit: true,
  },
  discounts: {
    view: true,
  },
  tasks: {
    view: true,
    create_edit: true,
    delete: true,
  },
  forms: {
    view: true,
    create_edit: true,
    delete: true,
  },
  routes: {
    view: true,
    assign: true,
    create_edit: true,
  },
  reports: {
    view: true,
  },
  settings: {
    customers: true,
    // import_export: true, // temporarily solution - SD-7621
    representatives: true,
  },
};

export const defaultValues = {
  email: "",
  name: "",
  // avatar: null,
  phone: "",
  status: "",
  role: "MERCHANDISER",
  active: true,
};

export const ROLES = [
  {
    key: "SALES",
    value: "Sales",
  },
  {
    key: "MERCHANDISER",
    value: "Merchandiser",
  },
  {
    key: "THIRD_PARTY",
    value: "3rd Party",
  },
];
