import {
  MERCHANDISER_PERMISSIONS,
  SALES_PERMISSIONS,
} from "../Pages/RepresentativesPage/components/ProfileComponent/TabsContent/TabProfile.constants.js";

const sortKeys = (ref, data) => {
  const sortedObj = {};

  for (const key in ref) {
    if (key in data) {
      sortedObj[key] =
        typeof data[key] === "object"
          ? sortKeys(ref[key], data[key])
          : data[key];
    }
  }

  return sortedObj;
};

export const sortUserPermissionByRole = (data) => {
  if (data.role === "THIRD_PARTY") return data;
  const { permissions, ...rest } = data;

  const sortedPermissions = permissions.map(({ id, role, representativeId, ...permissionRest }) => {
    const ref = role === "SALES" ? SALES_PERMISSIONS : MERCHANDISER_PERMISSIONS;

    return { id, role, representativeId, ...sortKeys(ref, permissionRest) }
  })

  return {
    ...rest,
    permissions: sortedPermissions,
  };
};
