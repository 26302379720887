/* eslint-disable react-hooks/rules-of-hooks */
import { useMemo } from "react";
import { bool, object, any, number } from "prop-types";
import { Box, Skeleton, Typography } from "@mui/material";
import { StyledProductIco } from "components/Icons";
import { photoUrl, truncateText } from "helpers/helpers";
import { SmallArrowRight } from "components/Icons/SmallArrowRight";

const RenderProductBox = ({
  product,
  oldOrder,
  isEdit,
  isDuplicate,
  index,
  order,
  isDeletedProduct,
  newProducts,
  showPrice = true,
  loading = false,
}) => {
  const isProductMissingInOrder = !order?.products?.some(
    (orderProduct) => orderProduct?.id !== oldOrder?.products[index]?.id
  );

  const productName = useMemo(() => {
    const name =
      product?.name ||
      oldOrder?.products?.[index]?.name ||
      product?.parentProduct?.name;

    return truncateText(name, 26);
  }, [index, oldOrder?.products, product?.name, product?.parentProduct?.name]);

  const productVariant = useMemo(() => {
    const sku = product?.sku;
    const color = !!product?.color && `/ ${product?.color}`;
    const size = !!product?.size && `/ ${product?.size}`;

    const variant = `${sku} ${color ? color : ""} ${size ? size : ""}`;

    return truncateText(variant, 38);
  }, [product?.color, product?.size, product?.sku]);

  return (
    <Box
      key={product.id}
      sx={{
        display: "flex",
        borderBottom: "1px solid #efefef",
        mt: "5px",
        minHeight: "76px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "56px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginLeft: "38px",
            mb: "2px",
            right: "4px",
            width: "auto",
            height: "16px",
            background:
              !isDeletedProduct &&
              (!isEdit ||
                product?.quantity === oldOrder?.products?.[index]?.quantity ||
                product?.freeCaseChosen)
                ? "#409a65"
                : "#E2E2E2",
            borderRadius: "32px",
            padding: " 0 5px 0 5px",
            color:
              !isDeletedProduct &&
              (!isEdit ||
                product?.quantity === oldOrder?.products?.[index]?.quantity ||
                product?.freeCaseChosen)
                ? " #ffffff"
                : "#409A65",
            lineHeight: "16px",
            zIndex: "1",
          }}
        >
          {product?.freeCaseChosen ? (
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "10px",
              }}
            >
              {product?.discountQty}
            </Typography>
          ) : (
            <>
              {!isEdit ||
              isDuplicate ||
              (product?.quantity === oldOrder?.products[index]?.quantity &&
                !isDeletedProduct) ? (
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "10px",
                  }}
                >
                  {product?.quantity}
                </Typography>
              ) : (
                <>
                  <Typography
                    sx={{
                      fontSize: "10px",
                      textDecoration: "line-through",
                      color: "#565656",
                    }}
                  >
                    {(isProductMissingInOrder &&
                      !oldOrder?.products[index]?.id) ||
                    newProducts?.length > 0
                      ? "0"
                      : oldOrder?.products[index]?.quantity}
                  </Typography>
                  <Box sx={{ m: "0 2px 3px 2px" }}>
                    <SmallArrowRight />
                  </Box>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: "10px",
                    }}
                  >
                    {isDeletedProduct || newProducts?.length < 0
                      ? "0"
                      : product?.quantity}
                  </Typography>
                </>
              )}
            </>
          )}
        </Box>

        <Box
          sx={{
            position: "absolute",
            top:
              product?.photos?.length === 0 || product?.photo === null
                ? "16px"
                : "0px",
            left:
              product?.photos?.length === 0 || product?.photo === null
                ? "12px"
                : "0px",
          }}
        >
          <StyledProductIco
            src={
              product?.photos?.length
                ? photoUrl(product.photos[0].fileName)
                : product?.photo
                ? photoUrl(product?.photo?.fileName)
                : ""
            }
            styles={{
              objectFit: "contain",
              width: "56px",
              height: "56px",
              borderRadius: "4px",
            }}
          />
        </Box>
        {showPrice && (
          <Box
            sx={{
              height: "20px",
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              width: "auto",
              background: "#ebebeb",
              borderRadius: "32px",
              top: "52px",
              left: "10px",
              minWidth: "36px",
              padding: "0 5px",
              fontWeight: 600,
              fontSize: "10px",
              lineHeight: "20px",
              color: "#333333",
            }}
          >
            {loading ? (
              <Skeleton
                variant="rounded"
                width="80%"
                height="11px"
                sx={{ borderRadius: "4px", my: "auto" }}
              />
            ) : (
              <>
                $
                {(isEdit && !isDuplicate && product?.amount
                  ? product?.amount || 0
                  : product?.wholesalePrice || product?.price || 0
                ).toFixed(2)}
              </>
            )}
          </Box>
        )}
      </Box>
      <Box sx={{ mt: "2px", ml: "10px", overflow: "hidden" }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 600,
            wordWrap: "break-word",
            width: "225px",
          }}
        >
          {productName}
        </Typography>
        <Typography
          sx={{ fontSize: "12px", fontWeight: 600, mt: "10px !important" }}
          noWrap
        >
          {productVariant}
        </Typography>
      </Box>

      {showPrice && (
        <>
          {loading ? (
            <Box sx={{ display: "flex", alignItems: "center", ml: "auto" }}>
              <Skeleton
                variant="rounded"
                width="54px"
                height="20px"
                my="auto"
                sx={{ borderRadius: "4px" }}
              />
            </Box>
          ) : (
            <>
              {product?.freeCaseChosen ? (
                <Typography
                  sx={{
                    ml: "auto",
                    mt: "0px",
                    fontWeight: "700",
                    fontSize: "16px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Free
                </Typography>
              ) : (
                <>
                  {!isEdit ||
                  isDuplicate ||
                  (product?.amount * product?.quantity ===
                    oldOrder?.products?.[index]?.amount *
                      oldOrder?.products?.[index]?.quantity &&
                    !isDeletedProduct) ? (
                    <Box
                      sx={{
                        ml: "auto",
                        mt: "0px",
                        fontWeight: "700",
                        fontSize: "16px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      $
                      {(isEdit && !isDuplicate && product?.amount
                        ? product?.amount * product?.quantity
                        : (product?.wholesalePrice || product?.price || 0) *
                          product?.quantity
                      )?.toFixed(2)}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        ml: "auto",
                        mt: "0px",
                        fontWeight: "700",
                        fontSize: "16px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: 700,
                          color: "#939393",
                          textDecoration: "line-through",
                          mr: "8px",
                          mt: "5px",
                        }}
                      >
                        $
                        {newProducts?.length > 0
                          ? "0"
                          : (
                              (isEdit &&
                                !isDuplicate &&
                                (oldOrder?.products?.[index]?.amount
                                  ? oldOrder?.products?.[index]?.amount *
                                    oldOrder?.products?.[index]?.quantity
                                  : (oldOrder?.products?.[index]
                                      ?.wholesalePrice ||
                                      oldOrder?.products?.[index]?.price) *
                                    oldOrder?.products?.[index]?.quantity)) ||
                              0
                            )?.toFixed(2)}
                      </Typography>
                      $
                      {isDeletedProduct || newProducts?.length < 0
                        ? "0"
                        : product?.amount?.toFixed(2)
                        ? (product?.amount * product?.quantity)?.toFixed(2)
                        : (
                            (product?.wholesalePrice || product?.price || 0) *
                            product?.quantity
                          )?.toFixed(2)}
                    </Box>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};

RenderProductBox.propTypes = {
  product: object,
  oldOrder: object,
  isEdit: bool,
  showPrice: bool,
  isDuplicate: bool,
  index: number,
  order: object,
  missingProducts: any,
  isDeletedProduct: bool,
  loading: bool,
  newProducts: any,
};

export default RenderProductBox;
