import * as React from "react";
export const DiscountsIcon = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m15.517 7.552-4.73-4.73c-.612-.612-.918-.918-1.31-1.06-.393-.142-.824-.103-1.685-.025L3.8 2.1c-.745.068-1.118.102-1.358.342S2.168 3.055 2.1 3.8l-.363 3.992c-.078.861-.117 1.292.025 1.685s.448.698 1.06 1.31l4.73 4.73c1.877 1.878 2.816 2.816 3.983 2.816 1.166 0 2.105-.938 3.982-2.816s2.816-2.816 2.816-3.982-.938-2.106-2.816-3.983Z"
      strokeWidth={1.5}
    />
    <path
      d="M7.845 5.488a1.667 1.667 0 1 1-2.357 2.357 1.667 1.667 0 0 1 2.357-2.357Z"
      strokeWidth={1.5}
    />
  </svg>
);
