import { SCROLL_LIMIT } from "utils/constants";
import {
  createManufacturerService,
  deleteManufacturerService,
  getManufacturersService,
  updateManufacturerService,
} from "../../services/manufacturers";
import { error, success } from "../../utils/notifications";
import { setFormChangedAction } from "./confirmDialogs";
export const GET_MANUFACTURERS = "GET_MANUFACTURERS";
export const START_LOADING_MANUFACTURERS = "START_LOADING_MANUFACTURERS";
export const END_LOADING_MANUFACTURERS = "END_LOADING_MANUFACTURERS";
export const CREATE_MANUFACTURER = "CREATE_MANUFACTURER";
export const DELETE_MANUFACTURER = "DELETE_MANUFACTURER";
export const UPDATE_MANUFACTURER = "UPDATE_MANUFACTURER";
export const GET_MANUFACTURER_COUNT = "GET_MANUFACTURER_COUNT";
export const GET_MANUFACTURERS_INACTIVE = "GET_MANUFACTURERS_INACTIVE";
export const GET_MANUFACTURER_COUNT_INACTIVE =
  "GET_MANUFACTURER_COUNT_INACTIVE";

const getManufacturers = (payload) => ({
  type: GET_MANUFACTURERS,
  payload,
});

const createManufacturer = (payload) => ({
  type: CREATE_MANUFACTURER,
  payload,
});

const updateManufacturer = (payload) => ({
  type: UPDATE_MANUFACTURER,
  payload,
});

const deleteManufacturer = (payload) => ({
  type: DELETE_MANUFACTURER,
  payload,
});

const getManufacturerCount = (payload) => ({
  type: GET_MANUFACTURER_COUNT,
  payload,
});

const getManufacturersInactive = (payload) => ({
  type: GET_MANUFACTURERS_INACTIVE,
  payload,
});

const getManufacturerCountInactive = (payload) => ({
  type: GET_MANUFACTURER_COUNT_INACTIVE,
  payload,
});

const startLoadingManufacturers = () => ({ type: START_LOADING_MANUFACTURERS });
const endLoadingManufacturers = () => ({ type: END_LOADING_MANUFACTURERS });

export const getManufacturersAction = (query) => {
  return (dispatch) => {
    const page = query?.page || 1;
    const limit = query?.limit ?? SCROLL_LIMIT;
    dispatch(startLoadingManufacturers());
    getManufacturersService({
      status: '["active", "inactive_for_customers"]',
      ...query,
      limit,
      page,
    })
      .then((res) => {
        dispatch(endLoadingManufacturers());
        dispatch(
          getManufacturers({
            manufacturers: res?.rows,
            isScrolling: page > 1,
            existData: res?.existData,
          })
        );
        dispatch(getManufacturerCount(res?.count));
      })
      .catch((err) => {
        error(err?.response?.data?.message);
        dispatch(endLoadingManufacturers());
        // eslint-disable-next-line no-console
        console.log(err?.message);
      });
  };
};

export const getManufacturersInactiveAction = (query) => {
  return (dispatch) => {
    const page = query?.page || 1;
    const limit = query?.limit ?? SCROLL_LIMIT;

    dispatch(startLoadingManufacturers());
    getManufacturersService({
      ...query,
      limit,
      page,
    })
      .then((res) => {
        dispatch(endLoadingManufacturers());
        dispatch(
          getManufacturersInactive({
            manufacturersInactive: res?.rows,
            isScrolling: page > 1,
            existDataInactive: res?.existData,
          })
        );
        dispatch(getManufacturerCountInactive(res?.count));
      })
      .catch((err) => {
        error(err?.response?.data?.message);
        dispatch(endLoadingManufacturers());
        // eslint-disable-next-line no-console
        console.log(err?.message);
      });
  };
};

export const createManufacturerAction = ({
  data,
  onSuccess,
  message,
  navigate,
  onError,
}) => {
  return (dispatch) => {
    dispatch(startLoadingManufacturers());
    createManufacturerService(data)
      .then((res) => {
        dispatch(setFormChangedAction(false));
        dispatch(endLoadingManufacturers());
        dispatch(createManufacturer(res));
        if (message) success("Manufacturer created");
        if (onSuccess) onSuccess(res);
        if (navigate) navigate("/catalog", { state: "Manufacturers" });
      })
      .catch((err) => {
        if (onError) onError();
        error(err?.response?.data?.message);
        dispatch(endLoadingManufacturers());
      });
  };
};

export const updateManufacturerAction = ({
  id,
  data,
  archiving,
  navigate,
  onSuccess,
  onError,
}) => {
  return (dispatch) => {
    dispatch(startLoadingManufacturers());
    updateManufacturerService(id, data)
      .then((res) => {
        dispatch(setFormChangedAction(false));
        dispatch(endLoadingManufacturers());
        if (archiving)
          dispatch(
            deleteManufacturer({
              ...res,
              status: res.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
            })
          );
        if (navigate) navigate("/catalog", { state: "Manufacturers" });
        else dispatch(updateManufacturer(res, archiving));
        if (onSuccess) onSuccess();
        success("Manufacturer updated");
      })
      .catch((err) => {
        if (onError) onError();
        error(err?.response?.data?.message);
        dispatch(endLoadingManufacturers());
      });
  };
};

export const deleteManufacturerAction = (id) => {
  return (dispatch) => {
    dispatch(startLoadingManufacturers());
    deleteManufacturerService(id)
      .then((res) => {
        dispatch(endLoadingManufacturers());
        dispatch(deleteManufacturer(res));
        success("Manufacturer deleted");
      })
      .catch((err) => {
        error(err?.response?.data?.message);
        dispatch(endLoadingManufacturers());
      });
  };
};
