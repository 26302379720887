import { object, func, array, bool, string } from "prop-types";
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { StyledAvatar, StyledTextField, TypographyAddress } from "components";
import { Controller } from "react-hook-form";
import {
  normalizeEveryFirstLetterToUpper,
  normalizeUppercaseString,
  photoUrl,
} from "helpers/helpers";
import useStyles from "./styles";
import { replace } from "lodash";

export const EmailToBlock = ({
  customerName,
  formattedBillingAddress,
  control,
  setValue,
  handleDeleteContact,
  contactsList,
  recipientsLoading,
  contactsSearch,
  setContactsSearch,
  handlePressEnter,
  processingIntoChip,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.rowWrap}>
      <Box flex="0 0 15%" mt="2px">
        <Typography className={classes.rowTitle}>Email to: </Typography>
      </Box>
      <Box flex="0 0 80%" sx={{ overflow: "hidden" }}>
        <Typography fontSize="12px" color="#000000">
          {customerName}
        </Typography>
        <TypographyAddress
          styles={{
            color: "#000000",
            fontSize: "12px",
            fontWeight: 400,
          }}
          address={formattedBillingAddress}
        />
        <Controller
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              multiple
              freeSolo
              disableCloseOnSelect
              loading={recipientsLoading}
              PaperComponent={(props) => (
                <Box {...props} className={classes.recipientsTable} />
              )}
              size="small"
              isOptionEqualToValue={(option, value) =>
                option?.email === value?.email &&
                option?.baseUser?.email === value?.baseUser?.email
              }
              sx={{
                marginTop: "10px",
                "& .MuiAutocomplete-paper": {
                  boxShadow: "none",
                },
              }}
              className={classes.textfield}
              getOptionLabel={(option) =>
                option?.email || option?.baseUser?.email
              }
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    value: contactsSearch,
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <></>,
                  }}
                  placeholder="Contact"
                  className={classes.textfield}
                  value={contactsSearch}
                  inputSx={{ color: "#707070" }}
                  formSx={{
                    "& .MuiOutlinedInput-input": {
                      fontSize: "12px",
                    },
                  }}
                  onKeyDown={handlePressEnter}
                  onBlur={processingIntoChip}
                  onChange={(e) => setContactsSearch(e.target.value)}
                  endIcon={recipientsLoading && <CircularProgress size={20} />}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      height: "fit-content",
                      p: "5px!important",
                      fontSizexx: "12px",
                    },
                  }}
                  error={error?.message || ""}
                />
              )}
              ListboxProps={{
                sx: {
                  p: 0,
                  maxHeight: "178px",
                  boxShadow: "none",
                  overflow: "auto",
                },
              }}
              renderOption={(props, recipient, { selected }) => (
                <Grid
                  key={recipient.email || recipient?.baseUser?.email}
                  container
                  columns={10}
                  sx={{
                    backgroundColor: selected && "rgba(66, 165, 127, 0.1)",
                  }}
                  {...props}
                  className={classes.recipientItem}
                >
                  <Grid item xs={5.5}>
                    <Box display="flex" gap="11px" alignItems="center">
                      <StyledAvatar
                        src={photoUrl(recipient?.profilePhoto?.fileName)}
                        name={recipient?.name}
                        sx={{
                          height: "29px",
                          width: "29px",
                          backgroundColor: "#F5F5F5",
                          color: "#7A7A7A",
                          fontWeight: "700",
                        }}
                        stringStyle={{
                          fontSize: "13px",
                        }}
                      />
                      <Box width="100%">
                        <Typography noWrap>{recipient?.name}</Typography>
                        <Typography fontWeight="400" noWrap>
                          {recipient?.email || recipient?.baseUser?.email}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    xs={4.5}
                    pr="35px"
                    item
                    container
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    <Typography>
                      {normalizeUppercaseString(
                        recipient?.role === "THIRD_PARTY"
                          ? "3rd party"
                          : recipient?.role
                      ) || "-"}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              options={contactsList}
              renderTags={(value, getTagProps) => {
                return (
                  <Box className={classes.recipientChipWrapper}>
                    {value
                      ?.filter(
                        (val) =>
                          !!val?.email ||
                          !!val?.baseUser?.email ||
                          typeof val === "string"
                      )
                      ?.map((cc, index) => (
                        <Chip
                          key={cc?.id || cc}
                          sx={{
                            borderRadius: "10px",
                            border: "1px solid #D4D4D4",
                            backgroundColor: "#F8F8FA",
                            height: "35px",
                          }}
                          size="small"
                          label={
                            <>
                              {cc?.name ? (
                                <Typography
                                  noWrap
                                  sx={{
                                    fontSize: "12px",
                                    fontWeight: 500,
                                    lineHeight: 1.2,
                                  }}
                                  color="groundLighter.main"
                                >
                                  {cc?.name}
                                  {cc?.name && cc?.role && " | "}
                                  {replace(
                                    normalizeEveryFirstLetterToUpper(cc?.role),
                                    /_/g,
                                    " "
                                  )}
                                </Typography>
                              ) : (
                                ""
                              )}
                              {cc?.email ||
                              cc?.baseUser?.email ||
                              typeof cc === "string" ? (
                                <Typography
                                  noWrap
                                  sx={{
                                    fontSize: "12px",
                                    fontWeight: 300,
                                    lineHeight: 1.3,
                                  }}
                                  color="#5F6368"
                                >
                                  {cc?.email || cc?.baseUser?.email || cc}
                                </Typography>
                              ) : (
                                ""
                              )}
                            </>
                          }
                          deleteIcon={<CloseIcon />}
                          onMouseDown={(e) => e.stopPropagation()}
                          onDelete={() => handleDeleteContact(value.id)}
                          {...getTagProps({ index })}
                        />
                      ))}
                  </Box>
                );
              }}
              error={error?.message || ""}
              {...field}
              onChange={(event, newValue) => {
                if (event.keyCode !== 8) {
                  setValue(field.name, newValue);
                }
              }}
            />
          )}
          name="contacts"
          control={control}
        />
      </Box>
    </Box>
  );
};

EmailToBlock.propTypes = {
  customerName: string,
  formattedBillingAddress: string,
  control: object,
  setValue: func,
  handleDeleteContact: func,
  contactsSearch: string,
  setContactsSearch: func,
  contactsList: array,
  recipientsLoading: bool,
  handlePressEnter: func,
  processingIntoChip: func,
};
