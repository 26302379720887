import { useCallback, useState } from "react";
import { array, bool, func, number } from "prop-types";
import pluralize from "pluralize";
import { Box, CircularProgress, Typography } from "@mui/material";
import { PaymentPlansHeader, PaymentPlansItem } from "./components";
import { cl } from "./PaymentPlans.styles";
import { getBillingInvoice } from "../../../../../../../../../services/stripe";
import { openFilePreview } from "../../../../../../../../OrdersPage/Orders.helper";
import { error } from "../../../../../../../../../utils/notifications";
import { InfiniteScrollWrapper } from "components";

export const PaymentPlans = ({ loading, list, count, fetchBillingData }) => {
  const [loadingInvoice, setLoadingInvoice] = useState("");
  const handleDownloadInvoice = useCallback((id) => {
    // check for invoice id instead of invoiceNumber
    setLoadingInvoice(id);
    getBillingInvoice(id)
      .then((res) => {
        setLoadingInvoice("");
        openFilePreview(res);
      })
      .catch((err) => {
        setLoadingInvoice("");
        error(err?.response?.data?.message);
      });
  }, []);

  const setPlanName = (planName, extraUsers) => {
    return extraUsers
      ? `${planName} + ${extraUsers} extra ${pluralize("user", extraUsers)}`
      : planName;
  };

  return (
    <Box width="100%">
      <PaymentPlansHeader />
      <Box sx={cl.listWrapper}>
        {!list?.length && loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "40px",
              overflow: "hidden",
            }}
          >
            <CircularProgress size="30px" />
          </Box>
        ) : list?.length ? (
          <InfiniteScrollWrapper
            maxHeight="202px"
            dataLength={list?.length}
            next={fetchBillingData}
            loader={
              <CircularProgress sx={{ ml: "50%", mt: "2px" }} size="30px" />
            }
            hasMore={list?.length < count}
            id="billing-scroll"
          >
            {list.map(
              ({
                id = "",
                name = "",
                invoiceNumber = "",
                periodStart = "",
                periodEnd = "",
                allowedUsers = 0,
                price = 0,
                stripeSubscriptionStatus = "",
                extraUser: { extraAllowedUsers = 0 },
              }) => (
                <PaymentPlansItem
                  key={id}
                  name={setPlanName(name, extraAllowedUsers)}
                  invoice={invoiceNumber}
                  billingPeriod={[periodStart, periodEnd]}
                  usersCount={allowedUsers + extraAllowedUsers}
                  amount={price}
                  status={stripeSubscriptionStatus}
                  dueDate={periodEnd}
                  onDownloadInvoice={() => handleDownloadInvoice(id)}
                  invoiceLoading={loadingInvoice === id}
                />
              )
            )}
          </InfiniteScrollWrapper>
        ) : (
          <Box width="100%">
            <Typography
              sx={{
                padding: "20px 0",
                textAlign: "center",
                fontSize: 16,
                color: "#6A6A6A",
              }}
            >
              No payments yet
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

PaymentPlans.propTypes = {
  loading: bool,
  list: array,
  count: number,
  fetchBillingData: func,
};
PaymentPlans.defaultProps = {
  loading: false,
  list: [],
  count: 0,
};
