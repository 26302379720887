import React from "react";
import axios from "axios";

import {
  getTokenFromLocalStorage,
  createAxiosResponseInterceptor,
} from "./helpers/auth";

import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { CssBaseline } from "@mui/material";
import "./style.css";
import { Provider } from "react-redux";
import store, { persistor } from "./redux/store.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorBoundary from "./components/ErrorBoundary";
import { PersistGate } from "redux-persist/integration/react";

// eslint-disable-next-line no-undef
axios.defaults.baseURL = process.env.REACT_APP_API;

const currentUrl = window.location.href;
const url = currentUrl.split("/");

let sessionToken;

if (url[3] === "view-admin" && !!url[4]) {
  sessionToken = url[4];
} else {
  const sToken = sessionStorage.getItem("token");
  if (sToken) {
    sessionToken = sToken;
  }
}

const token = sessionToken || getTokenFromLocalStorage();

if (token) {
  axios.defaults.headers.Authorization = `Bearer ${token}`;
}

createAxiosResponseInterceptor();

ReactDOM.render(
  //<React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorBoundary>
          <BrowserRouter>
            <App />
            <ToastContainer />
          </BrowserRouter>
        </ErrorBoundary>
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  //</React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
