import Box from "@mui/material/Box";

import useStyles from "./styles";
import { FilterSearchTextField } from "components";
import { useContext } from "react";
import { CategoriesContext } from "Pages/CatalogPage";

export const CategoriesFilter = () => {
  const classes = useStyles();
  const { searchValue, setListSearch, existCategoriesData, categoriesLoading } =
    useContext(CategoriesContext);

  return (
    <Box className={classes.filtersWrapper}>
      <FilterSearchTextField
        adminIsAllowed
        placeholder="Search categories"
        value={searchValue}
        loading={existCategoriesData && categoriesLoading}
        onChange={(e) => setListSearch(e.target.value)}
        fullWidth
        adornmentProps={{
          sx: {
            justifyContent: "flex-end",
          },
        }}
        handleClearValue={() => setListSearch("")}
      />
    </Box>
  );
};
