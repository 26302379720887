export const cl = {
  wrapper: {
    p: "24px",
    width: "454px",
    bgcolor: "#FFF",
    borderRadius: "8px",
    border: "1px solid #D9D9D9",
    boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.16)",
  },
  selectSx: {
    "& .MuiSvgIcon-root": { color: "#9E9E9E" },
    "& svg": {
      width: 24,
      height: 24,
      top: "calc(50% - 13px)",
    },
  },
  selectLabelSx: {
    color: "#00000099",
    fontSize: "13px !important",
    top: 3,
  },
  selectTimeSx: {
    "& .MuiSvgIcon-root": { color: "#9E9E9E" },
    "& svg": {
      width: 24,
      height: 26,
      top: "calc(50% - 13px)",
    },
  },
  selectTimeLabelSx: {
    color: "#00000099",
    fontSize: "13px !important",
    top: 3,
  },
  PaperPropsSx: { marginTop: "12px" },
};
