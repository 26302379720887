import { forwardRef, useEffect } from "react";
import { bool, object, func, string, array, oneOf, shape } from "prop-types";
import { Grid, Link, Typography, Box } from "@mui/material";
import {
  InformationIcon,
  TriangleDownIcon,
  TriangleRightIcon,
  StyledProductIco,
} from "components/Icons";
import useStyles from "../../ProductsSearchItem/styles";
import { photoUrl, getRangeWholeSalePrice } from "helpers/helpers";
import { StyledTooltip, LargeCheckbox } from "components";
import { PRODUCT_TYPE_INVENTORY } from "utils/constants";

const PopupProductsItem = forwardRef(
  (
    {
      product,
      parent,
      isChecked,
      disabled,
      lastChild,
      handleExpandVariations,
      expandedParentId,
      isChild,
      handleCheckProduct,
      outOfStock,
      parentInOrder,
      onMouseEnter,
      onMouseLeave,
      isOrder,
      isPriceList = false,
      hideAvailable,
      disableIfChosenProduct,
      indeterminateParent,
      setExpandedParentId,
      isMissingInfo,
      hideGreenNavigationBar = false,
      skipExpanding = false,
      supplierWithOnlyOneProductWithVariations,
      environment,
      productsRefs,
      hideChildCheckbox = false,
    },
    ref
  ) => {
    const classes = useStyles();

    const isNonInventory =
      product?.type === PRODUCT_TYPE_INVENTORY.non_inventory;

    const setBackgroundColor = () => {
      if (isChecked) return "#ECF5F0";
      if (isChild) return "#F7F7F7";
      if (expandedParentId === product.id || isChild)
        return "rgba(71, 160, 109, 0.1)";
    };

    const calculatePosition = () => {
      if (expandedParentId === product.id) return "12px";
      if (lastChild) return "-16px";
    };

    useEffect(() => {
      if (supplierWithOnlyOneProductWithVariations) {
        handleExpandVariations(parent?.id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product.id, supplierWithOnlyOneProductWithVariations]);

    return (
      <Grid
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={ref}
        container
        key={product.id}
        columns={9}
        height="47px"
        sx={{
          overflow: "hidden",
          backgroundColor: setBackgroundColor,
          opacity: disabled || outOfStock ? 0.5 : 1,
          position: "relative",
          "&:not(:last-of-type)": {
            borderBottom: "0.5px solid #D5D9D9",
          },
          "&:hover": {
            backgroundColor:
              !isChecked &&
              expandedParentId !== product.id &&
              !isChild &&
              "#F7F7F7",
          },
        }}
        data-testid={product.name}
      >
        {(expandedParentId === product.id || isChild) &&
          !hideGreenNavigationBar && (
            <Box
              sx={{
                width: "3px",
                height: "110%",
                backgroundColor: "#42A57F",
                borderRadius: "4px",
                position: "absolute",
                top: calculatePosition,
                left: "0px",
              }}
            />
          )}
        <Grid
          ref={(el) => (productsRefs.current[product.id] = el)}
          item
          xs={0.5}
          className={classes.grid}
        >
          {!hideChildCheckbox && (
            <LargeCheckbox
              size={16}
              sx={{
                "&.MuiCheckbox-indeterminate svg": { fontSize: 22 },
                p: "0",
                ml: "8px",
              }}
              formSx={{ m: 0 }}
              disabled={disabled || outOfStock}
              checked={!disabled && isChecked}
              indeterminate={indeterminateParent}
              onChange={() => {
                if (!isChild && !skipExpanding) setExpandedParentId(product.id);

                handleCheckProduct({
                  ...product,
                  quantity: 1,
                  parentProduct: isChild && parent,
                  itemDiscountType: "PERCENTAGE",
                  itemDiscountAmount: 0,
                  isNewAdded: true,
                });
              }}
            />
          )}
        </Grid>

        {/* Product */}
        <Grid item xs={0.7} display="flex" alignItems="center" pl="5px">
          {/* Picture */}
          <Box className={classes.productPhoto} style={{ height: "38px" }}>
            <StyledProductIco
              src={
                product?.photos?.length
                  ? photoUrl(product.photos[0].fileName)
                  : ""
              }
              styles={{
                objectFit: "contain",
                width: "100%",
                height: "100%",
                borderRadius: "4px",
              }}
            />
          </Box>
        </Grid>

        {/* Name */}
        <Grid
          item
          // xs={2.8}
          xs={hideAvailable ? 6.2 : 4.5}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Box display="flex" alignItems="center" gap={0.5}>
            <Typography
              className={classes.text}
              style={{
                fontWeight: 500,
                maxWidth: isMissingInfo?.length ? "80%" : "100%",
                marginRight: isMissingInfo?.length ? 0 : "4px",
              }}
            >
              {product.name || parent.name}
            </Typography>
            {!!isMissingInfo?.length && (
              <StyledTooltip
                arrow
                title={
                  <Box textAlign="left">
                    <Typography fontSize={12}>Missing info:</Typography>
                    {isMissingInfo?.map((info) => (
                      <Typography key={info} fontSize={12} fontWeight={300}>
                        {info}
                      </Typography>
                    ))}
                  </Box>
                }
                placement="top"
                PopperProps={{
                  modifiers: [
                    {
                      name: "offset",
                      options: { offset: [0, -8] },
                    },
                  ],
                }}
              >
                <Box display="flex" alignItems="center">
                  <InformationIcon size="16" stroke="#FF6969" />
                </Box>
              </StyledTooltip>
            )}
          </Box>
          {/* <Typography
            className={classes.text}
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              fontWeight: 400,
            }}
          >
            by {product?.manufacturer?.name || parent?.manufacturer?.name}
          </Typography> */}

          {product.childProducts?.length ? (
            <Link
              sx={{
                fontSize: "10px",
                fontWeight: 400,
                color: "#1C1C19",
                underline: "hover",
                width: "fit-content",
              }}
              component="button"
              onClick={() => handleExpandVariations(product.id)}
            >
              {expandedParentId === product.id ? (
                <TriangleDownIcon />
              ) : (
                <TriangleRightIcon />
              )}
              <Box mr="6px" component="span" />
              {product.childProducts.length} Variations
            </Link>
          ) : disableIfChosenProduct || !disabled ? (
            <Typography fontSize="10px" fontWeight="400" color="#1C1C19" noWrap>
              {`${product.sku} ${product.size ? `/ ${product.size}` : ""} ${
                product.color ? `/ ${product.color}` : ""
              }`}
            </Typography>
          ) : (
            <Typography fontSize="10px" fontWeight="400" color="#1C1C19" noWrap>
              {`${product.sku} ${product.size ? `/ ${product.size}` : ""} ${
                product.color ? `/ ${product.color}` : ""
              }`}
            </Typography>
          )}
        </Grid>

        {/* Variations */}
        {/* <Grid
          item
          xs={1.8}
          display="flex"
          alignItems="center"
        >
          {product.childProducts?.length ? (
            <Link
              sx={{
                fontSize: "10px",
                fontWeight: 400,
                color: "#1C1C19",
                underline: "hover",
              }}
              component="button"
              onClick={() => handleExpandVariations(product.id)}
            >
              {expandedParentId === product.id ? (
                <TriangleDownIcon />
              ) : (
                <TriangleRightIcon />
              )}
              <Box mr="6px" component="span" />
              {product.childProducts.length} Variations
            </Link>
          ) : disableIfChosenProduct || !disabled ? (
            <Typography fontSize="10px" fontWeight="400" color="#1C1C19" noWrap>
              {product.sku}
              {(product.size || product.color) &&
                ` / ${product.size || product.color}`}
            </Typography>
          ) : (
            ""
          )}
        </Grid> */}

        {/* Already added */}
        {(!isPriceList && parentInOrder) ||
        (disabled &&
          !isPriceList &&
          !outOfStock &&
          !disableIfChosenProduct &&
          !isMissingInfo?.length) ? (
          <Grid item xs={1.6} display="flex" alignItems="center">
            <Typography fontSize="12px" fontWeight="400" color="#47A06D">
              Already{" "}
              {isPriceList ? "added" : isOrder ? "in the order" : "added"}
            </Typography>
          </Grid>
        ) : (
          <>
            {/* Available QTY */}
            {!hideAvailable && (
              <Grid item xs={1.5} display="flex" alignItems="center">
                {product.inventory &&
                  (environment === "cart_page" || !hideAvailable) && (
                    <Typography
                      noWrap
                      className={classes.text}
                      style={{ fontWeight: 400 }}
                    >
                      {outOfStock
                        ? "Out of stock"
                        : isNonInventory
                        ? "-"
                        : `${
                            product.inventory.onHand -
                            product.inventory.allocated
                          } available`}
                    </Typography>
                  )}
              </Grid>
            )}

            {/* Price */}
            <Grid
              item
              xs={1.6}
              display="flex"
              alignItems="center"
              pl={isPriceList ? "20px" : 0}
            >
              {product?.sku && (
                <Typography
                  className={classes.text}
                  style={{ fontWeight: 400 }}
                >
                  $
                  {(
                    (product?.priceWithPriceListDiscount ??
                      product?.wholesalePrice) ||
                    0
                  ).toFixed(2)}
                </Typography>
              )}
              {!isChild && !!product?.childProducts?.length && (
                <Typography
                  className={classes.text}
                  style={{ fontWeight: 400 }}
                >
                  {getRangeWholeSalePrice(product.childProducts)}
                </Typography>
              )}
            </Grid>
          </>
        )}
      </Grid>
    );
  }
);

PopupProductsItem.propTypes = {
  product: object,
  manufacturer: object,
  isChecked: bool,
  disabled: bool,
  handleExpandVariations: func,
  expandedParentId: string,
  parent: object,
  isChild: bool,
  handleCheckProduct: func,
  lastChild: bool,
  outOfStock: bool,
  parentInOrder: bool,
  onMouseEnter: func,
  onMouseLeave: func,
  isOrder: bool,
  hideAvailable: bool,
  isPriceList: bool,
  disableIfChosenProduct: bool,
  indeterminateParent: bool,
  setExpandedParentId: func,
  isMissingInfo: array,
  hideGreenNavigationBar: bool,
  skipExpanding: bool,
  hideChildCheckbox: bool,
  supplierWithOnlyOneProductWithVariations: bool,
  environment: oneOf(["cart_page"]),
  productsRefs: shape({ current: array }),
};

PopupProductsItem.defaultProps = {
  product: null,
  manufacturer: null,
  isChecked: false,
  isPriceList: false,
  disabled: false,
  parent: null,
  isChild: false,
  lastChild: false,
  outOfStock: false,
  parentInOrder: false,
  isOrder: false,
  hideAvailable: false,
  hideGreenNavigationBar: false,
  skipExpanding: false,
  disableIfChosenProduct: false,
  indeterminateParent: false,
  setExpandedParentId: () => {},
  isMissingInfo: [],
  supplierWithOnlyOneProductWithVariations: false,
  productsRefs: { current: [] },
};
PopupProductsItem.displayName = "PopupProductsItem";

export default PopupProductsItem;
