import * as Yup from "yup";
import {
  MAX_CHARACTERS_DESCRIPTIONS,
  MAX_CHARACTERS_OPTIONS_TITLE,
  MAX_CHARACTERS_QUESTIONS_TEXT,
  MAX_CHARACTERS_TITLE,
} from "./FormViewPage.constants";

export const validationSchema = ({ incoming = false } = {}) =>
  Yup.object().shape({
    title: Yup.string()
      .trim()
      .required("This field can't be empty")
      .max(MAX_CHARACTERS_TITLE, `Max ${MAX_CHARACTERS_TITLE} characters`),
    active: Yup.boolean().required("This field can't be empty"),
    description: Yup.string()
      .trim()
      .nullable()
      .max(
        MAX_CHARACTERS_DESCRIPTIONS,
        `Max ${MAX_CHARACTERS_DESCRIPTIONS} characters`
      ),
    autofill: Yup.boolean().required("This field can't be empty"),
    allRequired: Yup.boolean().required("This field can't be empty"),
    questions: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string(),
          text: Yup.string()
            .required("Please enter the question title to proceed")
            .max(
              MAX_CHARACTERS_QUESTIONS_TEXT,
              `Max ${MAX_CHARACTERS_QUESTIONS_TEXT} characters`
            ),
          type: Yup.mixed()
            .oneOf([
              "TEXT",
              "YES_NO",
              "NUMERIC",
              "SINGLE_ANSWER",
              "MULTIPLE_ANSWER",
              "PHOTO",
              "DATE",
            ])
            .required("This field can't be empty"),
          options: Yup.array()
            .nullable()
            .of(
              incoming
                ? Yup.string()
                    .trim()
                    .required("This field can't be empty")
                    .max(
                      MAX_CHARACTERS_OPTIONS_TITLE,
                      `Max ${MAX_CHARACTERS_OPTIONS_TITLE} characters`
                    )
                : Yup.object().shape({
                    title: Yup.string()
                      .trim()
                      .test("titleValidation", (value, ctx) => {
                        if (!value) {
                          return ctx.createError({
                            message: "This field can't be empty",
                          });
                        }
                        if (value.length > MAX_CHARACTERS_OPTIONS_TITLE) {
                          return ctx.createError({
                            message: `Max ${MAX_CHARACTERS_OPTIONS_TITLE} characters`,
                          });
                        }

                        const currentOption = ctx?.options?.from?.[0]?.value;
                        const options = ctx?.options?.from?.[1]?.value?.options;
                        const optionsWithoutCurrent = options?.filter(
                          (option) => option?.uuid !== currentOption?.uuid
                        );

                        const hasDuplicate = optionsWithoutCurrent?.some(
                          (option) => option?.title === value
                        );
                        if (hasDuplicate) {
                          return ctx.createError({
                            message: "Duplicate Answers Are Not Allowed",
                          });
                        }

                        return true;
                      }),
                  })
            ),
          orderIndex: Yup.number().required("This field can't be empty"),
          autofill: Yup.boolean().required("This field can't be empty"),
          required: Yup.boolean().required("This field can't be empty"),
          allowGallery: Yup.boolean().nullable(),
        })
      )
      .required("This field can't be empty")
      .min(1, "Please add questions to save the form"),
  });

export const validateResponseData = async ({ data }) => {
  try {
    await validationSchema({ incoming: true }).validate(data, {
      abortEarly: false,
    });
  } catch (err) {
    if (err instanceof Yup.ValidationError) {
      // eslint-disable-next-line no-console
      console.error("Response validation error", err.inner);
    }
  }
};
