import { bool, func } from "prop-types";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { CrossIcon, DangerIcon } from "components/Icons";
import { StyledButton } from "components";

export const QuestionDeleteDialog = ({
  open,
  handleClose,
  handleSubmit,
  isDeleteResponses,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        zIndex: (theme) => theme.zIndex.tooltip,
        ".MuiPaper-root": {
          borderRadius: "8px",
          boxShadow: "none",
        },
      }}
    >
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "16px 10px 12px 16px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <DangerIcon size={24} />
          <Typography fontSize={18} color="#363531">
            {isDeleteResponses
              ? "This Question Has Responses"
              : "Delete Question Confirmation"}
          </Typography>
        </Box>
        <IconButton
          sx={{ position: "absolute", top: 10, right: 10 }}
          onClick={handleClose}
        >
          <CrossIcon />
        </IconButton>
      </Stack>

      <DialogContent
        sx={{
          minWidth: "420px",
          maxWidth: "420px",
          padding: "0px 16px 0px 16px",
          boxSizing: "border-box",
          marginBottom: isDeleteResponses ? "12px" : "34px",
          maxHeight: "178px",
          overflow: "auto",
        }}
      >
        <Typography fontSize={13} fontWeight={300} color="#363531">
          {isDeleteResponses
            ? "Would you like to keep the responses or delete them along with the question?"
            : "Are you sure you want to delete this question?"}
        </Typography>
      </DialogContent>

      <Stack
        sx={{
          borderTop: "0.5px solid #CCC",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          gap: "12px",
          p: "16px",
        }}
      >
        {isDeleteResponses ? (
          <>
            <StyledButton
              sx={{ width: "110px", height: "28px", p: 0 }}
              variant="outlined"
              label="Keep Responses"
              onClick={() => handleSubmit({ deleteResponses: false })}
              fontSize="12px"
            />

            <StyledButton
              sx={{ width: "110px", height: "28px", p: 0, ml: 0 }}
              variant="contained"
              label="Delete Both"
              onClick={() => handleSubmit({ deleteResponses: true })}
              fontSize="12px"
            />
          </>
        ) : (
          <>
            <StyledButton
              sx={{ width: "110px", height: "28px", p: 0 }}
              variant="outlined"
              label="Cancel"
              onClick={handleClose}
              fontSize="12px"
              color="cancel"
            />

            <StyledButton
              sx={{ width: "110px", height: "28px", p: 0 }}
              variant="contained"
              label="Confirm"
              onClick={handleSubmit}
              fontSize="12px"
            />
          </>
        )}
      </Stack>
    </Dialog>
  );
};

QuestionDeleteDialog.propTypes = {
  open: bool,
  handleClose: func,
  handleSubmit: func,
  isDeleteResponses: bool,
};
QuestionDeleteDialog.defaultProps = {
  open: false,
  handleClose: () => {},
  handleSubmit: () => {},
};
