import { bool, string } from "prop-types";
import { Box, Skeleton, Typography } from "@mui/material";

export const PriceListLabel = ({ priceListName, loading }) => {
  return (
    <>
      {loading ? (
        <Skeleton
          variant="rectangular"
          width="100%"
          height={30}
          sx={{ borderRadius: "4px" }}
        />
      ) : (
        <Box
          sx={{
            height: "30px",
            maxWidth: "100%",
            bgcolor: "#409A6510",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            px: "10px",
            gap: "10px",
          }}
        >
          <Typography fontSize={12} color="#5F6267" noWrap>
            Price list: {priceListName || "Standard"}
          </Typography>
        </Box>
      )}
    </>
  );
};

PriceListLabel.propTypes = { priceListName: string, loading: bool };
PriceListLabel.defaultProps = { priceListName: "Standard", loading: false };
