import { useCallback, useEffect, useState } from "react";
import { useDebounce } from "helpers/hooks";
import { SCROLL_LIMIT } from "utils/constants";

import { error } from "utils/notifications";
import { getCategoriesService } from "services/categories";

const DEFAULT_STATE = {
  loading: false,
  list: [],
  count: 0,
  existData: false,
};

const DEFAULT_PARAMS = {
  page: 1,
  limit: SCROLL_LIMIT,
  search_with_children: "",
};

const DEFAULT_DRAWER_STATE = {
  state: false,
  element: null,
  parent: null,
};

export const useCategoriesTab = () => {
  const [expandParentId, setExpandParentId] = useState(null);
  const [categoriesState, setCategoriesState] = useState(DEFAULT_STATE);
  const [categoriesParams, setCategoriesParams] = useState(DEFAULT_PARAMS);
  const [openAddDrawer, setOpenAddDrawer] = useState(DEFAULT_DRAWER_STATE);

  const searchInputDebounced = useDebounce(
    categoriesParams.search_with_children,
    500
  );

  const setLoading = (loading) =>
    setCategoriesState((prev) => ({ ...prev, loading }));

  const setListSearch = (search_with_children) =>
    setCategoriesParams((prev) => ({ ...prev, search_with_children }));

  const onEditCategoryDrawerOpen = ({
    element = null,
    parent = null,
    isCreate = false,
  }) => {
    if (isCreate)
      return setOpenAddDrawer({ state: true, element: null, parent });
    return setOpenAddDrawer({ state: true, element, parent });
  };

  const onEditDrawerClose = () => setOpenAddDrawer(DEFAULT_DRAWER_STATE);

  const handleExpandChildrenList = (id) => {
    if (id === expandParentId) {
      setExpandParentId(null);
    } else {
      setExpandParentId(id);
    }
  };

  const fetchAfterSave = async () => {
    setLoading(true);

    try {
      const res = await getCategoriesService({
        search_with_children: searchInputDebounced,
        limit: categoriesParams.page * SCROLL_LIMIT,
      });
      const rows = res?.rows || [];
      const count = res?.count || 0;
      setCategoriesState((prev) => ({
        ...prev,
        list: rows,
        count: count,
        existData: res.existData,
      }));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  const handleFetchCategories = useCallback(
    async (page) => {
      if (!page) {
        setLoading(true);
      }
      try {
        const res = await getCategoriesService({
          ...categoriesParams,
          search_with_children: searchInputDebounced,
          page: page ? page : 1,
        });
        const rows = res?.rows || [];
        const count = res?.count || 0;
        setCategoriesState((prev) => ({
          ...prev,
          list: page > 1 ? [...prev.list, ...rows] : rows,
          count: count,
          existData: res.existData,
        }));
        setCategoriesParams((prev) => ({ ...prev, page: page ? page : 1 }));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong.");
      } finally {
        setLoading(false);
      }
    },
    [categoriesParams, searchInputDebounced]
  );

  const onSaveCallback = (list) => {
    onEditDrawerClose();

    if (list?.length)
      return setCategoriesState((prev) => ({
        ...prev,
        list,
        count: list.length,
      }));

    fetchAfterSave();
  };

  //const onEditSuccess = (updatedItem, oldItem) => {
  //  let list = [...categoriesState.list];

  //  if (oldItem.parentCategoryId) {
  //    const filteredList = list.filter(
  //      (category) => category.id === oldItem.parentCategoryId
  //    );

  //    if (!filteredList.length) return;

  //    let parentUpdateIndex = list.findIndex(
  //      (category) => category.id === oldItem.parentCategoryId
  //    );

  //    let updateIndex = filteredList?.[0]?.childCategories.findIndex(
  //      (category) => category.id === oldItem.id
  //    );

  //    if (updateIndex !== -1 && parentUpdateIndex !== -1) {
  //      list[parentUpdateIndex].childCategories[updateIndex] = {
  //        ...list[parentUpdateIndex].childCategories[updateIndex],
  //        ...updatedItem,
  //      };
  //      setCategoriesState((prev) => ({ ...prev, list }));
  //    }
  //  } else {
  //    let updateIndex = list.findIndex(
  //      (category) => category.id === oldItem.id
  //    );

  //    if (updateIndex !== -1) {
  //      list[updateIndex] = { ...list[updateIndex], ...updatedItem };
  //      setCategoriesState((prev) => ({ ...prev, list }));
  //    }
  //  }
  //  onEditDrawerClose();
  //};

  useEffect(() => {
    handleFetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInputDebounced]);

  return {
    setListSearch,
    searchInputDebounced,
    list: categoriesState.list,
    openAddDrawer,
    setOpenAddDrawer,
    onEditCategoryDrawerOpen,
    onEditDrawerClose,
    onSaveCallback,
    expandParentId,
    setExpandParentId,
    handleExpandChildrenList,
    searchValue: categoriesParams.search_with_children,
    categoryPage: categoriesParams.page,
    handleFetchCategories,
    categoryCount: categoriesState.count,
    categoriesLoading: categoriesState.loading,
    existCategoriesData: categoriesState.existData,
  };
};
