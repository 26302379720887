import { isValidNotPastDate } from "helpers/helpers";
import * as Yup from "yup";

export const validationSchema = (timeZone) =>
  Yup.object().shape({
    name: Yup.string().trim().required("This field is required"),
    priority: Yup.string().trim().required("This field is required"),
    stops: Yup.array().test(
      "stopsValidation",
      "Add at least one stop.",
      (value) => {
        return value.length > 0;
      }
    ),
    activationDate: Yup.string().test(
      "activationDate",
      "Invalid date",
      (value) => {
        if (!value) return true;
        return isValidNotPastDate(value, timeZone);
      }
    ),
  });
