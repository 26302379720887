import {
  CircularProgress,
  Divider,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Box,
  Link,
} from "@mui/material";
import { func, number, string } from "prop-types";
import { useContext, useMemo } from "react";
import { ImportStepOne, ImportStepThree, ImportStepTwo } from "./components";
import { IMPORT_STATUSES, STEPS } from "./ImportTab.constants";
import useStyles from "./styles";
import { AtentionIcon } from "components/Icons";
import { StyledTooltip } from "components";
import { ImportContext } from "Pages/SettingsPage/SettingsPage";

const ImportTab = ({ step, status, handleRetryRemap }) => {
  const classes = useStyles();
  const { importData } = useContext(ImportContext);

  const content = useMemo(() => {
    switch (step) {
      case 0:
        return <ImportStepOne />;
      case 1:
        return <ImportStepTwo />;
      case 2:
        return <ImportStepThree />;
      default:
        <></>;
    }
  }, [step]);

  const loadingProgress = useMemo(
    () =>
      [
        IMPORT_STATUSES.REMAP_PROGRESS.status,
        IMPORT_STATUSES.VALIDATION_PROGRESS.status,
        IMPORT_STATUSES.IMPORT_PROGRESS.status,
      ].some((s) => s === status),
    [status]
  );

  const error = useMemo(() => IMPORT_STATUSES[status], [status]);
  return (
    <Box className={classes.pageWrapper}>
      <Box className={classes.importPage}>
        <Box className={classes.importHeader}>
          <Box display="flex" alignItems="center" gap="20px">
            <Typography className={classes.title}>
              Import {importData.importType?.title || "Customers"}
            </Typography>
            {loadingProgress && <CircularProgress />}
            {(status === IMPORT_STATUSES.ERROR.status ||
              status === IMPORT_STATUSES.ERROR_REMAP.status) && (
              <StyledTooltip
                title={
                  <>
                    <span style={{ whiteSpace: "pre-wrap" }}>
                      {error.message}{" "}
                    </span>
                    {importData.step === 1 && (
                      <Link
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          if (importData.retryLoading) return;
                          handleRetryRemap();
                        }}
                      >
                        Try again
                      </Link>
                    )}
                  </>
                }
                arrow
                placement="top"
              >
                <Box>
                  <AtentionIcon size="30" />
                </Box>
              </StyledTooltip>
            )}
          </Box>
          <Stepper activeStep={step} connector={<></>}>
            {STEPS.map((label) => (
              <Step key={label} className={classes.step}>
                <StepLabel
                  StepIconComponent={({ completed, active, icon }) => {
                    return (
                      <Box
                        className={classes.stepIcon}
                        sx={{
                          border: `1px solid ${
                            completed || active ? "#47A06D" : "#B5B5AC"
                          }`,
                          color: completed || active ? "#47A06D" : "#B5B5AC",
                        }}
                      >
                        {icon}
                      </Box>
                    );
                  }}
                  sx={{
                    "& .MuiStepLabel-label": {
                      color: "#B5B5AC",
                      fontWeight: 400,
                      fontSize: "13px",
                      "&.Mui-active, &.Mui-completed": {
                        color: "#47A06D",
                      },
                    },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Divider />
        <Box className={classes.importBody}>{content}</Box>
      </Box>
    </Box>
  );
};

ImportTab.propTypes = {
  step: number,
  status: string,
  handleRetryRemap: func,
};

export default ImportTab;
