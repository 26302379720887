import { Box, Typography } from "@mui/material";
import { PrinterIcon } from "components/Icons";
import {
  normalizeEveryFirstLetterToUpper,
  normalizeSnakeCaseString,
  normalizeUppercaseString,
} from "helpers/helpers";

const replaceText = (text, from, to) => {
  return text.replace(from, to);
};

export const preparedType = (type, operationType, data) => {
  const emailedTo =
    data?.email?.emailedTo?.length && type !== "ORDER_EMAILED"
      ? " & Emailed"
      : "";

  if (
    ["PICKLIST_PRINTED", "INVOICE_PRINTED", "PACKING_SLIP_PRINTED"].includes(
      type
    )
  ) {
    return (
      <Box display="flex" alignItems="center" gap={1}>
        <Box>
          {type === "INVOICE_PRINTED"
            ? "Order printed"
            : normalizeEveryFirstLetterToUpper(type).replace(/_/g, " ")}
        </Box>
        <PrinterIcon width="17" />
      </Box>
    );
  }

  if (typeof type !== "string") return type;
  if (type === "FULFILL_BY_CHANGED" && !data?.values?.[0])
    return "Fulfill By Added";

  const typeToLowercase = type.toLowerCase();

  let text = replaceText(typeToLowercase, "operation", "Update");
  text = replaceText(text, "delivery", "Fulfillment");
  text = replaceText(text, "changed", "updated");

  if (!operationType) return `${normalizeSnakeCaseString(text)}${emailedTo}`;

  text = text.split("_")[0];

  return `Processed ${normalizeUppercaseString(text)}`;
};

export const getUpdatedReference = (data) => {
  const { paymentType = [], reference = [], check = [] } = data.payment;

  const isCash = paymentType?.[0] === "CASH";
  const oldRef = isCash ? reference?.find((r) => r) : check?.find((c) => c);
  const newRef = isCash ? check?.find((c) => c) : reference?.find((r) => r);

  return (
    <>
      <Typography fontSize={12}>Updated the reference:</Typography>
      <Typography fontSize={12}>Old reference: {oldRef}</Typography>
      <Typography fontSize={12}>New reference: {newRef}</Typography>
    </>
  );
};
