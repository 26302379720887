import { CUSTOMER_STATUS_FILTERS } from "utils/constants";
import {
  CREATE_CUSTOMER,
  GET_CUSTOMERS,
  START_LOADING_CUSTOMERS,
  END_LOADING_CUSTOMERS,
  UPDATE_CUSTOMER,
  DELETE_CUSTOMER,
  GET_REP_CUSTOMERS,
  START_LOADING_REP_CUSTOMERS,
  END_LOADING_REP_CUSTOMERS,
  GET_CUSTOMERS_INACTIVE,
  SHOW_INACTIVE_CUSTOMERS,
  SHOW_INACTIVE_REP_CUSTOMERS,
  CUSTOMERS_GET_PARAMS,
  GET_CUSTOMERS_COUNT,
  GET_CUSTOMERS_INACTIVE_COUNT,
  CUSTOMERS_REP_GET_PARAMS,
  EXIST_CUSTOMERS,
  CUSTOMERS_GET_FILTER_FIELD,
  CUSTOMER_GET_SWITCH,
  RESET_ACTION,
  RESET_ACTION_SWITCH,
  CUSTOMERS_GET_GROUP_FILTER_FIELD,
  CUSTOMER_GET_SWITCH_GROUP,
  RESET_ACTION_GROUP_SWITCH,
  RESET_ACTION_GROUP_FILTER,
  GET_INACTIVE_CUSTOMERS_DATA,
  GET_CUSTOMERS_DATA,
  CUSTOMER_STATUS_FILTER,
  CUSTOMER_STATUS_FILTER_BY_REP,
  SET_SEARCH_CUSTOMERS,
  SET_SEARCH_GROUPS,
} from "../actions/customers";

const ORDER_DIRECTS = [
  { value: "APPROVED", name: "Approved" },
  { value: "PENDING", name: "Pending" },
  { value: "REJECTED", name: "Rejected" },
  { value: null, name: "No order direct" },
];

export const initialCustomersState = {
  customers: [],
  customersFilters: {
    representative_id: "",
    territory_id: "",
    group_id: "",
    tag_id: [],
    sort_by: "",
    last_visit: "",
    last_order: "",
    missing_info: "",
    order_direct: "",
    tasks_due: "",
    has_assigned_routes: "",
    created_at: "",
    purchasedProducts: "",
  },
  customersSwitch: [
    {
      value: "representative_id",
      label: "Representative",
      type: "select",
      checked: false,
      selectLabel: "Select Rep",
    },
    {
      value: "last_visit",
      label: "Last visit",
      type: "date",
      checked: false,
      selectLabel: "Select",
    },
    {
      value: "territory_id",
      label: "Territory",
      type: "select",
      checked: false,
      selectLabel: "Select Territory",
    },
    {
      value: "last_order",
      label: "Last order",
      type: "date",
      checked: false,
      selectLabel: "Select",
    },
    {
      value: "group_id",
      label: "Group",
      type: "select",
      checked: false,
      selectLabel: "Select Group",
    },
    {
      value: "tag_id",
      label: "Tag",
      type: "multiselect",
      checked: false,
      selectLabel: "Select Tag",
    },
    {
      value: "sort_by",
      label: "Sort by",
      type: "select",
      checked: false,
      selectLabel: "Sort by",
      // values: ["sort_orders", "shipping_date"],
    },
    {
      value: "issues",
      label: "Issues",
      type: "switch",
      multiple: true,
      checked: false,
      values: ["Missing info", "Duplicates", "Expired license"],
      //values: [
      //  { value: "with_missing_info", name: "Missing info" },
      //  { value: "with_duplicated_info", name: "Duplicates" },
      //  { value: "with_expired_document", name: "Expired license" },
      //],
    },
    {
      value: "order_direct",
      label: "Order direct",
      type: "switch",
      checked: false,
      values: ORDER_DIRECTS,
    },
    {
      value: "tasks_due",
      label: "Tasks",
      type: "date",
      checked: false,
      selectLabel: "Select",
    },
    {
      value: "has_assigned_routes",
      label: "Route assignment",
      type: "switch",
      checked: false,
      values: ["ASSIGNED", "NOT_ASSIGNED"],
    },
    {
      value: "created_at",
      label: "Created at",
      type: "date",
      checked: false,
      selectLabel: "Select Date",
    },
    {
      value: "purchasedProducts",
      checked: false,
      label: "Products purchased",
      type: "element",
    },
  ],
  customersSwitchGroup: [
    {
      value: "sort_by",
      label: "Sort by",
      type: "select",
      checked: false,
      selectLabel: "Sort by",
    },
  ],
  searchCustomers: "",
  searchGroups: "",
  customersFiltersGroup: { sort_by: "" },
  inactive: [],
  repCustomers: [],
  repCustomersInactive: [],
  repCustomersLoading: false,
  inactiveCustomersCount: 0,
  showInactive: false,
  status: CUSTOMER_STATUS_FILTERS.active,
  showInactiveByRep: false,
  statusByRep: CUSTOMER_STATUS_FILTERS.active,
  loading: false,
  customersGetParams: {
    sort_visit: "desc",
    sort_parent_customer_name: "",
    sort_territory_name: "",
    sort_order_direct: "",
  },
  customersByRepGetParams: {
    status: `["${CUSTOMER_STATUS_FILTERS.active}"]`,
    page: 1,
    limit: 10,
    parent_customer_id: "",
    territory_id: "",
  },
  count: 0,
  countInactive: 0,
  existData: false,
  repCustomersCount: 0,
  repCustomersActiveCount: 0,
  repCustomersInactiveCount: 0,
  countData: {
    duplicatedInfoCount: 0,
    missingInfoCount: 0,
    expiredDocumentInfoCount: 0,
  },
  inactiveCountData: {
    duplicatedInfoCount: 0,
    missingInfoCount: 0,
    expiredDocumentInfoCount: 0,
  },
};

const customersReducer = (
  state = initialCustomersState,
  { type, payload, scrolling }
) => {
  switch (type) {
    case GET_CUSTOMERS:
      if (payload.isScrolling) {
        return {
          ...state,
          customers: [...state.customers, ...payload.customers],
        };
      }
      return { ...state, customers: [...payload.customers] };

    case EXIST_CUSTOMERS:
      return { ...state, existData: payload };

    case GET_REP_CUSTOMERS:
      return {
        ...state,
        repCustomers: scrolling
          ? [...state.repCustomers, ...payload.rows]
          : payload.rows,
        repCustomersCount: payload.count,
        repCustomersActiveCount: payload.countActive,
        repCustomersInactiveCount: payload.countInactive,
      };
    case CUSTOMERS_GET_FILTER_FIELD:
      return { ...state, customersFilters: payload };
    case CUSTOMER_GET_SWITCH:
      return { ...state, customersSwitch: payload };
    case CUSTOMERS_GET_GROUP_FILTER_FIELD:
      return { ...state, customersFiltersGroup: payload };
    case CUSTOMER_GET_SWITCH_GROUP:
      return { ...state, customersSwitchGroup: payload };
    case RESET_ACTION_GROUP_SWITCH:
      return {
        ...state,
        customersSwitchGroup: initialCustomersState.customersSwitchGroup,
      };
    case RESET_ACTION_GROUP_FILTER:
      return {
        ...state,
        customersFiltersGroup: initialCustomersState.customersFiltersGroup,
      };
    case RESET_ACTION:
      return {
        ...state,
        customersFilters: initialCustomersState.customersFilters,
      };
    case RESET_ACTION_SWITCH:
      return {
        ...state,
        customersSwitch: initialCustomersState.customersSwitch,
      };
    case GET_CUSTOMERS_COUNT:
      return { ...state, count: payload };

    case GET_CUSTOMERS_INACTIVE_COUNT:
      return { ...state, countInactive: payload };

    case GET_CUSTOMERS_INACTIVE:
      if (payload.isScrolling) {
        return {
          ...state,
          inactive: [...state.inactive, ...payload.customers],
        };
      }
      return { ...state, inactive: [...payload.customers] };

    case SHOW_INACTIVE_CUSTOMERS:
      return { ...state, showInactive: payload };

    case CUSTOMER_STATUS_FILTER:
      return { ...state, status: payload };

    case SHOW_INACTIVE_REP_CUSTOMERS:
      return { ...state, showInactiveByRep: payload };

    case CUSTOMER_STATUS_FILTER_BY_REP:
      return { ...state, statusByRep: payload };

    case CREATE_CUSTOMER:
      return { ...state, customers: [...state.customers, payload] };
    case UPDATE_CUSTOMER: {
      let newState = { ...state };
      let customersList = payload.active
        ? [...state.customers]
        : [...state.inactive];
      let updateIndex = customersList.findIndex((rep) => rep.id === payload.id);
      if (updateIndex >= 0) {
        customersList.splice(updateIndex, 1, payload);
        if (payload.active) newState = { ...state, customers: customersList };
        else newState = { ...state, inactive: customersList };
        return { ...newState, loading: false };
      }

      customersList = !payload.active
        ? [...state.customers]
        : [...state.inactive];
      updateIndex = customersList.findIndex((rep) => rep.id === payload.id);
      customersList.splice(updateIndex, 1);
      if (payload.active)
        newState = {
          ...state,
          customers: [...state.customers, payload],
          inactive: customersList,
        };
      else
        newState = {
          ...state,
          inactive: [...state.inactive, payload],
          customers: customersList,
        };
      return { ...newState, loading: false };
    }

    case DELETE_CUSTOMER: {
      let newState = { ...state };
      const customersList = [...newState.customers];

      payload.forEach((id) => {
        const index = customersList.findIndex((customer) => customer.id === id);
        if (index >= 0) {
          customersList.splice(index, 1);
        }
      });

      newState = { ...state, customers: customersList };
      return { ...newState, loading: false };
    }

    case CUSTOMERS_GET_PARAMS:
      return { ...state, customersGetParams: payload };

    case CUSTOMERS_REP_GET_PARAMS:
      return {
        ...state,
        customersByRepGetParams: {
          ...state.customersByRepGetParams,
          ...payload,
        },
      };

    case GET_CUSTOMERS_DATA:
      return {
        ...state,
        countData: {
          missingInfoCount: payload.missingInfoCount,
          duplicatedInfoCount: payload.duplicatedInfoCount,
          expiredDocumentInfoCount: payload.expiredDocumentInfoCount,
        },
      };

    case GET_INACTIVE_CUSTOMERS_DATA:
      return {
        ...state,
        inactiveCountData: {
          missingInfoCount: payload.missingInfoCount,
          duplicatedInfoCount: payload.duplicatedInfoCount,
          expiredDocumentInfoCount: payload.expiredDocumentInfoCount,
        },
      };

    case SET_SEARCH_CUSTOMERS:
      return { ...state, searchCustomers: payload };
    case SET_SEARCH_GROUPS:
      return { ...state, searchGroups: payload };

    case START_LOADING_CUSTOMERS:
      return { ...state, loading: true };
    case END_LOADING_CUSTOMERS:
      return { ...state, loading: false };
    case START_LOADING_REP_CUSTOMERS:
      return { ...state, repCustomersLoading: true };
    case END_LOADING_REP_CUSTOMERS:
      return { ...state, repCustomersLoading: false };

    default:
      return state;
  }
};

export default customersReducer;
