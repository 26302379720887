import { bool, func, object } from "prop-types";
import { Controller, useForm, useWatch } from "react-hook-form";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { CrossIcon } from "components/Icons";
import {
  LargeCheckbox,
  StyledButton,
  StyledTextField,
  StyledTooltip,
} from "components";
import React, { useEffect } from "react";
import useStyles from "../../../../../../CustomersPage/pages/NewCustomerPage/styles.js";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./ConnectIntegration.validations.js";

const ConnectIntegrationDialog = ({
  open,
  handleClose,
  handleConnect,
  integration,
}) => {
  const classes = useStyles();
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { isValid },
  } = useForm({ mode: "onChange", resolver: yupResolver(validationSchema()) });

  const formField = useWatch({ control });

  useEffect(() => {
    if (integration?.params?.length && open) {
      reset(
        integration.params.reduce((acc, cur) => {
          return { ...acc, [cur.key]: { value: cur.value, name: cur.name } };
        }, {})
      );
    }
  }, [integration.params, open, reset]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ padding: "13px 20px 15px 25px" }}
      >
        <Typography fontSize="20px">{integration.name}</Typography>
        <IconButton onClick={handleClose}>
          <CrossIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ minWidth: "377px", padding: "0 0 21px 25px" }}>
        <Typography fontSize="12px" whiteSpace="pre-wrap">
          {`Connect ${integration?.name} online and sync all data with\nSimplyDepo`}
        </Typography>
      </DialogContent>

      <Stack p="0 25px 21px 25px" spacing={2}>
        {integration?.params?.map((item) => (
          <Controller
            key={item?.key}
            render={({ field }) => (
              <LargeCheckbox
                size={16}
                formSx={{ pl: 0.4 }}
                label={
                  <Typography fontSize={12} fontWeight={300} color="#000">
                    {item?.name}
                  </Typography>
                }
                {...field}
                checked={!!formField?.[item.key]?.value}
                onChange={(e) =>
                  setValue(item.key, {
                    value: e.target.checked,
                    name: item.name,
                  })
                }
              />
            )}
            name={item?.key}
            control={control}
          />
        ))}
        {integration?.body?.map((item) => (
          <Controller
            key={item?.key}
            name={item?.key}
            control={control}
            render={({ field, fieldState: { error } }) => {
              return (
                <StyledTooltip
                  placement="right"
                  arrow
                  title={
                    error
                      ? error.message
                      : `You can find all credentials in shopify partners account`
                  }
                >
                  <Box>
                    <StyledTextField
                      adminIsAllowed
                      fullWidth
                      size="small"
                      label={item?.displayedName}
                      InputProps={{
                        className: classes.textInput,
                      }}
                      InputLabelProps={{
                        className: classes.inputLabel,
                      }}
                      error={error ? error.message : ""}
                      {...field}
                    />
                  </Box>
                </StyledTooltip>
              );
            }}
          />
        ))}
      </Stack>

      <DialogActions sx={{ padding: "0 28px 19px" }}>
        <StyledButton
          variant="contained"
          label={`Connect to ${integration.name}`}
          disabled={integration.body?.length ? !isValid : false}
          onClick={() => {
            const params = {};
            const body = {};

            if (integration?.params?.length) {
              integration?.params.map((item) => {
                params[item.key] = formField?.[item.key]?.value;
              });
            }

            if (integration?.body?.length) {
              integration?.body.map((item) => {
                body[item.key] = formField?.[item.key];
              });
            }

            handleSubmit(handleConnect({ integration, params, body }));
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

ConnectIntegrationDialog.propTypes = {
  open: bool,
  handleClose: func,
  handleConnect: func,
  integration: object,
};

export default ConnectIntegrationDialog;
