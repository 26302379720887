export const cl = {
  title: {
    PopperProps: {
      modifiers: [{ name: "offset", options: { offset: [0, -5] } }],
    },
    InputProps: { style: { fontSize: 14 } },
    InputLabelProps: { sx: { fontSize: 14, top: 3 } },
    sx: {
      "& .MuiOutlinedInput-root": { height: "44px" },
    },
  },
  description: {
    PopperProps: {
      modifiers: [{ name: "offset", options: { offset: [0, -5] } }],
    },
    InputProps: { style: { fontSize: 14 } },
    InputLabelProps: { sx: { fontSize: 14, top: 3 } },
    sx: {
      "& .MuiOutlinedInput-root": { height: "100%" },
    },
  },
  dueTime: {
    formSx: {
      "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":
        { borderColor: "#D6D9D980" },
    },
    sx: {
      "& .MuiSvgIcon-root": { color: "#9E9E9E" },
      "& svg": {
        width: 24,
        height: 26,
        top: "calc(50% - 13px)",
      },
    },
    labelSx: {
      color: "#00000099",
      fontSize: "14px !important",
      top: 3,
    },
    InputProps: {
      style: { fontSize: "14px !important" },
    },
    labelProps: { sx: { top: 3 } },
  },
  assignee: {
    labelSx: {
      color: "#00000099",
      fontSize: "14px !important",
      top: 3,
    },
    InputProps: { style: { fontSize: "14px !important" } },
    labelProps: { sx: { top: 3 } },
    paperPropsSx: { marginTop: "10px" },
  },
  priority: {
    labelSx: {
      color: "#00000099",
      fontSize: "14px !important",
      top: 3,
    },
    InputProps: { style: { fontSize: "14px !important" } },
    MenuItemSx: {
      width: "60px",
      height: "24px",
      borderRadius: "8px",
      "& p": {
        fontSize: "12px !important",
        textTransform: "lowercase",
      },
      "& p:first-letter": {
        textTransform: "capitalize",
      },
    },
    paperPropsSx: { marginTop: "8px" },
  },
};
