import StyledButton from "components/StyledButton";

export const SET_CONFIRM_OPEN = "SET_CONFIRM_OPEN";
export const SET_CONFIRM_TEXT1 = "SET_CONFIRM_TEXT1";
export const SET_CONFIRM_TITLE = "SET_CONFIRM_TITLE";
export const SET_CONFIRM_USERNAME = "SET_CONFIRM_USERNAME";
export const SET_CONFIRM_TEXT2 = "SET_CONFIRM_TEXT2";
export const SET_CONFIRM_ICON = "SET_CONFIRM_ICON";
export const SET_CONFIRM_BUTTONS = "SET_CONFIRM_BUTTONS";
export const SET_CONFIRM_PROP_BTNS = "SET_CONFIRM_PROP_BTNS";
export const SET_CONFIRM_CENTERED = "SET_CONFIRM_CENTERED";
export const SET_FORM_CHANGED = "SET_FORM_CHANGED";
export const SET_EDIT_ITEM = "SET_EDIT_ITEM";
export const SET_EDIT_TYPE = "SET_EDIT_TYPE";
export const SET_CONFIRM_SAVE = "SET_CONFIRM_SAVE";
export const SET_CONFIRM_PATH = "SET_CONFIRM_PATH";
export const SET_CONFIRM_DIALOG_STYLES = "SET_CONFIRM_DIALOG_STYLES";
export const SET_CONFIRM_DIALOG_BUTTONS_STYLES =
  "SET_CONFIRM_DIALOG_BUTTONS_STYLES";

export const SET_CONFIRM_IS_CANCEL_STYLES = "SET_CONFIRM_IS_CANCEL_STYLES";
export const RESET_CONFIRM_DIALOG = "RESET_CONFIRM_DIALOG";
export const SET_CONFIRM_DIALOG_IS_CONFIRM_DATA =
  "SET_CONFIRM_DIALOG_IS_CONFIRM_DATA";

export const setConfirmIsOpenAction = (payload) => ({
  type: SET_CONFIRM_OPEN,
  payload,
});

export const setConfirmText1Action = (payload) => ({
  type: SET_CONFIRM_TEXT1,
  payload,
});

export const setConfirmTitleAction = (payload) => ({
  type: SET_CONFIRM_TITLE,
  payload,
});

export const setConfirmUserNameAction = (payload) => ({
  type: SET_CONFIRM_USERNAME,
  payload,
});

export const setConfirmText2Action = (payload) => ({
  type: SET_CONFIRM_TEXT2,
  payload,
});

export const setConfirmIconAction = (payload) => ({
  type: SET_CONFIRM_ICON,
  payload,
});

export const setConfirmButtonsAction = (payload) => ({
  type: SET_CONFIRM_BUTTONS,
  payload,
});

export const setConfirmPropBtnsAction = (payload) => ({
  type: SET_CONFIRM_PROP_BTNS,
  payload,
});

export const setConfirmCenteredAction = (payload) => ({
  type: SET_CONFIRM_CENTERED,
  payload,
});

export const setFormChangedAction = (payload) => ({
  type: SET_FORM_CHANGED,
  payload,
});

export const setEditItemAction = (payload) => ({
  type: SET_EDIT_ITEM,
  payload,
});

export const setEditTypeAction = (payload, createMode = false) => ({
  type: SET_EDIT_TYPE,
  payload,
  createMode,
});

export const setOnConfirmSaveAction = (payload) => ({
  type: SET_CONFIRM_SAVE,
  payload,
});

export const setConfirmPathAction = (payload) => ({
  type: SET_CONFIRM_PATH,
  payload,
});

export const setConfirmDialogStylesAction = (payload) => ({
  type: SET_CONFIRM_DIALOG_STYLES,
  payload,
});

export const setConfirmDialogButtonsStylesAction = (payload) => ({
  type: SET_CONFIRM_DIALOG_BUTTONS_STYLES,
  payload,
});

export const setConfirmDialogIsCancelAction = (payload) => ({
  type: SET_CONFIRM_IS_CANCEL_STYLES,
  payload,
});

export const resetConfirmDialogAction = (payload = {}) => ({
  type: RESET_CONFIRM_DIALOG,
  payload,
});

export const setConfirmDialogIsConfirmDataAction = (payload = null) => ({
  type: SET_CONFIRM_DIALOG_IS_CONFIRM_DATA,
  payload,
});

export const openConfirmDialogAction = ({
  title,
  text,
  userName,
  text2,
  isIcon,
  buttons,
  propBtns,
  centered,
  path,
  dialogStyles,
  dialogButtonsStyles,
  isCancel,
  confirmData,
}) => {
  return (dispatch) => {
    dispatch(setConfirmIsOpenAction(true));
    dispatch(setConfirmTitleAction(title));
    dispatch(setConfirmText1Action(text));
    dispatch(setConfirmUserNameAction(userName));
    dispatch(setConfirmText2Action(text2));
    dispatch(setConfirmIconAction(isIcon));
    dispatch(setConfirmButtonsAction(buttons));
    dispatch(setConfirmPropBtnsAction(propBtns));
    dispatch(setConfirmCenteredAction(centered));
    dispatch(setConfirmPathAction(path));
    dispatch(setConfirmDialogStylesAction(dialogStyles));
    dispatch(setConfirmDialogButtonsStylesAction(dialogButtonsStyles));
    dispatch(setConfirmDialogIsCancelAction(isCancel));
    dispatch(setConfirmDialogIsConfirmDataAction(confirmData));
  };
};

export const openDiscardChanges = (onDiscard, onSave) => {
  return (dispatch, getState) => {
    const state = getState();
    const editType = state.confirmDialogs.editType;
    const createMode = state.confirmDialogs.createMode;

    dispatch(setConfirmIsOpenAction(true));
    dispatch(
      setConfirmTitleAction(
        createMode
          ? "Leave page with unsaved changes?"
          : "Discard all unsaved changes?"
      )
    );
    dispatch(
      setConfirmText1Action(
        createMode
          ? "Leaving this page will delete all unsaved changes."
          : "If you discard changes, you will delete any edits that you made since you last saved."
      )
    );

    const isButton = createMode || editType === "product_sku";

    const showStayText =
      editType === "product-photo" || editType === "product_sku" || createMode;

    const setForm = () => {
      if (editType === "reps_profile") return "rep-profile-form";

      return `new-${editType === "product_sku" ? "product" : editType}-form`;
    };

    dispatch(
      setConfirmButtonsAction(
        <>
          <StyledButton
            variant={createMode ? "outlined" : "contained"}
            color={createMode ? "edit" : "primary"}
            type={isButton || onSave ? "button" : "submit"}
            form={setForm()}
            label={showStayText ? "Stay" : "Save & Finish"}
            sx={{ height: "28px" }}
            onClick={(e) => {
              if (onSave) {
                e.preventDefault();
                onSave();
              }
              // dispatch(setConfirmIsOpenAction(false));
              // dispatch(resetConfirmDialogAction());
              showStayText
                ? dispatch(setConfirmIsOpenAction(false))
                : setTimeout(() => dispatch(resetConfirmDialogAction()), 0);
            }}
          />
          <StyledButton
            label={createMode ? "Leave page" : "Discard changes"}
            variant="contained"
            sx={{ height: "28px" }}
            color="confirmDelete"
            onClick={() => {
              dispatch(discardChangesAction(onDiscard));
              dispatch(resetConfirmDialogAction());
            }}
          />
        </>
      )
    );
  };
};

export const discardChangesAction = (onDiscard) => {
  return (dispatch) => {
    if (onDiscard) onDiscard();
    dispatch(setConfirmIsOpenAction(false));
    dispatch(setFormChangedAction(false));
    dispatch(setEditTypeAction(""));
    dispatch(setEditItemAction(null));
  };
};

export const finishChangesAction = () => {
  return (dispatch, getState) => {
    const state = getState();
    const onSubmit = state.confirmDialogs.onSave;
    onSubmit();
    dispatch(setConfirmIsOpenAction(false));
  };
};
