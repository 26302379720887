import { useCallback, useMemo, useState } from "react";
import { bool, array, func, object, number } from "prop-types";
import Nestable from "react-nestable";
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";

import { DragAndDropIcon, OutlinedPlusIcon } from "components/Icons";
import CloseIcon from "@mui/icons-material/Close";
import { generateUUID, truncateText } from "helpers/helpers";
import { Input } from "./index";
import { StyledTooltip } from "components";
import { cl } from "./SingleAnswerContentBlock.styles";

export const SingleAnswerContentBlock = ({
  isActive,
  options,
  setValue,
  formField,
  orderIndex,
  control,
  errors,
  trigger,
  _count,
}) => {
  const responses = _count?.responses || 0;

  const hasResponses = responses > 0;

  const [hoveredElement, setHoveredElement] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  const tempList = useMemo(() => [...formField?.questions], [formField]);
  const indexOfQuestion = useMemo(
    () => formField?.questions.findIndex((q) => q.orderIndex === orderIndex),
    [formField?.questions, orderIndex]
  );
  const questionsList = useMemo(
    () => tempList[indexOfQuestion]?.options,
    [indexOfQuestion, tempList]
  );

  const handleDragStart = () => setIsDragging(true);

  const handleAddOption = useCallback(() => {
    const preparedQuestion = {
      ...formField.questions[indexOfQuestion],
      options: [
        ...formField.questions[indexOfQuestion].options,
        {
          title: `Option ${
            formField.questions[indexOfQuestion].options.length + 1
          }`,
          uuid: generateUUID(),
          orderIndex: formField.questions[indexOfQuestion].options.length,
        },
      ],
    };

    tempList.splice(indexOfQuestion, 1, preparedQuestion);

    setValue("questions", tempList, { shouldDirty: true });
  }, [formField.questions, indexOfQuestion, setValue, tempList]);

  const handleDeleteOption = useCallback(
    (item) => {
      if (options?.length <= 1) return;

      const questionIndex = questionsList?.findIndex(
        (q) => q.uuid === item.uuid
      );

      questionsList.splice(questionIndex, 1);

      tempList.splice(indexOfQuestion, 1, {
        ...formField.questions[indexOfQuestion],
        options: questionsList.map((q, i) => ({ ...q, orderIndex: i })),
      });

      setValue("questions", tempList, { shouldDirty: true });
      trigger(`questions.${indexOfQuestion}.options`);
    },
    [
      formField.questions,
      indexOfQuestion,
      options?.length,
      questionsList,
      setValue,
      tempList,
      trigger,
    ]
  );

  const handleUpdateOption = useCallback(() => setActiveItem(null), []);

  const handleChangeDnd = useCallback(
    (items) => {
      setIsDragging(false);

      const preparedQuestion = {
        ...formField.questions[indexOfQuestion],
        options: [
          ...items.map((item, index) => {
            return {
              ...item,
              orderIndex: index,
            };
          }),
        ],
      };

      tempList.splice(indexOfQuestion, 1, preparedQuestion);

      setValue("questions", tempList, { shouldDirty: true });

      trigger(`questions.${indexOfQuestion}.options`);
    },
    [formField.questions, indexOfQuestion, setValue, tempList, trigger]
  );

  const disabledAddBtn = questionsList?.length >= 20;

  const handleClickDelete = useCallback(
    (e, item) => {
      if (!(isActive && hoveredElement === item?.uuid && options?.length > 1))
        return;

      e.stopPropagation();
      handleDeleteOption(item);
    },
    [handleDeleteOption, hoveredElement, isActive, options?.length]
  );

  return (
    <Box sx={cl.mainWrapper}>
      <Box mb={0.5}>
        <Typography fontSize={14} color="#56565680">
          Answer preview
        </Typography>
      </Box>
      <RadioGroup sx={cl.radioGroup}>
        {!!options?.length && (
          <Nestable
            handler={
              <Stack
                draggable
                alignItems="center"
                width="24px"
                height="24px"
                onDragStart={handleDragStart}
              >
                <DragAndDropIcon />
              </Stack>
            }
            maxDepth={0}
            idProp="uuid"
            items={options}
            onChange={({ items }) => handleChangeDnd(items)}
            renderItem={({ item, handler, index }) => (
              <Stack
                key={index}
                direction="row"
                alignItems="center"
                gap="5px"
                height="24px"
                maxWidth="480px"
                width="100%"
                ml="-28px"
                onMouseEnter={() => {
                  if (hasResponses) return;
                  setHoveredElement(item?.uuid);
                }}
                onMouseLeave={() => {
                  if (isDragging) return;
                  setHoveredElement(null);
                }}
              >
                <Box
                  component="span"
                  sx={{
                    opacity: isActive && hoveredElement === item?.uuid ? 1 : 0,
                    transition: "all 0.3s",
                    transform: "rotate(90deg)",
                  }}
                >
                  {handler}
                </Box>
                <FormControlLabel
                  sx={cl.formControlLabel({
                    isActive,
                    activeItem,
                    item,
                    errors,
                    orderIndex,
                  })}
                  label={
                    !!errors?.questions?.[orderIndex]?.options?.[
                      item?.orderIndex
                    ] ||
                    (!!activeItem?.uuid &&
                      activeItem?.uuid === item?.uuid &&
                      isActive) ? (
                      <Box ml="-4px">
                        <Input
                          {...{
                            handleUpdateOption,
                            item,
                            control,
                            orderIndex,
                            trigger,
                          }}
                        />
                      </Box>
                    ) : (
                      <Box sx={cl.iconWrapper}>
                        {item?.title
                          ? truncateText(item?.title, 56)
                          : "Enter the answer option"}
                        <Stack mr={0.5}>
                          <IconButton
                            sx={cl.iconButton({
                              isActive,
                              hoveredElement,
                              item,
                              options,
                            })}
                            onClick={(e) => handleClickDelete(e, item)}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Stack>
                      </Box>
                    )
                  }
                  control={<Radio />}
                  onClick={() => {
                    if (hasResponses) return;
                    setActiveItem(item);
                  }}
                  value={item?.title}
                />
              </Stack>
            )}
          />
        )}

        {!hasResponses && (
          <StyledTooltip
            arrow
            placement="left"
            isDark
            title="Max answer options reached"
            disableHoverListener={!disabledAddBtn}
            disableFocusListener={!disabledAddBtn}
            PopperProps={{
              modifiers: [
                {
                  name: "offset",
                  options: { offset: [0, -5] },
                },
              ],
            }}
          >
            <Box component="span">
              <Button
                disableRipple
                disabled={disabledAddBtn}
                sx={cl.addNewBtn}
                onClick={handleAddOption}
              >
                <OutlinedPlusIcon circleColor="#47A06D" plusColor="#47A06D" />
                Add new
              </Button>
            </Box>
          </StyledTooltip>
        )}
      </RadioGroup>
    </Box>
  );
};

SingleAnswerContentBlock.propTypes = {
  isActive: bool,
  options: array,
  setValue: func,
  formField: object,
  orderIndex: number,
  control: object,
  errors: object,
  trigger: func,
  _count: object,
};
