import { useMemo, useState } from "react";
import { array, object, func, number, string, bool } from "prop-types";
import { useWatch } from "react-hook-form";
import { Box, Grid, Typography } from "@mui/material";
import { ActionRestrictionWrapper, TagField, TagsDrawer } from "components";
import { TAG_TYPES } from "helpers/useTagsActions";
import {
  ADMIN_ONLY_VIEW_MESSAGE,
  ADMIN_ONLY_VIEW_MESSAGE_PAGE,
} from "utils/constants";

export const TagsBlock = ({
  control,
  tagsList,
  setValue,
  errors,
  setError,
  clearErrors,
  repPermissions,
  placeholderFontSize = "14px",
  textFieldProps = {},
  fieldSx = {},
  savingTags = false,
}) => {
  const formField = useWatch({ control });

  const [viewAllTags, setViewAllTags] = useState(false);

  const disabledRepPermissions = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.orders?.create_edit;
    }
    return false;
  }, [repPermissions]);

  return (
    <>
      <Grid display="flex" alignItems="center" xs={12} item>
        <Typography fontSize={12} fontWeight={500} color="#000">
          TAGS
        </Typography>
      </Grid>

      <Grid paddingRight={3} xs={12} item>
        <Box>
          <ActionRestrictionWrapper
            disableHoverListener={!disabledRepPermissions}
            title={
              repPermissions?.role === "SALES"
                ? ADMIN_ONLY_VIEW_MESSAGE
                : ADMIN_ONLY_VIEW_MESSAGE_PAGE
            }
          >
            <TagField
              fieldName="orderTags"
              placeholder="Order tag"
              placeholderFontSize={placeholderFontSize}
              setValue={setValue}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
              tagsList={tagsList}
              chosenTags={formField.orderTags}
              handleViewAll={() => setViewAllTags(true)}
              type="order"
              textFieldProps={textFieldProps}
              disabled={disabledRepPermissions || savingTags}
              fieldSx={fieldSx}
            />
          </ActionRestrictionWrapper>

          <TagsDrawer
            type={TAG_TYPES.order}
            open={viewAllTags}
            handleClose={() => setViewAllTags(false)}
            orderTags={formField.orderTags}
            handleSave={({ tags }) => {
              setValue("orderTags", tags);
              setViewAllTags(false);
            }}
            setCheckedCustomers={() => {}}
          />
        </Box>
      </Grid>
    </>
  );
};

TagsBlock.propTypes = {
  control: object,
  tagsList: array,
  orderTagsCount: number,
  handleFetchTags: func,
  tagsPage: number,
  setValue: func,
  errors: object,
  setError: func,
  clearErrors: func,
  repPermissions: object,
  textFieldProps: object,
  fieldSx: object,
  placeholderFontSize: string,
  savingTags: bool,
};
