import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Typography,
  Divider,
  Box,
} from "@mui/material";
import { bool, func, object, string } from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { CrossIcon } from "../../../../../../components/Icons";
import { StyledSelect } from "../../../../../../components/Selects";
import StyledButton from "../../../../../../components/StyledButton";
import { StyledTextField } from "../../../../../../components/TextFields/TextFields";
import { yupResolver } from "@hookform/resolvers/yup";
import { defaultValues } from "../CustomerCardPopup/CustomerCardPopup.constants";
import TypographyAddress from "../../../../../../components/TypographyAddress/TypographyAddress";
import useStyles from "./styles";
import lodash from "lodash";
import AddressField from "../../../AddressField/AddressField";
import { validationSchema } from "../CustomerCardPopup/CustomerCardPopup.validations";
import MapPopup from "../../../../pages/NewCustomerPage/MapPopup/MapPopup";
import { getFullAddressStripe } from "../../CustomerCreditCards.helpers";
import {
  updateCustomerCardService,
  updateDistributorPaymentCardsService,
} from "../../../../../../services/stripe";
import Loader from "../../../../../../components/Loader";
import { useDispatch } from "react-redux";
import { getOrderByIdAction } from "../../../../../../redux/actions/orders";
import { error, success } from "../../../../../../utils/notifications";
import { CardIconComponent } from "../../../../../../components/CardIconComponent/CardIconComponent";
import { getDistributorPaymentCardsAction } from "../../../../../../redux/actions/auth";
import { setCardName } from "../../../../../../helpers/helpers";
import { addressFieldOptions } from "Pages/CustomersPage/pages/CustomerPage/components/CustomerMainBody/components/AddressesBlock/AddressesBlock.constants";

const EditPaymentPopup = ({
  isOpen,
  customerId,
  distributorId,
  orderId,
  handleClose,
  card,
  handleSave,
  successMsg,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [toggleDetails, setToggleDetails] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [mapOpen, setMapOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      id: card?.id || "",
      fid: card?.fid || "",
      name: card?.name || card?.billingAddress?.name || defaultValues.name,

      expYear: card?.expYear || defaultValues.exp,
      expMonth: card?.expMonth || defaultValues.exp,
      billingAddress:
        {
          zip: card?.billingAddress?.zip,
          street: card?.billingAddress?.street,
          state: card?.billingAddress?.state,
          city: card?.billingAddress?.city,
          appartement: card?.billingAddress?.appartement,
          lat: null,
          lng: null,
        } || defaultValues.billingAddress,
      defaultMethod: card?.defaultMethod || false,
    },
    resolver: yupResolver(
      validationSchema({ isEdit: true, editingAddress: editAddress })
    ),
  });

  const formField = useWatch({ control });

  useEffect(() => {
    reset({
      id: card?.id || "",
      fid: card?.fid || "",
      name: card?.name || card?.billingAddress?.name || defaultValues.name,
      expYear: card?.expYear || defaultValues.exp,
      expMonth: card?.expMonth || defaultValues.exp,
      billingAddress:
        {
          zip: card?.billingAddress?.zip,
          street: card?.billingAddress?.street,
          state: card?.billingAddress?.state,
          city: card?.billingAddress?.city,
          appartement: card?.billingAddress?.appartement,
          lat: null,
          lng: null,
        } || defaultValues.billingAddress,
      defaultMethod: card?.defaultMethod || false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, reset]);

  const onSubmit = (data) => {
    // const { exp, fid, ...uploadData } = data;

    const addressFormatted = lodash.pickBy(
      {
        // id: card?.id || null,
        name: data?.name || card?.billingAddress?.name,
        defaultMethod: card?.defaultMethod === "true" || card?.defaultMethod,
        expMonth: data?.expMonth || card?.expMonth,
        expYear: data?.expYear || card?.expYear,
        number: card?.number || null,
        cvc: card?.cvc || null,
      },
      (value) => value !== null
    );
    const billingAddressFormatted = lodash.pickBy(
      {
        formatted_address:
          getFullAddressStripe(
            (!!data?.billingAddress?.city && data?.billingAddress) ||
              card?.billingAddress?.address
          ) || null,
        zip:
          (data?.billingAddress?.zip && data?.billingAddress?.zip) ||
          card?.billingAddress?.address?.zip ||
          card?.billingAddress?.address?.postal_code ||
          card?.billingAddress?.zip, // distributor
        city:
          (data?.billingAddress?.city && data?.billingAddress?.city) ||
          card?.billingAddress?.address?.city ||
          card?.billingAddress?.city || // distributor
          null,
        state:
          (data?.billingAddress?.state && data?.billingAddress?.state) ||
          card?.billingAddress?.address?.state ||
          card?.billingAddress?.state || // distributor
          null,
        street:
          (data?.billingAddress?.street && data?.billingAddress?.street) ||
          card?.billingAddress?.address?.street ||
          card?.billingAddress?.address?.line1 ||
          card?.billingAddress?.street || // distributor
          null,
        appartement:
          (data?.billingAddress?.appartement &&
            data?.billingAddress?.appartement) ||
          card?.billingAddress?.address?.appartement ||
          card?.billingAddress?.address?.line2 ||
          card?.billingAddress?.appartement || // distributor
          null,
        lat:
          data?.billingAddress?.lat || card?.billingAddress?.address?.lat || 0,
        lng:
          data?.billingAddress?.lng || card?.billingAddress?.address?.lng || 0,
      },
      (value) => value !== null
    );

    if (customerId) {
      setIsLoading(true);
      updateCustomerCardService(customerId, card?.id, {
        ...addressFormatted,
        billingAddress: billingAddressFormatted,
      })
        .then((res) => {
          handleSave(res?.rows);
          if (orderId) {
            dispatch(getOrderByIdAction(orderId));
          }
          success(successMsg ? successMsg : "Card updated");
        })
        .catch((err) => {
          error("Something went wrong!");
          // eslint-disable-next-line no-console
          console.error(err);
        })
        .finally(() => {
          setIsLoading(false);
          setEditAddress(false);
          handleClose();
        });
    }
    if (distributorId) {
      setIsLoading(true);
      updateDistributorPaymentCardsService(card?.id, {
        ...addressFormatted,
        billingAddress: billingAddressFormatted,
      })
        .then(() => {
          // handleSave();
          if (orderId) {
            dispatch(getOrderByIdAction(orderId));
          }
          success("Card updated");
          dispatch(getDistributorPaymentCardsAction());
        })
        .catch((err) => {
          error("Something went wrong!");
          // eslint-disable-next-line no-console
          console.error(err);
        })
        .finally(() => {
          setIsLoading(false);
          setEditAddress(false);
          handleClose();
        });
    }
  };

  const { heightOfGoogleAddresses = 0 } = formField || {};

  const setMaxHeight = useMemo(() => {
    if (toggleDetails) {
      return heightOfGoogleAddresses < 178
        ? "0px"
        : `${heightOfGoogleAddresses - 160}px`;
    }

    if (heightOfGoogleAddresses > 0) {
      return `${heightOfGoogleAddresses}px`;
    }

    return "0px";
  }, [heightOfGoogleAddresses, toggleDetails]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: { maxHeight: "calc(100vh - 11px)" },
      }}
    >
      <Loader isLoading={isLoading} />
      <MapPopup
        isOpen={mapOpen}
        handleClose={() => setMapOpen(false)}
        address={formField.billingAddress}
        handleSetAddress={(billingAddress) =>
          setValue("billingAddress", billingAddress)
        }
      />
      <DialogTitle className={classes.dialogTitle}>
        <Typography className={classes.title}>Edit payment method</Typography>
        <IconButton onClick={handleClose}>
          <CrossIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <form id="edit-payment-form" onSubmit={handleSubmit(onSubmit)}>
          <Box className={classes.editFields}>
            <Box>
              <Typography mb="8px" className={classes.blockTitle}>
                Payment method
              </Typography>
              <Box className={classes.methodBlock}>
                <CardIconComponent type={card?.brand} size={22} />
                <Typography className={classes.dialogText}>
                  <span style={{ fontWeight: "400" }}>
                    {setCardName(card?.brand)}
                  </span>{" "}
                  ending in {card?.number?.slice(-4) || card?.last4}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography className={classes.blockTitle}>
                Name on card
              </Typography>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <StyledTextField
                    disabled={card?.hasExpiresDateIsDecline}
                    error={error?.message || ""}
                    {...field}
                  />
                )}
                name="name"
                control={control}
              />
            </Box>
            <Box>
              <Typography className={classes.blockTitle}>
                Expiration date
              </Typography>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <StyledSelect
                    disabled={card?.hasExpiresDateIsDecline}
                    {...field}
                    notched={false}
                    error={error?.message || ""}
                    value={parseInt(field.value)}
                    formSx={{ width: "62px" }}
                    noErrorMessage
                  >
                    {lodash.range(1, 13).map((month) => (
                      <MenuItem
                        disabled={
                          formField.expYear === new Date().getFullYear() &&
                          month < new Date().getUTCMonth() + 1
                        }
                        key={month}
                        value={month}
                      >
                        {month}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                )}
                name="expMonth"
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <StyledSelect
                    disabled={card?.hasExpiresDateIsDecline}
                    {...field}
                    notched={false}
                    formSx={{ ml: "8px" }}
                  >
                    {lodash
                      .range(
                        new Date().getFullYear(),
                        new Date().getFullYear() + 9
                      )
                      .map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                  </StyledSelect>
                )}
                name="expYear"
                control={control}
              />
            </Box>
          </Box>
          <Box mt="20px">
            <Box display="flex" alignItems="center" mt="14px" mb="7px">
              <Typography className={classes.addressTitle}>
                {editAddress ? "Current billing" : "Billing"} address
              </Typography>

              {!card?.hasExpiresDateIsDecline && (
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ height: "14px", mt: "3px", mx: "4px" }}
                />
              )}

              {editAddress ? (
                <>
                  {!card?.hasExpiresDateIsDecline && (
                    <StyledButton
                      label="Cancel"
                      sx={{ minWidth: "20px", height: "20px", p: "1px" }}
                      fontWeight="400"
                      onClick={() => setEditAddress(false)}
                      color="error"
                    />
                  )}
                </>
              ) : (
                <>
                  {!card?.hasExpiresDateIsDecline && (
                    <StyledButton
                      label="Edit"
                      sx={{ minWidth: "20px", height: "20px", p: "1px" }}
                      fontWeight="400"
                      onClick={() => setEditAddress(true)}
                    />
                  )}
                </>
              )}
            </Box>
            <TypographyAddress
              address={
                card?.billingAddress?.formatted_address ||
                getFullAddressStripe(card?.billingAddress?.address)
              }
              styles={{
                fontSize: "13px",
                fontWeight: 400,
                color: "#363531",
              }}
            />
            {editAddress && (
              <>
                <AddressField
                  withoutAbsoluteLabel
                  curtainProps={{ curtainHeight: "198px" }}
                  isEdit={editAddress}
                  control={control}
                  clearErrors={clearErrors}
                  setValue={setValue}
                  label="Address"
                  fieldName="billingAddress"
                  address={formField.billingAddress}
                  addressToggled={toggleDetails}
                  wrapperProps={{ mt: "16px" }}
                  onMapOpen={() => {
                    setMapOpen(true);
                  }}
                  typingTrigger={() => {
                    setValue("billingAddress.lat", null);
                    setValue("billingAddress.lng", null);
                  }}
                  typeSetValue="heightOfGoogleAddresses"
                  errorCoordinates={
                    !!errors?.billingAddress?.lat ||
                    !!errors?.billingAddress?.lng
                  }
                  toggleButtonProps={{
                    sx: {
                      height: "43px",
                      ml: 1.5,
                      width: "34px",

                      "& .MuiTypography-root": { fontSize: "18px" },
                      borderColor: errors?.billingAddress
                        ? "#F2545B !important"
                        : "#d5d9d9 !important",
                      borderRadius: "4px 0 0 4px",
                      borderWidth: "1px 0px 1px 1px",
                    },
                  }}
                  {...addressFieldOptions}
                  onToggle={() => {
                    setToggleDetails(!toggleDetails);
                  }}
                  error={!!errors.billingAddress}
                />
                <Box
                  sx={{
                    maxHeight: setMaxHeight,
                    overflow: "hidden",
                    transition: "all 0.5s ease",
                    opacity: 0,
                    pointerEvents: "none",
                  }}
                  height={"478px"}
                />
              </>
            )}
          </Box>
        </form>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <StyledButton
          label="Cancel"
          color="cancel"
          variant="outlined"
          fontSize="10px"
          onClick={() => {
            handleClose();
            setEditAddress(false);
          }}
        />
        <StyledButton
          label="Save"
          type="submit"
          variant="contained"
          fontSize="10px"
          form="edit-payment-form"
        />
      </DialogActions>
    </Dialog>
  );
};

EditPaymentPopup.propTypes = {
  isOpen: bool,
  customerId: string,
  distributorId: string,
  orderId: string,
  handleClose: func,
  card: object,
  handleSave: func,
  setMapOpen: func,
  successMsg: string,
};

EditPaymentPopup.defaultProps = {
  isOpen: false,
  card: null,
};

export default EditPaymentPopup;
