import { useContext, useMemo } from "react";
import { Controller } from "react-hook-form";
import {
  Box,
  Chip,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CustomerContext } from "Pages/CustomersPage/pages/CustomerPage/CustomerPage";
import { useTags } from "Pages/CustomersPage/pages/CustomerPage/CustomerPage.hooks";
import { TAG_TYPES, useTagsActions } from "helpers/useTagsActions";

export const TagsBlock = () => {
  const { control, setValue, repPermissions } = useContext(CustomerContext);

  const isDisabledCreateTagsPermissions = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.customers?.customer_tags;
    }
  }, [repPermissions]);

  const { list: tagsList } = useTagsActions({
    open,
    type: TAG_TYPES.customer,
    params: {
      sort_by_tag: "asc",
      limit: null,
      page: null,
    },
  });

  const {
    selectedTagsList,
    handlePressEnter,
    handleBlur,
    handleAddTag,
    handleDeleteTag,
  } = useTags({ control, setValue, tagsList });

  return (
    <>
      <Grid xs={12} item>
        <Controller
          render={({ field }) => (
            <FormControl fullWidth>
              <TextField
                size="small"
                inputProps={{ maxLength: 50 }}
                InputProps={{
                  sx: {
                    minHeight: "43px",
                    fontSize: 13,
                    flexDirection: "column",
                    pt: selectedTagsList.length ? "8px" : "4px",
                    pr: "14px",
                    "& input": { pl: "14px" },
                  },
                  startAdornment: (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 0.5,
                      }}
                    >
                      {selectedTagsList?.map((tag) => (
                        <Chip
                          sx={{
                            border: "1px solid #D4D4D4",
                            bgcolor: "#FAFAFB",
                            fontSize: 14,
                            color: "#5F6368",
                            cursor: "pointer",
                            maxWidth: "100%",
                          }}
                          key={tag?.id}
                          label={tag?.tag}
                          size="small"
                          deleteIcon={
                            <CloseIcon size="15" style={{ fill: "#707070" }} />
                          }
                          onDelete={() => handleDeleteTag(tag?.id)}
                        />
                      ))}
                    </Box>
                  ),
                }}
                disabled={isDisabledCreateTagsPermissions}
                onKeyDown={handlePressEnter}
                {...field}
                onBlur={handleBlur}
              />
            </FormControl>
          )}
          control={control}
          name="tagInput"
        />
      </Grid>

      <Grid xs={12} item>
        <Typography fontSize={13} color="#9F9F9F">
          Used to categorize customers
        </Typography>
      </Grid>

      <Grid mt={2} xs={12} item container gap={1.5}>
        {tagsList?.map((tag) => (
          <Chip
            disabled={selectedTagsList.some((t) => t?.id === tag?.id)}
            sx={{
              border: "1px solid #D4D4D4",
              bgcolor: "#FAFAFB",
              fontSize: 14,
              color: "#5F6368",
              cursor: "pointer",
              paddingBottom: "1px",
              maxWidth: "100%",
            }}
            key={tag?.id}
            label={tag?.tag}
            size="small"
            onClick={() => handleAddTag(tag)}
          />
        ))}
      </Grid>
    </>
  );
};
