import {
  DELIVERY_METHODS,
  PAYMENT_STATUSES,
  DELIVERY_STATUSES,
  ORDER_STATUSES,
  ARCHIVED,
} from "../../Pages/OrdersPage/Orders.constants";
import {
  GET_ORDERS,
  START_LOADING_ORDERS,
  END_LOADING_ORDERS,
  CREATE_ORDER,
  GET_ORDERS_COUNT,
  GET_ORDERS_PARAMS,
  SET_EDIT_ORDER,
  UPDATE_ORDER,
  GET_ACTIVITIES,
  GET_ACTIVITIES_COUNT,
  EXIST_ORDERS,
  GET_ORDERS_THIRD_PARTY_LIST,
  GET_ORDERS_THIRD_PARTY_COUNT,
  EXIST_ORDERS_THIRD_PARTY,
  GET_ORDERS_PARAMS_THIRD_PARTY,
  SET_CURRENT_ORDER_TAB,
  ORDERS_GET_FILTER_FIELD,
  ORDERS_GET_SWITCH,
  RESET_ORDERS_ACTION_FILTER,
  RESET_ORDERS_ACTION_SWITCH,
  QUICK_FILTER,
  RESET_QUICK_FILTER,
  GET_ORDERS_HAS_ORDER_DIRECT,
  SET_ORDERS_HAS_QB,
  GET_EXIST_WITH_NO_SALES_ASSIGNED,
  SET_ORDERS_PARAMS,
  SET_THIRD_PARTY_LOADING,
  END_THIRD_PARTY_LOADING,
} from "../actions/orders";

export const initialOrdersState = {
  orders: [],
  count: 0,
  hasOrderDirect: false,
  hasQbOrders: false,
  loading: false,
  params: {
    search: "",
    sort_by_shipping_date: null,
    sort_by_order_date: "desc",
  },
  ordersQuickFilter: {
    name: "",
    value: "",
  },
  ordersFilter: {
    order_status: "",
    payment_status: "",
    delivery_status: "",
    fulfillment_method: "",
    sort_by: "",
    payment_due: "",
    archived: "",
    fulfill_by: "",
    tag_id: [],
    date: "",
    purchasedProducts: "",
  },
  ordersSwitch: [
    {
      value: "sales_id",
      label: "Created by",
      type: "select",
      checked: false,
      selectLabel: "Select Rep",
    },
    {
      value: "sort_by",
      label: "Sort by",
      type: "switch",
      checked: false,
      values: ["order_date", "shipping_date"],
    },
    {
      value: "order_status",
      label: "Order status",
      type: "switch",
      values: ORDER_STATUSES,
      checked: false,
      multiple: true,
    },
    {
      value: "payment_status",
      label: "Payment status",
      type: "switch",
      values: PAYMENT_STATUSES,
      checked: false,
      multiple: true,
    },
    {
      value: "fulfillment_status",
      label: "Fulfillment status",
      selectLabel: "Fulfill By",
      type: "switch",
      values: DELIVERY_STATUSES,
      checked: false,
      multiple: true,
    },
    {
      value: "fulfillment_method",
      label: "Fulfillment method",
      type: "switch",
      values: DELIVERY_METHODS,
      checked: false,
    },
    {
      value: "archived",
      label: "Archived",
      type: "switch",
      values: ARCHIVED,
      checked: false,
    },
    {
      value: "tag_id",
      label: "Tag",
      type: "multiselect",
      checked: false,
      selectLabel: "Select Tag",
    },
    {
      value: "date",
      label: "Created at",
      type: "date",
      checked: false,
      selectLabel: "Select Date",
    },
    {
      value: "purchasedProducts",
      checked: false,
      label: "Products purchased",
      type: "element",
    },
  ],
  editOrder: {},
  activities: [],
  activitiesCount: 0,
  existData: false,

  ordersThirdPartyList: [],
  ordersThirdPartyCount: 0,
  thirdPartyLoading: false,
  existWithNoSalesAssigned: false,
  existDataThirdParty: false,
  paramsThirdParty: {
    search: "",
    sort_by_order_date: "desc",
    archived: false,
  },
  currentTab: "",
};

const ordersReducer = (state = initialOrdersState, { type, payload }) => {
  switch (type) {
    case GET_ORDERS:
      if (payload.isScrolling) {
        return {
          ...state,
          orders: [...state.orders, ...payload.orders],
        };
      }
      return { ...state, orders: payload.orders };

    case EXIST_ORDERS:
      return { ...state, existData: payload };

    case GET_ORDERS_PARAMS:
      return { ...state, params: payload };

    case GET_ORDERS_COUNT:
      return { ...state, count: payload };

    case GET_ORDERS_HAS_ORDER_DIRECT:
      return { ...state, hasOrderDirect: payload };

    case SET_ORDERS_HAS_QB:
      return { ...state, hasQbOrders: payload };

    case CREATE_ORDER:
      return { ...state, orders: [...state.orders, payload] };

    case UPDATE_ORDER: {
      const ordersList = [...state.orders];
      const updateIndex = ordersList.findIndex(
        (order) => order.id === payload.id
      );
      if (updateIndex >= 0) {
        ordersList.splice(updateIndex, 1, payload);
      }
      return { ...state, payterms: ordersList, loading: false };
    }
    case ORDERS_GET_FILTER_FIELD:
      return { ...state, ordersFilter: payload };
    case ORDERS_GET_SWITCH:
      return { ...state, ordersSwitch: payload };
    case RESET_ORDERS_ACTION_FILTER:
      return { ...state, ordersFilter: initialOrdersState.ordersFilter };
    case RESET_ORDERS_ACTION_SWITCH:
      return { ...state, ordersSwitch: initialOrdersState.ordersSwitch };
    case QUICK_FILTER:
      return { ...state, ordersQuickFilter: payload };
    case RESET_QUICK_FILTER:
      return {
        ...state,
        ordersQuickFilter: initialOrdersState.ordersQuickFilter,
      };
    case GET_ACTIVITIES:
      return { ...state, activities: payload };

    case GET_ACTIVITIES_COUNT:
      return { ...state, activitiesCount: payload };

    case SET_EDIT_ORDER:
      return { ...state, editOrder: payload };

    case SET_CURRENT_ORDER_TAB:
      return { ...state, currentTab: payload };

    case SET_ORDERS_PARAMS:
      return { ...state, params: payload };

    case START_LOADING_ORDERS:
      return { ...state, loading: true };

    case SET_THIRD_PARTY_LOADING:
      return { ...state, thirdPartyLoading: true };

    case END_LOADING_ORDERS:
      return { ...state, loading: false };

    case END_THIRD_PARTY_LOADING:
      return { ...state, thirdPartyLoading: false };

    case GET_ORDERS_THIRD_PARTY_LIST:
      return { ...state, ordersThirdPartyList: payload };

    case GET_EXIST_WITH_NO_SALES_ASSIGNED:
      return { ...state, existWithNoSalesAssigned: payload };

    case GET_ORDERS_THIRD_PARTY_COUNT:
      return { ...state, ordersThirdPartyCount: payload };

    case EXIST_ORDERS_THIRD_PARTY:
      return { ...state, existDataThirdParty: payload };

    case GET_ORDERS_PARAMS_THIRD_PARTY:
      return { ...state, paramsThirdParty: payload };

    default:
      return state;
  }
};

export default ordersReducer;
