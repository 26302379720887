import {
  Box,
  Divider,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { SimpleArrowDownIcon } from "components/Icons";
import { array, bool } from "prop-types";
import { useState } from "react";
import { useStyles } from "../styles";
import { ActionRestrictionWrapper } from "components";

export const DropDownActionsSelect = ({ dropDownActions = [], disabled }) => {
  const classes = useStyles();

  const [openSelectMenu, setOpenSelectMenu] = useState(false);

  return (
    <FormControl fullWidth size="small" sx={{ width: "110px" }}>
      <Select
        displayEmpty
        disabled={disabled}
        value=""
        open={openSelectMenu}
        onClick={() => {
          if (disabled) return;
          setOpenSelectMenu(!openSelectMenu);
        }}
        input={<OutlinedInput />}
        renderValue={() => (
          <Box
            m={0}
            component="span"
            sx={{
              fontSize: "12px",
              WebkitTextFillColor: "#fff !important",
              opacity: disabled ? "0.5 !important" : null,

              "&:hover": {
                textDecoration: disabled
                  ? "none !important"
                  : "underline !important",
              },
            }}
          >
            More Actions
          </Box>
        )}
        inputProps={{ "aria-label": "Without label" }}
        MenuProps={{
          PaperProps: {
            className: classes.selectPaper,
            sx: {
              "& .MuiMenuItem-root": {
                mx: "6px",
                p: "5px 6px",
              },

              "& .MuiMenu-list": {
                py: "6px",
              },
            },
          },
        }}
        IconComponent={(props) => (
          <SimpleArrowDownIcon
            style={{
              width: "8px",
              height: "10px",
              fill: "#fff",
              marginTop: "4px",
              opacity: disabled ? 0.5 : 1,
            }}
            {...props}
          />
        )}
        className={classes.selectWrapper}
      >
        {!!dropDownActions?.length &&
          dropDownActions.map(
            (
              {
                sx,
                label,
                disabled,
                onClick,
                element,
                show,
                hasTooltip,
                withDividerAfter,
                withDividerBefore,
                disabledPermissions,
              },
              index
            ) => {
              const disabledPermission =
                disabledPermissions instanceof Object &&
                typeof disabledPermissions?.disabled === "boolean"
                  ? disabledPermissions?.disabled
                  : disabledPermissions;
              const title =
                disabledPermissions instanceof Object &&
                typeof disabledPermissions?.title === "string"
                  ? disabledPermissions?.title
                  : "";

              return (
                <ActionRestrictionWrapper
                  key={index}
                  disableHoverListener={!disabledPermission}
                  {...(!!title && { title })}
                >
                  <Box>
                    {withDividerBefore && show && <Divider />}
                    {show && hasTooltip && element}
                    {show && !hasTooltip && (
                      <MenuItem
                        sx={sx}
                        disabled={disabled || disabledPermission}
                        onClick={onClick}
                      >
                        {element ? (
                          element
                        ) : (
                          <ListItemText>{label}</ListItemText>
                        )}
                      </MenuItem>
                    )}
                    {withDividerAfter && show && <Divider />}
                  </Box>
                </ActionRestrictionWrapper>
              );
            }
          )}
      </Select>
    </FormControl>
  );
};

DropDownActionsSelect.propTypes = {
  dropDownActions: array,
  disabled: bool,
};
