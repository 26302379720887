import React, { useCallback, useContext, useState } from "react";
import { TableBody, TableHeader } from "./styles";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { array, func } from "prop-types";
import { ProspectTableItem } from "./components";
import { FilterSearchTextField, InfiniteLoaderWrapper } from "components";
import { CUSTOMER_STATUS_FILTERS } from "utils/constants";
import { ProspectsPageContext } from "Pages/CustomersPage/components/ProspectsTab/ProspectsTab";

const ProspectsTable = ({
  prospects,
  checkedProspects,
  handleCheckProspect,
}) => {
  const { handleSearchChange, currentLoading } =
    useContext(ProspectsPageContext);

  const [searchValue, setSearchValue] = useState("");

  const handleLocalSearchChange = useCallback(
    (e) => {
      if (!e.target) return;
      const { value } = e.target;
      setSearchValue(value);
      handleSearchChange(value);
    },
    [handleSearchChange]
  );

  return (
    <Box height="100%">
      <TableHeader sx={{ borderRadius: "4px 4px 0 0" }}>
        <Typography variant="header" fontWeight="600" fontSize="15px">
          Prospects
        </Typography>
      </TableHeader>

      {!currentLoading && !!prospects.length && (
        <TableHeader sx={{ backgroundColor: "#FFF" }}>
          <FilterSearchTextField
            adminIsAllowed
            value={searchValue}
            onChange={handleLocalSearchChange}
            formSx={{ minWidth: "380px" }}
            placeholderWidth="308px"
            placeholder="Search by customer name or address"
            fullWidth
            adornmentProps={{ sx: { justifyContent: "flex-end" } }}
          />
        </TableHeader>
      )}

      <TableBody>
        {currentLoading ? (
          <Stack height="100%" alignItems="center" justifyContent="center">
            <CircularProgress size="30px" />
          </Stack>
        ) : (
          <>
            {prospects.length ? (
              <InfiniteLoaderWrapper
                itemsList={prospects}
                itemsCount={0}
                offsetHeight={347}
                loading={false}
                handleFetch={() => {}}
                itemHeight={115}
                tableStyle={{ borderWidth: "0px" }}
                bordersWidthProp={0.5}
                listProps={{ width: 458 }}
                renderChildren={(index) => (
                  <ProspectTableItem
                    prospect={prospects[index]}
                    index={index}
                    checked={checkedProspects.some(({ id, foursquareId }) =>
                      prospects[index].status ===
                      CUSTOMER_STATUS_FILTERS.prospect
                        ? id === prospects[index].id
                        : id === prospects[index].id ||
                          foursquareId === prospects[index].fsq_id
                    )}
                    handleCheckProspect={handleCheckProspect}
                  />
                )}
              />
            ) : (
              <Box
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography fontSize="12px" color="groundLighter">
                  Please select an area on the map or search for location
                </Typography>
              </Box>
            )}
          </>
        )}
      </TableBody>
    </Box>
  );
};

ProspectsTable.propTypes = {
  prospects: array,
  checkedProspects: array,
  handleCheckProspect: func,
};

ProspectsTable.defaultProps = {
  prospects: [],
  checkedProspects: [],
  handleCheckProspect: () => {},
};

export default ProspectsTable;
