import axios from "axios";
import { sortUserPermissionByRole } from "../utils/sortRepresentativePermissions.js";

export const getRepsService = async (params, signal) => {
  return axios
    .get("/users/representatives/territories", {
      params,
      signal,
    })
    .then((res) => res.data);
};

export const getRepsDuplicatesService = async (params) => {
  const { data } = await axios.get("/users/representatives/duplicates", {
    params,
  });
  return data;
};

export const getRepByIdService = async (id) => {
  const { data } = await axios.get(`/users/representatives/${id}`);

  return sortUserPermissionByRole(data);
};

export const getRepDuplicateByIdService = async (id) => {
  return axios
    .get(`/users/representatives/duplicate/${id}`)
    .then((res) => res.data);
};

export const getRepsArrayService = async (data, signal) => {
  return axios
    .post("/users/representatives/info", data, signal)
    .then((res) => res.data);
};

export const createRepService = async (userData) => {
  return await axios
    .post("/users/representatives", userData)
    .then((res) => res.data);
};

export const updateUserService = async (userData, userId) => {
  const { data } = await axios.put(
    `/users/representatives/${userId}`,
    userData
  );
  return data;
};

export const archiveRepsService = async (representativeIds, active) => {
  const { data } = await axios.put(`users/representatives/bulk-activation`, {
    representativeIds,
    active,
  });
  return data;
};

export const resetRepsService = async (emails) => {
  const { data } = await axios.post(
    `/users/representatives/bulk-reset-password`,
    { emails }
  );
  return data;
};

export const deleteUserService = async (users) => {
  const { data } = await axios.delete(`/users/representatives/bulk`, {
    data: { users },
  });
  return data;
};

export const logoutRepsByIdService = async (id) => {
  const { data } = await axios.put(`/auth/representative/logout/${id}`);
  return data;
};

export const resetRepPasswordService = async (email) => {
  const { data } = await axios.post("/users/representatives/reset-password", {
    email,
  });
  return data;
};

export const distributorResetRepPasswordService = async (id) => {
  const { data } = await axios.post(
    `/users/distributors/reset-representative-password/${id}`
  );
  return data;
};

export const distributorResetRepEmailService = async (id) => {
  const { data } = await axios.post(
    `/users/representatives/resend-confirmation-email/${id}`
  );
  return data;
};

export const transferWhenDeleteRepService = async (body) => {
  const { data } = await axios.put(
    "/transfers/when-delete-representatives",
    body
  );
  return data;
};

export const getTopSalesRepresentativesService = async (params) => {
  const { data } = await axios.get("/users/representatives/top-sales", {
    params,
  });
  return data;
};

export const getRepsTotalSummeriesService = async () => {
  const { data } = await axios.get("/users/representatives/total-summaries");
  return data;
};
