import { string, func } from "prop-types";
import { IconButton, Stack, Typography } from "@mui/material";
import { CrossIcon } from "components/Icons";

export const DrawerHeader = ({ onClose, titleLabel }) => {
  return (
    <Stack
      direction="row"
      sx={{
        width: "100%",
        height: "64px",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "0.5px solid #D5D9D9",
        backgroundColor: "#F8F8F8",
        p: "24px 20px 18px 32px",
      }}
    >
      <Typography fontSize={18} color="#3F3F3F" noWrap>
        {titleLabel}
      </Typography>
      <IconButton onClick={onClose}>
        <CrossIcon />
      </IconButton>
    </Stack>
  );
};

DrawerHeader.propTypes = {
  onClose: func,
  titleLabel: string,
};
DrawerHeader.defaultProps = {
  onClose: () => {},
  titleLabel: "",
};
