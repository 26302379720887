import { bool, func, object } from "prop-types";
import { IosArrowForward } from "components/Icons";

import { Grid, IconButton, Stack, Typography } from "@mui/material";
import { LargeCheckbox } from "components";

export const CategoryItem = ({
  isLastCategory,
  item,
  onCheck,
  parent = null,
  isChild = false,
  isExpanded = false,
  onExpand = () => {},
  isCategorySelected,
  isIntermediateSelected,
}) => {
  const { name, childCategories, _count } = item || {};

  const isChecked = isCategorySelected(item, parent);
  const isIntermediateChecked = isChild ? false : isIntermediateSelected(item);

  const CATEGORY_HEADER = [
    {
      xs: 0.39,
      element: (
        <LargeCheckbox
          formSx={{ m: 0 }}
          sx={{
            padding: 0,
            "& .MuiSvgIcon-root": {
              ml: isChecked ? "-2.5px" : 0,
            },
          }}
          size={20}
          checkedProps={{
            size: 19.5,
          }}
          indeterminate={isIntermediateChecked}
          checked={isChecked}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => onCheck(item, parent, e.target.checked)}
          indeterminateProps={{
            size: 20.2,
            color: "#47a06d",
            fill: "#ffff",
          }}
          customIcon
        />
      ),
    },
    {
      xs: 7,
      props: {
        pr: 1,
        xs: 7,
        pl: isChild ? "54px" : "30px",
        container: true,
        alignItems: "center",
        gap: "8px",
      },
      element: (
        <Stack direction="row" alignItems="center" gap="12px">
          <Typography fontSize={12} fontWeight={600} color="#1C1C19" noWrap>
            {name}
          </Typography>
          {!!childCategories?.length && (
            <IconButton onClick={onExpand} sx={{ p: 0 }} disableRipple>
              <IosArrowForward
                fill="#707070"
                style={{
                  transform: isExpanded ? "rotate(90deg)" : "rotate(0deg)",
                  transition: "transform 0.2s",
                }}
              />
            </IconButton>
          )}
        </Stack>
      ),
    },
    {
      xs: 1.5,
      element: (
        <Typography fontSize={12} fontWeight={400} color="#1C1C19" noWrap>
          {_count.products}
        </Typography>
      ),
    },
  ];

  return (
    <Grid
      sx={{
        alignItems: "center",
        height: "48px",
        pl: "16px",
        overflow: "hidden",
        minHeight: "48px",
        maxHeight: "48px",
        background: !isChild ? "rgba(246, 246, 246, 0.80)" : "#ffff",
        borderBottom:
          isLastCategory && isChild
            ? "0.5px solid #D5D9D9"
            : isLastCategory
            ? "none"
            : "0.5px solid #D5D9D9",
        position: "relative",
      }}
      columns={9}
      container
    >
      {CATEGORY_HEADER.map(({ xs, props, element }, index) => (
        <Grid item xs={xs} key={index} {...props}>
          {element && element}
        </Grid>
      ))}
    </Grid>
  );
};

CategoryItem.propTypes = {
  item: object,
  isLastCategory: bool,
  isExpanded: bool,
  collapseIcon: object,
  onExpand: func,
  isCategorySelected: func,
  isIntermediateSelected: func,
  isChild: bool,
  onCheck: func,
  parent: object,
};
