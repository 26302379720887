import { useContext } from "react";
import { Grid, Typography } from "@mui/material";
import AddressField from "Pages/CustomersPage/components/AddressField/AddressField";
import { CustomerContext } from "Pages/CustomersPage/pages/CustomerPage/CustomerPage";
import { Controller } from "react-hook-form";
import { LargeCheckbox } from "components";
import { addressFieldOptions } from "./AddressesBlock.constants";

export const AddressesBlock = () => {
  const {
    isEdit,
    control,
    setValue,
    errors,
    billingDuplicates,
    handleOpenDuplicates,
    shippingDuplicates,
    resetShippingAddressCoordinates,
    googleAddress,
    setGoogleAddress,
    toggleDetails,
    setToggleDetails,
    setMapOpen,
    formField,
  } = useContext(CustomerContext);

  const { billingAddress, shippingIsSame } = formField || {};

  const optionsBilling = {
    ...addressFieldOptions,
    toggleButtonProps: {
      sx: {
        height: "43px",
        ml: 1.5,
        width: "34px",

        "& .MuiTypography-root": { fontSize: "18px" },
        borderColor: errors?.billingAddress
          ? "#F2545B !important"
          : "#d5d9d9 !important",
        borderRadius: "4px 0 0 4px",
        borderWidth: "1px 0px 1px 1px",
      },
    },

    errorCoordinates:
      !!errors?.billingAddress?.lat || !!errors?.billingAddress?.lng,
  };

  const optionsShipping = {
    ...addressFieldOptions,
    toggleButtonProps: {
      sx: {
        height: "43px",
        ml: 1.5,
        width: "34px",

        "& .MuiTypography-root": { fontSize: "18px" },
        borderColor: errors?.shippingAddress
          ? "#F2545B !important"
          : "#d5d9d9 !important",
        borderRadius: "4px 0 0 4px",
        borderWidth: "1px 0px 1px 1px",
      },
    },
    errorCoordinates:
      !!errors?.shippingAddress?.lat || !!errors?.shippingAddress?.lng,
    formSx: {
      "& .MuiFormHelperText-root.Mui-error": {
        whiteSpace: "nowrap",
      },
    },
  };

  return (
    <Grid xs={12} item container>
      <Grid xs={12} item>
        <AddressField
          adminIsAllowed
          withoutAbsoluteLabel
          isEdit={isEdit}
          duplicates={shippingDuplicates}
          handleOpenDuplicates={handleOpenDuplicates}
          control={control}
          setValue={setValue}
          fieldName="shippingAddress"
          label="Shipping address"
          address={formField.shippingAddress}
          googleAddress={googleAddress}
          setGoogleAddress={setGoogleAddress}
          addressToggled={toggleDetails === "shippingAddress"}
          typingTrigger={() => {
            resetShippingAddressCoordinates();
            if (formField.proceededWithDuplicatedFields) {
              setValue("proceededWithDuplicatedFields", false);
            }
          }}
          onMapOpen={() => setMapOpen("shippingAddress")}
          onToggle={() => {
            if (toggleDetails === "shippingAddress")
              return setToggleDetails("");
            setToggleDetails("shippingAddress");
          }}
          error={!!errors.shippingAddress}
          {...optionsShipping}
        />
      </Grid>
      <Grid height="46px" display="flex" alignItems="center" xs={12} item>
        <Typography fontSize={22} color="ground.main">
          Billing address
        </Typography>
      </Grid>

      {shippingIsSame ? null : (
        <>
          <Grid xs={12} item>
            <AddressField
              adminIsAllowed
              withoutAbsoluteLabel
              isEdit={isEdit}
              duplicates={billingDuplicates}
              handleOpenDuplicates={handleOpenDuplicates}
              control={control}
              setValue={setValue}
              disabled={!!formField.shippingIsSame}
              fieldName="billingAddress"
              label="Billing address"
              address={billingAddress}
              googleAddress={googleAddress}
              setGoogleAddress={setGoogleAddress}
              addressToggled={toggleDetails === "billingAddress"}
              onMapOpen={() => setMapOpen("billingAddress")}
              typingTrigger={() => {
                if (formField.proceededWithDuplicatedFields) {
                  setValue("proceededWithDuplicatedFields", false);
                }
              }}
              onToggle={() => {
                if (toggleDetails === "billingAddress")
                  return setToggleDetails("");
                setToggleDetails("billingAddress");
              }}
              error={!!errors.billingAddress}
              {...optionsBilling}
            />
          </Grid>
        </>
      )}
      <Grid mb={2} xs={12} item>
        <Controller
          render={({ field }) => (
            <LargeCheckbox
              adminIsAllowed
              size={16}
              formSx={{ pl: 0.4, mt: "-12px" }}
              label={
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: 13,
                    fontWeight: 400,
                    color: "#5F6267",
                    letterSpacing: "normal",
                  }}
                >
                  Same as shipping address
                </Typography>
              }
              {...field}
              onChange={() => {
                if (!field.value)
                  setValue("billingAddress", formField.shippingAddress);
                setValue("shippingIsSame", !field.value);
                if (formField.proceededWithDuplicatedFields) {
                  setValue("proceededWithDuplicatedFields", false);
                }
              }}
              checked={!!field.value}
              value={!!field.value}
            />
          )}
          name="shippingIsSame"
          control={control}
        />
      </Grid>
    </Grid>
  );
};
