import React, { useMemo } from "react";
import { object, func, string, bool } from "prop-types";
import { Box, Typography } from "@mui/material";
import { StoreLink, StoreWidget, StorefrontAccessBlock } from "..";
import useStyles from "./styles";
import { StyledButton } from "components";
import { success } from "utils/notifications";
import { WIDGET_SIZES } from "../StoreWidget/StoreWidget.constants";
import { ORDER_DIRECT_TABS } from "Pages/SettingsPage/SettingsPage.constants";

const OrderDirectCompletedSetup = ({
  currentTab,
  storeName,
  widgetState,
  updateWidgetState,
  allowStorePreview,
  loadingStorefrontPreview,
  handleSwitchStorefrontPreview,
  showRetailPrice,
  loadingSuggestedRetailPrice,
  handleSwitchStorefrontSuggestedRetailPrice,
  hideRequestPaymentTerms,
  loadingHideRequestPaymentTermsForCustomer,
  handleSwitchHideRequestPaymentTermsForCustomer,
  approvalAccess,
  loadingApprovalAccess,
  handleSwitchApprovalAccess,
  allowStoreCreditCardPayments,
  loadingAllowStoreCreditCardPayments,
  handleSwitchAllowStoreCreditCardPayments,
  isAdvancePaymentType,
}) => {
  const classes = useStyles();

  const { sizes } = useMemo(
    () => WIDGET_SIZES.find((ws) => ws.value === widgetState.size),
    [widgetState.size]
  );

  const widgetSrc = useMemo(
    () => `${window.location.origin}/widgets/${widgetState.size}`,
    [widgetState.size]
  );

  const handleCopyCode = () => {
    navigator.clipboard.writeText(
      `<iframe height={${sizes.height}} width={${sizes.width}} style="height:${sizes.height}px;width:${sizes.width}px;display:block;margin:0 auto;" src="${widgetSrc}?storeName=${storeName}" />`
    );
    success("Widget link copied");
  };

  return (
    <Box className={classes.wrapper}>
      {currentTab === ORDER_DIRECT_TABS[0].value && (
        <>
          <StoreWidget
            setSize={(size) => updateWidgetState({ size })}
            size={widgetState.size}
            sizes={sizes}
            widgetSrc={widgetSrc}
            storeName={storeName}
          />
          <Box className={classes.linkWrap}>
            <StoreLink
              widgetState={widgetState}
              updateWidgetState={updateWidgetState}
            />

            <Box>
              <Typography className={`${classes.copyText} title`}>
                Quickly add the widget to your site
              </Typography>
              <Typography className={classes.copyText}>
                {
                  "\nCopy and paste the code for your widget into a\ncustom HTML component on your website."
                }
              </Typography>
              <StyledButton
                variant="contained"
                label="Copy Code"
                className={classes.copyButton}
                onClick={handleCopyCode}
              />
            </Box>
          </Box>
        </>
      )}

      {currentTab === ORDER_DIRECT_TABS[1].value && (
        <StorefrontAccessBlock
          {...{
            allowStorePreview,
            loadingStorefrontPreview,
            handleSwitchStorefrontPreview,
            showRetailPrice,
            loadingSuggestedRetailPrice,
            handleSwitchStorefrontSuggestedRetailPrice,
            hideRequestPaymentTerms,
            loadingHideRequestPaymentTermsForCustomer,
            handleSwitchHideRequestPaymentTermsForCustomer,
            approvalAccess,
            loadingApprovalAccess,
            handleSwitchApprovalAccess,
          }}
          hidePayments
          wrapperStyles={{
            p: 0,
            border: "none",
          }}
        />
      )}

      {currentTab === ORDER_DIRECT_TABS[2].value && (
        <StorefrontAccessBlock
          {...{
            allowStorePreview,
            loadingStorefrontPreview,
            handleSwitchStorefrontPreview,
            showRetailPrice,
            loadingSuggestedRetailPrice,
            handleSwitchStorefrontSuggestedRetailPrice,
            hideRequestPaymentTerms,
            loadingHideRequestPaymentTermsForCustomer,
            handleSwitchHideRequestPaymentTermsForCustomer,
            approvalAccess,
            loadingApprovalAccess,
            handleSwitchApprovalAccess,
            allowStoreCreditCardPayments,
            loadingAllowStoreCreditCardPayments,
            handleSwitchAllowStoreCreditCardPayments,
            isAdvancePaymentType,
          }}
          hideStorefrontAccess
          wrapperStyles={{
            p: 0,
            border: "none",
          }}
        />
      )}
    </Box>
  );
};

OrderDirectCompletedSetup.propTypes = {
  storeName: string,
  widgetState: object,
  updateWidgetState: func,
  handleCopyLink: func,
  currentTab: string,
  allowStorePreview: bool,
  loadingStorefrontPreview: bool,
  handleSwitchStorefrontPreview: func,
  showRetailPrice: bool,
  loadingSuggestedRetailPrice: bool,
  handleSwitchStorefrontSuggestedRetailPrice: func,
  hideRequestPaymentTerms: bool,
  loadingHideRequestPaymentTermsForCustomer: bool,
  handleSwitchHideRequestPaymentTermsForCustomer: func,
  approvalAccess: bool,
  loadingApprovalAccess: bool,
  handleSwitchApprovalAccess: func,
  allowStoreCreditCardPayments: bool,
  loadingAllowStoreCreditCardPayments: bool,
  handleSwitchAllowStoreCreditCardPayments: func,
  isAdvancePaymentType: bool,
};

export default OrderDirectCompletedSetup;
