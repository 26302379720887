import Box from "@mui/material/Box";

import useStyles from "./styles";
import { FilterSearchTextField, StyledButton, StyledTooltip } from "components";
import { FilterIcon } from "components/Icons";
import { PriceContext } from "Pages/CatalogPage";
import { useContext } from "react";

const PriceListFilter = () => {
  const classes = useStyles();
  const { searchInput, setListSearch, existPriceListData, listLoading } =
    useContext(PriceContext);

  return (
    <Box className={classes.filtersWrapper}>
      <FilterSearchTextField
        adminIsAllowed
        placeholder="Search price lists"
        value={searchInput}
        loading={existPriceListData && listLoading}
        onChange={(e) => setListSearch(e.target.value)}
        fullWidth
        adornmentProps={{
          sx: {
            justifyContent: "flex-end",
          },
        }}
        handleClearValue={() => setListSearch("")}
      />

      <StyledTooltip
        placement="top"
        arrow
        title="Coming soon"
        PopperProps={{
          modifiers: [
            {
              name: "offset",
              options: { offset: [0, -5] },
            },
          ],
        }}
      >
        <Box component="span">
          <StyledButton
            label="Filter"
            disabled
            startIcon={<FilterIcon />}
            variant="outlined"
            color="edit"
            sx={{
              height: "39px",
              width: "100%",
              maxWidth: "74px",
              border: "0.5px solid #D5D9D9",
              "& .MuiButton-startIcon": {
                ml: 0,
              },
            }}
            fontSize="15px"
          />
        </Box>
      </StyledTooltip>
    </Box>
  );
};

export default PriceListFilter;
