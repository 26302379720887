import { useEffect, useMemo, useRef, useState } from "react";
import { bool, func, object } from "prop-types";
import { Box, Dialog, IconButton, Stack } from "@mui/material";
import { CrossIcon } from "components/Icons";
import {
  AddressBlock,
  DateBlock,
  MultipleBlock,
  MultipleDialogBlock,
  NameBlock,
  NoResponsesBlock,
  NumericBlock,
  PhotoBlock,
  SingleDialogBlock,
  TextBlock,
  TitleBlock,
  YesNoDialogBlock,
} from "../ContentBlocks/components";
import { handleGetIcons } from "Pages/FormViewPage/FormViewPage.constants";
import { ScrollControlWrapper } from "components";
import { useSelector } from "react-redux";

const DIALOG_GLOBAL_Y_MARGINS = 64;
const BLOCK_MARGINS = 26;
const WRAPPER_MB = 8;

export const DialogPreviewContent = ({
  open = false,
  onClose = () => {},
  item = {},
  seeAllAction = false,
}) => {
  const currentUser = useSelector(({ auth }) => auth?.currentUser);

  const [filterByMultipleName, setFilterByMultipleName] = useState(null);

  const questionType = item?.type;
  const type = item?.deleted
    ? "Deactivated"
    : item?.required
    ? "Required"
    : "Optional";

  const FORM_ICONS = handleGetIcons({
    singleProps: {
      firstRectFill: "#409A65",
      secondRectStroke: "#fff",
      lastRectFill: "#fff",
      strokeOpacity: 1,
    },
    multiProps: {
      rectFill: "#47A06E",
      width: 21,
      height: 21,
    },
  });

  const answersBlock = ({ type, response, question }) => {
    switch (type) {
      case "TEXT":
        return <TextBlock text={response?.answer} />;
      case "NUMERIC":
        return <NumericBlock number={response?.answer} />;
      case "YES_NO":
        return <YesNoDialogBlock text={response?.answer} />;
      case "SINGLE_ANSWER":
        return <SingleDialogBlock text={response?.answer} />;
      case "MULTIPLE_ANSWER":
        return <MultipleDialogBlock answers={response?.answerJson} />;
      case "PHOTO":
        return <PhotoBlock response={response} question={question} isDialog />;
      case "DATE":
        return (
          <DateBlock
            date={response?.answerDate}
            timeZone={currentUser?.timeZone}
          />
        );
      default:
        return <TextBlock text={response?.answer} />;
    }
  };

  const renderQuestion = ({ list, question }) => {
    const type = question?.type;
    return [
      "TEXT",
      "NUMERIC",
      "PHOTO",
      "DATE",
      "YES_NO",
      "SINGLE_ANSWER",
      "MULTIPLE_ANSWER",
    ].includes(type)
      ? list?.map((q) => (
          <Stack key={q?.id} mt="16px">
            <NameBlock
              name={q?.progress?.representative?.name || ""}
              date={q?.updatedAt || ""}
              timeZone={currentUser?.timeZone}
              autofilled={q?.autofilled}
            />

            {answersBlock({ type: questionType, response: q, question })}

            <AddressBlock
              place={q?.progress?.customer?.displayedName}
              address={q?.progress?.customer?.billingAddress?.formatted_address}
              wrapProps={{
                ...(["PHOTO"].includes(type) && { mt: "0px" }),
              }}
            />
          </Stack>
        ))
      : answersBlock({
          type: questionType,
          questions: question?.questions,
          question,
        });
  };

  const titleBlockRef = useRef({});
  const [titleBlockHeight, setTitleBlockHeight] = useState({});
  const [hasTitleBlockRef, setHasTitleBlockRef] = useState(false);

  useEffect(() => {
    if (titleBlockRef.current) {
      setTitleBlockHeight(titleBlockRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasTitleBlockRef]);

  const filterByName = (filterName, isMultiple) => {
    if (isMultiple) {
      return (el) => {
        return el?.answerJson?.some((a) => a === filterName);
      };
    }

    return (el) => el?.answer === filterName;
  };

  const list = useMemo(() => {
    return item?.filterName || filterByMultipleName
      ? item?.responses?.filter(
          filterByName(
            item?.filterName || filterByMultipleName,
            item?.type === "MULTIPLE_ANSWER"
          )
        )
      : item?.responses;
  }, [item?.filterName, item?.responses, item?.type, filterByMultipleName]);

  return (
    <Dialog
      open={open}
      sx={{
        ".MuiPaper-root": {
          boxShadow: "none",
          width: "100%",
          maxWidth: "780px",
          overflow: "hidden",
        },
      }}
    >
      <IconButton
        sx={{ position: "absolute", right: 16, top: 14 }}
        onClick={onClose}
      >
        <CrossIcon />
      </IconButton>

      <Box sx={{ p: "24px 24px 0px 24px" }}>
        <Box
          ref={(el) => {
            titleBlockRef.current[item?.id] = el;
            setHasTitleBlockRef(true);
          }}
        >
          <TitleBlock
            icon={FORM_ICONS[questionType]}
            title={item?.text}
            type={type}
            questionType={questionType}
            isDialog
            questionsCount={list?.length}
          />
        </Box>
      </Box>
      <Box
        sx={{
          px: "24px",
          mb: `${WRAPPER_MB}px`,
          position: "relative",
          overflowY: "auto",
          maxHeight: `calc(100vh - ${
            titleBlockHeight?.[item?.id]?.clientHeight || 0
          }px - ${DIALOG_GLOBAL_Y_MARGINS}px - ${BLOCK_MARGINS}px)`,
          height: "100%",

          ...(!!seeAllAction &&
            questionType === "MULTIPLE_ANSWER" && {
              "-ms-overflow-style": "none",
              scrollbarWidth: "none",
              "-webkit-scrollbar": {
                display: "none",
              },
            }),
        }}
      >
        {!!seeAllAction && questionType === "MULTIPLE_ANSWER" && (
          <Box mt="16px">
            <MultipleBlock
              questions={item?.questions}
              question={item}
              // isDialog
              isMultipleDialog
              filterByMultipleName={filterByMultipleName}
              setFilterByMultipleName={setFilterByMultipleName}
            />
          </Box>
        )}

        <ScrollControlWrapper
          maxHeight={`calc(100vh - ${
            titleBlockHeight?.[item?.id]?.clientHeight || 0
          }px - ${DIALOG_GLOBAL_Y_MARGINS}px - ${BLOCK_MARGINS}px - ${WRAPPER_MB}px)`}
          id="dialog-preview-content-list"
        >
          {!!seeAllAction && questionType === "MULTIPLE_ANSWER" && (
            <Box borderBottom="1px solid #D9D9D9" />
          )}

          {list?.length ? (
            renderQuestion({ list, question: item })
          ) : (
            <Box pb="8px">
              <NoResponsesBlock />
            </Box>
          )}
        </ScrollControlWrapper>
      </Box>
    </Dialog>
  );
};

DialogPreviewContent.propTypes = {
  open: bool,
  onClose: func,
  item: object,
  seeAllAction: bool,
};
