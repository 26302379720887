import { bool, func, array, string } from "prop-types";

import { CrossBigIcon } from "components/Icons";
import { cl } from "./styles";

import {
  Box,
  Dialog,
  IconButton,
  Typography,
  DialogContent,
} from "@mui/material";
import { useProductsPurchased } from "./useProductsPurchased";
import { ProductContentBlock } from "./components/ProductContentBlock";
import { ProductActionBlock } from "./components/ProductActionBlock";

const TYPE = {
  Purchased: "purchased",
  Selected: "selected",
};

export const ProductsPurchased = ({
  isOpen,
  handleClose,
  checkedList,
  handleApplyPurchasedProducts,
  type = "purchased",
}) => {
  const {
    handleCheckProduct,
    handleIsAlreadyAdded,
    handleSetSort,
    quickSort,
    productsState,
    checkedProducts,
    page,
    handleFetchProducts,
    handleCancel,
  } = useProductsPurchased({ checkedList, isOpen, handleClose, type });

  return (
    <Dialog
      open={isOpen}
      sx={{ overflow: "hidden" }}
      PaperProps={{ sx: cl.paperWrapper }}
    >
      <DialogContent sx={cl.dialogContentWrapper}>
        <Box display="flex" justifyContent="space-between" mb="15px">
          <Typography fontSize="30px" color="#707070">
            Products {type === TYPE.Purchased ? "Purchased" : "Selected"}
          </Typography>
          <IconButton onClick={handleClose} sx={cl.iconBtn}>
            <CrossBigIcon size={23} />
          </IconButton>
        </Box>

        <ProductContentBlock
          {...{
            page,
            quickSort,
            productsState,
            handleSetSort,
            handleCheckProduct,
            handleFetchProducts,
            handleIsAlreadyAdded,
            type,
          }}
        />
      </DialogContent>

      <ProductActionBlock
        {...{
          handleCancel,
          disabledSaveBtn: !checkedProducts.length,
          handleApplyPurchasedProducts,
          checkedProducts,
        }}
      />
    </Dialog>
  );
};

ProductsPurchased.propTypes = {
  isOpen: bool,
  handleClose: func,
  checkedList: array,
  handleApplyPurchasedProducts: func,
  type: string,
};

ProductsPurchased.defaultProps = {
  isOpen: false,
  handleClose: () => {},
  checkedList: [],
  handleApplyPurchasedProducts: () => {},
  type: TYPE.Purchased,
};
