import { getEnvironment } from "helpers/helpers";
import {
  connectShopify,
  disconnectQuickBooks,
  disconnectShipStation,
  disconnectShopify,
  getQuickBooksAuth,
  syncQuickBooks,
} from "../../../../../services/integrations";
import {
  QuickbooksIcon,
  ShipstationIcon,
  ZapierIcon,
  ShopifyIcon,
} from "./components/Icons";
import { APP_ENVS } from "utils/constants";

const isProd = getEnvironment(APP_ENVS.prod);
const isDev = getEnvironment(APP_ENVS.dev);

const SHIPSTATION_LOGIN = isProd
  ? "https://ship.shipstation.com/settings/stores"
  : "https://ship-devss111.sslocal.com/";

const ZAPIER_LOGIN = "https://zapier.com/app/home";

export const INTEGRATIONS = [
  {
    name: "ShipStation",
    icon: <ShipstationIcon />,
    connectService: () => {
      return SHIPSTATION_LOGIN;
    },
    disconnectService: disconnectShipStation,
    loadingKey: "ShipStation",
    confirmDisconnect: true,
    hasSync: true,
    disabled: false,
    tooltipProps: { disableHoverListener: true },
    hidden: false,
  },
  {
    name: "QuickBooks",
    icon: <QuickbooksIcon />,
    connectService: getQuickBooksAuth,
    disconnectService: disconnectQuickBooks,
    loadingKey: "Quickbooks",
    connectionKey: "quickBooksTokens",
    confirmDisconnect: false,
    hasSync: true,
    disabled: false,
    tooltipProps: { disableHoverListener: true },
    params: [
      {
        key: "useQuickBooksOrdersId",
        value: false,
        name: "Continue with QuickBooks invoice indexation",
      },
    ],
    progressBar: {
      hide: true,
      tooltip:
        "We'll send you an email notification once the synchronization is complete",
    },
    hidden: false,
  },
  {
    name: "Shopify",
    icon: <ShopifyIcon />,
    connectService: connectShopify,
    disconnectService: disconnectShopify,
    loadingKey: "Shopify",
    confirmDisconnect: true,
    hasSync: false,
    disabled: !isDev,
    tooltipProps: { disableHoverListener: isDev, title: "Coming soon" },
    body: [
      {
        key: "shop",
        value: "",
        displayedName: "Shop",
        name: '"shop" identifier and url of shopify shop',
      },
    ],
    hidden: false,
  },
  {
    name: "Zapier",
    icon: <ZapierIcon />,
    connectService: () => {
      return ZAPIER_LOGIN;
    },
    disconnectService: () => {
      return window.open(ZAPIER_LOGIN, "_blank");
    },
    loadingKey: "Zapier",
    confirmDisconnect: false,
    hasSync: false,
    disabled: !isDev,
    tooltipProps: { disableHoverListener: isDev, title: "Coming soon" },
    hidden: false,
  },
];

export const INTEGRATIONS_SYNC = { Quickbooks: syncQuickBooks };
