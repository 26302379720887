import React from "react";
import {
  ContentTitle,
  ContentWrap,
  StyledRadio,
  StyledRadioGroup,
} from "./styles";
import { Controller } from "react-hook-form";
import { FormControl, FormControlLabel } from "@mui/material";
import { StyledTooltip } from "components";
import {
  DISCOUNT_APPLICABLE_ON_SCOPES,
  DISCOUNT_APPLICABLE_ON_SCOPES_LABELS,
} from "../../NewDiscountsPage.constants";
import { bool, object } from "prop-types";

const ApplicableOnSection = ({ control, hasManufacturer, isAdmin }) => {
  return (
    <ContentWrap>
      <ContentTitle>Discount applicable on</ContentTitle>
      <Controller
        render={({ field }) => (
          <FormControl sx={{ mt: "4px" }}>
            <StyledRadioGroup {...field}>
              {Object.keys(DISCOUNT_APPLICABLE_ON_SCOPES).map((key) => (
                <StyledTooltip
                  key={key}
                  title={
                    !hasManufacturer
                      ? "Please select manufacturer to proceed"
                      : ""
                  }
                  sx={{
                    paddingRight: "64px",
                    height: "44px",
                  }}
                  placement="top"
                  arrow
                >
                  <FormControlLabel
                    key={key}
                    value={key}
                    disabled={isAdmin || !hasManufacturer}
                    componentsProps={{
                      typography: {
                        fontSize: "14px",
                        color: "#1C1C19",
                        fontWeight: 400,
                      },
                    }}
                    label={DISCOUNT_APPLICABLE_ON_SCOPES_LABELS[key]}
                    control={<StyledRadio />}
                  />
                </StyledTooltip>
              ))}
            </StyledRadioGroup>
          </FormControl>
        )}
        name="scope"
        control={control}
      />
    </ContentWrap>
  );
};

ApplicableOnSection.propTypes = {
  control: object,
  hasManufacturer: bool,
  isAdmin: bool,
};

ApplicableOnSection.defaultProps = {
  control: {},
  hasManufacturer: false,
  isAdmin: false,
};

export default ApplicableOnSection;
