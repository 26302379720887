import { useCallback, useEffect, useMemo, useState } from "react";
import { MAP_PROSPECT_TYPES } from "./components/ProspectsFilter/ProspectsFilter.constants";
import { MARKER_TYPE } from "../CustomersTab/components/TabsContent/TabMapComponent/TabMapComponent.constants";
import { getProspectsSearchResultsService } from "services/prospects";
import { error } from "utils/notifications";
import { getCustomersService, updateCustomerService } from "services/customers";
import { CUSTOMER_STATUS_FILTERS } from "utils/constants";
import { useReceivingHours } from "Pages/CustomersPage/pages/CustomerPage/components/CustomerMainBody/components/ReceivingHoursBlock/useReceivingHours";
import {
  setIncomingReceivingHours,
  setReceivingHours,
} from "Pages/CustomersPage/pages/CustomerPage/CustomerPage.helpers";
import { WEEK_DAYS } from "components/ReceivingHoursDrawer/ReceivingHoursDrawer.constants";
import { createSelector } from "reselect";
import { currentUserSelector } from "redux/selectors/auth";
import { useDispatch, useSelector } from "react-redux";
import { setPictureItemAction } from "redux/actions/gallery";

const selector = createSelector(currentUserSelector, (currentUser) => ({
  currentUser,
}));

export const useProspects = () => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector(selector);

  const [searchValue, setSearchValue] = useState("");

  const [locationBias, setLocationBias] = useState(null);

  const [checkedProspects, setCheckedProspects] = useState([]);

  const [tooltipState, setTooltipState] = useState({
    photoGroupOpen: false,
    notesOpen: false,
    notes: [],
    customer: {},
  });

  const [filtersState, setFiltersState] = useState({
    open: false,
    categories: [],
    filter: MAP_PROSPECT_TYPES.history,
    search: "",
  });

  const [activeCustomersState, setActiveCustomersState] = useState({
    show: false,
    loading: false,
    fetched: false,
    list: [],
  });

  const [listState, setListState] = useState({
    [MAP_PROSPECT_TYPES.new]: {
      list: [],
      loading: false,
      markerType: MARKER_TYPE.new,
    },
    [MAP_PROSPECT_TYPES.history]: {
      list: [],
      loading: false,
      markerType: MARKER_TYPE.prospectSearchResult,
    },
    [MAP_PROSPECT_TYPES.prospects]: {
      list: [],
      loading: false,
      markerType: MARKER_TYPE.prospect,
    },
    newWithIds: { list: [], loading: false, markerType: MARKER_TYPE.new },
  });

  const setListToListState = useCallback((key, list) => {
    setListState((prev) => ({
      ...prev,
      [key]: { ...prev[key], list, loading: false },
    }));
  }, []);

  const setAddToListState = useCallback((key, list) => {
    setListState((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        list: [...list, ...prev[key].list],
        loading: false,
      },
    }));
  }, []);

  const setLoadingToListState = useCallback((key, loading) => {
    setListState((prev) => ({
      ...prev,
      [key]: { ...prev[key], loading },
    }));
  }, []);

  const getActiveCustomers = useCallback(async () => {
    setActiveCustomersState((prev) => ({ ...prev, loading: true }));
    try {
      const { rows: activeCustomers } = await getCustomersService({
        status: `["${CUSTOMER_STATUS_FILTERS.active}"]`,
        with_activities: true,
      });
      setActiveCustomersState((prev) => ({
        ...prev,
        loading: false,
        fetched: true,
        show: true,
        list: activeCustomers.filter(
          ({ shippingAddress }) =>
            !!shippingAddress?.lat && !!shippingAddress?.lng
        ),
      }));
    } catch (err) {
      setActiveCustomersState((prev) => ({ ...prev, loading: false }));
      error(err?.response?.data?.message);
    }
  }, []);

  const getProspectCustomers = useCallback(async () => {
    setLoadingToListState(MAP_PROSPECT_TYPES.prospects, true);

    try {
      const { rows: prospectCustomers } = await getCustomersService({
        status: `["${CUSTOMER_STATUS_FILTERS.prospect}"]`,
      });
      const prospectCustomersWithType = prospectCustomers
        .map((p) => ({
          ...p,
          type: MARKER_TYPE.prospect,
        }))
        .filter(
          ({ shippingAddress }) =>
            !!shippingAddress?.lat && !!shippingAddress?.lng
        );
      setListToListState(
        MAP_PROSPECT_TYPES.prospects,
        prospectCustomersWithType
      );
    } catch (err) {
      setLoadingToListState(MAP_PROSPECT_TYPES.prospects, false);
      error(err?.response?.data?.message);
    }
  }, [setListToListState, setLoadingToListState]);

  const onShowActiveCustomersChange = useCallback(() => {
    if (activeCustomersState.fetched) {
      setActiveCustomersState((prev) => ({ ...prev, show: !prev.show }));
    } else {
      getActiveCustomers();
    }
  }, [activeCustomersState.fetched, getActiveCustomers]);

  const currentMarkersList = useMemo(
    () => listState[filtersState.filter].list,
    [filtersState.filter, listState]
  );

  const currentMarkerType = useMemo(
    () => listState[filtersState.filter].markerType,
    [filtersState.filter, listState]
  );

  const currentLoading = useMemo(
    () => listState[filtersState.filter].loading,
    [filtersState.filter, listState]
  );

  const handleReplaceOldData = useCallback(() => {
    const newList = currentMarkersList.filter(
      (m) =>
        !checkedProspects.some((p) =>
          p.fsq_id
            ? p.fsq_id === m.fsq_id
            : p.foursquareId
            ? p.foursquareId === m.foursquareId
            : p.id === m.id
        )
    );

    setListToListState(filtersState.filter, [...newList]);
  }, [
    checkedProspects,
    currentMarkersList,
    filtersState.filter,
    setListToListState,
  ]);

  const handleAddProspectsToState = useCallback((newProspects) => {
    const newProspectsWithType = newProspects.map((p) => ({
      ...p,
      type: MARKER_TYPE.prospect,
    }));
    setListState((prev) => ({
      ...prev,
      [MAP_PROSPECT_TYPES.prospects]: {
        ...prev[MAP_PROSPECT_TYPES.prospects],
        list: [
          ...prev[MAP_PROSPECT_TYPES.prospects].list,
          ...newProspectsWithType,
        ],
      },
    }));
  }, []);

  const handleAddCustomersToState = useCallback((newCustomers) => {
    const newCustomersWithType = newCustomers.map((p) => ({
      ...p,
      type: MARKER_TYPE.customer,
      status: CUSTOMER_STATUS_FILTERS.active,
    }));
    setActiveCustomersState((prev) => ({
      ...prev,
      list: [...prev.list, ...newCustomersWithType],
    }));
  }, []);

  const currentMarkersListBySearch = useMemo(
    () =>
      currentMarkersList.filter(
        ({ name, shippingAddress }) =>
          name.toLowerCase().includes(filtersState.search.toLowerCase()) ||
          shippingAddress.formatted_address
            .toLowerCase()
            .includes(filtersState.search.toLowerCase())
      ),
    [currentMarkersList, filtersState.search]
  );

  const handleOpenFilters = useCallback(() => {
    setFiltersState((prev) => ({ ...prev, open: true }));
  }, []);

  const handleCloseFilters = () => {
    setFiltersState((prev) => ({ ...prev, open: false }));
  };

  const handleSearchProspects = (categories) => {
    setFiltersState((prev) => ({
      ...prev,
      open: false,
      categories: [...categories],
    }));
  };

  const handleDeleteFilter = (id) => {
    setFiltersState((prev) => ({
      ...prev,
      categories: prev.categories.filter((c) => c.id !== id),
    }));
  };

  const handleChangeFilter = useCallback((e) => {
    const filter = e.target.value;
    setCheckedProspects([]);
    setFiltersState((prev) => ({
      ...prev,
      filter,
    }));
  }, []);

  const setFilter = useCallback(
    (filter) => setFiltersState((prev) => ({ ...prev, filter })),
    []
  );

  const getSearchResults = useCallback(async () => {
    try {
      setLoadingToListState(MAP_PROSPECT_TYPES.history, true);
      const results = await getProspectsSearchResultsService();
      const formattedResults = results.map((r) => ({
        ...r,
        type: MARKER_TYPE.prospectSearchResult,
        categories: r.categories.map(({ category }) => category),
      }));
      setListToListState(MAP_PROSPECT_TYPES.history, formattedResults);
    } catch (err) {
      error(err?.response?.data?.message);
    }
  }, [setListToListState, setLoadingToListState]);

  const getInitialData = useCallback(() => {
    getSearchResults();
    getProspectCustomers();
  }, [getProspectCustomers, getSearchResults]);

  useEffect(() => getInitialData(), [getInitialData]);

  const handleSearchChange = useCallback((newVal) => {
    setFiltersState((prev) => ({ ...prev, search: newVal }));
  }, []);

  const handleCheckProspect = useCallback(
    (prospect) => {
      const prospectWithId = listState.newWithIds.list.find(
        (p) => p.foursquareId === prospect.fsq_id
      );

      const newChecked = prospectWithId ?? prospect;

      setCheckedProspects((prev) =>
        prev.some(({ id }) => id === newChecked.id)
          ? prev.filter((p) => p.id !== newChecked.id)
          : [...prev, newChecked]
      );
    },
    [listState.newWithIds]
  );

  const [receivingHoursData, setReceivingHoursData] = useState({
    weekDays: {},
    customer: null,
  });

  const [noteDialogState, setNoteDialogState] = useState({
    open: false,
    data: null,
  });

  const [taskDialogState, setTaskDialogState] = useState({
    open: false,
    data: null,
  });

  const { weekDays } = receivingHoursData;

  const handleUpdateCustomer = async (data, customerId) => {
    try {
      await updateCustomerService(data, customerId);
    } catch (err) {
      error(err?.response?.data?.message);
    }
  };

  const handleUpdateHours = async (_, data) => {
    const customer = receivingHoursData.customer;

    if (!customer.id) return;

    const receivingHours = setReceivingHours(data);

    await handleUpdateCustomer({ receivingHours }, customer?.id);

    setReceivingHoursData({
      weekDays: {},
      customer: null,
    });

    getActiveCustomers();
  };

  const {
    openReceivingHours,
    handleSetHours,
    handleOpenReceivingHours,
    handleCloseReceivingHours,
  } = useReceivingHours({
    setValue: handleUpdateHours,
    weekDays,
    onDelete: handleUpdateHours,
  });

  const onCloseReceivingHours = () => {
    handleCloseReceivingHours();
    setReceivingHoursData((prev) => ({
      ...prev,
      weekDays: {},
    }));
  };

  const onOpenReceivingHours = (customer) => {
    const { receivingHours } = customer || {};

    setReceivingHoursData({
      weekDays: receivingHours
        ? setIncomingReceivingHours(receivingHours)
        : WEEK_DAYS,
      customer,
    });

    handleOpenReceivingHours();
  };

  const handleOpenProductDialog = (el) => {
    dispatch(setPictureItemAction(el?.id));
    setTooltipState((prev) => ({ ...prev, photoGroupOpen: true }));
  };

  const handleOpenCustomerNotes = useCallback((data) => {
    setNoteDialogState({
      open: true,
      data,
    });
  }, []);

  const handleCloseCustomerNotes = useCallback(() => {
    setNoteDialogState({
      open: false,
      data: null,
    });
  }, []);

  const handleCustomerTaskDrawer = (open, data) => {
    setTaskDialogState({
      open,
      data,
    });
  };

  const onCreateCommentCallback = async (comment) => {
    const customerId = comment.customer.id;

    const indexForChange = activeCustomersState.list.findIndex(
      (c) => c?.id === customerId
    );

    if (indexForChange !== -1) {
      setActiveCustomersState((prev) => ({ ...prev, loading: true }));
      try {
        const { rows } = await getCustomersService({
          customer_ids: JSON.stringify([customerId]),
          with_activities: true,
        });

        const data = [...activeCustomersState.list];

        data[indexForChange].activities = rows?.[0]?.activities || [];

        setActiveCustomersState((prev) => ({
          ...prev,
          list: data,
        }));

        setActiveCustomersState((prev) => ({ ...prev, loading: false }));
      } catch (err) {
        setActiveCustomersState((prev) => ({ ...prev, loading: false }));
        error(err?.response?.data?.message);
      }
    }
  };

  return {
    filtersState,
    handleOpenFilters,
    handleCloseFilters,
    handleSearchProspects,
    handleDeleteFilter,
    handleChangeFilter,
    setFilter,
    currentMarkersList,
    setListToListState,
    setAddToListState,
    listState,
    onShowActiveCustomersChange,
    activeCustomersState,
    handleSearchChange,
    currentMarkersListBySearch,
    currentMarkerType,
    handleCheckProspect,
    checkedProspects,
    setCheckedProspects,
    handleReplaceOldData,
    handleAddProspectsToState,
    setLoadingToListState,
    handleAddCustomersToState,
    openReceivingHours,
    handleSetHours,
    onCloseReceivingHours,
    weekDays,
    onOpenReceivingHours,
    getActiveCustomers,
    currentUser,
    tooltipState,
    setTooltipState,
    handleOpenProductDialog,
    noteDialogState,
    handleCloseCustomerNotes,
    handleOpenCustomerNotes,
    onCreateCommentCallback,
    handleCustomerTaskDrawer,
    taskDialogState,
    searchValue,
    setSearchValue,
    locationBias,
    setLocationBias,
    currentLoading,
  };
};
