/* eslint-disable react/prop-types */
import * as React from "react";
export const DashboardIcon = (props) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.667 3.333c0-.785 0-1.178.244-1.422s.637-.244 1.422-.244h3.334c.785 0 1.178 0 1.422.244s.244.637.244 1.422v.834c0 .785 0 1.178-.244 1.422s-.637.244-1.422.244H3.333c-.785 0-1.178 0-1.422-.244s-.244-.637-.244-1.422zm10 13.334c0 .785 0 1.178.244 1.422s.637.244 1.422.244h3.334c.785 0 1.178 0 1.422-.244s.244-.637.244-1.422v-.834c0-.785 0-1.178-.244-1.422s-.637-.244-1.422-.244h-3.334c-.785 0-1.178 0-1.422.244s-.244.637-.244 1.422zM1.667 10c0-.786 0-1.178.244-1.423.244-.244.637-.244 1.422-.244h3.334c.785 0 1.178 0 1.422.244.244.245.244.637.244 1.423v6.667c0 .785 0 1.178-.244 1.422s-.637.244-1.422.244H3.333c-.785 0-1.178 0-1.422-.244s-.244-.637-.244-1.422zm10 0c0 .786 0 1.179.244 1.423s.637.244 1.422.244h3.334c.785 0 1.178 0 1.422-.244s.244-.637.244-1.423V3.333c0-.785 0-1.178-.244-1.422s-.637-.244-1.422-.244h-3.334c-.785 0-1.178 0-1.422.244s-.244.637-.244 1.422z"
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
  </svg>
);
