import { useNavigate, useLocation } from "react-router-dom";
import useMapData from "./useMapData";
import { useRepsPermissions } from "helpers/hooks";
import { useCallback, useContext, useEffect, useState } from "react";
import { ImportContext } from "Pages/SettingsPage/SettingsPage";
import useImport from "./useImport";
import { IMPORT_STATUSES } from "../../ImportTab.constants";
import {
  fixImportErrorService,
  importDeleteAllErrorsService,
  validateImportService,
} from "services/import";
import { error, successMiddle } from "utils/notifications";
import {
  getImportOptions,
  KEY_TYPES,
} from "../../../ImportExportTab/ImportExportTab.constants";
import { getEnvironment } from "helpers/helpers";
import { getCurrentUser } from "helpers/auth";
import { APP_ENVS } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser } from "redux/actions/auth";
import {
  setEditTypeAction,
  setFormChangedAction,
} from "redux/actions/confirmDialogs";
import { confirmDialogIsOpenSelector } from "redux/selectors/confirmDialogs";
import pluralize from "pluralize";

const useImportValidation = ({
  tableRows,
  saveAfterLocalChanges,
  setSaveAfterLocalChanges,
}) => {
  const dispatch = useDispatch();
  const isLeavePopupOpen = useSelector(confirmDialogIsOpenSelector);
  const { headers, currentSession, currentColumns } = useMapData();
  const repPermissions = useRepsPermissions();

  const navigate = useNavigate();
  const { state } = useLocation();

  const { importData } = useContext(ImportContext);
  const [validationState, setValidationState] = useState({
    errors: [],
    loading: false,
    count: 0,
    changes: null,
  });

  const {
    updateImportData,
    importStatus,
    getErrorsCount,
    getRowsCount,
    handleDeleteImportSession,
  } = useImport();

  useEffect(() => {
    if (importStatus === IMPORT_STATUSES.READY_FOR_VALIDATION.status) {
      validateImportService(importData.uploadSession?.session);
    }
    if (importStatus === IMPORT_STATUSES.DONE.status) {
      successMiddle(
        `${importData?.rowsCount} ${pluralize(
          importData.importType?.key,
          importData?.rowsCount
        )} successfully imported`
      );
      if (repPermissions) {
        if (
          importData.importType?.key === KEY_TYPES.customers &&
          !repPermissions?.customers?.view
        ) {
          return navigate("/");
        }
        if (
          importData.importType?.key === KEY_TYPES.orders &&
          !repPermissions?.orders?.view
        ) {
          return navigate("/");
        }
        if (
          importData.importType?.key === KEY_TYPES.products &&
          !repPermissions?.products?.view
        ) {
          return navigate("/");
        }
      } else if (state?.type === "onboarding") {
        getCurrentUser({
          setCurrentUser: (user) => {
            dispatch(setCurrentUser(user));
            return navigate("/");
          },
        });
      } else return navigate(`/${importData.importType?.key}`);
    }
  }, [
    importData.importType?.key,
    importData.status,
    importData.uploadSession?.session,
    importStatus,
    navigate,
    repPermissions,
    updateImportData,
    importData?.rowsCount,
    dispatch,
    state?.type,
  ]);

  const updateValidationState = (newObj) => {
    setValidationState((prev) => ({ ...prev, ...newObj }));
  };

  const updateChangesState = useCallback(({ updatedRowFields, id }) => {
    if (!Object.keys(updatedRowFields).length) {
      return;
    }

    setValidationState((prev) => ({
      ...prev,
      changes: {
        ...prev.changes,
        [id]: { ...(prev.changes?.[id] || {}), ...updatedRowFields },
      },
    }));
  }, []);

  const ERROR_MAP = {
    required: "Value is required",
    unique: "Duplicate value found in file",
    unique_in_db: "Duplicate value found in database",
    uniqueComposite: (value) => {
      if (value && Array.isArray(value)) {
        if (
          value.length === 2 &&
          value.includes("ID") &&
          value.includes("Product Name")
        )
          return "Remove duplicate products from the order";
        return `Duplicate of [${value.join(", ")}] combination found in file`;
      }
      return "Duplicate value found in file";
    },
    isNumber: "Value should be number format",
    isFloat: "Max 2 decimal places (hundredths) allowed.",
    isString: "Value should be text format",
    isPhoneNumber: "Invalid phone number",
    isEmail: "Invalid email format",
    isURL: "Value should be URL format",
    isDate: "Invalid date format",
    not_found: (value, field) => {
      if (importData?.importType?.key === "customers") {
        switch (field) {
          case "group":
            return "Group does not exist in the system. Please correct or leave blank";
          case "notesCreatorName":
            return "Such user does not exist";
          default:
            return "Not found in Database";
        }
      } else {
        return "Not found in Database";
      }
    },
    notContainsInvalidCharacters: "Invalid character used",
    isEnum:
      "Invalid Status value provided. Status can only be set to Active, Inactive, or Prospect",
  };

  // const REQUIRED_FIELDS = useMemo(
  //   () => ({
  //     customers: [
  //       "name",
  //       "customerBillingAddress",
  //       "customerBillingCity",
  //       "customerBillingState",
  //       "customerBillingZip",
  //       "customerShippingAddress",
  //       "customerShippingCity",
  //       "customerShippingState",
  //       "customerShippingZip",
  //     ],
  //     orders: ["orderId", "customer", "productName", "quantity"],
  //   }),
  //   []
  // );

  const getErrorMessage = (errors, field) => {
    const messages = Object.entries(errors).map(([key, value]) => {
      return (
        (typeof ERROR_MAP[key] === "function"
          ? ERROR_MAP[key](value, field)
          : ERROR_MAP[key]) || `Unknown error: ${key}`
      );
    });
    return messages.join("\n");
  };

  const formatCell = (field, value) => {
    const VALIDATION_MAP = {
      federalTaxId: (val) => {
        return Number(val);
      },
      percentDiscount: (val) => {
        return Number(val);
      },
      customerBillingZip: (val) => {
        return Number(val);
      },
      customerShippingZip: (val) => {
        return Number(val);
      },
      quantity: (val) => {
        return Number(val);
      },
    };

    const validate = VALIDATION_MAP[field];

    if (validate) return validate(value);
    return value?.toString() || "";
  };

  const handleDeleteCell = async (ids) => {
    try {
      updateValidationState({ loading: true });
      await fixImportErrorService(importData.uploadSession?.session, {
        rowsToDelete: ids,
      });
      getErrorsCount();
      getRowsCount({ force: true });
      updateValidationState({ loading: false });
      updateImportData({ errorsCountFetched: false });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      updateValidationState({ loading: false });
      error(err?.response?.data?.message);
    }
  };

  const isProdEnv = getEnvironment(APP_ENVS.prod);

  const IMPORT_OPTIONS_LIST = getImportOptions({ isProdEnv, repPermissions });

  const handleDeleteAllCell = async ({ withDeleteSession }) => {
    try {
      const option = IMPORT_OPTIONS_LIST.find((el) => el?.key === "customers");
      const currentSchema = importData.allSchemas[option.key];

      if (withDeleteSession) {
        await handleDeleteImportSession(option, currentSchema);

        updateImportData((prev) => ({ ...prev, importType: null }));

        navigate("/settings", {
          state: { tab: "Import/Export", goBack: false },
        });

        return;
      }

      updateValidationState({ loading: true });
      await importDeleteAllErrorsService(importData.uploadSession?.session);

      getErrorsCount();
      getRowsCount({ force: true });
      updateValidationState({ loading: false });
      updateImportData({ errorsCountFetched: false });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      updateValidationState({ loading: false });
      error(err?.response?.data?.message);
    }
  };

  const handleSaveTable = useCallback(
    async ({ appliedChanges } = { appliedChanges: null }) => {
      updateValidationState({ loading: true });
      try {
        const formattedChanges = Object.entries(
          appliedChanges || validationState.changes || {}
        ).reduce((acc, [rowId, changes]) => {
          const formattedRowChanges = Object.entries(changes).reduce(
            (rowAcc, [field, value]) => {
              const fieldName = importData.mappedCols[field];
              const sendValue = formatCell(fieldName, value);
              return {
                ...rowAcc,
                [field]: sendValue,
              };
            },
            {}
          );

          return {
            ...acc,
            [rowId]: formattedRowChanges,
          };
        }, {});

        if (!formattedChanges || Object.keys(formattedChanges).length === 0) {
          updateValidationState({ loading: false });
          return;
        }
        await fixImportErrorService(
          importData.uploadSession?.session,
          formattedChanges
        );
        getErrorsCount();
        updateValidationState({ loading: false, changes: null });
        updateImportData({ errorsCountFetched: false });
        setSaveAfterLocalChanges(false);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        updateValidationState({ loading: false });
        error(err?.response?.data?.message);
        setSaveAfterLocalChanges(false);
      }
    },
    [
      getErrorsCount,
      importData.mappedCols,
      importData.uploadSession?.session,
      setSaveAfterLocalChanges,
      updateImportData,
      validationState.changes,
    ]
  );

  const handleSaveLocalChanges = useCallback(
    ({ newRow, modifiedKey }) => {
      if (!newRow || !modifiedKey) {
        return;
      }

      const newValue = newRow[modifiedKey]?.trim?.() || "";

      const oldRow = (tableRows ?? []).find(({ id }) => id === newRow.id);

      if (!oldRow) {
        return;
      }

      const oldRowValueIsTheSame = oldRow[modifiedKey] === newValue;
      // const fieldName = importData.mappedCols[modifiedKey];

      // const currentRequiredFields =
      //   REQUIRED_FIELDS[importData.currentSchema.key];

      // const isFieldRequired = currentRequiredFields.includes(fieldName);
      let appliedChanges = null;
      setValidationState((prev) => {
        if (oldRowValueIsTheSame) {
          const changesWithoutModifiedKey = { ...prev.changes?.[newRow.id] };
          delete changesWithoutModifiedKey[modifiedKey];
          if (!Object.keys(changesWithoutModifiedKey).length) {
            const changesWithoutRowId = { ...prev.changes };
            delete changesWithoutRowId[newRow.id];
            if (!Object.keys(changesWithoutRowId).length) {
              appliedChanges = null;
              return { ...prev, changes: null };
            }
            appliedChanges = changesWithoutRowId;
            return { ...prev, changes: changesWithoutRowId };
          }

          appliedChanges = {
            ...prev.changes,
            [newRow.id]: changesWithoutModifiedKey,
          };
          return {
            ...prev,
            changes: {
              ...prev.changes,
              [newRow.id]: changesWithoutModifiedKey,
            },
          };
        }

        appliedChanges = {
          ...prev.changes,
          [newRow.id]: {
            ...(prev?.changes?.[newRow.id] || {}),
            [modifiedKey]: newValue,
          },
        };
        return {
          ...prev,
          changes: {
            ...prev.changes,
            [newRow.id]: {
              ...(prev?.changes?.[newRow.id] || {}),
              [modifiedKey]: newValue,
            },
          },
        };
      });

      if (saveAfterLocalChanges) {
        handleSaveTable({ appliedChanges });
      }
    },
    [handleSaveTable, saveAfterLocalChanges, tableRows]
  );

  useEffect(() => {
    dispatch(setEditTypeAction("import", true));

    const hasChanges = !!validationState.changes;
    dispatch(setFormChangedAction(hasChanges));
  }, [dispatch, validationState.changes, isLeavePopupOpen]);

  return {
    validationState,
    getErrorMessage,
    handleDeleteCell,
    handleDeleteAllCell,
    headers,
    currentSession,
    currentColumns,
    handleSaveLocalChanges,
    handleSaveTable,
    updateChangesState,
  };
};

export default useImportValidation;
