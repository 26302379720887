import React from "react";

const RepsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={18}
    fill="none"
    {...props}
  >
    <path
      stroke="#A5A5A5"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12 4.875c0 1.864-1.343 3.375-3 3.375S6 6.739 6 4.875 7.343 1.5 9 1.5s3 1.511 3 3.375ZM1.875 13.75a6.25 6.25 0 0 1 5-2.5h4.25a6.25 6.25 0 0 1 5 2.5l.038.05a1.687 1.687 0 0 1-1.35 2.7H3.187a1.687 1.687 0 0 1-1.35-2.7l.038-.05Z"
    />
  </svg>
);
export default RepsIcon;
