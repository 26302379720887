import * as React from "react";
export const NotificationsCenterIcon = (props) => (
  <svg
    width={16}
    height={18}
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.406 13.517A19.2 19.2 0 0 0 15 12.353 8.22 8.22 0 0 1 13.053 7v-.667C13.054 3.388 10.792 1 8 1S2.947 3.388 2.947 6.333V7A8.22 8.22 0 0 1 1 12.353c1.46.568 2.998.964 4.594 1.164m4.812 0a19.4 19.4 0 0 1-4.812 0m4.812 0a2.8 2.8 0 0 1 .12.816C10.527 15.806 9.397 17 8 17s-2.527-1.194-2.527-2.667a2.8 2.8 0 0 1 .121-.816"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
