import { CircularProgress, Stack } from "@mui/material";
import {
  ContentBlocks,
  ControlPanel,
  DialogPreviewContent,
  DialogPreviewPhotos,
} from "./components";
import { useContext } from "react";
import { FormViewContext } from "Pages/FormViewPage/FormViewPage";
import { FiltersBlock } from "./components/FilterBlock/FilterBlock";

export const ResponsesDetails = () => {
  const { dialogPreviewContentData, dialogPreviewPhotosData, responsesState } =
    useContext(FormViewContext);

  const { loading } = responsesState;

  const { dialogPreviewContentState, handleCloseDialogPreviewContent } =
    dialogPreviewContentData;

  const { dialogPreviewPhotosState, handleCloseDialogPreviewPhotos } =
    dialogPreviewPhotosData;

  return (
    <>
      {!!dialogPreviewContentState.open && (
        <DialogPreviewContent
          open={dialogPreviewContentState.open}
          onClose={handleCloseDialogPreviewContent}
          item={dialogPreviewContentState.item}
          seeAllAction={dialogPreviewContentState.seeAllAction}
        />
      )}

      {!!dialogPreviewPhotosState.open && (
        <DialogPreviewPhotos
          open={dialogPreviewPhotosState.open}
          onClose={handleCloseDialogPreviewPhotos}
          response={dialogPreviewPhotosState.response}
          position={dialogPreviewPhotosState.position}
        />
      )}

      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "calc(100vh - 310px)" }}
        >
          <CircularProgress size="20px" />
        </Stack>
      ) : (
        <Stack gap="12px">
          <ControlPanel />
          <FiltersBlock />
          <ContentBlocks />
        </Stack>
      )}
    </>
  );
};
