import { bool, func, object } from "prop-types";
import { Box, Dialog, Divider, IconButton, Typography } from "@mui/material";
import { CrossIcon } from "components/Icons";
import { makeStyles } from "@mui/styles";
import { StyledButton } from "components";
import { DoubleClipIcon } from "components/Icons/DoubleClipIcon";
import { success } from "utils/notifications";

const useStyles = makeStyles(() => ({
  dialogTitle: {
    width: "630px",
    height: "57px",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "0.5px solid #D5D9D9",
    alignItems: "center",
    paddingLeft: "32px",
    paddingRight: "22px",
  },
  titleText: {
    fontSize: 17,
    fontWeight: 500,
    color: "#3F3F3F",
  },
  titleTextSecond: {
    fontSize: 12,
    fontWeight: 400,
    color: "#5F6267",
  },
}));

export const EmailLinkDialog = ({
  open,
  onClose,
  onSubmit,
  emailLinkDialogData,
}) => {
  const { paymentIntentLink, orderType, customId } = emailLinkDialogData || {};
  const classes = useStyles();

  const handleCopyLink = () => {
    if (paymentIntentLink) {
      navigator.clipboard.writeText(paymentIntentLink);
      success("Payment Link Copied");
    }
  };

  return (
    <Dialog maxWidth="none" open={open} onClose={onClose}>
      <Box className={classes.dialogTitle}>
        <Box display="flex" alignItems="center" gap="28px">
          <Typography className={classes.titleText}>Email Order</Typography>

          {customId && (
            <>
              <Box>
                <Divider
                  sx={{ height: "17px" }}
                  orientation="vertical"
                  flexItem
                />
              </Box>
              <Typography className={classes.titleTextSecond}>
                Order {customId}
              </Typography>
            </>
          )}
        </Box>

        <IconButton onClick={onClose}>
          <CrossIcon />
        </IconButton>
      </Box>

      <Box p="22px 36px 12px">
        <Typography fontSize={12} color="#000">
          Send your customer a link to their invoice
        </Typography>
      </Box>

      <Box
        sx={{
          height: "39px",
          display: "flex",
          alignItems: "center",
          border: "0.5px solid #D5D9D9",
          borderRadius: "4px",
          color: "#5F6267",
          mx: "36px",
          mb: "10px",
        }}
      >
        <Box sx={{ m: "3px 7px 0 7px" }}>
          <DoubleClipIcon />
        </Box>

        <Box flexGrow={1}>
          <Typography
            sx={{
              maxWidth: "434px",
              overflow: "hidden",
              fontSize: 12,
            }}
            noWrap
          >
            {paymentIntentLink}
          </Typography>
        </Box>

        <Box color="#C2C0C0" component="span">
          <StyledButton
            sx={{ color: "#47A06D" }}
            label="Copy Link"
            variant="outland"
            onClick={handleCopyLink}
          />
        </Box>
      </Box>

      <Box
        sx={{
          p: "26px 36px",
          display: "flex",
          justifyContent: "flex-end",
          gap: 1,
        }}
      >
        <StyledButton
          sx={{ height: "34px" }}
          label="Cancel"
          fontSize="13px"
          color="cancel"
          onClick={onClose}
        />
        <StyledButton
          sx={{ height: "34px" }}
          fontSize="15px"
          label="Done"
          variant="contained"
          onClick={() => onSubmit(orderType)}
        />
      </Box>
    </Dialog>
  );
};

EmailLinkDialog.propTypes = {
  open: bool,
  onClose: func,
  onSubmit: func,
  emailLinkDialogData: object,
};
EmailLinkDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onSubmit: () => {},
};
