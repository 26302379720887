import { array, func, string } from "prop-types";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import useStyles from "../../styles";

export const HeaderBlock = ({ currentTab, setCurrentTab, tabs }) => {
  const classes = useStyles();

  return (
    <Box className={classes.ordersHeader}>
      <Typography fontSize="30px" color="#5F6267">
        Orders
      </Typography>
      <Tabs
        value={currentTab}
        onChange={(e, newValue) => setCurrentTab(newValue)}
        sx={{
          "& .MuiTabs-indicator": {
            borderTopRightRadius: "4px",
            borderTopLeftRadius: "4px",
          },
        }}
      >
        {tabs.map((tab) => (
          <Tab
            className="profile-tabs"
            sx={{
              fontSize: "20px",
              color: "#707070",
            }}
            key={tab.name}
            label={<Box sx={{ textTransform: "none" }}>{tab.name}</Box>}
            value={tab.value}
          />
        ))}
      </Tabs>
    </Box>
  );
};

HeaderBlock.propTypes = {
  currentTab: string,
  setCurrentTab: func,
  tabs: array,
};
HeaderBlock.defaultProps = {
  currentTab: "",
  setCurrentTab: () => {},
  tabs: [],
};
