import { useCallback, useContext, useMemo, useState } from "react";
import { bool, number, object } from "prop-types";
import { IconButton, Stack } from "@mui/material";
import { CopyOutlinedIcon, DeleteOutlinedIcon } from "components/Icons";
import { FormViewContext } from "Pages/FormViewPage/FormViewPage";
import { generateUUID, useAdmin } from "helpers/helpers";
import { CheckboxBlock } from "./index";
import { cloneDeep } from "lodash";
import { MAX_ALLOWED_QUESTIONS } from "Pages/FormViewPage/FormViewPage.constants";

export const FooterBlock = ({ question, orderIndex, control, showActions }) => {
  const {
    formField,
    setValue,
    handleOpenQuestionDeleteDialog,
    clearErrors,
    dirtyFields,
  } = useContext(FormViewContext);

  const isAdmin = useAdmin();

  const tempList = useMemo(() => cloneDeep(formField?.questions), [formField]);

  const [isClickBlocked, setIsClickBlocked] = useState(false);

  const handleCopy = useCallback(() => {
    if (tempList?.length >= MAX_ALLOWED_QUESTIONS) return;

    if (isClickBlocked) return;
    setIsClickBlocked(true);
    setTimeout(() => setIsClickBlocked(false), 300);

    tempList.splice(question?.orderIndex + 1, 0, {
      text: question?.text,
      type: question?.type,
      ...(!!question?.options?.length && { options: question?.options }),
      autofill: question?.autofill,
      required: question?.required,
      uuid: generateUUID(),
    });

    setValue(
      "questions",
      tempList.map((q, i) => ({ ...q, orderIndex: i })),
      { shouldDirty: true }
    );
    clearErrors("questions");
  }, [question, setValue, tempList, clearErrors, isClickBlocked]);

  const handleDelete = useCallback(() => {
    const responses = question?._count?.responses || 0;
    if (typeof responses !== "number")
      // eslint-disable-next-line no-console
      return console.error("responses is required");

    if (responses > 0)
      return handleOpenQuestionDeleteDialog({
        question,
        isDeleteResponses: true,
      });

    handleOpenQuestionDeleteDialog({ question, isDeleteResponses: false });
  }, [question, handleOpenQuestionDeleteDialog]);

  return (
    <Stack justifyContent="space-between" direction="row" mt={1.5}>
      <CheckboxBlock
        questionType={question?.type}
        {...{ orderIndex, control, formField, setValue, dirtyFields }}
      />

      {!!showActions && (
        <Stack gap="4px" direction="row" alignItems="center">
          <IconButton
            sx={{ p: 0, width: "20px", height: "20px" }}
            onClick={handleCopy}
            disabled={isAdmin}
          >
            <CopyOutlinedIcon />
          </IconButton>

          <IconButton
            sx={{ p: 0, width: "20px", height: "20px" }}
            onClick={handleDelete}
            disabled={isAdmin}
          >
            <DeleteOutlinedIcon />
          </IconButton>
        </Stack>
      )}
    </Stack>
  );
};

FooterBlock.propTypes = {
  question: object,
  orderIndex: number,
  control: object,
  showActions: bool,
};
