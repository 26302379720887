import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { pickBy } from "lodash";
import {
  createMultipleProductAction,
  createSingleProductAction,
  updateMultipleProductActions,
  updateSingleProductAction,
} from "redux/actions/products";
import { categoriesListSelector } from "redux/selectors/categories";
import { createSelector } from "reselect";
import { success } from "utils/notifications";
import { setFormChangedAction } from "redux/actions/confirmDialogs";
import { getCurrentUser } from "helpers/auth";
import { setCurrentUser } from "redux/actions/auth";
import { useRedirect } from "helpers/useRedirect";

const selector = createSelector(categoriesListSelector, (categoriesList) => ({
  categoriesList,
}));

export const useActions = ({
  isEdit,
  productId,
  productProfile,
  state,
  formField,
  errors,
  setError,
  setValue,
}) => {
  const { categoriesList } = useSelector(selector);
  const dispatch = useDispatch();
  const navigate = useRedirect();

  const setOnHand = (inventoryWithoutSave, item) => {
    if (+inventoryWithoutSave >= 0) return inventoryWithoutSave;
    return item || 0;
  };

  const getVariant = (data) => {
    let res = [];
    data?.forEach((obj) => {
      res = [
        ...res,
        ...Object.values(obj).filter(
          (el) => (el != null && el !== "") || el === 0
        ),
      ];
    });

    return res;
  };

  const handleSaveProduct = useCallback(
    ({ data, justSave, successMsg, onSuccess }) => {
      const { status } = data || {};

      if (errors.name?.type === "duplicate")
        return setError("name", {
          ...errors.name,
        });

      const tagIds = [];
      const newTags = [];

      data?.tags.forEach((tag) => {
        return tag?.newTag
          ? newTags.push(tag?.tag)
          : tagIds.push(tag?.id || tag?.tag?.id);
      });

      if (data?.isMultiple) {
        const subProducts = data?.variationsFields.map((variation, i) => {
          if (typeof variation.id === "number") delete variation.id;

          const setVariationStatus = (mainStatus, variationStatus) => {
            if (mainStatus === "ACTIVE") {
              return variationStatus;
            }
            if (mainStatus === "INACTIVE") {
              return mainStatus;
            }
            if (mainStatus === "INACTIVE_FOR_CUSTOMERS") {
              return mainStatus;
            }
            return mainStatus;
          };

          const subProduct = {
            sku: variation?.sku?.trim() || "",
            barcode: variation.barcode ? variation.barcode : null,
            wholesalePrice: +variation.wholesalePrice || 0,
            retailPrice: +variation.retailPrice ? +variation.retailPrice : 0,
            distributorPrice: +variation.distributorPrice
              ? +variation.distributorPrice
              : null,
            itemsPerCase: +variation.itemsPerCase || 0,
            inventory: {
              onHand: setOnHand(
                +data?.inventoryWithoutSave?.[i]?.value,
                +variation?.inventory?.onHand
              ),
              expected: +variation?.inventory?.expected || 0,
            },
            photoData: variation.photos.map((img) => {
              const isGroupContentId = !!img?.groupContentId;
              return {
                id: isGroupContentId ? img?.groupContentId : img?.id,
                type: isGroupContentId ? "groupContentId" : "photoId",
              };
            }),
            status: setVariationStatus(status, variation?.status || "ACTIVE"),
          };
          if (variation.createdAt) subProduct.id = variation.initialId;
          if (variation.color) subProduct.color = variation?.color?.trim();
          if (variation.sku) subProduct.sku = variation?.sku?.trim();
          if (variation.size) subProduct.size = variation?.size?.trim();

          if (
            variation.photoIds &&
            variation.photoIds.length > 0 &&
            variation.photos
          ) {
            subProduct.photoData = [
              variation?.photoIds[0].map((p) => {
                return {
                  id: p,
                  type: "photoId",
                };
              }),
            ];
          }
          return subProduct;
        });

        const newProduct = {
          name: data?.name?.trim(),
          description: data?.description,
          minOrderQTY: +data?.minOrderQTY,
          sellingOutOfStock: data?.whenOutOfStock,
          manufacturerId: data?.manufacturerId,
          productCategoryId:
            data?.category?.id ||
            categoriesList.find((cat) => cat?.name === "Uncategorized")?.id,
          status,
          hasColorVariation: data?.chooseVariationsType?.color,
          hasSizeVariation: data?.chooseVariationsType?.size,
          subProducts,
          photoData: data?.photos.map((img) => {
            const isGroupContentId = !!img?.groupContentId;
            return {
              id: isGroupContentId ? img?.groupContentId : img?.id,
              type: isGroupContentId ? "groupContentId" : "photoId",
            };
          }),
          type: data?.type,
          countryOfOrigin: data?.countryOfOrigin ? data?.countryOfOrigin : null,
        };
        newProduct.tagIds = tagIds;
        newProduct.newTags = newTags;

        const hasSku = data?.variationsFields
          .filter((el) => el?.sku)
          .map((v) => v.sku);
        const hasColor = data?.variationsFields
          .filter((el) => el?.color)
          .map((v) => v.color);
        const hasSize = data?.variationsFields
          .filter((el) => el?.size)
          .map((v) => v.size);

        if (hasSku.length) newProduct.skuVariants = hasSku;
        if (hasColor.length) newProduct.colorVariants = hasColor;
        if (hasSize.length) newProduct.sizeVariants = hasSize;

        if (getVariant(data?.skuVariationFields)?.length)
          newProduct.skuVariants = getVariant(data?.skuVariationFields);

        if (getVariant(data?.colorVariationFields)?.length && !isEdit) {
          newProduct.colorVariants = getVariant(data?.colorVariationFields);
        }

        if (isEdit && productProfile?.colorVariants?.length) {
          newProduct.colorVariants = productProfile?.colorVariants;
        }

        if (getVariant(data?.sizeVariationFields)?.length) {
          newProduct.sizeVariants = getVariant(data?.sizeVariationFields);
        }

        if (isEdit && productProfile?.sizeVariants?.length) {
          newProduct.sizeVariants = productProfile?.sizeVariants;
        }

        if (!justSave) dispatch(setFormChangedAction(false));

        isEdit
          ? dispatch(
              updateMultipleProductActions({
                id: productId,
                data: newProduct,
                navigate: justSave ? null : navigate,
                setError,
                variationsFields: formField.variationsFields,
                onSuccess: (res) => {
                  success(successMsg || "Product updated");
                  if (onSuccess) {
                    onSuccess(res);
                  }

                  setValue("photos", res?.photos);
                  setValue("inventoryWithoutSave", null);
                  setValue(
                    "variationsFields",
                    res?.childProducts.length
                      ? res?.childProducts.map((prod) => {
                          prod.initialId = prod.id;
                          return pickBy(prod, (value) => {
                            return value !== null;
                          });
                        })
                      : []
                  );

                  // reset({
                  //   ...formField,
                  //   // ...newProduct,
                  //   photos: res?.photos,
                  //   inventoryWithoutSave: null,
                  //   variationsFields: res?.childProducts.length
                  //     ? res?.childProducts.map((prod) => {
                  //         prod.initialId = prod.id;
                  //         return pickBy(prod, (value) => {
                  //           return value !== null;
                  //         });
                  //       })
                  //     : [],
                  // });
                },
              })
            )
          : dispatch(
              createMultipleProductAction(
                newProduct,
                () => {
                  if (state?.type === "onboarding") {
                    getCurrentUser({
                      setCurrentUser: (user) => {
                        dispatch(setCurrentUser(user));
                      },
                    });
                  }
                  navigate(state?.type === "onboarding" ? "/" : "/catalog");
                },
                setError,
                formField.variationsFields
              )
            );
      } else {
        const newProduct = {
          name: data?.name?.trim(),
          description: data?.description,
          minOrderQTY: +data?.minOrderQTY,
          manufacturerId: data?.manufacturerId,
          productCategoryId:
            data?.category?.id ||
            categoriesList.find((cat) => cat?.name === "Uncategorized")?.id,
          status,
          sellingOutOfStock: data?.whenOutOfStock,
          sku: data?.sku?.trim(),
          barcode: data?.barcode ? data?.barcode : null,
          wholesalePrice: +data?.wholesalePrice,
          retailPrice: +data?.retailPrice ? +data?.retailPrice : null,
          distributorPrice: +data?.distributorPrice
            ? +data?.distributorPrice
            : null,
          itemsPerCase: +data?.itemsPerCase,
          photoData: data?.photos.map((img) => {
            const isGroupContentId = !!img?.groupContentId;
            return {
              id: isGroupContentId ? img?.groupContentId : img?.id,
              type: isGroupContentId ? "groupContentId" : "photoId",
            };
          }),
          inventory: {
            onHand: setOnHand(
              +data?.inventoryWithoutSave?.[0]?.value,
              +data?.inventory?.onHand
            ),
            expected: +data?.expected,
          },
          tagIds,
          newTags,
          type: data?.type,
          countryOfOrigin: data?.countryOfOrigin ? data?.countryOfOrigin : null,
        };

        if (!justSave) dispatch(setFormChangedAction(false));

        isEdit
          ? dispatch(
              updateSingleProductAction({
                id: productId,
                data: newProduct,
                navigate: justSave ? null : navigate,
                setError,
                onSuccess: (res) => {
                  if (onSuccess) {
                    onSuccess(res);
                  }
                  if (!res?.photos?.length) return;
                  success(successMsg || "Product updated");
                  setValue("photos", res?.photos);
                  setValue("inventoryWithoutSave", null);
                },
              })
            )
          : dispatch(
              createSingleProductAction(
                newProduct,
                () => {
                  if (state?.type === "onboarding") {
                    getCurrentUser({
                      setCurrentUser: (user) => {
                        dispatch(setCurrentUser(user));
                      },
                    });
                  }
                  navigate(state?.type === "onboarding" ? "/" : "/catalog");
                },
                setError
              )
            );
      }
    },
    [
      categoriesList,
      dispatch,
      errors.name,
      formField,
      isEdit,
      navigate,
      productId,
      productProfile?.colorVariants,
      productProfile?.sizeVariants,
      setError,
      setValue,
      state?.type,
    ]
  );

  const onSubmit = useCallback(
    (data) => {
      handleSaveProduct({ data, justSave: false });
    },
    [handleSaveProduct]
  );

  return { handleSaveProduct, onSubmit };
};
