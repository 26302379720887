import { func, string, object } from "prop-types";
import { Box, Grid } from "@mui/material";
import { Controller } from "react-hook-form";
import { StyledSelectTime } from "components";
import moment from "moment-timezone";

import { DueDateBlock } from "components/TaskDrawer/components";
import { ClockNewIcon } from "components/Icons/ClockNewIcon";

export const DueDateDrawerBody = ({
  onSubmit,
  timeZone,
  control,
  setError,
  clearErrors,
  setValue,
  formField,
}) => {
  const isValidDate = (str) => {
    if (!str) return false;
    const parsedDate = moment(str, "M/D/YYYY", true);

    return parsedDate.isValid();
  };

  return (
    <Box
      overflow="auto"
      flexGrow={1}
      width="544px"
      maxWidth="544px"
      component="form"
      id="due-date-drawer-form"
      onSubmit={onSubmit}
    >
      <Grid sx={{ mt: 3, px: 4 }} columnSpacing={1.75} container>
        <Grid xs={8} item>
          <DueDateBlock
            {...{
              formField,
              timeZone,
              setValue,
              control,
              isValidDate,
              setError,
              clearErrors,
            }}
          />
        </Grid>
        <Grid xs={4} item>
          <Controller
            render={({ field, fieldState: { error } }) => {
              return (
                <StyledSelectTime
                  disabled={!formField?.dueDate}
                  label="Due time"
                  fullWidth
                  error={!!error}
                  sx={{
                    "& fieldset": {
                      borderColor: "#D6D9D9 !important",
                    },
                    "& .MuiSvgIcon-root": { color: "#9E9E9E" },
                    "& svg": {
                      width: 24,
                      height: 26,
                      top: "calc(50% - 13px)",
                    },
                  }}
                  labelSx={{
                    color: "#00000099",
                    fontSize: "14px !important",
                    top: 3,
                  }}
                  IconComponent={ClockNewIcon}
                  height="44px"
                  fontSize="14px !important"
                  InputProps={{
                    style: { fontSize: "14px !important" },
                  }}
                  labelProps={{ sx: { top: 3 } }}
                  {...field}
                />
              );
            }}
            name="dueTime"
            control={control}
          />
        </Grid>
        {/* <Grid xs={12} item>
          <Stack mt={3} direction="row" alignItems="center" gap="9px">
            <Box>
              <Controller
                render={({ field }) => (
                  <LargeCheckbox
                    checked={!!field.value}
                    size={16}
                    formSx={{ m: 0 }}
                    sx={{ padding: 0 }}
                    {...field}
                    onChange={(e, newVal) => {
                      setValue("addTime", newVal);

                      if (!newVal) {
                        setValue("repeatInterval", "day");
                        setValue("repeatStep", "1");
                      }
                    }}
                  />
                )}
                name="addTime"
                control={control}
              />
            </Box>
            <Typography variant="header" fontSize="13px">
              Add time
            </Typography>
          </Stack>
        </Grid> */}
      </Grid>

      {/* Interval */}
      {/* <Box
        sx={{
          mt: 1,
          px: 4,
          height: "35px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <IntervalContainer
          control={control}
          formField={formField}
          setValue={setValue}
        />
      </Box> */}
    </Box>
  );
};

DueDateDrawerBody.propTypes = {
  onSubmit: func,
  timeZone: string,
  control: object,
  setError: func,
  clearErrors: func,
  setValue: func,
  formField: object,
};
DueDateDrawerBody.defaultProps = {
  onSubmit: () => {},
  timeZone: "",
  control: {},
  setError: () => {},
  clearErrors: () => {},
  setValue: () => {},
  formField: {},
};
