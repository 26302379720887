import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { debounce, isArray } from "lodash";
import {
  ActiveTabs,
  CustomFilterMenu,
  FilterMenu,
  FilterSearchTextField,
  FilterViewSaveDialog,
  StyledButton,
} from "components";
import { Box, Chip, Stack, Typography } from "@mui/material";
import { FILTER_TABS } from "../../FormsTab.constants";
import { cl } from "./FormsFilter.styles";
import { FormsPageContext } from "Pages/TasksPage/TasksPage";
import {
  formsFilterAction,
  formsSwitchAction,
  resetFormsFilterAction,
  resetFormsSwitchAction,
} from "redux/actions/forms";
import { CUSTOM_FILTERS_TYPES, NAME_VIEW_DIALOGS } from "utils/constants";
import { FilterIcon } from "components/Icons";
import { useCustomFilterCreate } from "helpers/useCustomFilterCreate";
import { normalizeSnakeCaseString, truncateText } from "helpers/helpers";
import { setSelectedFilterIdAction } from "redux/actions/savedFilters";
import {
  checkForDefaultFilter,
  handleSetSwitchesForEdit,
} from "helpers/filters";
import CloseIcon from "@mui/icons-material/Close";
import { useRepsAssignedActions } from "helpers/useRepsAssignedActions";

export const FormsFilter = () => {
  const dispatch = useDispatch();

  const {
    formsSwitch,
    formsFilter,
    handleUncheckAllForms,
    existDataForms,
    loadingFormsList,
    currentUser,
  } = useContext(FormsPageContext);

  const filterAnchor = useRef();
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);

  const [tabField, setTabField] = useState(FILTER_TABS[0]);

  const { list: repsList } = useRepsAssignedActions({
    params: {
      page: undefined,
      limit: undefined,
      form_active: tabField?.name === FILTER_TABS[0].name,
    },
    open: filterMenuOpen,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((value, setParams) => setParams(value), 500),
    []
  );

  const [search, setSearch] = useState("");

  const [switches, setSwitches] = useState(formsSwitch);
  const [filterFields, setFilterFields] = useState({
    assigned_representative_id: formsFilter?.assigned_representative_id,
  });
  const getChipLabel = (key) => {
    const fieldKeys = filterFields[key]
      ? Object.keys(filterFields[key])
      : undefined;

    const isCombined = key === "price";
    const isArr = isArray(filterFields[key]);

    return (
      <Box display="flex" alignItems="center" gap="6px">
        <Typography
          sx={{ fontSize: "13px", fontWeight: 500 }}
          color="groundLighter.main"
        >
          {isArr ? (
            <>
              {normalizeSnakeCaseString(
                truncateText(filterFields[key][0]?.label || key)
              )}
              :{" "}
              <span style={{ color: "#5F6368" }}>
                {typeof filterFields[key][0] === "string"
                  ? filterFields[key]
                      .map((name) =>
                        normalizeSnakeCaseString(truncateText(name))
                      )
                      .join(", ")
                  : filterFields[key]
                      .map(({ name }) =>
                        normalizeSnakeCaseString(truncateText(name))
                      )
                      .join(", ")}
              </span>
            </>
          ) : (
            <>
              {normalizeSnakeCaseString(
                truncateText(filterFields[key]?.label || key)
              )}
              :{" "}
              <span style={{ color: "#5F6368" }}>
                {isCombined
                  ? fieldKeys
                      .filter((fieldKey) => filterFields[key][fieldKey]?.value)
                      .map((fieldKey) => {
                        const { prefix, value } = filterFields[key][fieldKey];
                        return `${truncateText(prefix)} ${truncateText(value)}`;
                      })
                      .join(" and ")
                  : normalizeSnakeCaseString(
                      truncateText(
                        filterFields?.[key]?.name || filterFields?.[key]
                      )
                    )}
              </span>
            </>
          )}
        </Typography>
      </Box>
    );
  };

  const filterChipKeys = useMemo(
    () => Object.keys(filterFields).filter((key) => filterFields[key]),
    [filterFields]
  );

  const handleDeleteFilter = useCallback(
    (key) => {
      const newState = [...switches];
      const index = newState.findIndex((s) => s.value === key);
      const insert = {
        ...newState[index],
        checked: false,
      };
      if (index > -1) {
        newState.splice(index, 1, insert);

        dispatch(formsSwitchAction([...newState]));

        setSwitches([...newState]);
      }

      dispatch(setSelectedFilterIdAction(null));

      dispatch(
        formsFilterAction({
          ...formsFilter,
          [key]: isArray(filterFields[key]) ? [] : "",
          page: 1,
        })
      );
      handleUncheckAllForms();

      setFilterFields((prev) => {
        return {
          ...prev,
          [key]: isArray(filterFields[key]) ? [] : "",
        };
      });
    },
    [dispatch, filterFields, handleUncheckAllForms, switches, formsFilter]
  );

  const handleApplyFilter = (newSwitches, newFields) => {
    const { assigned_representative_id, ...clearData } = newFields || {};

    dispatch(setSelectedFilterIdAction(null));

    clearData.assigned_representative_id = assigned_representative_id?.value;

    setFilterMenuOpen(false);
    dispatch(
      formsFilterAction({
        ...formsFilter,
        ...clearData,
        page: 1,
      })
    );
    dispatch(
      formsSwitchAction(handleSetSwitchesForEdit(newSwitches, switches))
    );
    handleUncheckAllForms();

    setSwitches([...newSwitches]);
    setFilterFields({ ...newFields });
  };

  const selectMatchData = [
    {
      id: "assigned_representative_id",
      childrenList: [...repsList],
      label: "Select Rep",
    },
  ];

  const onCustomFilterApply = useCallback(
    (newFields) => {
      const { assigned_representative_id, ...clearData } = newFields || {};

      clearData.assigned_representative_id = assigned_representative_id?.value;

      setSwitches(handleSetSwitchesForEdit(newFields, switches));

      dispatch(
        formsFilterAction({
          ...formsFilter,
          ...clearData,
          page: 1,
        })
      );
      setFilterFields({ ...newFields });
    },
    [dispatch, switches, formsFilter]
  );

  const [isFilterChanged, setIsFilterChanged] = useState(false);
  useEffect(() => {
    setIsFilterChanged(true);
    if (isFilterChanged) return;

    checkForDefaultFilter({
      type: CUSTOM_FILTERS_TYPES.FORMS,
      list: currentUser.customFilters,
      setFilters: (filters) => {
        const preparedFilters = {};
        if (filters?.assigned_representative_id?.value)
          preparedFilters.assigned_representative_id =
            filters?.assigned_representative_id?.value;

        if (Object.keys(preparedFilters).length)
          dispatch(
            formsFilterAction({
              ...formsFilter,
              ...preparedFilters,
              page: 1,
            })
          );
        setFilterFields(filters);
      },
      setSwitches: (switchers) => {
        setSwitches(switchers);
        dispatch(formsSwitchAction(switchers));
      },
      switchesList: switches,
      dispatchFunc: (id) => dispatch(setSelectedFilterIdAction(id)),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.customFilters]);

  const {
    onCloseFilterDialog,
    onFilterViewSave,
    onOpenFilterSaveDialog,
    isOpenedMenu,
  } = useCustomFilterCreate({
    createFilterCallback: onCustomFilterApply,
    menuCallback: () => setFilterMenuOpen(false),
    viewTypeKey: NAME_VIEW_DIALOGS.FORMS,
    filterType: CUSTOM_FILTERS_TYPES.FORMS,
  });

  return (
    <>
      {isOpenedMenu && (
        <FilterViewSaveDialog
          open={isOpenedMenu}
          onClose={onCloseFilterDialog}
          handleSave={onFilterViewSave}
        />
      )}
      <Box sx={cl.filterWrapper}>
        <ActiveTabs
          value={tabField.value}
          onChange={(e, newVal) => {
            setTabField({ name: e.target.name, value: newVal });
            handleUncheckAllForms();
            dispatch(
              formsFilterAction({
                ...formsFilter,
                active: newVal,
                page: 1,
              })
            );
          }}
          customTabs={FILTER_TABS}
          tabStyles={{ fontWeight: "400" }}
        />

        <Stack gap="15px" direction="row" width="100%">
          <Box sx={cl.filterSearchBlock}>
            <FilterSearchTextField
              adminIsAllowed
              value={search}
              loading={existDataForms && loadingFormsList}
              onChange={(e) => {
                const value = e.target.value;
                setSearch(value);
                debouncedSearch(value, (v) => {
                  handleUncheckAllForms();
                  dispatch(
                    formsFilterAction({
                      ...formsFilter,
                      page: 1,
                      search: v,
                    })
                  );
                });
              }}
              formSx={cl.searchFromSx}
              placeholder="Search forms"
              fullWidth
              adornmentProps={{ sx: cl.adornmentPropsSx }}
              customAdornment={
                <Box
                  display="flex"
                  gap="5px"
                  overflow="auto"
                  sx={{ py: "4px", "&::-webkit-scrollbar": { height: "2px" } }}
                >
                  {filterChipKeys.map((key) => (
                    <Chip
                      key={key}
                      sx={{
                        borderRadius: "4px",
                        border: "0.7px solid #d5d9d9",
                        backgroundColor: "#FAFAFB",
                        height: "28px",
                      }}
                      size="small"
                      label={getChipLabel(key)}
                      deleteIcon={
                        <CloseIcon size="15" style={{ fill: "#707070" }} />
                      }
                      onMouseDown={(e) => e.stopPropagation()}
                      onDelete={() => handleDeleteFilter(key)}
                    />
                  ))}
                </Box>
              }
              handleClearValue={() => {
                filterChipKeys?.length
                  ? null
                  : () => {
                      setSearch("");
                      handleUncheckAllForms("");
                      dispatch(
                        formsFilterAction({
                          ...formsFilter,
                          page: 1,
                          search: undefined,
                        })
                      );
                    };
              }}
            />
          </Box>

          <CustomFilterMenu
            type={CUSTOM_FILTERS_TYPES.FORMS}
            switches={switches}
            onFilterApply={onCustomFilterApply}
            selectMatchData={selectMatchData}
            resetFilterFunc={() => {
              dispatch(resetFormsSwitchAction());
              dispatch(resetFormsFilterAction());

              setFilterFields({
                assigned_representative_id: undefined,
              });
              setSwitches(formsSwitch);
            }}
          />

          <Box>
            <StyledButton
              label="Filter"
              startIcon={
                filterChipKeys.length ? (
                  <Typography
                    fontSize="9px!important"
                    color="#ffffff"
                    backgroundColor="#47A06D"
                    borderRadius="50%"
                    width="15px"
                    height="15px"
                  >
                    {filterChipKeys.length}
                  </Typography>
                ) : (
                  <FilterIcon />
                )
              }
              variant="outlined"
              ref={filterAnchor}
              color="edit"
              sx={{
                height: "39px",
                width: "100%",
                maxWidth: "74px",
                border: "0.5px solid #D5D9D9",
                "& .MuiButton-startIcon": {
                  ml: 0,
                },
              }}
              fontSize="15px"
              onClick={() => setFilterMenuOpen(true)}
            />
            <FilterMenu
              anchorEl={filterAnchor.current}
              open={filterMenuOpen}
              onClose={() => setFilterMenuOpen(false)}
              filterFields={filterFields}
              switches={switches}
              handleApplyFilter={handleApplyFilter}
              selectMatchData={selectMatchData}
              onViewSave={onOpenFilterSaveDialog}
            />
          </Box>
        </Stack>
      </Box>
    </>
  );
};
