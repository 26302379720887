import {
  CREATE_MANUFACTURER,
  DELETE_MANUFACTURER,
  END_LOADING_MANUFACTURERS,
  GET_MANUFACTURERS,
  START_LOADING_MANUFACTURERS,
  UPDATE_MANUFACTURER,
  GET_MANUFACTURER_COUNT,
  GET_MANUFACTURERS_INACTIVE,
  GET_MANUFACTURER_COUNT_INACTIVE,
} from "../actions/manufacturers";

const initialState = {
  manufacturers: [],
  loading: false,
  count: 0,
  manufacturersInactive: [],
  countInactive: 0,
  existData: false,
  existDataInactive: false,
};

const manufacturersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_MANUFACTURERS:
      if (payload.isScrolling) {
        return {
          ...state,
          manufacturers: [...state.manufacturers, ...payload.manufacturers],
          existData: payload.existData,
        };
      }
      return {
        ...state,
        manufacturers: payload.manufacturers,
        existData: payload.existData,
      };

    case GET_MANUFACTURER_COUNT:
      return { ...state, count: payload };
    case GET_MANUFACTURERS_INACTIVE:
      if (payload.isScrolling) {
        return {
          ...state,
          manufacturersInactive: [
            ...state.manufacturersInactive,
            ...payload.manufacturersInactive,
          ],
        };
      }
      return {
        ...state,
        manufacturersInactive: payload.manufacturersInactive,
        existDataInactive: payload.existDataInactive,
      };
    case GET_MANUFACTURER_COUNT_INACTIVE:
      return { ...state, countInactive: payload };

    case START_LOADING_MANUFACTURERS:
      return { ...state, loading: true };
    case CREATE_MANUFACTURER:
      return { ...state, manufacturers: [...state.manufacturers, payload] };
    case DELETE_MANUFACTURER: {
      const manufacturersList =
        payload.status === "ACTIVE"
          ? [...state.manufacturers]
          : [...state.manufacturersInactive];

      const index = manufacturersList.findIndex(
        (manufacturer) => manufacturer.id === payload.id
      );
      if (index >= 0) {
        manufacturersList.splice(index, 1);
      }
      return {
        ...state,
        manufacturers:
          payload.status === "ACTIVE"
            ? manufacturersList
            : [...state.manufacturers],
        manufacturersInactive:
          payload.status === "INACTIVE"
            ? manufacturersList
            : [...state.manufacturersInactive],
        loading: false,
        count: state.count - 1,
      };
    }
    case UPDATE_MANUFACTURER: {
      const manufacturersList = [...state.manufacturers];
      const updateIndex = manufacturersList.findIndex(
        (customer) => customer.id === payload.id
      );
      if (updateIndex >= 0) {
        manufacturersList.splice(updateIndex, 1, payload);
      }
      return { ...state, manufacturers: manufacturersList, loading: false };
    }
    case END_LOADING_MANUFACTURERS:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default manufacturersReducer;
