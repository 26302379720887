import { func, bool, number, object } from "prop-types";

import { ArrowDownIcon, ArrowUpIcon, CheckboxIcon } from "components/Icons";
import TableHeader from "../../../../components/TableHeader";

import { Box, Checkbox, Grid, Typography } from "@mui/material";
import { Fragment } from "react";

const RoutesHeader = ({
  isChecked,
  quickSort,
  handleSetSortBy,
  handleCheckAvailableRoutes,
}) => {
  const SORTABLE_COLUMNS = {
    "Route name": { sortableField: "sort_by_name" },
    "Last check in": { sortableField: "sort_by_last_check_in" },
    "Total Stops": { sortableField: "sort_by_total_stops" },
  };

  const HEADER_DATA = [
    {
      title: "Status",
      xs: 1.4,
      wrapperProps: { sx: { textAlign: "center" } },
      element: null,
    },
    {
      title: "Route name",
      xs: 3.5,
      element: null,
    },
    {
      title: "Last check in",
      xs: 2.8,
      element: null,
      sortable: true,
    },
    {
      title: "Total Stops",
      xs: 2.4,
      wrapperProps: {},
      element: null,
    },

    {
      title: "Priority",
      xs: 2.1,
      wrapperProps: {},
      element: null,
    },
    {
      title: "Progress",
      xs: 4.65,
      wrapperProps: { pl: 4.5 },
      element: null,
    },
    {
      title: "Activation date",
      xs: 3,
      wrapperProps: { pl: 4.5 },
      element: null,
    },
  ];

  return (
    <TableHeader
      sx={{
        borderWidth: "0.5px",
        borderTop: "none",
        display: "flex",
        "& .MuiTypography-root": {
          fontSize: "12px",
          textTransform: "uppercase",
          color: "#6a6a6a",
        },
      }}
      height="39px"
      component={Box}
    >
      <Box
        width="84px"
        pl="16px"
        sx={{
          display: "flex",
          alignItems: "center",
          height: "39px",
        }}
      >
        <Checkbox
          style={{ width: 22, height: 22, mx: "auto" }}
          icon={<CheckboxIcon size={22} />}
          sx={{
            padding: 0,
            "& .MuiSvgIcon-root": { fontSize: 30 },
          }}
          checked={isChecked}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={() => handleCheckAvailableRoutes(!isChecked)}
        />
      </Box>
      <Grid
        sx={{ width: "calc(100% - 64px)" }}
        item
        xs={21}
        columns={21}
        container
      >
        {HEADER_DATA.map(({ xs, title, wrapperProps, element }, index) => (
          <Fragment key={index}>
            {element ? (
              element
            ) : (
              <Grid
                container
                alignItems="center"
                gap="8px"
                item
                xs={xs}
                {...wrapperProps}
              >
                <Typography>{title}</Typography>
                {SORTABLE_COLUMNS[title] && (
                  <Box
                    sx={{ display: "flex", cursor: "pointer" }}
                    onClick={() => {
                      if (SORTABLE_COLUMNS[title])
                        return handleSetSortBy(
                          SORTABLE_COLUMNS[title].sortableField
                        );
                    }}
                  >
                    {quickSort[SORTABLE_COLUMNS[title].sortableField] ===
                    "asc" ? (
                      <ArrowDownIcon />
                    ) : (
                      <ArrowUpIcon />
                    )}
                  </Box>
                )}
              </Grid>
            )}
          </Fragment>
        ))}
      </Grid>
    </TableHeader>
  );
};

RoutesHeader.propTypes = {
  loading: bool,
  isChecked: bool,
  quickSort: object,
  itemsCount: number,
  allAreChecked: bool,
  selectedCount: number,
  handleSetSortBy: func,
  handleCheckAllRoutes: func,
  handleCheckAvailableRoutes: func,
};

export default RoutesHeader;
