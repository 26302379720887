import { func, object, number, string } from "prop-types";

import { CategoryItem } from "../CategoryItem";
import { CategoryHeader } from "../CategoryHeader";

import { cl } from "../../styles";

import {
  Box,
  CircularProgress,
  Collapse,
  Stack,
  Typography,
} from "@mui/material";
import { CategoryFilterBlock } from "../CategoryFilterBlock";
import { InfiniteLoadMoreBtn, InfiniteScrollWrapper } from "components";

export const CategoryContentBlock = ({
  categoriesState,
  page,
  handleFetchCategories,
  handleSetSort,
  quickSort,
  handleCheckCategory,
  expandParentId,
  handleExpandChildrenList,
  setListSearch,
  searchInput,
  isCategorySelected,
  selectCategoryWithChildren,
  isIntermediateSelected,
  checkedCount,
  totalCountOfElements,
}) => {
  const { list, loading, count, existData } = categoriesState;

  return (
    <Stack sx={cl.tableWrapper}>
      <CategoryFilterBlock
        {...{ setListSearch, searchInput, loading: loading && existData }}
      />

      <CategoryHeader
        {...{
          handleSetSort,
          quickSort,
          categoriesState,
          onSelectAll: selectCategoryWithChildren,
          checkedCount,
          totalCountOfElements,
        }}
      />
      {loading && !list.length ? (
        <Stack alignItems="center" justifyContent="center" height="335px">
          <CircularProgress />
        </Stack>
      ) : list.length ? (
        <>
          <Stack
            sx={{
              maxHeight: "335px",
              height: "100%",
              overflowY: "hidden",
            }}
          >
            <InfiniteScrollWrapper
              maxHeight="335px"
              dataLength={list.length}
              id="category-popup-list-scroll-table"
              loading={loading}
              nextFunc={() => handleFetchCategories(page + 1)}
              loader={
                <CircularProgress
                  sx={{ ml: "calc(50% - 15px)", mt: "10px" }}
                  size="30px"
                />
              }
              hasMoreItems={list.length < count}
            >
              {list?.map((category) => (
                <Box key={category.id}>
                  <CategoryItem
                    key={category.id}
                    isExpanded={category?.id === expandParentId}
                    isLastCategory={category?.id === list[list.length - 1]?.id}
                    item={category}
                    onCheck={handleCheckCategory}
                    onExpand={() => handleExpandChildrenList(category.id)}
                    isCategorySelected={isCategorySelected}
                    isIntermediateSelected={isIntermediateSelected}
                  />
                  <Collapse in={expandParentId === category.id} timeout={300}>
                    {category?.childCategories?.map((child, indexChild) => (
                      <CategoryItem
                        key={child.id}
                        onCheck={handleCheckCategory}
                        item={child}
                        isChild
                        parent={category}
                        isCategorySelected={isCategorySelected}
                        isLastCategory={
                          category?.childCategories.length === indexChild + 1
                        }
                      />
                    ))}
                  </Collapse>
                </Box>
              ))}

              {!loading && list.length < count && (
                <InfiniteLoadMoreBtn
                  onClick={() => handleFetchCategories(page + 1)}
                />
              )}
            </InfiniteScrollWrapper>
          </Stack>
        </>
      ) : (
        <Stack alignItems="center" justifyContent="center" height="335px">
          <Typography fontSize="16px" color="#6A6A6A">
            No categories found
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

CategoryContentBlock.propTypes = {
  categoriesState: object,
  page: number,
  handleFetchCategories: func,
  isCategorySelected: func,
  handleIsAlreadyAdded: func,
  selectCategoryWithChildren: func,
  handleExpandChildrenList: func,
  handleSetSort: func,
  quickSort: object,
  handleCheckCategory: func,
  expandParentId: number,
  setListSearch: func,
  isIntermediateSelected: func,
  searchInput: string,
  checkedCount: number,
  totalCountOfElements: number,
};
