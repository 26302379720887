import { useCallback, useEffect } from "react";
import { bool, func, array } from "prop-types";
import { Drawer } from "@mui/material";
import {
  DueDateDrawerActions,
  DueDateDrawerBody,
  DueDateDrawerHeader,
} from "./components";
import { useSelector } from "react-redux";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./DueDateDrawer.validations";

export const DueDateDrawer = ({
  checkedTasksIds,
  open,
  handleClose,
  handleSave,
}) => {
  const currentUser = useSelector(({ auth }) => auth.currentUser);
  const { timeZone } = currentUser || {};

  const {
    control,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onSubmit",
    defaultValues: { dueDate: "", dueTime: "" },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({ control });

  const onSubmit = useCallback(
    (data) => {
      const { dueDate, dueTime } = data || {};

      const preparedData = {
        tasksIds: checkedTasksIds,
        dueDate,
      };

      if (dueTime) preparedData.dueTime = dueTime;

      handleSave(preparedData);
      handleClose();
    },
    [handleClose, handleSave, checkedTasksIds]
  );

  useEffect(() => {
    if (!open) reset({ dueDate: "", dueTime: "" });
  }, [open, reset]);

  return (
    <Drawer anchor="right" open={open}>
      <DueDateDrawerHeader onClose={handleClose} />

      <DueDateDrawerBody
        onSubmit={handleSubmit(onSubmit)}
        timeZone={timeZone}
        control={control}
        setError={setError}
        clearErrors={clearErrors}
        setValue={setValue}
        formField={formField}
      />

      <DueDateDrawerActions
        onClose={handleClose}
        formField={formField}
        errors={errors}
      />
    </Drawer>
  );
};

DueDateDrawer.propTypes = {
  open: bool,
  handleClose: func,
  handleSave: func,
  checkedTasksIds: array,
};
DueDateDrawer.defaultProps = {
  open: false,
  handleClose: () => {},
  handleSave: () => {},
  checkedTasksIds: [],
};
