import * as React from "react";
export const PeopleIcon = (props) => (
  <svg
    width={20}
    height={18}
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.4 17h1.98A1.61 1.61 0 0 0 19 15.4v-.178c0-1.963-1.612-3.555-3.6-3.555M10.45 4.11c0 1.718-1.41 3.111-3.15 3.111S4.15 5.83 4.15 4.112C4.15 2.391 5.56 1 7.3 1s3.15 1.393 3.15 3.111m6.75 2.667C17.2 8.005 16.193 9 14.95 9s-2.25-.995-2.25-2.222c0-1.228 1.007-2.222 2.25-2.222s2.25.994 2.25 2.222M3.16 17h8.28c1.193 0 2.16-.955 2.16-2.133 0-2.75-2.256-4.978-5.04-4.978H6.04C3.256 9.889 1 12.118 1 14.867 1 16.045 1.967 17 3.16 17"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
