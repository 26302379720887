import { bool, func, number, string } from "prop-types";
import { Box, LinearProgress, Typography } from "@mui/material";
import { LINEAR_PROGRESS_COLORS } from "Pages/FormViewPage/FormViewPage.constants";
import { CheckmarkIcon } from "components/Icons";

export const LinearProgressComponent = ({
  title = "",
  value = 0,
  // isDialog = false,
  onClick = () => {},
  index = 0,
  isChecked = false,
}) => {
  const setRight = (value) => {
    if (value === 100) return "-7.5%";

    if (value >= 20 && value < 100) return `${100 - value - 6.5}%`;

    if (value >= 10 && value < 20) return `${100 - value - 6}%`;

    if (value > 0 && value < 10) return `${100 - value - 5}%`;

    return `${100 - value - 4}%`;
  };

  return (
    <Box
      sx={{
        position: "relative",
        p: "8px 6px 4px 6px",
        borderRadius: "4px",
        // ...(!isDialog && {
        cursor: "pointer",
        "&:hover": {
          bgcolor: isChecked ? "#409A6520" : "#70707010",
        },
        // }),
        ...(!!isChecked && {
          cursor: "pointer",
          bgcolor: "#409A6520",
        }),
      }}
      onClick={onClick}
    >
      {!!isChecked && (
        <Box
          sx={{
            position: "absolute",
            right: "6px",
            top: "50%",
            transform: "translateY(-50%)",
          }}
        >
          <CheckmarkIcon width="24" height="19" />
        </Box>
      )}

      <Typography fontSize={13} fontWeight={400} color="#5F6267">
        {title}
      </Typography>

      <Box width="94%" maxWidth="94%" sx={{ pt: "2px", position: "relative" }}>
        <LinearProgress
          variant="determinate"
          value={value}
          sx={{
            height: "16px",
            backgroundColor: "transparent",
            borderRadius: "4px",
            "& .MuiLinearProgress-barColorPrimary": {
              backgroundColor: LINEAR_PROGRESS_COLORS[index],
              borderRadius: "4px",
            },
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: "1px",
            right: setRight(value),
          }}
        >
          <Typography
            sx={{
              fontSize: 12,
              fontWeight: 600,
              color: "#000",
            }}
          >
            {value}%
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

LinearProgressComponent.propTypes = {
  title: string,
  value: number,
  // isDialog: bool,
  onClick: func,
  index: number,
  isChecked: bool,
};
