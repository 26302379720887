export const cl = {
  paper: {
    mt: "16px",
    p: "16px",
    position: "relative",
    width: "100%",
  },
  title: {
    color: "#5F6267",
    fontWeight: 500,
    fontSize: "clamp(15px, 1.3vw, 24px)",
    position: "relative",
  },
  dividerWrapper: {
    width: "100%",
    height: "24px",
    display: "flex",
    alignItems: "center",
  },
  divider: {
    borderTop: "0.5px solid #D9D9D9",
    width: "100%",
    height: "1px",
  },
  customerDiscountText: {
    fontSize: "14px",
    color: "#707070",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    position: "relative",
    left: "20px",
  },
};
