import { makeStyles } from "@mui/styles";

export const cl = {
  NewRepresentativesFilter: {
    wrapper: {
      backgroundColor: "#ffffff",
      display: "flex",
      border: "1px solid #d5d9d9",
      borderRadius: "4px 4px 0 0",
      alignItems: "center",
      padding: "0px 12px 0px 17px",
      height: "67px",
      gap: "11px",
    },
    searchAdor: {
      display: "flex",
      gap: "5px",
      overflow: "auto",
      justifyContent: "flex-end",
      py: "4px",
      "&::-webkit-scrollbar": {
        height: "2px",
      },
    },
    btnsWrapper: {
      display: "flex",
      alignItems: "center",
      gap: 1.2,
    },
    btn: { height: "39px", px: 0 },
    btnLabel: {
      fontSize: "9px!important",
      color: "#ffffff",
      backgroundColor: "#47A06D",
      borderRadius: "50%",
      width: "15px",
      height: "15px",
    },
    btnFilter: {
      height: "39px",
      width: "100%",
      maxWidth: "74px",
      border: "0.5px solid #D5D9D9",
      "& .MuiButton-startIcon": {
        ml: 0,
      },
    },
  },
};

const useStyles = makeStyles(() => ({
  filterChip: {
    borderRadius: "4px",
    border: "0.7px solid #d5d9d9",
    backgroundColor: "#FAFAFB",
    height: "28px",
  },
}));

export default useStyles;
