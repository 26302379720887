import * as React from "react";
export const FormYesNoTypeIcon = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)" fill="#C9C9C9">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.636a6.364 6.364 0 1 1 0 12.728A6.364 6.364 0 0 1 8 1.636m.682 1.41v9.908a5.001 5.001 0 0 0 0-9.908m-1.364 9.908V3.046a5.001 5.001 0 0 0 0 9.908"
      />
      <path d="M8 13.91V2.09l3.182 1.364L13 5.727l.455 3.182-2.728 4.546z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
