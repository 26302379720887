import React, { useState, useCallback, useEffect, useRef } from "react";
import { string, node, number, bool, object } from "prop-types";

import { Box } from "@mui/material";
import useStyles from "./styles";

const SCROLL_BOX_MIN_HEIGHT = 20;

export const ScrollControlWrapper = ({
  children,
  dataLength = null,
  loading = false,
  maxHeight = "100%",
  id = "",
  documentId = "",
  scrollBarStyle = {},
  hideScroll = false,
  dynamicHeight = false,
  ...restProps
}) => {
  const classes = useStyles({ maxHeight, scrollBarStyle });

  const scrollHostRef = useRef();
  const contentWrapper = document.getElementById(
    documentId || "content-wrapper"
  );

  const scrollHostElement =
    id && scrollHostRef ? scrollHostRef.current : contentWrapper;

  const [hovering, setHovering] = useState(false);
  const [isDragging, setDragging] = useState(false);
  const [scrollBoxTop, setScrollBoxTop] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const [isScrollNeeded, setIsScrollNeeded] = useState(true);
  const [lastScrollThumbPosition, setScrollThumbPosition] = useState(0);
  const [scrollBoxHeight, setScrollBoxHeight] = useState(SCROLL_BOX_MIN_HEIGHT);

  const handleMouseOver = useCallback(() => {
    if (hideScroll) return;
    !hovering && setHovering(true);
  }, [hideScroll, hovering]);

  const handleMouseOut = useCallback(() => {
    !!hovering && setHovering(false);
  }, [hovering]);

  const handleDocumentMouseUp = useCallback(
    (e) => {
      if (isDragging) {
        e.preventDefault();
        setDragging(false);
      }
    },
    [isDragging]
  );

  const handleDocumentMouseMove = useCallback(
    (e) => {
      if (!scrollHostElement) return;
      if (isDragging) {
        e.preventDefault();
        e.stopPropagation();

        const { scrollHeight, offsetHeight } = scrollHostElement;

        let deltaY = e.clientY - lastScrollThumbPosition;
        let percentage = deltaY * (scrollHeight / offsetHeight);

        setScrollThumbPosition(e.clientY);
        setScrollBoxTop(
          Math.min(
            Math.max(0, scrollBoxTop + deltaY),
            offsetHeight - scrollBoxHeight
          )
        );
        scrollHostElement.scrollTop = Math.min(
          scrollHostElement.scrollTop + percentage,
          scrollHeight - offsetHeight
        );
      }
    },
    [
      isDragging,
      lastScrollThumbPosition,
      scrollBoxHeight,
      scrollBoxTop,
      scrollHostElement,
    ]
  );

  const handleScrollThumbMouseDown = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setScrollThumbPosition(e.clientY);
    setDragging(true);
  }, []);

  const onParentWrapperCheck = useCallback(() => {
    const parentElement = document.getElementsByClassName(
      "infinite-scroll-component__outerdiv"
    );
    if (
      parentElement &&
      parentElement[0] &&
      parentElement[0]?.offsetHeight !== containerHeight
    ) {
      setContainerHeight(parentElement[0]?.offsetHeight);
    }
  }, [containerHeight]);

  const handleScroll = useCallback(() => {
    if (!scrollHostRef || !scrollHostElement) {
      return;
    }
    const { scrollTop, scrollHeight, offsetHeight } = scrollHostElement;

    let newTop =
      (parseInt(scrollTop, 10) / parseInt(scrollHeight, 10)) * offsetHeight;
    newTop = Math.min(newTop, offsetHeight - scrollBoxHeight);
    setScrollBoxTop(newTop);

    if (dynamicHeight) onParentWrapperCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollBoxHeight, dataLength, scrollHostElement]);

  useEffect(() => {
    if (!scrollHostElement) return;

    const { clientHeight, scrollHeight } = scrollHostElement;

    if (!(clientHeight > 0 && scrollHeight > 0)) return;

    const scrollThumbPercentage = clientHeight / scrollHeight;
    const scrollThumbHeight = Math.max(
      scrollThumbPercentage * clientHeight,
      SCROLL_BOX_MIN_HEIGHT
    );
    setScrollBoxHeight(scrollThumbHeight);
    handleScroll();
  }, [handleScroll, dataLength, scrollHostElement, containerHeight]);

  useEffect(() => {
    if (!scrollHostElement) return;

    scrollHostElement.addEventListener("scroll", handleScroll, true);
    return function cleanup() {
      scrollHostElement.removeEventListener("scroll", handleScroll, true);
    };
  }, [handleScroll, scrollHostElement]);

  useEffect(() => {
    if (!scrollHostElement) return;

    const { clientHeight, scrollHeight } = scrollHostElement;
    setIsScrollNeeded(scrollHeight > clientHeight);
  }, [dataLength, scrollHostElement]);

  useEffect(() => {
    document.addEventListener("mousemove", handleDocumentMouseMove);
    document.addEventListener("mouseup", handleDocumentMouseUp);
    document.addEventListener("mouseleave", handleDocumentMouseUp);
    return function cleanup() {
      document.removeEventListener("mousemove", handleDocumentMouseMove);
      document.removeEventListener("mouseup", handleDocumentMouseUp);
      document.removeEventListener("mouseleave", handleDocumentMouseUp);
    };
  }, [handleDocumentMouseMove, handleDocumentMouseUp]);

  useEffect(() => {
    onParentWrapperCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      className={classes.scrollhostContainer}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <Box
        className={classes.scrollhost}
        id={id}
        ref={scrollHostRef}
        {...restProps}
      >
        {children}
      </Box>
      <Box
        className={classes.scrollBar}
        style={{ opacity: !hovering || loading || !isScrollNeeded ? 0 : 1 }}
      >
        <Box
          className={classes.scrollThumb}
          style={{ height: scrollBoxHeight, top: scrollBoxTop }}
          onMouseDown={handleScrollThumbMouseDown}
        />
      </Box>
    </Box>
  );
};

export default ScrollControlWrapper;

ScrollControlWrapper.propTypes = {
  children: node,
  dataLength: number || null,
  loading: bool,
  maxHeight: string,
  id: string,
  documentId: string,
  scrollBarStyle: object,
  hideScroll: bool,
  dynamicHeight: bool,
};
