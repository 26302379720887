export const STEPS = ["Input Data", "Map Data", "Import"];

export const TEST_ROWS = [
  {
    id: 1,
    customId: "1234567890",
    name: "Test2",
    formatted_address: "Street name ave 3",
    city: "New York",
    state: "New York",
    zip: 1234543,
    phone: "(043)-282828",
    email: "email@gmail.com",
    contactName: "Contact Name",
    contactRole: "Manager",
    assignedSales: "Sales",
    assignedDelivery: "Delivery",
    territory: "Brooklyn",
    tags: "tag1, tag2",
  },
  {
    id: 2,
    customId: "1234567890",
    name: "Test3",
    formatted_address: "Street name ave 3",
    city: "New York",
    state: "New York",
    zip: 1234543,
    phone: "(043)-282828",
    email: "email@gmail.com",
    contactName: "Contact Name",
    contactRole: "Manager",
    assignedSales: "Sales",
    assignedDelivery: "Delivery",
    territory: "Brooklyn",
    tags: "tag1, tag2",
  },
  {
    id: 3,
    customId: "1234567890",
    name: "Test4",
    formatted_address: "Street name ave 3",
    city: "New York",
    state: "New York",
    zip: 1234543,
    phone: "(043)-282828",
    email: "email@gmail.com",
    contactName: "Contact Name",
    contactRole: "Manager",
    assignedSales: "Sales",
    assignedDelivery: "Delivery",
    territory: "Brooklyn",
    tags: "tag1, tag2",
  },
  {
    id: 4,
    customId: "1234567890",
    name: "Test5",
    formatted_address: "Street name ave 3",
    city: "New York",
    state: "New York",
    zip: 1234543,
    phone: "(043)-282828",
    email: "email@gmail.com",
    contactName: "Contact Name",
    contactRole: "Manager",
    assignedSales: "Sales",
    assignedDelivery: "Delivery",
    territory: "Brooklyn",
    tags: "tag1, tag2",
  },
  {
    id: 5,
    customId: "1234567890",
    name: "Test6",
    formatted_address: "Street name ave 3",
    city: "New York",
    state: "New York",
    zip: 1234543,
    phone: "(043)-282828",
    email: "email@gmail.com",
    contactName: "Contact Name",
    contactRole: "Manager",
    assignedSales: "Sales",
    assignedDelivery: "Delivery",
    territory: "Brooklyn",
    tags: "tag1, tag2",
  },
];

export const getGridColumns = ({ editable }) => {
  return [
    {
      field: "customId",
      headerName: "Customer #",
      editable,
      flex: 1,
      minWidth: 160,
    },
    {
      field: "name",
      headerName: "Name",
      editable,
      flex: 1,
      minWidth: 160,
    },
    {
      field: "formatted_address",
      headerName: "Address",
      editable,
      flex: 1,
      minWidth: 160,
    },
    {
      field: "city",
      headerName: "City",
      editable,
      flex: 1,
      minWidth: 160,
    },
    {
      field: "state",
      headerName: "State",
      editable,
      flex: 1,
      minWidth: 160,
    },
    {
      field: "zip",
      headerName: "Zip code",
      editable,
      flex: 1,
      minWidth: 160,
    },
    {
      field: "phone",
      headerName: "Phone #",
      editable,
      flex: 1,
      minWidth: 160,
    },
    {
      field: "email",
      headerName: "Email",
      editable,
      flex: 1,
      minWidth: 160,
    },
    {
      field: "contactName",
      headerName: "Contact Name",
      editable,
      flex: 1,
      minWidth: 160,
    },
    {
      field: "contactRole",
      headerName: "Contact Role",
      editable,
      flex: 1,
      minWidth: 160,
    },
    {
      field: "assignedSales",
      headerName: "Assigned Sales Rep",
      editable,
      flex: 1,
      minWidth: 160,
    },
    {
      field: "assignedDelivery",
      headerName: "Assigned Delivery Rep",
      editable,
      flex: 1,
      minWidth: 160,
    },
    {
      field: "territory",
      headerName: "Territory",
      editable,
      flex: 1,
      minWidth: 160,
    },
    {
      field: "tags",
      headerName: "Tags",
      editable,
      flex: 1,
      minWidth: 160,
    },
  ];
};

export const HEADER_OPTIONS = [
  "Business Name",
  "Group",
  "FED Tax ID (EIN)",
  "Address (Billing)",
  "City (Billing)",
  "State (Billing)",
  "Zip Code (Billing)",
  "Address (Shipping)",
  "City (Shipping)",
  "State (Shipping)",
  "Zip Code (Shipping)",
  "Business phone",
  "Email address",
  "Payment terms",
  "Customer discount",
  "Territory",
  "Website",
  "Tags",
];

export const HEADERS = [
  {
    field: "name",
    headerName: "Business Name",
    id: "A",
  },
  {
    field: "group",
    headerName: "Group",
    id: "B",
  },
  {
    field: "federalTaxId",
    headerName: "FED Tax ID (EIN)",
    id: "C",
  },
  {
    field: "city",
    headerName: "City",
    id: "D",
  },
  {
    field: "state",
    headerName: "State",
    id: "E",
  },
  {
    field: "zip",
    headerName: "Zip code",
    id: "F",
  },
  {
    field: "phone",
    headerName: "Phone #",
    id: "G",
  },
  {
    field: "email",
    headerName: "Email",
    id: "H",
  },
  {
    field: "contactName",
    headerName: "Contact Name",
    id: "I",
  },
  {
    field: "contactRole",
    headerName: "Contact Role",
    id: "J",
  },
  {
    field: "assignedSales",
    headerName: "Assigned Sales Rep",
    id: "K",
  },
  {
    field: "assignedDelivery",
    headerName: "Assigned Delivery Rep",
    id: "L",
  },
  {
    field: "territory",
    headerName: "Territory",
    id: "M",
  },
  {
    field: "tags",
    headerName: "Tags",
    id: "N",
  },
];

export const IMPORT_LIMIT = 10;

export const IMPORT_STATUSES = {
  READY_FOR_REMAP: { status: "READY_FOR_REMAP", step: 1 },
  READY_FOR_VALIDATION: { status: "READY_FOR_VALIDATION", step: 2 },
  ERROR_VALIDATION: { status: "ERROR_VALIDATION", step: 2 },
  READY_FOR_IMPORT: { status: "READY_FOR_IMPORT", step: 2 },
  IMPORT_PROGRESS: { status: "IMPORT_PROGRESS", step: 2 },
  VALIDATION_PROGRESS: { status: "VALIDATION_PROGRESS", step: 2 },
  REMAP_PROGRESS: { status: "REMAP_PROGRESS", step: 1 },
  ERROR: { status: "ERROR", message: "Error while importing current file." },
  ERROR_REMAP: {
    status: "ERROR_REMAP",
    step: 1,
    message: "Error while remapping current file.",
  },
  DONE: {
    status: "DONE",
  },
};

export const IMPORT_INSTRUCTIONS = {
  customers: [
    "All customer information must be in one file",
    "Top row of your file must contain a header title for each column of information",
    "The file is limited to 5,000 customers",
  ],
  orders: [
    "All order information must be in one file",
    "Top row of your file must contain a header title for each column of information",
  ],
};

export const CUSTOMER_IMPORT_STATUSES = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
  { value: "Prospect", label: "Prospect" },
];
