import { Stack, Typography } from "@mui/material";
import { SelectedInfoBlock } from "components";
import { useAdmin } from "helpers/helpers";

import { number, func, bool } from "prop-types";
export const ProductAssignBlock = ({ showAllProducts, count, onEdit }) => {
  const isAdmin = useAdmin();

  return (
    <Stack gap="12px" mt="4px">
      <Typography fontSize="18px" color="#3F3F3F" lineHeight="22px">
        Products Assigned
      </Typography>
      <SelectedInfoBlock
        showZero={!showAllProducts}
        type="product"
        emptyLabel="All products"
        count={showAllProducts ? null : count}
        {...{ isAdmin, onEdit }}
      />
    </Stack>
  );
};

ProductAssignBlock.propTypes = {
  count: number,
  onEdit: func,
  showAllProducts: bool,
};
