import { handleError } from "helpers/helpers";
import { useCallback, useEffect, useState } from "react";
import { getFormsWithResponsesService } from "services/forms";

const initState = {
  loading: true,
  data: null,
};

const initParams = {
  is_completed: true,
  representative_ids: [],
  customer_ids: [],
  responses_created_at: "this_week",
  responses_created_at_start_date: "",
  responses_created_at_end_date: "",
};

export const useFormViewResponsesPage = ({ open = false, formId }) => {
  const [responsesPageState, setResponsesPageState] = useState(initState);
  const [params, setParams] = useState(initParams);

  const fetchResponses = useCallback(async () => {
    try {
      const res = await getFormsWithResponsesService({ id: formId, params });

      setResponsesPageState((prev) => ({ ...prev, data: res }));
    } catch (error) {
      handleError(error);
    } finally {
      setResponsesPageState((prev) => ({ ...prev, loading: false }));
    }
  }, [formId, params]);

  useEffect(() => {
    if (!formId) return;

    open && fetchResponses();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId, open, params]);

  return {
    ...responsesPageState,
    setResponsesQueryParams: setParams,
  };
};
