import { useCallback, useEffect, useState } from "react";
import { Box, Button, CircularProgress, Stack } from "@mui/material";
import DiscountsPageTab from "./components/DiscountsPageTab/DiscountsPageTab";
import {
  AccordionComponent,
  SummaryComponent,
  DetailsComponent,
} from "../../components/AccordionComponent/AccordionComponent";
import SummaryItem from "./components/SummaryItem.jsx";
import HeaderList from "./components/HeaderList.jsx";

import ItemList from "./components/ItemList.jsx";
import { containsEvery, useAdmin } from "../../helpers/helpers";
import { currentUserSelector } from "../../redux/selectors/auth";
import {
  manufacturersDiscountsListSelector,
  manufacturersDiscountsCountSelector,
  manufacturersDiscountsLoadingSelector,
  manufacturersDiscountsCountAllSelector,
} from "../../redux/selectors/manufacturers-discounts.js";

import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
// import DiscountFilter from "./components/DiscountFilter";
import {
  getManufacturersDiscountsAction,
  getManufacturerByIdWithDiscountsAction,
} from "../../redux/actions/manufacturers-discounts.js";
import {
  ADMIN_ONLY_VIEW_MESSAGE,
  SCROLL_LIMIT,
  SCROLL_LIMIT_DISCOUNT,
} from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import {
  deleteDiscountAction,
  updateDiscountAction,
} from "../../redux/actions/discounts";
import ProductsMenu from "./components/ProductsMenu/ProductsMenu";
import EmptyScreen from "../../components/EmptyScreen/EmptyScreen";
import { NewDiscountsFilter } from "./components";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
  setEditItemAction,
  setFormChangedAction,
} from "../../redux/actions/confirmDialogs";
import StyledButton from "../../components/StyledButton";
import { InfiniteLoadMoreBtn, InfiniteScrollWrapper } from "../../components";
import { useRepsPermissions } from "helpers/hooks";

const selector = createSelector(
  manufacturersDiscountsListSelector,
  manufacturersDiscountsCountSelector,
  manufacturersDiscountsCountAllSelector,
  currentUserSelector,
  manufacturersDiscountsLoadingSelector,
  (
    manufacturersDiscountsList,
    manufacturersDiscountsCount,
    manufacturersDiscountsCountAll,
    currentUser,
    manufacturersDiscountsLoading
  ) => ({
    manufacturersDiscountsList,
    manufacturersDiscountsCount,
    manufacturersDiscountsCountAll,
    currentUser,
    manufacturersDiscountsLoading,
  })
);

const DiscountsPage = () => {
  const {
    manufacturersDiscountsList,
    manufacturersDiscountsCount,
    manufacturersDiscountsCountAll,
    currentUser,
    manufacturersDiscountsLoading,
  } = useSelector(selector);
  const dispatch = useDispatch();

  const isAdmin = useAdmin();
  const repPermissions = useRepsPermissions();

  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [productsList, setProductsList] = useState([]);
  const [manufacturerName, setManufacturerName] = useState("");
  const [checkedDiscounts, setCheckedDiscounts] = useState([]);
  const [limitDiscountsIntoGroups, setLimitDiscountsIntoGroups] = useState([]);

  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  useEffect(() => {
    setCheckedDiscounts([]);
    if (
      limitDiscountsIntoGroups?.length !== manufacturersDiscountsList?.length
    ) {
      setLimitDiscountsIntoGroups(
        manufacturersDiscountsList.map((el) => {
          return {
            id: el?.id,
            limit: SCROLL_LIMIT_DISCOUNT,
            page: 2,
            count: el?.customerCount,
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manufacturersDiscountsList]);

  const handleCheckDiscounts = (discount) => {
    const discountIndex = checkedDiscounts.findIndex(
      (checkedDiscount) => checkedDiscount.id === discount.id
    );
    if (discountIndex > -1) {
      const newDiscounts = [...checkedDiscounts];
      newDiscounts.splice(discountIndex, 1);
      return setCheckedDiscounts([...newDiscounts]);
    }

    setCheckedDiscounts([...checkedDiscounts, discount]);
  };

  const handleCheckWholeGroup = (group) => {
    if (containsEvery(group.discounts, checkedDiscounts)) {
      const newChecked = [...checkedDiscounts];
      group.discounts.forEach((groupDiscount) => {
        const index = newChecked.findIndex(
          (checkedDiscount) => checkedDiscount.id === groupDiscount.id
        );
        newChecked.splice(index, 1);
      });
      return setCheckedDiscounts([...newChecked]);
    }

    const uniqueDiscounts = [
      ...new Set([...checkedDiscounts, ...group.discounts]),
    ];
    setCheckedDiscounts(uniqueDiscounts);
  };

  const handleFetchList = useCallback(() => {
    dispatch(
      getManufacturersDiscountsAction({
        page: page + 1,
        limit: SCROLL_LIMIT,
        discount_limit: SCROLL_LIMIT_DISCOUNT,
      })
    );
    setPage((prev) => prev + 1);
  }, [dispatch, page]);

  const handleFetchDiscounts = useCallback(
    (discountId) => {
      const tempArr = [...limitDiscountsIntoGroups];
      const indexItem = tempArr.findIndex((el) => el.id === discountId);
      let editItem = tempArr.find((el) => el.id === discountId);

      dispatch(
        getManufacturerByIdWithDiscountsAction(discountId, {
          limit: SCROLL_LIMIT_DISCOUNT,
          page: editItem.page || 1,
        })
      );
      editItem.page += 1;
      tempArr.splice(indexItem, 1, editItem);
      setLimitDiscountsIntoGroups(tempArr);
    },
    [dispatch, limitDiscountsIntoGroups]
  );

  const handleEditDiscount = (id) => {
    navigate(id);
  };

  const editType = useSelector(({ confirmDialogs }) => confirmDialogs.editType);

  const handleConfirmCheckedItemsDialog = useCallback(
    (callback) => {
      dispatch(
        openConfirmDialogAction({
          title: "Leaving this page?",
          text: "Your selected list will be discarded.",
          buttons: (
            <>
              <StyledButton
                variant="outlined"
                color="cancel"
                type="submit"
                form={`new-${editType}-form`}
                label="Cancel"
                sx={{ height: "28px" }}
                onClick={() => {
                  dispatch(setConfirmIsOpenAction(false));
                }}
              />
              <Button
                sx={{
                  width: "auto",
                  height: "28px",
                  color: "#FFFFFF",
                  fontSize: "13px",
                  boxShadow: "none",
                }}
                color="confirmDelete"
                onClick={() => {
                  setCheckedDiscounts([]);

                  dispatch(setFormChangedAction(false));
                  dispatch(setEditItemAction(null));

                  dispatch(setConfirmIsOpenAction(false));
                  callback();
                }}
                variant="contained"
              >
                Confirm
              </Button>
            </>
          ),
        })
      );
    },
    [dispatch, editType]
  );

  return (
    <>
      <DiscountsPageTab
        handleConfirmCheckedItemsDialog={() =>
          handleConfirmCheckedItemsDialog(() => navigate("new"))
        }
        repPermissions={repPermissions}
      />
      <NewDiscountsFilter
        checkedCustomers={checkedDiscounts}
        {...{
          handleConfirmCheckedItemsDialog,
          repPermissions,
        }}
      />
      <ProductsMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        products={productsList}
        manufacturerName={manufacturerName}
      />
      {manufacturersDiscountsList?.length ? (
        <Box
          sx={{
            height: "calc(100vh - 206px)",
            overflowY: "hidden",
            position: "relative",
            px: "32px",
            paddingTop: "12px",
            opacity: manufacturersDiscountsLoading ? 0.5 : 1,
            pointerEvents: manufacturersDiscountsLoading && "none",
          }}
        >
          <InfiniteScrollWrapper
            dynamicHeight
            maxHeight="calc(100vh - 220px)"
            id="discounts-scroll-table"
            resetCallback={() => setPage(1)}
            dataLength={manufacturersDiscountsList.length}
            next={handleFetchList}
            loader={
              <CircularProgress sx={{ ml: "50%", mt: "2px" }} size="30px" />
            }
            hasMore={
              manufacturersDiscountsList.length < manufacturersDiscountsCount
            }
          >
            {manufacturersDiscountsList?.map((el) => (
              <AccordionComponent
                sx={{
                  "& .MuiAccordionSummary-content": { width: "100%" },
                  "&.MuiPaper-root.MuiAccordion-root.MuiPaper-root.MuiAccordion-root":
                    { "&:last-of-type": { mb: 0 } },
                }}
                key={el.id}
                item={el}
                expandedDflt={manufacturersDiscountsList?.length <= 1}
              >
                <SummaryComponent item={el}>
                  <SummaryItem
                    item={el}
                    discountCounts={el?.discountCount}
                    checkedDiscounts={checkedDiscounts}
                    groupsList={el?.discounts}
                  />
                </SummaryComponent>
                <DetailsComponent>
                  <HeaderList
                    allDiscountsChecked={containsEvery(
                      el?.discounts,
                      checkedDiscounts
                    )}
                    checkAllDiscounts={handleCheckWholeGroup}
                    group={el}
                  />
                  <Stack
                    sx={{
                      maxHeight: "40vh",
                      overflowY: "hidden",
                      position: "relative",
                      opacity: manufacturersDiscountsLoading ? 0.5 : 1,
                      pointerEvents: manufacturersDiscountsLoading && "none",
                      borderRadius: "4px",
                    }}
                  >
                    <InfiniteScrollWrapper
                      maxHeight="40vh"
                      dataLength={el?.discounts?.length}
                      next={() => handleFetchDiscounts(el?.id)}
                      loader={
                        <CircularProgress
                          sx={{ ml: "50%", mt: "2px" }}
                          size="30px"
                        />
                      }
                      hasMore={el?.discounts?.length < el?.discountCount}
                      id={`${el.id}-scroll-table`}
                    >
                      {el?.discounts.map((discount) => (
                        <ItemList
                          key={discount.id}
                          item={discount}
                          setManufacturerName={setManufacturerName}
                          checkedCustomers={checkedDiscounts}
                          handleCheckCustomer={handleCheckDiscounts}
                          timeZone={currentUser.timeZone}
                          setAnchorElMenu={setAnchorEl}
                          setProductsList={setProductsList}
                          handleEditDiscount={handleEditDiscount}
                          handleConfirmCheckedItemsDialog={
                            handleConfirmCheckedItemsDialog
                          }
                          handleDeleteDiscount={() =>
                            dispatch(
                              openConfirmDialogAction({
                                title: "Delete selected discount(s)?",
                                text: "Please confirm that you would like to delete selected discount(s)? This cannot be restored.",
                                propBtns: {
                                  left: {
                                    sx: {
                                      color: "#6A6A6A",
                                      borderColor: "#D4D4D4",
                                      fontSize: "13px",
                                      height: "28px",
                                    },
                                    onClick: () => {
                                      dispatch(setConfirmIsOpenAction(false));
                                    },
                                    variant: "outlined",
                                    label: "Cancel",
                                  },

                                  right: {
                                    sx: {
                                      color: "#FFFFFF",
                                      fontSize: "13px",
                                      height: "28px",
                                      boxShadow: "none",
                                    },
                                    color: "primary2",
                                    onClick: () =>
                                      dispatch(
                                        deleteDiscountAction(discount.id)
                                      ),
                                    variant: "contained",
                                    label: "Confirm",
                                  },
                                },
                              })
                            )
                          }
                          handleEnableDiscount={() =>
                            dispatch(
                              openConfirmDialogAction({
                                title: "Enable selected discount(s)?",
                                text: "Please confirm that you would like to enable selected discount(s)?",
                                propBtns: {
                                  left: {
                                    sx: {
                                      color: "#6A6A6A",
                                      borderColor: "#D4D4D4",
                                      fontSize: "13px",
                                      height: "28px",
                                    },
                                    onClick: () => {
                                      dispatch(setConfirmIsOpenAction(false));
                                    },
                                    variant: "outlined",
                                    label: "Cancel",
                                  },

                                  right: {
                                    sx: {
                                      color: "#FFFFFF",
                                      fontSize: "13px",
                                      height: "28px",
                                      boxShadow: "none",
                                    },
                                    color: "primary2",
                                    onClick: () =>
                                      dispatch(
                                        updateDiscountAction(discount.id, {
                                          status: "ACTIVE",
                                        })
                                      ),
                                    variant: "contained",
                                    label: "Confirm",
                                  },
                                },
                              })
                            )
                          }
                          handleDisableDiscount={() =>
                            dispatch(
                              openConfirmDialogAction({
                                title: "Disable selected discount(s)?",
                                text: "Please confirm that you would like to disable selected discount(s)?",
                                propBtns: {
                                  left: {
                                    sx: {
                                      color: "#6A6A6A",
                                      borderColor: "#D4D4D4",
                                      fontSize: "13px",
                                      height: "28px",
                                    },
                                    onClick: () => {
                                      dispatch(setConfirmIsOpenAction(false));
                                    },
                                    variant: "outlined",
                                    label: "Cancel",
                                  },

                                  right: {
                                    sx: {
                                      color: "#FFFFFF",
                                      fontSize: "13px",
                                      height: "28px",
                                      boxShadow: "none",
                                    },
                                    color: "primary2",
                                    onClick: () =>
                                      dispatch(
                                        updateDiscountAction(discount.id, {
                                          status: "INACTIVE",
                                        })
                                      ),
                                    variant: "contained",
                                    label: "Confirm",
                                  },
                                },
                              })
                            )
                          }
                          repPermissions={repPermissions}
                        />
                      ))}
                      {!manufacturersDiscountsLoading &&
                        el?.discounts?.length < el?.discountCount && (
                          <InfiniteLoadMoreBtn
                            onClick={() => handleFetchDiscounts(el?.id)}
                            disabled={isAdmin}
                          />
                        )}
                    </InfiniteScrollWrapper>
                  </Stack>
                </DetailsComponent>
              </AccordionComponent>
            ))}
            {!manufacturersDiscountsLoading &&
              manufacturersDiscountsList.length <
                manufacturersDiscountsCount && (
                <InfiniteLoadMoreBtn
                  onClick={handleFetchList}
                  disabled={isAdmin}
                />
              )}
          </InfiniteScrollWrapper>
        </Box>
      ) : (
        <EmptyScreen
          type="discounts"
          onConfirm={() => navigate("/discounts/new")}
          showAction={manufacturersDiscountsCountAll === 0}
          loading={manufacturersDiscountsLoading}
          disabled={!!repPermissions && !repPermissions?.discounts?.create_edit}
          tooltipTitle={ADMIN_ONLY_VIEW_MESSAGE}
        />
      )}
    </>
  );
};

export default DiscountsPage;
