/* eslint-disable react/prop-types */
import React from "react";

export const CrossIcon = ({ fill, opacity = 0.54, size, ...props }) => {
  return (
    <svg
      id="Group_3290"
      data-name="Group 3290"
      xmlns="http://www.w3.org/2000/svg"
      width={size || "24"}
      height={size || "24"}
      viewBox="0 0 24 24"
      fill={fill}
      {...props}
    >
      <rect id="rectangle" width="24" height="24" fill="none" />
      <path
        id="path"
        d="M19,6.4,17.6,5,12,10.6,6.4,5,5,6.4,10.6,12,5,17.6,6.4,19,12,13.4,17.6,19,19,17.6,13.4,12Z"
        transform="translate(0.598)"
        fillRule="evenodd"
        opacity={opacity}
      />
    </svg>
  );
};
