export const cl = {
  inputProps: ({ item, error }) => ({
    sx: {
      opacity: item?.title ? 1 : 0.5,
      fontSize: "13px",
      "& > fieldset": {
        borderColor: "#ffff",
        borderWidth: "0px !important",
      },
      "& input": { pl: "4px" },
      ...(!!error && {
        height: "26px",
        border: "0.5px solid #F2545C !important",
      }),
      "&.Mui-focused": {
        borderWidth: "0px !important",
      },
    },
  }),
};
