import { useCallback, useMemo } from "react";
import { any } from "prop-types";
import { useLocation } from "react-router-dom";
import { PermissionsDenyPage } from "Pages";
import { useRepsPermissions } from "helpers/hooks";

export const PermissionsLayout = ({ children }) => {
  const location = useLocation();
  const repPermissions = useRepsPermissions();

  const checkHasAnyCheckedTrue = useCallback((permissions) => {
    const temp = { ...permissions };

    const values = [];

    Object.keys(temp).forEach((key) => {
      values.push(temp[key]);
    });

    return values.some((item) => !!item);
  }, []);

  const tabs = useMemo(
    () => ({
      "/": { show: true },
      orders: { show: repPermissions?.orders?.view },
      catalog: { show: repPermissions?.catalog?.view },
      customers: { show: repPermissions?.customers?.view },
      representatives: { show: repPermissions?.representatives?.view },
      discounts: { show: repPermissions?.discounts?.view },
      tasks: { show: repPermissions?.tasks?.view },
      routes: { show: repPermissions?.routes?.view },
      reports: { show: repPermissions?.reports?.view },
      settings: {
        show: checkHasAnyCheckedTrue({
          ...repPermissions?.settings,
          ...(repPermissions?.customers?.import && { import: true }),
          ...(repPermissions?.orders?.import && { import: true }),
        }),
      },
    }),
    [
      checkHasAnyCheckedTrue,
      repPermissions?.catalog?.view,
      repPermissions?.customers?.import,
      repPermissions?.customers?.view,
      repPermissions?.discounts?.view,
      repPermissions?.orders?.import,
      repPermissions?.orders?.view,
      repPermissions?.reports?.view,
      repPermissions?.representatives?.view,
      repPermissions?.routes?.view,
      repPermissions?.settings,
      repPermissions?.tasks?.view,
    ]
  );

  const showPermissionsDenyPage = useMemo(() => {
    const path =
      location?.pathname.split("/")[1] === ""
        ? "/"
        : location?.pathname.split("/")[1];

    const showCurrentTab = tabs[path]?.show;

    if (
      repPermissions &&
      typeof showCurrentTab === "boolean" &&
      !showCurrentTab
    )
      return true;

    if (repPermissions && !repPermissions?.hasAnyCheckedTrue) return true;

    return false;
  }, [location?.pathname, repPermissions, tabs]);

  if (showPermissionsDenyPage) return <PermissionsDenyPage />;

  return children;
};

PermissionsLayout.propTypes = {
  children: any,
};
PermissionsLayout.defaultProps = {};
