import React, { useCallback, useEffect, useMemo, useState } from "react";
import { string, object, func } from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  deleteMultipleProductAction,
  deleteSingleProductAction,
  getProductsAction,
  setParentProduct,
  selectedProductsAction,
  selectedChildrensOfProductsAction,
  archiveProductsAction,
  getProductsParamsAction,
} from "redux/actions/products.js";
import {
  productsCountSelector,
  productsCountWithVariationsSelector,
  productsExistSelector,
  productsListSelector,
  productsLoadingSelector,
  productsParamsSelector,
  selectedChildrensOfProductsSelector,
  selectedProductsSelector,
} from "redux/selectors/products.js";
import { Box, Button, Collapse, Stack } from "@mui/material";
import ProductListHeader from "./ProductListHeader";
import ProductListItem from "./ProductListItem";
import {
  openConfirmDialogAction,
  resetConfirmDialogAction,
  setEditTypeAction,
  setFormChangedAction,
} from "redux/actions/confirmDialogs";
import { ADMIN_ONLY_VIEW_MESSAGE, SCROLL_LIMIT } from "utils/constants.js";
import { currentUserSelector } from "redux/selectors/auth.js";
import EmptyScreen from "components/EmptyScreen/EmptyScreen.jsx";
import {
  ChangeCategoriesDialog,
  InfiniteLoadMoreBtn,
  InfiniteScrollWrapper,
  TableHeaderControlPanel,
  TagsDrawer,
} from "components";
import { useProductsActions } from "../useProductsActions";
import ReportDialog from "Pages/ReportsPage/components/ReportDialog/ReportDialog";
import { ChangeAssignmentDialog } from "components/ChangeAssignmentDialog/ChangeAssignmentDialog";
import { TAG_TYPES } from "helpers/useTagsActions";
import { error, success } from "utils/notifications";
import {
  bulkAssignProductTagsService,
  updateMultipleProductService,
  updateSingleProductService,
} from "services/products";
import { getTagsAction } from "redux/actions/tags";
import { containsEvery, containsNone, truncateText } from "helpers/helpers";
import { useReportPage } from "Pages/ReportsPage/ReportsPage.hooks";

const selector = createSelector(
  productsListSelector,
  productsCountSelector,
  currentUserSelector,
  selectedProductsSelector,
  selectedChildrensOfProductsSelector,
  productsExistSelector,
  productsLoadingSelector,
  productsParamsSelector,
  productsCountWithVariationsSelector,
  (
    productsList,
    productsCount,
    currentUser,
    selectedProducts,
    selectedChildrenOfProducts,
    productsExist,
    productsLoading,
    productsParams,
    productsCountWithVariations
  ) => ({
    productsList,
    productsCount,
    currentUser,
    selectedProducts,
    selectedChildrenOfProducts,
    productsExist,
    productsLoading,
    productsParams,
    productsCountWithVariations,
  })
);

const ProductsList = ({
  currentTab,
  repPermissions,
  fetchCountryOfOrigin = () => {},
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    productsList,
    productsCount,
    currentUser,
    selectedProducts,
    selectedChildrenOfProducts,
    productsExist,
    productsLoading,
    productsParams,
    productsCountWithVariations,
  } = useSelector(selector);

  const [page, setPage] = useState(1);
  const [expandParentId, setExpandParentId] = useState(null);
  const [allProductsChecked, setAllProductsChecked] = useState(false);

  const handleSetAllProducts = useCallback(
    (newProductsList) => {
      if (!newProductsList || !newProductsList.length) return;
      setAllProductsChecked(true);
      dispatch(selectedProductsAction(newProductsList));
      dispatch(
        selectedChildrensOfProductsAction([
          ...newProductsList
            .filter((el) => el.isMultiple)
            .map((prod) => [prod, prod.childProducts.map((child) => child.id)]),
        ])
      );
    },
    [dispatch]
  );

  const handleDeleteAllProducts = useCallback(() => {
    setAllProductsChecked(false);
    dispatch(selectedProductsAction([]));
    dispatch(selectedChildrensOfProductsAction([]));
  }, [dispatch]);

  const handleSelectAll = useCallback(() => {
    if (selectedProducts?.length === productsCountWithVariations) {
      handleDeleteAllProducts();
    } else {
      dispatch(
        getProductsAction({ onSuccess: handleSetAllProducts, fetchAll: true })
      );
    }
  }, [
    dispatch,
    handleDeleteAllProducts,
    handleSetAllProducts,
    productsCountWithVariations,
    selectedProducts?.length,
  ]);

  const checkAllProducts = useCallback(() => {
    dispatch(setParentProduct(null));
    if (selectedProducts.length > 0) {
      return handleDeleteAllProducts();
    }
    handleSetAllProducts(productsList);
  }, [
    selectedProducts,
    dispatch,
    handleSetAllProducts,
    productsList,
    handleDeleteAllProducts,
  ]);

  useEffect(() => {
    if (selectedProducts?.length) {
      dispatch(setParentProduct({}));
    }

    if (!selectedProducts?.length) {
      dispatch(setEditTypeAction(""));
      dispatch(setFormChangedAction(false));
    }
  }, [dispatch, selectedProducts]);

  const handleCheckProduct = useCallback(
    (product, parent) => {
      if (!parent?.id) {
        dispatch(setParentProduct({}));
        const productIndex = selectedProducts.findIndex(
          (selectedProduct) => selectedProduct.id === product.id
        );

        if (productIndex > -1) {
          dispatch(
            selectedProductsAction(
              selectedProducts.filter((prod) => prod.id !== product.id)
            )
          );
        } else {
          if (product.childProducts.length) {
            const flatChildrens = selectedChildrenOfProducts
              ?.map((prod) => prod[1])
              ?.flat();

            const hasCheckedEvery = containsEvery(
              product.childProducts,
              flatChildrens
            );

            const hasEmptySelection = containsNone(
              product.childProducts,
              flatChildrens
            );

            if (hasCheckedEvery || hasEmptySelection) {
              dispatch(selectedProductsAction([...selectedProducts, product]));
            }
          } else {
            dispatch(selectedProductsAction([...selectedProducts, product]));
          }
        }
        if (product.isMultiple) {
          setExpandParentId(product?.id);
          const hasParentIndex = selectedChildrenOfProducts
            ?.map((prod) => prod[0])
            ?.findIndex((selectedProduct) => selectedProduct.id === product.id);
          if (hasParentIndex > -1) {
            dispatch(
              selectedChildrensOfProductsAction([
                ...selectedChildrenOfProducts.filter(
                  (prod) =>
                    prod[0].id !==
                    selectedChildrenOfProducts[hasParentIndex][0].id
                ),
              ])
            );
          } else {
            const filteredChildrenIds = product.childProducts
              .filter(
                (childProduct) =>
                  !productsList.some((prod) => prod.id === childProduct.id)
              )
              .map((prod) => prod.id);
            dispatch(
              selectedChildrensOfProductsAction([
                ...selectedChildrenOfProducts,
                [product, filteredChildrenIds],
              ])
            );
          }
        }
      } else {
        dispatch(setParentProduct(parent));
        if (parent.childProducts?.length === 1) {
          dispatch(selectedProductsAction([...selectedProducts, parent]));
        }
        const parentIndex = selectedChildrenOfProducts
          ?.map((prod) => prod[0])
          ?.findIndex((selectedProduct) => selectedProduct.id === parent.id);
        if (parentIndex > -1) {
          const childIndex = selectedChildrenOfProducts[
            parentIndex
          ][1].findIndex((prodId) => prodId === product.id);

          if (childIndex > -1) {
            const tempSelectedChildrensOfProducts = [
              ...selectedChildrenOfProducts,
            ];
            const newChildIdsArray = selectedChildrenOfProducts[
              parentIndex
            ][1].filter((prodId) => prodId !== product.id);

            if (newChildIdsArray.length) {
              tempSelectedChildrensOfProducts[parentIndex][1] = [
                ...newChildIdsArray,
              ];
            } else {
              tempSelectedChildrensOfProducts.splice(parentIndex, 1);
            }

            dispatch(
              selectedProductsAction(
                selectedProducts.filter((prod) => prod.id !== parent.id)
              )
            );

            dispatch(
              selectedChildrensOfProductsAction([
                ...tempSelectedChildrensOfProducts,
              ])
            );
            if (
              selectedProducts?.length === 0 &&
              newChildIdsArray.length === 0
            ) {
              dispatch(setParentProduct({}));
            }
          } else {
            const tempSelectedChildrensOfProducts = [
              ...selectedChildrenOfProducts,
            ];
            const newChildIdsArray = [
              ...selectedChildrenOfProducts[parentIndex][1],
              product.id,
            ];
            tempSelectedChildrensOfProducts[parentIndex][1] = [
              ...newChildIdsArray,
            ];

            dispatch(
              selectedChildrensOfProductsAction([
                ...tempSelectedChildrensOfProducts,
              ])
            );
            const countChildrenOfCurrentParent = parent?.childProducts?.length;

            const countSelectedChildrenOfCurrentParent =
              selectedChildrenOfProducts[parentIndex][1].length;

            if (
              countChildrenOfCurrentParent ===
              countSelectedChildrenOfCurrentParent
            ) {
              dispatch(selectedProductsAction([...selectedProducts, parent]));
            }
          }
        } else {
          dispatch(
            selectedChildrensOfProductsAction([
              ...selectedChildrenOfProducts,
              [parent, [product.id]],
            ])
          );
        }
      }
    },
    [dispatch, productsList, selectedChildrenOfProducts, selectedProducts]
  );

  useEffect(() => {
    if (
      productsList.length &&
      productsList.length === selectedProducts.length
    ) {
      setAllProductsChecked(true);
    } else {
      setAllProductsChecked(false);
    }
  }, [productsList.length, selectedProducts]);

  const handleExpandChildrenList = (id) => {
    if (id === expandParentId) {
      setExpandParentId(null);
    } else {
      setExpandParentId(id);
    }
  };

  const handleDeleteProduct = useCallback(
    (product, parentProduct, lastChild) => {
      const productName = product?.name || parentProduct?.name;
      dispatch(
        openConfirmDialogAction({
          title: `Delete ${truncateText(productName, 26)}?`,
          text: "Are you sure you want to delete the product",
          userName: truncateText(productName, 40),
          text2: !lastChild
            ? "All data will be erased and this can't be undone."
            : "Deletion of the last variation will delete the parent product. This can’t be undone.",
          isIcon: !lastChild,
          buttons: (
            <>
              <Button
                sx={{
                  width: "98px",
                  color: "#6A6A6A",
                  borderColor: "#D4D4D4",
                  fontSize: "13px",
                  height: "28px",
                }}
                onClick={() => {
                  // dispatch(setConfirmIsOpenAction(false));
                  dispatch(resetConfirmDialogAction());
                }}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                sx={{
                  width: "124px",
                  color: "#FFFFFF",
                  fontSize: "13px",
                  height: "28px",
                  boxShadow: "none",
                  px: 0.5,
                }}
                color="confirmDelete"
                onClick={() => {
                  // dispatch(setConfirmIsOpenAction(false));
                  dispatch(resetConfirmDialogAction());
                  // dispatch(setFormChangedAction(false));
                  lastChild
                    ? dispatch(
                        deleteMultipleProductAction({
                          id: parentProduct?.id,
                          onSuccess: fetchCountryOfOrigin,
                        })
                      )
                    : product?.childProducts
                    ? dispatch(
                        deleteMultipleProductAction({
                          id: product?.id,
                          onSuccess: fetchCountryOfOrigin,
                        })
                      )
                    : dispatch(
                        deleteSingleProductAction({
                          id: product?.id,
                          onSuccess: fetchCountryOfOrigin,
                        })
                      );
                }}
                variant="contained"
              >
                Delete Product
              </Button>
            </>
          ),
        })
      );
    },
    [dispatch, fetchCountryOfOrigin]
  );

  const handleOpenConfirmDialog = useCallback(
    (confirmQuery) => {
      dispatch(openConfirmDialogAction(confirmQuery));
    },
    [dispatch]
  );

  const handleOpenProductProfile = useCallback(
    ({ product, isChildren, dirNav }) => {
      navigate(`${product.id}`, {
        state: { product, isChildren, currentTab, dirNav },
      });
    },
    [navigate, currentTab]
  );

  const handleFetchProducts = useCallback(() => {
    dispatch(
      getProductsAction({ query: { limit: SCROLL_LIMIT, page: page + 1 } })
    );
    setPage((prev) => prev + 1);
  }, [dispatch, page]);

  const [changeCategoriesState, setChangeCategoriesState] = useState({
    open: false,
    product: null,
  });

  const handleOpenChangeCategoriesDialog = (product) => {
    setChangeCategoriesState((prev) => ({ ...prev, open: true, product }));
  };

  const handleCloseChangeCategoriesDialog = () => {
    setChangeCategoriesState((prev) => ({
      ...prev,
      open: false,
      product: null,
    }));
  };

  const handleArchive = (product) => {
    const isInactive = product.status === "INACTIVE";
    dispatch(
      openConfirmDialogAction({
        title: `${isInactive ? "Unarchive" : "Archive"} ${product.name}?`,
        text: isInactive
          ? "Unarchiving this product will show it in your sales channels."
          : "Archiving this product will hide it from your sales channels. You’ll find it using the status filter in your product list.",
        buttons: (
          <>
            <Button
              sx={{
                width: "98px",
                color: "#6A6A6A",
                borderColor: "#D4D4D4",
                fontSize: "13px",
                height: "28px",
              }}
              onClick={() => {
                // dispatch(setConfirmIsOpenAction(false));
                dispatch(resetConfirmDialogAction());
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              sx={{
                color: "#FFFFFF",
                fontSize: "13px",
                height: "28px",
                boxShadow: "none",
              }}
              color={isInactive ? "primary" : "confirmDelete"}
              onClick={() => {
                // dispatch(setConfirmIsOpenAction(false));
                // dispatch(setFormChangedAction(false));
                dispatch(resetConfirmDialogAction());
                dispatch(archiveProductsAction([product.id], isInactive));
              }}
              variant="contained"
            >
              {isInactive ? "Unarchive" : "Archive"} Product
            </Button>
          </>
        ),
      })
    );
  };

  const { handleSetFavorite, reportState } = useReportPage();

  const navState = useMemo(() => {
    const data = { isChildren: false };

    if (state?.type === "onboarding") data.type = "onboarding";

    return data;
  }, [state?.type]);

  const lastItemIdWithoutBottomLine = useMemo(() => {
    const lastItem = productsList[productsList?.length - 1];
    if (expandParentId === lastItem?.id) {
      return lastItem?.childProducts?.[lastItem?.childProducts?.length - 1]?.id;
    }
    return lastItem?.id;
  }, [expandParentId, productsList]);

  const quickSort = useMemo(() => {
    return {
      sort_by_name: productsParams.sort_by_name,
      sort_by_category: productsParams.sort_by_category,
      sort_by_manufacturer: productsParams.sort_by_manufacturer,
    };
  }, [
    productsParams.sort_by_name,
    productsParams.sort_by_category,
    productsParams.sort_by_manufacturer,
  ]);

  const handleSetSortBy = (field) => {
    const sortParams = {
      ...productsParams,
      sort_by_name: null,
      sort_by_category: null,
      sort_by_manufacturer: null,
    };

    sortParams[field] = productsParams[field] === "asc" ? "desc" : "asc";

    dispatch(getProductsParamsAction(sortParams));
  };

  const currentSingleProduct = useMemo(
    () =>
      !selectedProducts.length && !!selectedChildrenOfProducts?.length
        ? selectedChildrenOfProducts[0]
        : selectedProducts[0],
    [selectedChildrenOfProducts, selectedProducts]
  );

  const {
    isCustom,
    currentReport,
    handleGetActions,
    handleSelectedChildren,
    isOpenReportDialog,
    handleSingleActions,
    handleGetDropDownActions,
    handleCloseReportDialog,
    handleClickOnEditProduct,
    changeAssignedState,
    handleCloseChangeAssignedDialog,
    assignTagsState,
    setAssignTagsState,
    handleCloseAssignTagsDialog,
    handleSingleProductsDropDownActions,
  } = useProductsActions({
    onDelete: handleDeleteProduct,
    handleArchive,
    handleOpenProductProfile,
    onOpenChangeCategoriesDialog: handleOpenChangeCategoriesDialog,
    selectedProducts,
    repPermissions,
    fetchCountryOfOrigin,
  });

  const ACTIONS_LIST = handleGetActions({
    product: currentSingleProduct,
    isChild: false,
  });

  const DROPDOWN_ACTIONS_LIST = handleGetDropDownActions(currentSingleProduct);

  const selectedChildrenCount = useMemo(
    () =>
      selectedChildrenOfProducts?.reduce(
        (acc, item) => acc + item[1].length,
        0
      ),
    [selectedChildrenOfProducts]
  );

  const handleSelectedParentProducts = useCallback(
    (selectedChildrenOfProducts, selectedProducts) => {
      if (selectedChildrenOfProducts.length) {
        const checkedAllSubs = selectedChildrenOfProducts.filter(
          (item) => item[1].length === item[0].childProducts.length
        );

        return checkedAllSubs.length
          ? selectedProducts.slice(
              0,
              selectedProducts.length - checkedAllSubs.length
            )
          : selectedProducts;
      }

      return selectedProducts;
    },
    []
  );

  const filteredSelectedProducts = useMemo(
    () =>
      handleSelectedParentProducts(
        selectedChildrenOfProducts,
        selectedProducts
      ),
    [handleSelectedParentProducts, selectedChildrenOfProducts, selectedProducts]
  );

  const checkedCount = useMemo(
    () => filteredSelectedProducts.length + selectedChildrenCount,
    [filteredSelectedProducts.length, selectedChildrenCount]
  );

  const selectedChildrenState = handleSelectedChildren();
  const changeCategoryState = useMemo(
    () =>
      (selectedProducts.length === 1 || changeCategoriesState?.product) &&
      !selectedChildrenState.length
        ? [changeCategoriesState?.product]
        : [...selectedProducts, ...selectedChildrenState],
    [changeCategoriesState?.product, selectedChildrenState, selectedProducts]
  );

  const handleUpdateProduct = useCallback(async ({ id, isMultiple, data }) => {
    try {
      const updateAction = isMultiple
        ? updateMultipleProductService
        : updateSingleProductService;

      await updateAction(id, data);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    }
  }, []);

  const [loadingAssignTags, setLoadingAssignTags] = useState(false);

  const transformTagDataForAssignTags = (tags) => tags.map((tag) => ({ tag }));

  const handleSaveAssignTags = useCallback(
    async ({ tags }) => {
      if (loadingAssignTags) return;

      assignTagsState?.isBulk
        ? setAssignTagsState((prev) => ({
            ...prev,
            products: prev.products.map((product) => ({
              ...product,
              tags: transformTagDataForAssignTags(tags),
            })),
          }))
        : setAssignTagsState((prev) => ({
            ...prev,
            product: {
              ...prev.product,
              tags: transformTagDataForAssignTags(tags),
            },
          }));

      try {
        setLoadingAssignTags(true);
        const tagIds = tags
          ?.filter(({ id, newTag }) => id && !newTag)
          ?.map(({ id }) => id);

        const newTags = tags
          ?.filter(({ newTag }) => !!newTag)
          ?.map(({ tag }) => tag);

        if (assignTagsState?.isBulk) {
          const data = {
            productIds: [
              ...new Set([
                ...assignTagsState?.products,
                ...selectedChildrenOfProducts.map((item) => item?.[0] || {}),
              ]),
            ]?.map((product) => product?.id),
            tagIds,
            newTags,
          };

          await bulkAssignProductTagsService(data);
        } else {
          const data = { tagIds, newTags };

          await handleUpdateProduct({
            id: assignTagsState?.product?.id,
            isMultiple: assignTagsState?.product?.isMultiple,
            data,
          });
        }

        success("Tags updated");

        dispatch(getTagsAction({ limit: SCROLL_LIMIT }));

        dispatch(getProductsAction({ query: {} }));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong.");
      } finally {
        handleCloseAssignTagsDialog();
        handleDeleteAllProducts();
        setLoadingAssignTags(false);
      }
    },
    [
      loadingAssignTags,
      assignTagsState?.isBulk,
      assignTagsState?.products,
      assignTagsState?.product?.id,
      assignTagsState?.product?.isMultiple,
      setAssignTagsState,
      dispatch,
      selectedChildrenOfProducts,
      handleUpdateProduct,
      handleCloseAssignTagsDialog,
      handleDeleteAllProducts,
    ]
  );

  const addOnlySharedTags = (items) => {
    if (!items.length) return [];

    const allTags = items.flatMap((item) => item.tags);
    const uniqueTagIds = [...new Set(allTags.map((tag) => tag?.tag?.id))];

    const uniqueTags = uniqueTagIds
      .map((id) => allTags.find((tag) => tag?.tag?.id === id))
      .filter((tag) =>
        items.every((item) =>
          item?.tags?.some((t) => t?.tag?.id === tag?.tag?.id)
        )
      );

    return uniqueTags;
  };

  return (
    <Box height="100%">
      <TagsDrawer
        type={TAG_TYPES.product}
        open={assignTagsState.open}
        handleClose={handleCloseAssignTagsDialog}
        productTags={
          assignTagsState?.isBulk
            ? addOnlySharedTags([
                ...new Set([
                  ...assignTagsState?.products,
                  ...selectedChildrenOfProducts.map((item) => item?.[0] || {}),
                ]),
              ])
            : assignTagsState?.product?.tags?.map((tag) => tag.tag)
        }
        handleSave={({ tags }) => {
          handleSaveAssignTags({ tags });
        }}
        isBulk={assignTagsState?.isBulk}
        setCheckedCustomers={() => {}}
      />

      <ReportDialog
        open={isOpenReportDialog}
        onClose={handleCloseReportDialog}
        report={currentReport}
        isCustom={isCustom}
        handleSetFavorite={handleSetFavorite}
        disabledCustomReportBtn={reportState.loading}
      />
      <ChangeCategoriesDialog
        open={changeCategoriesState?.open}
        onClose={handleCloseChangeCategoriesDialog}
        products={changeCategoryState}
      />
      {changeAssignedState?.open && (
        <ChangeAssignmentDialog
          open={changeAssignedState?.open}
          onClose={handleCloseChangeAssignedDialog}
          products={changeAssignedState.product}
        />
      )}
      {productsList.length ? (
        <>
          <TableHeaderControlPanel
            actionWrapperProps={{
              sx: { padding: "0 19px 0 20px !important" },
            }}
            checkedCount={checkedCount}
            actionsList={ACTIONS_LIST}
            loading={productsLoading}
            dropDownActions={DROPDOWN_ACTIONS_LIST}
            onSelectAll={(value) => handleSelectAll(value)}
            hasCheckedItems={!!checkedCount}
            availableSelectCount={productsCountWithVariations}
            selectName="product"
            onSelectVisible={checkAllProducts}
            cancelSelection={handleDeleteAllProducts}
            headerComponent={
              <ProductListHeader
                allProductsChecked={allProductsChecked}
                checkAllProducts={checkAllProducts}
                quickSort={quickSort}
                handleSetSortBy={handleSetSortBy}
              />
            }
          />

          <Stack
            sx={{
              borderRadius: "0 0 4px 4px",
              maxHeight: "calc(100vh - 318px)",
              height: "100%",
              borderWidth: "0 1px 1px 1px",
              borderStyle: "solid",
              borderColor: "#D5D9D9",
              overflow: "hidden",
            }}
          >
            <InfiniteScrollWrapper
              maxHeight="calc(100vh - 318px)"
              dataLength={productsList.length}
              id="products-scroll-table"
              loading={productsLoading}
              nextFunc={handleFetchProducts}
              resetCallback={() => setPage(1)}
              hasMoreItems={productsList.length < productsCount}
            >
              {productsList?.map((product) => (
                <React.Fragment key={product.id}>
                  <ProductListItem
                    product={product}
                    hasChildren={product?.childProducts?.length}
                    hasChildrenInActiveForCustomers={
                      !!product?.childProducts?.length &&
                      product?.childProducts?.every(
                        (p) => p?.status === "INACTIVE_FOR_CUSTOMERS"
                      )
                    }
                    onExpandChildrenList={handleExpandChildrenList}
                    chosenParentId={expandParentId}
                    selectedProducts={selectedProducts}
                    currentTab={currentTab}
                    selectedChildrenOfProducts={selectedChildrenOfProducts}
                    handleCheckProduct={handleCheckProduct}
                    handleOpenConfirmDialog={handleOpenConfirmDialog}
                    timeZone={currentUser.timeZone}
                    getActionsList={(product, isChild, isTableItem) => {
                      const listFirst = handleSingleActions(
                        product,
                        isChild,
                        isTableItem
                      );
                      const listSecond = handleSingleProductsDropDownActions(
                        product,
                        false
                      );

                      return [...listFirst, ...listSecond];
                    }}
                    setAllProductsChecked={setAllProductsChecked}
                    lastItemIdWithoutBottomLine={lastItemIdWithoutBottomLine}
                    {...{ handleClickOnEditProduct, repPermissions }}
                  />
                  <Collapse in={expandParentId === product.id} timeout={700}>
                    {product?.childProducts?.map((children, indexChild) => (
                      <ProductListItem
                        key={`${children.id}`}
                        product={children}
                        parent={product}
                        currentTab={currentTab}
                        getActionsList={handleSingleActions}
                        isVisible={!!product.id}
                        onExpandChildrenList={handleExpandChildrenList}
                        isChild
                        lastChild={
                          product?.childProducts.length === indexChild + 1
                        }
                        selectedProducts={selectedProducts}
                        selectedChildrenOfProducts={selectedChildrenOfProducts}
                        handleCheckProduct={handleCheckProduct}
                        timeZone={currentUser.timeZone}
                        lastItemIdWithoutBottomLine={
                          lastItemIdWithoutBottomLine
                        }
                        {...{ handleClickOnEditProduct, repPermissions }}
                      />
                    ))}
                  </Collapse>
                </React.Fragment>
              ))}
              {!productsLoading && productsList.length < productsCount && (
                <InfiniteLoadMoreBtn onClick={handleFetchProducts} />
              )}
            </InfiniteScrollWrapper>
          </Stack>
        </>
      ) : (
        <EmptyScreen
          type="products"
          onConfirm={() => {
            navigate("/catalog/new", { state: navState });
          }}
          height="calc(100vh - 470px)"
          showAction={!productsExist}
          loading={productsLoading}
          disabled={!!repPermissions && !repPermissions?.catalog?.create_edit}
          tooltipTitle={ADMIN_ONLY_VIEW_MESSAGE}
        />
      )}
    </Box>
  );
};
ProductsList.propTypes = {
  currentTab: string,
  repPermissions: object,
  fetchCountryOfOrigin: func,
};
export default ProductsList;
