import { useState } from "react";
import { func, object, string } from "prop-types";
import { useWatch } from "react-hook-form";
import { useDispatch } from "react-redux";

import { Box } from "@mui/material";
import { cl } from "./BillingTab.styles";

import Loader from "../../../../../../../components/Loader";
import StyledButton from "../../../../../../../components/StyledButton";
import { PaymentDetails, PaymentPlans, PaymentSearch } from "./components";

import { error, success } from "../../../../../../../utils/notifications";
import { setCardName } from "../../../../../../../helpers/helpers";

import {
  // deleteDistributorCardService,
  deleteDistributorPaymentCardsService,
} from "../../../../../../../services/stripe";
// import { setCurrentUser } from "../../../../../../../redux/actions/auth";
// import { getCurrentUser } from "../../../../../../../services/account";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
  setEditTypeAction,
} from "../../../../../../../redux/actions/confirmDialogs";
import { useBillingActions } from "./useBillingActions";

export const BillingTab = ({
  distributorId,
  control,
  setValue,
  onAddCard,
  onEditCard,
}) => {
  const dispatch = useDispatch();
  const formField = useWatch({ control });

  const { billingData, fetchBillingData } = useBillingActions();

  const [isLoading, setIsLoading] = useState(false);

  const onConfirmDelete = (card) => {
    if (distributorId) {
      setIsLoading(true);
      const cards = formField.creditCards.filter((c) => c.id !== card.id);

      // deleteDistributorCardService(card?.id)
      deleteDistributorPaymentCardsService(card?.id)
        .then(() => {
          setValue("creditCards", cards);
          success("Card deleted");
        })
        // .then(() => {
        //   getCurrentUser()
        //     .then((res) => {
        //       dispatch(setCurrentUser(res));
        //     })
        //     .catch((err) => console.error(err));
        // })
        .catch((err) => {
          error("Something went wrong!");
          // eslint-disable-next-line no-console
          console.error(err);
        })
        .finally(() => {
          setIsLoading(false);
          dispatch(setConfirmIsOpenAction(false));
        });
    }
  };

  const handleConfirmDeleteCard = (card) => {
    dispatch(setEditTypeAction("", false));
    dispatch(
      openConfirmDialogAction({
        title: "Delete payment method?",
        text: (
          <span>
            Are you sure you want to delete
            <span style={cl.confirmText}>
              {" "}
              {setCardName(card?.brand)} ending in{" "}
              {card.number?.slice(-4) || card.last4}
            </span>
            ? Removing this payment method will not cancel any of your open
            orders that use this method.
          </span>
        ),
        buttons: (
          <>
            <StyledButton
              sx={cl.cancelBtn}
              onClick={() => {
                dispatch(setConfirmIsOpenAction(false));
              }}
              fontSize="13px"
              label="Cancel"
              variant="outlined"
            />

            <StyledButton
              sx={cl.confirmBtn}
              color="confirmDelete"
              fontSize="13px"
              onClick={() => onConfirmDelete(card)}
              variant="contained"
              label="Confirm"
            />
          </>
        ),
      })
    );
  };

  return (
    <Box sx={cl.wrapper}>
      <Loader isLoading={isLoading} />
      <PaymentDetails
        control={control}
        onAddCard={onAddCard}
        onEditCard={onEditCard}
        onDeleteCard={handleConfirmDeleteCard}
      />
      <PaymentSearch />
      <PaymentPlans
        loading={billingData?.loading}
        list={billingData?.list}
        count={billingData?.count}
        fetchBillingData={fetchBillingData}
      />
    </Box>
  );
};

BillingTab.propTypes = {
  distributorId: string,
  setValue: func,
  control: object,
  onAddCard: func,
  onEditCard: func,
};
