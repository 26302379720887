import { useCallback, useEffect, useState } from "react";

export const useProducts = ({ switches, filterFields, allowToUse = true }) => {
  const [isOpenedPurchasedPopup, setOpenPurchasedPopup] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const handleApplyPurchasedProducts = (list) => setSelectedProducts(list);

  const handleRemoveProduct = (id) =>
    setSelectedProducts(
      selectedProducts.filter((products) => products.id !== id)
    );

  const handleProductIds = (list) => {
    if (!selectedProducts.length && !list?.length) return [];
    return (list || selectedProducts).map(({ id }) => id);
  };

  const handleCurrentSwitch = useCallback(() => {
    return switches.filter(
      (item) =>
        item?.value === "purchasedProducts" ||
        item?.value === "selectedProducts"
    )?.[0];
  }, [switches]);

  useEffect(() => {
    if (!allowToUse) return;
    const currentSwitch = handleCurrentSwitch();
    if (!currentSwitch?.checked) setSelectedProducts([]);
  }, [allowToUse, handleCurrentSwitch]);

  useEffect(() => {
    if (!allowToUse) return;
    if (
      filterFields?.purchasedProducts &&
      filterFields?.purchasedProducts?.value?.length
    ) {
      setSelectedProducts(filterFields?.purchasedProducts?.value);
    }
  }, [filterFields?.purchasedProducts, allowToUse]);

  useEffect(() => {
    if (!allowToUse) return;
    if (
      filterFields?.selectedProducts &&
      filterFields?.selectedProducts?.value?.length
    ) {
      setSelectedProducts(filterFields?.selectedProducts?.value);
    }
  }, [filterFields?.selectedProducts, allowToUse]);

  return {
    handleApplyPurchasedProducts,
    selectedProducts,
    handleRemoveProduct,
    handleProductIds,
    isOpenedPurchasedPopup,
    setOpenPurchasedPopup,
  };
};
