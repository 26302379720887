import { Box, Stack } from "@mui/material";
import { LargeCheckbox, StyledTooltip } from "components";
import { number, object, oneOf, func } from "prop-types";
import { cl } from "../QuestionItem.styles";
import { Controller } from "react-hook-form";
import { FORM_TYPES } from "Pages/FormViewPage/FormViewPage.constants";
import { useCallback, useMemo } from "react";
import { useAdmin } from "helpers/helpers.js";

const init = [
  {
    Input: LargeCheckbox,
    label: "Required",
    size: 16,
    formSx: cl.checkboxFormStyles,
    labelSx: cl.checkboxLabel,
    name: "required",
    tooltip: {
      title:
        "If checked, the responder must answer this question before submitting the form.",
    },
  },
  {
    Input: LargeCheckbox,
    label: "Autofill",
    size: 16,
    formSx: cl.checkboxFormStyles,
    labelSx: cl.checkboxLabel,
    name: "autofill",
    tooltip: {
      title:
        "If checked, the next time a representative opens this form for the same client, selected fields will be pre-filled with the data submitted on the last visit.",
    },
  },
  {
    Input: LargeCheckbox,
    label: "Allow Gallery",
    size: 16,
    formSx: cl.checkboxFormStyles,
    labelSx: cl.checkboxLabel,
    name: "allowGallery",
    tooltip: {
      title: "Allow Selecting Pictures From The Gallery",
    },
  },
];

export const CheckboxBlock = ({
  questionType,
  orderIndex,
  control,
  formField,
  dirtyFields,
}) => {
  const isAdmin = useAdmin();

  const checkboxData = useMemo(() => {
    if (questionType === FORM_TYPES.PHOTO) return [init[0], init[2]];

    return [init[0], init[1]];
  }, [questionType]);

  const currentQuestion = formField?.questions?.[orderIndex];

  const hasAlreadyAnswered = currentQuestion?._count?.responses > 0;

  const isNameDirty = !!dirtyFields?.questions?.[orderIndex]?.text;

  const disableInput = useCallback(
    ({ name }) => {
      if (name !== "autofill") return false;

      if (hasAlreadyAnswered && isNameDirty) return true;

      return false;
    },
    [hasAlreadyAnswered, isNameDirty]
  );

  return (
    <Stack
      flexWrap="nowrap"
      direction="row"
      width="fit-content"
      mb="auto"
      mt="3px"
      ml="2px"
    >
      {checkboxData.map(({ Input, tooltip, ...fieldData }, index) => {
        return (
          <Controller
            key={index}
            render={({ field }) => {
              return (
                <StyledTooltip
                  arrow
                  placement="left"
                  isDark
                  title={tooltip?.title}
                  disableHoverListener={!tooltip?.title}
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: { offset: [0, -5] },
                      },
                    ],
                  }}
                >
                  <Box component="span">
                    <Input
                      disabled={
                        disableInput({ name: fieldData.name }) || isAdmin
                      }
                      checked={!!field.value}
                      {...fieldData}
                      {...field}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  </Box>
                </StyledTooltip>
              );
            }}
            control={control}
            name={`questions[${orderIndex}].${fieldData.name}`}
          />
        );
      })}
    </Stack>
  );
};

CheckboxBlock.propTypes = {
  questionType: oneOf([...Object.values(FORM_TYPES)]),
  orderIndex: number,
  control: object,
  formField: object,
  setValue: func,
  dirtyFields: object,
};
