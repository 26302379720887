import { useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addTerritoryAction } from "../../redux/actions/territory.js";

import CustomersTab from "./components/CustomersTab";
import CustomerTagsTab from "./components/CustomerTagsTab";
import GalleryTab from "./components/GalleryTab";
import TerritoriesTab from "./components/TerritoriesTab";
import { CustomersSwitcherTab } from "./components";
import { getRepsAction, repsGetParams } from "../../redux/actions/reps.js";
import { useCheckedCustomers } from "./useCheckedCustomers.js";
import { initialState } from "redux/reducers/settings.js";
import { updateDistributorSettingsService } from "services/account.js";
import {
  setFlatColumnLayoutsAction,
  setSortedColumnLayoutsAction,
} from "redux/actions/settings.js";
import { error, success } from "utils/notifications.js";
import { useAdmin } from "helpers/helpers.js";
import ProspectsTab from "./components/ProspectsTab";
import { useRepsPermissions } from "helpers/hooks.js";
import lodash from "lodash";
import deepdash from "deepdash";
import { createSelector } from "reselect";
import { territoryTreeSelector } from "redux/selectors/territory.js";
import { ADMIN_ONLY_VIEW_MESSAGE } from "utils/constants.js";

const _ = deepdash(lodash);

const customerTabs = [
  "Customers",
  "Prospects",
  "Gallery",
  // "Files",
  "Customer Tags",
  "Territories",
];

const selector = createSelector(territoryTreeSelector, (territoriesTree) => ({
  territoriesTree,
}));

const CustomersPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [downloadProgress, setDownloadProgress] = useState({
    download: false,
    progress: 0,
  });

  const repPermissions = useRepsPermissions();

  const galleryPermissions = useMemo(
    () => (repPermissions ? !repPermissions?.customers?.gallery : false),
    [repPermissions]
  );

  const prospectPermissions = useMemo(
    () => (repPermissions ? !repPermissions?.customers?.prospects : false),
    [repPermissions]
  );

  const customerPermissions = useMemo(
    () => (repPermissions ? !repPermissions?.customers?.view : false),
    [repPermissions]
  );

  const tagsPermissions = useMemo(
    () => (repPermissions ? !repPermissions?.customers?.customer_tags : false),
    [repPermissions]
  );

  const territoriesPermissions = useMemo(
    () => (repPermissions ? !repPermissions?.customers?.territories : false),
    [repPermissions]
  );

  const CUSTOMER_TABS = useMemo(
    () => [
      {
        value: "Customers",
        label: "Customers",
        disabled: customerPermissions,
        showTooltip: customerPermissions,
        tooltipText: ADMIN_ONLY_VIEW_MESSAGE,
      },
      {
        value: "Prospects",
        label: "Prospects",
        disabled: prospectPermissions,
        showTooltip: prospectPermissions,
        tooltipText: ADMIN_ONLY_VIEW_MESSAGE,
      },
      {
        value: "Gallery",
        label: "Gallery",
        disabled: galleryPermissions,
        showTooltip: galleryPermissions,
        tooltipText: ADMIN_ONLY_VIEW_MESSAGE,
      },
      {
        value: "Customer Tags",
        label: "Customer Tags",
        disabled: tagsPermissions,
        showTooltip: tagsPermissions,
        tooltipText: ADMIN_ONLY_VIEW_MESSAGE,
      },
      {
        value: "Territories",
        label: "Territories",
        disabled: territoriesPermissions,
        showTooltip: territoriesPermissions,
        tooltipText: ADMIN_ONLY_VIEW_MESSAGE,
      },
    ],
    [
      customerPermissions,
      galleryPermissions,
      prospectPermissions,
      tagsPermissions,
      territoriesPermissions,
    ]
  );

  const isAdmin = useAdmin();

  const currentUser = useSelector(({ auth }) => auth?.currentUser);

  const [currentCustomerTab, setCurrentCustomerTab] = useState(customerTabs[0]);

  useEffect(() => {
    const { tab } = state || {};
    if (!tab || !customerTabs.includes(tab)) return;
    setCurrentCustomerTab(tab);
  }, [state]);

  useEffect(() => {
    navigate("/customers", { state });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSetCurrentTab = (tab) => {
    setCurrentCustomerTab(tab);
  };

  const dispatch = useDispatch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function updateTerritories(data) {
    if (Array.isArray(data)) {
      data.forEach((value) => {
        delete value?._count;
        delete value.representativeCount;
        if (Array.isArray(value.children)) {
          updateTerritories(value.children);
        }
      });
      return data;
    }
  }

  const handleChangedTerritory = useCallback(
    ({ ter, handleCollapse, onSuccess }) => {
      dispatch(
        addTerritoryAction({
          data: updateTerritories(ter),
          handleCollapse,
          onSuccess,
        })
      );
    },
    [dispatch, updateTerritories]
  );

  const { territoriesTree } = useSelector(selector);

  const [territories, setTerritories] = useState(territoriesTree);

  useEffect(() => {
    setTerritories(territoriesTree);
  }, [territoriesTree]);

  const addNamedPathToTerritories = (arr) => {
    return _.eachDeep(arr, (value, key, parentValue, context) => {
      if (_.isObject(context._item.value) && context._item.value.id) {
        context._item.parent.parent?.value
          ? (value.namedPath = [
              ...context._item.parent.parent.value?.namedPath,
              value.name,
            ])
          : (value.namedPath = [value.name]);

        const pathWithoutParent = arr.findIndex((el) => el.id === value.id);
        const pathWithParent = _.findPathDeep(arr, (el) => el.id === value.id, {
          childrenPath: "children",
          pathFormat: "array",
        });
        context._item.parent.parent?.value
          ? (value.path = [
              ...pathWithParent
                .filter((el) => el !== "children")
                .map((el) => {
                  return Number(el);
                }),
            ])
          : (value.path = [pathWithoutParent]);

        let arrayPathes = pathWithParent
          .filter((el) => el !== "children")
          .map((el) => {
            return Number(el);
          });

        if (arrayPathes.length !== 0)
          arrayPathes.splice(arrayPathes.length - 1, 1);

        context._item.parent.parent?.value
          ? (value.parentPath = [...arrayPathes])
          : (value.parentPath = []);
      }
    });
  };

  const addNewTerritory = (name) => {
    // e.preventDefault();
    handleChangedTerritory({
      ter: addNamedPathToTerritories([
        ...territories,
        {
          id: `temp-${Date.now()}`,
          // name: "New territory",
          name: name || "New territory",
          path: territories?.length ? territories?.length : [0],
          parentPath: [],
          children: [],
        },
      ]),
      onSuccess: () => success("Territory created"),
    });
  };

  useEffect(() => {
    dispatch(
      repsGetParams({
        territoryId: "",
        activity: "",
        search: "",
      })
    );
    dispatch(getRepsAction({ active: true }, { isScrolling: false }));
  }, [dispatch]);

  // Customers tab
  const {
    checkedCustomers,
    setCheckedCustomers,
    handleCheckCustomer,
    handleSetCheckedCustomersHeader,
    handleSetCheckAllCustomers,
    handleSetCheckAllCustomersWithGroup,
    customersCount,
    loadingCustomers,
    resetCheckedCustomers,
  } = useCheckedCustomers();

  const renderContent = (currentTab, tabs) => {
    switch (currentTab) {
      case tabs[0]:
        return (
          <CustomersTab
            {...{
              checkedCustomers,
              setCheckedCustomers,
              handleCheckCustomer,
              handleSetCheckedCustomersHeader,
              handleSetCheckAllCustomers,
              handleSetCheckAllCustomersWithGroup,
              customersCount,
              loadingCustomers,
              resetCheckedCustomers,
            }}
          />
        );
      case tabs[1]:
        return <ProspectsTab />;
      case tabs[2]:
        return <GalleryTab setDownloadProgress={setDownloadProgress} />;
      // case tabs[2]:
      //   return <FilesTab />;
      case tabs[3]:
        return <CustomerTagsTab />;
      case tabs[4]:
        return (
          <TerritoriesTab
            {...{
              handleChangedTerritory,
              territories,
              addNamedPathToTerritories,
              addNewTerritory,
            }}
          />
        );

      default:
        return (
          <CustomersTab
            {...{
              checkedCustomers,
              setCheckedCustomers,
              handleCheckCustomer,
              handleSetCheckedCustomersHeader,
              handleSetCheckAllCustomers,
              handleSetCheckAllCustomersWithGroup,
              customersCount,
              loadingCustomers,
              resetCheckedCustomers,
            }}
          />
        );
    }
  };

  const handleResetLayout = useCallback(async () => {
    const { flat, sorted } = initialState.column_layouts;

    try {
      const res = await updateDistributorSettingsService({
        customerLayoutSettings: { flat, sorted },
      });
      dispatch(setFlatColumnLayoutsAction(res?.customerLayoutSettings?.flat));
      dispatch(
        setSortedColumnLayoutsAction(res?.customerLayoutSettings?.sorted)
      );
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    }
  }, [dispatch]);

  // It should reset the columns to the initial state if their counts don't match
  const savedColumnsCount = {
    flat:
      (currentUser?.orderLayoutSettings?.flat?.disable?.length || 0) +
      (currentUser?.orderLayoutSettings?.flat?.main?.length || 0),
    sorted:
      (currentUser?.orderLayoutSettings?.sorted?.disable?.length || 0) +
      (currentUser?.orderLayoutSettings?.sorted?.main?.length || 0),
  };
  const initColumnsCount = {
    flat:
      initialState.column_layouts.flat.disable?.length +
      initialState.column_layouts.flat.main?.length,
    sorted:
      initialState.column_layouts.sorted.disable?.length +
      initialState.column_layouts.sorted.main?.length,
  };

  useEffect(() => {
    if (
      savedColumnsCount?.flat !== initColumnsCount?.flat ||
      savedColumnsCount?.sorted !== initColumnsCount?.sorted
    ) {
      if (repPermissions || isAdmin) return;
      handleResetLayout();
    }
  }, [
    repPermissions,
    handleResetLayout,
    initColumnsCount?.flat,
    initColumnsCount?.sorted,
    isAdmin,
    savedColumnsCount?.flat,
    savedColumnsCount?.sorted,
  ]);

  return (
    <>
      <CustomersSwitcherTab
        tabs={CUSTOMER_TABS}
        currentTab={currentCustomerTab}
        handleSetCurrentTab={handleSetCurrentTab}
        resetCheckedCustomers={resetCheckedCustomers}
        repPermissions={repPermissions}
        downloadProgress={downloadProgress}
        setDownloadProgress={setDownloadProgress}
        addNewTerritory={addNewTerritory}
      />
      {renderContent(currentCustomerTab, customerTabs)}
      <Outlet />
    </>
  );
};

export default CustomersPage;
