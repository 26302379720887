/* eslint-disable react/prop-types */
import * as React from "react";
export const CatalogIcon = (props) => (
  <svg
    width={18}
    height={16}
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m7.555 1.724.06.027c.569.25.848.371 1.13.408q.255.033.51 0c.282-.037.561-.159 1.13-.408l.06-.027c.865-.38 1.315-.575 1.775-.705q.475-.135.963-.202c.475-.066.96-.067 1.892-.067h.097c.364 0 .644 0 .867.02.231.021.41.063.565.151.199.113.363.275.476.469l.648-.378-.649.378c.088.15.13.323.15.549.02.218.021.494.021.853v9.038c0 .32 0 .567-.017.763-.016.202-.05.358-.12.495l.669.34-.669-.34c-.123.242-.323.44-.572.565-.143.07-.304.104-.511.12l.06.748-.06-.747c-.2.016-.45.016-.775.016h-.03c-.793 0-1.184 0-1.57.043q-.528.06-1.043.198c-.374.102-.738.245-1.475.536l-.59.233.276.698-.275-.698-.116.046c-.669.265-1.047.404-1.432.404s-.763-.14-1.432-.404l-.116-.046-.275.698.275-.698-.59-.233c-.736-.29-1.1-.434-1.474-.536a7 7 0 0 0-1.043-.198c-.385-.043-.777-.043-1.57-.043h-.03c-.325 0-.575 0-.775-.016l-.06.747.06-.747a1.4 1.4 0 0 1-.51-.121 1.3 1.3 0 0 1-.573-.565 1.3 1.3 0 0 1-.12-.495 10 10 0 0 1-.017-.763V2.792c0-.36 0-.635.02-.853.021-.226.063-.4.15-.549.113-.194.277-.356.476-.469.155-.088.334-.13.565-.15.223-.02.503-.021.867-.021h.098c.932 0 1.416.001 1.89.067q.49.067.964.202c.46.13.91.326 1.775.705Z"
      strokeWidth={1.5}
    />
    <path d="M9 15V2" strokeWidth={1.5} />
  </svg>
);
