import React, { useEffect, useMemo, useRef, useState } from "react";
import { bool, func, object, any, string } from "prop-types";
import { useSelector } from "react-redux";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  Box,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  LargeCheckbox,
  StyledAvatar,
  StyledButton,
  StyledTextField,
  StyledTooltip,
} from "components";
import { CrossIcon } from "components/Icons";
import useStyles, { cl } from "./styles";
import {
  matchEmail,
  normalizeUppercaseString,
  photoUrl,
} from "helpers/helpers";
import { useDebounce } from "helpers/hooks";
import { getUserRecipients } from "services/account";
import { validationSchema } from "./CustomerEmailDialog.validations";
import { defaultValues } from "./CustomerEmailDialog.constants";
import { EmailToBlock } from "./EmailToBlock";
import { bytesToSize } from "firebase/Chat/helpers";

const CustomerEmailDialog = ({
  isOpen,
  handleClose,
  order,
  handleSendEmail,
  isEdit,
  customer,
  loading,
}) => {
  const distributor = useSelector(({ auth }) => auth.currentUser);
  const repsList = useSelector(({ reps }) => reps.reps);
  const rep = repsList?.find((r) => r?.id === order?.salesId);

  const { type, createOrderType } = order || {};

  const hasCreatedOrderThirdParty = useMemo(() => {
    if (createOrderType === "THIRD_PARTY" && order?.salesId) {
      return { ...rep, role: "THIRD_PARTY" };
    }

    if (type === "THIRD_PARTY") {
      return { ...order?.salesDuplicate, role: "THIRD_PARTY" };
    }

    return false;
  }, [order, rep, type, createOrderType]);

  const inputRef = useRef();
  const [attachments, setAttachments] = useState([]);

  const attachmentsSizeExceeded = useMemo(
    () =>
      attachments.reduce(
        (prevSum, attachment) => prevSum + attachment.size,
        0
      ) > 10485760, //10 MB limit in bytes
    [attachments]
  );

  const isPreviewValid = useMemo(() => {
    return (
      order?.paymentStatus !== "PAID" &&
      type !== "THIRD_PARTY" &&
      distributor?.stripeAccountVerificationStatus === "VERIFIED"
    );
  }, [
    order?.paymentStatus,
    type,
    distributor?.stripeAccountVerificationStatus,
  ]);

  const { control, handleSubmit, setValue, reset, setError, clearErrors } =
    useForm({
      mode: "onChange",
      defaultValues: {
        ...defaultValues,
        businessEmail: customer?.email || defaultValues?.businessEmail,
        contacts: customer
          ? [
              {
                customerId: customer?.id,
                defaultContact: true,
                email: customer?.email,
                id: customer?.customId?.customId,
                name: customer?.name,
                phone: customer?.phone,
                role: "Customer",
              },
            ]
          : defaultValues?.contacts,
        // message: letterText(customerName, distributor?.name, isEdit),
      },
      resolver: yupResolver(validationSchema({ isCustomerEmail: !!customer })),
    });
  const classes = useStyles(isPreviewValid);

  const formField = useWatch({ control });

  useEffect(() => {
    if (!formField.others.some((o) => o.email === distributor.baseUser.email))
      setValue("sendMe", false);
  }, [formField.others, setValue, distributor.baseUser.email]);

  const [recipientsSearch, setRecipientsSearch] = useState("");
  const [contactsSearch, setContactsSearch] = useState("");
  const [recipientsLoading, setRecipientsLoading] = useState(false);

  const [CCList, setCCList] = useState([]);
  const [contactsList, setContactsList] = useState([]);

  const recipientsSearchDebounced = useDebounce(recipientsSearch, 500);
  const contactsSearchDebounced = useDebounce(contactsSearch, 500);

  useEffect(() => setContactsSearch(""), [formField?.contacts]);
  useEffect(() => setRecipientsSearch(""), [formField?.others]);

  useEffect(() => {
    if (!isOpen) {
      setRecipientsSearch("");
      setContactsSearch("");
      setAttachments([]);
      reset({
        ...defaultValues,
        // message: letterText(order?.customer?.name, distributor?.name, isEdit),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.id, isOpen]);

  const handleDeleteContact = (id) => {
    setValue(
      "contacts",
      formField.contacts.filter((c) => c.id !== id)
    );
  };

  useEffect(() => {
    if (!isOpen) return;

    if (customer) {
      reset({
        ...defaultValues,
        // subject: setSubject,
        businessEmail: customer?.email || defaultValues?.businessEmail,
        contacts: customer
          ? [
              {
                customerId: customer?.id,
                defaultContact: true,
                email: customer?.email,
                id: customer?.customId?.customId,
                name: customer?.name,
                phone: customer?.phone,
                role: "Customer",
              },
            ]
          : defaultValues?.contacts,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.customer?.id, isOpen, customer]);

  useEffect(() => {
    const trimmedString = recipientsSearchDebounced.trim().trimStart();
    if (trimmedString) {
      setRecipientsLoading(true);
      return getUserRecipients({ search: trimmedString })
        .then((res) => {
          setRecipientsLoading(false);
          setCCList(res);
        })
        .catch(() => setRecipientsLoading(false));
    }
    setCCList(distributor?.contacts || []);
  }, [distributor, recipientsSearchDebounced]);

  useEffect(() => {
    const customerOfOrder = order?.customer;

    const currentCustomer = customer || customerOfOrder;

    if (hasCreatedOrderThirdParty && Array.isArray(currentCustomer?.contacts)) {
      return setContactsList([
        hasCreatedOrderThirdParty,
        ...currentCustomer?.contacts?.filter((c) => c?.email),
      ]);
    }
    if (!currentCustomer) return;

    const list = [
      ...currentCustomer?.contacts?.filter((c) => c.email),
      ...repsList?.filter((c) => c.email || c?.baseUser?.email),
    ];
    if (currentCustomer?.email)
      list.push({ ...currentCustomer, role: "Business", isCustomer: true });
    if (contactsSearchDebounced) {
      return setContactsList(
        list?.filter(
          (contact) =>
            contact?.email &&
            contact?.email
              .toLowerCase()
              .includes(contactsSearchDebounced.toLowerCase())
        )
      );
    }
    return setContactsList([...list]);
  }, [
    contactsSearchDebounced,
    customer,
    hasCreatedOrderThirdParty,
    order,
    repsList,
  ]);

  const onSubmit = (data) => {
    if (recipientsSearch.trim()) {
      return setError("cc", {
        message: "Please add a valid email address.",
      });
    }

    const { message, subject, sendMe, files } = data || {};
    const updatedMessage = message.replace(/\n/g, "<br>");
    const otherEmails = data.others
      .map((o) => o.email.trim())
      .filter((email) => email !== distributor.baseUser.email);

    const recipientEmails = data.contacts
      .filter(
        (c) =>
          typeof c === "object" &&
          ((c.email && c.role === "THIRD_PARTY") ||
            c.id ||
            c.customerId ||
            (c.id && !(c.isCustomer || c.role === "Customer")))
      )
      .map((c) => c.email || (c.baseUser && c.baseUser.email))
      .filter((email) => typeof email === "string");

    const formattedData = {
      recipientEmails,
      otherEmails,
      // businessEmail,
      message: updatedMessage,
      subject,
      sendMe,
    };

    if (customer?.id) {
      const params = {
        customer_id: customer?.id,
      };

      const uploadFormData = new FormData();

      Object.keys(formattedData).forEach((key) =>
        uploadFormData.append(key, JSON.stringify(formattedData[key]))
      );

      const filesArray = Object.keys(files).map((f) => files[f]);

      filesArray.forEach((file) => uploadFormData.append("file[]", file));

      handleSendEmail({ params, data: uploadFormData });
    } else {
      handleSendEmail(formattedData);
    }
  };

  const isDisabled = useMemo(() => {
    const noContactsWithEmail = !formField?.contacts?.filter(
      ({ email, baseUser }) => !!email || !!baseUser?.email
    )?.length;

    if (
      noContactsWithEmail ||
      (customer && !formField?.message) ||
      attachmentsSizeExceeded ||
      loading ||
      recipientsSearch.trim() !== ""
    )
      return true;

    return formField?.contacts?.length === 0 || formField?.subject === "";
  }, [
    attachmentsSizeExceeded,
    customer,
    formField?.contacts,
    formField?.message,
    formField?.subject,
    loading,
    recipientsSearch,
  ]);

  const getCreateButtonLabel = () => {
    if (loading) return "Loading...";
    if (order?.id || customer) return "Send Email";
  };

  const processingIntoChip = () => {
    const newEmail = recipientsSearch.trim().trimStart();
    const newEmailC = contactsSearch.trim().trimStart();

    const alreadyAddedRecipients =
      !!formField.others.find((e) => e.email === newEmail) ||
      !!formField.contacts.find((e) => e.email === newEmail);

    const alreadyAddedContacts =
      !!formField.others.find((e) => e.email === newEmailC) ||
      !!formField.contacts.find((e) => e.email === newEmailC);

    if (!alreadyAddedRecipients && newEmail && matchEmail(newEmail)) {
      setRecipientsSearch("");
      setValue("others", [
        ...formField.others,
        {
          id: newEmail,
          email: newEmail,
        },
      ]);
      clearErrors("cc");
    }

    if (!alreadyAddedContacts && newEmailC && matchEmail(newEmailC)) {
      setContactsSearch("");
      setValue("contacts", [
        ...formField.contacts,
        {
          id: newEmailC,
          email: newEmailC,
        },
      ]);
    }
  };
  const handlePressEnter = (e) => {
    const val = e.target.value;
    if (e.keyCode === 13 && val !== "") {
      processingIntoChip();
    }
  };

  const handleRemoveFile = (fileName) => {
    setAttachments((prev) => [...prev.filter((f) => f.name !== fileName)]);
    const files = formField?.files;
    delete files[fileName];
    setValue("files", files);
  };

  const onAttach = (e) => {
    const files = Array.prototype.slice.call(e.target.files);
    const objFiles = [];
    const filesList = formField.files;
    files.forEach((f) => (filesList[f.name] = f));
    files.forEach((file) => {
      objFiles.push({
        size: file.size,
        name: file.name,
        type: file.type,
      });
    });
    setAttachments([...attachments, ...objFiles]);
    setValue("files", filesList);
  };

  const distribIsAlreadyAdded = useMemo(
    () =>
      !!formField?.others?.find(
        (e) => e?.email === distributor?.baseUser?.email
      ),
    [distributor?.baseUser?.email, formField?.others]
  );

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{ className: classes.dialog }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Box sx={{ display: "inline-flex", gap: "15px" }}>
          <Typography
            // borderRight="1px solid #d5d9d9"
            // pr="15px"
            className={classes.titleText}
          >
            Email Customer
          </Typography>
        </Box>
        <IconButton onClick={handleClose}>
          <CrossIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          width: "100%",
          minWidth: customer ? "auto" : "631px",
          padding: "20px 41px 0px 42px!important",
          display: "flex",
          overflow: "auto",
        }}
      >
        <form
          id="order-email-form"
          onSubmit={handleSubmit(onSubmit)}
          className={classes.formBox}
        >
          <Box>
            <EmailToBlock
              customerName={
                order?.customer?.displayName ||
                order?.customer?.name ||
                customer?.displayName ||
                customer?.name
              }
              handlePressEnter={handlePressEnter}
              processingIntoChip={processingIntoChip}
              formattedBillingAddress={
                order?.customer?.billingAddress?.formatted_address ||
                customer?.billingAddress?.formatted_address
              }
              {...{
                control,
                setValue,
                handleDeleteContact,
                contactsList,
                recipientsLoading,
                contactsSearch,
                setContactsSearch,
              }}
            />

            <Box className={classes.rowWrap} mt="14px" alignItems="center">
              <Box flex="0 0 15%">
                <Typography className={classes.rowTitle}>CC:</Typography>
              </Box>
              <Box flex="0 0 80%" maxWidth="80%" position="relative">
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      multiple
                      freeSolo
                      disableCloseOnSelect
                      loading={recipientsLoading}
                      PaperComponent={(props) => (
                        <Box {...props} className={classes.recipientsTable} />
                      )}
                      size="small"
                      isOptionEqualToValue={(option, value) =>
                        option.email === value.email
                      }
                      sx={{
                        "& .MuiAutocomplete-paper": {
                          boxShadow: "none",
                        },
                      }}
                      className={classes.textfield}
                      getOptionLabel={(option) =>
                        option.email || option?.baseUser?.email
                      }
                      renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            value: recipientsSearch,
                            autocomplete: "new-password",
                            name: Math.random().toString(36).substring(2),
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: <></>,
                          }}
                          error={error?.message || ""}
                          noErrorMessage
                          placeholder="Emails"
                          className={classes.textfield}
                          value={recipientsSearch}
                          inputSx={{ color: "#707070" }}
                          onChange={(e) => setRecipientsSearch(e.target.value)}
                          endIcon={
                            recipientsLoading && <CircularProgress size={20} />
                          }
                          onKeyDown={handlePressEnter}
                          onBlur={processingIntoChip}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              height: "fit-content",
                              p: "5px!important",
                              fontSize: "12px",
                            },
                          }}
                        />
                      )}
                      ListboxProps={{
                        sx: {
                          p: 0,
                          maxHeight: "178px",
                          boxShadow: "none",
                          overflow: "overlay",
                        },
                      }}
                      renderOption={(props, recipient, { selected }) => (
                        <Grid
                          key={recipient?.id}
                          container
                          columns={10}
                          sx={{
                            backgroundColor:
                              selected && "rgba(66, 165, 127, 0.1)",
                          }}
                          {...props}
                          className={classes.recipientItem}
                        >
                          <Grid item xs={7}>
                            <Box display="flex" gap="11px" alignItems="center">
                              <StyledAvatar
                                src={photoUrl(
                                  recipient?.profilePhoto?.fileName
                                )}
                                name={recipient?.name}
                                sx={{
                                  height: "29px",
                                  width: "29px",
                                  backgroundColor: "#F5F5F5",
                                  color: "#7A7A7A",
                                  fontWeight: "700",
                                }}
                                stringStyle={{
                                  fontSize: "13px",
                                }}
                              />
                              <Box>
                                <Typography>{recipient?.name}</Typography>
                                <Typography fontWeight="400">
                                  {recipient?.email}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item>
                            <Typography>
                              {normalizeUppercaseString(
                                recipient?.role === "THIRD_PARTY"
                                  ? "3rd party"
                                  : recipient?.role
                              ) || "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                      options={CCList}
                      renderTags={(value, getTagProps) => (
                        <Box className={classes.recipientChipWrapper}>
                          {value
                            .filter((val) => !!val?.email)
                            .map((cc, index) => (
                              <Chip
                                key={index}
                                sx={{
                                  borderRadius: "10px",
                                  border: "1px solid #D4D4D4",
                                  backgroundColor: "#F8F8FA",
                                  height: "19px",
                                  maxWidth: "90%",
                                }}
                                size="small"
                                label={
                                  <Typography
                                    sx={{
                                      fontSize: "12px",
                                      lineHeight: 1.5,
                                    }}
                                    color="groundLighter.main"
                                    noWrap
                                  >
                                    {cc?.email}
                                  </Typography>
                                }
                                deleteIcon={<CloseIcon />}
                                onMouseDown={(e) => e.stopPropagation()}
                                {...getTagProps({ index })}
                              />
                            ))}
                        </Box>
                      )}
                      {...field}
                      onChange={(event, newValue) => {
                        if (event.keyCode !== 8) {
                          setValue(field.name, newValue);
                        }
                      }}
                    />
                  )}
                  name="others"
                  control={control}
                />
              </Box>
            </Box>
            <Box className={classes.rowWrap} mt="23px" alignItems="center">
              <Box flex="0 0 15%">
                <Typography className={classes.rowTitle}>Subject:</Typography>
              </Box>
              <Box flex="0 0 80%">
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <StyledTextField
                      placeholder="Subject"
                      className={classes.textfield}
                      inputSx={{ color: "#707070" }}
                      disabled={!order?.createdAt && !isEdit ? false : true}
                      error={error?.message || ""}
                      {...field}
                    />
                  )}
                  name="subject"
                  control={control}
                />
              </Box>
            </Box>
            <Box className={classes.rowWrap} mt="25px">
              <Box flex="0 0 15%" mt="4px">
                <Typography className={classes.rowTitle}>Message:</Typography>
              </Box>
              <Box flex="0 0 80%">
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <StyledTextField
                      placeholder={
                        customer
                          ? "Example of the message"
                          : "Example of the message (optional)"
                      }
                      multiline
                      maxRows={14}
                      minRows={4}
                      size="small"
                      inputSx={cl.messageField}
                      className={classes.textfield}
                      error={error?.message || ""}
                      {...field}
                    />
                  )}
                  name="message"
                  control={control}
                />
              </Box>
            </Box>
            <Box
              sx={{
                mt: "10px",
                display: "flex",
                alignItems: "baseline",
              }}
            >
              <Controller
                render={({ field }) => (
                  <LargeCheckbox
                    label="Send me a copy of this email"
                    formSx={{ ml: "15%" }}
                    size={16}
                    labelSx={{ fontSize: "11px", color: "#363531" }}
                    checked={!!field.value || distribIsAlreadyAdded}
                    {...field}
                    onChange={() => {
                      setValue(field.name, !field.value);
                      if (!field.value && !distribIsAlreadyAdded)
                        return setValue("others", [
                          ...formField.others,
                          { email: distributor.baseUser.email },
                        ]);
                      setValue(
                        "others",
                        formField.others.filter(
                          (o) => o.email !== distributor.baseUser.email
                        )
                      );
                    }}
                  />
                )}
                name="sendMe"
                control={control}
              />
              <StyledTooltip
                placement="top"
                title={
                  attachmentsSizeExceeded ? "10 MB max. can be attached" : ""
                }
              >
                <Box sx={{ ml: "75px" }}>
                  {customer ? (
                    <>
                      <Button
                        variant="outlined"
                        startIcon={
                          <FileUploadIcon style={{ fontSize: "14px" }} />
                        }
                        color="cancel"
                        onClick={() => {
                          inputRef.current.click();
                        }}
                        sx={{ borderColor: "#d5d9d9", height: "34px" }}
                      >
                        Attach File
                        <input
                          ref={inputRef}
                          type="file"
                          hidden
                          onChange={onAttach}
                          accept={"*"}
                          multiple
                        />
                      </Button>
                      <Typography fontSize="12px" color="#A8A8A8">
                        Max. 10 MB
                      </Typography>
                    </>
                  ) : null}
                </Box>
              </StyledTooltip>
            </Box>

            {customer ? (
              <Box maxWidth="468px">
                <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                    width: "100%",
                    maxWidth: "644px",
                    overflow: "auto",
                    paddingTop: "5px",
                    minHeight: "34px",
                    "&::-webkit-scrollbar": {
                      height: "5px",
                    },
                  }}
                >
                  {attachments.map((attachment) => (
                    <Box
                      key={attachment.name}
                      sx={{
                        display: "flex",
                        backgroundColor: "#eeeeee",
                        justifyContent: "space-between",
                        padding: "3px 7px 4px 10px",
                        borderRadius: "4px",
                        maxWidth: "198px",
                      }}
                    >
                      <Box sx={{ display: "flex", gap: "6px", width: "85%" }}>
                        <Typography
                          fontSize="14px"
                          noWrap
                          color="primary"
                          fontWeight={600}
                          maxWidth="65%"
                        >
                          {attachment.name}
                        </Typography>
                        <Typography
                          fontSize="14px"
                          fontWeight={600}
                          whiteSpace="nowrap"
                          color="#707070"
                        >
                          ({bytesToSize(attachment.size)})
                        </Typography>
                      </Box>
                      <IconButton
                        sx={{ p: "2px" }}
                        onClick={() => handleRemoveFile(attachment.name)}
                      >
                        <CrossIcon size="18" />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              </Box>
            ) : null}
          </Box>
        </form>
      </DialogContent>
      <DialogActions
        sx={{ padding: customer ? "15px 64px 18px" : "15px 40px 18px" }}
      >
        <StyledButton
          sx={{ height: "34px" }}
          label="Cancel"
          fontSize="13px"
          color="cancel"
          onClick={handleClose}
        />
        <StyledButton
          disabled={isDisabled}
          sx={{ height: "34px" }}
          fontSize="15px"
          type="submit"
          form="order-email-form"
          label={getCreateButtonLabel()}
          variant="contained"
        />
      </DialogActions>
    </Dialog>
  );
};

CustomerEmailDialog.propTypes = {
  isOpen: bool,
  handleClose: func,
  order: object,
  handleSendEmail: func,
  isEdit: bool,
  isDuplicate: bool,
  totalDiscount: any,
  deliveryFee: string,
  grandTotal: string,
  calculatedTotal: string,
  customer: object,
  loading: bool,
  selectedContactEmail: any,
};

export default CustomerEmailDialog;
