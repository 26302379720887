import React from "react";
import { func, string } from "prop-types";
import { Box, IconButton, Typography } from "@mui/material";
import { OutlinedPlusIcon } from "components/Icons";
import { useAdmin } from "helpers/helpers.js";

export const AddContentButton = ({ label, onClick = () => {} }) => {
  const isAdmin = useAdmin();

  return (
    <Box
      onClick={!isAdmin && onClick}
      sx={{
        cursor: isAdmin ? null : "pointer",
        display: "flex",
        alignItems: "center",
        gap: "8px",
        width: "fit-content",
      }}
    >
      <IconButton
        disableRipple
        sx={{
          p: 0,
        }}
        disabled={isAdmin}
      >
        <OutlinedPlusIcon
          size={21}
          circleColor="#707070"
          plusColor={isAdmin ? "#BCBCBC" : "#47A06D"}
        />
      </IconButton>

      <Typography color="#5F6267" fontSize={13}>
        {label}
      </Typography>
    </Box>
  );
};

AddContentButton.propTypes = {
  label: string,
  onClick: func,
};
