export const getFormattedAddress = (address) => {
  const { street, state, city, zip, appartement } = address || {};

  const formattedStreet = street ? `${street}, ` : "";
  const formattedApartment = appartement ? `${appartement}, ` : "";
  const formattedState = state ? `${state}, ` : "";

  return `${formattedStreet}${city} ${formattedApartment}${formattedState}${zip}`;
};

export const getAddressComponents = (location) => {
  const address_components = location.address_components;

  const streetName = address_components.find((comp) =>
    comp.types.includes("route")
  )?.short_name;
  const appartementName =
    address_components.find((comp) => comp.types.includes("subpremise"))
      ?.short_name || "";
  const streetNum =
    address_components.find((comp) => comp.types.includes("street_number"))
      ?.short_name || "";
  const city =
    address_components.find((comp) => comp.types.includes("locality"))
      ?.long_name ||
    address_components.find((comp) =>
      comp.types.includes("administrative_area_level_1")
    )?.long_name ||
    "";

  const state =
    address_components.find((comp) =>
      comp.types.includes("administrative_area_level_1")
    )?.short_name || "";

  const zip =
    address_components.find((comp) => comp.types.includes("postal_code"))
      ?.short_name || "";

  const formattedStreet = streetName ? `${streetNum} ${streetName}` : "";
  const formattedApartment = appartementName ? appartementName : "";

  return {
    street: streetName ? formattedStreet : "",
    city,
    state,
    zip,
    appartement: formattedApartment,
    formatted_address: getFormattedAddress({
      street: formattedStreet,
      city,
      state,
      zip,
      appartement: formattedApartment,
    }),
  };
};
