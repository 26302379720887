import { array, bool, func, number, object } from "prop-types";
import { Box } from "@mui/material";
import { MapChipCarousel } from "components";

const ROUTES_TITLE_WIDTH = 42;

export const RoutesItem = ({
  routes,
  headerBlockWidth,
  classes,
  repPermissions,
  handleOpenRoutesDrawer,
  customer,
  routeItemsRef,
  tooltipIsOpen,
}) => {
  const isRepHasPermission = repPermissions?.routes?.create_edit;
  return (
    <Box
      sx={{
        maxWidth: `${headerBlockWidth}px`,
        width: "100%",
        height: "28px",
      }}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box
        className={classes.routesTitle}
        sx={{ cursor: isRepHasPermission ? "pointer" : "default" }}
        onClick={() => {
          if (repPermissions) {
            if (isRepHasPermission) {
              handleOpenRoutesDrawer(customer);
              return;
            }
            return;
          }
          handleOpenRoutesDrawer(customer);
        }}
      >
        Routes
      </Box>

      <Box width="16px" />

      {headerBlockWidth && routes.length ? (
        <Box
          sx={{
            width: `calc(100% - ${ROUTES_TITLE_WIDTH}px - 24px)`,
          }}
        >
          <MapChipCarousel
            {...{
              slides: routes,
              routeItemsRef,
              tooltipIsOpen,
            }}
          />
        </Box>
      ) : (
        <Box flexGrow={1} height="28px" />
      )}

      <Box width="8px" />
    </Box>
  );
};

RoutesItem.propTypes = {
  routes: array,
  headerBlockWidth: number,
  routesTitleWidth: number,
  routesTitleRef: object,
  classes: object,
  repPermissions: object,
  handleOpenRoutesDrawer: func,
  customer: object,
  routesWrapperRef: object,
  routesWrapperWidth: number,
  routeItemsRef: object,
  tooltipIsOpen: bool,
};
RoutesItem.defaultProps = {
  routes: [],
  headerBlockWidth: 0,
  routesTitleWidth: 0,
  routesTitleRef: {},
  classes: {},
  repPermissions: {},
  handleOpenRoutesDrawer: () => {},
  customer: {},
};
