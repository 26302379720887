import * as React from "react";
export const ClockNewIcon = (props) => (
  <svg
    width={20}
    height={22}
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.25 6.5v5h5M.75 4l2.5-2.5m14 0 2 2m0 8a9 9 0 1 1-18 0 9 9 0 0 1 18 0"
      stroke="#A5A5A5"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
