import { useMemo } from "react";
import { bool, func, string, number, object, array } from "prop-types";
import { Box, IconButton, Paper, Stack, Typography } from "@mui/material";
import ApplyDiscountPopup from "../ApplyDiscountPopup/ApplyDiscountPopup";
import TotalDiscountPopup from "../TotalDiscountPopup/TotalDiscountPopup";
import {
  getMSDDiscountSum,
  getProductsDictionary,
} from "Pages/DiscountsPage/components/ApplyDiscountPage/components/MSDPage/MSDPage.helpers";
import { countMSDs } from "./ThirdCustomerStep.helpers";
import { AddComponent, DiscountCount } from "./components";
import useStyles from "Pages/CartPage/styles";
import { cl } from "./ThirdCustomerStep.styles";
import { CartTrashIcon } from "components/Icons";
import { MsdQtyComponent } from "components";

export const ThirdCustomerStep = ({
  formField,
  isAdmin,
  updateDiscountDisabled,
  handleAddDiscount,
  applyDiscountOpen,
  setApplyDiscountOpen,
  handleApplyDiscount,
  cartState,
  deliveryStatus,
  paymentStatus,
  totalDiscountOpen,
  setTotalDiscountOpen,
  handleApplyTotalDiscount,
  calculatedTotal,
  hasQuickBooks,
  customerDiscount,
  totalCustomerDiscount,
  isPayed,
  isDuplicate,
  cartProductsWithFreeCaseProducts,
  calcProductPriceByPriceList,
  priceListId,
  appliedOrderDiscount,
  freeProducts,
  handleRemoveDiscount,
  setValue,
  freeCaseList,
}) => {
  const classes = useStyles();

  const {
    customer,
    products,
    manufacturerDiscounts,
    discount,
    totalOrderDiscountAmount,
    totalOrderDiscountType,
  } = formField || {};

  const prodDict = getProductsDictionary(
    products,
    cartState.availableMSDs?.map((dis) => dis.discounts).flat(),
    isPayed && !isDuplicate
  );

  const availableMSDCount = useMemo(
    () => countMSDs(cartState, !!customer?.id, formField, prodDict),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cartState.availableMSDs, manufacturerDiscounts, prodDict]
  );

  const handleDiscountsBlock = (type) => {
    const priceListProducts = cartProductsWithFreeCaseProducts?.map(
      (product) => {
        return {
          ...product,
          wholesalePrice: calcProductPriceByPriceList({ product }),
          price: calcProductPriceByPriceList({ product }),
        };
      }
    );

    const productsFormMsd = priceListId
      ? priceListProducts
      : cartProductsWithFreeCaseProducts;

    const discountRowName = (discount) => (
      <Box className={classes.discountRowName}>
        <Typography
          sx={{
            fontSize: "14px",
            color: "#707070",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            position: "relative",
            left: "20px",
          }}
        >
          {formField.discount?.name}
        </Typography>
        <Typography className={classes.discountTotal}>
          -$
          {(
            appliedOrderDiscount ||
            getMSDDiscountSum(
              productsFormMsd,
              [discount],
              null,
              freeProducts
            ) ||
            0
          ).toFixed(2)}
        </Typography>
      </Box>
    );

    const discountRowText = (discount) => (
      <Box key={discount.id} mt="-2px">
        <Typography fontSize="12px" fontWeight="400" color="#9C9C94">
          {discount?.manufacturer?.name}
        </Typography>
        <Typography
          key={discount.id}
          fontSize="12px"
          fontWeight="400"
          color="#9C9C94"
        >
          {discount.name}
        </Typography>
      </Box>
    );

    const setMaxMultiplierValue = (discount) => {
      if (discount?.type !== "QUANTITY") return 0;

      const discountId = discount?.manufacturerDiscountId || discount?.id;

      const productsForThisDiscount = prodDict[discountId];

      if (!productsForThisDiscount) return;

      const quantitySum = productsForThisDiscount.reduce(
        (acc, cur) => acc + cur?.quantity,
        0
      );

      return Math.floor(quantitySum / discount?.minQuantity);
    };

    const handleChangeMultiplier = ({ discount, value }) => {
      const discountIndex = formField?.manufacturerDiscounts.findIndex(
        (checkedDiscount) =>
          (checkedDiscount?.manufacturerDiscountId || checkedDiscount?.id) ===
          (discount?.manufacturerDiscountId || discount?.id)
      );

      if (discountIndex > -1) {
        const newDiscounts = [...formField?.manufacturerDiscounts];

        const currentItem = [...newDiscounts][discountIndex];

        currentItem.multiplier = value;

        newDiscounts.splice(discountIndex, 1, currentItem);

        setValue("manufacturerDiscounts", [...newDiscounts]);
      }
    };

    if (type === "manufacturer") {
      return formField?.manufacturerDiscounts?.map((discount) => {
        const manufacturerId = discount?.manufacturer?.id;

        const currentChosenProducts = freeCaseList.filter(
          (product) => product?.manufacturer?.id === manufacturerId
        );

        const minValue = currentChosenProducts.reduce((acc, cur) => {
          if (discount?.productType === "SPECIFIC_PRODUCTS") {
            const discountProduct = discount?.products?.some(
              ({ product, productId }) => {
                return (
                  (productId || product?.id || product?.product?.id) === cur?.id
                );
              }
            );

            return discountProduct ? acc + +(cur?.discountQty || 0) : acc;
          }
          return acc + +(cur?.discountQty || 0);
        }, 0);

        const maxMultiplierValue = setMaxMultiplierValue(discount);

        const setDisabledRightBtn = (discount) => {
          if (
            discount?.isLimited &&
            discount?.limit > 0 &&
            discount?.multiplier >= discount?.limit
          ) {
            return {
              title: (
                <Box sx={{ textAlign: "center" }}>
                  Discount limit of {discount?.limit}/{discount?.limit} has been
                  reached
                </Box>
              ),
            };
          }

          return null;
        };

        const disabledRightBtn = setDisabledRightBtn(discount);

        return (
          <Box key={discount.id} className={classes.discountRow}>
            {discountRowName(discount)}
            {discount?.type === "QUANTITY" ? (
              <Box
                sx={{
                  position: "absolute",
                  right: 8,
                  bottom: 8,
                }}
              >
                <MsdQtyComponent
                  value={discount?.multiplier || 1}
                  minValue={Math.ceil(
                    (minValue || 1) / discount?.quantityDiscount
                  )}
                  maxValue={maxMultiplierValue}
                  onChange={handleChangeMultiplier}
                  handleDeleteDiscount={() =>
                    handleRemoveDiscount([discount?.id])
                  }
                  isShowLeftTooltip={false}
                  disabledRightBtn={disabledRightBtn}
                  {...{ discount }}
                />
              </Box>
            ) : (
              <IconButton
                disabled={isAdmin}
                sx={{
                  position: "absolute",
                  right: 11,
                  bottom: 4,
                  width: "32px",
                  height: "32px",
                }}
                onClick={() =>
                  handleRemoveDiscount([
                    discount?.manufacturerDiscountId || discount?.id,
                  ])
                }
              >
                <CartTrashIcon height="15.82" width="14.23" />
              </IconButton>
            )}

            <Box className={classes.discountRowText}>
              {discountRowText(discount)}
            </Box>
          </Box>
        );
      });
    } else {
      return (
        <Box className={classes.discountRow}>
          {discountRowName()}
          <IconButton
            disabled={isAdmin}
            sx={{
              position: "absolute",
              right: 11,
              bottom: 4,
              width: "32px",
              height: "32px",
            }}
            onClick={() => handleRemoveDiscount()}
          >
            <CartTrashIcon height="15.82" width="14.23" />
          </IconButton>
          <Box className={classes.discountRowText}>
            {formField?.manufacturerDiscounts?.map((discount) =>
              discountRowText(discount)
            )}
          </Box>
        </Box>
      );
    }
  };

  return (
    <>
      <ApplyDiscountPopup
        isOpen={applyDiscountOpen}
        handleClose={() => setApplyDiscountOpen(false)}
        handleApplyDiscount={handleApplyDiscount}
        isDisablemsdBtn={!cartState.availableMSDCount}
        deliveryStatus={deliveryStatus}
        paymentStatus={paymentStatus}
      />
      <TotalDiscountPopup
        isOpen={totalDiscountOpen}
        handleClose={() => setTotalDiscountOpen(false)}
        handleApply={handleApplyTotalDiscount}
        amount={totalOrderDiscountAmount}
        type={totalOrderDiscountType}
        orderAmountWithoutCustomerDiscount={
          calculatedTotal - Number(totalCustomerDiscount)
        }
      />

      <Paper className={classes.section} sx={cl.paper}>
        <AddComponent
          customer={customer ? customer : null}
          {...{
            discount,
            products,
            isAdmin,
            updateDiscountDisabled,
            handleAddDiscount,
          }}
        />

        <Stack direction="row" gap="10px">
          <Typography sx={cl.title}>Discount</Typography>

          {!!availableMSDCount && !!products.length && !!customer?.id && (
            <DiscountCount
              {...{ availableMSDCount, handleApplyDiscount, deliveryStatus }}
            />
          )}
        </Stack>

        {!!discount && (
          <Box sx={cl.dividerWrapper}>
            <Box sx={cl.divider} />
          </Box>
        )}

        {customer?.id &&
        !hasQuickBooks &&
        (customer?.percentDiscount || customerDiscount) ? (
          <Box
            className={classes.customerDiscountRow}
            sx={{ mt: "12px", mb: !!discount && "12px" }}
          >
            <Typography sx={cl.customerDiscountText}>
              Customer discount |{" "}
              {customer?.percentDiscount || customerDiscount}%
            </Typography>
            <Typography className={classes.discountTotal}>
              -${totalCustomerDiscount}
            </Typography>
          </Box>
        ) : null}

        {!!discount && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
            {handleDiscountsBlock(discount?.type)}
          </Box>
        )}
      </Paper>
    </>
  );
};

ThirdCustomerStep.propTypes = {
  isAdmin: bool,
  updateDiscountDisabled: func,
  handleAddDiscount: func,
  applyDiscountOpen: bool,
  setApplyDiscountOpen: func,
  handleApplyDiscount: func,
  cartState: object,
  totalDiscountOpen: bool,
  setTotalDiscountOpen: func,
  priceListLoading: bool,
  handleApplyTotalDiscount: func,
  calculatedTotal: number,
  hasQuickBooks: bool,
  customerDiscount: number,
  totalCustomerDiscount: string,
  formField: object,
  deliveryStatus: string,
  paymentStatus: string,
  priceListName: string,
  isPayed: bool,
  isDuplicate: bool,
  cartProductsWithFreeCaseProducts: array,
  calcProductPriceByPriceList: func,
  priceListId: string,
  appliedOrderDiscount: number,
  freeProducts: array,
  handleRemoveDiscount: func,
  setValue: func,
  freeCaseList: array,
};
