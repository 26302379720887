import { elementType, number, string } from "prop-types";

import { cl } from "./TextWithIcon.styles";

import { Box, Typography } from "@mui/material";

export const TextWithIcon = ({ icon, count, label }) => {
  return (
    <Box sx={cl.wrapper}>
      {!!icon && <Box sx={cl.iconWrapper}>{icon}</Box>}
      {!!count && (
        <Typography component="span" sx={cl.counterText}>
          {count}
        </Typography>
      )}
      <Typography noWrap sx={cl.labelText}>
        {label}
      </Typography>
    </Box>
  );
};

TextWithIcon.propTypes = {
  icon: elementType,
  count: number,
  label: string,
};
