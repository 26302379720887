import * as React from "react";
export const CartIcon = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 3.25a.75.75 0 0 0 0 1.5zM19.5 15.5l.732.165v-.004zM6.526 6.566l.724-.196zm1.776 8.742.74-.121-.002-.015zm10.317 2.538.492.566zm-9.52 0-.51.55zm11.133-5.687.732.16zm-.782-1.195-.146.736zM3 4.75h1v-1.5H3zM11.45 19h4.909v-1.5H11.45zm8.783-3.34.731-3.34-1.465-.321-.732 3.34zM5.802 6.76l.522 1.935 1.448-.39L7.25 6.37zm.508 1.876 1.255 6.808 1.475-.272-1.255-6.808zm13.286 1.593L7.194 7.764 6.9 9.236 19.304 11.7zM16.359 19c.594 0 1.107-.004 1.531-.067.448-.067.86-.208 1.22-.521l-.983-1.133c-.078.068-.19.13-.458.17-.293.044-.69.051-1.31.051zm2.41-3.665c-.152.672-.253 1.116-.365 1.443-.107.314-.198.433-.277.501l.984 1.133c.36-.313.563-.711.712-1.148.145-.423.265-.961.409-1.6zM11.45 17.5c-.672 0-1.084.002-1.393-.039-.273-.036-.372-.095-.447-.165l-1.02 1.1c.375.348.805.49 1.271.552.431.057.97.052 1.589.052zm-3.888-2.071c.113.69.206 1.268.335 1.724.132.47.324.9.692 1.242l1.02-1.1c-.081-.075-.172-.207-.268-.548-.1-.356-.18-.836-.299-1.56zM4 4.75c.259 0 .492.001.707.035s.34.09.41.149l.956-1.156c-.36-.298-.783-.42-1.133-.474-.35-.055-.699-.054-.94-.054zm3.25 1.62c-.163-.602-.3-1.111-.454-1.51-.16-.413-.369-.79-.723-1.082l-.956 1.156c.077.063.166.174.28.468.12.307.233.725.405 1.359zm13.714 5.95a1.75 1.75 0 0 0-1.368-2.091l-.292 1.471a.25.25 0 0 1 .195.299z"
      fill="currentColor"
      strokeWidth={0.1}
    />
    <circle cx={10} cy={21.5} r={1} fill="currentColor" strokeWidth={0.1} />
    <circle cx={17} cy={21.5} r={1} fill="currentColor" strokeWidth={0.1} />
  </svg>
);
