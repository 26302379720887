import * as React from "react";
export const FutureRouteIcon = (props) => (
  <svg
    width={12}
    height={14}
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.5 1h9m-9 12h9M6 7c-.2 0-.3 0-.387-.007-1.022-.084-1.861-.895-2.042-1.97a6 6 0 0 1-.045-.411L3.188 1h5.624l-.338 3.612c-.02.213-.03.32-.045.41-.18 1.076-1.02 1.887-2.042 1.97C6.301 7 6.2 7 6 7Zm0 0c-.2 0-.3 0-.387.007-1.022.084-1.861.895-2.042 1.97a6 6 0 0 0-.045.411L3.188 13h5.624l-.338-3.612a6 6 0 0 0-.045-.41c-.18-1.076-1.02-1.887-2.042-1.97C6.301 7 6.2 7 6 7Z"
      stroke="#F39325"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </svg>
);
