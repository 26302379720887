import { func, bool } from "prop-types";

import { cl } from "../../styles";

import { DialogActions } from "@mui/material";
import { StyledButton } from "components";

export const CategoriesActionBlock = ({
  handleCancel = () => {},
  disabledSaveBtn = false,
  onAdd = () => {},
}) => {
  return (
    <DialogActions sx={cl.actionsWrapper}>
      <StyledButton
        label="Cancel"
        sx={cl.actionBtn}
        color="cancel"
        onClick={handleCancel}
        fontWeight="600"
        fontSize="12px"
      />
      <StyledButton
        disabled={disabledSaveBtn}
        label="Save"
        variant="contained"
        sx={cl.actionBtn}
        fontWeight="600"
        fontSize="12px"
        onClick={() => {
          onAdd();
          handleCancel();
        }}
      />
    </DialogActions>
  );
};

CategoriesActionBlock.propTypes = {
  handleCancel: func,
  onAdd: func,
  disabledSaveBtn: bool,
};
