import { array, bool, func, number, object } from "prop-types";
import { Stack } from "@mui/material";
import {
  ActionsBlock,
  SelectedFormsBlock,
  SelectFormsBlock,
  TitleBlock,
} from "./components";
import { useAddFormsComponent } from "./useAddFormsComponent";

export const AddFormsComponent = ({
  checkedItemsLimit = 5,
  isNotAllowedToEdit = false,
  wrapperProps = {},
  editCheckedForms = [],
  handleSetCheckedFormsIds = () => {},
  isAdmin = false,
}) => {
  const formsState = useAddFormsComponent({
    checkedItemsLimit,
    editCheckedForms,
    handleSetCheckedFormsIds,
  });

  const {
    loadingFormsList,
    countForms,
    formsList,
    formsFilter,
    setFormsFilter,
    checkedForms,
    handleSetCheckedForms,
    anchorMenuEl,
    handleOpenAddForms,
    handleCloseAddForms,
  } = formsState || {};

  return (
    <Stack {...wrapperProps}>
      <TitleBlock />

      {!!checkedForms?.length && (
        <SelectedFormsBlock
          {...{ checkedForms, handleSetCheckedForms, isNotAllowedToEdit }}
        />
      )}

      <ActionsBlock
        handleOpenAddForms={handleOpenAddForms}
        isNotAllowedToEdit={isNotAllowedToEdit}
        isAdmin={isAdmin}
      />

      <SelectFormsBlock
        {...{
          anchorMenuEl,
          formsList,
          handleSetCheckedForms,
          checkedForms,
          handleCloseAddForms,
          formsFilter,
          setFormsFilter,
          loadingFormsList,
          countForms,
          checkedItemsLimit,
        }}
      />
    </Stack>
  );
};

AddFormsComponent.propTypes = {
  wrapperProps: object,
  isNotAllowedToEdit: bool,
  checkedItemsLimit: number,
  editCheckedForms: array,
  handleSetCheckedFormsIds: func,
  isAdmin: bool,
};
