import { bool, func, object, oneOf } from "prop-types";
import {
  Box,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import useStyles from "../../styles";
import { Controller } from "react-hook-form";
import { IOSSwitch, PriceFormat, StyledTextField } from "components";
import NumberFormat from "react-number-format";

export const DeliveryTab = ({
  isAdmin = false,
  control = {},
  handleCheckDeliveryFee = () => {},
  deliveryFee = false,
  deliveryFeeType = "PERCENTAGE",
  deliveryFeePriceCap = false,
  automaticFulfillByDate = false,
  setValue = () => {},
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.ordersBody}>
      <Paper className={classes.buttonBlock}>
        <Stack flexDirection="row" alignItems="center">
          <Stack>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <IOSSwitch
                  disabled={isAdmin}
                  label={
                    <Box className={classes.switchLabelBlock}>
                      <Typography className={classes.switchLabelFirst}>
                        Delivery
                      </Typography>
                      <Typography className={classes.switchLabelSecond}>
                        Delivery & handling fee
                      </Typography>
                    </Box>
                  }
                  checked={!!field.value}
                  formSx={{ flex: "0 0 34%" }}
                  error={error ? error.message : ""}
                  {...field}
                  onChange={() => {
                    if (isAdmin) return;
                    handleCheckDeliveryFee(field.value);
                  }}
                />
              )}
              name="deliveryFee"
              control={control}
            />
          </Stack>

          <Stack sx={{ marginLeft: "auto", width: "113px" }}>
            <Controller
              render={({ field, fieldState: { error } }) => {
                return (
                  <PriceFormat
                    type={deliveryFeeType}
                    noErrorMessage
                    fullWidth
                    disabled={isAdmin || !deliveryFee}
                    InputProps={{
                      sx: {
                        height: "32px",
                        fontSize: "12px",
                        pr: 0,
                        background: "#fff",
                        color: "#707070",
                      },
                      endAdornment: (
                        <Controller
                          render={({ field, fieldState: { error } }) => (
                            <FormControl fullWidth>
                              <Select
                                disabled={isAdmin || !deliveryFee}
                                sx={{
                                  height: "32px",
                                  width: "45px",
                                  fontSize: 12,
                                  color: "#707070",
                                  borderRadius: "0",
                                  "& .MuiSelect-select": {
                                    paddingLeft: "8px",
                                    minWidth: "20px",
                                  },
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderWidth: "0 0 0 1px !important",
                                    borderColor: "#D5D9D9 !important",
                                  },
                                }}
                                size="small"
                                error={!!error?.message}
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                  setValue("deliveryFeeAmount", 0);
                                }}
                              >
                                <MenuItem value="PERCENTAGE">%</MenuItem>
                                <MenuItem value="ABSOLUTE">$</MenuItem>
                              </Select>
                            </FormControl>
                          )}
                          name="deliveryFeeType"
                          control={control}
                        />
                      ),
                    }}
                    error={error ? !!error.message : ""}
                    {...field}
                    value={field.value.toString()}
                  />
                );
              }}
              name="deliveryFeeAmount"
              control={control}
            />
          </Stack>
        </Stack>
      </Paper>

      <Paper className={classes.buttonBlock}>
        <Stack flexDirection="row" alignItems="center">
          <Stack>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <IOSSwitch
                  disabled={isAdmin || !deliveryFee}
                  hasTooltip={!deliveryFee}
                  tooltipText="Delivery Fee Price Cap cannot be turned on/off because the Delivery Fee is off. Please enable Delivery Fee to access this option."
                  label={
                    <Box className={classes.switchLabelBlock}>
                      <Typography className={classes.switchLabelFirst}>
                        Delivery fee price cap
                      </Typography>
                      <Typography className={classes.switchLabelSecond}>
                        Delivery fee will not add to invoice if order total is
                        greater then specified amount
                      </Typography>
                    </Box>
                  }
                  checked={!!field.value}
                  error={error ? error.message : ""}
                  formSx={{ flex: "0 0 34%" }}
                  {...field}
                  onChange={(e) => {
                    if (isAdmin) return;
                    field.onChange(e);
                  }}
                />
              )}
              name="deliveryFeePriceCap"
              control={control}
            />
          </Stack>

          <Stack sx={{ marginLeft: "auto", width: "113px" }}>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <PriceFormat
                  noErrorMessage
                  fullWidth
                  disabled={isAdmin || !deliveryFee || !deliveryFeePriceCap}
                  InputProps={{
                    sx: {
                      height: "32px",
                      fontSize: "12px",
                      pr: 0,
                      background: "#fff",
                      color: "#707070",
                    },
                    startAdornment: (
                      <Typography className={classes.deliveryPriceCapText}>
                        $
                      </Typography>
                    ),
                  }}
                  error={error ? error.message : ""}
                  {...field}
                />
              )}
              name="deliveryFeePriceCapAmount"
              control={control}
            />
          </Stack>
        </Stack>
      </Paper>

      <Paper className={classes.buttonBlock}>
        <Stack flexDirection="row" alignItems="center">
          <Stack>
            <Controller
              render={({ field, fieldState: { error } }) => (
                <IOSSwitch
                  disabled={isAdmin}
                  label={
                    <Box className={classes.switchLabelBlock}>
                      <Typography className={classes.switchLabelFirst}>
                        Fulfill by date
                      </Typography>
                      <Typography className={classes.switchLabelSecond}>
                        Set automatic fulfill by date from order created
                      </Typography>
                    </Box>
                  }
                  checked={!!field.value}
                  formSx={{ flex: "0 0 34%" }}
                  error={error ? error.message : ""}
                  {...field}
                  onChange={(e) => {
                    if (isAdmin) return;
                    field.onChange(e);
                  }}
                />
              )}
              name="automaticFulfillByDate"
              control={control}
            />
          </Stack>

          <Stack sx={{ marginLeft: "auto", width: "113px" }}>
            <Controller
              render={({ field, fieldState: { error } }) => {
                return (
                  <NumberFormat
                    customInput={StyledTextField}
                    noErrorMessage
                    fullWidth
                    size="small"
                    placeholder="0"
                    allowNegative={false}
                    disabled={isAdmin || !automaticFulfillByDate}
                    InputProps={{
                      sx: {
                        height: "32px",
                        fontSize: "12px",
                        pr: 0,
                        background: "#fff",
                        color: "#707070",
                      },
                      endAdornment: (
                        <Typography
                          fontSize={14}
                          fontWeight={300}
                          color="#D5D9D9"
                          mr={1}
                        >
                          days
                        </Typography>
                      ),
                    }}
                    isAllowed={(values) => {
                      const { floatValue, formattedValue } = values;
                      return (
                        (floatValue <= 999 &&
                          floatValue >= 0 &&
                          !formattedValue.includes("-") &&
                          !/^0\d/.test(formattedValue)) ||
                        formattedValue === ""
                      );
                    }}
                    error={error ? !!error.message : ""}
                    {...field}
                  />
                );
              }}
              name="automaticFulfillByDateAmount"
              control={control}
            />
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
};

DeliveryTab.propTypes = {
  isAdmin: bool,
  control: object,
  handleCheckDeliveryFee: func,
  deliveryFee: bool,
  deliveryFeeType: oneOf(["PERCENTAGE", "ABSOLUTE"]),
  deliveryFeePriceCap: bool,
  automaticFulfillByDate: bool,
  setValue: func,
};
