import { useCallback, useEffect, useState } from "react";
import { defaultValues, FORM_TYPES, TABS_PAGE } from "./FormViewPage.constants";
import { generateUUID, handleError } from "helpers/helpers";
import {
  createFormService,
  getFormByIdService,
  updateFormByIdService,
} from "services/forms";
import { validateResponseData } from "./FormViewPage.validations";
import { success } from "utils/notifications";
import {
  openConfirmDialogAction,
  resetConfirmDialogAction,
  setEditTypeAction,
  setFormChangedAction,
} from "redux/actions/confirmDialogs";
import { useDispatch } from "react-redux";
// import { confirmDialogIsOpenSelector } from "redux/selectors/confirmDialogs";
import { Button } from "@mui/material";
import { useRedirect } from "helpers/useRedirect";

export const useFormViewPage = ({
  formId,
  reset,
  handleSubmit,
  formField,
  setValue,
  isDirty,
  clearErrors,
}) => {
  // const isOpenConfirmDialog = useSelector(confirmDialogIsOpenSelector);

  const navigate = useRedirect();
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState(TABS_PAGE[0].value);
  const [activeQuestion, setActiveQuestion] = useState(null);

  const handleChangeCurrentTab = (tab) => {
    setCurrentTab(tab);
  };

  const [loading, setLoading] = useState(false);

  const fetchFormById = useCallback(
    async ({ formId }) => {
      // eslint-disable-next-line no-console
      if (!formId) return console.error("Form id is required");

      try {
        setLoading(true);
        const res = await getFormByIdService(formId);

        validateResponseData({ data: res });

        reset({
          ...defaultValues,
          uuid: generateUUID(),
          title: res?.title,
          active: res?.active,
          description: res?.description || "",
          autofill: res?.autofill,
          allRequired: res?.allRequired,
          allowGallery: res?.allowGallery,
          _count: res?._count || defaultValues._count,
          assignedCustomers:
            res?.assignedCustomers || defaultValues.assignedCustomers,
          assignedRepresentatives:
            res?.assignedRepresentatives ||
            defaultValues.assignedRepresentatives,
          questions: res?.questions.map((q, i) => ({
            ...q,
            uuid: generateUUID(),
            orderIndex: i,
            ...(q?.options?.length && {
              options: q?.options?.map((o, index) => ({
                title: o,
                uuid: generateUUID(),
                orderIndex: index,
              })),
            }),
          })),
        });
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
      }
    },
    [reset]
  );

  useEffect(() => {
    if (formId) {
      fetchFormById({ formId });
    }
  }, [fetchFormById, formId]);

  const handleSubmitForm = useCallback(
    async (data) => {
      try {
        setLoading(true);

        const {
          title,
          active,
          description,
          autofill,
          allRequired,
          questions,
          questionsToDelete,
          assignedCustomers,
          assignedRepresentatives,
        } = data;

        const preparedData = {
          title: title?.trim(),
          active,
          ...(description?.trim() && { description: description?.trim() }),
          autofill,
          allRequired,
          customersIds:
            data._count?.assignedCustomers === 0
              ? []
              : assignedCustomers?.length
              ? assignedCustomers?.map(({ id }) => id)
              : undefined,
          representativesIds:
            data._count?.assignedRepresentatives === 0
              ? []
              : assignedRepresentatives?.length
              ? assignedRepresentatives?.map(({ id }) => id)
              : undefined,
          questions: questions?.map(({ uuid, children, options, ...q }) => {
            return {
              ...(formId && !!q.id && { id: q.id }),
              ...(formId && !q.id && { id: null }),
              text: q?.text?.trim(),
              type: q?.type,
              ...(!!options?.length &&
                [FORM_TYPES.SINGLE_ANSWER, FORM_TYPES.MULTIPLE_ANSWER].includes(
                  q?.type
                ) && {
                  options: options?.map(({ title }) => title?.trim()),
                }),
              orderIndex: q?.orderIndex,
              autofill: q?.autofill,
              required: q?.required,
              allowGallery: q?.allowGallery,
            };
          }),
          ...(questionsToDelete?.length && { questionsToDelete }),
        };

        if (formId) {
          await updateFormByIdService(formId, preparedData);
        } else {
          await createFormService(preparedData);
        }

        dispatch(setEditTypeAction("", false));
        dispatch(setFormChangedAction(false));

        navigate("/tasks", { state: { tab: "Forms" } });

        success(`Form ${formId ? "Saved" : "Created"}`);
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, formId, navigate]
  );

  const questionDeleteInit = {
    open: false,
    question: null,
    isDeleteResponses: false,
  };

  const [questionDeleteState, setQuestionDeleteState] =
    useState(questionDeleteInit);

  const handleCloseQuestionDeleteDialog = () => {
    setQuestionDeleteState(questionDeleteInit);
  };

  const handleOpenQuestionDeleteDialog = ({ question, isDeleteResponses }) => {
    setQuestionDeleteState((prev) => ({
      ...prev,
      open: true,
      question,
      isDeleteResponses,
    }));
  };

  const handleDeleteQuestion = ({ deleteResponses }) => {
    if (!questionDeleteState.question)
      // eslint-disable-next-line no-console
      return console.error("Question id is required");

    if (typeof deleteResponses !== "boolean")
      // eslint-disable-next-line no-console
      return console.error("deleteResponses is required");

    setValue("questionsToDelete", [
      ...formField?.questionsToDelete,
      {
        id: questionDeleteState?.question?.id,
        deleteResponses,
      },
    ]);

    const tempList = [...formField?.questions];

    tempList.splice(questionDeleteState?.question?.orderIndex, 1);

    setValue(
      "questions",
      tempList.map((q, i) => ({ ...q, orderIndex: i })),
      { shouldDirty: true }
    );
    handleCloseQuestionDeleteDialog();
  };

  const handleDeleteQuestionWithoutResponses = () => {
    const tempList = [...formField?.questions];

    tempList.splice(questionDeleteState?.question?.orderIndex, 1);

    if (formId && questionDeleteState?.question?.id)
      setValue("questionsToDelete", [
        ...formField?.questionsToDelete,
        {
          id: questionDeleteState?.question.id,
          deleteResponses: true,
        },
      ]);

    setValue(
      "questions",
      tempList.map((q, i) => ({ ...q, orderIndex: i })),
      { shouldDirty: true }
    );
    clearErrors("questions");
    handleCloseQuestionDeleteDialog();
  };

  useEffect(() => {
    dispatch(setFormChangedAction(isDirty));
    dispatch(setEditTypeAction("form-view", false));

    return () => dispatch(setEditTypeAction("", false));
  }, [isDirty, dispatch]);

  const handleSwitchStatus = useCallback(
    ({ status }) => {
      dispatch(
        openConfirmDialogAction({
          isIcon: true,
          title: "Would you like to change status?",
          text: `Changes were made to this ${
            status ? "inactive" : "active"
          } form. Would you like to make it ${status ? "active" : "inactive"}?`,
          buttons: (
            <>
              <Button
                sx={{
                  fontSize: "13px",
                  height: "28px",
                }}
                onClick={() => {
                  dispatch(resetConfirmDialogAction());
                  setValue("active", !status);
                  handleSubmitForm({
                    ...formField,
                    active: !status,
                  });
                }}
                variant="outlined"
              >
                {`Keep ${status ? "inactive" : "active"} & Save`}
              </Button>
              <Button
                sx={{
                  width: "124px",
                  color: "#FFFFFF",
                  fontSize: "13px",
                  height: "28px",
                  boxShadow: "none",
                  px: 0.5,
                }}
                color="primary"
                onClick={() => {
                  dispatch(resetConfirmDialogAction());
                  handleSubmitForm(formField);
                }}
                variant="contained"
              >
                {`${status ? "Active" : "Inactive"} & Save`}
              </Button>
            </>
          ),
        })
      );
    },
    [dispatch, formField, handleSubmitForm, setValue]
  );

  const handleOpenChangeDialog = useCallback(
    ({ onSubmit }) => {
      dispatch(
        openConfirmDialogAction({
          isIcon: true,
          title: "Would you like to change the question?",
          text: "Changing the question title will disable the Autofill feature. You can re-enable it anytime if needed.",
          buttons: (
            <>
              <Button
                sx={{
                  fontSize: "13px",
                  height: "28px",
                }}
                color="cancel"
                onClick={() => dispatch(resetConfirmDialogAction())}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                sx={{
                  width: "124px",
                  color: "#FFFFFF",
                  fontSize: "13px",
                  height: "28px",
                  boxShadow: "none",
                  px: 0.5,
                }}
                color="primary"
                onClick={() => {
                  dispatch(resetConfirmDialogAction());
                  onSubmit();
                }}
                variant="contained"
              >
                Proceed
              </Button>
            </>
          ),
        })
      );
    },
    [dispatch]
  );

  return {
    loading,
    currentTab,
    handleChangeCurrentTab,
    tabs: TABS_PAGE,
    activeQuestion,
    setActiveQuestion,
    handleSubmitForm: handleSubmit(handleSubmitForm),
    ...{
      questionDeleteState,
      handleCloseQuestionDeleteDialog,
      handleOpenQuestionDeleteDialog,
      handleDeleteQuestion,
      handleDeleteQuestionWithoutResponses,
    },
    handleSwitchStatus,
    handleOpenChangeDialog,
  };
};
