import { useContext } from "react";
import { CategoriesContext } from "Pages/CatalogPage";

import { DrawerHeader } from "./components/DrawerHeader";
import { DrawerFooter } from "./components/DrawerFooter";
import { DrawerContent } from "./components/DrawerContent";

import { useCategoriesDrawer } from "./useCategoriesDrawer";

import { Box, Drawer } from "@mui/material";
import AllProductsPopup from "Pages/CartPage/components/AllProductsPopup/AllProductsPopup";
import { useCategoryProducts } from "helpers/useCategoryProducts";

export const AddCategoryDrawer = () => {
  const {
    openAddDrawer: openState,
    onEditDrawerClose: onClose,
    onSaveCallback,
  } = useContext(CategoriesContext);

  const {
    loading,
    isEdit,
    handleSubmit,
    onSubmit,
    control,
    formField,
    disabledSaveButton,
    onCloseDrawer,
    categoriesLoading,
    categoriesList,
    categoriesCount,
    categoriesPage,
    onDelete,
    setValue,
    handleFetchCategories,
    isParent,
    onConfirmProductsSelect,
    onSaveProductsFromPopup,
    getDataLoading,
    resetSelectedProducts,
    availableProducts,
  } = useCategoriesDrawer({
    openState,
    onClose,
    onSaveCallback,
  });

  const {
    changedIds,
    openAllProducts,
    onProductsPopupOpen,
    onCloseAllProducts,
    onTempProductSelect,
    tempCheckedProducts,
    onProductsStateUpdate,
    isAllUnchecked,
    setIsAllUnchecked,
    checkForProductChange,
  } = useCategoryProducts({
    productsList: formField?.categoryProducts || [],
  });

  return (
    <Drawer
      anchor="right"
      open={openState.state}
      onClose={onCloseDrawer}
      PaperProps={{ sx: { width: "540px" } }}
    >
      <Box
        component="form"
        style={{ height: "100%", display: "flex", flexDirection: "column" }}
        id="category-form-item"
        onSubmit={handleSubmit(onSubmit)}
      >
        <DrawerHeader
          titleLabel={isEdit ? "Edit Category" : "Add Category"}
          onClose={onCloseDrawer}
        />

        <DrawerContent
          {...{
            isParent,
            getDataLoading,
            setValue,
            isEdit,
            control,
            formField,
            categoriesLoading,
            categoriesList,
            categoriesCount,
            categoriesPage,
            handleFetchCategories,
            resetSelectedProducts,
            count: formField?.selectedCount,
            onEdit: onProductsPopupOpen,
            parentName: openState?.parent?.name,
          }}
        />
        <DrawerFooter
          {...{
            onClose: onCloseDrawer,
            loading,
            isEdit,
            disabledSaveButton,
            onDelete,
          }}
        />
      </Box>

      {openAllProducts && (
        <AllProductsPopup
          dialogProps={{
            BackdropProps: { sx: { backgroundColor: "transparent" } },
          }}
          isPriceList
          hideChildCheckbox
          headerTitle="Select Products"
          isOpen={openAllProducts}
          handleClose={() => onCloseAllProducts()}
          handleCheckProduct={onTempProductSelect}
          handleCancelProducts={() => onCloseAllProducts()}
          setCheckState={(data) =>
            onProductsStateUpdate({ tempCheckedProducts: data })
          }
          handleAddProducts={(data) => {
            const productsHaveBeenChanged = checkForProductChange();
            onCloseAllProducts();

            if (!productsHaveBeenChanged) {
              onSaveProductsFromPopup(data);
              return;
            }

            return onConfirmProductsSelect(() => {
              onSaveProductsFromPopup(data, productsHaveBeenChanged);
            });
          }}
          checkedProducts={tempCheckedProducts}
          addedProducts={[]}
          allowMultipleSelect
          hideGreenNavigationBar
          isOrder
          skipExpanding
          hideAvailable
          disableOutOfStock={false}
          allowSelectAll
          allCheckedByDefault
          isAllUnchecked={isAllUnchecked}
          excludeIds={changedIds}
          setExcludeIds={(data) => onProductsStateUpdate({ changedIds: data })}
          setIsAllUnchecked={setIsAllUnchecked}
          availableAssignedProducts={availableProducts}
          isAllUncheckedProducts={formField.isAllUncheckedProducts}
        />
      )}
    </Drawer>
  );
};
