import { Typography } from "@mui/material";
import { func, string } from "prop-types";

export const ToastUndoBlock = ({
  text,
  undoText = "Undo",
  undoCallback,
  onClose,
}) => {
  return (
    <Typography
      variant="subtitle1"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {text}{" "}
      <span
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          !!undoCallback && undoCallback();
          !!onClose && onClose();
        }}
        style={{ cursor: "pointer", textDecoration: "underline" }}
      >
        {undoText}
      </span>
    </Typography>
  );
};

ToastUndoBlock.propTypes = {
  undoCallback: func,
  onClose: func,
  text: string,
  undoText: string,
};
