import { Box, Button, ListItemText, MenuItem, Stack } from "@mui/material";
import {
  LargeCheckbox,
  SearchTextField,
  SelectDatePicker,
  StyledTooltip,
} from "components";
import { useContext, useEffect, useMemo, useState } from "react";
import { StyledMultipleSelect } from "components/Selects";
import { useRepsAssignedActions } from "helpers/useRepsAssignedActions";
import { ExpandMore } from "@mui/icons-material";
import AllCustomersPopup from "Pages/CreateRoutePage/components/AllCustomersPopup";
import { FormViewContext } from "Pages/FormViewPage/FormViewPage";
import { useDebounce } from "helpers/hooks";
import { TextWithIcon } from "./components/TextWithIcon/TextWithIcon";
import RepsIcon from "./components/icons/RepsIcon";
import { CustomersIcon } from "components/Icons/NavbarIcons";
import DatePickerIcon from "./components/icons/DatePickerIcon";
import { cl } from "./FilterBlock.styles";
export const FiltersBlock = () => {
  const { setResponsesQueryParams } = useContext(FormViewContext);
  const [isRepsDropdownOpen, setIsRepsDropdownOpen] = useState(false);
  const [isCustomerPopupOpen, setIsCustomerPopupOpen] = useState(false);

  const [isDateTooltipOpen, setIsDateTooltipOpen] = useState(false);

  const {
    list: repsList,
    initCount: repsInitCount,
    setRepsParams,
  } = useRepsAssignedActions({
    params: {
      page: undefined,
      limit: undefined,
      form_active: true,
    },
    open: isRepsDropdownOpen,
  });

  const [searchInput, setSearchInput] = useState("");
  const debouncedSearch = useDebounce(searchInput, 500);

  useEffect(() => {
    setRepsParams((prev) => ({
      ...prev,
      search: debouncedSearch,
    }));
  }, [debouncedSearch, setRepsParams]);

  const [selectedRepresentatives, setSelectedRepresentatives] = useState([
    "All reps",
  ]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedDate, setSelectedDate] = useState("This week");

  const repSelectRenderValue = useMemo(() => {
    if (selectedRepresentatives?.length === 1) {
      return <TextWithIcon icon={<RepsIcon />} label={"All Reps"} />;
    } else if (selectedRepresentatives?.length === 2) {
      return (
        <TextWithIcon
          icon={<RepsIcon />}
          label={
            repsList.filter(
              (el) =>
                el.id ===
                selectedRepresentatives?.filter((el) => el !== "All reps")[0]
            )[0].name
          }
        />
      );
    } else {
      return (
        <TextWithIcon
          icon={<RepsIcon />}
          count={selectedRepresentatives.length - 1}
          label="Selected"
        />
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRepresentatives]);

  const customersSelectRenderValue = useMemo(() => {
    if (selectedCustomers?.length === 0) {
      return (
        <TextWithIcon
          icon={<CustomersIcon width={16.5} height={16.5} />}
          label={"All Customers"}
        />
      );
    } else if (selectedCustomers?.length === 1) {
      return (
        <TextWithIcon
          icon={<CustomersIcon width={16.5} height={16.5} />}
          label={selectedCustomers[0].name}
        />
      );
    } else {
      return (
        <TextWithIcon
          icon={<CustomersIcon width={16.5} height={16.5} />}
          count={selectedCustomers.length}
          label={"Selected"}
        />
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomers]);

  useEffect(
    () => {
      setResponsesQueryParams((prev) => ({
        ...prev,
        representative_ids: JSON.stringify(
          selectedRepresentatives.filter((id) => id && id !== "All reps")
        ),
        customer_ids: JSON.stringify(
          selectedCustomers.map((customer) => customer.id)
        ),
        responses_created_at:
          typeof selectedDate === "string"
            ? selectedDate.toLowerCase().replace(/\s+/g, "_")
            : null,
        responses_created_at_start_date:
          typeof selectedDate !== "string" && selectedDate[0]
            ? selectedDate[0].format("YYYY-MM-DD")
            : null,
        responses_created_at_end_date:
          typeof selectedDate !== "string" && selectedDate[1]
            ? selectedDate[1].format("YYYY-MM-DD")
            : null,
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRepresentatives, selectedCustomers, selectedDate]
  );

  return (
    <Stack direction="row" gap="9px" align="center" width="594px" height="40px">
      {isCustomerPopupOpen && (
        <AllCustomersPopup
          viewCustomerPopup={false}
          handleAddCustomers={(customers) => {
            setSelectedCustomers(customers);
            setIsCustomerPopupOpen(false);
          }}
          addedStops={selectedCustomers}
          open={isCustomerPopupOpen}
          onClose={() => setIsCustomerPopupOpen(false)}
        />
      )}
      {/* Time Range Filter */}
      <StyledTooltip
        title="Select Period"
        placement="top"
        open={isDateTooltipOpen}
      >
        <Box
          width="33.3%"
          maxWidth="192px"
          onMouseEnter={() => {
            setIsDateTooltipOpen(true);
          }}
          onMouseLeave={() => {
            setIsDateTooltipOpen(false);
          }}
          onClick={() => {
            setIsDateTooltipOpen(false);
          }}
        >
          <SelectDatePicker
            value={selectedDate}
            fullWidth
            noSquare
            handleClearValue={() => setSelectedDate("This week")}
            newStyledPicker
            handleDateInput={setSelectedDate}
            customStartAdornment={<DatePickerIcon />}
            truncateCustomDate={true}
            disabled={typeof selectedDate !== "string"}
            onOpen={() => setIsDateTooltipOpen(false)}
            renderValue={() => {
              return (
                <TextWithIcon icon={<DatePickerIcon />} label={selectedDate} />
              );
            }}
            onChange={(e) => {
              if (e.target.value) {
                setSelectedDate(e.target.value);
              }
            }}
            sx={cl.selectSx}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              PaperProps: {
                style: {
                  marginTop: "8px",
                  width: "292px",
                },
              },
            }}
          />
        </Box>
      </StyledTooltip>

      {/* Representatives Filter */}
      <StyledTooltip
        title="Select Representatives"
        placement="top"
        disableHoverListener={isRepsDropdownOpen}
      >
        <Box width="33.3%" maxWidth="192px">
          <StyledMultipleSelect
            adminIsAllowed
            multiple
            name="Reps select"
            label=""
            value={selectedRepresentatives}
            renderValue={() => {
              return repSelectRenderValue;
            }}
            onChange={(e) => setSelectedRepresentatives(e.target.value)}
            onOpen={() => {
              setIsRepsDropdownOpen(true);
            }}
            onClose={() => setIsRepsDropdownOpen(false)}
            fullWidth
            sx={cl.selectSx}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              PaperProps: {
                style: {
                  marginTop: "8px",
                  width: "292px",
                },
              },
            }}
          >
            {repsInitCount > 5 && (
              <Box
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => e.stopPropagation()}
              >
                <SearchTextField
                  placeholder="Search reps"
                  variant="outlined"
                  size="small"
                  formSx={{
                    width: "292px",
                    pl: "10px",
                    pr: "10px",
                  }}
                  onChange={(e) => setSearchInput(e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                  onKeyDown={(e) => e.stopPropagation()}
                />
              </Box>
            )}
            <Box
              onClick={(e) => e.stopPropagation()}
              onKeyDown={(e) => e.stopPropagation()}
            >
              <MenuItem
                sx={{ maxWidth: "282px !important" }}
                key="all-all"
                value="all"
                name="all"
                // iconid={child?.iconId}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedRepresentatives(["All reps"]);
                }}
                onKeyDown={(e) => e.stopPropagation()}
              >
                <LargeCheckbox
                  checked={selectedRepresentatives.length === 1}
                  size={15}
                  sx={{ p: "5px" }}
                  formSx={{ mr: "5px" }}
                />
                <ListItemText
                  primaryTypographyProps={{
                    sx: { fontSize: 12 },
                    noWrap: true,
                  }}
                >
                  All
                </ListItemText>
              </MenuItem>
            </Box>

            {repsList.length ? (
              repsList?.map((child) => {
                return (
                  <MenuItem
                    sx={{ maxWidth: "282px !important" }}
                    key={`${child?.id}-${child?.name}`}
                    value={child?.id}
                    name={child?.name || child?.tag}
                    iconid={child?.iconId}
                    {...child?.props}
                  >
                    <LargeCheckbox
                      checked={selectedRepresentatives.some(
                        (listItem) => listItem === child?.id
                      )}
                      size={15}
                      sx={{ p: "5px" }}
                      formSx={{ mr: "5px" }}
                    />
                    <ListItemText
                      primaryTypographyProps={{
                        sx: { fontSize: 12 },
                        noWrap: true,
                      }}
                    >
                      {child?.name || child?.tag}
                    </ListItemText>
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem disabled value="">
                No reps available
              </MenuItem>
            )}
          </StyledMultipleSelect>
        </Box>
      </StyledTooltip>

      {/* Customers Filter */}
      <StyledTooltip title="Select Customers" placement="top">
        <Box width="33.3%" maxWidth="192px">
          <Button
            disableRipple
            fullWidth
            endIcon={<ExpandMore sx={cl.buttonIconSx} />}
            onClick={() => setIsCustomerPopupOpen(true)}
            sx={cl.buttonSx}
          >
            {customersSelectRenderValue}
          </Button>
        </Box>
      </StyledTooltip>
    </Stack>
  );
};
