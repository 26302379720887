export const cl = {
  startAdornment: ({ error }) => ({
    color: "#707070 !important",
    borderRadius: "4px 0 0 4px",
    borderColor: error ? "#EB4233!important" : "#D5D9D9!important",
    minWidth: "24px",
    width: "24px",
    p: 0,
    backgroundColor: "#F7F7F7",
    height: "100%",
    borderTop: "none",
    borderBottom: "none",
    "&.Mui-disabled": {
      borderLeft: "1px solid #D5D9D9",
      opacity: 0.75,
      color: "#70707050 !important",
      "& .MuiTypography-root": {
        color: "#70707050 !important",
      },
    },
    "&:hover": {
      borderTop: "none",
      borderBottom: "none",
      borderColor: error ? "#EB4233!important" : "#D5D9D9!important",
    },
    "& .MuiButton-startIcon": {
      marginLeft: 0,
      marginRight: 0,
    },
  }),
  endAdornment: ({ error }) => ({
    color: "#707070 !important",
    borderRadius: "0 4px 4px 0",
    borderColor: error ? "#EB4233!important" : "#D5D9D9!important",
    minWidth: "24px",
    width: "24px",
    p: 0,
    backgroundColor: "#F7F7F7",
    height: "100%",
    borderTop: "none",
    borderBottom: "none",
    "&.Mui-disabled": {
      borderRight: "1px solid #D5D9D9",
      opacity: 0.75,
      color: "#70707050 !important",
      "& .MuiTypography-root": {
        color: "#70707050 !important",
      },
    },
    "&:hover": {
      borderTop: "none",
      borderBottom: "none",
      borderColor: error ? "#EB4233!important" : "#D5D9D9!important",
    },
  }),
  inputPropsSx: ({ error }) => ({
    height: "24px",
    borderRadius: "4px",
    fontSize: "9px",
    p: 0,
    "& fieldset": {
      borderColor: error ? "#EB4233!important" : "#D5D9D9!important",
      borderRight: "none",
      borderWidth: "1px!important",
      p: 0,
    },
    "& > input": {
      p: 0,
      textAlign: "center",
    },
  }),
};
