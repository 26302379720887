import { useAdmin } from "helpers/helpers";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { doc, getFirestore, writeBatch } from "firebase/firestore";
import {
  archiveRepsAction,
  deleteUserAction,
  resetRepsAction,
  transferDeleteRepsAction,
} from "redux/actions/reps";
import { createSelector } from "reselect";
import { useNewRepresentativesFilter } from "./components/NewRepresentativesFilter/NewRepresentativesFilter.hooks";
import {
  repsShowInactiveSelector,
  merchandisersCountSelector,
  salesCountSelector,
  thirdPartyRepsCountSelector,
} from "redux/selectors/reps";
import { Divider, Stack, Typography } from "@mui/material";
import { useStyles } from "../../components/TableHeaderControlPanel/styles";
import { ActionRestrictionWrapper, StyledTooltip } from "components";
import { reportsList } from "Pages/ReportsPage/ReportsPage.constants";
import { useRepsPermissions } from "helpers/hooks";
import {
  ADMIN_ONLY_DELETE_REPS,
  ADMIN_ONLY_VIEW_MESSAGE,
  ADMIN_ONLY_VIEW_MESSAGE_PAGE,
} from "utils/constants";

const selector = createSelector(
  repsShowInactiveSelector,
  merchandisersCountSelector,
  salesCountSelector,
  thirdPartyRepsCountSelector,

  (showInactive, merchandisersCount, salesCount, thirdPartyCount) => ({
    showInactive,
    merchandisersCount,
    salesCount,
    thirdPartyCount,
  })
);

export const useRepsActions = ({
  checkedUsers,
  setCheckedUsers,
  currentTab,
}) => {
  const classes = useStyles();
  const isAdmin = useAdmin();
  const dispatch = useDispatch();
  const repPermissions = useRepsPermissions();

  const { showInactive, merchandisersCount, salesCount, thirdPartyCount } =
    useSelector(selector);

  const [transferOpen, setTransferOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isOpenReportDialog, setOpenReportDialog] = useState(false);
  const [currentReport, setCurrentReport] = useState(null);
  const [confirmText, setConfirmText] = useState({
    title: "",
    text: "",
    confirmColor: "",
    onAccept: () => {},
  });

  const handleReport = useCallback((report) => {
    setCurrentReport(report);
    setOpenReportDialog(true);
  }, []);

  const handleCloseReportDialog = useCallback(() => {
    setOpenReportDialog(false);
    setCurrentReport(null);
  }, []);

  const reportTab = useMemo(
    () => (currentTab === "3rd Party" ? "All" : currentTab),
    [currentTab]
  );

  const filterUsers = useMemo(
    () => checkedUsers.filter((rep) => rep.role !== "THIRD_PARTY"),
    [checkedUsers]
  );

  const reportData = useMemo(() => {
    return {
      ...reportsList[3].reports[1],
      selectAndReorderColumnsBottom:
        reportsList[3].reports[1].selectAndReorderColumnsBottom.map(
          (field) => ({
            ...field,
            chosen: true,
            selected: true,
          })
        ),
      config: { ...reportsList[3].config },
      groupBy: currentTab === "Sales" ? "Sales reps" : reportTab,
      groupByIds: currentTab === "All" ? [] : filterUsers,
    };
  }, [reportTab, filterUsers, currentTab]);

  const { separateSelectedUsers, isDisabledResetBtn, setTextConfirmReset } =
    useNewRepresentativesFilter({ checkedUsers });

  const handleOpenConfirmDialog = ({ title, text, confirmColor, onAccept }) => {
    setConfirmDialogOpen(true);
    setConfirmText({
      title,
      text,
      confirmColor,
      onAccept,
    });
  };

  const deleteRepsWithStatus = useCallback(({ reps, deleteAction }) => {
    const ids = reps.map((r) => r?.id);
    const duplicateIds = reps?.map((r) => r?.duplicate?.id);
    deleteAction(ids);
    const batch = writeBatch(getFirestore());
    duplicateIds.forEach((id) => {
      const statusRef = doc(getFirestore(), `online-status/${id}`);
      batch.delete(statusRef);
    });
    batch.commit();
  }, []);

  const handleDeleteReps = useCallback(() => {
    deleteRepsWithStatus({
      reps: checkedUsers,
      deleteAction: (ids) => dispatch(deleteUserAction(ids)),
    });
    setConfirmDialogOpen(false);
    setCheckedUsers([]);
  }, [checkedUsers, deleteRepsWithStatus, dispatch, setCheckedUsers]);

  const handleConfirmDeleteTransfer = async (sales, merch, thirdParty) => {
    setCheckedUsers([]);
    const salesIds = sales.map((s) => s.id);
    const merchIds = merch.map((m) => m.id);
    const thirdPartyIds = thirdParty.map((m) => m.id);

    handleDeleteReps();

    deleteRepsWithStatus({
      reps: checkedUsers,
      deleteAction: (oldRepresentativeIds) =>
        dispatch(
          transferDeleteRepsAction(
            {
              oldRepresentativeIds,
              newRepresentativeIds: [
                ...salesIds,
                ...merchIds,
                ...thirdPartyIds,
              ],
            },
            !showInactive,
            () => setTransferOpen(false)
          )
        ),
    });
  };

  const handleDeleteClick = useCallback(() => {
    const checkMerch = checkedUsers.filter((i) => i.role === "MERCHANDISER");
    const checkSales = checkedUsers.filter((i) => i.role === "SALES");
    const checkThirdPartyCount = checkedUsers.filter(
      (i) => i.role === "THIRD_PARTY"
    );
    const isLastMerch =
      !checkMerch.length || checkMerch.length === merchandisersCount;
    const isLastSales = !checkSales.length || checkSales.length === salesCount;
    const isLastThirdParty =
      !checkThirdPartyCount.length ||
      checkThirdPartyCount.length === thirdPartyCount;

    if (
      (isLastMerch && isLastSales && isLastThirdParty) ||
      !checkedUsers.some((r) => r._count.assignedCustomers > 0)
    )
      return handleOpenConfirmDialog({
        title: "Delete employees?",
        text: "Please confirm that you would like to delete the selected employee(s). Deleted employees cannot be restored.",
        confirmColor: "confirmDelete",
        onAccept: handleDeleteReps,
      });
    setTransferOpen(true);
  }, [
    checkedUsers,
    handleDeleteReps,
    merchandisersCount,
    salesCount,
    thirdPartyCount,
  ]);

  const handleResetRepsPassword = useCallback(() => {
    const { MERCHANDISER, SALES } = separateSelectedUsers;

    const getSelectedUsersWithoutThirdParty = [
      ...(MERCHANDISER ?? []),
      ...(SALES ?? []),
    ];
    const emails = getSelectedUsersWithoutThirdParty.map(
      (rep) => rep.baseUser.email
    );

    dispatch(resetRepsAction(emails));
    setConfirmDialogOpen(false);
    setCheckedUsers([]);
  }, [separateSelectedUsers, dispatch, setCheckedUsers]);

  const handleArchiveReps = useCallback(() => {
    const ids = checkedUsers.map((rep) => rep.id);
    dispatch(archiveRepsAction(ids, !checkedUsers[0].active));
    setConfirmDialogOpen(false);
    setCheckedUsers([]);
  }, [dispatch, checkedUsers, setCheckedUsers]);

  const isDisabledAction = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.representatives?.create_edit;
    }
  }, [repPermissions]);

  const isDisabledReportPermissions = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.reports?.view;
    }
  }, [repPermissions]);

  const QUICK_ACTIONS = useMemo(() => {
    if (!checkedUsers.length) return [];

    const isReportDisabled = checkedUsers?.every(
      (rep) => rep.role === "THIRD_PARTY"
    );

    return [
      {
        hasTooltip: true,
        label: "Reset Password",
        element: (
          <>
            <StyledTooltip
              placement="top"
              arrow
              title={
                isDisabledAction
                  ? ADMIN_ONLY_VIEW_MESSAGE_PAGE
                  : "Can't reset password for 3rd Party reps"
              }
              disableHoverListener={!(isDisabledResetBtn || isDisabledAction)}
            >
              <Stack direction="row" gap="11px" alignItems="center">
                <Typography
                  disabled={isDisabledAction || isDisabledResetBtn || isAdmin}
                  onClick={() =>
                    !(isDisabledAction || isDisabledResetBtn || isAdmin) &&
                    handleOpenConfirmDialog({
                      title: "Reset Password?",
                      text: setTextConfirmReset,
                      confirmColor: "primary",
                      onAccept: handleResetRepsPassword,
                    })
                  }
                  className={classes.actionItemText}
                  sx={{
                    cursor:
                      isDisabledAction || isDisabledResetBtn || isAdmin
                        ? "auto"
                        : "pointer",
                    opacity:
                      isDisabledAction || isDisabledResetBtn || isAdmin
                        ? 0.5
                        : 1,

                    "&:hover": {
                      textDecoration:
                        (isDisabledAction || isDisabledResetBtn || isAdmin) &&
                        "none !important",
                    },
                  }}
                >
                  Reset Password
                </Typography>
                <Divider orientation="vertical" className={classes.divider} />
              </Stack>
            </StyledTooltip>
          </>
        ),
        disabled: isDisabledAction || isDisabledResetBtn || isAdmin,
        onClick: () => {},
        show: true,
      },
      {
        label: "Report",
        // element: null,
        element: (
          <Stack direction="row" gap="26px" alignItems="center">
            <ActionRestrictionWrapper
              disableHoverListener={
                !(isDisabledAction || isDisabledReportPermissions)
              }
              title={ADMIN_ONLY_VIEW_MESSAGE}
            >
              <Typography
                onClick={() =>
                  !isDisabledAction &&
                  !isReportDisabled &&
                  !isAdmin &&
                  !isDisabledReportPermissions &&
                  handleReport(reportData)
                }
                className={classes.actionItemText}
                sx={{
                  cursor:
                    isDisabledAction ||
                    isReportDisabled ||
                    isAdmin ||
                    isDisabledReportPermissions
                      ? "auto"
                      : "pointer",
                  opacity:
                    isDisabledAction ||
                    isReportDisabled ||
                    isAdmin ||
                    isDisabledReportPermissions
                      ? 0.5
                      : 1,

                  "&:hover": {
                    textDecoration:
                      (isDisabledAction ||
                        isReportDisabled ||
                        isAdmin ||
                        isDisabledReportPermissions) &&
                      "none !important",
                  },
                }}
              >
                Report
              </Typography>
            </ActionRestrictionWrapper>
            <Divider orientation="vertical" className={classes.divider} />
          </Stack>
        ),
        disabled: isDisabledAction || isReportDisabled || isAdmin,
        onClick: () =>
          !isDisabledAction &&
          !isReportDisabled &&
          !isAdmin &&
          handleReport(reportData),
        show: !showInactive,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    checkedUsers,
    handleReport,
    handleResetRepsPassword,
    isDisabledAction,
    isDisabledResetBtn,
    reportData,
    setTextConfirmReset,
    showInactive,
  ]);

  const MULTIPLE_ACTIONS = useMemo(() => {
    if (!checkedUsers.length) return [];

    return [
      {
        label: "Restore",
        element: null,
        disabled: isDisabledAction || isAdmin,
        disabledPermissions: isDisabledAction,
        onClick: () =>
          !(isDisabledAction || isAdmin) &&
          handleOpenConfirmDialog({
            title: "Restore employees?",
            text: "Please confirm that you would like to restore the selected employee(s).",
            confirmColor: "confirmDelete",
            onAccept: handleArchiveReps,
          }),
        show: showInactive,
      },
      {
        label: "Archive",
        element: null,
        disabled: isDisabledAction || isAdmin,
        disabledPermissions: isDisabledAction,
        onClick: () =>
          !(isDisabledAction || isAdmin) &&
          handleOpenConfirmDialog({
            title: "Archive employees?",
            text: "Please confirm that you would like to archive the selected employee(s). Timesheets cannot be created for archived employees.",
            confirmColor: "confirmDelete",
            onAccept: handleArchiveReps,
          }),
        show: !showInactive,
      },
      {
        label: "Delete",
        element: null,
        disabled: !!repPermissions || isAdmin,
        disabledPermissions: {
          disabled: !!repPermissions,
          title: ADMIN_ONLY_DELETE_REPS,
        },
        onClick: () => !(isDisabledAction || isAdmin) && handleDeleteClick(),
        show: true,
        sx: {
          "& .MuiListItemText-root span": {
            color: "rgb(255, 98, 84) !important",
          },
        },
      },
    ];
  }, [
    checkedUsers.length,
    handleArchiveReps,
    handleDeleteClick,
    isAdmin,
    isDisabledAction,
    repPermissions,
    showInactive,
  ]);

  return {
    QUICK_ACTIONS,
    MULTIPLE_ACTIONS,
    confirmDialogOpen,
    confirmText,
    setConfirmDialogOpen,
    transferOpen,
    setTransferOpen,
    handleConfirmDeleteTransfer,
    isOpenReportDialog,
    handleCloseReportDialog,
    currentReport,
  };
};
