import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useMemo, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import ApplyDiscountPageTab from "../ApplyDiscountPageTab/ApplyDiscountPageTab";
import ItemDiscountPage from "./components/ItemDiscountPage/ItemDiscountPage";
import MSDPage from "./components/MSDPage/MSDPage";

const ApplyDiscountPage = () => {
  const { type } = useParams();
  const { state } = useLocation();
  const [withDiscount, setWithDiscount] = useState(0);
  const [msdSelected, setMsdSelected] = useState(false);

  const [handleClearAll, setHandleClearAll] = useState(null);

  const {
    contactId,
    manufacturers,
    customerId,
    customer,
    products: productsList,
    manufacturerDiscounts,
    isEdit,
    orderId,
    isDuplicate,
    isPayed,
    deliveryFee,
    manualDeliveryFee,
    createOrderType,
    discount,
    isDraftOrder,
    priceList,
    freeCaseList,
  } = useMemo(() => state, [state]);

  const pageContent = useMemo(() => {
    switch (type) {
      case "manufacturer":
        return (
          <MSDPage
            {...{
              contactId,
              manufacturers,
              customerId,
              customer,
              productsList,
              manufacturerDiscounts,
              isEdit,
              orderId,
              isDuplicate,
              isPayed,
              deliveryFee,
              manualDeliveryFee,
              setMsdSelected,
              setHandleClearAll,
              setWithDiscount,
              createOrderType,
              isDraftOrder,
              isPriceList: !!priceList,
              freeCaseList,
            }}
          />
        );
      case "item":
        return (
          <ItemDiscountPage
            {...{
              contactId,
              manufacturers,
              customerId,
              customer,
              productsList,
              setWithDiscount,
              setHandleClearAll,
              withDiscount,
              isEdit,
              orderId,
              isDuplicate,
              deliveryFee,
              manualDeliveryFee,
              createOrderType,
              isDraftOrder,
              isPriceList: !!priceList,
            }}
          />
        );
      default:
        return <></>;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, state, withDiscount]);

  const calculatedTotal = useMemo(
    () =>
      state.products?.reduce(
        (prevSum, currProd) =>
          prevSum +
          (currProd.wholesalePrice || currProd.price || 0) * currProd.quantity,
        0
      ),
    [state.products]
  );

  return (
    <Box>
      <ApplyDiscountPageTab
        isDisableBtn={withDiscount === 0 && !msdSelected}
        {...{
          type,
          customerId,
          customer,
          handleClearAll,
          productsList,
          orderId,
          isDuplicate,
          discount,
          deliveryFee,
          manualDeliveryFee,
          createOrderType,
          manufacturerDiscounts,
          isDraftOrder,
          isPriceList: !!priceList,
        }}
      />
      <Box
        sx={{
          backgroundColor: "rgba(66, 165, 127, 0.1)",
          height: "48px",
          borderTop: "0.5px solid #d5d9d9",
          display: "flex",
          justifyContent: "flex-end",
          p: "13px 32px 15px",
        }}
      >
        <Typography fontSize="12px" color="#1C1C19">
          Order total:
          <span style={{ fontWeight: "700" }}>
            {" "}
            ${calculatedTotal.toFixed(2)}
          </span>{" "}
          | with Discount:{" "}
          <span style={{ fontWeight: "700" }}>
            $
            {(calculatedTotal - withDiscount < 0
              ? 0
              : calculatedTotal - withDiscount
            ).toFixed(2)}
          </span>
        </Typography>
      </Box>
      {pageContent}
    </Box>
  );
};

export default ApplyDiscountPage;
