import { bool, func, object } from "prop-types";
import { Box, Typography } from "@mui/material";

import { LargeCheckbox } from "components/Checkboxes";
import TableHeader from "../../../../components/TableHeader";
import { ArrowDownIcon, ArrowUpIcon } from "components/Icons";
import { cl } from "./ProductList.styles";

const ProductListHeader = ({
  quickSort,
  handleSetSortBy,
  checkAllProducts,
  allProductsChecked,
}) => {
  const SORTABLE_COLUMNS = {
    PRODUCT: { sortableField: "sort_by_name" },
    CATEGORY: { sortableField: "sort_by_category" },
    MANUFACTURER: { sortableField: "sort_by_manufacturer" },
  };

  const TABLE_DATA = [
    {
      title: "",
      element: (
        <LargeCheckbox
          formSx={{ marginX: 0 }}
          size={22}
          adminIsAllowed
          checked={allProductsChecked}
          onChange={checkAllProducts}
          sx={{ p: 0 }}
        />
      ),
      styles: { textAlign: "center", ml: "20px" },
    },
    {
      title: "STATUS",
      styles: { width: "4.8%", ...cl.centredHeaderItem },
    },
    {
      title: "PRODUCT",
      styles: { width: "21.4%", ...cl.sortableHeaderItem },
    },
    {
      title: "MANUFACTURER",
      styles: { width: "11.2%", ...cl.sortableHeaderItem },
    },
    {
      title: "CATEGORY",
      styles: { width: "11%", ...cl.sortableHeaderItem },
    },
    {
      title: "DATE CREATED",
      sortable: false,
      styles: { width: "10.6%", ...cl.headerItem },
    },
    {
      title: "WHOLESALE PRICE",
      styles: { width: "10.3%", ...cl.headerItem },
    },
    {
      title: "ON HAND",
      styles: { width: "7.7%", ...cl.centredHeaderItem },
    },
    {
      title: "ALLOCATED",
      styles: { width: "7.7%", ...cl.centredHeaderItem },
    },
    {
      title: "AVAILABLE",
      styles: { width: "7.7%", ...cl.centredHeaderItem },
    },
  ];

  return (
    <TableHeader sx={{ borderRadius: 0, borderTop: "none" }}>
      {TABLE_DATA.map(({ styles, title, element }, index) => (
        <Box sx={styles} key={index}>
          {title && <Typography variant="header">{title}</Typography>}
          {element && element}

          {SORTABLE_COLUMNS[title] && (
            <Box
              sx={cl.arrowBox}
              onClick={() => {
                if (SORTABLE_COLUMNS[title])
                  return handleSetSortBy(SORTABLE_COLUMNS[title].sortableField);
              }}
            >
              {quickSort[SORTABLE_COLUMNS[title].sortableField] === "asc" ? (
                <ArrowDownIcon />
              ) : (
                <ArrowUpIcon />
              )}
            </Box>
          )}
        </Box>
      ))}
    </TableHeader>
  );
};
ProductListHeader.propTypes = {
  quickSort: object,
  handleSetSortBy: func,
  checkAllProducts: func,
  allProductsChecked: bool,
};
ProductListHeader.defaultProps = {
  allProductsChecked: false,
};

export default ProductListHeader;
