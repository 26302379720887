import { WEEK_DAYS } from "components/ReceivingHoursDrawer/ReceivingHoursDrawer.constants";

export const PAYMENT_TERM_TYPE = [
  { name: "Check", value: "CHECK" },
  { name: "Cash", value: "CASH" },
  { name: "Credit Card", value: "CREDIT_CARD" },
];

export const LIMIT_ADDED_TAGS = 30;

const STATUSES = [{ name: "Active", value: "ACTIVE" }];
const DEFAULT_TERRITORY = { name: "Uncategorized", value: "Uncategorized" };

export const defaultValues = {
  id: "",
  name: "",
  status: STATUSES[0].value,
  customId: "",
  addDisplayName: false,
  displayName: "",
  primaryPaymentMethod: "",
  paymentTermsId: "",
  paymentTermsDuplicate: null,
  percentDiscount: "",
  isASubBusiness: false,
  parentCustomerId: "",
  billParentGroup: false,
  territoryId: DEFAULT_TERRITORY.value,
  disableSubCustomer: false,
  proceededWithDuplicatedFields: false,
  email: "",
  website: "",
  phone: "",
  businessFax: "",
  shippingIsSame: true,
  billingAddress: {
    formatted_address: "",
    zip: "",
    city: "",
    state: "",
    street: "",
    appartement: "",
  },
  shippingAddress: {
    formatted_address: "",
    zip: "",
    city: "",
    state: "",
    street: "",
    appartement: "",
  },
  tagInput: "",
  selectedTagsList: [],
  licenses: [],
  weekDays: WEEK_DAYS,
  representatives: [],
  contacts: [],
  statusData: {
    isDisabled: false,
    message: "",
    showTooltip: false,
  },
  productCategories: [],
  rating: null,
  price: null,
  reviews: 0,
  categories: [],
  priceListId: "",
  showAllCategories: true,
  showAllProducts: true,
};
