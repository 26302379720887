import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  filtersWrapper: {
    backgroundColor: "#fff",
    height: "64px",
    border: "0.5px solid #D5D9D9",
    borderRadius: "4px 4px 0 0",
    padding: "12px",
    display: "flex",
    gap: "12px",
  },

  filterChip: {
    borderRadius: "4px",
    border: "0.7px solid #d5d9d9",
    backgroundColor: "#FAFAFB",
    height: "28px",
  },
}));

export default useStyles;
