import { isEqual } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { getFormsService } from "services/forms";
import { SCROLL_LIMIT } from "utils/constants";
import { error } from "utils/notifications";

const init = {
  loading: false,
  list: [],
  count: 0,
  searcheble: false,
};

export const useFormsActions = ({ params = {}, open = true } = {}) => {
  const [formsState, setFormsState] = useState(init);

  const paramRef = useRef(null);

  const [formsParams, setFormsParams] = useState({
    page: 1,
    limit: SCROLL_LIMIT,
    ...params,
  });
  const handleFetchForms = useCallback(
    async (page) => {
      if (!open || formsState.loading) return;

      setFormsState((prev) => ({ ...prev, loading: true }));

      const reqParams = {
        ...formsParams,
        page: page || formsParams.page,
      };

      if (isEqual(paramRef?.current, reqParams))
        return setFormsState((prev) => ({ ...prev, loading: false }));

      paramRef.current = reqParams;

      try {
        const res = await getFormsService(reqParams);

        const rows = res?.rows || [];
        const count = res?.count || 0;

        setFormsState((prev) => ({
          ...prev,
          list: page > 1 ? [...prev.list, ...rows] : rows,
          count: count,
          searcheble: !prev.searcheble ? count >= 10 : prev.searcheble,
        }));

        if (page) setFormsParams((prev) => ({ ...prev, page }));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong.");
      } finally {
        setFormsState((prev) => ({ ...prev, loading: false }));
      }
    },
    [formsParams, open, formsState.loading]
  );

  useEffect(() => {
    if (open) {
      handleFetchForms(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, formsParams.search]);

  return {
    ...formsState,
    setFormsParams,
    page: formsParams.page,
    handleFetchForms,
  };
};
