import { array, bool, func, object, string } from "prop-types";
import { Box, Stack, Typography } from "@mui/material";
import { LinearProgressComponent } from "../index";
import { useContext } from "react";
import { FormViewContext } from "Pages/FormViewPage/FormViewPage";
import { ScrollControlWrapper } from "components";

export const MultipleBlock = ({
  questions = [],
  question = {},
  // isDialog = false,
  isMultipleDialog = false,
  filterByMultipleName = null,
  setFilterByMultipleName = () => {},
}) => {
  const list = question?.sortedAnswers?.map((key) => {
    return {
      id: key,
      answer: key,
      count: Math.round(question?.answersPercent?.[key]),
    };
  });

  const { dialogPreviewContentData } = useContext(FormViewContext);

  const { handleOpenDialogPreviewContent } = dialogPreviewContentData;

  return (
    <Box pb="12px">
      <Box
        sx={{
          maxHeight: "467px",
          borderRadius: "6px",
          backgroundColor: "#F8F8F8",
        }}
      >
        <Box pt="16px" pl="16px">
          <Typography fontSize={13} fontWeight={700} color="#000">
            Answers options
          </Typography>
        </Box>
        <ScrollControlWrapper
          dataLength={questions?.length}
          id="multiple-block-list"
          maxHeight="432px"
          // hideScroll={isDialog}
        >
          <Stack sx={{ px: "8px", pb: "8px" }}>
            {list?.map((q, i) => (
              <LinearProgressComponent
                key={q?.id}
                index={i}
                title={q?.answer}
                value={q?.count}
                // isDialog={isDialog}
                isChecked={filterByMultipleName === q?.answer}
                onClick={() => {
                  // if (isDialog) return;
                  if (isMultipleDialog) {
                    setFilterByMultipleName((prev) => {
                      if (prev === q?.answer) return null;
                      return q?.answer;
                    });
                    return;
                  }

                  handleOpenDialogPreviewContent({
                    item: {
                      ...question,
                      filterName: q?.answer,
                    },
                  });
                }}
              />
            ))}
          </Stack>
        </ScrollControlWrapper>
      </Box>
    </Box>
  );
};

MultipleBlock.propTypes = {
  questions: array,
  question: object,
  // isDialog: bool,
  isMultipleDialog: bool,
  filterByMultipleName: string,
  setFilterByMultipleName: func,
};
