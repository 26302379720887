import { createSelector } from "reselect";

export const galleryListSelector = (state) => state.gallery.gallery;
export const galleryCountSelector = (state) => state.gallery.count;
export const galleryShowingCountSelector = (state) =>
  state.gallery.showingCount;
export const galleryLoadingSelector = (state) => state.gallery.loading;
export const pictureItemLoadingSelector = (state) =>
  state.gallery.pictureLoading;

export const pictureItemSelector = (state) => state.gallery.pictureItem;

export const galleryGetParamsSelector = (state) =>
  state.gallery.galleryGetParams;

export const currentGalleryItemSelector = createSelector(
  galleryListSelector,
  pictureItemSelector,
  (galleryList, pictureId) => {
    return galleryList.find((gallery) => gallery.id === pictureId);
  }
);

export const repsWithPhotoSelector = (state) => state.gallery.repsWithPhoto;
export const repsWithPhotoParamsSelector = (state) =>
  state.gallery.repsWithPhotoParams;
export const repsWithPhotoCountSelector = (state) =>
  state.gallery.repsWithPhotoCount;
export const groupIdsSelector = (state) => state.gallery.groupIds;

export const existGalleryDataSelector = (state) => state.gallery.existData;
