/* eslint-disable no-useless-escape */
import * as Yup from "yup";
export const validationSchema = () =>
  Yup.object().shape({
    shop: Yup.string()
      .required()
      .required("Shop is required")
      .test({
        name: "shopValidation",
        test: (value, ctx) => {
          const shopRegexp = /^[a-zA-Z0-9][a-zA-Z0-9\-]*\.myshopify\.com/;
          if (!shopRegexp.test(value)) {
            return ctx.createError({
              message: `Shop with value ${value} fails to match the required pattern: /^[a-zA-Z0-9][a-zA-Z0-9\\-]*\\.myshopify\\.com/`,
            });
          }
          return true;
        },
      }),
  });
