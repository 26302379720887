import React from "react";
import { bool, func, object } from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Button,
} from "@mui/material";
import { CrossIcon } from "components/Icons";
import { RecurringTaskContent } from "./RecurringTaskContent";

export const ConfirmTaskDialog = ({
  isOpen,
  //isRepeatedTask,
  onClose,
  handleSaveTask,
  formField,
  loading,
  setDialogState,
  handleConfirmDelete,
  isDeleting,
  setValue,
}) => {
  return (
    <Dialog open={isOpen} scroll="body">
      <DialogTitle
        sx={{
          fontSize: "20px",
          color: "#080808",
          fontWeight: 400,
          p: "12px 30px 10px 30px",
          m: "0 0 1 0",
          position: "relative",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          sx={{ justifyContent: !isDeleting && "center" }}
        >
          {isDeleting ? "Delete repeating task?" : "Edit repeating task?"}
        </Box>
        <IconButton
          sx={{ position: "absolute", right: 4, top: 5 }}
          onClick={onClose}
        >
          <CrossIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          p: "16px 25px 20px 30px",
          maxWidth: "484px",
          minWidth: "420px",
          textAlign: !isDeleting && "center",
          borderBottom: "0.5px solid #D5D9D9",
        }}
      >
        <Box>
          <RecurringTaskContent
            setValue={setValue}
            formField={formField}
            isDeleting={isDeleting}
          />
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "14px 25px 15px 30px",
        }}
      >
        <>
          <Button
            sx={{
              width: `${isDeleting ? "110px" : "98px"}`,
              color: "#6A6A6A",
              borderColor: "#D4D4D4",
              fontSize: "13px",
              height: "28px",
            }}
            onClick={() => {
              setDialogState((prev) => ({ ...prev, isOpen: false }));
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          {isDeleting ? (
            <Button
              sx={{
                width: "110px",
                color: "#FFFFFF",
                fontSize: "13px",
                height: "28px",
                boxShadow: "none",
              }}
              color="primary"
              variant="contained"
              disabled={loading}
              onClick={() =>
                handleConfirmDelete({
                  data: formField,
                  //isRepeatedTask
                })
              }
            >
              Delete Task
            </Button>
          ) : (
            <Button
              sx={{
                width: "98px",
                color: "#FFFFFF",
                fontSize: "13px",
                height: "28px",
                boxShadow: "none",
              }}
              onClick={() =>
                handleSaveTask(
                  formField
                  //isRepeatedTask
                )
              }
              color="primary"
              disabled={loading}
              variant="contained"
            >
              Save
            </Button>
          )}
        </>
      </DialogActions>
    </Dialog>
  );
};

ConfirmTaskDialog.propTypes = {
  isOpen: bool,
  onClose: func,
  handleSaveTask: func,
  formField: object,
  loading: bool,
  //isRepeatedTask: bool,
  setDialogState: func,
  handleConfirmDelete: func,
  isDeleting: bool,
  setValue: func,
};

ConfirmTaskDialog.defaultProps = {
  isOpen: false,
  title: "",
  centered: false,
  text: "Are you sure?",
  buttons: null,
  onClose: () => {},
};
