/* eslint-disable no-useless-escape */
import * as Yup from "yup";
import {
  MAX_CHARACTERS_DESCRIPTIONS,
  MAX_CHARACTERS_TITLE,
} from "./TaskDrawer.constants";
import { isValidNotPastDate } from "helpers/helpers";

export const validationSchema = ({ isAdvancedDrawer = false, timeZone }) =>
  Yup.object().shape({
    title: Yup.string()
      .trim()
      .required("This field can't be empty")
      .max(MAX_CHARACTERS_TITLE, `Max ${MAX_CHARACTERS_TITLE} characters`),
    description: Yup.string()
      .trim()
      .max(
        MAX_CHARACTERS_DESCRIPTIONS,
        `Max ${MAX_CHARACTERS_DESCRIPTIONS} characters`
      ),
    representative: Yup.string().trim().required("This field is required"),
    selectedCustomersCount: Yup.number().test(
      "selectedCustomersCount",
      "At least one customer should be selected",
      (value) => (isAdvancedDrawer ? value > 0 : true)
    ),
    dueDate: Yup.string().test("dueDate", "Invalid date", (value) => {
      if (!value) return true;
      return isValidNotPastDate(value, timeZone);
    }),
  });
