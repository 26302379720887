import { bool, number, shape, string } from "prop-types";
import moment from "moment-timezone";

import {
  Box,
  Collapse,
  Divider,
  Grid,
  Link,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { ExpandCircleDown, Adjust } from "@mui/icons-material";
import {
  TriangleDownIcon,
  TriangleRightIcon,
} from "../../../../../../components/Icons";

import { preparedType } from "./Activity.helps";
import ActivityMoreInfo from "./ActivityMoreInfo";
import { formatDate } from "../../../../../../helpers/helpers";
import { useActivityItemActions } from "./useActivityItemActions";

const cl = {
  type: {
    position: "absolute",
    top: "50%",
    right: "50%",
    marginTop: "-12px",
    marginRight: "-12px",
    color: "#47A06D",
    fontSize: 24,
  },
};

const ActivityItem = ({ activity, index, lastItem, timeZone, order }) => {
  const activityState = useActivityItemActions({ activity, order });

  const { isInfoShow, toggleExpandInfo, isMoreInformation, setName } =
    activityState;

  const formattedDate = formatDate({
    utc_date: activity?.createdAt,
    formatThisYear: "MMM DD [at] h:mm a",
  });

  return (
    <Stack direction="row" pr={2} height="fit-content" width="100%">
      {!(index === 0 && lastItem) ? (
        <Grid sx={{ position: "relative" }} xs={1.2} item container>
          {activity?.type === "ORDER_CREATED" ? (
            <Adjust sx={cl.type} />
          ) : (
            <ExpandCircleDown sx={cl.type} />
          )}
          <Grid justifyContent="center" xs={12} item container>
            <Divider sx={{ borderStyle: "dashed" }} orientation="vertical" />
          </Grid>
          <Grid justifyContent="center" xs={12} item container>
            {!lastItem && (
              <Divider sx={{ borderStyle: "dashed" }} orientation="vertical" />
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid position="relative" xs={1.2} item container>
          <Adjust sx={{ ...cl.type, marginTop: "-18px" }} />
          <Grid
            sx={{ justifyContent: "center", height: "40%" }}
            xs={12}
            item
            container
          >
            <Divider sx={{ borderStyle: "dashed" }} orientation="vertical" />
          </Grid>
        </Grid>
      )}
      <Grid xs={10.8} item>
        <Paper
          pl="24px"
          borderColor="rgba(213, 217, 217, 0.3)"
          marginBottom={1}
          elevation={0}
          variant="outlined"
          component={Grid}
          container
        >
          <Grid
            position="relative"
            justifyContent="space-between"
            xs={12}
            item
            container
          >
            <Box
              pt={isMoreInformation ? "10px" : 0}
              height="54px"
              display="flex"
              alignItems={isMoreInformation ? "flex-start" : "center"}
            >
              <Typography fontSize={16} color="#000">
                {preparedType(
                  activity?.type,
                  activity?.operationType,
                  activity?.data
                )}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                minWidth: "170px",
                width: "170px",
              }}
            >
              <Typography fontSize={14} color="#000000" noWrap>
                {["ORDER_DIRECT", "QUICKBOOKS"].includes(
                  activity?.createdByType
                )
                  ? "Via "
                  : "By "}
                <Box sx={{ color: "#47A06D" }} component="span">
                  {setName}
                </Box>
              </Typography>
              <Typography
                fontSize={14}
                color="#000000"
                sx={{ opacity: "30%" }}
                component={Box}
              >
                {moment(activity?.createdAt).tz(timeZone).format(formattedDate)}
              </Typography>
            </Box>
            <Box sx={{ position: "absolute", top: 24 }}>
              {isMoreInformation && (
                <Link
                  sx={{
                    fontSize: "10px",
                    fontWeight: 400,
                    color: "#000",
                  }}
                  component="button"
                  underline="hover"
                  onClick={toggleExpandInfo}
                >
                  {isInfoShow ? <TriangleDownIcon /> : <TriangleRightIcon />}
                  <Box sx={{ marginRight: "4px" }} component="span" />
                  More information
                </Link>
              )}
            </Box>
          </Grid>
          <Grid xs={12} item>
            <Collapse in={isInfoShow}>
              <ActivityMoreInfo activity={activity} timeZone={timeZone} />
              <Box pb={1} />
            </Collapse>
          </Grid>
        </Paper>
      </Grid>
    </Stack>
  );
};
ActivityItem.propTypes = {
  index: number,
  lastItem: bool,
  timeZone: string,
  activity: shape({
    type: string,
    operationType: string,
    createdAt: string,
  }),
  order: shape({
    customer: shape({
      name: string,
    }),
  }),
};

export default ActivityItem;
