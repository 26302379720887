import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { currentUserSelector } from "redux/selectors/auth";
import { formsFilterAction } from "redux/actions/forms";
import { useRepsPermissions } from "helpers/hooks";
import { TABS_PAGE } from "../TasksPage.constants";
import { handleError, useAdmin } from "helpers/helpers";
import { error, success } from "utils/notifications";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";
import { Box, Typography } from "@mui/material";
import { bulkFormsActivationService, deleteFormsService } from "services/forms";
import pluralize from "pluralize";

const selector = createSelector(currentUserSelector, (currentUser) => ({
  currentUser,
}));

export const useFormsPage = ({ formsState }) => {
  const isAdmin = useAdmin();
  const dispatch = useDispatch();
  const { currentUser } = useSelector(selector);
  const repPermissions = useRepsPermissions();

  const {
    formsList,
    formsFilter,
    checkedForms,
    setCheckedForms,
    allFormsChecked,
    setAllFormsChecked,
    setFormsState,
  } = formsState || {};

  const [currentTab, setCurrentTab] = useState(TABS_PAGE[0].value);

  const handleChangeCurrentTab = (_, tab) => {
    setCurrentTab(tab);
  };

  const handleCheckForm = (formId) => {
    const formIndex = checkedForms.findIndex(
      (checkedForm) => checkedForm === formId
    );
    if (formIndex > -1) {
      const newForms = [...checkedForms];
      newForms.splice(formIndex, 1);
      return setCheckedForms([...newForms]);
    }

    setCheckedForms([...checkedForms, formId]);
  };

  const handleUncheckAllForms = useCallback(() => {
    setAllFormsChecked(false);
    setCheckedForms([]);
  }, [setAllFormsChecked, setCheckedForms]);

  const checkAllForms = useCallback(() => {
    dispatch(
      formsFilterAction({ ...formsFilter, page: undefined, limit: undefined })
    );
  }, [dispatch, formsFilter]);

  const handleSelectAvailableForms = useCallback(() => {
    if (formsList?.length === checkedForms?.length) {
      handleUncheckAllForms();
      return;
    }

    setCheckedForms(formsList);
  }, [checkedForms?.length, handleUncheckAllForms, setCheckedForms, formsList]);

  const handleSwitchStatusForms = useCallback(
    async (form) => {
      const preparedFormsIds = form?.id
        ? [form.id]
        : checkedForms.map((form) => form?.id);

      const isActive = formsFilter?.active;
      const switchText = isActive ? "Deactivated" : "Activated";

      if (!preparedFormsIds.length)
        return error(`Nothing to mark as ${switchText}`);

      try {
        setFormsState((prev) => ({ ...prev, loadingFormsList: true }));

        await bulkFormsActivationService({
          formsIds: preparedFormsIds,
          active: !isActive,
        });

        dispatch(formsFilterAction({ ...formsFilter, page: 1 }));
        setCheckedForms([]);
        success(`${pluralize("Form", preparedFormsIds.length)} ${switchText}`);
      } catch (error) {
        handleError(error);
      } finally {
        setFormsState((prev) => ({ ...prev, loadingFormsList: false }));
      }
    },
    [checkedForms, dispatch, formsFilter, setCheckedForms, setFormsState]
  );

  const handleBulkDeleteForms = useCallback(
    async (form) => {
      const preparedFormsIds = form?.id
        ? [form.id]
        : checkedForms.map((form) => form?.id);

      if (!preparedFormsIds.length) return error("Nothing to delete");

      try {
        setFormsState((prev) => ({ ...prev, loadingFormsList: true }));

        await deleteFormsService({ formsIds: preparedFormsIds });

        dispatch(formsFilterAction({ ...formsFilter, page: 1 }));
        setCheckedForms([]);
        success(
          `${
            preparedFormsIds.length > 1 ? preparedFormsIds.length : ""
          } ${pluralize("Form", preparedFormsIds.length)} Deleted`
        );
      } catch (error) {
        handleError(error);
      } finally {
        setFormsState((prev) => ({ ...prev, loadingFormsList: false }));
      }
    },
    [checkedForms, dispatch, formsFilter, setCheckedForms, setFormsState]
  );

  const handleDeleteWarning = (onConfirm, item) => {
    const isSingle = !!item;

    const confirmText = "Yes";

    dispatch(
      openConfirmDialogAction({
        isIcon: true,
        title: "Confirm Form Deletion",
        text: (
          <Typography>
            {isSingle ? (
              <Box
                component="span"
                sx={{ fontSize: 13, fontWeight: 300, color: "#363531" }}
              >
                This action will permanently delete the form and cannot be
                undone. Please type{" "}
                <Box
                  component="span"
                  sx={{ fontSize: 13, fontWeight: 500, color: "#363531" }}
                >
                  &apos;{confirmText}&apos;
                </Box>{" "}
                to confirm.
              </Box>
            ) : (
              <Box
                component="span"
                sx={{ fontSize: 13, fontWeight: 300, color: "#363531" }}
              >
                This action will permanently delete {checkedForms?.length} forms
                and cannot be undone. Please type{" "}
                <Box
                  component="span"
                  sx={{ fontSize: 13, fontWeight: 500, color: "#363531" }}
                >
                  &apos;{confirmText}&apos;
                </Box>{" "}
                to confirm.
              </Box>
            )}
          </Typography>
        ),
        confirmData: {
          value: confirmText,
          props: {
            sx: { "& .MuiOutlinedInput-root": { height: "40px" } },
            InputProps: { style: { fontSize: 13, color: "#707070" } },
            InputLabelProps: { sx: { fontSize: 13, color: "#D9D9D9", top: 3 } },
            placeholder: "Type “Yes”",
            fullWidth: true,
          },
        },
        dialogStyles: { maxWidth: "420px" },
        propBtns: {
          left: {
            sx: {
              fontSize: "12px",
              width: "110px",
              height: "28px",
            },
            onClick: () => {
              dispatch(setConfirmIsOpenAction(false));
            },
            variant: "outlined",
            color: "cancel",
            label: "Cancel",
          },

          right: {
            sx: {
              color: "#FFFFFF",
              fontSize: "12px",
              width: "110px",
              height: "28px",
              boxShadow: "none",
              "&.Mui-disabled": {
                color: "#FFF",
                bgcolor: ({ palette }) =>
                  `${palette.primary.main}50 !important`,
              },
            },
            onClick: () => {
              onConfirm();
              dispatch(setConfirmIsOpenAction(false));
            },
            variant: "contained",
            label: "Confirm",
          },
        },
      })
    );
  };

  const handleFetchList = () =>
    dispatch(formsFilterAction({ ...formsFilter, page: 1 }));

  useEffect(() => {
    if (formsList?.length && formsList?.length === checkedForms?.length) {
      setAllFormsChecked(true);
    } else {
      setAllFormsChecked(false);
    }
  }, [checkedForms?.length, setAllFormsChecked, formsList?.length]);

  return {
    TABS_PAGE,
    currentTab,
    handleChangeCurrentTab,
    currentUser,
    checkedForms,
    handleCheckForm,
    allFormsChecked,
    setAllFormsChecked,
    checkAllForms,
    repPermissions,
    handleFetchList,
    handleUncheckAllForms,
    handleSelectAvailableForms,
    handleBulkDeleteForms: (item) =>
      handleDeleteWarning(
        () => handleBulkDeleteForms(item),
        item ? item : checkedForms?.length === 1 ? checkedForms[0] : null
      ),
    handleSwitchStatusForms,
    isAdmin,
  };
};
