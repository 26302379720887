import { bool, object } from "prop-types";
import { Controller } from "react-hook-form";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { IOSSwitch } from "components";
import useStyles from "../../styles";

export const InvoicesTab = ({ isAdmin = false, control = {} }) => {
  const classes = useStyles();

  const options = [
    {
      title: "Signature",
      subTitle: "Use electronic signatures on invoices",
      onChange: ({ e, field }) => field.onChange(e),
      name: "signatureOnOrders",
      disabled: isAdmin,
    },
    {
      title: "Past due balance",
      subTitle: "Show past due balance on every invoice",
      onChange: ({ e, field }) => field.onChange(e),
      name: "pastDueBalanceInInvoices",
      disabled: isAdmin,
    },
    {
      title: "Condensed invoice",
      subTitle: "Remove all product images from the printed invoices",
      onChange: ({ e, field }) => field.onChange(e),
      name: "useCondensedInvoices",
      disabled: isAdmin,
    },
    {
      title: "Scannable barcode",
      subTitle: "Show scannable barcode on invoices",
      onChange: ({ e, field }) => field.onChange(e),
      name: "useScannableBarcodes",
      disabled: isAdmin,
    },
  ];

  return (
    <Box className={classes.ordersBody}>
      {options.map(({ name, title, subTitle, onChange, disabled }) => (
        <Paper key={title} className={classes.buttonBlock}>
          <Stack flexDirection="row" alignItems="center">
            <Stack>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <IOSSwitch
                    disabled={disabled}
                    label={
                      <Box className={classes.switchLabelBlock}>
                        <Typography className={classes.switchLabelFirst}>
                          {title}
                        </Typography>
                        <Typography className={classes.switchLabelSecond}>
                          {subTitle}
                        </Typography>
                      </Box>
                    }
                    checked={!!field.value}
                    formSx={{ flex: "0 0 34%", cursor: "default" }}
                    error={error ? error.message : ""}
                    {...field}
                    onChange={(e) => {
                      if (isAdmin) return;
                      !!onChange && onChange({ field, e });
                    }}
                  />
                )}
                name={name}
                control={control}
              />
            </Stack>
          </Stack>
        </Paper>
      ))}
    </Box>
  );
};

InvoicesTab.propTypes = {
  isAdmin: bool,
  control: object,
};
