export const cl = {
  wrapper: {
    width: "fit-content",
    "& .MuiCalendarPicker-root": {
      "& > div:first-of-type": {
        position: "relative",
        "& > div:first-of-type": {
          height: "100%",
          position: "absolute",
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
          "& button": {
            display: "none",
          },
        },

        "& > div:last-of-type": {
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          "& button": {
            color: "#409A65 !important",
          },
        },
      },

      "& .MuiTypography-root": {
        fontSize: 15,
        fontWeight: 500,
        color: "#409A65",
        width: "34px",
      },
    },

    "& .PrivatePickersSlideTransition-root": {
      minHeight: "240px",
      "& .MuiPickersDay-root": {
        width: "22px",
        height: "22px",
        m: "7.6px",
        fontSize: "15px",
        fontWeight: 500,
        // color: "#333",
      },
    },
  },

  yearBtns: {
    "& .PrivatePickersYear-yearButton": {
      "&.Mui-disabled": {
        color: "#BDBDBD !important",
      },
      "&.Mui-selected": {
        color: "#ffff !important",
      },
      color: "#333333 !important",
    },
  },
};
