import {
  ContentBlock,
  PageHeader,
  QuestionDeleteDialog,
  QuestionsPreview,
} from "./components";

import { Grid, Stack } from "@mui/material";
import { createContext } from "react";
import { useFormViewPage } from "./useFormViewPage";
import { useParams } from "react-router-dom";
import { useFormFields } from "./useFormFields";
import { Loader } from "components";
import { useResponsesTab } from "./useResponsesTab";
import { useVisibilitySettingsTab } from "./useVisibilitySettingsTab";
import { useDialogPreviewContent } from "./components/ContentBlock/components/ResponsesDetails/components/DialogPreviewContent/useDialogPreviewContent";
import { useDialogPreviewPhotos } from "./components/ContentBlock/components/ResponsesDetails/components/DialogPreviewPhotos/useDialogPreviewPhotos";
import { useFormViewResponsesPage } from "./useFormViewResponsesPage";
import { useSelector } from "react-redux";

export const FormViewContext = createContext();

const FormViewPage = () => {
  const currentUser = useSelector(({ auth }) => auth?.currentUser);

  const { id: formId } = useParams();

  const formFieldsData = useFormFields();

  const { reset, formField, handleSubmit, setValue, isDirty, clearErrors } =
    formFieldsData;

  const formState = useFormViewPage({
    formId,
    reset,
    formField,
    handleSubmit,
    setValue,
    isDirty,
    clearErrors,
  });

  const responsesTabState = useResponsesTab();
  const visibilitySettingsState = useVisibilitySettingsTab(
    formId,
    setValue,
    formField
  );

  const responsesState = useFormViewResponsesPage({
    open: formState?.currentTab === 1,
    formId,
    // limit_responses: 10,
  });

  const dialogPreviewContentData = useDialogPreviewContent();
  const dialogPreviewPhotosData = useDialogPreviewPhotos();

  return (
    <FormViewContext.Provider
      value={{
        currentUser,
        ...formState,

        ...formFieldsData,

        formId,

        ...responsesTabState,
        ...visibilitySettingsState,

        dialogPreviewContentData,
        dialogPreviewPhotosData,
        responsesState,
        setResponsesQueryParams: responsesState.setResponsesQueryParams,
      }}
    >
      <QuestionDeleteDialog
        open={formState.questionDeleteState.open}
        handleClose={formState.handleCloseQuestionDeleteDialog}
        handleSubmit={
          formState.questionDeleteState.isDeleteResponses
            ? formState.handleDeleteQuestion
            : formState.handleDeleteQuestionWithoutResponses
        }
        isDeleteResponses={formState.questionDeleteState.isDeleteResponses}
      />
      <PageHeader />

      {formState?.loading ? <Loader isLoading={formState?.loading} /> : null}

      <Grid pt="20px" pb="94px" px="40px" container>
        <Grid item xs>
          {formState?.currentTab === 0 && <QuestionsPreview />}
        </Grid>

        <Grid item xs="auto">
          <Stack direction="row">
            <ContentBlock />
          </Stack>
        </Grid>

        <Grid item xs />
      </Grid>
    </FormViewContext.Provider>
  );
};

export default FormViewPage;
