import { forwardRef, useRef } from "react";
import { bool, object, func, string, number } from "prop-types";
import { Controller } from "react-hook-form";
import { Box, MenuItem, Stack } from "@mui/material";
import {
  CloneProps,
  StyledSelect,
  StyledTextField,
  StyledTooltip,
} from "components";
import {
  generateUUID,
  normalizeCamelCaseWithSeparate,
  onPasteTextWithMaxLength,
  useAdmin,
} from "helpers/helpers";
import {
  FORM_TYPES,
  FORM_TYPES_SELECT,
  handleGetIcons,
  ICON_STYLES,
  MAX_CHARACTERS_QUESTIONS_TEXT,
} from "Pages/FormViewPage/FormViewPage.constants";
import { cl } from "../QuestionItem.styles";

export const TitleBlock = forwardRef(
  (
    {
      isActive,
      selectedType,
      setSelectedType,
      handleItemClick,
      orderIndex,
      control,
      setValue,
      formField,
      handleOpenChangeDialog,
      trigger,
    },
    selectRef
  ) => {
    const isAdmin = useAdmin();
    const currentQuestionResponse =
      formField?.questions?.[orderIndex]?._count?.responses || 0;
    const hasCurrentOptionsUsed = currentQuestionResponse > 0;

    const FORM_ICONS = handleGetIcons({
      singleProps: {
        firstRectFill: "#409A65",
        secondRectStroke: "#fff",
        lastRectFill: "#fff",
        strokeOpacity: 1,
      },
      multiProps: {
        rectFill: "#47A06E",
        width: 21,
        height: 21,
      },
    });

    const FORM_SELECT_ICONS = handleGetIcons();

    const onChangeTypes = (e) => {
      const { value } = e.target;
      setSelectedType(value);

      const tempList = [...formField?.questions];

      const type = value;
      const currentItem = {
        ...formField?.questions?.[orderIndex],
        type,
        ...([FORM_TYPES.SINGLE_ANSWER, FORM_TYPES.MULTIPLE_ANSWER].includes(
          type
        ) &&
          !!formField?.questions?.[orderIndex]?.options?.length && {
            options: [...formField?.questions?.[orderIndex]?.options],
          }),
        ...(type === FORM_TYPES.SINGLE_ANSWER &&
          !formField?.questions?.[orderIndex]?.options?.length && {
            options: [
              {
                title: "Option 1",
                uuid: generateUUID(),
                orderIndex: 0,
              },
              {
                title: "Option 2",
                uuid: generateUUID(),
                orderIndex: 1,
              },
            ],
          }),
        ...(type === FORM_TYPES.MULTIPLE_ANSWER &&
          !formField?.questions?.[orderIndex]?.options?.length && {
            options: [
              {
                title: "Answer 1",
                uuid: generateUUID(),
                orderIndex: 0,
              },
              {
                title: "Answer 2",
                uuid: generateUUID(),
                orderIndex: 1,
              },
            ],
          }),
      };

      tempList.splice(orderIndex, 1, currentItem);

      setValue("questions", tempList, { shouldDirty: true });
    };

    const errorMsgRef = useRef({});

    return (
      <Stack width="100%" flexDirection="row" alignItems="flex-end" gap="5px">
        <Controller
          render={({ field, fieldState: { error } }) => {
            const hasAlreadyAnswered =
              formField?.questions?.[orderIndex]?._count?.responses > 0;

            const autofill = formField?.questions?.[orderIndex]?.autofill;

            if (error?.message)
              errorMsgRef.current[`questions[${orderIndex}].text`] =
                error?.message;

            return (
              <CloneProps>
                {(clonedProps) => (
                  <StyledTooltip
                    arrow
                    placement="left"
                    isError
                    title={
                      errorMsgRef.current?.[`questions[${orderIndex}].text`]
                    }
                    open={!!error}
                    PopperProps={{
                      modifiers: [
                        { name: "offset", options: { offset: [0, -5] } },
                      ],
                    }}
                  >
                    <Box
                      width="100%"
                      height="fit-content"
                      display="flex"
                      alignItems="flex-end"
                    >
                      <StyledTextField
                        {...clonedProps}
                        fullWidth
                        onClick={(e) => {
                          if (!isActive) handleItemClick(e);

                          if (hasAlreadyAnswered && autofill) {
                            return handleOpenChangeDialog({
                              onSubmit: () => {
                                setValue(
                                  `questions[${orderIndex}].autofill`,
                                  false
                                );
                              },
                            });
                          }
                        }}
                        placeholder="Question title"
                        InputProps={{
                          multiline: true,
                          fullWidth: true,
                          sx: {
                            color: isActive ? "#595959" : "#565656",
                            ...cl.textFieldStyles,
                            ...(isActive && cl.activeTextFieldStyles),
                          },
                        }}
                        error={!!error}
                        {...field}
                        onChange={(e) => {
                          if (hasAlreadyAnswered && autofill) return;

                          const val = e.target.value;
                          if (
                            val?.length <=
                            MAX_CHARACTERS_QUESTIONS_TEXT + 1
                          ) {
                            field.onChange(val);
                            trigger(`questions[${orderIndex}].text`);
                          }
                        }}
                        onPaste={(e) => {
                          onPasteTextWithMaxLength({
                            event: e,
                            value: field.value,
                            onChange: (v) => {
                              field.onChange(v);
                              trigger(`questions[${orderIndex}].text`);
                            },
                            maxCharacters: MAX_CHARACTERS_QUESTIONS_TEXT,
                          });
                        }}
                      />
                    </Box>
                  </StyledTooltip>
                )}
              </CloneProps>
            );
          }}
          control={control}
          name={`questions[${orderIndex}].text`}
        />

        <Box>
          <StyledSelect
            disabled={hasCurrentOptionsUsed || isAdmin}
            fullWidth
            ref={selectRef}
            sx={cl.selectStyles}
            value={selectedType || FORM_TYPES_SELECT[0].value}
            formSx={cl.selectFormStyles}
            height="38px"
            onChange={onChangeTypes}
            PaperPropsSx={cl.selectPaperStyles}
            renderValue={(s) => (
              <Stack direction="row" alignItems="center" gap="6px">
                <Box
                  sx={{
                    ...cl.iconWrapper,
                    ...ICON_STYLES[s],
                    ...(hasCurrentOptionsUsed && {
                      background: "#C1C1C1 !important",
                      "& rect:nth-of-type(1)": {
                        fill: "#C1C1C1 !important",
                      },
                    }),
                  }}
                >
                  {FORM_ICONS[s]}
                </Box>
                {s === FORM_TYPES_SELECT[2].value
                  ? FORM_TYPES_SELECT[2].label
                  : normalizeCamelCaseWithSeparate(s)}
              </Stack>
            )}
          >
            {FORM_TYPES_SELECT.map(({ label, value, disabled }) => (
              <MenuItem
                key={value}
                value={value}
                disabled={!!disabled || isAdmin}
                sx={cl.selectMenuItem}
              >
                {FORM_SELECT_ICONS[value]}
                {label}
              </MenuItem>
            ))}
          </StyledSelect>
        </Box>
      </Stack>
    );
  }
);

TitleBlock.displayName = "TitleBlock";

TitleBlock.propTypes = {
  isActive: bool,
  selectedType: string,
  setSelectedType: func,
  setActiveQuestion: func,
  handleItemClick: func,
  clickAwayRef: object,
  orderIndex: number,
  control: object,
  setValue: func,
  formField: object,
  handleOpenChangeDialog: func,
  trigger: func,
};
