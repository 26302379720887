import { bool, func, object, string } from "prop-types";
import { Box, Menu, Stack } from "@mui/material";
import { Controller } from "react-hook-form";
import moment from "moment-timezone";
import { CalendarPicker, LocalizationProvider } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import { StyledButton, StyledDatePicker } from "components";
import useStyles from "Pages/CustomersPage/pages/CustomerProfilePage/components/TaskDrawer/styles";
import { CalendarIconNew } from "components/Icons";
import { cl } from "./DueDateBlock.styles";
import { useRef, useState } from "react";

export const DueDateBlock = ({
  formField = {},
  timeZone = "",
  setValue = () => {},
  control = {},
  isNotAllowedToEdit = false,
  isValidDate = () => {},
  setError = () => {},
  clearErrors = () => {},
  fieldName = "dueDate",
  label = "Due Date",
  inputLabelSx = {},
  showDefaultTime = true,
  skipTime = false,
  closeConfirm,
}) => {
  const classes = useStyles();

  const datePickerRef = useRef(null);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const handleCloseMenu = () => {
    if (closeConfirm) return closeConfirm(setAnchorElMenu(null));
    return setAnchorElMenu(null);
  };
  const incomingDateBeforeOpen = useRef(null);

  const handleSetDefaultDueTime = () => {
    if (skipTime) return;
    if (!formField.dueTime) setValue("dueTime", "08:00", { shouldDirty: true });
  };

  const handleChangeCalendarPicker = (date) => {
    const formattedDate = moment
      .tz(date, timeZone)
      .hours(0)
      .minutes(0)
      .seconds(0)
      .utc()
      .format();
    setValue(fieldName, formattedDate, { shouldDirty: true });
    handleSetDefaultDueTime();
    clearErrors(fieldName);
  };

  const handleCancel = () => {
    handleCloseMenu();
    setValue(fieldName, incomingDateBeforeOpen.current);
  };

  const handleChangeDatePicker = ({ time, keyboardVal, field }) => {
    let isoTime = "";
    if (keyboardVal) {
      if (!isValidDate(keyboardVal)) {
        return setError(fieldName, {
          type: "dueDateValidate",
          message: "Incorrect date",
        });
      } else {
        clearErrors(fieldName);
      }

      const formattedDate = moment
        .tz(keyboardVal, "MM/DD/YYYY", timeZone)
        .utc()
        .format();

      const isValidFormattedDate = moment(formattedDate).isValid();

      isoTime = isValidFormattedDate && formattedDate ? formattedDate : "";
    } else if (time) {
      isoTime = moment
        .tz(time, "MM/DD/YYYY", timeZone)
        .hours(0)
        .minutes(0)
        .seconds(0)
        .utc()
        .toISOString();
      clearErrors(fieldName);
    } else {
      if (!keyboardVal) {
        setValue(fieldName, null, { shouldDirty: false });
        clearErrors(fieldName);
        handleSetDefaultDueTime();
        return;
      }
    }
    clearErrors(fieldName);
    handleSetDefaultDueTime();
    field.onChange(isoTime);
  };

  const handleSetToday = () => {
    const dateNow = moment()
      .tz(timeZone)
      .hours(0)
      .minutes(0)
      .seconds(0)
      .utc()
      .format();
    setValue(fieldName, dateNow, { shouldDirty: true });
    handleCloseMenu();
    clearErrors(fieldName);
    handleSetDefaultDueTime();
  };

  return (
    <Box>
      <Menu
        sx={{
          "& .MuiList-root": { p: 0 },
          "& .MuiPaper-root": { marginTop: "8px" },
        }}
        anchorEl={anchorElMenu}
        open={!!anchorElMenu}
        onClose={handleCloseMenu}
      >
        <Box sx={cl.wrapper}>
          <Controller
            render={({ field }) => {
              const value = formField[fieldName]
                ? moment.tz(field.value, timeZone)
                : null;
              return (
                <Box sx={cl.yearBtns}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <CalendarPicker
                      disablePast
                      timezone={timeZone}
                      date={value}
                      onChange={(date) => handleChangeCalendarPicker(date)}
                    />
                  </LocalizationProvider>
                  <Stack
                    flexDirection="row"
                    justifyContent="flex-end"
                    sx={{ px: "8px", pb: "16px" }}
                  >
                    <StyledButton
                      sx={{ width: "64px", height: "28px" }}
                      fontWeight={500}
                      label="Cancel"
                      onClick={handleCancel}
                    />

                    <StyledButton
                      sx={{ width: "64px", height: "28px" }}
                      label="Today"
                      fontWeight={500}
                      onClick={handleSetToday}
                    />
                  </Stack>
                </Box>
              );
            }}
            name={fieldName}
            control={control}
          />
        </Box>
      </Menu>

      <Controller
        render={({ field, fieldState: { error } }) => {
          return (
            <StyledDatePicker
              dateLibInstance={moment}
              dateAdapter={AdapterMoment}
              value={
                !showDefaultTime && !field.value
                  ? null
                  : moment.tz(field.value, timeZone)
              }
              wrapperClassName={classes.calendarInput}
              textFieldProps={{
                fullWidth: true,
                noErrorMessage: true,
              }}
              inputSx={{ height: "44px" }}
              InputProps={{ style: { fontSize: 14 } }}
              disabled={isNotAllowedToEdit}
              inputProps={{ placeholder: label }}
              label={label}
              InputLabelProps={{
                sx: { fontSize: 14, top: 3, ...inputLabelSx },
              }}
              error={error?.message ? error.message : ""}
              onChange={(time, keyboardVal) =>
                handleChangeDatePicker({ time, keyboardVal, field })
              }
              components={{
                OpenPickerIcon: CalendarIconNew,
              }}
              inputRef={datePickerRef}
              open={false}
              onOpen={() => {
                setAnchorElMenu(datePickerRef.current);
                incomingDateBeforeOpen.current = formField[fieldName];
              }}
            />
          );
        }}
        name={fieldName}
        control={control}
      />
    </Box>
  );
};

DueDateBlock.propTypes = {
  formField: object,
  timeZone: string,
  fieldName: string,
  label: string,
  setValue: func,
  control: object,
  inputLabelSx: object,
  isNotAllowedToEdit: bool,
  showDefaultTime: bool,
  skipTime: bool,
  isValidDate: func,
  setError: func,
  clearErrors: func,
  closeConfirm: func,
};
