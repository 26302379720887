import { array, bool, func, number, object } from "prop-types";

import { CrossBigIcon } from "components/Icons";
import { cl } from "./styles";

import {
  Box,
  Dialog,
  IconButton,
  Typography,
  DialogContent,
} from "@mui/material";
import { useCustomerCategories } from "./useCustomerCategories";
import { CategoryContentBlock } from "./components/CategoryContentBlock";
import { CategoriesActionBlock } from "./components/CategoriesActionBlock";

export const CustomerCategoriesPopup = ({
  isOpen = false,
  handleClose = () => {},
  checkedList = null,
  onAdd = () => {},
  isEdit = false,
  incomingExcludeIds = [],
  selectedCategoriesCount = null,
  isAllUncheckedCategories = false,
  availableAssignCategories = [],
}) => {
  const {
    handleCheckCategory,
    handleIsAlreadyAdded,
    handleSetSort,
    quickSort,
    categoriesState,
    page,
    handleFetchCategories,
    handleCancel,
    expandParentId,
    setListSearch,
    searchInput,
    isCategorySelected,
    handleExpandChildrenList,
    isIntermediateSelected,
    selectCategoryWithChildren,
    checkedCount,
    totalCountOfElements,
    isLoading,
    handleSubmitCategories,
  } = useCustomerCategories({
    checkedList,
    isOpen,
    handleClose,
    onAdd,
    isEdit,
    incomingExcludeIds,
    selectedCategoriesCount,
    isAllUncheckedCategories,
    availableAssignCategories,
  });

  return (
    <Dialog
      open={isOpen}
      sx={{ overflow: "hidden" }}
      PaperProps={{ sx: cl.paperWrapper }}
    >
      <DialogContent sx={cl.dialogContentWrapper}>
        <Box display="flex" justifyContent="space-between" mb="24px">
          <Typography fontSize="30px" color="#707070" lineHeight="36px">
            Select Categories
          </Typography>
          <IconButton disableRipple onClick={handleClose} sx={cl.iconBtn}>
            <CrossBigIcon size={23} />
          </IconButton>
        </Box>

        <CategoryContentBlock
          {...{
            page,
            setListSearch,
            searchInput,
            quickSort,
            expandParentId,
            categoriesState,
            selectCategoryWithChildren,
            handleSetSort,
            handleCheckCategory,
            handleFetchCategories,
            handleIsAlreadyAdded,
            handleExpandChildrenList,
            isCategorySelected,
            isIntermediateSelected,
            checkedCount,
            totalCountOfElements,
            isLoading,
          }}
        />
      </DialogContent>

      <CategoriesActionBlock
        {...{
          handleCancel,
          disabledSaveBtn: isLoading,
          onAdd: handleSubmitCategories,
        }}
      />
    </Dialog>
  );
};

CustomerCategoriesPopup.propTypes = {
  isOpen: bool,
  isEdit: bool,
  handleClose: func,
  isAllUncheckedCategories: bool,
  checkedList: object,
  onAdd: func,
  incomingExcludeIds: array,
  availableAssignCategories: array,
  selectedCategoriesCount: number,
};
