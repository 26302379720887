import { createContext, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import CatalogSwitcherTab from "./components/CatalogSwitcherTab";
import ManufacturersTab from "./ManufacturersTab/ManufacturersTab";
import ProductsTab from "./ProductsTab";
import ProductTagsTab from "./ProductTagsTab";
import { useRepsPermissions } from "helpers/hooks";
import { PriceListTab } from "./PriceListTab";
import { usePriceListTab } from "./PriceListTab/usePriceListTab";
import { CategoriesTab } from "./CategoriesTab";
import { useCategoriesTab } from "./CategoriesTab/useCategoriesTab";

const catalogTabs = [
  "Products",
  "Categories",
  "Manufacturers",
  "Product Tags",
  "Price Lists",
];

export const PriceContext = createContext();
export const CategoriesContext = createContext();

const CatalogPage = () => {
  const [currentCatalogTab, setCurrentCatalogTab] = useState(catalogTabs[0]);
  const location = useLocation();
  const repPermissions = useRepsPermissions();
  const priceListData = usePriceListTab(currentCatalogTab);
  const categoriesData = useCategoriesTab();

  const handleSetCurrentTab = (tab) => {
    setCurrentCatalogTab(tab);
  };

  const renderContent = (currentTab, tabs) => {
    switch (currentTab) {
      case tabs[0]:
        return (
          <ProductsTab
            currentTab={currentCatalogTab}
            repPermissions={repPermissions}
          />
        );
      case tabs[1]:
        return <CategoriesTab />;
      case tabs[2]:
        return (
          <ManufacturersTab
            currentTab={currentCatalogTab}
            repPermissions={repPermissions}
          />
        );
      case tabs[3]:
        return (
          <ProductTagsTab
            currentTab={currentCatalogTab}
            repPermissions={repPermissions}
          />
        );
      case tabs[4]:
        return (
          <PriceListTab
            currentTab={currentCatalogTab}
            repPermissions={repPermissions}
          />
        );

      default:
        return (
          <ProductsTab
            currentTab={currentCatalogTab}
            repPermissions={repPermissions}
          />
        );
    }
  };

  useEffect(() => {
    if (typeof location.state === "string")
      setCurrentCatalogTab(location.state);
  }, [location.state]);

  return (
    <PriceContext.Provider value={{ ...priceListData, repPermissions }}>
      <CategoriesContext.Provider value={categoriesData}>
        <CatalogSwitcherTab
          tabs={catalogTabs}
          currentTab={currentCatalogTab}
          handleSetCurrentTab={handleSetCurrentTab}
          repPermissions={repPermissions}
        />
        {renderContent(
          location?.state && location?.state?.type !== "onboarding"
            ? location?.state?.currentTab || location?.state
            : currentCatalogTab,
          catalogTabs
        )}
        <Outlet />
      </CategoriesContext.Provider>
    </PriceContext.Provider>
  );
};

export default CatalogPage;
