import axios from "axios";

export const getRoutesService = async (params) => {
  return axios.get("routes", { params }).then((res) => res.data);
};

export const getAssignedRoutesService = async (id) => {
  return axios
    .get(`routes/assigned-and-available/${id}`)
    .then((res) => res.data);
};

export const getRouteByIdService = async (id) => {
  return axios.get(`routes/${id}`).then((res) => res.data);
};

export const getRouteProgressByIdService = async (id) => {
  return axios.get(`route-progresses/${id}`).then((res) => res.data);
};

export const getRouteSummariesService = async () => {
  return axios.get("routes/total-summaries").then((res) => res.data);
};

export const createRouteService = async (body) => {
  return axios.post("routes", body).then((res) => res.data);
};

export const updateRouteService = async (body, id) => {
  return axios.put(`routes/${id}`, body).then((res) => res.data);
};

export const bulkAssignRoutesService = async (id, body) => {
  return axios.put(`routes/bulk-assign/${id}`, body).then((res) => res.data);
};

export const bulkAssignRepresentativesService = async (body) => {
  return axios
    .put("routes/bulk-assign-representatives", body)
    .then((res) => res.data);
};

export const deleteRouteService = async (id) => {
  return axios.delete(`routes/${id}`).then((res) => res.data);
};

export const bulkDeleteRouteService = async (routesIds) => {
  return axios
    .delete("routes/bulk", {
      data: { routesIds },
    })
    .then((res) => res.data);
};

export const bulkDeactivateRouteService = async (data) => {
  return axios.put("routes/bulk-activation", data).then((res) => res.data);
};

export const checkRouteAvailabilityService = async (body) => {
  return axios
    .post("/routes/check-route-availability", body)
    .then((res) => res.data);
};
