import { useCallback, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Controller } from "react-hook-form";
import { bool, func, array, string, number, object } from "prop-types";

import useStyles from "../../styles";
import { useAdmin } from "helpers/helpers";
import { useOrderedItems } from "./useOrderedItems";
import { AddressCard } from "./components/AddressCard";
import { SecondStepTabs } from "./components/SecondStepTabs";
import { OrderedProductsPopup } from "../OrderedProductsPopup";
import { StyledButton, StyledSelect, StyledTextField } from "components";
import EditCustomerPopup from "Pages/OrdersPage/components/EditCustomerPopup/EditCustomerPopup";
import {
  CrossIcon,
  OutlinedPlusIcon,
  PenIcon,
  SearchIcon,
} from "components/Icons";

import { updateCustomerAction } from "redux/actions/customers";

import {
  Box,
  Grid,
  Paper,
  Stack,
  MenuItem,
  IconButton,
  Typography,
  useMediaQuery,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import { PriceListLabel } from "../ThirdCustomerStep/components";

export const SecondCustomerStep = ({
  formField,
  errors,
  orderCompleted,
  deliveryStatus,
  paymentStatus,
  control,
  handleCheckRemoveCustomer,
  isThirdParty,
  handleClickLogoClient,
  handleCloseLogoClient,
  setOpenPaymentTermModal,
  anchorEl,
  openHeaderList,
  paytermsListActive,
  handleTerms,
  thirdPartyRepsList,
  repsList,
  handleOpenRepsDialog,
  fetchThirdPartyReps,
  handleContactChange,
  defaultContactId,
  setContactOpen,
  setEditContact,
  customersSearchInput,
  customersLoading,
  setCustomersSearchInput,
  customersList,
  setAllCustomersOpen,
  productsRef,
  setCustomersList,
  customersDropDown,
  setIsFetchable,
  searchRef,
  setStateValue,
  clearErrors,
  checkSellingOutStock,
  priceListName,
  priceListLoading,
}) => {
  const isAdmin = useAdmin();
  const classes = useStyles();
  const dispatch = useDispatch();
  const showLongContactLabel = useMediaQuery("(min-width: 1800px)");

  const [addressTab, setAddressTab] = useState(0);
  const [hoveredElement, setHoveredElement] = useState(null);
  const [loadingCustomer, setLoadingCustomer] = useState(false);
  const [editCustomerOpen, setEditCustomerOpen] = useState(false);
  const [isOpenOrderedPopUp, setIsOpensetOrderedPopUp] = useState(false);

  const {
    loading,
    setValue,
    params,
    errors: orderedErrors,
    handleFetch: orderedProductsFetch,
    calcAvailable,
    getOutOfStock,
    handleAddProduct,
    handleCheckAddBtn,
    handleSetProductError,
    handleSubtractQty,
    handleIsAlreadyInCart,
    control: orderedProductsControl,
    formField: orderedProductsFields,
  } = useOrderedItems({
    isThirdParty,
    cartProducts: formField.products,
    allowFetch: formField.customer?.id,
    customer_id: formField.customer?.id,
  });

  const selectSalesIdRef = useRef(null);
  const selectContactIdRef = useRef(null);

  const setWidth = (ref) => {
    if (ref.current) {
      const width = ref.current.offsetWidth;
      return width;
    } else {
      return "180px";
    }
  };

  const handleAddProducts = () => {
    clearErrors("products");
    const filtredProducts = orderedProductsFields.products.filter(
      ({ quantity }) => quantity > 0
    );
    setStateValue("products", [...formField.products, ...filtredProducts]);
    setIsOpensetOrderedPopUp(false);
  };

  const showBtn = useMemo(
    () => !formField.customer?.billToParentCustomer,
    [formField.customer?.billToParentCustomer]
  );

  const TABLE_TABS = [
    {
      value: 0,
      label: "Ship to",
      type: "shipping address",
      var: "shippingAddress",
      tooltipText: "",
    },
    {
      value: 1,
      label: "Bill to",
      type: "billing address",
      var: "billingAddress",
      tooltipText: "",
    },
  ];

  const CUSTOMER_ADDRESS_INFO = useMemo(
    () => ({
      0: formField.customer?.shippingAddress,
      1: formField.customer?.billingAddress,
    }),
    [formField.customer]
  );

  const isListOpen = useMemo(
    () => (formField?.orderStatus === "CANCELED" ? false : openHeaderList),
    [formField?.orderStatus, openHeaderList]
  );

  const handleOpenEditCustomer = () => {
    setEditCustomerOpen(true);
  };

  const handleChangeCustomer = (data) => {
    if (!data) return;
    setLoadingCustomer(true);
    const { heightOfGoogleAddresses, ...clearData } = data?.data || {};

    const preparedData = {
      [TABLE_TABS[addressTab].var]: { ...clearData },
    };

    dispatch(
      updateCustomerAction({
        data: preparedData,
        id: formField.customer.id,
        onSuccess: (data) => {
          setStateValue("customer", data, { shouldDirty: true });
          setLoadingCustomer(false);
          setEditCustomerOpen(false);
        },
        onError: () => {
          setLoadingCustomer(false);
          setEditCustomerOpen(false);
        },
        returnCustomer: true,
      })
    );
  };

  const widthOnHover = useMemo(
    () => (hoveredElement && showBtn ? "calc(100% - 74px)" : "100%"),
    [hoveredElement, showBtn]
  );

  const showOnHover = useMemo(
    () => (hoveredElement && showBtn ? "flex" : "none"),
    [hoveredElement, showBtn]
  );

  const disabledSaveBtn = handleCheckAddBtn();

  const customerAssignedRepIds = useMemo(
    () =>
      formField?.customer?.assignedRepresentatives
        ? formField?.customer?.assignedRepresentatives?.map(
            (r) => r?.representative?.id
          )
        : [],
    [formField?.customer]
  );

  const handleThirdPartyRepsList = useCallback(() => {
    const assignedListThirdPartyReps = thirdPartyRepsList?.filter((r) =>
      customerAssignedRepIds.includes(r.representative?.id || r?.id)
    );

    const unAssignedListThirdPartyReps = thirdPartyRepsList?.filter(
      (r) => !customerAssignedRepIds.includes(r.representative?.id || r?.id)
    );

    return [
      { name: "No rep assigned", id: "no_rep" },
      ...assignedListThirdPartyReps,
      ...unAssignedListThirdPartyReps,
    ];
  }, [customerAssignedRepIds, thirdPartyRepsList]);

  const preparedThirdRepsList = useMemo(
    () => handleThirdPartyRepsList(),
    [handleThirdPartyRepsList]
  );

  return (
    <Paper
      className={classes.section}
      sx={{
        position: "relative",
        p: "16px",
        minHeight: "197px",
      }}
    >
      <OrderedProductsPopup
        currentPage={params.page}
        loading={loading}
        setValue={setValue}
        orderedProductsFetch={orderedProductsFetch}
        calcAvailable={calcAvailable}
        isOpen={isOpenOrderedPopUp}
        getOutOfStock={getOutOfStock}
        control={orderedProductsControl}
        disabledSaveBtn={
          !disabledSaveBtn || !!Object.keys(orderedErrors).length
        }
        handleAddProduct={handleAddProduct}
        handleAddOrders={handleAddProducts}
        handleSubtractQty={handleSubtractQty}
        checkSellingOutStock={checkSellingOutStock}
        handleIsAlreadyInCart={handleIsAlreadyInCart}
        orderedProductsCount={orderedProductsFields.productsCount}
        orderedProducts={orderedProductsFields.products}
        handleClose={() => setIsOpensetOrderedPopUp(false)}
        handleCancel={() => setIsOpensetOrderedPopUp(false)}
        handleSetProductError={handleSetProductError}
      />

      {formField.customer && (
        <EditCustomerPopup
          isOpen={editCustomerOpen}
          data={CUSTOMER_ADDRESS_INFO[addressTab]}
          type={TABLE_TABS[addressTab].type}
          handleClose={() => setEditCustomerOpen(false)}
          handleSave={handleChangeCustomer}
          loading={loadingCustomer}
          customer={formField.customer}
          withoutAbsoluteLabel
        />
      )}

      <Box
        display="flex"
        justifyContent="space-between"
        className={classes.secondStepContainer}
      >
        <Box>
          <Box display="inline-flex">
            <Typography className={classes.stepTitle}>Step 2</Typography>
            {(errors.customer || errors.contactId || errors.salesId) && (
              <Typography
                fontSize="14px"
                color="#FF6969"
                ml="9px"
                fontWeight="400"
              >
                {errors.customer?.message ||
                  errors.contactId?.message ||
                  errors.salesId?.message}
              </Typography>
            )}
          </Box>
          <Typography
            color="#707070"
            fontWeight={600}
            fontSize="clamp(15px, 1.3vw, 25px)"
          >
            Customer
          </Typography>
        </Box>
        <Stack
          direction="row"
          justifyContent="flex-end"
          flexWrap="wrap"
          className={classes.secondStepHeaderContainer}
        >
          {formField.customer && !!orderedProductsFields.products.length && (
            <StyledButton
              disabled={isAdmin}
              label="Ordered Items"
              onClick={() => setIsOpensetOrderedPopUp(true)}
              variant="outlined"
              className={classes.updateBtn}
            />
          )}

          {formField.customer &&
            !orderCompleted &&
            (deliveryStatus === "UNFULFILLED" || !deliveryStatus) &&
            (paymentStatus === "PENDING" || !paymentStatus) && (
              <StyledButton
                disabled={isAdmin}
                label="Update"
                onClick={handleCheckRemoveCustomer}
                variant="outlined"
                className={classes.updateBtn}
              />
            )}
        </Stack>
      </Box>

      {formField.customer && (
        <SecondStepTabs
          isAdmin={isAdmin}
          anchorEl={anchorEl}
          formField={formField}
          TABLE_TABS={TABLE_TABS}
          addressTab={addressTab}
          isListOpen={isListOpen}
          handleTerms={handleTerms}
          isThirdParty={isThirdParty}
          setAddressTab={setAddressTab}
          paytermsListActive={paytermsListActive}
          handleCloseLogoClient={handleCloseLogoClient}
          handleClickLogoClient={handleClickLogoClient}
          setOpenPaymentTermModal={setOpenPaymentTermModal}
        />
      )}

      {formField.customer ? (
        <Stack direction="column" gap="13px">
          <AddressCard
            CUSTOMER_ADDRESS_INFO={CUSTOMER_ADDRESS_INFO}
            formField={formField}
            addressTab={addressTab}
            showOnHover={showOnHover}
            widthOnHover={widthOnHover}
            setHoveredElement={setHoveredElement}
            handleOpenEditCustomer={handleOpenEditCustomer}
          />
          <Grid
            container
            columns={12}
            columnSpacing={1.75}
            sx={{ position: "relative" }}
          >
            <Grid xs={6} item>
              <Typography color="#707070" fontSize="14px">
                Sales Rep
              </Typography>
              <Box width="100%">
                {(
                  isThirdParty ? !thirdPartyRepsList?.length : !repsList?.length
                ) ? (
                  <StyledButton
                    sx={{
                      width: "100%",
                      height: "30px",
                      border: errors?.salesId
                        ? "1px solid #FF696A"
                        : "1px solid #D5D9D9",
                      justifyContent: "flex-start",
                    }}
                    variant="outlined"
                    color="greyBtn"
                    label={
                      <Box display="flex" alignItems="center">
                        <OutlinedPlusIcon color="#717171" />
                        <Typography
                          fontSize={14}
                          fontWeight={400}
                          color="#717171"
                          ml={1}
                        >
                          Add
                        </Typography>
                      </Box>
                    }
                    onClick={handleOpenRepsDialog}
                  />
                ) : (
                  <Box width="100%">
                    <Controller
                      render={({ field, fieldState: { error } }) => (
                        <StyledSelect
                          ref={selectSalesIdRef}
                          disabled={
                            isAdmin ||
                            (isThirdParty
                              ? !preparedThirdRepsList?.length
                              : !repsList?.length)
                          }
                          height="30px"
                          PaperPropsSx={{
                            boxShadow: "none",
                            border: "0.5px solid #D5D9D9",
                            width: setWidth(selectSalesIdRef),
                            mt: "5px",
                            boxSizing: "border-box",
                            "& .MuiMenu-list": { p: "0px" },
                            "& .MuiMenuItem-root": {
                              p: "7px 12px 5px",
                              height: "46px",
                              "&:not(last-child)": {
                                borderBottom: "0.5px solid #D5D9D9",
                              },
                            },
                          }}
                          fullWidth
                          fontSize="12px"
                          color="#707070"
                          notched={false}
                          noErrorMessage
                          error={error ? error.message : ""}
                          {...field}
                          value={field.value}
                          displayEmpty
                          dataLength={
                            isThirdParty
                              ? preparedThirdRepsList?.length
                              : repsList?.length
                          }
                          dataCount={
                            isThirdParty
                              ? preparedThirdRepsList?.length
                              : repsList?.length
                          }
                          handleFetch={fetchThirdPartyReps}
                          renderValue={() => {
                            const data = isThirdParty
                              ? preparedThirdRepsList
                              : repsList;

                            return field.value
                              ? data.filter(
                                  (item) => item.id === field.value
                                )[0]?.name
                              : "Select";
                          }}
                        >
                          <MenuItem sx={{ display: "none" }} value="">
                            <Typography color="#DBDBDB" fontSize="14px">
                              Select
                            </Typography>
                          </MenuItem>
                          {(isThirdParty
                            ? preparedThirdRepsList
                            : repsList
                          )?.map((rep) => (
                            <MenuItem
                              sx={{ width: "100%" }}
                              key={rep.representative?.id || rep?.id}
                              value={rep.representative?.id || rep?.id}
                            >
                              <Stack sx={{ width: "100%" }} direction="column">
                                <Stack
                                  direction="row"
                                  justifyContent="space-between"
                                  gap="3px"
                                >
                                  <Typography
                                    fontSize={13}
                                    fontWeight={600}
                                    color="#5F6267"
                                    noWrap
                                  >
                                    {rep.representative?.name || rep.name}
                                  </Typography>
                                  {isThirdParty &&
                                    customerAssignedRepIds?.includes(
                                      rep.representative?.id || rep?.id
                                    ) && (
                                      <Typography
                                        fontSize={6}
                                        lineHeight="16px"
                                        fontWeight={500}
                                        color="#47A06D"
                                        mb="auto"
                                      >
                                        ASSIGNED
                                      </Typography>
                                    )}
                                </Stack>
                                <Typography
                                  fontSize={13}
                                  fontWeight={300}
                                  color="#5F6267"
                                  noWrap
                                >
                                  {rep.email || rep.baseUser?.email}
                                </Typography>
                              </Stack>
                            </MenuItem>
                          ))}
                        </StyledSelect>
                      )}
                      name="salesId"
                      control={control}
                    />
                  </Box>
                )}
              </Box>
            </Grid>

            <Grid xs={6} item>
              <Typography color="#707070" fontSize="14px" noWrap>
                Contact
              </Typography>
              {formField?.customer?.contacts?.length ? (
                <Box width="100%">
                  <Controller
                    render={({ field, fieldState: { error } }) => {
                      handleContactChange(field.value);
                      return (
                        <StyledSelect
                          ref={selectContactIdRef}
                          disabled={isAdmin}
                          height="30px"
                          PaperPropsSx={{
                            boxShadow: "none",
                            border: "0.5px solid #D5D9D9",
                            width: setWidth(selectContactIdRef),
                            mt: "5px",
                            boxSizing: "border-box",
                            "& .MuiMenu-list": { p: "0px" },
                            "& .MuiMenuItem-root": {
                              "&:not(last-child)": {
                                borderBottom: "0.5px solid #D5D9D9",
                              },
                            },
                          }}
                          fullWidth
                          fontSize="12px"
                          color="#707070"
                          notched={false}
                          noErrorMessage
                          error={error ? error.message : ""}
                          {...field}
                          value={field.value || defaultContactId || ""}
                          renderValue={(v) => {
                            const contact =
                              formField?.customer?.contacts?.filter(
                                (c) => c?.id === v
                              );
                            return contact?.[0]?.name || "Select";
                          }}
                        >
                          <MenuItem
                            sx={{
                              height: "46px",
                              borderBottom: "0.5px solid #D5D9D9",
                            }}
                            value={formField?.contactId}
                            onClick={() => setContactOpen(true)}
                          >
                            <OutlinedPlusIcon />
                            <Typography
                              fontSize="13px"
                              fontWeight="300"
                              ml="10px"
                            >
                              Add new contact
                            </Typography>
                          </MenuItem>
                          {formField.customer?.contacts?.map((contact) => (
                            <MenuItem
                              sx={{
                                width: "100%",
                                height: "100%",
                                p: "0 0 0 12px",
                              }}
                              key={contact.id}
                              value={contact.id}
                            >
                              <Grid
                                sx={{ width: "100%", height: "46px" }}
                                container
                              >
                                <Grid sx={{ height: "100%" }} xs={9} item>
                                  <Stack
                                    width="100%"
                                    height="100%"
                                    direction="column"
                                    justifyContent="center"
                                  >
                                    <Typography
                                      fontSize={13}
                                      fontWeight={600}
                                      color="#5F6267"
                                      noWrap
                                    >
                                      {contact.name}
                                    </Typography>
                                    <Typography
                                      fontSize={13}
                                      fontWeight={300}
                                      color="#5F6267"
                                      noWrap
                                    >
                                      {contact.email}
                                    </Typography>
                                  </Stack>
                                </Grid>
                                <Grid
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    height: "100%",
                                  }}
                                  onClick={() => {
                                    setContactOpen(true);
                                    setEditContact(contact);
                                  }}
                                  xs={3}
                                  item
                                >
                                  <IconButton
                                    disabled={isAdmin}
                                    sx={{
                                      flexGrow: 1,
                                      "&:hover": { bgcolor: "transparent" },
                                    }}
                                    onClick={() => {
                                      setContactOpen(true);
                                      setEditContact(contact);
                                    }}
                                  >
                                    <PenIcon size={8} color="#000000" />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </MenuItem>
                          ))}
                        </StyledSelect>
                      );
                    }}
                    name="contactId"
                    control={control}
                  />
                </Box>
              ) : (
                <StyledButton
                  startIcon={<OutlinedPlusIcon color="#707070" size="14" />}
                  label={showLongContactLabel ? "Add customer contact" : "Add"}
                  fontSize="12px"
                  variant="outlined"
                  sx={{
                    whiteSpace: "nowrap",
                    width: "100%",
                    justifyContent: "flex-start",
                    borderColor: errors.contactId ? "#FF6969" : "#D5D9D9",
                    height: "30px",
                    maxHeight: "30px",
                  }}
                  color="edit"
                  onClick={() => setContactOpen(true)}
                />
              )}
            </Grid>
          </Grid>
        </Stack>
      ) : (
        <Box
          sx={{ position: "relative", display: "flex", alignItems: "center" }}
        >
          <StyledTextField
            fullWidth
            ref={searchRef}
            formSx={{ mt: "13px" }}
            size="small"
            value={customersSearchInput}
            onFocus={() => setIsFetchable(true)}
            onClick={() => setIsFetchable(true)}
            onChange={(e) => setCustomersSearchInput(e.target.value)}
            placeholder="Search by name, address or ID"
            InputProps={{
              className: classes.textInput,
              endAdornment: (
                <>
                  <InputAdornment
                    sx={{ position: "absolute", left: 9 }}
                    position="start"
                  >
                    <SearchIcon />
                  </InputAdornment>
                  {customersLoading && (
                    <InputAdornment position="end">
                      <CircularProgress size="20px" />
                    </InputAdornment>
                  )}
                  {customersSearchInput && (
                    <InputAdornment position="end" sx={{ mr: "-4px" }}>
                      <IconButton
                        sx={{ p: "1px" }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setCustomersList([]);
                          setCustomersSearchInput("");
                        }}
                      >
                        <CrossIcon size="15" />
                      </IconButton>
                    </InputAdornment>
                  )}
                </>
              ),
            }}
            InputLabelProps={{
              className: classes.inputLabel,
            }}
          />
          {!!customersList.length && customersDropDown}
          <StyledButton
            ref={productsRef}
            label="View all"
            onClick={() => {
              setCustomersList([]);
              setCustomersSearchInput("");
              setIsFetchable(false);
              setAllCustomersOpen(true);
            }}
            variant="outlined"
            color="greyBtn"
            fontSize="15px"
            data-testid="all-customers"
            sx={{
              color: "#6A6A6A",
              height: "34px",
              whiteSpace: "nowrap",
              mt: "13px",
              ml: "10px",
            }}
          />
        </Box>
      )}

      {formField?.customer?.id && (
        <Box mt="16px">
          <PriceListLabel
            priceListName={priceListName}
            loading={priceListLoading}
          />
        </Box>
      )}
    </Paper>
  );
};

SecondCustomerStep.propTypes = {
  setStateValue: func,
  formField: object,
  errors: object,
  orderCompleted: bool,
  deliveryStatus: string,
  paymentStatus: string,
  control: object,
  handleCheckRemoveCustomer: func,
  isThirdParty: bool,
  handleClickLogoClient: func,
  handleCloseLogoClient: func,
  setOpenPaymentTermModal: func,
  anchorEl: object,
  openHeaderList: bool,
  paytermsListActive: array,
  setCustomersList: func,
  setIsFetchable: func,
  handleTerms: func,
  thirdPartyRepsList: array,
  repsList: array,
  handleOpenRepsDialog: func,
  fetchThirdPartyReps: func,
  handleContactChange: func,
  defaultContactId: number,
  setContactOpen: func,
  setEditContact: func,
  customersSearchInput: string,
  customersLoading: bool,
  setCustomersSearchInput: func,
  customersList: array,
  setAllCustomersOpen: func,
  productsRef: object,
  searchRef: object,
  customersDropDown: object,
  clearErrors: func,
  checkSellingOutStock: func,
  priceListName: string,
  priceListLoading: bool,
};
