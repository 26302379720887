import { bool, func, object } from "prop-types";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { ArrowNextIcon } from "components/Icons";
import { RoundedPlus } from "components/Icons/RoundedPlus";
import { useState } from "react";

export const TableItem = ({
  isLastCategory,
  item,
  onEdit,
  isChild = false,
  isExpanded = false,
  onExpand = () => {},
}) => {
  const [isItemHovered, setIsItemHovered] = useState(false);
  const { name, parentCategoryId, childCategories, _count } = item || {};

  const isUncategorized = name === "Uncategorized";

  return (
    <Grid
      onMouseEnter={() => setIsItemHovered(true)}
      onMouseLeave={() => setIsItemHovered(false)}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (isUncategorized) return;
        onEdit();
      }}
      sx={{
        alignItems: "center",
        height: "49px",
        pl: "16px",
        overflow: "hidden",
        minHeight: "49px",
        maxHeight: "49px",
        cursor: isUncategorized ? "default" : "pointer",
        background: isItemHovered
          ? "rgba(213, 217, 217, 0.12)"
          : isUncategorized
          ? "#ffff"
          : !parentCategoryId
          ? "#ffff"
          : "rgba(213, 217, 217, 0.16)",
        borderBottom:
          isLastCategory && isChild
            ? "0.5px solid #D5D9D9"
            : isLastCategory
            ? "none"
            : "0.5px solid #D5D9D9",

        position: "relative",
      }}
      container
    >
      <Grid
        pr={1}
        xs={4.5}
        pl={isChild ? "24px" : "0px"}
        item
        container
        alignItems="center"
        gap="8px"
      >
        <Typography
          fontSize={12}
          fontWeight={400}
          color="#1C1C19"
          noWrap
          maxWidth={!isChild ? "calc(100% - 34px)" : "100%"}
          sx={{
            "&:hover": {
              textDecoration: isUncategorized ? "none" : "underline",
            },
          }}
        >
          {name}
        </Typography>
        {!isChild && !isUncategorized && (
          <Box component="span">
            <IconButton
              disabled={!childCategories?.length}
              disableRipple
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onExpand();
              }}
              sx={{
                opacity: childCategories?.length ? 1 : 0.5,
                transform: isExpanded ? "rotate(90deg)" : "rotate(0deg)",
                transition: "all 0.3s",
              }}
            >
              <ArrowNextIcon color="#707070" width={5.9} height={10.9} />
            </IconButton>
          </Box>
        )}
      </Grid>

      <Grid xs={7.04} item pr={1}>
        <Typography fontSize={12} fontWeight={400} color="#1C1C19" noWrap>
          {_count?.products}
        </Typography>
      </Grid>
      {!isChild && !isUncategorized && (
        <Grid
          xs={0.46}
          item
          pr="14px"
          justifyContent="flex-end"
          container
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <IconButton
            disableRipple
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();

              onEdit(item, true);
            }}
            sx={{
              p: 0,
              cursor: "pointer",
              borderRadius: "2px",
              "&:hover": {
                background: "rgba(112, 112, 112, 0.10)",
              },
            }}
          >
            <RoundedPlus />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

TableItem.propTypes = {
  item: object,
  isLastCategory: bool,
  collapseIcon: object,
  onEdit: func,
  onExpand: func,
  isChild: bool,
  isExpanded: bool,
};
