import { Select } from "@mui/material";
import { LargeCheckbox, StyledTextField } from "components";
import { IosArrowDownIcon } from "components/Icons";
import {
  MAX_CHARACTERS_DESCRIPTIONS,
  MAX_CHARACTERS_TITLE,
  STATUS_SELECT_DATA,
} from "Pages/FormViewPage/FormViewPage.constants";

export const useDetailFields = () => {
  const FIELDS_DATA = [
    {
      id: "title",
      Input: StyledTextField,
      placeholder: "Title",
      label: "Title",
      fullWidth: true,
      InputProps: {
        sx: {
          fontSize: "13px",
          height: "43px",
          borderRadius: "5px 0 0 5px",
          "& fieldset": { borderWidth: "1px 0px 1px 1px" },
        },
      },
      InputLabelProps: {
        sx: { fontSize: "13px", color: "#5F6267", top: 3 },
      },
      maxCharacters: MAX_CHARACTERS_TITLE,
      name: "title",
    },
    {
      id: "active",
      isSelect: true,
      Input: Select,
      displayEmpty: true,
      MenuProps: {
        disableScrollLock: true,
      },
      sx: {
        width: "fit-content",
        height: 43,
        "& .MuiInputBase-input": {
          fontSize: 13,
          color: "#5F6267",
        },
        "& .MuiSelect-select": {
          paddingLeft: "16px !important",
          paddingRight: "30px !important",
        },
        "& fieldset": {
          border: "1px solid #D5D9D9",
          borderRadius: "0 5px 5px 0",
          borderWidth: "1px 1px 1px 0.5px",
        },
        "& svg": {
          right: 8,
        },
        "&.MuiInputBase-root.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
          { borderLeftColor: "#D5D9D9 !important" },
      },
      IconComponent: (props) => <IosArrowDownIcon {...props} />,
      menuItems: STATUS_SELECT_DATA,
      value: STATUS_SELECT_DATA[0].value,
      name: "active",
    },
    {
      id: "description",
      Input: StyledTextField,
      placeholder: "Details",
      label: "Details",
      fullWidth: true,
      InputProps: {
        multiline: true,
        rows: 5,
        sx: {
          fontSize: "13px",
          borderRadius: "5px",
        },
      },
      InputLabelProps: {
        sx: { fontSize: "13px", color: "#5F6267", top: 3 },
      },
      maxCharacters: MAX_CHARACTERS_DESCRIPTIONS,
      name: "description",
    },
    {
      id: "allRequired",
      Input: LargeCheckbox,
      label: "Required",
      size: 16,
      formSx: { "& span": { p: 0 }, ml: 0 },
      labelSx: { fontSize: "13px", color: "#707070", ml: "5px" },
      name: "allRequired",
      tooltip: {
        title:
          "If checked, the responder must answer this question before submitting the form.",
      },
    },
    {
      id: "autofill",
      Input: LargeCheckbox,
      label: "Autofill",
      size: 16,
      formSx: { "& span": { p: 0 }, ml: 0 },
      labelSx: { fontSize: "13px", color: "#707070", ml: "5px" },
      name: "autofill",
      tooltip: {
        title:
          "If checked, the next time a representative opens this form for the same client, selected fields will be pre-filled with the data submitted on the last visit.",
      },
    },
  ];
  return { FIELDS_DATA };
};
