import { useMemo, useState } from "react";
import { func, array, bool, object, string } from "prop-types";
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import {
  LargeCheckbox,
  RepsAssignedDrawer,
  StyledAvatar,
  StyledSelect,
  StyledTextField,
  StyledTooltip,
} from "components";
import { PlusIcon, SimpleArrowDownIcon } from "components/Icons";
import StopItem from "../StopItem";
import useStyles from "./styles";
import { Controller } from "react-hook-form";
import { normalizeUppercaseString, photoUrl } from "helpers/helpers";
import { PRIORITIES } from "Pages/CreateRoutePage/CreateRoutePage.constants";
import Slider from "react-slick";
import {
  NextArrow,
  PrevArrow,
} from "Pages/CatalogPage/CatalogNewProductPage/ContentTabs/Arrows";
import { useRepsPermissions } from "helpers/hooks";
import { getPriority } from "Pages/CustomersPage/pages/CustomerProfilePage/components/TaskDrawer/TaskDrawer.hooks";
import { DueDateBlock } from "components/TaskDrawer/components";
import moment from "moment-timezone";
import { createSelector } from "reselect";
import { currentUserSelector } from "redux/selectors/auth";
import { useDispatch, useSelector } from "react-redux";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";

const selector = createSelector(currentUserSelector, (currentUser) => ({
  currentUser,
}));

const StopsTable = ({
  stops,
  handleDeleteStop,
  hasStartLoc,
  setEditCustomerData,
  customersState,
  control,
  handleAssignReps,
  reps,
  setValue,
  formField,
  setError,
  clearErrors,
  showDateChangeConfirm,
  setShowDateChangeConfirm,
  routeId,
  defaultDate,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const repPermissions = useRepsPermissions();

  const { currentUser } = useSelector(selector);

  const { timeZone } = currentUser || {};

  const [assignDialogOpen, setAssignDialogOpen] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const sliderSettings = {
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <NextArrow width="4.5" height="7.9" color="#707070" top="30%" />,
    prevArrow: <PrevArrow width="4.5" height="7.9" color="#707070" top="30%" />,
  };

  const handleOpenAssign = (e) => {
    e.preventDefault();
    if (repPermissions && !repPermissions?.routes?.assign) return;
    setAssignDialogOpen(true);
  };

  const thirdPartyLabel = (rep) => {
    return rep?.representative?.role === "THIRD_PARTY" ? (
      <Box className={classes.thirdPartyLabel}>3rd Party</Box>
    ) : (
      ""
    );
  };

  const toggleSelectOpen = () => {
    setIsSelectOpen(!isSelectOpen);
  };

  const handleAvatarContent = (rep) => (
    <Box sx={{ textAlign: "center" }}>
      <Typography fontSize="10px" color="primary">
        {!!rep?.role &&
          (rep?.role === "THIRD_PARTY"
            ? "3rd Party Rep"
            : `${normalizeUppercaseString(rep?.role)} Rep`)}
      </Typography>
      <Typography fontSize="10px" color="#363531">
        {rep?.name}
      </Typography>
    </Box>
  );

  const closeConfirm = (callback = () => {}) => {
    if (!routeId) return callback();
    const defaultRouteDate = moment.tz(defaultDate, timeZone);
    const selectedDate = moment.tz(formField.activationDate, timeZone);

    const isDefaultPastOrToday = defaultRouteDate.isSameOrBefore(
      moment().tz(timeZone),
      "day"
    );
    const isDateChanged = !defaultRouteDate.isSame(selectedDate, "day");

    const showConfirm =
      isDefaultPastOrToday &&
      isDateChanged &&
      !showDateChangeConfirm &&
      formField?.assignedReps?.length;

    if (showConfirm) {
      return dispatch(
        openConfirmDialogAction({
          title: "Confirm Route Date Changes",
          isIcon: true,
          text: "Changing the route date may affect some routes that are already in progress. Are you sure you want to proceed?",
          propBtns: {
            left: {
              sx: {
                color: "#6A6A6A",
                borderColor: "#D4D4D4",
                fontSize: "13px",
                height: "28px",
              },
              onClick: () => {
                dispatch(setConfirmIsOpenAction(false));
                setValue("activationDate", defaultDate, { shouldDirty: true });
              },
              variant: "outlined",
              label: "Cancel",
            },
            right: {
              sx: {
                color: "#FFFFFF",
                fontSize: "13px",
                height: "28px",
                boxShadow: "none",
              },
              color: "primary",
              onClick: () => {
                callback();
                dispatch(setConfirmIsOpenAction(false));
                setShowDateChangeConfirm(true);
              },
              variant: "contained",
              label: "Proceed",
            },
          },
        })
      );
    }

    callback();
  };

  const isValidDate = (str) => {
    if (!str) return false;
    const parsedDate = moment(str, "MM/DD/YYYY", true);
    return parsedDate.isValid();
  };

  const setMarginRight = useMemo(() => {
    if (reps?.length === 1) return "-26px";
    if (reps?.length === 2) return "-5px";
    return "16px";
  }, [reps?.length]);

  return (
    <>
      <RepsAssignedDrawer
        isOpen={assignDialogOpen}
        handleClose={() => setAssignDialogOpen(false)}
        assignedRepresentatives={reps}
        handleSetData={handleAssignReps}
        submitBtnLabel="Update"
        withoutThirdParty
        skipDisabledBtn
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "14px",
          height: "100%",
          width: "458px",
        }}
      >
        <Box>
          <Box
            className={classes.headerWrapper}
            sx={{ gap: reps?.length > 3 ? "16px" : 0 }}
          >
            <Box display="flex" gap={1} flexGrow={1}>
              <Typography fontSize="15px" color="#6A6A6A">
                Route
              </Typography>
              <Typography fontSize="13px" my="auto" color="#ACACAC">
                {stops.length} stops {hasStartLoc && "I 33 miles I 3 hrs 9 m"}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: reps.length > 1 ? "pointer" : "auto",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mr: setMarginRight,
                }}
                onClick={() => {
                  if (!reps.length) return;
                  toggleSelectOpen();
                }}
              >
                {reps.length > 1 ? (
                  <Typography
                    sx={{ fontSize: "12px", color: "#47A06D", mr: "5px" }}
                  >
                    +{reps.length}
                  </Typography>
                ) : null}
                {reps?.length > 1 ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "10px",
                    }}
                  >
                    <SimpleArrowDownIcon width="20px" />
                  </Box>
                ) : (
                  <Box width="34px" />
                )}
              </Box>

              <Select
                open={isSelectOpen}
                onClose={() => setIsSelectOpen(false)}
                value={""}
                sx={{ opacity: 0, position: "absolute", zIndex: -1 }}
              >
                {reps.map((rep, index) => {
                  const photo = rep?.profilePhoto?.fileName;
                  const name = (rep?.name || "").trim();
                  return (
                    <MenuItem
                      key={rep?.representative?.id || rep?.id}
                      px="10px"
                      value={{ ...rep, index }}
                      sx={{
                        maxWidth: "700px",
                        "&:hover": { backgroundColor: "#FFF" },
                      }}
                      onClick={handleOpenAssign}
                    >
                      <Grid
                        sx={{
                          height: "50px",
                          flexWrap: "nowrap",
                          alignItems: "center",
                          "&:hover": {
                            background: "#E9E9E950",
                            borderRadius: "4px",
                            cursor: "pointer",
                          },
                        }}
                        container
                      >
                        <Grid pl={1} item xs="auto">
                          {thirdPartyLabel(rep)}
                          <StyledAvatar
                            sx={{ width: 30, height: 30 }}
                            name={name}
                            src={photo}
                            stringStyle={{ fontSize: 12 }}
                          />
                        </Grid>

                        <Grid item xs>
                          <Typography
                            sx={{ maxWidth: "435px", px: 0.5 }}
                            noWrap
                          >
                            {name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </MenuItem>
                  );
                })}
              </Select>

              {reps?.length ? (
                <Box className={classes.sliderWrapper}>
                  <Slider {...sliderSettings}>
                    {reps.map((rep) => {
                      return (
                        <StyledTooltip
                          placement="top"
                          arrow
                          title={handleAvatarContent(rep)}
                          key={rep?.id}
                          PopperProps={{
                            modifiers: [
                              {
                                name: "offset",
                                options: { offset: [0, -5] },
                              },
                            ],
                          }}
                        >
                          <StyledAvatar
                            className={classes.assignedAvatar}
                            name={rep?.name}
                            src={photoUrl(rep?.profilePhoto?.fileName)}
                            stringStyle={{ fontSize: 10 }}
                            onClick={handleOpenAssign}
                          />
                        </StyledTooltip>
                      );
                    })}
                  </Slider>
                </Box>
              ) : (
                <Typography
                  sx={{
                    fontSize: 12,
                    color: "#ACACAC",
                    cursor: "pointer",
                  }}
                  onClick={handleOpenAssign}
                >
                  Assign Rep
                </Typography>
              )}
            </Box>

            <IconButton onClick={handleOpenAssign}>
              <PlusIcon
                width="18"
                height="18"
                strokeWidth="3.5"
                stroke="#EEE"
              />
            </IconButton>
          </Box>
          <Stack
            gap="12px"
            p="16px 16px 6px"
            sx={{
              border: "0.5px solid #CCCCCC",
              borderRadius: "0 0 4px 4px",
              backgroundColor: "#FFF",
            }}
          >
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  label="Route Name *"
                  InputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{
                    sx: { fontSize: 14, top: 3, color: "#A5A5A5" },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": { height: "44px" },
                  }}
                  error={error?.message || ""}
                  noErrorMessage
                  {...field}
                />
              )}
              name="name"
              {...{ control }}
            />

            <Stack flexDirection="row" gap="8px">
              <Box width="67%" maxWidth="calc(67% - 9px)">
                <DueDateBlock
                  {...{
                    formField,
                    timeZone,
                    setValue,
                    control,
                    isValidDate,
                    setError,
                    clearErrors,
                    fieldName: "activationDate",
                    showDefaultTime: false,
                    skipTime: true,
                    label: "Activation Date",
                    inputLabelSx: {
                      color: "#A5A5A5",
                    },
                    closeConfirm,
                  }}
                />
              </Box>

              <Box width="33%">
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <StyledSelect
                      label="Priority"
                      fullWidth
                      error={!!error}
                      height="44px"
                      fontSize="14px !important"
                      labelSx={{
                        color: "#A5A5A5",
                        fontSize: "14px !important",
                        top: 3,
                      }}
                      InputProps={{ style: { fontSize: "14px !important" } }}
                      {...field}
                    >
                      {PRIORITIES.map((p) => (
                        <MenuItem key={p} value={p}>
                          {getPriority({
                            type: p,
                            sx: {
                              width: "60px",
                              height: "24px",
                              borderRadius: "8px",
                              "& p": {
                                fontSize: "10px !important",
                                fontWeight: 500,
                                textTransform: "lowercase",
                              },
                              "& p:first-letter": {
                                textTransform: "capitalize",
                              },
                            },
                          })}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  )}
                  name="priority"
                  control={control}
                />
              </Box>
            </Stack>

            <Controller
              render={({ field }) => (
                <StyledTextField
                  fullWidth
                  InputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{
                    sx: { fontSize: 14, top: 3, color: "#A5A5A5" },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": { height: "44px" },
                  }}
                  label="Route Notes"
                  placeholder="Add route note or instructions"
                  {...field}
                />
              )}
              name="note"
              {...{ control }}
            />

            <Box sx={{ width: "100%", pl: "6px", mt: "-8px" }}>
              <Controller
                render={({ field }) => (
                  <LargeCheckbox
                    label="Allow assigned reps to edit route"
                    size={16}
                    labelSx={{ fontSize: "12px", color: "#1C1C19" }}
                    checked={!!field.value}
                    {...field}
                  />
                )}
                name="allowAssignedRepsToUpdate"
                {...{ control }}
              />
            </Box>
          </Stack>
        </Box>

        <Stack className={classes.stopsWrapper}>
          {stops.length ? (
            stops.map((stop, index) => {
              let shouldRemove = false;
              if (
                !customersState?.available &&
                customersState?.inaccessibleCustomers?.length
              ) {
                shouldRemove = customersState?.inaccessibleCustomers.some(
                  (id) => id === stop.id
                );
              }
              return (
                <StopItem
                  key={stop.id}
                  {...{ stop, index, handleDeleteStop, setEditCustomerData }}
                  shouldRemove={shouldRemove}
                />
              );
            })
          ) : (
            <Typography textAlign="center" mt="24px" fontSize="12px">
              No stops added
            </Typography>
          )}
        </Stack>
      </Box>
    </>
  );
};

StopsTable.propTypes = {
  stops: array,
  handleDeleteStop: func,
  setValue: func,
  hasStartLoc: bool,
  setEditCustomerData: func,
  customersState: object,
  formField: object,
  control: object,
  setError: func,
  clearErrors: func,
  handleAssignReps: func,
  reps: array,
  showDateChangeConfirm: bool,
  setShowDateChangeConfirm: func,
  routeId: string,
  defaultDate: string,
};

StopsTable.defaultProps = {
  stops: [],
  handleDeleteStop: () => {},
  hasStartLoc: false,
  setEditCustomerData: () => {},
};

export default StopsTable;
