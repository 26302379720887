import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { number, object, string } from "prop-types";
import { Box } from "@mui/material";

export const BadgeComponent = ({
  count = 1,
  qualitySize = 100,
  size = 12,
  color = "#47A06D",
  wrapperStyle = {},
}) => {
  const allowedRangeNumbers = useMemo(() => count >= 1 && count < 100, [count]);

  const canvasRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);

  const displayNumber = Math.max(1, Math.min(100, count));

  const generateImage = useCallback(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    canvas.width = qualitySize;
    canvas.height = qualitySize;

    ctx.clearRect(0, 0, qualitySize, qualitySize);

    ctx.fillStyle = color;
    ctx.beginPath();
    ctx.arc(qualitySize / 2, qualitySize / 2, qualitySize / 2, 0, Math.PI * 2);
    ctx.fill();

    ctx.fillStyle = "#fff";
    ctx.font = "32px Inter";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillText(displayNumber, qualitySize / 2, qualitySize / 1.8);

    const image = canvas.toDataURL("image/png");
    setImageSrc(image);
  }, [color, displayNumber, qualitySize]);

  useEffect(() => {
    allowedRangeNumbers && generateImage();
  }, [allowedRangeNumbers, generateImage]);

  return allowedRangeNumbers ? (
    <>
      <canvas ref={canvasRef} style={{ display: "none" }} />

      {imageSrc && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: `${size}px`,
            height: `${size}px`,
            minWidth: `${size}px`,
            minHeight: `${size}px`,
            borderRadius: "50%",
            zIndex: 1,
            ...wrapperStyle,
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            component="img"
            src={imageSrc}
            alt={`Badge ${count}`}
          />
        </Box>
      )}
    </>
  ) : null;
};

BadgeComponent.propTypes = {
  count: number,
  qualitySize: number,
  size: number,
  color: string,
  wrapperStyle: object,
};
