import { useMemo } from "react";
import { array, bool, func, object } from "prop-types";
import { Box, IconButton } from "@mui/material";
import { StyledButton, StyledTooltip } from "components";
import { ButtonTagIcon, CrossIcon } from "components/Icons";

export const AddComponent = ({
  discount = null,
  products = [],
  customer = null,
  isAdmin = false,
  updateDiscountDisabled = () => {},
  handleAddDiscount = () => {},
}) => {
  const tooltipTitle = useMemo(() => {
    if (!products?.length && !customer)
      return "Please add product and customer to proceed";

    if (!products?.length && customer) return "Please add product to proceed";

    if (products?.length && !customer) return "Please add customer to proceed";

    return "";
  }, [products, customer]);

  return (
    <Box
      sx={{
        position: "absolute",
        top: discount ? 19 : 12,
        right: discount ? 19 : 8,
        zIndex: 1,
      }}
    >
      <StyledTooltip
        title={tooltipTitle}
        arrow
        PopperProps={{
          modifiers: [{ name: "offset", options: { offset: [0, -5] } }],
        }}
        disableHoverListener={!!customer?.id && products?.length > 0}
        placement="top"
      >
        <Box>
          {discount ? (
            <StyledButton
              label="Update"
              variant="outlined"
              disabled={isAdmin || updateDiscountDisabled()}
              color="greyBtn"
              sx={{ color: "#6A6A6A" }}
              onClick={handleAddDiscount}
              startIcon={<ButtonTagIcon />}
            />
          ) : (
            <IconButton
              disabled={
                !products?.length ||
                !customer ||
                isAdmin ||
                updateDiscountDisabled()
              }
              onClick={handleAddDiscount}
              sx={{ transform: "rotate(135deg)" }}
            >
              <CrossIcon />
            </IconButton>
          )}
        </Box>
      </StyledTooltip>
    </Box>
  );
};

AddComponent.propTypes = {
  discount: object,
  products: array,
  customer: object,
  isAdmin: bool,
  updateDiscountDisabled: func,
  handleAddDiscount: func,
};
