import { useMemo, useRef } from "react";
import { bool, array, number, func, string, object } from "prop-types";
import { Box, Stack } from "@mui/material";
import { HeaderThirdParty } from "./HeaderThirdParty";
import { ItemThirdParty } from "./ItemThirdParty";
import EmptyScreen from "../../../../../../components/EmptyScreen/EmptyScreen";
import { useNavigate } from "react-router-dom";
import { InfiniteLoaderWrapper } from "../../../../../../components";
import { TableHeaderControlPanel } from "components/TableHeaderControlPanel/TableHeaderControlPanel";
import { useWindowSize } from "helpers/hooks";
import { ADMIN_ONLY_VIEW_MESSAGE } from "utils/constants";

export const ListThirdParty = ({
  currentTab,
  ordersLoading,
  loadingAllItems,
  ordersThirdPartyList,
  ordersThirdPartyCount,
  ordersExistThirdParty,
  allOrdersChecked,
  checkAllOrders,
  checkedOrders,
  handleCheckOrder,
  handleChoseMenuItem,
  handleOpenEmail,
  handleConfirmCheckedItemsDialog,
  isQuickBooksConnected,
  repPermissions,
  itemAllCount,
  onSelectAll,
  additionalActions,
  setCheckedOrders,
  dropDownActions,
  nextPageFetch,
  setCursor,
}) => {
  const navigate = useNavigate();
  const [windowWidth] = useWindowSize();

  const stackRef = useRef(null);

  const calcStackWidth = useMemo(() => {
    return stackRef?.current?.offsetWidth || 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth, stackRef?.current?.offsetWidth]);

  return (
    <Box>
      {ordersThirdPartyList?.length && ordersExistThirdParty ? (
        <>
          <TableHeaderControlPanel
            actionWrapperProps={{
              sx: { padding: "0 19px 0 14.5px !important" },
            }}
            checkedCount={checkedOrders?.length}
            actionsList={additionalActions}
            loading={ordersLoading}
            loadingAllItems={loadingAllItems}
            dropDownActions={dropDownActions}
            hasCheckedItems={!!checkedOrders?.length}
            availableSelectCount={itemAllCount}
            selectName="order"
            onSelectAll={(value) => onSelectAll(value)}
            cancelSelection={() => setCheckedOrders([])}
            onSelectVisible={checkAllOrders}
            headerComponent={
              <HeaderThirdParty
                allOrdersChecked={allOrdersChecked}
                checkAllOrders={checkAllOrders}
              />
            }
          />
          <Stack
            sx={{
              opacity: ordersLoading ? 0.5 : 1,
              pointerEvents: ordersLoading && "none",
              border: "0.5px solid #D5D9D9",
              borderRadius: "0 0 4px 4px",
              maxHeight: "calc(100vh - 311px)",
              overflow: "hidden",
              height: "100%",
            }}
            ref={stackRef}
            className="infinite-scroll-custom-scrollbar"
          >
            <InfiniteLoaderWrapper
              itemsList={ordersThirdPartyList}
              itemsCount={ordersThirdPartyCount}
              offsetHeight={312}
              wrapperWidth={calcStackWidth}
              loading={ordersLoading}
              hideScroll
              tableStyle={{ borderWidth: "0px" }}
              handleFetch={nextPageFetch}
              itemHeight={49}
              resetCallback={() => setCursor("")}
              testid="orders-third-party-list"
              renderChildren={(index) => (
                <ItemThirdParty
                  order={ordersThirdPartyList[index]}
                  {...{
                    handleCheckOrder,
                    handleChoseMenuItem,
                    handleOpenEmail,
                    handleConfirmCheckedItemsDialog,
                    currentTab,
                    isQuickBooksConnected,
                    repPermissions,
                  }}
                  isChecked={checkedOrders.some(
                    (checkedOrder) =>
                      checkedOrder === ordersThirdPartyList[index].id
                  )}
                />
              )}
            />
          </Stack>
        </>
      ) : (
        <EmptyScreen
          type="orders"
          onConfirm={() => navigate("/cart")}
          height="calc(100vh - 453px)"
          showAction={!ordersExistThirdParty}
          loading={ordersLoading}
          disabled={!!repPermissions && !repPermissions?.orders?.create_edit}
          tooltipTitle={ADMIN_ONLY_VIEW_MESSAGE}
        />
      )}
    </Box>
  );
};

ListThirdParty.propTypes = {
  currentTab: string,
  ordersLoading: bool,
  loadingAllItems: bool,
  ordersThirdPartyList: array,
  ordersThirdPartyCount: number,
  ordersExistThirdParty: bool,
  allOrdersChecked: bool,
  checkAllOrders: func,
  checkedOrders: array,
  handleCheckOrder: func,
  handleChoseMenuItem: func,
  handleOpenEmail: func,
  handleConfirmCheckedItemsDialog: func,
  isQuickBooksConnected: bool,
  repPermissions: object,
  onSelectAll: func,
  itemAllCount: number,
  additionalActions: array,
  setCheckedOrders: func,
  dropDownActions: array,
  setCursor: func,
  nextPageFetch: func,
};
