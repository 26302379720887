export const classes = {
  grid: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "3px",
  },
  typography: {
    fontSize: "12px",
    color: "#6A6A6A",
    textTransform: "uppercase",
  },
  paperWrapper: {
    backgroundColor: "#EEEEEE",
    border: "none",
    borderBottom: "0.5px solid #D5D9D9",
    borderTop: "0.5px solid #D5D9D9",
    height: "40px",
    minHeight: "40px",
  },
};
