// eslint-disable-next-line no-unused-vars
/*global google*/
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { CUSTOMER_STATUS_FILTERS } from "utils/constants";
import { StyledButton } from "../../components";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
  setFormChangedAction,
} from "../../redux/actions/confirmDialogs";
import { createRouteService, updateRouteService } from "../../services/routes";
import { error, success } from "../../utils/notifications";
import { useRedirect } from "helpers/useRedirect";

export const useRoute = () => {
  const [customersState, setCustomersState] = useState({
    popupOpen: false,
    tooltipOpen: false,
    warningOpen: false,
    loading: false,
    available: true,
    inaccessibleCustomers: [],
  });

  const navigate = useRedirect();

  const dispatch = useDispatch();
  const { routeId } = useParams();

  const onProceedSubmit = useCallback(
    (data, hasArchivedStops) => {
      const {
        // mode,
        name,
        assignedReps,
        priority,
        note,
        activationDate,
        allowAssignedRepsToUpdate,
      } = data;
      const stops = hasArchivedStops
        ? data.stops.filter(
            (stop) => stop.status !== CUSTOMER_STATUS_FILTERS.inactive
          )
        : [...data.stops];

      if (
        customersState?.inaccessibleCustomers?.length === 0 &&
        !customersState?.available
      ) {
        setCustomersState((prev) => ({
          ...prev,
          warningOpen: true,
        }));
        return;
      }

      // const lastStop = stops[stops.length - 1];

      // const destination = {
      //   lat: lastStop.shippingAddress.lat,
      //   lng: lastStop.shippingAddress.lng,
      // };

      // const waypoints = stops.slice(1, -1).map((stop) => ({
      //   location: {
      //     lat: stop.shippingAddress.lat,
      //     lng: stop.shippingAddress.lng,
      //   },
      // }));
      // const directionsService = new google.maps.DirectionsService();

      // const req = {
      //   destination,
      //   origin: {
      //     lat: stops[0].shippingAddress.lat,
      //     lng: stops[0].shippingAddress.lng,
      //   },
      //   travelMode: mode.toUpperCase(),
      //   waypoints,
      // };

      // const splitWays = waypoints.reduce((resultArray, item, index) => {
      //   const chunkIndex = Math.floor(index / 25);

      //   if (!resultArray[chunkIndex]) {
      //     resultArray[chunkIndex] = [];
      //   }

      //   resultArray[chunkIndex].push(item);

      //   return resultArray;
      // }, []);

      setCustomersState((prev) => ({ ...prev, loading: true }));
      // Promise.all(
      //   splitWays.map((ways) =>
      //     directionsService.route({ ...req, waypoints: ways })
      //   )
      // )
      // .then(() => {
      const preparedData = {
        name,
        representativesIds: assignedReps.length
          ? assignedReps.map((rep) => rep.id)
          : routeId
          ? []
          : undefined,
        priority,
        note,
        allowAssignedRepsToUpdate,
        customerIds: stops.map((stop) => stop.id),
        activationDate,
      };

      (routeId
        ? updateRouteService(preparedData, routeId)
        : createRouteService(preparedData)
      )
        .then(() => {
          dispatch(setFormChangedAction(false));
          setCustomersState((prev) => ({
            ...prev,
            loading: false,
          }));
          navigate("/routes");
          success(`Route ${routeId ? "updated" : "created"}`);
        })
        .catch((err) => {
          error(err?.response?.data?.message);
          setCustomersState((prev) => ({
            ...prev,
            loading: false,
          }));
        });
      // })
      // .catch(() =>
      //   setCustomersState((prev) => ({
      //     ...prev,
      //     warningOpen: true,
      //     loading: false,
      //   }))
      // );
    },
    [
      customersState?.available,
      customersState?.inaccessibleCustomers?.length,
      dispatch,
      navigate,
      routeId,
    ]
  );

  const onSubmit = useCallback(
    (data) => {
      const hasArchivedStops = data.stops.some(
        (stop) => stop.status === CUSTOMER_STATUS_FILTERS.inactive
      );
      if (hasArchivedStops) {
        dispatch(
          openConfirmDialogAction({
            title: "Update Route Confirmation",
            text: "Please note that any archived stops in the route will also be removed when you update the route. If you want to keep these stops, please make sure to remove them from the route manually before updating.",
            buttons: (
              <>
                <StyledButton
                  label="Keep Archived Stops"
                  variant="outlined"
                  onClick={() => dispatch(setConfirmIsOpenAction(false))}
                />
                <StyledButton
                  label="Update Route"
                  variant="contained"
                  onClick={() => {
                    dispatch(setConfirmIsOpenAction(false));
                    onProceedSubmit(data, hasArchivedStops);
                  }}
                />
              </>
            ),
          })
        );
        return;
      }

      onProceedSubmit(data, hasArchivedStops);
    },
    [onProceedSubmit, dispatch]
  );

  return { routeId, onSubmit, customersState, setCustomersState };
};
