import { func, number, object } from "prop-types";

import { classes } from "./styles";

import { Box, Grid, Paper, Typography } from "@mui/material";
import { ArrowDownIcon, ArrowUpIcon } from "components/Icons";
import { LargeCheckbox } from "components";

export const SORTABLE_COLUMNS = {
  CATEGORY: { sortableField: "sort_name" },
};

export const CategoryHeader = ({
  handleSetSort,
  quickSort,
  categoriesState,
  onSelectAll,
  checkedCount,
  totalCountOfElements,
}) => {
  const { loading, count } = categoriesState || {};

  const isChecked =
    !!totalCountOfElements && checkedCount >= totalCountOfElements;

  const isIntermediateChecked =
    checkedCount > 0 && checkedCount < totalCountOfElements;

  const CATEGORY_HEADER = [
    {
      label: "",
      xs: 0.39,
      element: (
        <LargeCheckbox
          disabled={loading || !count}
          formSx={{ m: 0 }}
          sx={{
            mt: isIntermediateChecked ? "1.5px" : 0,
            padding: 0,
            "& .MuiSvgIcon-root": {
              opacity: loading || !count ? 0.5 : 1,
              ml: isChecked ? "-2.5px" : 0,
            },
          }}
          size={20}
          checkedProps={{
            size: 19.5,
          }}
          indeterminate={isIntermediateChecked}
          checked={isChecked}
          onClick={(e) => e.stopPropagation()}
          onChange={() => onSelectAll(!isChecked)}
          indeterminateProps={{
            size: 20.25,
            color: "#47a06d",
            fill: "#ffff",
            rectProps: {
              width: "13.2",
            },
          }}
          customIcon
        />
      ),
      style: { pl: "16px" },
    },
    {
      label: "CATEGORY",
      xs: 7,
      style: { pl: "45px" },
    },
    {
      label: "PRODUCT",
      xs: 1.5,
      style: {},
    },
  ];

  return (
    <Paper
      elevation={0}
      variant="outlined"
      square
      component={Grid}
      container
      columns={9}
      sx={classes.paperWrapper}
    >
      {CATEGORY_HEADER.map(({ label, xs, style, element }, index) => (
        <Grid
          item
          xs={xs}
          key={index}
          sx={{
            ...classes.grid,
            ...style,
            cursor: !!SORTABLE_COLUMNS[label] && "pointer",
          }}
          onClick={() =>
            SORTABLE_COLUMNS[label] &&
            handleSetSort(SORTABLE_COLUMNS[label].sortableField)
          }
        >
          {!!label && <Typography sx={classes.typography}>{label}</Typography>}
          {element && element}
          <Box
            sx={{
              "& svg": {
                mb: "1px",
                ml: "2px",
              },
            }}
          >
            {SORTABLE_COLUMNS[label] ? (
              quickSort[SORTABLE_COLUMNS[label].sortableField] === "desc" ? (
                <ArrowDownIcon fill="#6A6A6A" />
              ) : (
                <ArrowUpIcon fill="#6A6A6A" />
              )
            ) : null}
          </Box>
        </Grid>
      ))}
    </Paper>
  );
};

CategoryHeader.propTypes = {
  quickSort: object,
  categoriesState: object,
  handleSetSort: func,
  onSelectAll: func,
  checkedCount: number,
  totalCountOfElements: number,
};

CategoryHeader.defaultProps = {
  quickSort: {},
  handleSetSort: () => {},
};
