import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { removeAllTokens } from "./auth";
import { deleteCurrentUser } from "redux/actions/auth";

const signOutPath = "Sign Out";

export const useRedirect = () => {
  const { path } = useSelector(({ confirmDialogs }) => confirmDialogs || {});

  const defaultNavigate = useNavigate();

  const dispatch = useDispatch();

  const navigate = (redirectPath, state) => {
    const navigatePath = path || redirectPath;

    if (navigatePath === signOutPath) {
      removeAllTokens();
      localStorage.clear();
      dispatch(deleteCurrentUser());
      window.location.reload();
      return;
    }
    defaultNavigate(path || redirectPath, state);
  };

  return navigate;
};
