export const FORMS_FILTER = "FORMS_FILTER";
export const RESET_FORMS_FILTER = "RESET_FORMS_FILTER";
export const FORMS_SWITCH = "FORMS_SWITCH";
export const RESET_FORMS_SWITCH = "RESET_FORMS_SWITCH";

export const formsFilterAction = (payload) => ({
  type: FORMS_FILTER,
  payload,
});

export const formsSwitchAction = (payload) => ({
  type: FORMS_SWITCH,
  payload,
});

export const resetFormsFilterAction = () => ({
  type: RESET_FORMS_FILTER,
});

export const resetFormsSwitchAction = () => ({
  type: RESET_FORMS_SWITCH,
});
