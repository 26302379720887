import { useCallback, useMemo, useState } from "react";
import PropTypes, { array, object, number, string, bool } from "prop-types";
import {
  Tooltip,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Text,
} from "recharts";
import {
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import DateChip from "./components/DateChip";
import { useBreakpoint } from "helpers/useBreakpoint";
import pluralize from "pluralize";
import {
  formatLargeNumber,
  separateNumWithComma,
  truncateText,
} from "helpers/helpers";

const DAY_NAMES = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const MONTH_NAMES = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];
const setMonth = (month) => MONTH_NAMES[parseInt(month, 10) - 1] || "";
const setWeek = (date) => DAY_NAMES[new Date(date).getDay()] || "";
const extractYear = (item) => {
  if (item.year) return parseInt(item.year, 10);
  if (item.month) return parseInt(item.month.split("-")[0], 10);
  if (item.week) return parseInt(item.week.split("-")[0], 10);
  if (item.date) return new Date(item.date).getFullYear();
  return null;
};

const SalesChart = ({ chartList, currentFilterDate, loadingChartList }) => {
  const breakpoint = useBreakpoint();
  const [radioValue, setRadioValue] = useState("sales");

  const styles = {
    paper: {
      alignItems: "center",
      border: "1px solid #D5D9D9",
      maxHeight: "296px",
    },
    text: {
      fontSize: "16px",
      fontWeight: 500,
      color: "#1C1C19",
    },
  };

  const formatDateForDisplay = useCallback((type, value, isSameYear) => {
    switch (type) {
      case "date": {
        const [month, day, year] = value.split("/");
        return `${setMonth(month)} ${isSameYear ? day : `${day} 20${year}`}`;
      }
      case "week": {
        const [year, week] = value.split("-");

        const firstDayOfYear = new Date(year, 0, 1);
        const startOfWeek = new Date(firstDayOfYear);
        startOfWeek.setDate(
          firstDayOfYear.getDate() + (week - 1) * 7 - firstDayOfYear.getDay()
        );

        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);

        const formattedDate = `${setMonth(
          endOfWeek.getMonth() + 1
        )} ${endOfWeek.getDate()}`;
        return isSameYear ? formattedDate : `${formattedDate} ${year}`;
      }
      case "month": {
        const [year, month] = value.split("-");
        return isSameYear ? setMonth(month) : `${setMonth(month)} ${year}`;
      }
      default:
        return value;
    }
  }, []);

  const list = useMemo(() => {
    if (!chartList || chartList.length === 0) return [];

    const isSameYear =
      extractYear(chartList[0]) ===
      extractYear(chartList[chartList.length - 1]);

    const formattedList = chartList.map((el) => {
      const sales = el.sales ?? 0;
      const units = el.units ?? 0;

      if (["This week", "Last week"].includes(currentFilterDate)) {
        const formated = setWeek(el.date);
        return {
          date: formated,
          nameTip: formated,
          sales: el.sales,
          units: el.units,
        };
      }
      if (["This year", "Last year"].includes(currentFilterDate)) {
        const formated = setMonth(el?.month?.split("-")[1]);
        return {
          date: formated,
          nameTip: formated,
          sales,
          units,
        };
      }
      if (el.hour) {
        return {
          date: el.hour,
          nameTip: el.hour,
          sales,
          units,
        };
      }
      if (el.date) {
        const formated = formatDateForDisplay("date", el.date, isSameYear);
        return {
          date: formated,
          nameTip: formated,
          sales,
          units,
        };
      }
      if (el.week) {
        const formated = formatDateForDisplay("week", el.week, isSameYear);
        return {
          date: formated,
          nameTip: formated,
          sales,
          units,
        };
      }
      if (el.month) {
        const formated = formatDateForDisplay("month", el.month, isSameYear);
        return {
          date: formated,
          nameTip: formated,
          sales,
          units,
        };
      }
      if (el.year) {
        return {
          date: el.year,
          nameTip: el.year,
          sales,
          units,
        };
      }
    });
    return formattedList;
  }, [chartList, currentFilterDate, formatDateForDisplay]);

  const CustomizedLabel = ({
    dy = 26,
    textAnchor = "middle",
    xLeft = 0,
    x,
    y,
    payload,
  }) => {
    const setLabel = (currentLabel) => {
      if (typeof currentLabel === "string") return currentLabel;

      return formatLargeNumber(currentLabel);
    };

    return (
      <g transform={`translate(${x},${y})`}>
        <Text
          x={xLeft}
          dy={dy}
          textAnchor={textAnchor}
          fill="#000000"
          fontSize={12}
        >
          {truncateText(setLabel(payload.value), 11)}
        </Text>
      </g>
    );
  };
  CustomizedLabel.propTypes = {
    dy: string,
    textAnchor: string,
    payload: object,
    xLeft: number,
    x: number,
    y: number,
    stroke: string,
  };

  const CustomTooltip = ({ active, payload }) => {
    if (!active || !payload || !payload.length) return null;
    return (
      <div
        style={{
          backgroundColor: "#FFFFFF",
          border: "1px solid #D5D9D9",
          borderRadius: "4px",
          position: "relative",
          textAlign: "center",
        }}
      >
        <p
          style={{
            fontSize: "12px",
            paddingLeft: "10px",
            paddingRight: "10px",
            margin: 0,
          }}
        >{`${payload[0]?.payload?.nameTip}`}</p>
        {payload[0]?.name === "units" && (
          <p
            style={{
              fontSize: "12px",
              paddingLeft: "10px",
              paddingRight: "10px",
              margin: 0,
            }}
          >
            {payload[0]?.payload?.units}{" "}
            {pluralize("case", payload[0]?.payload?.units)}
          </p>
        )}
        {payload[0]?.name === "sales" && (
          <p
            style={{
              fontSize: "12px",
              paddingLeft: "10px",
              paddingRight: "10px",
              margin: 0,
            }}
          >
            {`${separateNumWithComma(payload[0]?.payload?.sales)} USD`}
          </p>
        )}
      </div>
    );
  };
  CustomTooltip.propTypes = {
    active: bool,
    payload: array,
    label: PropTypes.oneOfType([string, number]),
  };

  const handleChangeRadioValue = (e) => setRadioValue(e.target.value);

  return (
    <Paper
      className="paperChart"
      sx={styles.paper}
      elevation={0}
      component={Grid}
      container
      data-testid="sales-chart"
    >
      <Grid item xs={12} container>
        <Grid sx={{ paddingLeft: "20px", paddingTop: "12px" }} item xs={5.5}>
          <Typography sx={{ ...styles.text, display: "inline" }} mr="8px">
            Sales{" "}
          </Typography>
          <DateChip date={currentFilterDate} />
        </Grid>
        <Grid
          sx={{
            paddingRight: "20px",
            paddingTop: "8px",
            paddingBottom: "8px",
            textAlign: "right",
          }}
          item
          xs={6.5}
        >
          <FormControl>
            <RadioGroup
              sx={{
                display: "flex",
                justifyContent: "space-around",
              }}
              aria-labelledby="radio-btns-group"
              name="radio-btns-group"
              row
              value={radioValue}
              onChange={handleChangeRadioValue}
            >
              <FormControlLabel
                value={"sales"}
                sx={{ marginRight: 0 }}
                labelPlacement="start"
                label={
                  <>
                    <Box
                      sx={{
                        display: "inline-block",
                        width: breakpoint === "xl" ? "36px" : "18px",
                        height: "6px",
                        borderTop: "2px solid #409A65",
                        marginRight: "6px",
                      }}
                    />
                    <Typography sx={{ fontSize: "12px" }} variant="caption">
                      Sales (USD)
                    </Typography>
                  </>
                }
                control={
                  <Radio
                    sx={{
                      "& svg": {
                        width: "16px",
                        height: "16px",
                      },
                    }}
                  />
                }
              />
              <FormControlLabel
                sx={{ m: 0 }}
                value={"units"}
                labelPlacement="start"
                label={
                  <>
                    <Box
                      sx={{
                        display: "inline-block",
                        width: breakpoint === "xl" ? "36px" : "18px",
                        height: "6px",
                        borderTop: "2px solid #F0C401",
                        marginLeft: breakpoint === "xl" ? "20px" : "10px",
                        marginRight: "6px",
                      }}
                    />
                    <Typography fontSize={12} variant="caption">
                      Cases Ordered
                    </Typography>
                  </>
                }
                control={
                  <Radio
                    sx={{
                      "& svg": {
                        width: "16px",
                        height: "16px",
                      },
                    }}
                  />
                }
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {loadingChartList ? (
          <Box
            height="247px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            color="#9C9C94"
          >
            <CircularProgress />
          </Box>
        ) : (
          <LineChart
            // width={916}
            width={document.querySelector(".paperChart")?.offsetWidth - 2}
            height={247}
            data={list}
            margin={{ top: 0, right: 0, bottom: 20, left: 0 }}
          >
            <Line
              dot={false}
              dataKey={radioValue}
              stroke={radioValue === "sales" ? "#409A65" : "#F0C401"}
              strokeWidth={1.5}
            />
            <CartesianGrid stroke="rgba(79, 79, 73, 0.1)" vertical={false} />
            <XAxis
              dataKey="date"
              tickSize={0}
              tick={<CustomizedLabel />}
              padding={{ left: 0, right: 0 }}
              interval={
                ["Today", "Yesterday"].includes(currentFilterDate)
                  ? 1
                  : "preserveEnd"
              }
            />
            <YAxis
              tickSize={0}
              tick={<CustomizedLabel dy="4" textAnchor="end" xLeft={-10} />}
              padding={{ top: 20 }}
            />
            <Tooltip
              allowEscapeViewBox={{ y: true }}
              offset={-40}
              content={<CustomTooltip />}
            />
          </LineChart>
        )}
      </Grid>
    </Paper>
  );
};
SalesChart.propTypes = {
  chartList: array,
  currentFilterDate: string,
  loadingChartList: bool,
};

export default SalesChart;
