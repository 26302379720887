/* eslint-disable no-useless-escape */
import * as Yup from "yup";

export const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .required("This field is required")
      .max(100, "Max 100 characters"),
    parentCategoryId: Yup.mixed().test(
      "parentCategoryValidation",
      "This field is required",
      (value, ctx) => {
        const { isSubcategory } = ctx?.parent || {};

        if (isSubcategory) return !!value;

        return true;
      }
    ),
  });
