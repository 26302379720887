import { Grid, Paper, Typography } from "@mui/material";

const MSDHeader = () => {
  return (
    <Paper
      sx={{
        backgroundColor: "#EEEEEE",
        borderRadius: "0",
        borderLeft: "none",
        borderRight: "none",
        alignItems: "center",
        pl: "16px",
        "& .MuiTypography-root": {
          textTransform: "uppercase",
          fontSize: "11px",
          color: "#1A1A1A",
        },
      }}
      elevation={0}
      variant="outlined"
      square
      component={Grid}
      container
      columns={24}
      height="48px"
    >
      <Grid item xs={2.3} />
      <Grid item xs={3.3}>
        <Typography>Discount name</Typography>
      </Grid>
      <Grid item xs={4.7}>
        <Typography>VALUE</Typography>
      </Grid>
      <Grid item xs={4.8}>
        <Typography>MINIMUM REQUIREMENTS</Typography>
      </Grid>
      <Grid item xs={3.2}>
        <Typography>END DATE</Typography>
      </Grid>
      <Grid item xs={2.7}>
        <Typography>ALLOWED USAGE</Typography>
      </Grid>
      <Grid item xs>
        <Typography>USED</Typography>
      </Grid>
    </Paper>
  );
};

export default MSDHeader;
