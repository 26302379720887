import { array, func, bool, object, string } from "prop-types";
import { Controller } from "react-hook-form";
import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TAG_TYPES } from "helpers/useTagsActions";
import { useMemo } from "react";
import { useRepsPermissions } from "helpers/hooks";

export const TagsBlock = ({
  selectedTagsList,
  handleDeleteTag,
  handlePressEnter,
  handleBlur,
  control,
  tagsList,
  handleAddTag,
  isBulk,
  loading,
  type,
}) => {
  const repPermissions = useRepsPermissions();

  const disabled = useMemo(() => {
    if (type === TAG_TYPES.product) {
      return !!repPermissions && !repPermissions?.catalog?.product_tags;
    }
    if (type === TAG_TYPES.customer) {
      return !!repPermissions && !repPermissions?.customers?.customer_tags;
    }
  }, [repPermissions, type]);

  return (
    <Stack
      maxWidth="544px"
      width="100%"
      minWidth="544px"
      flex="1 0 auto"
      px={4.25}
      py={3.5}
    >
      <Grid container>
        {isBulk && TAG_TYPES.customer === type && (
          <Grid xs={12} item mb="18px">
            <Typography fontSize={18} color="#3F3F3F">
              Choose Tags
            </Typography>
          </Grid>
        )}
        <Grid xs={12} item>
          <Controller
            render={({ field }) => (
              <FormControl fullWidth>
                <TextField
                  size="small"
                  disabled={disabled}
                  inputProps={{ maxLength: 50 }}
                  InputProps={{
                    sx: {
                      minHeight: "43px",
                      fontSize: 13,
                      flexDirection: "column",
                      pt: selectedTagsList.length ? "8px" : "4px",
                      pr: "14px",
                      "& input": { pl: "14px" },
                    },
                    startAdornment: (
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 0.5,
                        }}
                      >
                        {selectedTagsList?.map((tag) => (
                          <Chip
                            sx={{
                              border: "1px solid #D4D4D4",
                              bgcolor: "#FAFAFB",
                              fontSize: 14,
                              color: "#5F6368",
                              cursor: "pointer",
                              maxWidth: "220px",
                            }}
                            key={tag?.id}
                            label={tag?.tag}
                            size="small"
                            deleteIcon={
                              <CloseIcon
                                size="15"
                                style={{ fill: "#707070" }}
                              />
                            }
                            onDelete={() => handleDeleteTag(tag?.id)}
                          />
                        ))}
                      </Box>
                    ),
                  }}
                  onKeyDown={handlePressEnter}
                  {...field}
                  onBlur={handleBlur}
                />
              </FormControl>
            )}
            control={control}
            name="tagInput"
          />
        </Grid>

        <Grid xs={12} item>
          <Typography fontSize={13} color="#9F9F9F">
            Used to categorize {type}s
          </Typography>
        </Grid>

        <Grid mt={2} xs={12} item container gap={1.5}>
          {loading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "476px",
                height: "100px",
              }}
            >
              <CircularProgress size={30} />
            </Box>
          )}

          {!loading &&
            tagsList?.map((tag) => (
              <Chip
                disabled={selectedTagsList.some((t) => t?.id === tag?.id)}
                sx={{
                  border: "1px solid #D4D4D4",
                  bgcolor: "#FAFAFB",
                  fontSize: 14,
                  color: "#5F6368",
                  cursor: "pointer",
                  paddingBottom: "1px",
                  maxWidth: "220px",
                }}
                key={tag?.id}
                label={tag?.tag}
                size="small"
                onClick={(e) => {
                  // Avoid adding a tag when tapping the space key
                  if (e.code) return;
                  handleAddTag(tag);
                }}
              />
            ))}
        </Grid>
      </Grid>
    </Stack>
  );
};

TagsBlock.propTypes = {
  selectedTagsList: array,
  handleDeleteTag: func,
  handlePressEnter: func,
  handleBlur: func,
  control: object,
  tagsList: array,
  handleAddTag: func,
  isBulk: bool,
  loading: bool,
  type: string,
};
TagsBlock.defaultProps = {
  selectedTagsList: [],
  handleDeleteTag: () => {},
  handlePressEnter: () => {},
  handleBlur: () => {},
  control: {},
  tagsList: [],
  handleAddTag: () => {},
  isBulk: false,
  loading: false,
};
