import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
  Box,
} from "@mui/material";
import { Controller, useFieldArray, useWatch } from "react-hook-form";
import { object, bool, func, string } from "prop-types";
import {
  UploadFile,
  StyledAvatar,
  StyledButton,
  StyledTextField,
  StyledSelect,
  Loader,
  StyledTooltip,
} from "components";
import {
  useAdmin,
  setWebsiteMask,
  photoUrl,
  getTimeZonesList,
  getPhoneCodes,
  onPastePhone,
} from "helpers/helpers";
import TableHeader from "../TableHeader";
import TableItem from "../TableItem";
import ContactsTable from "./components/ContactsTable";
import {
  INDUSTRIES,
  MAX_UPLOAD_FILE_SIZE_DISTRIBUTIVE_MB,
  MAX_UPLOAD_FILE_RESOLUTION_DISTRIBUTIVE_MP,
} from "utils/constants";
import useStyles from "../../newStyles";
import NumberFormat from "react-number-format";
import { useLocation } from "react-router-dom";
import { useEffect, useMemo, useRef, useState, useCallback } from "react";
import { setCurrentUserAction } from "redux/actions/auth";
import { useDispatch } from "react-redux";
import { error, success } from "utils/notifications";
import { useRepsPermissions } from "helpers/hooks";
import { setCurrentUser } from "redux/actions/auth";
import { updateDistributorService } from "services/account";

const SummaryTab = ({
  control,
  isEdit,
  setValue,
  clearErrors,
  trigger,
  errPhoneStatus,
  distributorId,
  handleRemovePhoto,
  userCountry,
}) => {
  const isAdmin = useAdmin();
  const repPermissions = useRepsPermissions();

  const formField = useWatch({ control });
  const { state } = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [progressPhotoUpload, setProgressPhotoUpload] = useState(false);
  const [preparingPhoto, setPreparingPhoto] = useState(false);

  const phoneCodeList = getPhoneCodes(userCountry);
  const timeZonesState = getTimeZonesList(userCountry);

  useEffect(() => {
    if (
      state?.type === "onboarding" &&
      !formField.contacts.length &&
      state?.profileTab === 0
    ) {
      handleAddContact();
      trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "contacts",
  });

  const handleUpdateContacts = useCallback(
    (data) => {
      updateDistributorService(data)
        .then((res) => {
          dispatch(setCurrentUser(res));
          success("Contacts updated");
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          error(err?.response?.data?.message || "Something went wrong.");
        });
    },
    [dispatch]
  );

  const handleAddContact = () => {
    append({
      name: "",
      role: "",
      email: "",
      phone: "",
      defaultContact: !fields?.length,
      countryPrefix: phoneCodeList[0],
    });
  };

  const handleRemoveContact = (pos, isEdit) => {
    remove(pos);
    if (!isEdit) {
      const contacts = fields
        .map(({ distributorId, countryPrefix, ...c }) => ({
          ...c,
          phone: `${countryPrefix?.phone_code}${c?.phone}`.replace(
            /\s|\(|\)/g,
            ""
          ),
        }))
        .filter((_, index) => index !== pos);
      handleUpdateContacts({
        contacts,
      });
    }
  };

  const handleChangeDefault = (pos) => {
    if (formField.contacts[pos].defaultContact) return;
    const defIndex = formField.contacts.findIndex((c) => c.defaultContact);
    setValue(`contacts.${defIndex}.defaultContact`, false);
    setValue(`contacts.${pos}.defaultContact`, true);
  };

  const [isCroppedDialogOpen, setIsCroppedDialogOpen] = useState(false);

  const tooltipRef = useRef(null);

  const initTooltipData = {
    open: false,
    text: (
      <>
        <Typography component="span" fontSize={12}>
          • Use at least 500 px by 500 px <br /> • Use white or neutral
          background
        </Typography>
        <br />
      </>
    ),
    modifiers: [{ name: "offset", options: { offset: [10, 0] } }],
  };

  const [tooltipData, setTooltipData] = useState(initTooltipData);

  const handleUploadChange = (data) => {
    if (isAdmin) return;
    if (data?.fileName) setValue("profilePhoto", data);
    dispatch(setCurrentUserAction());
  };

  const profilePhoto = useMemo(() => {
    if (formField?.profilePhoto?.fileName)
      return photoUrl(formField?.profilePhoto?.fileName);
    if (typeof formField?.profilePhoto === "string")
      return formField?.profilePhoto;
    return "";
  }, [formField?.profilePhoto]);

  return (
    <>
      <Loader isLoading={progressPhotoUpload || preparingPhoto} />
      <Box>
        <Box display="flex" gap="47px">
          <Box flex="1 1 50%">
            <TableHeader title="Business details" />
            <Box>
              <TableItem
                title="Legal Business Name"
                value={formField.name}
                isEdit={isEdit}
                EditField={
                  <Controller
                    render={({ field, fieldState: { error } }) => (
                      <StyledTextField
                        fullWidth
                        size="large"
                        InputProps={{ className: classes.editInput }}
                        error={error?.message || ""}
                        {...field}
                      />
                    )}
                    name="name"
                    control={control}
                  />
                }
              />
              <TableItem
                title="FED Tax ID (EIN)"
                value={formField.federalTaxId ? formField.federalTaxId : "-"}
                isEdit={isEdit}
                EditField={
                  <Controller
                    render={({ field, fieldState: { error } }) => (
                      <NumberFormat
                        customInput={StyledTextField}
                        noErrorMessage
                        fullWidth
                        format="##-#######"
                        size="large"
                        InputProps={{ className: classes.editInput }}
                        error={error}
                        {...field}
                      />
                    )}
                    name="federalTaxId"
                    control={control}
                  />
                }
              />
              <TableItem
                title="Industry"
                value={formField.companyIndustry}
                isEdit={isEdit}
                EditField={
                  <Controller
                    render={({ field, fieldState: { error } }) => (
                      <StyledSelect
                        fullWidth
                        size="large"
                        error={error ? error.message : ""}
                        notched={false}
                        height="43px"
                        fontSize="15px"
                        borderRadius="9px"
                        sx={{
                          "& svg": {
                            transform: "scale(1)",
                          },
                        }}
                        {...field}
                      >
                        {INDUSTRIES.map((industry) => (
                          <MenuItem key={industry} value={industry}>
                            {industry}
                          </MenuItem>
                        ))}{" "}
                      </StyledSelect>
                    )}
                    name="companyIndustry"
                    control={control}
                  />
                }
              />
              <TableItem
                title="Primary Time Zone"
                value={
                  timeZonesState.find((tz) => tz?.value === formField.timeZone)
                    ?.name ?? formField.timeZone
                }
                isEdit={isEdit}
                EditField={
                  <Controller
                    render={({ field, fieldState: { error } }) => (
                      <StyledSelect
                        fullWidth
                        size="small"
                        error={error ? error.message : ""}
                        notched={false}
                        height="43px"
                        fontSize="15px"
                        borderRadius="9px"
                        sx={{
                          "& svg": {
                            transform: "scale(1)",
                          },
                        }}
                        {...field}
                      >
                        {timeZonesState.map((zone, index) => (
                          <MenuItem
                            key={zone.value || index}
                            value={zone.value || ""}
                          >
                            {zone.name || ""}
                          </MenuItem>
                        ))}
                      </StyledSelect>
                    )}
                    name="timeZone"
                    control={control}
                  />
                }
              />
              <Box pl="27px" pt="12px" display="flex" alignItems="center">
                <StyledAvatar
                  sx={{
                    height: "61px",
                    width: "61px",
                    border: "1px solid #CBCBCB",
                  }}
                  src={profilePhoto}
                  stringStyle={{ fontSize: "25px" }}
                  name={formField.name}
                />
                <Box>
                  {isAdmin || !!repPermissions ? null : (
                    <Box display="flex" alignItems="center">
                      <StyledTooltip
                        open={tooltipData.open && !isCroppedDialogOpen}
                        title={tooltipData.text}
                        arrow
                        placement="top"
                      >
                        <Box
                          ref={tooltipRef}
                          onMouseEnter={() =>
                            setTooltipData((prev) => ({ ...prev, open: true }))
                          }
                          onMouseLeave={() => {
                            if (tooltipData.show) return;
                            setTooltipData(initTooltipData);
                          }}
                          onMouseDown={() => setTooltipData(initTooltipData)}
                        >
                          <UploadFile
                            label="Upload new logo"
                            maxUploadFileSizeMb={{
                              count: MAX_UPLOAD_FILE_SIZE_DISTRIBUTIVE_MB,
                              callback: (fileName) => {
                                error(
                                  <>
                                    <Typography component="span" fontSize={12}>
                                      Photo upload failed
                                      <br />
                                      {fileName}
                                      <br />
                                      Exceeds maximum file size of{" "}
                                      {MAX_UPLOAD_FILE_SIZE_DISTRIBUTIVE_MB} MB
                                    </Typography>
                                    <br />
                                  </>
                                );
                              },
                            }}
                            maxUploadFileResolutionMp={{
                              count: MAX_UPLOAD_FILE_RESOLUTION_DISTRIBUTIVE_MP,
                              callback: (fileName) => {
                                error(
                                  <>
                                    <Typography component="span" fontSize={12}>
                                      Photo upload failed
                                      <br />
                                      {fileName}
                                      <br />
                                      Exceeds maximum image resolution of{" "}
                                      {
                                        MAX_UPLOAD_FILE_RESOLUTION_DISTRIBUTIVE_MP
                                      }{" "}
                                      MP
                                    </Typography>
                                    <br />
                                  </>
                                );
                              },
                            }}
                            accept="image/png, image/gif, image/jpeg"
                            wrapperProps={{ sx: { p: "5px" } }}
                            onChange={handleUploadChange}
                            Wrapper={Button}
                            path="users/distributors/profile-photo"
                            withCropper
                            dialogSize="small"
                            settingsCropper={{ cropShape: "round" }}
                            uploadData={{
                              distributorId,
                              type: "PROFILE_DISTRIBUTOR",
                              setGroupContentId: (groupContentId) => {
                                if (groupContentId)
                                  setValue("groupContentId", groupContentId);
                              },
                            }}
                            setIsOpen={setIsCroppedDialogOpen}
                            progressUpload={setProgressPhotoUpload}
                            {...{ preparingPhoto, setPreparingPhoto }}
                          />
                        </Box>
                      </StyledTooltip>
                      {formField.profilePhoto && (
                        <>
                          <Typography color="#707070">•</Typography>
                          <StyledButton
                            label="Remove logo"
                            fontSize="14px"
                            sx={{ p: "5px" }}
                            onClick={() => {
                              if (isAdmin) return;
                              if (
                                formField?.groupContentId ||
                                formField?.profilePhoto?.id
                              )
                                handleRemovePhoto();
                            }}
                          />
                        </>
                      )}
                    </Box>
                  )}
                  <Box>
                    <Typography
                      ml="5px"
                      mt="-3px"
                      textAlign="start"
                      fontSize={12}
                      color="#D4D4D4"
                    >
                      • Use at least 500 px by 500 px <br /> • Use white or
                      neutral background
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box flex="1 1 50%">
            <TableHeader title="Business Contact" />
            <Box>
              <TableItem
                title="Business Email"
                value={formField.email}
                isEdit={isEdit}
              />

              <TableItem
                title="Business Phone"
                value={
                  formField.phone && formField?.countryPrefics?.phone_code
                    ? `${formField?.countryPrefics?.phone_code}${formField.phone}`
                    : "-"
                }
                isEdit={isEdit}
                EditField={
                  <Controller
                    render={({ field, fieldState: { error } }) => (
                      <NumberFormat
                        customInput={StyledTextField}
                        fullWidth
                        format={formField?.countryPrefics?.format}
                        mask="_"
                        size="large"
                        InputProps={{
                          className: classes.editInput,
                          startAdornment: (
                            <FormControl>
                              <Controller
                                render={({ field }) => {
                                  return (
                                    <Select
                                      sx={{
                                        borderRadius: "0",
                                        marginRight: 1,
                                        "& .MuiSelect-select": {
                                          minWidth: "50px",
                                        },
                                        "& .MuiOutlinedInput-notchedOutline": {
                                          borderWidth: "0 1px 0 0 !important",
                                          borderColor: "#D5D9D9 !important",
                                        },
                                      }}
                                      size="small"
                                      renderValue={() =>
                                        field?.value?.phone_code
                                      }
                                      {...field}
                                      value={JSON.stringify(field?.value)}
                                    >
                                      {phoneCodeList.map((code) => {
                                        return (
                                          <MenuItem
                                            key={code.label}
                                            value={JSON.stringify(code)}
                                          >
                                            {code.phone_code} {code.label}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  );
                                }}
                                name="countryPrefics"
                                control={control}
                              />
                            </FormControl>
                          ),
                        }}
                        error={
                          errPhoneStatus.status
                            ? errPhoneStatus.message
                            : error?.message || ""
                        }
                        {...field}
                        autoComplete="new-password"
                        onPaste={(e) => {
                          const prepareVal = (v) => {
                            if (/^\+[\d]+/.test(v)) {
                              return v.substring(2);
                            }
                            return v;
                          };

                          onPastePhone({
                            e,
                            setValue: (type, val) =>
                              setValue(type, prepareVal(val)),
                            currentValue: formField.phone,
                          });
                        }}
                      />
                    )}
                    name="phone"
                    control={control}
                  />
                }
              />
              <TableItem
                title="Website"
                value={setWebsiteMask(formField.website)}
                isEdit={isEdit}
                EditField={
                  <Controller
                    render={({ field, fieldState: { error } }) => (
                      <StyledTextField
                        fullWidth
                        size="large"
                        InputProps={{ className: classes.editInput }}
                        error={error?.message || ""}
                        {...field}
                      />
                    )}
                    name="website"
                    control={control}
                  />
                }
              />
            </Box>
          </Box>
        </Box>
        <Box mt="26px">
          <ContactsTable
            contacts={fields}
            phoneCodeList={phoneCodeList}
            {...{
              isEdit,
              control,
              setValue,
              clearErrors,
              handleAddContact,
              handleRemoveContact,
              handleChangeDefault,
              handleUpdateContacts,
            }}
          />
        </Box>
      </Box>
    </>
  );
};

SummaryTab.propTypes = {
  control: object,
  isEdit: bool,
  setValue: func,
  clearErrors: func,
  trigger: func,
  errPhoneStatus: object,
  distributorId: string,
  handleRemovePhoto: func,
  userCountry: string,
};

export default SummaryTab;
