import { useCallback, useMemo, useState } from "react";
import { bool, array, func, string, number, object } from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { CircularProgress, Stack } from "@mui/material";

import PhotoGroupPopup from "../../../CustomersPage/components/GalleryTab/components/PhotoGroupPopup/PhotoGroupPopup";
import AssignedRepsPopper from "../../../CustomersPage/components/CustomersTab/components/AssignedRepsPopper/AssignedRepsPopper";

import NoActivity from "../NoActivity/NoActivity";
import { ItemList, LoadingBlock, Switcher } from "./components";

import { groupIdsAction } from "../../../../redux/actions/gallery";
import MapPopup from "../../../CustomersPage/pages/NewCustomerPage/MapPopup/MapPopup";
import NotAvailable from "../NotAvailable";
import { createSelector } from "reselect";
import { currentUserSelector } from "../../../../redux/selectors/auth";

import { getPhotoGroupByIdService } from "../../../../services/gallery";
import { error } from "../../../../utils/notifications";
import { getActivitiesService } from "../../../../services/dashboard";
import { InfiniteScrollWrapper, NoteDrawer, TaskDrawer } from "components";
import { ReportsIcon } from "components/Icons";

const selector = createSelector(currentUserSelector, (currentUser) => ({
  currentUser,
}));

export const ActivitiesList = ({
  isLoading,
  activitiesList,
  handleFetchActivities,
  dataLength,
  hasMore,
  currentTabActivities,
  setCurrentTabActivities,
  params,
  notAvailable,
  getActivities,
}) => {
  const { currentUser } = useSelector(selector);
  const dispatch = useDispatch();

  const [isOpenProductDialog, setIsOpenProductDialog] = useState(false);
  const [assignedAnchorEl, setAssignedAnchorEl] = useState(null);
  const [popoverRep, setPopoverRep] = useState(null);
  const [assignedPopperOpen, setAssignedPopperOpen] = useState(false);
  const [mapData, setMapData] = useState(null);

  const [currentPage, setCurrentPage] = useState(null);
  const [galleryGroupItem, setGalleryGroupItem] = useState(null);

  const [noteDialogState, setNoteDialogState] = useState({
    open: false,
    data: null,
  });

  const [taskDialogState, setTaskDialogState] = useState({
    open: false,
    data: null,
  });

  const handleCloseCustomerNotes = useCallback(() => {
    setNoteDialogState({
      open: false,
      data: null,
    });
  }, []);

  const handleCustomerTaskDrawer = useCallback((open, data) => {
    setTaskDialogState({
      open,
      data,
    });
  }, []);

  const handleOpenCustomerNotes = useCallback((data) => {
    setNoteDialogState({
      open: true,
      data,
    });
  }, []);

  const handleOpenProductDialog = useCallback(
    (el) => {
      getActivitiesService({ ...params, type: "photo_group_added" })
        .then((res) => {
          const groupIds = res?.rows
            ?.map((el) => el?.photoGroups)
            .flat()
            .map((el) => el?.id);
          dispatch(groupIdsAction(groupIds));
          const index = groupIds.findIndex((group) => group === el?.id);
          setCurrentPage(index + 1);
        })
        .then(() => {
          return new Promise(() => {
            getPhotoGroupByIdService(el?.id)
              .then((res) => {
                setGalleryGroupItem(res);
              })
              .catch((err) => {
                // eslint-disable-next-line no-console
                console?.error(err);
                error("Something went wrong!");
              });
            setIsOpenProductDialog(true);
          });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err?.message);
        });
    },
    [dispatch, params]
  );

  const handleCloseProductDialog = () => {
    setIsOpenProductDialog(false);
  };

  const handleSetAssignedPopover = (anchor, rep, open) => {
    setAssignedAnchorEl(anchor);
    setPopoverRep(rep);
    setAssignedPopperOpen(open);
  };

  const handleClickCheckin = useCallback((data) => {
    setMapData(data);
  }, []);

  const activitiesContent = useMemo(() => {
    if (isLoading) return <LoadingBlock />;
    if (notAvailable) return <NotAvailable />;
    return activitiesList.length ? (
      <InfiniteScrollWrapper
        id="dashboard-activities-scroll"
        dataLength={dataLength}
        maxHeight="280px"
        hasMore={hasMore}
        loader={<CircularProgress sx={{ ml: "50%", mt: "2px" }} size="20px" />}
        next={handleFetchActivities}
      >
        {activitiesList.map((el) => (
          <ItemList
            key={el?.id}
            item={el}
            timeZone={currentUser?.timeZone}
            distributor={{
              name: currentUser?.name,
              profilePhoto: currentUser?.profilePhoto,
            }}
            handleSetAssignedPopover={handleSetAssignedPopover}
            onOpenProductDialog={handleOpenProductDialog}
            params={params}
            onClickCheckin={handleClickCheckin}
            checkInCustomerRadiusEnabled={
              currentUser?.checkInCustomerRadiusEnabled
            }
            {...{
              handleOpenCustomerNotes,
              handleCustomerTaskDrawer,
            }}
          />
        ))}
      </InfiniteScrollWrapper>
    ) : (
      <NoActivity
        activity="activity"
        icon={<ReportsIcon fill="none" stroke="#ABABAB" />}
      />
    );
  }, [
    activitiesList,
    currentUser?.checkInCustomerRadiusEnabled,
    currentUser?.name,
    currentUser?.profilePhoto,
    currentUser?.timeZone,
    dataLength,
    handleClickCheckin,
    handleCustomerTaskDrawer,
    handleFetchActivities,
    handleOpenCustomerNotes,
    handleOpenProductDialog,
    hasMore,
    isLoading,
    notAvailable,
    params,
  ]);

  return (
    <>
      <PhotoGroupPopup
        user={{
          avatar: currentUser?.profilePhoto,
          name: currentUser?.name,
          id: currentUser?.id,
        }}
        isOpenProductDialog={isOpenProductDialog}
        handleCloseProductDialog={handleCloseProductDialog}
        currentPage={currentPage}
        galleryGroupItem={galleryGroupItem}
      />
      <AssignedRepsPopper
        anchorEl={assignedAnchorEl}
        rep={popoverRep}
        open={assignedPopperOpen}
      />
      <MapPopup
        withHeader
        isOpen={!!mapData}
        handleClose={() => setMapData(null)}
        address={mapData?.customer?.shippingAddress}
        customer={mapData}
        closeBtnStyle={{
          top: 10,
          right: 10,
        }}
      />
      <TaskDrawer
        open={taskDialogState.open}
        onClose={() => handleCustomerTaskDrawer(false, null)}
        data={taskDialogState.data}
        refetchCallback={getActivities}
      />

      {!!noteDialogState.open && (
        <NoteDrawer
          open={noteDialogState.open}
          onClose={handleCloseCustomerNotes}
          data={noteDialogState.data}
          refetchCallback={getActivities}
          titleLabel="Notes"
        />
      )}

      <Switcher
        {...{ currentTabActivities, setCurrentTabActivities }}
        isThirdParty={notAvailable}
      />

      <Stack
        sx={{
          height: "280px",
          maxHeight: "280px",
          overflow: "hidden",
          borderWidth: "0px 1px 1px 1px",
          borderStyle: "solid",
          borderColor: "#D5D9D9",
          borderRadius: "0 0 4px 4px",
          background: !activitiesList.length && "white",
        }}
      >
        {activitiesContent}
      </Stack>
    </>
  );
};

ActivitiesList.propTypes = {
  isLoading: bool,
  activitiesList: array,
  handleFetchActivities: func,
  dataLength: number,
  hasMore: bool,
  currentTabActivities: string,
  setCurrentTabActivities: func,
  params: object,
  notAvailable: bool,
  getActivities: func,
};
ActivitiesList.defaultProps = {
  isLoading: false,
  activitiesList: [],
  getActivities: () => {},
  handleFetchActivities: () => {},
  dataLength: 0,
  hasMore: false,
  currentTabActivities: "",
  setCurrentTabActivities: () => {},
  notAvailable: false,
};
