import { DISCOUNT_APPLICABLE_ON_SCOPES } from "Pages/DiscountsPage/components/NewDiscountPage/NewDiscountsPage.constants";
import pluralize from "pluralize";

export const getMSDDiscountSum = (
  productsList,
  discountsList,
  isPayed,
  chosenFreeCaseProducts
) => {
  const discountSum = productsList.reduce((prevSum, currProd) => {
    // match discount with a product
    const discSum = getSingleMSD(
      currProd,
      discountsList,
      isPayed,
      chosenFreeCaseProducts
    );
    return prevSum + discSum;
  }, 0);
  return discountSum;
};

export const getSingleMSD = (
  product,
  discountsList,
  isPayed,
  chosenFreeCaseProducts
) => {
  const freeCaseProduct = chosenFreeCaseProducts?.find(
    (p) => p?.id === product?.id || p?.id === product?.product?.id
  );
  const matched = discountsList.filter((discount) => {
    const containsDiscount = product.manufacturerDiscountDuplicates?.find(
      (d) => d?.discountDuplicateId === discount?.id
    );
    if (isPayed && !containsDiscount && !product.isNewAdded) return 0;
    // match by manufacturer first
    const matchedByManufacturer =
      discount?.manufacturer?.id === product?.manufacturer?.id ||
      discount?.manufacturer?.id === product?.manufacturerId ||
      discount?.manufacturer?.id === product?.parentProduct?.manufacturer?.id;
    // match by product just to be sure
    const matchedByProduct = discount?.products?.some((disProd) => {
      const discountProductId = disProd?.product?.id || disProd?.productId;
      const productId = product?.product?.id || product?.id;

      return discountProductId === productId;
    });

    const matchedByQuantity =
      discount?.type === "QUANTITY"
        ? product?.discountQty >= 0 && !!freeCaseProduct?.freeCaseChosen
        : discount?.requirements === "MIN_QUANTITY"
        ? product?.quantity >= discount?.minQuantity
        : true;

    const price = product.wholesalePrice || product.price || 0;

    const matchedByMPA =
      discount?.requirements === "MIN_PURCHASE_AMOUNT"
        ? price * product.quantity >= discount.minPurchaseAmount
        : true;

    // if applied to all products, use only first criteria, if applied to specific => use both
    return discount?.productType === "ALL_PRODUCTS"
      ? matchedByManufacturer && matchedByQuantity && matchedByMPA
      : matchedByManufacturer &&
          matchedByProduct &&
          matchedByQuantity &&
          matchedByMPA;
  });
  // sum up all matched discounts, without ABSOLUTE type
  const discSum = matched.reduce((prevDiscSum, currDisc) => {
    const price = product.wholesalePrice || product.price || 0;

    // SD-8490: Cart Total - the discount is applied on sum of the product; Each SKU - the new one which will apply the discount value on each added product. E.g: if 10 pcs of Cola1 were added to the cart, the Each SKU discount type will by applied on each of 10 added pcs.
    const quantityFactor =
      currDisc.scope === DISCOUNT_APPLICABLE_ON_SCOPES.CART_TOTAL
        ? 1
        : product.quantity;

    const disc =
      (currDisc.type === "PERCENTAGE" &&
        (currDisc.percentDiscount / 100) * price * product.quantity) ||
      (currDisc.type === "QUANTITY" && (product.discountQty || 0) * price) ||
      (currDisc.type === "ABSOLUTE" &&
        currDisc.absoluteDiscount * quantityFactor);

    // add this sum to previous sum
    return prevDiscSum + disc;
  }, 0);
  return discSum;
};

const getMatchedByProduct = ({ isEdit, discount, product }) => {
  if (isEdit && discount.orderProducts) {
    return discount.orderProducts?.some(
      (disProd) => disProd.orderProductId === product.id
    );
  }

  if (isEdit && discount.draftOrderProducts) {
    return discount.draftOrderProducts?.some(
      (disProd) => disProd.draftOrderProductId === product.id
    );
  }

  // SD-8280
  return discount.products?.some(
    (disProd) =>
      disProd.product?.id === product.id ||
      disProd.product?.id === product.product?.id ||
      disProd.productId === product.id
  );
};

// create discounts dictionary: product.id: [discounts]
export const getDiscountsDictionary = ({
  productsList,
  checkedDiscounts,
  isPayed,
  isEdit,
}) => {
  const discountsDict = [];
  productsList.filter(Boolean).forEach((product) => {
    const matched = checkedDiscounts.filter((checkedDiscount) => {
      const disManId = checkedDiscount.manufacturer?.id;
      const matchedByManufacturer =
        disManId === product.manufacturer?.id ||
        disManId === product.parentProduct?.manufacturer?.id ||
        disManId === product.manufacturerId;

      const matchedByProduct = getMatchedByProduct({
        isEdit,
        discount: checkedDiscount,
        product,
      });

      const currentManufacturerProducts = productsList.filter(
        ({ manufacturer, parentProduct, manufacturerId }) =>
          manufacturer?.id === disManId ||
          disManId === parentProduct?.manufacturer?.id ||
          disManId === manufacturerId
      );

      const currentManufacturerProductsQtySum =
        currentManufacturerProducts.reduce(
          (prevSum, { quantity }) => prevSum + (quantity || 0),
          0
        );

      const matchedByQuantity =
        checkedDiscount?.requirements !== "MIN_QUANTITY" ||
        (checkedDiscount?.type === "QUANTITY"
          ? currentManufacturerProductsQtySum >= checkedDiscount?.minQuantity
          : product.quantity >= checkedDiscount?.minQuantity);

      const price = product.wholesalePrice || product.price || 0;

      const matchedByMPA =
        checkedDiscount?.requirements === "MIN_PURCHASE_AMOUNT"
          ? price * product.quantity >= checkedDiscount.minPurchaseAmount
          : true;

      return checkedDiscount.productType === "ALL_PRODUCTS"
        ? matchedByManufacturer && matchedByQuantity && matchedByMPA
        : matchedByManufacturer &&
            matchedByProduct &&
            matchedByQuantity &&
            matchedByMPA;
    });
    if (!product.isNewAdded && isPayed) {
      // const findDiscountsInDuplicates = product.manufacturerDiscountDuplicates.map(d => )
      return (discountsDict[product.id] = []);
    }
    if (product.id in discountsDict) discountsDict[product.id].push(matched);
    else discountsDict[product.id] = matched;
  });
  return discountsDict;
};

// create products dictionary: discount.id: [products]
export const getProductsDictionary = (productsList, discountsList, isPayed) => {
  const productsDict = [];
  discountsList.forEach((discount) => {
    const matched = productsList
      .filter((p) => (isPayed ? p.isNewAdded : p))
      .filter((product) => {
        const disManId = discount.manufacturer?.id;
        const matchedByManufacturer =
          disManId === product.manufacturer?.id ||
          disManId === product.parentProduct?.manufacturer?.id ||
          disManId === product.manufacturerId;

        const matchedByProduct = discount.products?.some(
          (disProd) =>
            (disProd.product?.id && disProd.product?.id === product.id) ||
            (disProd.productId && disProd.productId === product.id) ||
            (disProd.product?.id &&
              disProd.product?.id === product.product?.id) ||
            (disProd.productId && disProd.productId === product.product?.id)
        );

        return discount.productType === "ALL_PRODUCTS"
          ? matchedByManufacturer
          : matchedByManufacturer && matchedByProduct;
      });
    if (discount.id in productsDict) productsDict[discount.id].push(matched);
    else productsDict[discount.id] = matched;
  });
  return productsDict;
};

export const filterByMPA = (discount, dictionary) => {
  const sortedByPrice = dictionary[discount.id]?.sort(
    (a, b) =>
      b.quantity * (b.wholesalePrice || b.price || 0) -
      a.quantity * (a.wholesalePrice || a.price || 0)
  );
  const productsPrice =
    sortedByPrice?.[0]?.quantity *
    (sortedByPrice?.[0]?.wholesalePrice || sortedByPrice?.[0]?.price);

  return productsPrice < discount.minPurchaseAmount;
};

export const filterByMOQ = (discount, dictionary) => {
  if (discount.type === "QUANTITY") {
    const productsQuantity = dictionary[discount.id]?.reduce(
      (prevSum, currProd) => prevSum + currProd?.quantity,
      0
    );

    return productsQuantity < discount.minQuantity;
  }

  const productsQuantity = dictionary[discount.id]?.sort(
    (a, b) => b.quantity - a.quantity
  )[0]?.quantity;

  return productsQuantity < discount.minQuantity;
};

export const filterByUsage = (discount) => {
  return discount.limit && discount.limit <= discount._count?.duplicates;
};

export const filterByRequirements = (discount, dictionary) => {
  return (
    filterByMOQ(discount, dictionary) ||
    filterByMPA(discount, dictionary) ||
    filterByUsage(discount)
  );
};

export const getValueLabel = (item) => {
  if (item?.productType !== "ALL_PRODUCTS") return "off specific products";
  if (item?.type === "QUANTITY")
    return pluralize("free case", item.quantityDiscount, false);
  if (item?.type === "ABSOLUTE") return "off all products";
  return "off all products";
};

export const setDiscountValue = (item) => {
  switch (item.type) {
    case "ABSOLUTE":
      return `$${item?.absoluteDiscount.toFixed(2)}`;
    case "PERCENTAGE":
      return `${item?.percentDiscount}%`;
    default:
      return item?.quantityDiscount;
  }
};

export const getProductManufacturer = ({
  manufacturer,
  parentProduct,
  manufacturerId,
}) => {
  return manufacturer?.id || parentProduct?.manufacturer?.id || manufacturerId;
};

export const getProductManufacturerName = ({
  manufacturer,
  parentProduct,
  manufacturerName,
}) => {
  return (
    manufacturer?.name || parentProduct?.manufacturer?.name || manufacturerName
  );
};

const getMOQDisabled = (discount, prodDict) => {
  // MOQ - minimum order quantity
  return filterByMOQ(discount, prodDict);
};

const getMPADisabled = (discount, prodDict) => {
  // MPA - minimum purchase amount
  return filterByMPA(discount, prodDict);
};

export const checkDisabledDiscount = (
  discount,
  discountsList,
  checkedDiscounts,
  prodDict
) => {
  const sameManufacturerMSD = discountsList.filter(
    (msd) => msd.manufacturer.id === discount.manufacturer.id
  );

  const oneSiblingChecked = sameManufacturerMSD?.some((child) =>
    checkedDiscounts
      .filter((checked) => checked.manufacturer?.id === child?.manufacturer?.id)
      .find((checked) => {
        if (checked.productType === "ALL_PRODUCTS")
          return (
            (checked.id === child.id ||
              checked.manufacturerDiscountId === child.id) &&
            child.id !== discount.id
          );
        if (checked.productType === "SPECIFIC_PRODUCTS") {
          return (
            discount.productType === "ALL_PRODUCTS" ||
            checked.products.some(
              (p) =>
                checked.id !== discount.id &&
                checked.manufacturerDiscountId !== discount.id &&
                discount.products.find(
                  ({ product: compareProduct }) =>
                    compareProduct?.id === p.product?.id ||
                    compareProduct?.id === p.productId
                )
            )
          );
        }
        return false;
      })
  );
  // if an MSD accordion has 1 checked discount, disable the rest -- OUTDATED --
  // One product specific discount can be added to the order with other product specific discount if these discounts will be applied to different product
  //  If All products discount was added to the shopping cart, no other discount could be added from the same Manufacturer
  // When non-conflicting (product specific) MSD's are selected - disable All products discounts in the list
  // When product specific MSD is selected - all conflicting MSD's will be disabled in the discounts list
  return (
    // check if sum of qty or price of one category products add up to discount's minQ ir minPA
    getMOQDisabled(discount, prodDict) ||
    getMPADisabled(discount, prodDict) ||
    oneSiblingChecked
  );
};
