import { useCallback, useContext, useMemo } from "react";
import { FilterButton, FilterSelect, FilterWrap } from "./styles";
import { PlaceAutocompleteField } from "components";
import { Box, Chip, MenuItem } from "@mui/material";
import { FilterIcon } from "components/Icons";
import { func, array, string, object } from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { MAP_PROSPECT_TYPES } from "./ProspectsFilter.constants";
import { startCase } from "lodash";
import { ProspectsPageContext } from "../../ProspectsTab";
import { getAddressComponents } from "Pages/CustomersPage/components/AddressField/AddressField.helpers";
import { sliceCountry } from "helpers/helpers";
import { saveProspectsSearchResultsService } from "services/prospects";
import { error } from "utils/notifications";

const ProspectsFilter = ({
  onOpenFilters,
  handleDeleteFilter,
  filter,
  handleChangeFilter,
}) => {
  const {
    filtersState,
    listState,
    locationBias,
    searchValue,
    setSearchValue,
    setLoadingToListState,
    setFilter,
    setAddToListState,
  } = useContext(ProspectsPageContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { categories } = useMemo(() => filtersState, [filtersState.categories]);

  const parseWorkingHours = (input) => {
    const [dayPart, timePart] = input?.split(": ");

    const day = dayPart?.trim()?.toLowerCase();

    // eslint-disable-next-line no-irregular-whitespace
    const [openTime, closeTime] = timePart?.split(" – ");

    const convertTo24Hour = (time) => {
      if (!time) return "";

      const [timePart, modifier] = time?.trim()?.split(" ");
      let [hours, minutes] = timePart
        .split(":")
        .map((el) => +el.replace(/\D/g, ""));

      if (modifier === "PM" && hours !== 12) {
        hours += 12;
      }
      if (modifier === "AM" && hours === 12) {
        hours = 0;
      }

      return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
        2,
        "0"
      )}`;
    };

    const open = convertTo24Hour(openTime);
    const closed = convertTo24Hour(closeTime);

    const working = !!open && !!closed;

    return {
      day,
      open,
      closed,
      working,
    };
  };

  const saveSearchResults = useCallback(
    async (prospectsToSave) => {
      if (!prospectsToSave?.length) return;

      const formattedProspectsToSave = prospectsToSave.map((c) => ({
        ...c,
        type: undefined,
        //stats: undefined,
        fsq_id: undefined,
        id: undefined,
        fax: undefined,
        foursquareId: c.fsq_id,
        businessFax: c.fax,
        categories: c.categories.map((cat) => ({
          foursquareId: cat.id,
          name: cat.name,
        })),
      }));

      try {
        setLoadingToListState("newWithIds", true);
        const results = await saveProspectsSearchResultsService({
          prospects: formattedProspectsToSave,
        });
        const savedResultsWithIds = prospectsToSave.map((p, index) => ({
          ...p,
          foursquareId: p.id,
          id: results[index].id,
        }));

        setFilter(MAP_PROSPECT_TYPES.new);
        setAddToListState(MAP_PROSPECT_TYPES.new, prospectsToSave);
        setAddToListState("newWithIds", savedResultsWithIds);
        setLoadingToListState("newWithIds", false);
      } catch (err) {
        if (err?.response?.data?.message.includes("to be empty")) {
          return error(
            "Cannot add: address is missing. Select a location with a full address"
          );
        }

        error(err?.response?.data?.message);
      }
    },
    [setFilter, setAddToListState, setLoadingToListState]
  );

  const handleSetCustomer = (customerInfo) => {
    const { street, state, city, zip } = getAddressComponents(customerInfo);

    const preparedNumber = (n) => {
      if (!n) return "";
      if (n.startsWith("(") && n.length === 14)
        return `+1${n.replace(/\s|\(|\)|-/g, "")}`;
      return n;
    };

    const address = {
      formatted_address: sliceCountry(customerInfo.formatted_address),
      lat: customerInfo.geometry.location.lat(),
      lng: customerInfo.geometry.location.lng(),
      street,
      state,
      city,
      zip,
    };

    const days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    const receivingHours = days.map((day) => {
      const weekdayText = customerInfo?.opening_hours?.weekday_text;

      if (!weekdayText)
        return {
          day: day?.toLocaleLowerCase(),
          open: undefined,
          closed: undefined,
          working: false,
        };

      const weekDay = weekdayText.find((w) => w.includes(day));

      if (weekDay.includes("Open 24 hours"))
        return {
          day: day?.toLocaleLowerCase(),
          open: "00:00",
          closed: "23:59",
          working: true,
        };

      return parseWorkingHours(weekDay);
    });

    const id = customerInfo?.reference || customerInfo?.place_id || null;

    const preparedData = {
      name: customerInfo?.name || "",
      shippingAddress: { ...address },
      billingAddress: { ...address },
      categories: [{ id: 17000, name: "Retail" }],
      stats: {},
      rating: customerInfo?.rating || 0,
      socialMedia: {},
      images: [],
      receivingHours,
      phone: preparedNumber(customerInfo.formatted_phone_number),
      website: customerInfo.website || "",
      fsq_id: id,
      foursquareId: id,
      id,
    };

    saveSearchResults([preparedData]);
    setSearchValue("");
  };

  return (
    <FilterWrap>
      <FilterSelect
        displayEmpty
        renderValue={(key) => {
          return startCase(key);
        }}
        value={filter}
        onChange={handleChangeFilter}
        formSx={{ width: "100%", maxWidth: "159px" }}
      >
        <MenuItem value={MAP_PROSPECT_TYPES.new}>
          New Search ({listState[MAP_PROSPECT_TYPES.new].list.length})
        </MenuItem>
        <MenuItem value={MAP_PROSPECT_TYPES.history}>
          History ({listState[MAP_PROSPECT_TYPES.history].list.length})
        </MenuItem>
        <MenuItem value={MAP_PROSPECT_TYPES.prospects}>
          Prospects ({listState[MAP_PROSPECT_TYPES.prospects].list.length})
        </MenuItem>
      </FilterSelect>

      <PlaceAutocompleteField
        adminIsAllowed
        showLoading
        fullWidth={false}
        size="small"
        InputLabelProps={{
          sx: { fontSize: "13px", color: "#5F6267", top: 3 },
        }}
        formSx={{ width: "calc(100% - 80px)", minWidth: "380px" }}
        handleSetCustomer={handleSetCustomer}
        noErrorMessage
        InputProps={{
          sx: {
            height: "39px",
            "& fieldset": { borderWidth: "1px 0.5px 1px 1px" },
          },
        }}
        showSearchIcon
        placeholderWidth="308px"
        placeholder="Search by customer name or address"
        adornmentProps={{ sx: { justifyContent: "flex-end" } }}
        customAdornment={
          <Box
            display="flex"
            gap="5px"
            overflow="auto"
            sx={{
              py: "4px",
              "&::-webkit-scrollbar": {
                height: "2px",
              },
            }}
          >
            {(filter !== MAP_PROSPECT_TYPES.new ? [] : categories).map((c) => (
              <Chip
                key={c.id}
                size="small"
                label={c.name}
                deleteIcon={<CloseIcon size="15" style={{ fill: "#707070" }} />}
                onMouseDown={(e) => e.stopPropagation()}
                onDelete={() => handleDeleteFilter(c.id)}
                sx={{
                  height: "26.5px",
                  border: "0.5px solid #D4D4D4",
                  borderRadius: "4px",
                  backgroundColor: "#FAFAFB",
                }}
              />
            ))}
          </Box>
        }
        locationBias={locationBias}
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
      />

      <Box display="flex" alignItems="center" gap="4px" pr="12px">
        <FilterButton
          label="Filter"
          startIcon={<FilterIcon />}
          variant="outlined"
          color="edit"
          fontSize="15px"
          sx={{ height: "39px" }}
          onClick={onOpenFilters}
          disabled={filter !== MAP_PROSPECT_TYPES.new}
        />
      </Box>
    </FilterWrap>
  );
};

ProspectsFilter.propTypes = {
  onOpenFilters: func,
  categories: array,
  handleDeleteFilter: func,
  filter: string,
  handleChangeFilter: func,
  counterInfo: object,
};
ProspectsFilter.defaultProps = {
  onOpenFilters: () => {},
  categories: [],
  handleDeleteFilter: () => {},
  filter: MAP_PROSPECT_TYPES.history,
  handleChangeFilter: () => {},
  counterInfo: {},
};

export default ProspectsFilter;
