import { generateUUID, handleError } from "helpers/helpers";
import { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tasksFilterAction } from "redux/actions/tasks";
import { savedFiltersListSelector } from "redux/selectors/savedFilters";
import {
  tasksFilterSelector,
  tasksSwitchSelector,
} from "redux/selectors/tasks";
import { createSelector } from "reselect";
import { getTasksService } from "services/tasks";

const selector = createSelector(
  tasksSwitchSelector,
  tasksFilterSelector,
  savedFiltersListSelector,
  (tasksSwitch, tasksFilter, savedFiltersList) => ({
    tasksSwitch,
    tasksFilter,
    savedFiltersList,
  })
);

export const initTasksState = {
  loadingTasksList: false,
  countTasks: 0,
  existData: null,
  existDataByCustomer: null,
  tasksList: [],
};

export const useTasks = () => {
  const dispatch = useDispatch();

  const fetchIdRef = useRef(null);

  const { tasksSwitch, tasksFilter } = useSelector(selector);

  const [tasksState, setTasksState] = useState(initTasksState);

  const [checkedTasks, setCheckedTasks] = useState([]);
  const [allTasksChecked, setAllTasksChecked] = useState(false);

  const fetchTasksList = useCallback(async () => {
    try {
      setTasksState((prev) => ({ ...prev, loadingTasksList: true }));

      const preparedTasksFilter = {
        ...tasksFilter,
        assigned_form_ids: tasksFilter?.assigned_form_ids
          ? JSON.stringify([tasksFilter?.assigned_form_ids])
          : undefined,
      };

      const fetchId = generateUUID();
      fetchIdRef.current = fetchId;

      const { count, existData, existDataByCustomer, rows, resFetchId } =
        await getTasksService(preparedTasksFilter, fetchId);

      if (fetchIdRef.current !== resFetchId) return;

      const tasksList = Array.isArray(rows) ? rows : [];

      setTasksState((prev) => ({
        ...prev,
        countTasks: count || 0,
        existData: !!existData,
        existDataByCustomer: !!existDataByCustomer,
        tasksList:
          tasksFilter.page > 1
            ? [...prev.tasksList, ...tasksList]
            : [...tasksList],
      }));
      if (!tasksFilter.page && !tasksFilter.limit) {
        setCheckedTasks(tasksList);
        setAllTasksChecked(true);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setTasksState((prev) => ({ ...prev, loadingTasksList: false }));
    }
  }, [tasksFilter]);

  const handleSetSortBy = useCallback(
    (data) => {
      dispatch(tasksFilterAction({ ...tasksFilter, ...data, page: 1 }));
    },
    [dispatch, tasksFilter]
  );

  return {
    ...tasksState,
    setTasksState,
    tasksSwitch,
    tasksFilter,
    fetchTasksList,
    handleSetSortBy,
    checkedTasks,
    setCheckedTasks,
    allTasksChecked,
    setAllTasksChecked,
  };
};
