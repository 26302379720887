import { object, bool } from "prop-types";
import { Controller } from "react-hook-form";
import { Box, Grid, Paper, Typography } from "@mui/material";
import useStyles from "../../styles";
import { IOSSwitch, PriceFormat } from "components";

export const GeneralTab = ({
  control = {},
  minimumOrderValue = false,
  isAdmin = false,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.ordersBody}>
      <Box>
        <Paper className={classes.buttonBlock}>
          <Grid className={classes.gridWrapper}>
            <Grid>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <IOSSwitch
                    disabled={isAdmin}
                    label={
                      <Box className={classes.switchLabelBlock}>
                        <Typography className={classes.switchLabelFirst}>
                          Minimum order value
                        </Typography>
                        <Typography className={classes.switchLabelSecond}>
                          Set the amount that wholesale orders need to meet or
                          exceed.
                        </Typography>
                      </Box>
                    }
                    checked={!!field.value}
                    error={error ? error.message : ""}
                    formSx={{ flex: "0 0 34%" }}
                    {...field}
                    onChange={(e) => {
                      if (isAdmin) return;
                      field.onChange(e);
                    }}
                  />
                )}
                name="minimumOrderValue"
                control={control}
              />
            </Grid>
            <Grid className={classes.gridWrapperRight}>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <PriceFormat
                    noErrorMessage
                    fullWidth
                    disabled={isAdmin || !minimumOrderValue}
                    InputProps={{
                      sx: {
                        height: "32px",
                        fontSize: "12px",
                        pr: 0,
                        background: "#fff",
                        color: "#707070",
                      },
                      startAdornment: (
                        <Typography className={classes.deliveryPriceCapText}>
                          $
                        </Typography>
                      ),
                    }}
                    error={error ? error.message : ""}
                    {...field}
                  />
                )}
                name="minimumOrderValueAmount"
                control={control}
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Box>
  );
};

GeneralTab.propTypes = {
  control: object,
  isAdmin: bool,
  minimumOrderValue: bool,
};
