import { useEffect, useState } from "react";
import { func, object } from "prop-types";
import { Slider, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

export const PhotoRangeSlider = ({
  handleSetRangePosition = () => {},
  wrapperProps = {},
}) => {
  const [valueRange, setValueRange] = useState(0);

  const handleChangeRange = (e, newValue) => {
    setValueRange(newValue);
  };

  const handleSetRange = (type) => {
    if (type === "decrement" && valueRange > 0) {
      setValueRange(valueRange - 10);
    }
    if (type === "increment" && valueRange < 90) {
      setValueRange(valueRange + 10);
    }
  };

  useEffect(() => {
    handleSetRangePosition(valueRange);
  }, [handleSetRangePosition, valueRange]);

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
        width: "308px",
        height: "44px",
        bgcolor: valueRange > 0 ? "#FFFFFF" : "#FFFFFF75",
        "&:hover": {
          bgcolor: "#FFFFFF",
        },
        borderRadius: "8px",
        px: "4px",
        ...wrapperProps,
      }}
    >
      <Stack pr="6px">
        <RemoveIcon
          sx={{ fontSize: "32px", cursor: "pointer" }}
          onClick={() => handleSetRange("decrement")}
        />
      </Stack>
      <Slider
        sx={{
          "& .MuiSlider-thumb": {
            width: "24px",
            height: "24px",
          },
        }}
        aria-label="Volume"
        step={10}
        min={0}
        max={90}
        value={valueRange}
        onChange={handleChangeRange}
      />
      <Stack pl="6px">
        <AddIcon
          sx={{ fontSize: "32px", cursor: "pointer" }}
          onClick={() => handleSetRange("increment")}
        />
      </Stack>
    </Stack>
  );
};

PhotoRangeSlider.propTypes = {
  handleSetRangePosition: func,
  wrapperProps: object,
};
