import { func, bool, object } from "prop-types";
import { Grid, Typography } from "@mui/material";
import { LargeCheckbox } from "../../../components/Checkboxes";
import TableHeader from "../../../components/TableHeader";

const HeaderList = ({
  checkAllDiscounts = () => {},
  allDiscountsChecked = false,
  group = null,
}) => {
  const cl = {
    wrapper: {
      backgroundColor: "#EEE",
      alignItems: "center",
      borderTop: "1px solid #D4D4D4",
      borderBottom: "1px solid #D4D4D4",
      borderWidth: "1px 0 1px 0",
    },
    grid: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    typography: {
      mb: "2px",
      fontSize: "11px",
      color: "#1A1A1A",
      textTransform: "uppercase",
    },
  };

  return (
    <TableHeader sx={cl.wrapper} columns={24}>
      <Grid item xs={2.3} sx={{ ...cl.grid, mt: "-2px", pl: "5px" }}>
        <LargeCheckbox
          formSx={{ m: 0 }}
          stroke="#707070"
          sx={{ p: 0, ml: "9px" }}
          size={22}
          checked={
            group
              ? !!group?.discounts.length && allDiscountsChecked
              : allDiscountsChecked
          }
          onChange={() => checkAllDiscounts(group)}
        />
      </Grid>

      {/* <Grid item xs={1.3} sx={classes.grid}>
        <Typography sx={classes.typography}>Status</Typography>
      </Grid> */}

      <Grid item xs={3.3} sx={cl.grid}>
        <Typography sx={cl.typography}>DISCOUNT NAME</Typography>
      </Grid>

      <Grid item xs={4.7} sx={{ ...cl.grid, justifyContent: "flex-start" }}>
        <Typography sx={cl.typography}>VALUE</Typography>
      </Grid>

      <Grid item xs={4.8} sx={{ ...cl.grid, justifyContent: "flex-start" }}>
        <Typography sx={cl.typography}>MINIMUM REQUIREMENTS</Typography>
      </Grid>

      <Grid item xs={3.2} sx={{ ...cl.grid, justifyContent: "flex-start" }}>
        <Typography sx={cl.typography}>END DATE</Typography>
      </Grid>

      <Grid item xs={2.7} sx={cl.grid}>
        <Typography sx={cl.typography}>ALLOWED USAGE</Typography>
      </Grid>

      <Grid item xs sx={cl.grid}>
        <Typography sx={cl.typography}>USED</Typography>
      </Grid>
    </TableHeader>
  );
};

HeaderList.propTypes = {
  checkAllDiscounts: func,
  allDiscountsChecked: bool,
  group: object,
};

export default HeaderList;
