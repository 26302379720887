import axios from "axios";

export const getProductsService = async (params, signal) => {
  return await axios
    .get("/products", { params, signal })
    .then((res) => res.data);
};

export const createSingleProductService = async (prodData) => {
  return await axios.post("/products/single", prodData).then((res) => res.data);
};

export const updateSingleProductService = async (id, productData) => {
  return await axios
    .put(`/products/single/${id}`, productData)
    .then((res) => res.data);
};

export const deleteProductService = async (id) => {
  return await axios.delete(`/products/${id}`).then((res) => res.data);
};

export const createMultipleProductService = async (prodData) => {
  return await axios
    .post("/products/multiple", prodData)
    .then((res) => res.data);
};

export const updateMultipleProductService = async (id, productData) => {
  return await axios
    .put(`/products/multiple/${id}`, productData)
    .then((res) => res.data);
};

export const archiveProductsService = async (data) => {
  return await axios
    .put("/products/bulk-activation", data)
    .then((res) => res.data);
};

export const bulkUpdateProductsCategoryService = async (
  categoryId,
  productIds
) => {
  const { data } = await axios.put(`/products/bulk-change-category`, {
    categoryId,
    productIds,
  });
  return data;
};

export const bulkUpdateProductsAssignmentService = async (
  manufacturerId,
  productIds
) => {
  const { data } = await axios.put(`/products/bulk-assign-manufacturer`, {
    manufacturerId,
    productIds,
  });
  return data;
};

export const bulkAssignProductTagsService = async ({
  tagIds,
  productIds,
  newTags,
}) => {
  const { data } = await axios.put("/products/bulk-assign-tags", {
    tagIds,
    productIds,
    newTags,
  });
  return data;
};

export const bulkDeleteProductsService = async (productIds) => {
  const { data } = await axios.delete(`/products/bulk-delete-products`, {
    data: { productIds },
  });
  return data;
};

export const updateSingleInventoryService = (id, data) => {
  return axios
    .put(`/products/single-inventory/${id}`, data)
    .then((res) => res.data);
};

export const getProductByIdService = (id) => {
  return axios.get(`/products/${id}`).then((res) => res.data);
};

export const getTotalSummariesCatalogService = (params) => {
  return axios
    .get("products/total-summaries", { params })
    .then((res) => res.data);
};

export const checkProductAvailabilityService = (params) => {
  return axios
    .get("products/check-info-availability", { params })
    .then((res) => res.data);
};

export const getProductsLastOrdersInfoService = ({ ids }) => {
  return axios
    .get("/products/cart-info", { params: { ids } })
    .then((res) => res.data);
};

export const getOrderedProductsByCustomer = (params) => {
  return axios.get("/products/ordered", { params }).then((res) => res.data);
};

export const getSelectedProductsByCustomer = (params) => {
  return axios.get("/products/selected", { params }).then((res) => res.data);
};

export const getProductsByCustomerIdService = (id, params) => {
  return axios
    .get(`/products/by-customer/${id}`, { params })
    .then((res) => res.data);
};

export const getLiteProductsService = (params) => {
  return axios.get(`/products/by-customer`, { params }).then((res) => res.data);
};
