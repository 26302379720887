import { styled } from "@mui/material";

const PageWrap = styled("div")(({ theme: { spacing } }) => ({
  display: "flex",
  gap: "14px",
  paddingTop: spacing(1.65),
  height: "calc(100vh - 220px)",
}));

const TableWrap = styled("div")(({ theme: { spacing, palette } }) => ({
  width: "100%",
  maxWidth: "458px",
  border: `0.5px solid ${palette.border.main}`,
  borderRadius: spacing(0.5),
  height: "100%",
  overflow: "hidden",
}));

const MapWrap = styled("div")({
  width: "100%",
});

export { TableWrap, MapWrap, PageWrap };
