import React from "react";

// eslint-disable-next-line react/prop-types
export const RoundedPlus = (props) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={20} height={20} rx={4} />
      <path
        d="M10 4v12m-6-6h12"
        stroke="#707070"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
