import {
  START_LOADING_PRODUCTS,
  END_LOADING_PRODUCTS,
  GET_PRODUCTS,
  GET_PRODUCTS_COUNT,
  GET_PRODUCTS_PARAMS,
  SHOW_INACTIVE_PRODUCTS,
  SET_PARENT_PRODUCT,
  SELECTED_CHILDRENS_OF_PRODUCTS,
  SELECTED_PRODUCTS,
  EXIST_PRODUCTS,
  CATALOG_GET_FILTER_FIELD,
  CATALOG_GET_SWITCH,
  RESET_CATALOG_ACTION_FILTER,
  RESET_CATALOG_ACTION_SWITCH,
  SET_SEARCH_PRODUCTS,
  PRODUCTS_WITH_VARIATIONS_COUNT,
} from "../actions/products";

export const initialProductsState = {
  products: [],
  loading: true,
  count: 0,
  countWithVariations: 0,
  params: {
    sort_by_name: "asc",
    sort_by_category: null,
    sort_by_manufacturer: null,
  },
  catalogFilter: {
    category_id: "",
    manufacturer_id: "",
    stock_availability: "",
    price: "",
    missing_info: "",
    tag_id: [],
    price_list_id: "",
  },
  catalogSwitch: [
    {
      value: "category_id",
      label: "Category",
      type: "categories",
      checked: false,
      selectLabel: "Search product categories",
    },
    {
      value: "price",
      label: "Price",
      type: "textfields",
      checked: false,
      options: [
        {
          id: "wholesale_price_greater",
          label: "Greater than",
          startAdornment: <div style={{ marginRight: "5px" }}> $ </div>,
          prefix: ">",
          placeholder: "0.00",
          type: "number",
        },
        {
          id: "wholesale_price_lesser",
          label: "Less than",
          startAdornment: <div style={{ marginRight: "5px" }}> $ </div>,
          prefix: "<",
          placeholder: "0.00",
          type: "number",
        },
        {
          id: "wholesale_price_equals",
          label: "Equals",
          startAdornment: <div style={{ marginRight: "5px" }}> $ </div>,
          prefix: "is",
          placeholder: "0.00",
          type: "number",
        },
      ],
    },
    {
      value: "stock_availability",
      label: "Stock availability",
      type: "switch",
      checked: false,
      values: [
        { value: "in", name: "In Stock" },
        { value: "out", name: "Out Of Stock" },
        { value: "low", name: "Running Low" },
      ],
    },
    {
      value: "manufacturer_id",
      label: "Manufacturer",
      type: "select",
      checked: false,
      selectLabel: "Select Manufacturer",
    },
    {
      value: "missing_info",
      label: "Missing info",
      type: "switch",
      checked: false,
      values: [
        { value: true, name: "With missing info" },
        { value: false, name: "Without missing info" },
      ],
    },
    {
      value: "tag_id",
      label: "Tag",
      type: "multiselect",
      checked: false,
      selectLabel: "Select Tag",
    },
    {
      value: "price_list_id",
      label: "Price list",
      type: "select",
      checked: false,
      selectLabel: "Select price list",
    },
  ],
  searchProducts: "",

  showInactive: false,
  parentProduct: {},
  selectedProducts: [],
  selectedChildrensOfProducts: [],
  existData: false,
};

const productsReducer = (state = initialProductsState, { type, payload }) => {
  switch (type) {
    case GET_PRODUCTS:
      if (payload.isScrolling) {
        return {
          ...state,
          products: [...state.products, ...payload.products],
        };
      }
      return { ...state, products: payload.products };

    case EXIST_PRODUCTS:
      return { ...state, existData: payload };

    case SET_PARENT_PRODUCT:
      return { ...state, parentProduct: payload };

    case SHOW_INACTIVE_PRODUCTS:
      return { ...state, showInactive: payload };

    case CATALOG_GET_FILTER_FIELD:
      return { ...state, catalogFilter: payload };
    case CATALOG_GET_SWITCH:
      return { ...state, catalogSwitch: payload };
    case RESET_CATALOG_ACTION_FILTER:
      return { ...state, catalogFilter: initialProductsState.catalogFilter };
    case RESET_CATALOG_ACTION_SWITCH:
      return { ...state, catalogSwitch: initialProductsState.catalogSwitch };

    case GET_PRODUCTS_PARAMS:
      return { ...state, params: { ...state.params, ...payload } };

    case GET_PRODUCTS_COUNT:
      return { ...state, count: payload };

    case START_LOADING_PRODUCTS:
      return { ...state, loading: true };

    case END_LOADING_PRODUCTS:
      return { ...state, loading: false };

    case SELECTED_PRODUCTS:
      return { ...state, selectedProducts: payload };

    case SELECTED_CHILDRENS_OF_PRODUCTS:
      return { ...state, selectedChildrensOfProducts: payload };

    case SET_SEARCH_PRODUCTS:
      return { ...state, searchProducts: payload };
    case PRODUCTS_WITH_VARIATIONS_COUNT:
      return { ...state, countWithVariations: payload };

    default:
      return state;
  }
};

export default productsReducer;
