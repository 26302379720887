import { useCallback, useContext, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { IosArrowForward } from "components/Icons";
import { StyledButton, ValidationPopper } from "components";
import { CustomerContext } from "../../CustomerPage";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openDiscardChanges } from "redux/actions/confirmDialogs";

export const CustomerHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    isEdit,
    handleSubmit,
    trigger,
    errors,
    formField,
    loadingCustomer,
    validationNames,
    formChanged,
    state,
  } = useContext(CustomerContext);
  const { billingAddress, shippingAddress, name, shippingIsSame } =
    formField || {};

  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleConfirmChangesDialog = useCallback(
    (location = -1) => {
      dispatch(openDiscardChanges(() => navigate(location, { state })));
    },
    [dispatch, navigate, state]
  );

  const isDisabled = useMemo(() => {
    if (!shippingIsSame) {
      if (
        !shippingAddress?.city ||
        !shippingAddress?.state ||
        !shippingAddress?.street ||
        !shippingAddress?.zip
      )
        return true;
    }

    if (Object.keys(errors)?.length) {
      return (
        !!errors?.billingAddress ||
        !!errors?.shippingAddress ||
        !!errors?.name ||
        !!errors.displayName
      );
    }

    return (
      !name ||
      !billingAddress?.formatted_address ||
      !billingAddress?.city ||
      !billingAddress?.state ||
      !billingAddress?.street ||
      !billingAddress?.zip
      // nameValidation ||
      // customIdValidation
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formField?.name,
    formField?.discount,
    formField?.manufacturer?.id,
    formField?.shippingIsSame,
    formField?.groupId,
    billingAddress?.formatted_address,
    billingAddress?.city,
    billingAddress?.state,
    billingAddress?.street,
    billingAddress?.zip,
    shippingAddress?.city,
    shippingAddress?.state,
    shippingAddress?.street,
    shippingAddress?.zip,
    errors?.discount,
    errors?.billingAddress,
    errors?.shippingAddress,
    errors?.groupId,
    errors?.name,
    errors?.displayName,
    // duplicateNamesError,
    // nameValidation,
    // customIdValidation,
  ]);

  const onSaveHoverRevalidate = useCallback(
    (e) => {
      setIsOpen(true);
      setAnchorEl(e.target);
      if (trigger) {
        const billingDuplicate =
          errors?.billingAddress?.formatted_address?.type === "duplicate";
        const shippingDuplicate =
          errors?.shippingAddress?.formatted_address?.type === "duplicate";

        const uniqueNameError = !!errors.name?.type?.includes("active_");

        const invalidCharacter = errors.name?.type === "specialCharacter";
        if (billingDuplicate || shippingDuplicate) {
          const arr = [];
          if (billingDuplicate) arr.push("billingAddress");
          if (shippingDuplicate) arr.push("shippingAddress");
          if (uniqueNameError || invalidCharacter) {
            arr.push("name");
          }
          trigger(validationNames.filter((item) => !arr.includes(item)));

          return;
        }

        if (uniqueNameError || invalidCharacter) {
          trigger(validationNames.filter((name) => name !== "name"));
          return;
        }
        trigger();
      }
    },
    [
      errors?.billingAddress?.formatted_address?.type,
      errors.name,
      errors?.shippingAddress?.formatted_address?.type,
      trigger,
      validationNames,
    ]
  );

  return (
    <Box
      sx={{
        bgcolor: "#B5B5AC10",
        borderBottom: "0.5px solid #D4D4D4",
        width: "100%",
        height: "44px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        px: 4,
      }}
    >
      <Box height="100%" display="flex" alignItems="center">
        <Typography
          variant="h6"
          fontWeight="400"
          fontSize="20px"
          color="primary"
          sx={{ cursor: "pointer" }}
          onClick={() => {
            if (formChanged) return handleConfirmChangesDialog("/customers");
            return navigate("/customers", { state });
          }}
        >
          Customer
        </Typography>

        {isEdit && (formField.displayName || formField.name) && (
          <>
            <Box mx={1.8}>
              <IosArrowForward />
            </Box>
            <Typography
              variant="h6"
              fontWeight="400"
              fontSize="20px"
              color="disable.main"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                if (formChanged)
                  return handleConfirmChangesDialog(
                    `/customers/${formField.id}`
                  );
                return navigate(`/customers/${formField.id}`);
              }}
            >
              {formField.displayName || formField.name}
            </Typography>
          </>
        )}
        <Box mx={1.8}>
          <IosArrowForward />
        </Box>
        <Typography
          variant="h6"
          fontWeight="400"
          fontSize="20px"
          color="disable.main"
        >{`${isEdit ? "Edit" : "New"} customer`}</Typography>
      </Box>

      <Box>
        <StyledButton
          sx={{
            width: "64px",
            height: "24px",
            mr: 1,
          }}
          fontSize="11px"
          color="cancel"
          label="Discard"
          variant="outlined"
          onClick={() => {
            if (formChanged) {
              handleConfirmChangesDialog(-1);
            }
            navigate(-1);
          }}
        />

        <ValidationPopper
          isOpen={isOpen}
          anchorEl={anchorEl}
          errors={errors}
          setIsOpen={setIsOpen}
          tailProps={{
            top: -6,
            right: 48,
            rotate: -180,
          }}
          offset={[20, 21]}
        />

        <Box onMouseEnter={onSaveHoverRevalidate} component="span">
          <StyledButton
            disabled={isDisabled || loadingCustomer}
            sx={{
              width: "64px",
              height: "24px",
            }}
            fontSize="11px"
            label="Save"
            variant="contained"
            onClick={handleSubmit}
          />
        </Box>
      </Box>
    </Box>
  );
};

CustomerHeader.propTypes = {};
CustomerHeader.defaultProps = {};
