import { func, object, number, string } from "prop-types";

import { InfiniteScrollWrapper } from "components";
import { Fragment } from "react";
import PurchasedProductItem from "../PurchasedProductItem";
import { PurchasedProductsHeader } from "../PurchasedProductsHeader";

import { cl } from "../../styles";

import { CircularProgress, Stack, Typography } from "@mui/material";

export const ProductContentBlock = ({
  productsState,
  page,
  handleFetchProducts,
  handleIsAlreadyAdded,
  handleSetSort,
  quickSort,
  handleCheckProduct,
  type,
}) => {
  const { list, count, loading } = productsState;
  return (
    <Stack sx={cl.tableWrapper}>
      <PurchasedProductsHeader {...{ handleSetSort, quickSort, type }} />
      <Stack sx={{ maxHeight: "269px", overflow: "hidden" }}>
        {loading ? (
          <Stack alignItems="center" justifyContent="center" height="269px">
            <CircularProgress size="30px" />
          </Stack>
        ) : (
          <InfiniteScrollWrapper
            maxHeight="269px"
            dataLength={list.length}
            id="purchased-products-table"
            loading={loading}
            next={() => handleFetchProducts(page + 1)}
            hasMore={list.length < count}
          >
            {list?.length ? (
              list.map((product) => {
                return (
                  <Fragment key={product.id}>
                    <PurchasedProductItem
                      product={product}
                      {...{
                        handleIsAlreadyAdded,
                        product,
                        handleCheckProduct,
                      }}
                    />
                  </Fragment>
                );
              })
            ) : (
              <Stack height="100%" justifyContent="center" alignItems="center">
                <Typography
                  fontWeight="300"
                  fontSize="16px"
                  textAlign="center"
                  width="100%"
                  color="#6A6A6A"
                  paddingTop="20px"
                  paddingBottom="20px"
                >
                  No {type === "purchased" ? "purchased" : "selected"} products
                  found
                </Typography>
              </Stack>
            )}
          </InfiniteScrollWrapper>
        )}
      </Stack>
    </Stack>
  );
};

ProductContentBlock.propTypes = {
  productsState: object,
  page: number,
  handleFetchProducts: func,
  handleIsAlreadyAdded: func,
  handleSetSort: func,
  quickSort: object,
  handleCheckProduct: func,
  type: string,
};

ProductContentBlock.defaultProps = {
  quickSort: {},
  page: 1,
  productsState: {},
  handleCheckProduct: () => {},
  handleSetSort: () => {},
  handleIsAlreadyAdded: () => {},
  handleFetchProducts: () => {},
};
