const shippingAddressFields = [
  {
    id: "Shipping address",
    name: "Shipping address",
    type: "shippingAddress",
    chosen: true,
    selected: true,
  },
  {
    id: "Billing address",
    name: "Billing address",
    type: "billingAddress",
    chosen: true,
    selected: true,
  },
];

const customerFields = [
  {
    id: "1",
    name: "Business name",
    type: "name",
    chosen: true,
    selected: true,
  },

  {
    id: "8",
    name: "Shipping address",
    type: "shippingFormatted",
    selectWith: [
      {
        id: "9",
        name: "Shipping city",
        type: "shippingCity",
        chosen: true,
        selected: true,
      },
      {
        id: "10",
        name: "Shipping state",
        type: "shippingState",
        chosen: true,
        selected: true,
      },
      {
        id: "11",
        name: "Shipping zip code",
        type: "shippingZip",
        chosen: true,
        selected: true,
      },
    ],
    chosen: true,
    selected: true,
  },

  // {
  //   id: "3",
  //   name: "FED tax ID (EIN)",
  //   type: "federalTaxId",
  //   chosen: true,
  //   selected: true,
  // },

  {
    id: "4",
    name: "Billing address",
    type: "billingFormatted",
    selectWith: [
      {
        id: "5",
        name: "Billing city",
        type: "billingCity",
        chosen: true,
        selected: true,
      },
      {
        id: "6",
        name: "Billing state",
        type: "billingState",
        chosen: true,
        selected: true,
      },
      {
        id: "7",
        name: "Billing zip code",
        type: "billingZip",
        chosen: true,
        selected: true,
      },
    ],
    chosen: true,
    selected: true,
  },
  {
    id: "2",
    name: "Group",
    type: "groupName",
    chosen: true,
    selected: true,
  },
  {
    id: "16",
    name: "Territory",
    type: "territoryName",
    chosen: true,
    selected: true,
  },
  {
    id: "24",
    name: "Total orders",
    type: "ordersCount",
    chosen: true,
    selected: true,
  },
  {
    id: "25",
    name: "Total sales",
    type: "totalSalesAmount",
    chosen: true,
    selected: true,
  },
  {
    id: "15",
    name: "Customer discount",
    type: "percentDiscount",
    chosen: true,
    selected: true,
  },
  {
    id: "29",
    name: "Price list",
    type: "priceListName",
    chosen: true,
    selected: true,
  },
  {
    id: "27",
    name: "Primary payment method",
    type: "primaryPaymentMethod",
    chosen: true,
    selected: true,
  },
  {
    id: "23",
    name: "Has order direct",
    type: "hasOrderDirect",
    chosen: true,
    selected: true,
  },
  {
    id: "14",
    name: "Payment terms",
    type: "paymentTermsName",
    chosen: true,
    selected: true,
  },
  {
    id: "19",
    name: "Contact",
    type: "contactName",
    selectWith: [
      {
        id: "20",
        name: "Title",
        type: "contactRole",
        chosen: true,
        selected: true,
      },
      {
        id: "21",
        name: "Contact number",
        type: "contactPhone",
        chosen: true,
        selected: true,
      },
      {
        id: "22",
        name: "Email address",
        type: "contactEmail",
        chosen: true,
        selected: true,
      },
    ],
    chosen: true,
    selected: true,
  },
  {
    id: "12",
    name: "Business phone",
    type: "phone",
    chosen: true,
    selected: true,
  },
  {
    id: "13",
    name: "Business email",
    type: "email",
    chosen: true,
    selected: true,
  },
  {
    id: "26",
    name: "Business Fax",
    type: "businessFax",
    chosen: true,
    selected: true,
  },
  {
    id: "17",
    name: "Website",
    type: "website",
    chosen: true,
    selected: true,
  },
  {
    id: "18",
    name: "Tags",
    type: "tags",
    chosen: true,
    selected: true,
  },
  {
    id: "28",
    name: "Licenses & certifications",
    type: "documents",
    chosen: true,
    selected: true,
  },
];

export const selectsOfSalesSummary = [
  {
    id: "30",
    name: "Customer ID",
    type: "customerCustomId",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: "22",
    name: "Archived",
    type: "archived",
    chosen: true,
    selected: true,
    isHidden: true,
  },
  {
    id: "4",
    name: "Customer",
    type: "customerName",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: "Billing address",
    name: "Billing address",
    type: "customerBillingAddress",
    chosen: true,
    selected: true,
  },
  {
    id: "Shipping address",
    name: "Shipping address",
    type: "customerShippingAddress",
    chosen: true,
    selected: true,
  },
  {
    id: "1",
    name: "Order ID",
    type: "id",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: "3",
    name: "Created by",
    type: "createdBy",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: "2",
    name: "Date created",
    type: "createdAt",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: "13",
    name: "Product",
    type: "productName",
    selected: true,
    chosen: true,
    isHidden: false,
    bottom: true,
  },
  {
    id: "21",
    name: "Manufacturer",
    type: "productManufacturerName",
    selected: true,
    chosen: true,
    isHidden: false,
    bottom: true,
  },
  {
    id: "14",
    name: "Quantity",
    type: "productQuantity",
    selected: true,
    chosen: true,
    isHidden: false,
    bottom: true,
  },
  {
    id: "19",
    name: "Discount type",
    type: "appliedDiscountType",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: "16",
    name: "Item discounts",
    type: "productItemDiscount",
    selected: true,
    chosen: true,
    isHidden: false,
    bottom: true,
  },
  {
    id: "20",
    name: "Customer discount",
    type: "customerDiscount",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: "17",
    name: "Manufacturer discounts",
    type: "manufacturerDiscount",
    selected: true,
    chosen: true,
    isHidden: false,
    bottom: true,
  },
  {
    id: "18",
    name: "Total order discount",
    type: "totalOrderDiscount",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: "32",
    name: "Price list",
    type: "priceListName",
    chosen: true,
    selected: true,
    isHidden: false,
  },
  {
    id: "23",
    name: "Free cases",
    type: "productTotalQuantityDiscount",
    selected: true,
    chosen: true,
    isHidden: false,
    bottom: true,
  },
  {
    id: "7",
    name: "Balance",
    type: "balance",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: "10",
    name: "Payment status",
    type: "paymentStatus",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: "31",
    name: "Expected payment date",
    type: "paymentDue",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: "6",
    name: "Grand total",
    type: "grandTotal",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: "8",
    name: "Paid",
    type: "paid",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: "9",
    name: "Delivery status",
    type: "deliveryStatus",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: "15",
    name: "Delivered",
    type: "productDelivered",
    selected: true,
    chosen: true,
    isHidden: false,
    bottom: true,
  },
  {
    id: "12",
    name: "Delivery methods",
    type: "deliveryMethods",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: "11",
    name: "Order status",
    type: "orderStatus",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: "5",
    name: "Representative",
    type: "representativeName",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: "29",
    name: "Order tags",
    type: "orderTags",
    selected: true,
    chosen: true,
    isHidden: false,
    bottom: true,
  },
  {
    id: "customerTags",
    name: "Customer tags",
    type: "customerTags",
    selected: false,
    chosen: false,
    isHidden: false,
    bottom: false,
  },
  {
    id: "productTags",
    name: "Product tags",
    type: "productTags",
    selected: false,
    chosen: false,
    isHidden: false,
    bottom: false,
  },
];

export const selects3rdOfSalesSummary = [
  {
    id: "31",
    name: "Customer ID",
    type: "customerCustomId",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: "22",
    name: "Archived",
    type: "archived",
    chosen: true,
    selected: true,
    isHidden: true,
  },
  {
    id: "4",
    name: "Customer",
    type: "customerName",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: "Billing address",
    name: "Billing address",
    type: "customerBillingAddress",
    chosen: true,
    selected: true,
  },
  {
    id: "Shipping address",
    name: "Shipping address",
    type: "customerShippingAddress",
    chosen: true,
    selected: true,
  },
  {
    id: "1",
    name: "Order ID",
    type: "id",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: "3",
    name: "Created by",
    type: "createdBy",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: "2",
    name: "Date created",
    type: "createdAt",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: "13",
    name: "Product",
    type: "productName",
    selected: true,
    chosen: true,
    isHidden: false,
    bottom: true,
  },
  {
    id: "21",
    name: "Manufacturer",
    type: "productManufacturerName",
    selected: true,
    chosen: true,
    isHidden: false,
    bottom: true,
  },
  {
    id: "14",
    name: "Quantity",
    type: "productQuantity",
    selected: true,
    chosen: true,
    isHidden: false,
    bottom: true,
  },
  {
    id: "6",
    name: "Grand total",
    type: "grandTotal",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: "19",
    name: "Discount type",
    type: "appliedDiscountType",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: "16",
    name: "Item discounts",
    type: "productItemDiscount",
    selected: true,
    chosen: true,
    isHidden: false,
    bottom: true,
  },
  {
    id: "17",
    name: "Manufacturer discounts",
    type: "manufacturerDiscount",
    selected: true,
    chosen: true,
    isHidden: false,
    bottom: true,
  },
  {
    id: "18",
    name: "Total order discount",
    type: "totalOrderDiscount",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: "30",
    name: "Price list",
    type: "priceListName",
    chosen: true,
    selected: true,
    isHidden: false,
  },
  {
    id: "20",
    name: "Customer discount",
    type: "customerDiscount",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: "23",
    name: "Free cases",
    type: "productTotalQuantityDiscount",
    selected: true,
    chosen: true,
    isHidden: false,
    bottom: true,
  },
  {
    id: "29",
    name: "Order tags",
    type: "orderTags",
    selected: true,
    chosen: true,
    isHidden: false,
    bottom: true,
  },
  {
    id: "5",
    name: "Representative",
    type: "representativeName",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: "customerTags",
    name: "Customer tags",
    type: "customerTags",
    selected: false,
    chosen: false,
    isHidden: false,
    bottom: false,
  },
  {
    id: "productTags",
    name: "Product tags",
    type: "productTags",
    selected: false,
    chosen: false,
    isHidden: false,
    bottom: false,
  },
];

const selectsOfRepDetails = [
  {
    id: "status",
    name: "Status",
    type: "status",
    selected: true,
    chosen: true,
  },
  {
    id: "customId",
    name: "ID",
    type: "customId",
    selected: true,
    chosen: true,
  },
  {
    id: "name",
    name: "Name",
    type: "name",
    selected: true,
    chosen: true,
  },
  {
    id: "role",
    name: "Role",
    type: "role",
    selected: true,
    chosen: true,
  },
  {
    id: "email",
    name: "Email",
    type: "email",
    selected: true,
    chosen: true,
  },
  {
    id: "phone",
    name: "Phone number",
    type: "phone",
    selected: true,
    chosen: true,
  },
  {
    id: "totalVisitCount",
    name: "Total visits",
    type: "totalVisitCount",
    selected: true,
    chosen: true,
  },
  {
    id: "totalPhotoCount",
    name: "Total photos",
    type: "totalPhotoCount",
    selected: true,
    chosen: true,
  },
  {
    id: "totalNoteCount",
    name: "Total notes",
    type: "totalNoteCount",
    selected: true,
    chosen: true,
  },
  {
    id: "workingHours",
    name: "Total working hours",
    type: "workingHours",
    selected: true,
    chosen: true,
  },
  {
    id: "breakHours",
    name: "Total break hours",
    type: "breakHours",
    selected: true,
    chosen: true,
  },
  {
    id: "distance",
    name: "Total travel distance",
    type: "distance",
    selected: true,
    chosen: true,
  },
  {
    id: "assignedCustomerCount",
    name: "Assigned customers",
    type: "assignedCustomerCount",
    selected: true,
    chosen: true,
  },
  {
    id: "activeCustomerCount",
    name: "Active customers",
    type: "activeCustomerCount",
    selected: true,
    chosen: true,
  },
  {
    id: "inactiveCustomerCount",
    name: "Inactive customers",
    type: "inactiveCustomerCount",
    selected: true,
    chosen: true,
  },
];

// const selectsOfSales = [
//   {
//     id: "1",
//     name: "Rep name",
//     type: "name",
//     selected: true,
//     chosen: true,
//   },
//   {
//     id: "2",
//     name: "Assigned Customers",
//     type: "assignedCustomerCount",
//     selected: true,
//     chosen: true,
//   },
//   {
//     id: "3",
//     name: "Territories",
//     type: "territoryCount",
//     selected: true,
//     chosen: true,
//   },
//   {
//     id: "4",
//     name: "Groups",
//     type: "groupCount",
//     selected: true,
//     chosen: true,
//   },
//   {
//     id: "5",
//     name: "Total sales",
//     type: "totalAmount",
//     selected: true,
//     chosen: true,
//   },
//   {
//     id: "6",
//     name: "New clients",
//     type: "newClientsCount",
//     selected: true,
//     chosen: true,
//   },
//   {
//     id: "7",
//     name: "Visits",
//     type: "totalVisitCount",
//     selected: true,
//     chosen: true,
//   },
//   {
//     id: "8",
//     name: "Working days",
//     type: "workingDayCount",
//     selected: true,
//     chosen: true,
//   },
//   {
//     id: "9",
//     name: "Working hours",
//     type: "workingHours",
//     selected: true,
//     chosen: true,
//   },
//   {
//     id: "10",
//     name: "Distance",
//     type: "distance",
//     selected: true,
//     chosen: true,
//   },
// ];

const selectsRepsSummary = [
  {
    id: 101,
    name: "ID",
    type: "customId",
    selected: true,
    chosen: true,
  },
  {
    id: 102,
    name: "Name",
    type: "name",
    selected: true,
    chosen: true,
  },
  {
    id: 103,
    name: "Role",
    type: "role",
    selected: true,
    chosen: true,
  },
  {
    id: 104,
    name: "Visits",
    type: "totalVisitCount",
    selected: true,
    chosen: true,
  },
  {
    id: 105,
    name: "Orders (Direct)",
    type: "directOrdersCount",
    selected: true,
    chosen: true,
  },
  {
    id: 106,
    name: "Orders (3rd party)",
    type: "thirdPartyOrdersCount",
    selected: true,
    chosen: true,
  },
  {
    id: 107,
    name: "Orders total",
    type: "totalOrderCount",
    selected: true,
    chosen: true,
  },
  {
    id: 108,
    name: "Cases sold (Direct)",
    type: "directOrdersItemsSold",
    selected: true,
    chosen: true,
  },
  {
    id: 109,
    name: "Cases sold (3rd party)",
    type: "thirdPartyOrdersItemsSold",
    selected: true,
    chosen: true,
  },
  {
    id: 110,
    name: "Cases sold total",
    type: "itemsSold",
    selected: true,
    chosen: true,
  },
  {
    id: 111,
    name: "Total revenue (Direct)",
    type: "directOrdersTotalAmount",
    selected: true,
    chosen: true,
  },
  {
    id: 112,
    name: "Total revenue (3rd party)",
    type: "thirdPartyOrdersTotalAmount",
    selected: true,
    chosen: true,
  },
  {
    id: 113,
    name: "Total revenue",
    type: "totalAmount",
    selected: true,
    chosen: true,
  },
  {
    id: 114,
    name: "Photos",
    type: "totalPhotoCount",
    selected: true,
    chosen: true,
  },
  {
    id: 115,
    name: "Notes",
    type: "totalNoteCount",
    selected: true,
    chosen: true,
  },
  {
    id: 116,
    name: "New clients",
    type: "newClientsCount",
    selected: true,
    chosen: true,
  },
  {
    id: 117,
    name: "Date",
    type: "date",
    selected: true,
    chosen: true,
  },
  {
    id: 118,
    name: "Start day",
    type: "startDay",
    selected: true,
    chosen: true,
  },
  {
    id: 119,
    name: "End day",
    type: "endDay",
    selected: true,
    chosen: true,
  },
  {
    id: 120,
    name: "Break",
    type: "breakHours",
    selected: true,
    chosen: true,
  },
  {
    id: "Travel distance",
    name: "Travel distance",
    type: "distance",
    selected: true,
    chosen: true,
  },
  {
    id: "121",
    name: "First visit",
    type: "firstVisit",
    selected: true,
    chosen: true,
    xs: 12,
  },
  {
    id: "17",
    name: "Last visit",
    type: "lastVisit",
    selected: true,
    chosen: true,
    xs: 12,
  },
  {
    id: 122,
    name: "Total time",
    type: "workingHours",
    selected: true,
    chosen: true,
  },
];

const selectsVisits = [
  {
    id: "role",
    name: "Role",
    type: "role",
    selected: true,
    chosen: true,
  },
  {
    id: "custom_id",
    name: "ID",
    type: "customId",
    selected: true,
    chosen: true,
  },
  {
    id: "name",
    name: "Name",
    type: "name",
    selected: true,
    chosen: true,
  },
  {
    id: "data",
    name: "Date",
    type: "date",
    selected: true,
    chosen: true,
  },
  {
    id: "business_name",
    name: "Business name",
    type: "businessName",
    chosen: true,
    selected: true,
  },
  {
    id: "billing_address",
    name: "Billing address",
    type: "billingAddress",
    chosen: true,
    selected: true,
  },
  {
    id: "shipping_address",
    name: "Shipping address",
    type: "shippingAddress",
    chosen: true,
    selected: true,
  },
  {
    id: "check_in",
    name: "Check In",
    type: "checkIn",
    chosen: true,
    selected: true,
  },
  {
    id: "check_out",
    name: "Check Out",
    type: "checkOut",
    chosen: true,
    selected: true,
  },
  {
    id: "total_time",
    name: "Total time",
    type: "totalTime",
    selected: true,
    chosen: true,
  },
  {
    id: "check_in_status",
    name: "Check In Status",
    type: "checkInStatus",
    chosen: true,
    selected: true,
  },
  {
    id: "cases_sold_count",
    name: "Cases sold (Direct)",
    type: "directOrdersItemsSold",
    chosen: true,
    selected: true,
  },
  {
    id: "third_party_orders_items_sold",
    name: "Cases sold (3rd party)",
    type: "thirdPartyOrdersItemsSold",
    chosen: true,
    selected: true,
  },
  {
    id: "items_sold",
    name: "Cases sold total",
    type: "itemsSold",
    chosen: true,
    selected: true,
  },
  {
    id: "notes",
    name: "Notes",
    type: "totalNoteCount",
    selected: true,
    chosen: true,
  },
  {
    id: "direct_orders_count",
    name: "Orders (Direct)",
    type: "directOrdersCount",
    selected: true,
    chosen: true,
  },
  {
    id: "third_party_orders_count",
    name: "Orders (3rd party)",
    type: "thirdPartyOrdersCount",
    selected: true,
    chosen: true,
  },
  {
    id: "total_order_count",
    name: "Orders total",
    type: "totalOrderCount",
    selected: true,
    chosen: true,
  },
  {
    id: "total_photo_count",
    name: "Photos",
    type: "totalPhotoCount",
    selected: true,
    chosen: true,
  },
  {
    id: "forms_count",
    name: "Forms Submission",
    type: "formsCount",
    selected: true,
    chosen: true,
  },
];

// const selectsOfMerch = [
//   ...selectsOfSales.slice(0, 4),
//   {
//     id: "11",
//     name: "Photos",
//     type: "totalPhotoCount",
//     selected: true,
//     chosen: true,
//   },
//   ...selectsOfSales.slice(5),
// ];

const productSelects = [
  {
    id: 201,
    name: "Category name",
    type: "categoryName",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: 202,
    name: "Product name",
    type: "name",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: 203,
    name: "SKU",
    type: "sku",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: 204,
    name: "Manufacturer name",
    type: "manufacturerName",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: 205,
    name: "Available",
    type: "available",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: 206,
    name: "Total sold quantity",
    type: "totalSoldQuantity",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: 207,
    name: "Total revenue",
    type: "totalRevenue",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: 208,
    name: "Wholesale price",
    type: "wholesalePrice",
    selected: true,
    chosen: true,
    isHidden: false,
  },
  {
    id: 209,
    name: "Retail price",
    type: "retailPrice",
    selected: true,
    chosen: true,
    isHidden: false,
  },
];

export const reportsList = [
  {
    title: "Customers",
    reportsCount: 2,
    config: {
      isShow: {
        reportPeriodShow: false,
        numberOptionsShow: true,
        withoutCents: true,
        showNegativeNumbersInRed: false,
        groupBy: true,
        includeInactive: true,
        check: false,
        prospectsShow: true,
      },
    },
    reports: [
      {
        title: "Customer details",
        reportsPerGroup: ["Customers", "Customers groups"],
        selectAndReorderColumnsBottom: customerFields,
        type: "full",
        url: "customer",
        canGetDate: false,
        date: "All time",
        reportCategory: "customers-details",
      },
      // {
      //   title: "Active customers (3 month)",
      //   reportsPerGroup: ["Customers", "Customers groups"],
      //   selectAndReorderColumnsBottom: customerFields,
      //   type: "three_month_active",
      //   url: "customer",
      //   canGetDate: true,
      //   id: "customers-active",
      // },
      // {
      //   title: "Inactive customers (3 month)",
      //   reportsPerGroup: ["Customers", "Customers groups"],
      //   selectAndReorderColumnsBottom: customerFields.filter(
      //     (select) =>
      //       ![
      //         "groupName",
      //         "federalTaxId",
      //         "billingZip",
      //         "shippingZip",
      //       ].includes(select.type)
      //   ),
      //   type: "three_month_non_active",
      //   url: "customer",
      //   canGetDate: true,
      //   id: "customers-inactive",
      // },
      {
        title: "Top customers",
        reportsPerGroup: ["Customers", "Customers groups"],
        selectAndReorderColumnsBottom: customerFields.filter(
          (select) => !["billingZip", "shippingZip"].includes(select.type)
        ),
        type: "top",
        url: "customer",
        canGetDate: false,
        date: "All time",
        reportCategory: "customers-top",
      },
      // {
      //   title: "Customers (no order direct)",
      //   reportsPerGroup: ["Customers", "Customers groups"],
      //   selectAndReorderColumnsBottom: customerFields.filter(
      //     (select) =>
      //       ![
      //         "federalTaxId",
      //         "hasOrderDirect",
      //         "ordersCount",
      //         "totalSalesAmount",
      //       ].includes(select.type)
      //   ),
      //   type: "no_order_direct",
      //   url: "customer",
      //   canGetDate: false,
      //   fetchParam: {
      //     customerIds: { order_direct: '["pending", "rejected", null]' },
      //   },
      //   id: "customers-order_direct",
      // },
    ],
  },
  {
    title: "Orders",
    reportsCount: 2,
    reports: [
      // {
      //   title: "Sales (1 month)",
      //   reportsPerGroup: ["Customers", "Reps", "Manufacturers"],
      //   selectAndReorderColumnsBottom: selectsOfSalesSummary,
      //   type: "common",
      //   url: "order",
      //   date: "This month",
      //   canGetDate: true,
      //   id: "orders-sales-1month",
      // },
      // {
      //   title: "Sales (3 month)",
      //   reportsPerGroup: ["Customers", "Reps", "Manufacturers"],
      //   selectAndReorderColumnsBottom: selectsOfSalesSummary.filter(
      //     (select) => !["2", "7", "12"].includes(select.id)
      //   ),
      //   type: "common",
      //   url: "order",
      //   start_date: moment().subtract(3, "months"),
      //   end_date: moment(),
      //   canGetDate: true,
      //   id: "orders-sales-3month",
      // },
      // {
      //   title: "Top 50 orders last month",
      //   reportsPerGroup: ["Customers", "Reps", "Manufacturers"],
      //   selectAndReorderColumnsBottom: selectsOfSalesSummary,
      //   type: "top_fifty",
      //   url: "order",
      //   date: "Last month",
      //   canGetDate: true,
      //   id: "orders-top",
      // },

      {
        title: "Order sales summary",
        reportsPerGroup: ["Customers", "Reps", "Manufacturers", "Groups"],
        selectAndReorderColumnsBottom: selectsOfSalesSummary,
        type: "common",
        url: "order",
        date: "This month",
        orderType: "DIRECT",
        canGetDate: true,
        reportCategory: "orders-sales-summary",
      },

      {
        title: "3rd party sales summary",
        reportsPerGroup: ["Customers", "Reps", "Manufacturers", "Groups"],
        selectAndReorderColumnsBottom: selects3rdOfSalesSummary,
        type: "common",
        orderType: "THIRD_PARTY",
        url: "order",
        date: "This month",
        canGetDate: true,
        reportCategory: "3rd-party-sales-summary",
      },
    ],
    config: {
      isShow: {
        reportPeriodShow: true,
        numberOptionsShow: true,
        withoutCents: true,
        showNegativeNumbersInRed: false,
        showIncludeCancelledOrders: true,
        groupBy: true,
        includeArchived: true,
        orderCheck: true,
      },
    },
  },
  {
    title: "Product & Inventory",
    reportsCount: 3,
    reports: [
      {
        title: "Current inventory",
        reportsPerGroup: ["Products", "Manufacturers"],
        type: "inventory",
        url: "product-and-inventory",
        selectAndReorderColumnsBottom: [
          ...productSelects.slice(0, 5),
          ...productSelects.slice(7, 9),
        ],
        reportCategory: "products-inventory",
        showAccumulated: false,
      },
      {
        title: "Low stock inventory",
        reportsPerGroup: ["Products", "Manufacturers"],
        type: "low_stock",
        url: "product-and-inventory",
        selectAndReorderColumnsBottom: [
          ...productSelects.slice(0, 5),
          ...productSelects.slice(7, 9),
        ],
        reportCategory: "products-low-stock",
        showAccumulated: false,
      },
      {
        title: "Best sellers",
        reportsPerGroup: ["Products", "Manufacturers"],
        type: "top_sale",
        url: "product-and-inventory",
        selectAndReorderColumnsBottom: [
          ...productSelects.slice(0, 4),
          ...productSelects.slice(5, 9),
          productSelects[4],
        ],
        canGetDate: true,
        date: "This month",
        showAccumulated: false,
        reportCategory: "products-top",
      },
      {
        label: "products",
        title: "SKU's not ordered",
        reportsPerGroup: ["Products", "Manufacturers"],
        type: "no_sale",
        url: "product-and-inventory",
        selectAndReorderColumnsBottom: [
          ...productSelects.slice(0, 4),
          ...productSelects.slice(5, 9),
          productSelects[4],
        ],
        canGetDate: true,
        date: "This month",
        showAccumulated: false,
        reportCategory: "sku-not-ordered",
      },
      {
        title: "Depletion",
        reportsPerGroup: ["Customers", "Products", "Manufacturers"],
        type: "no_sale",
        url: "product-and-inventory/depletion",
        selectAndReorderColumnsBottom: [
          { ...customerFields[0], type: "customerName" },
          ...shippingAddressFields,
          { ...productSelects[1], type: "productName" },
        ],
        canGetDate: true,
        date: "This month",
        showAccumulated: true,
        reportCategory: "depletion",
      },
    ],
    config: {
      isShow: {
        reportPeriodShow: true,
        numberOptionsShow: true,
        withoutCents: true,
        showNegativeNumbersInRed: true,
        groupBy: true,
        includeInactive: true,
        check: true,
      },
    },
  },
  {
    title: "Representatives",
    reportsCount: 4,
    reports: [
      {
        title: "Rep details",
        label: "reps",
        reportsPerGroup: [
          "All",
          "Sales reps",
          "Merchandisers",
          "Territory",
          "Customers groups",
        ],
        selectAndReorderColumnsBottom: selectsOfRepDetails,
        url: "representative",
        type: "common",
        reportCategory: "representatives-sales",
        roleByGroup: { "Sales reps": "SALES", Merchandisers: "MERCHANDISER" },
      },
      {
        title: "Reps summary",
        label: "reps",
        reportsPerGroup: ["All", "Sales reps", "Merchandisers"],
        selectAndReorderColumnsBottom: selectsRepsSummary,
        url: "representative-summary",
        roleByGroup: { "Sales reps": "SALES", Merchandisers: "MERCHANDISER" },
        canGetDate: true,
        reportCategory: "representatives-summary",
      },
      {
        title: "Visits",
        label: "reps",
        reportsPerGroup: ["All", "Sales reps", "Merchandisers"],
        selectAndReorderColumnsBottom: selectsVisits,
        url: "representative-visits",
        roleByGroup: { "Sales reps": "SALES", Merchandisers: "MERCHANDISER" },
        canGetDate: true,
        reportCategory: "visits",
        withoutCents: null,
      },
    ],
    config: {
      isShow: {
        reportPeriodShow: true,
        numberOptionsShow: true,
        withoutCents: true,
        showNegativeNumbersInRed: false,
        groupBy: true,
        showInactiveReps: true,
      },
    },
  },
];

export const OPTIONAL_CUSTOMER_COLUMN = {
  id: "30",
  name: "Status",
  type: "status",
  chosen: true,
  selected: true,
};
