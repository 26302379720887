import { object, number } from "prop-types";

import { cl } from "./QuestionItem.styles";

import { Box, Stack, Typography } from "@mui/material";
import { handleGetIcons } from "Pages/FormViewPage/FormViewPage.constants";
import { formatLongWords } from "helpers/helpers";

export const QuestionItem = ({ question, index }) => {
  const { type, text } = question || {};

  const FORM_ICONS = handleGetIcons({
    singleProps: {
      firstRectFill: "rgba(165, 165, 165, 0.60)",
      secondRectStroke: "#fff",
      lastRectFill: "#fff",
      strokeOpacity: 1,
    },
    multiProps: {
      pathFill: "rgba(165, 165, 165, 0.60)",
      rectFill: "#fff",
    },
  });

  return (
    <Stack sx={{ ...cl.itemWrapper, pr: "52px" }} direction="row">
      <Box
        sx={{
          ...cl.iconWrapper,
          ...(type === "MULTIPLE_ANSWER" && { ...cl.multiAnswerIcon }),
        }}
      >
        {FORM_ICONS[type]}
      </Box>

      <Box display="flex" gap="4px">
        <Typography sx={cl.text}>{index}.</Typography>
        <Typography
          sx={{
            ...cl.text,
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            textOverflow: "ellipsis",
          }}
        >{`${formatLongWords(text, 20)}`}</Typography>
      </Box>
    </Stack>
  );
};

QuestionItem.propTypes = {
  question: object,
  index: number,
};
