import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { bool, func, object, string } from "prop-types";
import { Box, Menu, MenuItem, Popper, Stack, Typography } from "@mui/material";
import { CalendarPicker, LocalizationProvider } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import { StyledButton, StyledSelect, StyledSelectTime } from "components";
import moment from "moment-timezone";
import { ClockNewIcon } from "components/Icons/ClockNewIcon";
import { cl } from "components/TaskDrawer/components/DueDateBlock/DueDateBlock.styles";
import { cl as clPopper } from "./CompleteTaskPopper.styles";
import { useTheme } from "@emotion/react";
import { CalendarIconNew } from "components/Icons";

export const CompleteTaskPopper = ({
  open = false,
  anchorElPopper = null,
  handleClose = () => {},
  handleConfirm = () => {},
  assignedTo = "",
  timeZone = "",
  taskId = "",
}) => {
  const theme = useTheme();
  const dateSelectRef = useRef(null);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const handleCloseMenu = () => setAnchorElMenu(null);
  const incomingDateBeforeOpen = useRef(null);

  const addBusinessDays = useCallback(
    (businessDays) => {
      const startDate = moment();
      let date = moment(startDate);

      while (businessDays > 0) {
        date.add(1, "days");

        if (date.isoWeekday() <= 5) {
          businessDays--;
        }
      }

      return date.tz(timeZone).hours(0).minutes(0).seconds(0).utc().format();
    },
    [timeZone]
  );

  const setPeriod = useCallback(
    (period) => {
      let count = 0;
      let type = "";

      if (period === "Today")
        return moment()
          .tz(timeZone)
          .hours(0)
          .minutes(0)
          .seconds(0)
          .utc()
          .format();
      if (period === "Tomorrow") {
        count = 1;
        type = "days";
      }
      if (period === "In 3 business days") {
        return addBusinessDays(3);
      }
      if (period === "In 1 week") {
        count = 1;
        type = "weeks";
      }
      if (period === "In 2 weeks") {
        count = 2;
        type = "weeks";
      }
      if (period === "In 1 month") {
        count = 1;
        type = "months";
      }

      return moment()
        .tz(timeZone)
        .hours(0)
        .minutes(0)
        .seconds(0)
        .utc()
        .add(count, type)
        .format();
    },
    [timeZone, addBusinessDays]
  );

  const DUE_DATE_SELECT = useMemo(
    () => [
      { value: "Today", label: "Today" },
      { value: "Tomorrow", label: "Tomorrow" },
      {
        value: "In 3 business days",
        label: `In 3 business days (${moment(addBusinessDays(3)).format(
          "dddd"
        )})`,
      },
      {
        value: "In 1 week",
        label: `In 1 week (${moment(setPeriod("In 1 week")).format("MMM DD")})`,
      },
      {
        value: "In 2 weeks",
        label: `In 2 weeks (${moment(setPeriod("In 2 weeks")).format(
          "MMM DD"
        )})`,
      },
      {
        value: "In 1 month",
        label: `In 1 month (${moment(setPeriod("In 1 month")).format(
          "MMM DD"
        )})`,
      },
    ],
    [addBusinessDays, setPeriod]
  );

  const [dueTime, setDueTime] = useState("");
  const [dueDateSelect, setDueDateSelect] = useState("");
  const [dueDate, setDueDate] = useState("");

  useEffect(() => {
    if (dueDateSelect) {
      return setDueDate(setPeriod(dueDateSelect));
    }
  }, [dueDateSelect, setPeriod, timeZone]);

  useEffect(() => {
    if (!open) {
      setDueTime("08:00");
      setDueDateSelect(DUE_DATE_SELECT[2].value);
    }
  }, [DUE_DATE_SELECT, open]);

  const handleChangeCalendarPicker = (date) => {
    const formattedDate = moment
      .tz(date, timeZone)
      .hours(0)
      .minutes(0)
      .seconds(0)
      .utc()
      .format();
    setDueDate(formattedDate);
  };

  const handleCancel = () => {
    handleCloseMenu();
    setDueDateSelect(incomingDateBeforeOpen.current);
  };

  return (
    <Popper
      style={{ zIndex: theme.zIndex.appBar }}
      anchorEl={anchorElPopper.current}
      open={open}
      placement="right-end"
    >
      <Box sx={clPopper.wrapper}>
        <Box>
          <Typography fontSize={20} fontWeight={700} color="#707070">
            Create a follow up task?
          </Typography>
        </Box>

        <Box>
          <Typography fontSize={13} fontWeight={400} color="#707070">
            We will create a follow-up task with{" "}
            <Box component="span" fontWeight={600}>
              {assignedTo}
            </Box>
            .
          </Typography>
        </Box>

        <Stack mt="12px" flexDirection="row" gap={1}>
          {/* Due date */}
          <Box width="67%" ref={dateSelectRef}>
            <Menu
              sx={{ "& .MuiList-root": { p: 0 } }}
              anchorEl={anchorElMenu}
              open={!!anchorElMenu}
              onClose={handleCloseMenu}
            >
              <Box sx={cl}>
                <Box>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <CalendarPicker
                      timezone={timeZone}
                      date={dueDate ? moment.tz(dueDate, timeZone) : null}
                      onChange={(date) => handleChangeCalendarPicker(date)}
                    />
                  </LocalizationProvider>
                  <Stack
                    flexDirection="row"
                    justifyContent="flex-end"
                    sx={{ px: "8px", pb: "16px" }}
                  >
                    <StyledButton
                      sx={{ width: "64px", height: "28px" }}
                      fontWeight={500}
                      label="Cancel"
                      onClick={handleCancel}
                    />

                    <StyledButton
                      sx={{ width: "64px", height: "28px" }}
                      label="Today"
                      fontWeight={500}
                      onClick={() => {
                        setDueDate(setPeriod("Today"));
                        handleCloseMenu();
                      }}
                    />
                  </Stack>
                </Box>
              </Box>
            </Menu>

            <StyledSelect
              fullWidth
              sx={clPopper.selectSx}
              labelSx={clPopper.selectLabelSx}
              IconComponent={CalendarIconNew}
              PaperPropsSx={clPopper.PaperPropsSx}
              height="40px"
              fontSize="13px !important"
              InputProps={{ style: { fontSize: "13px !important" } }}
              labelProps={{ sx: { top: 3 } }}
              value={dueDateSelect || moment(dueDate).format("MM-DD-YYYY")}
              onChange={(e) => {
                setDueDateSelect(e.target.value);
              }}
            >
              {DUE_DATE_SELECT.map(({ value: date, label }) => (
                <MenuItem
                  sx={{ p: "12px", position: "relative" }}
                  key={date}
                  value={date}
                >
                  {label}

                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      borderBottom: "0.5px solid #D9D9D950",
                      width: "calc(100% - 24px)",
                    }}
                  />
                </MenuItem>
              ))}

              <MenuItem
                sx={{ p: "12px", position: "relative" }}
                onClick={() => {
                  setAnchorElMenu(dateSelectRef.current);
                  incomingDateBeforeOpen.current = dueDateSelect;
                }}
              >
                Custom Date
              </MenuItem>

              {!dueDateSelect && !!dueDate && (
                <MenuItem
                  sx={{ display: "none" }}
                  value={moment(dueDate).format("MM-DD-YYYY")}
                >
                  {moment(dueDate).format("MM-DD-YYYY")}
                </MenuItem>
              )}
            </StyledSelect>
          </Box>

          {/* Due time */}
          <Box width="33%">
            <StyledSelectTime
              placeholder="Due time"
              fullWidth
              sx={clPopper.selectTimeSx}
              labelSx={clPopper.selectTimeLabelSx}
              IconComponent={ClockNewIcon}
              height="40px"
              fontSize="13px !important"
              InputProps={{ style: { fontSize: "13px !important" } }}
              labelProps={{ sx: { top: 3 } }}
              value={dueTime}
              onChange={(e) => {
                const time = e.target.value;
                setDueTime(time);
              }}
            />
          </Box>
        </Stack>

        <Box mt="24px" display="flex" alignItems="center" gap="8px">
          <StyledButton
            sx={{ width: "110px", height: "32px" }}
            label="Create Task"
            variant="contained"
            onClick={() => {
              handleConfirm({ taskId, dueDate, dueTime });
              handleClose();
            }}
          />
          <StyledButton
            sx={{ width: "110px", height: "32px" }}
            label="Not Now"
            variant="outlined"
            onClick={handleClose}
          />
        </Box>
      </Box>
    </Popper>
  );
};

CompleteTaskPopper.propTypes = {
  open: bool,
  anchorElPopper: object,
  handleConfirm: func,
  handleClose: func,
  assignedTo: string,
  timeZone: string,
  taskId: string,
};
