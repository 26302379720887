import { useTheme } from "@emotion/react";
import { Controller } from "react-hook-form";
import moment from "moment";
import { bool, object, func, number } from "prop-types";
import { forwardRef, Fragment, useEffect, useMemo, useRef } from "react";

import { StyledProductIco } from "components/Icons";
import { StyledButton, StyledTextField, StyledTooltip } from "components";
import AssignedRepsPopper from "Pages/CustomersPage/components/CustomersTab/components/AssignedRepsPopper/AssignedRepsPopper";

import { useIsVisible } from "helpers/hooks";
import { photoUrl, useAdmin } from "helpers/helpers";

import useStyles from "./styles";

import { Grid, Typography, Box } from "@mui/material";

const OrderedProductItem = forwardRef(
  (
    {
      index,
      control,
      product,
      setValue,
      disabled,
      outOfStock,
      calcAvailable,
      isAlreadyAdded,
      isNonInventory,
      handleAddProduct,
      handleSubtractQty,
      checkSellingOutStock,
      handleSetProductError,
    },
    ref
  ) => {
    const {
      sku,
      name,
      photos,
      quantity,
      orderProducts,
      parentProduct,
      totalQuantity,
      wholesalePrice,
      totalDelivered,
    } = product || {};

    const boxRef = useRef();

    const theme = useTheme();
    const isAdmin = useAdmin();
    const classes = useStyles();
    const isVisible = useIsVisible(boxRef);

    const { zIndex } = theme;
    const popOverZIndex = zIndex.tooltip + 1;
    const availableProduct = calcAvailable(product);

    const showCorrentColumns = !disabled && !outOfStock && !isAlreadyAdded;

    const openToolTip = useMemo(
      () =>
        product?.minOrderQTY > quantity ||
        product?.product?.minOrderQTY > quantity ||
        product?.parentProduct?.minOrderQTY > quantity ||
        product?.product?.parentProduct?.minOrderQTY > quantity,
      [product, quantity]
    );

    const showMinOrderTooltip = boxRef.current && !!quantity && openToolTip;

    const showQtyTooltip =
      boxRef.current &&
      !isNonInventory &&
      !!quantity &&
      checkSellingOutStock(product) &&
      !showMinOrderTooltip;

    const setQtyText = useMemo(() => {
      if (availableProduct <= 0) return "Out of stock";
      return `Stock: ${availableProduct} item`;
    }, [availableProduct]);

    useEffect(() => {
      handleSetProductError(
        showQtyTooltip,
        `products.${index}.quantity`,
        "Stock error"
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showQtyTooltip]);

    useEffect(() => {
      handleSetProductError(
        showMinOrderTooltip,
        `products.${index}.quantity`,
        "Stock error"
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showMinOrderTooltip]);

    const handleOnChange = (e) => {
      if (isAdmin) return;

      const val = parseInt(e.target.value, 10);
      if (
        (outOfStock && val > product.quantity) ||
        val < product.totalDelivered
      )
        return;
      setValue(
        `products.${index}.quantity`,
        e.target.value > 0
          ? parseInt(e.target.value, 10)
          : product?.minOrderQTY
          ? product?.minOrderQTY
          : 1,
        { shouldDirty: true }
      );
    };

    const ITEM_DATA = [
      {
        show: true,
        element: (
          <Grid xs={3} item display="flex" alignItems="center" gap="8px">
            <Box className={classes.productPhoto} style={{ height: "35px" }}>
              <StyledProductIco
                src={photos?.length ? photoUrl(photos[0].fileName) : ""}
                styles={{
                  objectFit: "contain",
                  width: "35px",
                  height: "35px",
                  borderRadius: "4px",
                }}
                placeholderStyles={{
                  style: {
                    width: "35px",
                    height: "35px",
                    borderRadius: "4px",
                    border: "1px solid #D5D9D9",
                    boxSizing: "border-box",
                    marginTop: "0.5px",
                  },
                }}
              />
            </Box>

            <Grid
              item
              display="flex"
              flexDirection="column"
              justifyContent="center"
              overflow="hidden"
              width="calc(100% - 43px)"
            >
              <Box display="flex" alignItems="center" gap={0.5}>
                <Typography className={classes.text} fontWeight="500" noWrap>
                  {name || parentProduct?.name}
                </Typography>
              </Box>

              {sku && (
                <Typography className={classes.text} fontWeight="300" noWrap>
                  {sku}
                </Typography>
              )}
            </Grid>
          </Grid>
        ),
      },
      {
        show: true,
        element: (
          <Grid item xs={2.2} display="flex" alignItems="center">
            {orderProducts[0]?.order?.createdAt && (
              <a
                href={`/orders/${orderProducts[0]?.order?.id}`}
                target="_blank"
                rel="noreferrer"
              >
                <Typography
                  className={classes.text}
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  {moment(orderProducts[0]?.order?.createdAt).format(
                    "MMM D, Y h:mm A"
                  )}
                </Typography>
              </a>
            )}
          </Grid>
        ),
      },
      {
        show: true,
        element: (
          <Grid item xs={1.1} display="flex" alignItems="center">
            <Typography className={classes.text}>
              ${(wholesalePrice || 0).toFixed(2)}
            </Typography>
          </Grid>
        ),
      },
      {
        show: true,
        element: (
          <Grid item xs={1.1} className={classes.grid}>
            {!!totalQuantity && (
              <Grid className={classes.orderedQty}>
                <Typography fontSize="9px" color="#1C1C19">
                  {totalQuantity}
                </Typography>
              </Grid>
            )}
          </Grid>
        ),
      },
      {
        show: !showCorrentColumns,
        element: (
          <Grid item xs={1.2} display="flex" alignItems="center" ml="auto">
            <StyledTooltip
              key={product.id}
              title={`Cannot add this product to the cart.
           Items Available:
              ${calcAvailable(product)}, Minimum Order QTY:
              ${
                product?.minOrderQTY ||
                product?.product?.minOrderQTY ||
                product?.parentProduct?.minOrderQTY ||
                product?.product?.parentProduct?.minOrderQTY
              }`}
              placement="top"
              arrow
              sx={{
                "& .MuiTooltip-tooltip": {
                  textAlign: "center",
                  maxWidth: "270px",
                },
              }}
              PopperProps={{
                modifiers: [
                  {
                    name: "offset",
                    options: { offset: [0, -6] },
                  },
                ],
              }}
              disableTouchListener
              disableFocusListener
              disableHoverListener={
                showMinOrderTooltip || showQtyTooltip || isAlreadyAdded
              }
            >
              <Typography className={classes.text}>
                {isAlreadyAdded ? "Already added" : "Out of stock"}
              </Typography>
            </StyledTooltip>
          </Grid>
        ),
      },
      {
        show: showCorrentColumns,
        element: (
          <Grid
            item
            xs={1.2}
            display="flex"
            justifyContent="center"
            flexDirection="column"
            ml="auto"
          >
            <Box ref={boxRef} width="75px" />

            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  value={quantity || 0}
                  size="small"
                  formSx={{ width: "75px" }}
                  InputProps={{
                    startAdornment: (
                      <Box height="100%">
                        <StyledButton
                          label="–"
                          disabled={
                            isAdmin ||
                            field.value === 0 ||
                            totalDelivered === quantity
                          }
                          sx={{
                            borderRadius: "4px 0 0 4px",
                            minWidth: "23px",
                            width: "23px",
                            p: 0,
                            backgroundColor: "#F7F7F7",
                            height: "100%",
                            borderColor: error
                              ? "#EB4233!important"
                              : "#D5D9D9!important",
                            borderTop: "none",
                            borderBottom: "none",
                            "&.Mui-disabled": {
                              borderLeft: "1px solid #D5D9D9",
                              opacity: 0.75,
                            },
                            "&:hover": {
                              borderTop: "none",
                              borderBottom: "none",
                              borderColor: error
                                ? "#EB4233!important"
                                : "#D5D9D9!important",
                            },
                          }}
                          color="edit"
                          onClick={() =>
                            handleSubtractQty(product, field.value, index)
                          }
                          variant="outlined"
                        />
                      </Box>
                    ),
                    endAdornment: (
                      <StyledButton
                        disabled={
                          isAdmin ||
                          outOfStock ||
                          (!isNonInventory && checkSellingOutStock(product))
                        }
                        label="+"
                        sx={{
                          borderRadius: "0 4px 4px 0",
                          borderColor: error
                            ? "#EB4233!important"
                            : "#D5D9D9!important",
                          borderTop: "none",
                          borderBottom: "none",
                          minWidth: "23px",
                          height: "100%",
                          p: 0,
                          backgroundColor: "#F7F7F7",
                          "&.Mui-disabled": {
                            borderRight: "1px solid #D5D9D9",
                            opacity: 0.75,
                          },
                          "&:hover": {
                            borderTop: "none",
                            borderBottom: "none",
                            borderColor: error
                              ? "#EB4233!important"
                              : "#D5D9D9!important",
                          },
                        }}
                        color="edit"
                        onClick={() =>
                          handleAddProduct(product, field.value, index)
                        }
                        variant="outlined"
                      />
                    ),
                    sx: {
                      height: "25px",
                      borderRadius: "4px",
                      fontSize: "9px",
                      p: 0,
                      "& fieldset": {
                        borderColor: error
                          ? "#EB4233!important"
                          : "#D5D9D9!important",
                        borderRight: "none",
                        borderWidth: "1px!important",
                        p: 0,
                      },
                      "& > input": {
                        p: 0,
                        textAlign: "center",
                      },
                    },
                  }}
                  sx={{ borderColor: "#707070" }}
                  error={error ? " " : ""}
                  {...field}
                  onChange={handleOnChange}
                />
              )}
              name={`products.${index}.quantity`}
              control={control}
            />
          </Grid>
        ),
      },
    ];

    return (
      <>
        <AssignedRepsPopper
          style={{ zIndex: popOverZIndex }}
          open={showMinOrderTooltip && isVisible}
          anchorEl={boxRef.current}
          rep={{
            name: `Minimum Order Quantity: ${
              product?.minOrderQTY ||
              product?.product?.minOrderQTY ||
              product?.parentProduct?.minOrderQTY ||
              product?.product?.parentProduct?.minOrderQTY
            }`,
          }}
          modifiers={[
            {
              name: "offset",
              options: {
                offset: [0, 10],
              },
            },
          ]}
        />

        <AssignedRepsPopper
          style={{ zIndex: popOverZIndex }}
          open={showQtyTooltip && isVisible}
          anchorEl={boxRef.current}
          rep={{ name: setQtyText }}
          modifiers={[{ name: "offset", options: { offset: [0, 10] } }]}
        />

        <Grid
          ref={ref}
          container
          key={product.id}
          columns={9}
          px="9px"
          height="45px"
          sx={{
            py: "5px",
            overflow: "hidden",
            opacity: isAlreadyAdded || disabled || outOfStock ? 0.5 : 1,
            position: "relative",
            borderBottom: "0.5px solid #D5D9D9",
          }}
        >
          {ITEM_DATA.map(({ element, show }, index) => (
            <Fragment key={index}>{show && element}</Fragment>
          ))}
        </Grid>
      </>
    );
  }
);

OrderedProductItem.propTypes = {
  index: number,
  setValue: func,
  disabled: bool,
  control: object,
  product: object,
  outOfStock: bool,
  calcAvailable: func,
  isNonInventory: bool,
  isAlreadyAdded: bool,
  handleAddProduct: func,
  handleSubtractQty: func,
  checkSellingOutStock: func,
  handleSetProductError: func,
};

OrderedProductItem.defaultProps = {
  index: 0,
  product: null,
  control: null,
  disabled: false,
  outOfStock: false,
  setValue: () => {},
  calcAvailable: () => {},
  isAlreadyAdded: false,
  handleAddProduct: () => {},
  handleSubtractQty: () => {},
  checkSellingOutStock: () => {},
  handleSetProductError: () => {},
};
OrderedProductItem.displayName = "PopupProductsItem";

export default OrderedProductItem;
