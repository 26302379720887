export const cl = {
  mainWrapper: {
    mt: 1,
    border: "0.5px solid #E3E3E3",
    borderRadius: "4px",
    bgcolor: "#D5D5D516",
    p: "16px",

    "& ol": { gap: "5px" },
  },
  radioGroup: {
    "& svg": { color: "#00000016 !important" },
    color: "#5F6267",
    gap: "10px",
    "& .MuiFormControlLabel-root": { ml: 0 },
    "& .MuiTypography-root": { fontSize: "13px", ml: "8px" },
    "& .MuiRadio-root": { p: "0px" },
    "& .MuiSvgIcon-root": { fontSize: 18 },
  },
  formControlLabel: ({ isActive, activeItem, item, errors, orderIndex }) => ({
    ...(isActive && {
      "&:hover": {
        borderRadius: "6px",
        bgcolor:
          !!activeItem?.uuid && activeItem?.uuid === item?.uuid && isActive
            ? null
            : "#D5D5D532",
      },
    }),
    minWidth:
      !!activeItem?.uuid && activeItem?.uuid === item?.uuid && isActive
        ? "100%"
        : "fit-content",
    "& .MuiFormControlLabel-label": {
      width: "100%",

      ...(!!errors?.questions?.[orderIndex]?.options?.[item?.orderIndex] && {
        maxWidth: "162px",
      }),
    },
    "& .MuiTypography-root": {
      ml: "9px",
      color: "#5F6267",
    },
  }),
  iconWrapper: {
    display: "inline-flex",
    alignItems: "center",
    gap: "8px",
    width: "100%",
    height: "22px",
  },
  iconButton: ({ isActive, hoveredElement, item, options }) => ({
    ml: "auto",
    p: 0,
    opacity:
      isActive && hoveredElement === item?.uuid && options?.length > 1 ? 1 : 0,
    width: "16px",
    height: "16px",
    transition: "all 0.3s",
    background: "#939393",
    borderRadius: "50%",

    "&:hover": {
      background: "#939393",
    },
    "& .MuiSvgIcon-root": {
      color: "#FFF !important",
      fontSize: "14px",
    },
  }),
  addNewBtn: {
    ml: "4px",
    color: "#5F6267",
    height: "18px",
    fontSize: "13px",
    fontWeight: 400,
    padding: 0,
    whiteSpace: "nowrap",
    width: "fit-content",
    minWidth: 0,
    mt: "2px",
    "& svg": {
      width: "16px",
      height: "16px",
      marginRight: "9px",
    },
  },
};
