export const cl = {
  wrapper: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "flex-start",
    fontSize: "13px",
    lineHeight: "20px",
    padding: 0,
    margin: 0,
    width: "100%",
    overflow: "hidden",
    boxSizing: "border-box",
  },
  iconWrapper: {
    display: "flex",
    marginRight: "8px",
    alignItems: "center",
    color: "#A5A5A5",
  },
  counterText: {
    color: "#42A57F",
    fontWeight: 500,
    marginRight: "4px",
    fontSize: "13px",
  },
  labelText: { color: "#5F6267", fontWeight: 400, fontSize: "13px" },
};
