import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { array, string, func } from "prop-types";

import { EditSavedFilterDialog, StyledButton } from "components";
import { ViewTypeIcon } from "components/Icons";
import { SavedFiltersMenu } from "./components/SavedFiltersMenu";

import { useCustomFilterMenu } from "./useCustomFilterMenu";

import {
  filterForEditSelector,
  savedFiltersListSelector,
  savedFiltersLoadingSelector,
  openedViewNameDialogSelector,
  selectedFilterIdSelector,
} from "redux/selectors/savedFilters";

import { NAME_VIEW_DIALOGS } from "utils/constants";
import { handleSetSwitchesForEdit } from "helpers/filters";

import { CircularProgress } from "@mui/material";
import { currentUserSelector } from "redux/selectors/auth";

const selector = createSelector(
  filterForEditSelector,
  selectedFilterIdSelector,
  savedFiltersListSelector,
  savedFiltersLoadingSelector,
  openedViewNameDialogSelector,
  currentUserSelector,
  (
    filterForEdit,
    selectedFilterId,
    savedFiltersList,
    savedFiltersLoading,
    openedViewNameDialog,
    currentUser
  ) => ({
    filterForEdit,
    savedFiltersList,
    selectedFilterId,
    savedFiltersLoading,
    openedViewNameDialog,
    currentUser,
  })
);

export const CustomFilterMenu = ({
  type,
  switches,
  onFilterApply,
  selectMatchData,
  resetFilterFunc,
}) => {
  const {
    currentUser,
    filterForEdit,
    savedFiltersList,
    selectedFilterId,
    savedFiltersLoading,
    openedViewNameDialog,
  } = useSelector(selector);

  const filters = savedFiltersList[type];

  const {
    filterAnchor,
    onSelectFilter,
    filterMenuOpen,
    setFilterMenuOpen,
    getActionList,
    onCloseEditDialog,
    onDelete,
  } = useCustomFilterMenu({
    onFilterApply,
    type,
    savedFilters: filters,
    resetFilterFunc,
    selectedFilterId,
    currentUser,
  });

  return (
    <>
      <EditSavedFilterDialog
        open={openedViewNameDialog[NAME_VIEW_DIALOGS.EDIT_FILTER]}
        onClose={onCloseEditDialog}
        {...{
          onFilterApply,
          selectMatchData,
          selectedFilterId,
          filterForEdit,
          switches: handleSetSwitchesForEdit(filterForEdit.filters, switches),
          type,
          onDelete,
        }}
      />
      {!!filters?.length && (
        <StyledButton
          disabled={savedFiltersLoading}
          endIcon={
            savedFiltersLoading ? (
              <CircularProgress size={18} />
            ) : (
              <ViewTypeIcon />
            )
          }
          variant="outlined"
          ref={filterAnchor}
          color="edit"
          sx={{
            height: "39px",
            width: "40px",
            minWidth: "40px",
            p: 0,
            maxWidth: "74px",
            border: "0.5px solid #D5D9D9",
          }}
          fontSize="15px"
          onClick={() => setFilterMenuOpen(true)}
        />
      )}
      <SavedFiltersMenu
        anchorEl={filterAnchor.current}
        open={filterMenuOpen}
        onClose={() => setFilterMenuOpen(false)}
        {...{
          getActionList,
          selectedFilterId,
          savedFiltersList: filters,
          loading: savedFiltersLoading,
          onFilterApply: onSelectFilter,
        }}
      />
    </>
  );
};

CustomFilterMenu.propTypes = {
  type: string,
  switches: array,
  onFilterApply: func,
  resetFilterFunc: func,
  selectMatchData: array,
};

CustomFilterMenu.defaultProps = {
  type: "",
  switches: [],
  selectMatchData: [],
  onFilterApply: () => {},
  resetFilterFunc: () => {},
};
