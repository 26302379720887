import { useCallback, useEffect, useMemo } from "react";
import { string, func } from "prop-types";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { defaultValues } from "./OrdersTab.constants";
import { validationSchema } from "./OrdersTab.validations";
import { useDebounce, useRepsPermissions } from "helpers/hooks";
import { useAdmin, removeSeparatorFromNum } from "helpers/helpers";
import { updateDistributorSettingsService } from "services/account";
import { setCurrentUser } from "redux/actions/auth";
import { error } from "utils/notifications";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";
import { useIntegrations } from "../IntegrationsTab/Integrations.hooks";
import { Loader, StyledButton } from "components";
import {
  DeliveryTab,
  GeneralTab,
  HeaderBlock,
  InvoicesTab,
  TagsTab,
} from "./components";
import { ORDERS_TABS } from "Pages/SettingsPage/SettingsPage.constants";

const OrdersTab = ({ currentTab, setCurrentTab }) => {
  const isAdmin = useAdmin();

  const currentUser = useSelector(({ auth }) => auth.currentUser);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      deliveryFee: currentUser.deliveryFee || defaultValues.deliveryFee,
      deliveryFeePriceCap:
        currentUser.deliveryFeePriceCap || defaultValues.deliveryFeePriceCap,
      deliveryFeeAmount: currentUser.deliveryFeeAmount
        ? currentUser.deliveryFeeAmount?.toString()
        : defaultValues.deliveryFeeAmount,
      deliveryFeeType:
        currentUser.deliveryFeeType || defaultValues.deliveryFeeType,
      deliveryFeePriceCapAmount:
        currentUser?.deliveryFeePriceCapAmount?.toFixed(2) ||
        defaultValues?.deliveryFeePriceCapAmount?.toFixed(2),
      minimumOrderValue:
        currentUser.minimumOrderValue || defaultValues.minimumOrderValue,
      minimumOrderValueAmount:
        currentUser?.minimumOrderValueAmount?.toFixed(2) ||
        defaultValues?.minimumOrderValueAmount?.toFixed(2),
      signatureOnOrders:
        currentUser?.signatureOnOrders || defaultValues.signatureOnOrders,
      pastDueBalanceInInvoices:
        currentUser?.pastDueBalanceInInvoices ||
        defaultValues.pastDueBalanceInInvoices,
      useCondensedInvoices:
        currentUser?.useCondensedInvoices || defaultValues.useCondensedInvoices,
      useScannableBarcodes:
        currentUser?.useScannableBarcodes || defaultValues.useScannableBarcodes,
      automaticFulfillByDate:
        currentUser?.automaticFulfillByDate ||
        defaultValues.automaticFulfillByDate,
      automaticFulfillByDateAmount:
        currentUser?.automaticFulfillByDateAmount ||
        defaultValues.automaticFulfillByDateAmount,
    },
    resolver: yupResolver(validationSchema()),
  });

  const dispatch = useDispatch();

  const formField = useWatch({ control });
  const {
    deliveryFeeType,
    deliveryFee,
    deliveryFeePriceCap,
    minimumOrderValue,
    automaticFulfillByDate,
  } = formField || {};

  const formFieldDebounced = useDebounce(formField, 500);
  const repPermissions = useRepsPermissions();

  useEffect(() => {
    if (isAdmin) return;

    if (repPermissions) {
      if (!repPermissions?.settings?.orders) return;
    }

    const errorKeys = Object.keys(errors);
    if (errorKeys.length) return;

    const {
      deliveryFeeAmount,
      deliveryFeePriceCapAmount,
      minimumOrderValueAmount,
      automaticFulfillByDateAmount,
    } = formFieldDebounced;

    updateDistributorSettingsService({
      ...formFieldDebounced,
      automaticFulfillByDateAmount: +automaticFulfillByDateAmount,
      deliveryFeeAmount: deliveryFeeAmount
        ? removeSeparatorFromNum(deliveryFeeAmount)
        : 0,
      deliveryFeePriceCapAmount: deliveryFeePriceCapAmount
        ? removeSeparatorFromNum(deliveryFeePriceCapAmount)
        : 0,
      minimumOrderValueAmount: minimumOrderValueAmount
        ? removeSeparatorFromNum(minimumOrderValueAmount)
        : 0,
    })
      .then((res) => {
        dispatch(setCurrentUser(res));
      })
      .catch((err) => {
        error(err?.response?.data?.message || "Something went wrong.");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formFieldDebounced, dispatch, handleSubmit]);

  const { handleOpenDeliveryFeeWarning, loading } = useIntegrations();

  const handleCheckDeliveryFee = useCallback(
    (value) => {
      if (value) return setValue("deliveryFee", false);

      dispatch(
        openConfirmDialogAction({
          title: "Delivery & Handling",
          isIcon: true,
          text: "A set fee will be added for all new orders. \nThis will not affect all existing orders.",
          buttons: (
            <>
              <StyledButton
                sx={{
                  width: "98px",
                  color: "#6A6A6A",
                  borderColor: "#D4D4D4",
                  fontSize: "13px",
                  height: "28px",
                  ml: "auto",
                }}
                onClick={() => {
                  dispatch(setConfirmIsOpenAction(false));
                }}
                variant="outlined"
                label="Cancel"
              />
              <StyledButton
                sx={{
                  width: "98px",
                  color: "#FFFFFF",
                  fontSize: "13px",
                  height: "28px",
                  boxShadow: "none",
                }}
                onClick={() => {
                  dispatch(setConfirmIsOpenAction(false));
                  handleOpenDeliveryFeeWarning({
                    buttonLabel: "Turn on",
                    onProceed: () => {
                      setValue("deliveryFee", true);
                    },
                    manualDeliveryFee: false,
                    isCreatingOrder: false,
                  });
                }}
                variant="contained"
                label="Continue"
              />
            </>
          ),
        })
      );
    },
    [dispatch, handleOpenDeliveryFeeWarning, setValue]
  );

  const showCurrentTab = useMemo(() => {
    switch (currentTab) {
      case ORDERS_TABS[0].value:
        return <GeneralTab {...{ control, minimumOrderValue, isAdmin }} />;

      case ORDERS_TABS[1].value:
        return <InvoicesTab {...{ isAdmin, control }} />;

      case ORDERS_TABS[2].value:
        return (
          <DeliveryTab
            {...{
              isAdmin,
              control,
              handleCheckDeliveryFee,
              deliveryFee,
              deliveryFeeType,
              deliveryFeePriceCap,
              automaticFulfillByDate,
              setValue,
            }}
          />
        );

      case ORDERS_TABS[3].value:
        return <TagsTab />;

      default:
        return <></>;
    }
  }, [
    automaticFulfillByDate,
    control,
    currentTab,
    deliveryFee,
    deliveryFeePriceCap,
    deliveryFeeType,
    handleCheckDeliveryFee,
    isAdmin,
    minimumOrderValue,
    setValue,
  ]);

  return (
    <>
      <Loader isLoading={loading} />
      <HeaderBlock {...{ currentTab, setCurrentTab }} tabs={ORDERS_TABS} />
      {showCurrentTab}
    </>
  );
};

OrdersTab.propTypes = {
  currentTab: string,
  setCurrentTab: func,
};
OrdersTab.propTypes = {
  setCurrentTab: () => {},
};

export default OrdersTab;
