import { useCallback, useMemo, useState } from "react";
import { array, bool, func, string, object } from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { createSelector } from "reselect";

import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

import StyledButton from "../../../../components/StyledButton";

import { galleryShowingCountSelector } from "../../../../redux/selectors/gallery";

import { ArrowLeftIcon } from "../../../../components/Icons";
import { ExportDialog } from "../../../SettingsPage/components/SettingsTabs/ImportExportTab/components/ExportDialog";
import { SETTINGS_TABS } from "../../../SettingsPage/SettingsPage.constants";
import { CreateFolderDialog } from "../FilesTab/components";
import { Total } from "./components";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
  setFormChangedAction,
} from "../../../../redux/actions/confirmDialogs";
import { useExport } from "../../../SettingsPage/components/SettingsTabs/ImportExportTab/ImportExport.hooks";
import { useAdmin } from "helpers/helpers";
import {
  StyledTabs,
  NewTerritoryDialog,
  ActionRestrictionWrapper,
} from "components";

const useStyles = makeStyles(() => ({
  label: {
    "& .MuiFormLabel-root": {
      color: "#B5B5AC",
      fontSize: "12px",
      top: "2px",
    },
    "& .Mui-focused": {
      color: "#42A57F",
      fontSize: "1rem",
      top: "0px",
    },
    "& .MuiOutlinedInput-root": {
      height: "100%",
    },
  },
  tabs: {
    minHeight: "auto",
    height: "44px",
    "& .Mui-selected": {
      backgroundColor: "transparent!important",
    },
  },
  smallerButton: {
    height: "24px",
    "& > span": {
      fontSize: "11px",
    },
  },

  biggerButton: {
    "& > span": {
      fontSize: "14px",
    },
  },

  importStyles: {
    display: "flex",
    textAlign: "center",
    alignItems: "center",
  },
}));

const selector = createSelector(
  galleryShowingCountSelector,
  (showingCount) => ({ showingCount })
);

export const CustomersSwitcherTab = ({
  tabs,
  currentTab,
  handleSetCurrentTab,
  resetCheckedCustomers,
  repPermissions,
  downloadProgress,
  addNewTerritory,
}) => {
  const isAdmin = useAdmin();
  const classes = useStyles();
  const { pathname, state } = useLocation();
  const { showingCount } = useSelector(selector);
  const navigate = useNavigate();

  const disabledCreateEdit = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.customers?.create_edit;
    }
  }, [repPermissions]);

  const disabledCreateImport = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.customers?.import;
    }
  }, [repPermissions]);

  const disabledExportBtn = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.customers?.export;
    }
  }, [repPermissions]);

  const [isOpenCreateFolderDialog, setOpenCreateFolderDialog] = useState(false);

  const {
    handleSubmit,
    control,
    exportOpenData,
    updateExportState,
    formField,
    onSubmit,
  } = useExport();

  const handleCloseCreateFolderDialog = () => {
    setOpenCreateFolderDialog(false);
  };

  const handleChangeCurrentTab = (e, newValue) => {
    if (formChanged && editType === "checked_items")
      return handleConfirmCheckedItemsDialog(newValue);

    handleSetCurrentTab(newValue);
  };

  const handleExportDialog = () => {
    updateExportState({ type: "customers", open: true });
  };

  const dispatch = useDispatch();
  const editType = useSelector(({ confirmDialogs }) => confirmDialogs.editType);
  const formChanged = useSelector(
    ({ confirmDialogs }) => confirmDialogs.formChanged
  );

  const handleConfirmCheckedItemsDialog = useCallback(
    (newValue, nav, navState) => {
      dispatch(
        openConfirmDialogAction({
          path: nav,
          title: "Leaving this page?",
          text: "Your selected list will be discarded.",
          buttons: (
            <>
              <StyledButton
                variant="outlined"
                color="cancel"
                type="submit"
                form={`new-${editType}-form`}
                label="Cancel"
                sx={{ height: "28px" }}
                onClick={() => {
                  dispatch(setConfirmIsOpenAction(false));
                }}
              />
              <Button
                sx={{
                  width: "auto",
                  height: "28px",
                  color: "#FFFFFF",
                  fontSize: "13px",
                  boxShadow: "none",
                }}
                color="confirmDelete"
                onClick={() => {
                  dispatch(setConfirmIsOpenAction(false));
                  dispatch(setFormChangedAction(false));
                  resetCheckedCustomers();
                  if (newValue) handleSetCurrentTab(newValue);
                  if (nav) navigate(nav, navState);
                }}
                variant="contained"
              >
                Confirm
              </Button>
            </>
          ),
        })
      );
    },
    [dispatch, editType, handleSetCurrentTab, navigate, resetCheckedCustomers]
  );

  const [isOpenNewTerritoryModal, setOpenNewTerritoryModal] = useState(false);

  return (
    <>
      <NewTerritoryDialog
        open={isOpenNewTerritoryModal}
        onClose={() => setOpenNewTerritoryModal(false)}
        name="New territory"
        handleSave={(name) => {
          setOpenNewTerritoryModal(false);
          !!addNewTerritory && addNewTerritory(name);
        }}
      />

      <form onSubmit={handleSubmit(onSubmit)} id="customers-export-form">
        <ExportDialog
          open={exportOpenData.open}
          type={exportOpenData.type}
          onClose={() => updateExportState({ open: false })}
          control={control}
          format={formField.format}
          form="customers-export-form"
        />
      </form>
      <CreateFolderDialog
        open={isOpenCreateFolderDialog}
        onClose={handleCloseCreateFolderDialog}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          px: "32px",
          backgroundColor: "#F0F0F0",
          borderBottom: "0.5px solid #D4D4D4",
          height: "44px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 3,
          }}
        >
          <Box>
            {
              <Typography
                variant="h6"
                fontWeight="400"
                fontSize="20px"
                color="#9C9C94"
              >
                Customers
              </Typography>
            }
          </Box>
          <Box>
            {tabs && (
              <StyledTabs
                onChange={handleChangeCurrentTab}
                value={currentTab}
                tabs={tabs}
                className={classes.tabs}
                tabProps={{
                  sx: {
                    py: 0,
                    height: "48px",
                    fontSize: "12px!important",
                    fontWeight: 400,
                    color: "#9C9C94",
                  },
                }}
              />
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 1,
          }}
        >
          {currentTab === "Customers" && (
            <>
              <ActionRestrictionWrapper
                disableHoverListener={!disabledExportBtn}
                placement="bottom"
              >
                <StyledButton
                  disabled={disabledExportBtn}
                  sx={{ height: 28, color: "#707070" }}
                  color="groundLighter"
                  variant="outlined"
                  label="Export"
                  onClick={handleExportDialog}
                />
              </ActionRestrictionWrapper>
              <ActionRestrictionWrapper
                disableHoverListener={!disabledCreateImport}
                placement="bottom"
              >
                <StyledButton
                  disabled={disabledCreateImport}
                  sx={{ height: 28, color: "#707070" }}
                  color="groundLighter"
                  variant="outlined"
                  label="Import"
                  onClick={() => {
                    if (formChanged) {
                      if (editType === "checked_items")
                        return handleConfirmCheckedItemsDialog(
                          "",
                          "/settings",
                          {
                            state: {
                              tab: SETTINGS_TABS[6],
                              goBack: true,
                              type:
                                state?.type === "onboarding" && "onboarding",
                            },
                          }
                        );
                    }
                    navigate("/settings", {
                      state: {
                        tab: SETTINGS_TABS[6],
                        goBack: true,
                        type: state?.type === "onboarding" && "onboarding",
                      },
                    });
                  }}
                />
              </ActionRestrictionWrapper>

              <ActionRestrictionWrapper
                disableHoverListener={!disabledCreateEdit}
                placement="bottom"
              >
                <StyledButton
                  disabled={disabledCreateEdit}
                  sx={{ height: 28 }}
                  variant="contained"
                  label="Add Customer"
                  component={Link}
                  onClick={(e) => {
                    if (formChanged) {
                      e.preventDefault();
                      if (editType === "checked_items")
                        return handleConfirmCheckedItemsDialog(
                          "",
                          `${pathname}/new`
                        );
                    }
                  }}
                  to={`${pathname}/new`}
                  from={pathname}
                  state={state?.type === "onboarding" && { type: "onboarding" }}
                />
              </ActionRestrictionWrapper>
            </>
          )}
          {currentTab === "Customer Tags" && (
            <>
              <StyledButton
                disabled={isAdmin}
                sx={{ height: 28 }}
                variant="contained"
                component={Link}
                to={`${pathname}/tags/new`}
                label="Add Customer Tag"
              />
            </>
          )}
          {currentTab === "Territories" && (
            <>
              <StyledButton
                disabled={isAdmin}
                sx={{ height: 28 }}
                variant="contained"
                label="Add Territory"
                onClick={() => setOpenNewTerritoryModal(true)}
              />
            </>
          )}
          {currentTab === "Gallery" ? (
            <>
              {downloadProgress.download && (
                <>
                  <CircularProgress
                    variant="determinate"
                    size="20px"
                    value={downloadProgress.progress}
                  />
                  <Typography color="primary">
                    {`${downloadProgress.progress}% `}
                    {"Downloading photos.."}
                  </Typography>
                </>
              )}
              <Total count={showingCount} />
            </>
          ) : null}
          {/* showing btns on the files tab */}
          {currentTab === "Files" && pathname.split("/").length === 2 && (
            <>
              <StyledButton
                disabled={isAdmin}
                sx={{ height: 28 }}
                variant="outlined"
                label="Create Folder"
                onClick={() => setOpenCreateFolderDialog(true)}
              />
              <StyledButton
                disabled={isAdmin}
                sx={{ height: 28 }}
                variant="contained"
                label="Upload Files"
              />
            </>
          )}

          {/* showing btn on the folder page */}
          {currentTab === "Files" && pathname.split("/")?.[2] === "folder" && (
            <>
              <StyledButton
                color="greySecondary"
                label="Back"
                sx={{
                  p: 0,
                  "& > span": {
                    fontSize: "14px",
                  },
                }}
                onClick={() => {
                  navigate(-1);
                }}
                startIcon={<ArrowLeftIcon />}
              />
              <StyledButton
                disabled={isAdmin}
                sx={{ height: 28 }}
                variant="contained"
                label="Upload Files"
              />
            </>
          )}

          {/* showing btn on the manage page */}
          {currentTab === "Files" && pathname.split("/")?.[2] === "manage" && (
            <StyledButton
              color="greySecondary"
              label="Back"
              sx={{
                p: 0,
                "& > span": {
                  fontSize: "14px",
                },
              }}
              onClick={() => {
                navigate(-1);
              }}
              startIcon={<ArrowLeftIcon />}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

CustomersSwitcherTab.propTypes = {
  tabs: array,
  handleSetCurrentTab: func,
  // handleSaveTerritory: func,
  currentTab: string,
  isSaveBtnDisable: bool,
  resetCheckedCustomers: func,
  repPermissions: object,
  downloadProgress: object,
  addNewTerritory: func,
};
CustomersSwitcherTab.defaultProps = {
  tabs: [],
  addNewTerritory: () => {},
};

export default CustomersSwitcherTab;
