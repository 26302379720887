import { string, func, array, bool } from "prop-types";
import { Box, Button, Chip, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { FilterSearchTextField } from "../../../../components/TextFields/TextFields";
import { normalizeSnakeCaseString } from "../../../../helpers/helpers";
import lodash from "lodash";
import {
  getProductsParamsAction,
  selectedChildrensOfProductsAction,
  selectedProductsAction,
  setShowInactiveProducts,
  catalogGetSwitchFieldActions,
  catalogGetFiltersAction,
  setSearchProductsAction,
  resetGetActionsFilter,
} from "../../../../redux/actions/products";
import {
  manufacturersCountSelector,
  manufacturersListSelector,
} from "../../../../redux/selectors/manufacturers";
import {
  getParentProductSelector,
  productsListSelector,
  productsShowInactiveSelector,
  selectedProductsSelector,
  catalogGetSwitchSelector,
  catalogGetFilterSelector,
  searchProductsSelector,
  productsLoadingSelector,
  productsExistSelector,
} from "../../../../redux/selectors/products";
import CloseIcon from "@mui/icons-material/Close";
import useStyles from "./styles";
import StyledButton from "../../../../components/StyledButton";
import { FilterIcon } from "../../../../components/Icons";
import FilterMenu from "../../../../components/FilterMenu";
import { useDebounce } from "../../../../helpers/hooks";
import {
  CUSTOM_FILTERS_TYPES,
  NAME_VIEW_DIALOGS,
  UNCATEGORIZED_CATEGORY,
} from "../../../../utils/constants";
import { useCallback } from "react";
import { getManufacturersAction } from "../../../../redux/actions/manufacturers";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
  setEditItemAction,
  setFormChangedAction,
} from "../../../../redux/actions/confirmDialogs";
import ActiveTabs from "../../../../components/ActiveTabs";
import { currentUserSelector } from "redux/selectors/auth";
import { useTagsActions } from "helpers/useTagsActions";
import { useCustomFilterCreate } from "helpers/useCustomFilterCreate";
import { CustomFilterMenu, FilterViewSaveDialog } from "components";
import {
  checkForDefaultFilter,
  handleSetSwitchesForEdit,
} from "helpers/filters";
import { initialProductsState } from "redux/reducers/products";
import { setSelectedFilterIdAction } from "redux/actions/savedFilters";
import { usePriceLists } from "helpers/usePriceLists";

const selector = createSelector(
  manufacturersListSelector,
  manufacturersCountSelector,
  productsShowInactiveSelector,
  getParentProductSelector,
  productsListSelector,
  selectedProductsSelector,
  catalogGetSwitchSelector,
  catalogGetFilterSelector,
  currentUserSelector,
  searchProductsSelector,
  productsLoadingSelector,
  productsExistSelector,
  (
    manufacturersList,
    manufacturersCount,
    showInactive,
    parent,
    productsList,
    selectedProducts,
    catalogGetSwitch,
    catalogGetFilter,
    currentUser,
    searchProducts,
    productsLoading,
    productsExist
  ) => ({
    manufacturersList,
    manufacturersCount,
    showInactive,
    parent,
    productsList,
    selectedProducts,
    catalogGetSwitch,
    catalogGetFilter,
    currentUser,
    searchProducts,
    productsLoading,
    productsExist,
  })
);

const CatalogFilter = ({
  currentTab,
  fetchCountryOfOrigin = () => {},
  countryOfOriginList = [],
  setCountryOfOriginSearch = () => {},
  loadingCountryOfOrigin = false,
}) => {
  const classes = useStyles();

  const {
    manufacturersList,
    manufacturersCount,
    showInactive,
    catalogGetSwitch,
    catalogGetFilter,
    currentUser,
    searchProducts: searchInput,
    productsLoading,
    productsExist,
  } = useSelector(selector);

  const searchInputDebounced = useDebounce(searchInput, 500);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [isFilterChanged, setIsFilterChanged] = useState(false);

  const filterAnchor = useRef();

  const [filterFields, setFilterFields] = useState(catalogGetFilter);

  const haveProductsMissingInfo =
    (currentUser?.countProductsWithMissingInfo ?? 0) > 0;

  const filterProductsWithMissingInfo = (el) => {
    return haveProductsMissingInfo ? true : el?.value !== "missing_info";
  };

  const [switches, setSwitches] = useState(
    catalogGetSwitch?.filter(filterProductsWithMissingInfo)?.map((cat) => {
      if (filterFields?.[cat.value]?.value) return { ...cat, checked: true };
      return cat;
    })
  );

  const dispatch = useDispatch();
  const formChanged = useSelector(
    ({ confirmDialogs }) => confirmDialogs.formChanged
  );
  const editType = useSelector(({ confirmDialogs }) => confirmDialogs.editType);

  useEffect(() => {
    const dataWithoutMissingInfo = switches.filter(
      (item) => item?.value !== "missing_info"
    );

    dispatch(
      catalogGetSwitchFieldActions(
        haveProductsMissingInfo ? switches : dataWithoutMissingInfo
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, switches]);

  useEffect(() => {
    const { missing_info, ...dataWithoutMissingInfo } = filterFields || {};
    dispatch(
      catalogGetFiltersAction(
        haveProductsMissingInfo ? filterFields : dataWithoutMissingInfo
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filterFields]);

  const handleConfirmCheckedItemsDialog = useCallback(
    (newVal) => {
      dispatch(
        openConfirmDialogAction({
          title: "Leaving this page?",
          text: "Your selected list will be discarded.",
          buttons: (
            <>
              <StyledButton
                variant="outlined"
                color="cancel"
                type="submit"
                form={`new-${editType}-form`}
                label="Cancel"
                sx={{ height: "28px" }}
                onClick={() => {
                  dispatch(setConfirmIsOpenAction(false));
                }}
              />
              <Button
                sx={{
                  width: "auto",
                  height: "28px",
                  color: "#FFFFFF",
                  fontSize: "13px",
                  boxShadow: "none",
                }}
                color="confirmDelete"
                onClick={() => {
                  dispatch(selectedProductsAction([]));
                  dispatch(selectedChildrensOfProductsAction([]));

                  dispatch(setFormChangedAction(false));
                  dispatch(setEditItemAction(null));

                  dispatch(setConfirmIsOpenAction(false));
                  dispatch(setShowInactiveProducts(newVal));
                }}
                variant="contained"
              >
                Confirm
              </Button>
            </>
          ),
        })
      );
    },
    [dispatch, editType]
  );

  const handleShowInactive = (e, newVal) => {
    dispatch(selectedProductsAction([]));
    dispatch(selectedChildrensOfProductsAction([]));
    if (formChanged && editType === "checked_items") {
      return handleConfirmCheckedItemsDialog(newVal);
    }
    dispatch(setShowInactiveProducts(newVal));
  };

  const filterChipKeys = useMemo(
    () =>
      Object.keys(filterFields).filter((key) => {
        if (
          lodash.isArray(filterFields[key]) &&
          lodash.isEmpty(filterFields[key])
        )
          return;
        if (key === "price") {
          return (
            !!filterFields[key].wholesale_price_equals?.value ||
            !!filterFields[key].wholesale_price_greater?.value ||
            !!filterFields[key].wholesale_price_lesser?.value
          );
        }
        return filterFields[key];
      }),
    [filterFields]
  );

  const handleDeleteFilter = (key) => {
    const newState = switches;
    const index = switches.findIndex((s) => s.value === key);
    const insert = {
      ...switches[index],
      checked: false,
    };

    if (index >= 0) newState.splice(index, 1, insert);

    dispatch(setSelectedFilterIdAction(null));
    setSwitches([...newState]);
    setFilterFields((prev) => {
      return {
        ...prev,
        [key]: lodash.isArray(catalogGetFilter[key]) ? [] : "",
      };
    });
  };

  const [manufacturersState, setManufacturersState] = useState({
    search: "",
    searcheble: false,
  });

  const handleFetchManufacturers = useCallback(() => {
    const fetchQuery = {
      limit: manufacturersList[manufacturersList.length - 1]?.id,
      search: manufacturersState.search,
    };
    dispatch(getManufacturersAction(fetchQuery));
    setManufacturersState((prev) => ({
      ...prev,
      searcheble: !prev ? manufacturersCount >= 10 : prev,
    }));
  }, [
    dispatch,
    manufacturersList,
    manufacturersState.search,
    manufacturersCount,
    setManufacturersState,
  ]);

  useEffect(() => {
    handleFetchManufacturers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manufacturersState.search]);

  const [searchCountry, setSearchCountry] = useState({ search: "" });

  useEffect(() => {
    setCountryOfOriginSearch(searchCountry.search);
  }, [searchCountry.search, setCountryOfOriginSearch]);

  useEffect(() => {
    fetchCountryOfOrigin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    list: tagsList,
    count: tagsCount,
    handleFetchTags,
    page: tagsPage,
    setTagsParams,
    searcheble,
  } = useTagsActions({
    type: "product",
    params: {
      used: true,
      sort_by_tag: "asc",
    },
  });

  const {
    priceList,
    onFetchPriceList,
    page: priceListPage,
    priceCount,
  } = usePriceLists({ open: true });

  const selectMatchData = useMemo(() => {
    const preparedCountryOfOriginList = [
      {
        id: "No country of origin",
        name: "No country of origin",
      },
      ...countryOfOriginList.map((country) => ({
        id: country,
        name: country,
      })),
    ];

    const list = [
      {
        id: "manufacturer_id",
        childrenList: manufacturersList,
        handleFetch: handleFetchManufacturers,
        setParams: setManufacturersState,
        dataCount: manufacturersCount,
        dataLength: manufacturersList.length,
        label: "",
        searcheble: manufacturersState.searcheble,
      },
      {
        id: "tag_id",
        childrenList: tagsList.map(({ tag, ...item }) => ({
          ...item,
          name: tag,
        })),
        handleFetch: () => handleFetchTags(tagsPage + 1),
        setParams: setTagsParams,
        dataCount: tagsCount,
        dataLength: tagsList?.length,
        label: "",
        searcheble,
      },
      {
        id: "countries_of_origin",
        childrenList: preparedCountryOfOriginList,
        handleFetch: () => fetchCountryOfOrigin(),
        label: "",
        searcheble: countryOfOriginList?.length >= 10,
        setParams: setSearchCountry,
      },
      {
        id: "price_list_id",
        childrenList: priceList,
        label: "",
        searcheble: false,
        handleFetch: () => onFetchPriceList(priceListPage + 1),
        textFieldProps: {
          emptyLabel: "Standard",
        },
        dataCount: priceCount,
        dataLength: priceList?.length,
      },
    ];

    return list;
  }, [
    countryOfOriginList,
    manufacturersList,
    handleFetchManufacturers,
    manufacturersCount,
    manufacturersState.searcheble,
    tagsList,
    setTagsParams,
    tagsCount,
    searcheble,
    priceList,
    priceCount,
    handleFetchTags,
    tagsPage,
    fetchCountryOfOrigin,
    onFetchPriceList,
    priceListPage,
  ]);

  const handleApplyFilter = (newSwitches, newFields) => {
    setFilterMenuOpen(false);
    setSwitches([...newSwitches]);
    setFilterFields({ ...newFields });
  };

  useEffect(() => {
    if (
      !Array.isArray(countryOfOriginList) ||
      !Object.keys(filterFields).length
    )
      return;

    // this show or hide country of origin filter SD-7795
    if (countryOfOriginList?.length >= 1) {
      setSwitches((prev) => [
        ...[...prev].filter((s) => s.value !== "countries_of_origin"),
        {
          value: "countries_of_origin",
          label: "Country of origin",
          type: "select",
          checked: false,
          selectLabel: "Select Country of origin",
        },
      ]);

      setFilterFields((prev) => ({ ...prev, countries_of_origin: "" }));
    } else {
      setSwitches((prev) => [
        ...[...prev].filter((s) => s.value !== "countries_of_origin"),
      ]);

      const newFilter = { ...filterFields };

      delete newFilter.countries_of_origin;

      setFilterFields({ ...newFilter });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryOfOriginList?.length]);

  useEffect(() => {
    if (!isFilterChanged) return;
    const priceKeys = Object.keys(filterFields.price);
    const priceFiltersArray = priceKeys.map((key) => ({
      [key]: filterFields.price[key]?.value,
    }));
    const priceFilters = {};
    priceFiltersArray.forEach((obj) => Object.assign(priceFilters, obj));

    const {
      wholesale_price_greater,
      wholesale_price_lesser,
      wholesale_price_equals,
    } = priceFilters;

    const setCountryOfOriginParam = () => {
      if (filterFields?.countries_of_origin?.value === "No country of origin") {
        return JSON.stringify([null]);
      }

      return filterFields?.countries_of_origin?.value
        ? JSON.stringify([filterFields?.countries_of_origin?.value])
        : undefined;
    };

    dispatch(
      getProductsParamsAction({
        wholesale_price_greater: wholesale_price_greater || "",
        wholesale_price_lesser: wholesale_price_lesser || "",
        wholesale_price_equals: wholesale_price_equals || "",
        manufacturer_id: filterFields?.manufacturer_id?.value,
        category_id:
          filterFields?.category_id?.value === UNCATEGORIZED_CATEGORY.id
            ? "null"
            : filterFields?.category_id?.value,
        search: searchInputDebounced,
        stock: filterFields?.stock_availability?.value
          ? JSON.stringify([filterFields?.stock_availability?.value])
          : undefined,
        with_missing_info: filterFields?.missing_info?.value,
        tag_ids: filterFields?.tag_id?.length
          ? JSON.stringify(filterFields?.tag_id?.map(({ value }) => value))
          : [],
        countries_of_origin: setCountryOfOriginParam(),
        price_list_id: filterFields?.price_list_id?.value,
      })
    );
  }, [
    filterFields,
    dispatch,
    searchInputDebounced,
    showInactive,
    isFilterChanged,
  ]);

  const getChipLabel = (key) => {
    const fieldKeys = Object.keys(filterFields[key]);
    const isCombined = key === "price";
    const isArray = lodash.isArray(filterFields[key]);
    return (
      <Box display="flex" alignItems="center" gap="6px">
        <Typography
          sx={{ fontSize: "13px", fontWeight: 500 }}
          color="groundLighter.main"
        >
          {isArray ? (
            <>
              {normalizeSnakeCaseString(filterFields[key][0]?.label || key)}:{" "}
              <span style={{ color: "#5F6368" }}>
                {filterFields[key]
                  .map(({ name }) => normalizeSnakeCaseString(name))
                  .join(", ")}
              </span>
            </>
          ) : (
            <>
              {normalizeSnakeCaseString(filterFields[key]?.label || key)}:{" "}
              <span style={{ color: "#5F6368" }}>
                {isCombined
                  ? fieldKeys
                      .filter((fieldKey) => filterFields[key][fieldKey]?.value)
                      .map((fieldKey) => {
                        const { prefix, value } = filterFields[key][fieldKey];
                        return `${prefix} ${value}`;
                      })
                      .join(" and ")
                  : normalizeSnakeCaseString(
                      filterFields[key]?.name || filterFields[key]
                    )}
              </span>
            </>
          )}
        </Typography>
      </Box>
    );
  };

  const onCustomFilterApply = useCallback(
    (filters) => {
      setFilterFields(filters);
      setSwitches(handleSetSwitchesForEdit(filters, catalogGetSwitch));
    },
    [catalogGetSwitch]
  );

  const {
    onCloseFilterDialog,
    onFilterViewSave,
    onOpenFilterSaveDialog,
    isOpenedMenu,
  } = useCustomFilterCreate({
    createFilterCallback: onCustomFilterApply,
    menuCallback: () => setFilterMenuOpen(false),
    viewTypeKey: NAME_VIEW_DIALOGS.CATALOG,
    filterType: CUSTOM_FILTERS_TYPES.CATALOG[currentTab],
  });

  useEffect(() => {
    if (isFilterChanged || loadingCountryOfOrigin) return;

    checkForDefaultFilter({
      type: CUSTOM_FILTERS_TYPES.CATALOG[currentTab],
      list: currentUser.customFilters,
      setFilters: setFilterFields,
      setSwitches,
      switchesList: switches,
      dispatchFunc: (id) => dispatch(setSelectedFilterIdAction(id)),
    });

    setIsFilterChanged(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.customFilters, loadingCountryOfOrigin]);

  return (
    <>
      <Box className={classes.filtersWrapper}>
        <ActiveTabs value={showInactive} onChange={handleShowInactive} />
        {isOpenedMenu && (
          <FilterViewSaveDialog
            open={isOpenedMenu}
            onClose={onCloseFilterDialog}
            handleSave={onFilterViewSave}
          />
        )}
        <FilterSearchTextField
          adminIsAllowed
          formSx={{ minWidth: "380px" }}
          placeholderWidth="360px"
          placeholder="Search products by name, manufacturer or SKU"
          value={searchInput}
          loading={productsExist && productsLoading}
          onChange={(e) => dispatch(setSearchProductsAction(e.target.value))}
          fullWidth
          adornmentProps={{
            sx: {
              justifyContent: "flex-end",
            },
          }}
          customAdornment={
            <Box
              display="flex"
              gap="5px"
              overflow="auto"
              sx={{
                py: "4px",
                "&::-webkit-scrollbar": {
                  height: "2px",
                },
              }}
            >
              {filterChipKeys.map((key) => (
                <Chip
                  key={key}
                  className={classes.filterChip}
                  size="small"
                  label={getChipLabel(key)}
                  deleteIcon={
                    <CloseIcon size="15" style={{ fill: "#707070" }} />
                  }
                  onMouseDown={(e) => e.stopPropagation()}
                  onDelete={() => handleDeleteFilter(key)}
                />
              ))}
            </Box>
          }
          handleClearValue={() => dispatch(setSearchProductsAction(""))}
        />
        <CustomFilterMenu
          {...{
            selectMatchData,
            type: CUSTOM_FILTERS_TYPES.CATALOG[currentTab],
            switches: catalogGetSwitch,
            onFilterApply: onCustomFilterApply,
            resetFilterFunc: () => {
              dispatch(resetGetActionsFilter());
              if (countryOfOriginList?.length >= 1) {
                setSwitches(() => [
                  ...[...initialProductsState.catalogSwitch].filter(
                    (s) => s.value !== "countries_of_origin"
                  ),
                  {
                    value: "countries_of_origin",
                    label: "Country of origin",
                    type: "select",
                    checked: false,
                    selectLabel: "Select Country of origin",
                  },
                ]);

                setFilterFields(() => ({
                  ...initialProductsState.catalogFilter,
                  countries_of_origin: "",
                }));
              } else {
                setSwitches(initialProductsState.catalogSwitch);
                setFilterFields({ ...initialProductsState.catalogFilter });
              }
            },
          }}
        />
        <StyledButton
          label="Filter"
          startIcon={
            filterChipKeys.length ? (
              <Typography
                fontSize="9px!important"
                color="#ffffff"
                backgroundColor="#47A06D"
                borderRadius="50%"
                width="15px"
                height="15px"
              >
                {filterChipKeys.length}
              </Typography>
            ) : (
              <FilterIcon />
            )
          }
          variant="outlined"
          ref={filterAnchor}
          color="edit"
          sx={{
            height: "39px",
            width: "100%",
            maxWidth: "74px",
            border: "0.5px solid #D5D9D9",
            "& .MuiButton-startIcon": {
              ml: 0,
            },
          }}
          fontSize="15px"
          onClick={() => setFilterMenuOpen(true)}
        />
        <FilterMenu
          anchorEl={filterAnchor.current}
          open={filterMenuOpen}
          price={true}
          onClose={() => setFilterMenuOpen(false)}
          filterFields={filterFields}
          switches={switches}
          handleApplyFilter={handleApplyFilter}
          selectMatchData={selectMatchData}
          onViewSave={onOpenFilterSaveDialog}
        />
      </Box>
    </>
  );
};

export default CatalogFilter;

CatalogFilter.propTypes = {
  currentTab: string,
  fetchCountryOfOrigin: func,
  countryOfOriginList: array,
  setCountryOfOriginSearch: func,
  loadingCountryOfOrigin: bool,
};
