export const cl = {
  paperWrapper: {
    maxWidth: "994px",
    overflow: "hidden",
  },
  dialogContentWrapper: {
    width: "994px",
    maxHeight: "551px",
    p: "24px 32px",
  },
  actionsWrapper: {
    p: "0 32px 42px",
  },
  actionBtn: {
    height: "28px",
    width: "58px",
    minWidth: "58px",
  },
  tableWrapper: {
    maxHeight: "480px",
    height: "100%",
    overflow: "hidden",
    border: "0.5px solid #D5D9D9",
    borderRadius: "4px 4px",
  },
  iconBtn: {
    mr: "-8px",
  },
  filtersWrapper: {
    backgroundColor: "#fff",
    height: "64px",
    borderRadius: "4px 4px 0 0",
    padding: "12px",
    display: "flex",
    gap: "12px",
  },
};
