import { string, func, bool } from "prop-types";
import { Box } from "@mui/material";
import { FilterSearchTextField } from "components";
import { cl } from "../../styles";

export const CategoryFilterBlock = ({
  searchInput = "",
  setListSearch,
  loading,
}) => {
  return (
    <Box sx={cl.filtersWrapper}>
      <FilterSearchTextField
        adminIsAllowed
        loading={loading}
        placeholder="Search product categories by name"
        value={searchInput}
        onChange={(e) => setListSearch(e.target.value)}
        fullWidth
        adornmentProps={{
          sx: {
            justifyContent: "flex-end",
          },
        }}
        handleClearValue={() => setListSearch("")}
      />
    </Box>
  );
};

CategoryFilterBlock.propTypes = {
  searchInput: string,
  setListSearch: func,
  loading: bool,
};
