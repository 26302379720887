import { Radio, RadioGroup, styled, Typography } from "@mui/material";

const ContentWrap = styled("div")(({ theme: { spacing } }) => ({
  marginTop: spacing(1.75),
}));

const ContentTitle = styled(Typography)(({ theme: { palette } }) => ({
  color: palette.cancel.main,
}));

const StyledRadio = styled(Radio)(() => ({
  "& svg": {
    height: "15px",
    width: "15px",
  },
}));

const StyledRadioGroup = styled(RadioGroup)(({ theme: { spacing } }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  gap: spacing(1.25),
}));

export { ContentWrap, ContentTitle, StyledRadio, StyledRadioGroup };
