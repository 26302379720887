import * as React from "react";
export const RepsIcon = (props) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.2 4.6c0 1.988-1.433 3.6-3.2 3.6S5.8 6.588 5.8 4.6 7.233 1 9 1s3.2 1.612 3.2 3.6ZM1.4 14.067A6.67 6.67 0 0 1 6.733 11.4h4.534c2.098 0 4.074.988 5.333 2.667l.04.053A1.8 1.8 0 0 1 15.2 17H2.8a1.8 1.8 0 0 1-1.44-2.88z"
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
  </svg>
);
