import { useContext, useState } from "react";
import { Box, Grid } from "@mui/material";
import {
  AddressesBlock,
  ContactsBlock,
  CustomerDetailsBlock,
  DiscountsBlock,
  LicensesBlock,
  PaymentTermsBlock,
  ReceivingHoursBlock,
  TagsBlock,
  WrapperBlock,
  WrapperWithBtnBlock,
} from "./components";
import { useLicenses } from "./components/LicensesBlock/useLicenses";
import LicensesDrawer from "components/LicensesDrawer/LicensesDrawer";
import { CustomerContext } from "../../CustomerPage";
import { useReceivingHours } from "./components/ReceivingHoursBlock/useReceivingHours";
import ReceivingHoursDrawer from "components/ReceivingHoursDrawer/ReceivingHoursDrawer";
import ContactAddDrawer from "components/ContactsSection/ContactPopup/ContactAddDrawer";
import { useContacts } from "./components/ContactsBlock/useContacts";
import RepsAssignedDrawer from "components/RepsAssignedDrawer/RepsAssignedDrawer";
import { AssignedRepsBlock } from "./components/AssignedRepsBlock/AssignedRepsBlock";
import { useAssignedRepsBlock } from "./components/AssignedRepsBlock/useAssignedRepsBlock";
import { RatingBlock } from "./components/RatingBlock";
import { handleCustomerCategories } from "../../CustomerPage.helpers";
import ContactDeletePopup from "components/ContactsSection/ContactPopup/ContactDeletePopup";
import { SelectedInfoBlock } from "components";
import { useAdmin } from "helpers/helpers";

export const CustomerMainBody = () => {
  const isAdmin = useAdmin();
  const {
    setValue,
    formField,
    setIsOpenCategoriesPopup,
    onOpenAllProducts,
    openRepEditDialog,
    getAllCategoriesForPopup,
  } = useContext(CustomerContext);

  const {
    licenses,
    weekDays,
    representatives,
    contacts: formFieldContacts,
  } = formField || {};

  const {
    openLicenses,
    handleOpenLicenses,
    handleCloseLicenses,
    handleSetLicenseData,
    editLicense,
    setEditLicense,
    handleDeleteLicense,
    dialogState,
    setDialogState,
    handleDeleteLicenseFile,
  } = useLicenses({ licenses, setValue });

  const {
    assignedRepsOpen,
    handleCloseAssignedReps,
    handleOpenAssignedReps,
    handleSetAssignedRepsData,
    assignedRepresentatives,
    handleSaveAssignReps,
    editAssignedReps,
    handleRemoveReps,
  } = useAssignedRepsBlock({ representatives, setValue });

  const {
    openReceivingHours,
    handleOpenReceivingHours,
    handleCloseReceivingHours,
    handleSetHours,
    removeAllHours,
    hasAnySetHours,
    setOpenReceivingHoursFunc,
  } = useReceivingHours({ setValue, weekDays });

  const {
    openContacts,
    handleOpenContacts,
    handleCloseContacts,
    contacts,
    handleAddContact,
    handleRemoveContact,
    editContact,
    setEditContact,
  } = useContacts({ initialContacts: formFieldContacts, setValue });

  const {
    rating,
    reviews,
    price,
    categories,
    selectedProductsCount,
    showAllProducts,
    showAllCategories,
    selectedCategoriesCount,
  } = formField;

  const customerCategories = formField.id
    ? handleCustomerCategories(categories)
    : categories;

  const [deleteContact, setDeleteContact] = useState(null);
  const [deleteContactOpen, setDeleteContactOpen] = useState(false);

  return (
    <Box
      border="0.5px solid #D5D9D9"
      borderRadius="4px"
      width="1098px"
      p="20px 20px 20px"
      backgroundColor="#FFF"
    >
      {!!openContacts && (
        <ContactAddDrawer
          isOpen={openContacts}
          handleClose={handleCloseContacts}
          editContact={editContact}
          handleAddContact={handleAddContact}
          contacts={contacts}
          handleDeleteContact={(contact) => {
            setDeleteContact(contact);
            setDeleteContactOpen(true);
          }}
        />
      )}

      {!!assignedRepsOpen && (
        <RepsAssignedDrawer
          isOpen={assignedRepsOpen}
          editAssignedReps={editAssignedReps}
          handleClose={handleCloseAssignedReps}
          handleSave={handleSaveAssignReps}
          handleSetData={handleSetAssignedRepsData}
          assignedRepresentatives={assignedRepresentatives}
          submitBtnLabel="Update"
        />
      )}

      {!!openLicenses && (
        <LicensesDrawer
          isOpen={openLicenses}
          editLicense={editLicense}
          handleClose={handleCloseLicenses}
          handleSetData={handleSetLicenseData}
          {...{ dialogState, setDialogState, handleDeleteLicenseFile }}
          handleDeleteLicense={(license) => {
            handleDeleteLicense({ licenses, license });
          }}
        />
      )}

      {!!deleteContactOpen && (
        <ContactDeletePopup
          contact={deleteContact}
          isOpen={deleteContactOpen}
          handleClose={() => setDeleteContactOpen(false)}
          handleDeleteContact={handleRemoveContact}
        />
      )}

      {!!openReceivingHours && (
        <ReceivingHoursDrawer
          isOpen={openReceivingHours}
          handleClose={handleCloseReceivingHours}
          handleSetData={handleSetHours}
          weekDays={weekDays}
        />
      )}

      <Grid container>
        {/* left block */}
        <Grid alignContent="flex-start" gap={2} xs={6} item container>
          <WrapperBlock
            title="Customer details"
            element={
              <RatingBlock
                widthCheck={!price ? 26 : 20}
                categories={customerCategories}
                {...{ reviews, price, rating }}
              />
            }
          >
            <CustomerDetailsBlock />
          </WrapperBlock>

          <WrapperBlock title="Shipping address">
            <AddressesBlock />
          </WrapperBlock>

          <WrapperBlock title="Payments">
            <PaymentTermsBlock />
          </WrapperBlock>

          <WrapperBlock title="Discounts">
            <DiscountsBlock />
          </WrapperBlock>
        </Grid>

        {/* right block */}
        <Grid alignContent="flex-start" gap={2} xs={6} item container>
          <WrapperWithBtnBlock
            title="Representatives"
            labelBtn="Assign"
            onAdd={handleOpenAssignedReps}
          >
            {assignedRepresentatives?.length ? (
              <Box my={1.75}>
                <AssignedRepsBlock
                  assignedReps={assignedRepresentatives}
                  onEdit={(item) => {
                    openRepEditDialog(item);
                  }}
                  onRemove={handleRemoveReps}
                />
              </Box>
            ) : null}
          </WrapperWithBtnBlock>

          <WrapperWithBtnBlock title="Contacts" onAdd={handleOpenContacts}>
            {contacts?.length ? (
              <Box my={1.75}>
                <ContactsBlock
                  contacts={contacts}
                  onEdit={(contact) => {
                    setEditContact(contact);
                    handleOpenContacts();
                  }}
                  onRemove={(contact) => {
                    setDeleteContact(contact);
                    setDeleteContactOpen(true);
                  }}
                />
              </Box>
            ) : null}
          </WrapperWithBtnBlock>

          <WrapperWithBtnBlock
            title="Licenses & Certifications"
            onAdd={handleOpenLicenses}
          >
            {licenses?.length > 0 ? (
              <Box my={1.75}>
                <LicensesBlock
                  licenses={licenses}
                  setValue={setValue}
                  onEdit={(license) => {
                    setEditLicense(license);
                    handleOpenLicenses();
                  }}
                  onDelete={handleDeleteLicense}
                  handleDeleteLicenseFile={handleDeleteLicenseFile}
                />
              </Box>
            ) : null}
          </WrapperWithBtnBlock>

          <WrapperWithBtnBlock
            title="Working Hours"
            onAdd={setOpenReceivingHoursFunc}
          >
            {hasAnySetHours && (
              <ReceivingHoursBlock
                onEdit={handleOpenReceivingHours}
                onRemove={removeAllHours}
              />
            )}
          </WrapperWithBtnBlock>

          <WrapperWithBtnBlock title="Categories Privacy">
            <SelectedInfoBlock
              emptyLabel="All categories"
              count={showAllCategories ? null : selectedCategoriesCount}
              showZero={!showAllCategories}
              {...{
                isAdmin,
                onEdit: () => {
                  if (formField.id) return getAllCategoriesForPopup();
                  return setIsOpenCategoriesPopup(true);
                },
              }}
            />
          </WrapperWithBtnBlock>

          <WrapperWithBtnBlock title="Product Privacy">
            <SelectedInfoBlock
              type="product"
              emptyLabel="All products"
              count={showAllProducts ? null : selectedProductsCount}
              showZero={!showAllProducts}
              tooltipText="Select category to enable Product Privacy setting"
              disabled={!showAllCategories && selectedCategoriesCount === 0}
              {...{ isAdmin, onEdit: () => onOpenAllProducts() }}
            />
          </WrapperWithBtnBlock>

          <WrapperWithBtnBlock title="Tags">
            <TagsBlock />
          </WrapperWithBtnBlock>
        </Grid>
      </Grid>
    </Box>
  );
};
