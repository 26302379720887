import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { bool, func, string } from "prop-types";
import { useLocation } from "react-router-dom";
import { isArray, isEmpty, snakeCase } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Button, Chip, Tab, Tabs, Typography, Box, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  FilterMenu,
  StyledButton,
  FilterSearchTextField,
  CustomFilterMenu,
  FilterViewSaveDialog,
  ProductsPurchased,
} from "components";
import { FilterIcon } from "components/Icons";
import { normalizeSnakeCaseString } from "helpers/helpers";
import {
  FILTER_DATE_VALUES,
  FILTER_STATUSES,
  PAYMENT_FILTER_DATE_VALUES,
} from "../../Orders.constants";
import useStyles from "./styles";
import {
  ordersGetParamsAction,
  ordersGetSwitchFieldActions,
  ordersGetFiltersAction,
  getQuickFilterActions,
  resetOrdersActionsFilter,
} from "redux/actions/orders";
import { useDebounce } from "helpers/hooks";
import {
  ordersGetFilterSelector,
  ordersGetSwitchSelector,
  ordersGetQuickFilterSelector,
  hasOrderDirectSelector,
  hasQbOrdersSelector,
} from "redux/selectors/orders";
import {
  CUSTOM_FILTERS_TYPES,
  NAME_VIEW_DIALOGS,
  USED_ON_TYPE_ORDER_TYPES,
} from "utils/constants";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
  setEditItemAction,
  setFormChangedAction,
} from "redux/actions/confirmDialogs";
import { ColumnsSettingComponent } from "Pages/CustomersPage/components/CustomersTab/components/TabsContent/components/ColumnsSettingComponent/ColumnsSettingComponent";
import { currentUserSelector } from "redux/selectors/auth";
import { useTagsActions } from "helpers/useTagsActions";
import { ROLES, TYPES, useRepsActions } from "helpers/useRepsActions";
import { setSelectedFilterIdAction } from "redux/actions/savedFilters";
import {
  checkForDefaultFilter,
  handleSetSwitchesForEdit,
} from "helpers/filters";
import { initialOrdersState } from "redux/reducers/orders";
import { useCustomFilterCreate } from "helpers/useCustomFilterCreate";
import { PurchasedProductsList } from "Pages/CustomersPage/components/CustomersTab/components/TabsContent/components/NewCustomersFilter/components/PurchasedProductsFilter/PurchasedProductsList";
import { useProducts } from "helpers/useProducts";

const selector = createSelector(
  ordersGetSwitchSelector,
  ordersGetFilterSelector,
  ordersGetQuickFilterSelector,
  hasOrderDirectSelector,
  currentUserSelector,
  hasQbOrdersSelector,
  (
    ordersGetSwitch,
    ordersGetFilter,
    ordersQuickFilter,
    hasOrderDirect,
    currentUser,
    hasQbOrders
  ) => ({
    ordersGetSwitch,
    ordersGetFilter,
    ordersQuickFilter,
    hasOrderDirect,
    currentUser,
    hasQbOrders,
  })
);

export const NewOrdersFilter = ({
  setAllOrdersChecked,
  setCheckedOrders,
  currentTab,
  ordersExist,
  ordersLoading,
}) => {
  const classes = useStyles();
  const { state } = useLocation();

  const {
    ordersGetSwitch,
    ordersGetFilter,
    ordersQuickFilter,
    hasOrderDirect,
    currentUser,
    hasQbOrders,
  } = useSelector(selector);
  const hasQuickBooksConnection = !!currentUser?.quickBooksTokens;

  const [tabField, setTabField] = useState(ordersQuickFilter);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);

  const filterAnchor = useRef();
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const searchInputDebounced = useDebounce(searchInput, 500);
  const [filterFields, setFilterFields] = useState(ordersGetFilter);
  const [switches, setSwitches] = useState(ordersGetSwitch);
  const [isFilterChanged, setIsFilterChanged] = useState(false);

  useEffect(() => {
    dispatch(ordersGetSwitchFieldActions(switches));
  }, [dispatch, switches]);

  useEffect(() => {
    dispatch(ordersGetFiltersAction(filterFields));
  }, [dispatch, filterFields]);

  useEffect(() => {
    dispatch(getQuickFilterActions(tabField));
  }, [dispatch, tabField]);

  const filterChipKeys = useMemo(
    () =>
      Object.keys(filterFields).filter((key) => {
        if (isArray(filterFields[key]) && isEmpty(filterFields[key])) return;

        return filterFields[key];
      }),
    [filterFields]
  );

  const handleApplyFilter = (newSwitches, newFields) => {
    dispatch(setSelectedFilterIdAction(null));
    setFilterMenuOpen(false);
    setSwitches([...newSwitches]);
    setFilterFields({ ...newFields });
  };

  const handleDeleteFilter = useCallback(
    (key) => {
      dispatch(setSelectedFilterIdAction(null));
      const newState = [...switches];
      const index = newState.findIndex((s) => s.value === key);
      const insert = {
        ...newState[index],
        checked: false,
      };
      if (index > -1) {
        newState.splice(index, 1, insert);
        setSwitches([...newState]);
      }
      setFilterFields((prev) => {
        return {
          ...prev,
          [key]: isArray(filterFields[key]) ? [] : "",
        };
      });
    },
    [dispatch, filterFields, switches]
  );

  const formChanged = useSelector(
    ({ confirmDialogs }) => confirmDialogs.formChanged
  );
  const editType = useSelector(({ confirmDialogs }) => confirmDialogs.editType);

  const handleConfirmCheckedItemsDialog = useCallback(
    (tab) => {
      dispatch(
        openConfirmDialogAction({
          title: "Leaving this page?",
          text: "Your selected list will be discarded.",
          buttons: (
            <>
              <StyledButton
                variant="outlined"
                color="cancel"
                type="submit"
                form={`new-${editType}-form`}
                label="Cancel"
                sx={{ height: "28px" }}
                onClick={() => {
                  dispatch(setConfirmIsOpenAction(false));
                }}
              />
              <Button
                sx={{
                  width: "auto",
                  height: "28px",
                  color: "#FFFFFF",
                  fontSize: "13px",
                  boxShadow: "none",
                }}
                color="confirmDelete"
                onClick={() => {
                  setAllOrdersChecked(false),
                    setCheckedOrders([]),
                    dispatch(setFormChangedAction(false));
                  dispatch(setEditItemAction(null));

                  dispatch(setConfirmIsOpenAction(false));
                  setTabField(tab);
                }}
                variant="contained"
              >
                Confirm
              </Button>
            </>
          ),
        })
      );
    },
    [dispatch, editType, setAllOrdersChecked, setCheckedOrders]
  );

  // const localStoreState = useMemo(() => {
  //   const orderData = localStorage.getItem("orderData");
  //   return JSON.parse(orderData);
  // }, []);

  const [localStoreState, setLocalStoreState] = useState(
    JSON.parse(localStorage.getItem("orderData"))
  );

  useEffect(() => {
    return () => localStorage.removeItem("orderData");
  }, []);

  const clearLocationState = useCallback(() => {
    localStorage.removeItem("orderData");
    setLocalStoreState(null);
  }, []);

  useEffect(() => {
    const { filters } = localStoreState || state || {};
    if (!filters) {
      setSwitches(ordersGetSwitch);
      setFilterFields(ordersGetFilter);
      return;
    }

    setFilterFields((prev) => ({ ...prev, ...filters }));

    const newSwitches = [...switches];

    Object.keys(filters).forEach((key) => {
      const replaceIndex = newSwitches.findIndex((s) => s.value === key);

      newSwitches.splice(replaceIndex, 1, {
        ...switches[replaceIndex],
        checked: true,
      });
    });

    setSwitches([...newSwitches]);

    dispatch(
      ordersGetParamsAction(
        {
          order_status: JSON.stringify(filters?.order_status),
        },
        clearLocationState
      )
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    setIsFilterChanged(true);
    if (localStoreState || isFilterChanged) return;

    checkForDefaultFilter({
      type: CUSTOM_FILTERS_TYPES.ORDERS[currentTab],
      list: currentUser.customFilters,
      setFilters: setFilterFields,
      setSwitches,
      switchesList: switches,
      dispatchFunc: (id) => dispatch(setSelectedFilterIdAction(id)),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.customFilters]);

  const setPaymentStatus = useMemo(() => {
    if (!filterFields?.payment_status) return [];
    return filterFields.payment_status.map((el) => {
      return el === "UNPAID" ? "PENDING" : el;
    });
  }, [filterFields]);

  const {
    handleApplyPurchasedProducts,
    selectedProducts,
    handleRemoveProduct,
    handleProductIds,
    isOpenedPurchasedPopup,
    setOpenPurchasedPopup,
  } = useProducts({ switches, filterFields });

  useEffect(() => {
    if (localStoreState || !isFilterChanged) return;

    const {
      sort_by,
      order_status,
      payment_status,
      fulfillment_method,
      fulfillment_status,
      archived,
      fulfill_by,
      tag_ids,
      tag_id,
      date,
      sales_id,
      purchasedProducts,
      ...query
    } = { ...filterFields };

    const { start_date, end_date } = date?.value || {};

    if (query.sort_by_order_date) delete query.sort_by_shipping_date;
    if (query.sort_by_shipping_date) delete query.sort_by_order_date;
    const sortBy = `sort_by_${sort_by}`;
    const paymentStatus = filterFields.payment_status
      ? {
          payment_status: setPaymentStatus.length
            ? JSON.stringify(setPaymentStatus)
            : [],
        }
      : {};
    const getBody = {
      ...query,
      date: start_date ? undefined : snakeCase(date?.value),
      start_date,
      end_date,

      order_status: order_status.length ? JSON.stringify(order_status) : [],
      payment_status: payment_status.length
        ? JSON.stringify(payment_status)
        : [],
      delivery_status: fulfillment_status?.length
        ? JSON.stringify(fulfillment_status)
        : [],
      delivery_method: fulfillment_method || null,
      [tabField.name]: query[tabField.name] || JSON.stringify([tabField.value]),
      sort_by_order_date: sort_by ? null : "desc",
      [sortBy]: filterFields.sort_by ? "desc" : undefined,
      search: searchInputDebounced,
      sales_duplicate_id: [
        "distributor",
        "order_direct",
        "quickbooks",
      ].includes(sales_id?.value)
        ? undefined
        : sales_id?.value,
      created_by: ["distributor", "order_direct", "quickbooks"].includes(
        sales_id?.value
      )
        ? sales_id?.value
        : sales_id?.value
        ? "sales"
        : undefined,
      archived: !!archived,
      fulfill_by:
        fulfill_by?.value?.start_date || fulfill_by?.value?.isDueDate
          ? null
          : fulfill_by?.value?.id,
      fulfill_by_start_date: fulfill_by?.value?.start_date,
      fulfill_by_due: fulfill_by?.value?.isDueDate
        ? fulfill_by?.value?.id
        : null,
      fulfill_by_end_date: fulfill_by?.value?.end_date,
      tag_ids: filterFields?.tag_id?.length
        ? JSON.stringify(filterFields?.tag_id?.map(({ value }) => value))
        : [],
      product_ids: purchasedProducts?.value?.length
        ? JSON.stringify(handleProductIds(purchasedProducts?.value))
        : "",
      ...paymentStatus,
    };

    dispatch(ordersGetParamsAction({ ...getBody }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterFields,
    dispatch,
    searchInputDebounced,
    tabField,
    setPaymentStatus,
    localStoreState,
    isFilterChanged,
  ]);

  const {
    list: tagsList,
    count: tagsCount,
    handleFetchTags,
    page: tagsPage,
    setTagsParams,
    searcheble: tagsSearcheble,
  } = useTagsActions({
    type: "order",
    params: {
      used: true,
      used_on_type: JSON.stringify([USED_ON_TYPE_ORDER_TYPES.direct]),
    },
    open: filterMenuOpen,
  });

  const {
    list: salesList,
    countSales: salesCount,
    handleFetchReps,
    page: salesPage,
    setRepsParams,
    searcheble: repsSearcheble,
  } = useRepsActions({
    type: TYPES.duplicates,
    params: {
      role: ROLES.sales,
      // with_orders: true,
      with_created_orders: true,
      order_type: JSON.stringify(["DIRECT"]),
    },
    open: filterMenuOpen,
  });

  const getChipLabel = (key) => {
    const fieldKeys = Object.keys(filterFields[key]);
    const isCombined = key === "price";
    const isArr = isArray(filterFields[key]);

    return (
      <Box display="flex" alignItems="center" gap="6px">
        <Typography
          sx={{ fontSize: "13px", fontWeight: 500 }}
          color="groundLighter.main"
        >
          {isArr ? (
            <>
              {normalizeSnakeCaseString(filterFields[key][0]?.label || key)}:{" "}
              <span style={{ color: "#5F6368" }}>
                {typeof filterFields[key][0] === "string"
                  ? filterFields[key]
                      .map((name) => normalizeSnakeCaseString(name))
                      .join(", ")
                  : filterFields[key]
                      .map(({ name }) => normalizeSnakeCaseString(name))
                      .join(", ")}
              </span>
            </>
          ) : (
            <>
              {normalizeSnakeCaseString(filterFields[key]?.label || key)}:{" "}
              <span style={{ color: "#5F6368" }}>
                {isCombined
                  ? fieldKeys
                      .filter((fieldKey) => filterFields[key][fieldKey]?.value)
                      .map((fieldKey) => {
                        const { prefix, value } = filterFields[key][fieldKey];
                        return `${prefix} ${value}`;
                      })
                      .join(" and ")
                  : normalizeSnakeCaseString(
                      filterFields[key]?.name || filterFields[key]
                    )}
              </span>
            </>
          )}
        </Typography>
      </Box>
    );
  };

  const preparedSalesMatchData = useMemo(() => {
    const preparedData = {
      id: "sales_id",
      childrenList: [],
      handleFetch: () => handleFetchReps(salesPage + 1),
      setParams: setRepsParams,
      dataCount: salesCount,
      dataLength: salesList.length,
      label: "Select Rep",
      searcheble: repsSearcheble,
    };

    preparedData.childrenList = [{ name: "Admin", id: "distributor" }];

    if (hasOrderDirect)
      preparedData.childrenList.push({
        name: "Order Direct",
        id: "order_direct",
      });

    if (hasQuickBooksConnection || hasQbOrders)
      preparedData.childrenList.push({ name: "Quickbooks", id: "quickbooks" });

    preparedData.childrenList = [...preparedData.childrenList, ...salesList];

    return preparedData;
  }, [
    salesCount,
    salesList,
    hasOrderDirect,
    hasQuickBooksConnection,
    handleFetchReps,
    hasQbOrders,
    salesPage,
    repsSearcheble,
    setRepsParams,
  ]);

  const selectMatchData = [
    preparedSalesMatchData,
    {
      id: "payment_status",
      checkboxes: [
        { label: "Overdue", field: "payment_due", value: "overdue" },
        { label: "Not due", field: "payment_due", value: "not_due" },
      ],
      valuesForCheckboxes: ["UNPAID"],
    },
    {
      id: "fulfillment_status",
      valuesForSelect: ["UNFULFILLED"],
      selectProps: {
        children: [...PAYMENT_FILTER_DATE_VALUES, ...FILTER_DATE_VALUES],
        datesList: [...PAYMENT_FILTER_DATE_VALUES, ...FILTER_DATE_VALUES],
        label: "Fulfill By",
        value: "fulfill_by",
        showDivider: true,
      },
    },
    {
      id: "tag_id",
      childrenList: tagsList.map(({ tag, ...item }) => ({
        ...item,
        name: tag,
      })),
      handleFetch: () => handleFetchTags(tagsPage + 1),
      setParams: setTagsParams,
      dataCount: tagsCount,
      dataLength: tagsList?.length,
      searcheble: tagsSearcheble,
      label: "",
    },
    { id: "date", label: "Select Date" },
    {
      id: "purchasedProducts",
      label: "Products purchased",
      elementProps: {
        handleOpen: () => setOpenPurchasedPopup(true),
        productsList: selectedProducts,
        handleRemoveProduct,
        type: "purchased",
      },
      Element: PurchasedProductsList,
    },
  ];

  const onCustomFilterApply = useCallback(
    (filters) => {
      setFilterFields(filters);
      setSwitches(handleSetSwitchesForEdit(filters, ordersGetSwitch));
    },
    [ordersGetSwitch]
  );

  const {
    onCloseFilterDialog,
    onFilterViewSave,
    onOpenFilterSaveDialog,
    isOpenedMenu,
  } = useCustomFilterCreate({
    createFilterCallback: onCustomFilterApply,
    menuCallback: () => setFilterMenuOpen(false),
    viewTypeKey: NAME_VIEW_DIALOGS.ORDERS,
    filterType: CUSTOM_FILTERS_TYPES.ORDERS[currentTab],
  });

  return (
    <Box className={classes.filterWrapper}>
      {isOpenedMenu && (
        <FilterViewSaveDialog
          open={isOpenedMenu}
          onClose={onCloseFilterDialog}
          handleSave={onFilterViewSave}
        />
      )}

      <ProductsPurchased
        isOpen={isOpenedPurchasedPopup}
        checkedList={selectedProducts}
        handleClose={() => setOpenPurchasedPopup(false)}
        handleApplyPurchasedProducts={handleApplyPurchasedProducts}
      />

      <Tabs
        value={tabField.value}
        onChange={(e, newVal) => {
          if (formChanged && editType === "checked_items") {
            handleConfirmCheckedItemsDialog({
              name: e.target.name,
              value: newVal,
            });
            return;
          }
          setTabField({ name: e.target.name, value: newVal });
        }}
        className={classes.tabs}
        TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
      >
        <Tab label="All" value="" className={classes.tab} />
        {FILTER_STATUSES.map((status) => (
          <Tab
            key={status.value}
            label={status.name}
            name={status.field}
            value={status.value}
            className={classes.tab}
          />
        ))}
      </Tabs>

      <FilterSearchTextField
        adminIsAllowed
        formSx={{ minWidth: "380px" }}
        placeholderWidth="310px"
        placeholder="Search by ID, customer name or address"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        fullWidth
        loading={ordersExist && ordersLoading}
        adornmentProps={{
          sx: {
            justifyContent: "flex-end",
          },
        }}
        customAdornment={
          <Box
            display="flex"
            gap="5px"
            overflow="auto"
            sx={{
              py: "4px",
              "&::-webkit-scrollbar": {
                height: "2px",
              },
            }}
          >
            {filterChipKeys.map((key) => {
              return (
                <Chip
                  key={key}
                  className={classes.filterChip}
                  size="small"
                  label={getChipLabel(key)}
                  deleteIcon={
                    <CloseIcon size="15" style={{ fill: "#707070" }} />
                  }
                  onMouseDown={(e) => e.stopPropagation()}
                  onDelete={() => handleDeleteFilter(key)}
                />
              );
            })}
          </Box>
        }
        handleClearValue={() => setSearchInput("")}
      />

      <CustomFilterMenu
        {...{
          selectMatchData,
          type: CUSTOM_FILTERS_TYPES.ORDERS[currentTab],
          switches: ordersGetSwitch,
          onFilterApply: onCustomFilterApply,
          resetFilterFunc: () => {
            dispatch(resetOrdersActionsFilter());
            setFilterFields(initialOrdersState.ordersFilter);
            setSwitches(initialOrdersState.ordersSwitch);
          },
        }}
      />

      <Stack direction="row" gap="14px">
        <StyledButton
          label="Filter"
          startIcon={
            filterChipKeys.length ? (
              <Typography
                fontSize="9px!important"
                color="#ffffff"
                backgroundColor="#47A06D"
                borderRadius="50%"
                width="15px"
                height="15px"
              >
                {filterChipKeys.length}
              </Typography>
            ) : (
              <FilterIcon />
            )
          }
          variant="outlined"
          ref={filterAnchor}
          color="edit"
          sx={{
            height: "39px",
            width: "100%",
            maxWidth: "74px",
            border: "0.5px solid #D5D9D9",
            "& .MuiButton-startIcon": {
              ml: 0,
            },
          }}
          fontSize="15px"
          onClick={() => setFilterMenuOpen(true)}
          data-testid="filter-button"
        />
        <FilterMenu
          anchorEl={filterAnchor.current}
          open={filterMenuOpen}
          onClose={() => setFilterMenuOpen(false)}
          filterFields={filterFields}
          isCustomFulfillmentStatus={true}
          switches={switches}
          handleApplyFilter={handleApplyFilter}
          selectMatchData={selectMatchData}
          onViewSave={onOpenFilterSaveDialog}
        />
        {/* {isFlatOrSortedTab && ( */}
        <Box sx={{ mt: "9px" }}>
          <ColumnsSettingComponent currentTab={currentTab} />
        </Box>
      </Stack>
      {/* )} */}
    </Box>
  );
};

NewOrdersFilter.propTypes = {
  setAllOrdersChecked: func,
  setCheckedOrders: func,
  currentTab: string,
  ordersExist: bool,
  ordersLoading: bool,
};

export default NewOrdersFilter;
