import { useMemo, useState } from "react";
import PropTypes, { number, func, object, bool, string } from "prop-types";
import pluralize from "pluralize";
import { Box, IconButton, Typography } from "@mui/material";
import { CrossIcon, InformationIcon } from "components/Icons";
import { StyledButton, StyledLabel, StyledTooltip } from "components";
import { STOPS_INDECES } from "../../CreateRoutePage.constants";
import useStyles from "./styles";
import { CUSTOMER_STATUS_FILTERS } from "utils/constants";
import { CheckInBlock } from "./components";
import { useRepsPermissions } from "helpers/hooks";
import { Link } from "react-router-dom";

const StopItem = ({
  stop,
  index,
  handleDeleteStop,
  completed,
  completedDate,
  timeZone,
  setEditCustomerData,
  shouldRemove,
  handleClickCheckIn,
}) => {
  const repPermissions = useRepsPermissions();

  const disabledCustomersCreateEdit = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.customers?.create_edit;
    }
  }, [repPermissions]);

  const hasMissingInfo =
    !!stop?.missingFields?.length &&
    stop?.missingFields?.includes("Shipping address");

  const [hoverIdEl, setHoverIdEl] = useState(null);

  const preparedVisitTime = useMemo(() => {
    if (
      !stop?.visitTimeInSeconds &&
      typeof stop?.visitTimeInSeconds !== "number"
    )
      return;

    const seconds = stop?.visitTimeInSeconds;
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    const secondsPart = seconds % 60;
    const minutesPart = minutes % 60;

    if (hours) {
      const preparedTime = `${hours} ${pluralize("hr", hours)} ${
        minutesPart ? ` ${minutesPart} min` : ""
      } `;

      return preparedTime;
    }

    if (minutes) {
      const preparedTime = secondsPart > 30 ? minutes + 1 : minutes;

      return preparedTime === 60 ? "1 hr" : `${preparedTime} min`;
    }

    return `${seconds} sec`;
  }, [stop?.visitTimeInSeconds]);

  const tooltipData = useMemo(() => {
    if (hasMissingInfo) {
      return {
        title: (
          <Box textAlign="left">
            <Typography fontSize={12}>
              Incomplete address, please correct
            </Typography>
          </Box>
        ),
      };
    }

    if (shouldRemove) {
      return {
        title: (
          <Box textAlign="left">
            <Typography fontSize={12}>
              Stop is not accessible, <br />
              please remove
            </Typography>
          </Box>
        ),
      };
    }

    return { title: "" };
  }, [hasMissingInfo, shouldRemove]);

  const classes = useStyles({
    completed,
    hasMissingInfo,
    hoverIdEl,
    shouldRemove,
  });

  return (
    <Box
      className={classes.stopItem}
      onMouseEnter={() => setHoverIdEl(stop?.id)}
      onMouseLeave={() => setHoverIdEl(null)}
    >
      <Box
        display="flex"
        gap="18px"
        alignItems="center"
        maxWidth={completed && completedDate ? "64%" : "92%"}
        width={completed && completedDate ? "64%" : "92%"}
      >
        {tooltipData.title ? (
          <StyledTooltip
            isDark
            arrow
            title={tooltipData.title}
            placement="top"
            PopperProps={{
              modifiers: [{ name: "offset", options: { offset: [0, -8] } }],
            }}
            disableHoverListener={!tooltipData.title}
          >
            <Box
              width="25px"
              height="25px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <InformationIcon size="18" stroke="#FF6969" />
            </Box>
          </StyledTooltip>
        ) : (
          <Box className={classes.indexWrapper}>
            <Typography className="index">
              {STOPS_INDECES[index] || index + 1}
            </Typography>
          </Box>
        )}

        <Box overflow="hidden" textOverflow="ellipsis" maxWidth="90%">
          <Box
            display="flex"
            alignItems="center"
            gap="6px"
            className="titleLink"
          >
            <Link to={`/customers/${stop?.id}`} target="_blank">
              <Typography className="title" key={stop?.id} noWrap>
                {stop?.displayName || stop?.name}
              </Typography>
            </Link>
            {stop?.status === CUSTOMER_STATUS_FILTERS.inactive && (
              <StyledLabel text="archived" customWidth="49px" />
            )}
            {stop?.status === CUSTOMER_STATUS_FILTERS.prospect && (
              <StyledLabel text="prospect" />
            )}
          </Box>
          <Typography className="address" noWrap>
            {stop?.formatted_address ||
              stop?.shippingAddress?.formatted_address}
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        gap={1}
        maxWidth="34%"
        width="fit-content"
      >
        {/* {completed && completedDate && (
          <Typography className="date" noWrap>
            {moment(getFormattedDate(completedDate, timeZone)).format(
              "MMM D, h:mm a"
            )}
          </Typography>
        )} */}
        {completed && stop?.visitTimeInSeconds && (
          <Typography className="time" noWrap>
            {preparedVisitTime}
          </Typography>
        )}

        {/* {completed && typeof index === "number" && <ActiveIcon />} */}
        {completed && typeof index === "number" && (
          <CheckInBlock
            {...{
              stop,
              timeZone: timeZone || "America/New_York",
              handleClickCheckIn,
            }}
          />
        )}
      </Box>

      {!!setEditCustomerData && !disabledCustomersCreateEdit && (
        <Box
          sx={{
            height: "100%",
            position: "absolute",
            right: "12%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            opacity: hoverIdEl === stop?.id ? 1 : 0,
          }}
        >
          <StyledButton
            variant="outlined"
            label="Edit"
            className={classes.editButton}
            fontSize="14px"
            fontWeight="300"
            color="ground"
            onClick={() => setEditCustomerData(stop)}
          />
        </Box>
      )}

      {handleDeleteStop && (
        <Box display="flex" alignItems="center" gap={1}>
          <IconButton
            className={classes.deleteIcon}
            onClick={() => handleDeleteStop(stop)}
          >
            <CrossIcon size="14" fill="#B2B2B2" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

StopItem.propTypes = {
  index: PropTypes.oneOfType([number, string]),
  handleDeleteStop: func,
  stop: object,
  completed: bool,
  startLoc: bool,
  completedDate: string,
  timeZone: string,
  setEditCustomerData: func,
  shouldRemove: bool,
  handleClickCheckIn: func,
};

StopItem.defaultProps = {
  index: 0,
  stop: null,
  completed: false,
  startLoc: false,
};

export default StopItem;
