import { createContext } from "react";
import { bool } from "prop-types";
import { useLocation, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { CustomerHeader, CustomerMainBody } from "./components";
import {
  useCustomerPage,
  useProductPrivacy,
  useEditRep,
} from "./CustomerPage.hooks";
import PaytermComponent from "Pages/SettingsPage/components/SettingsTabs/PaytermsTab/PaytermComponent";
import { NewTerritoryDialog } from "components/NewTerritoryDialog/NewTerritoryDialog";
import MapPopup from "../NewCustomerPage/MapPopup/MapPopup";
import DuplicatePopup from "Pages/CustomersPage/components/DuplicatePopup";
import { useRepsPermissions } from "helpers/hooks";
import { usePriceLists } from "helpers/usePriceLists";
import { CustomerCategoriesPopup } from "components/CustomerCategoriesPopup/CustomerCategoriesPopup";
import AllProductsPopup from "Pages/CartPage/components/AllProductsPopup/AllProductsPopup";
import { ProfileComponent } from "Pages/RepresentativesPage/components";
import { error, success } from "utils/notifications";

export const CustomerContext = createContext();

export const CustomerPage = ({ isEdit }) => {
  const { state } = useLocation();
  const { customerId } = useParams();
  const repPermissions = useRepsPermissions();

  const priceListData = usePriceLists({ open: true });

  const {
    open: isOpenRepEditDialog,
    item: itemRepEditDialog,
    openRepEditDialog,
    closeRepEditDialog,
  } = useEditRep();

  const {
    isAdmin,
    control,
    setValue,
    errors,
    trigger,
    formField,
    handleSetCustomer,
    loadingCustomer,
    handleSetDisplayName,
    setCustomerLoading,
    territoryList,
    duplicateOpen,
    setDuplicateOpen,
    handleProceedWithDuplicate,
    popupDuplicates,
    setPopupDuplicates,
    paytermsList,
    handleFetchPayTerms,
    isOpenPaymentTermModal,
    setOpenPaymentTermModal,
    isOpenNewTerritoryModal,
    setOpenNewTerritoryModal,
    shippingDuplicates,
    setShippingDuplicates,
    resetShippingAddressCoordinates,
    handleOpenDuplicates,
    googleAddress,
    setGoogleAddress,
    toggleDetails,
    setToggleDetails,
    mapOpen,
    setMapOpen,
    customIdModal,
    setCustomIdModal,
    customIdValidation,
    setCustomIdValidation,
    nameTooltipOpen,
    setNameTooltipOpen,
    nameWarnText,
    setNameWarnText,
    nameValidation,
    setNameValidation,
    billingValidation,
    setBillingValidation,
    shippingValidation,
    setShippingValidation,
    handleSubmit,
    clearErrors,
    validationNames,
    resetBillingAddressCoordinates,
    formChanged,
    deleteDublicates,
    checkedCustomers,
    handleCheckCustomers,
    deleteLoading,
    handleCloseDuplicatePopup,
    haveQBConnect,
    setError,
    currentUser,
    isOpenCategoriesPopup,
    setIsOpenCategoriesPopup,
    preparedProductCategories,
    productsFetchQuery,
    getAllCategoriesForPopup,
    onSaveProductsFromPopup,
    availableAssignCategories,
    checkForCategoriesChange,
  } = useCustomerPage({ customerId, state, isEdit });

  const {
    excludeIds,
    openAllProducts,
    onOpenAllProducts,
    onCloseAllProducts,
    tempCheckedProducts,
    setTempCheckedProducts,
    onTempProductSelect,
    setExcludeIds,
    getAllProductsForPopup,
    isAllUnchecked,
    setIsAllUnchecked,
    availableAssignedProducts,
  } = useProductPrivacy({
    productsList: formField?.products,
    setValue,
    customerId: formField?.id,
    categoryProducts: formField?.categoryProducts || [],
    productsCount: formField.selectedProductsCount,
    excludeCategoryIds: formField?.excludeProductCategories || [],
  });

  const resetProductsPopup = () => {
    setTempCheckedProducts([]);
    setExcludeIds([]);
    setIsAllUnchecked(false);
    setValue("showAllProducts", true);
    setValue("selectedProductsCount", 0);
    setValue("excludeProducts", []);
    setValue("categoryProducts", []);
    setValue("isAllUncheckedProducts", false);
    //setValue("productCategories", []);
  };

  const onAddCategories = ({
    excludeIds,
    count,
    categories,
    preparedCategories,
    showAllCategories,
    isAllUnchecked,
  }) => {
    if (isEdit) {
      //const productCategoryIds = formField?.productCategories.map(
      //  (category) => category.id
      //);

      //const includeCategory = categories?.filter(
      //  (category) => !productCategoryIds?.includes(category?.id)
      //);

      const includeCategoryIds = preparedCategories?.length
        ? preparedCategories?.map((category) => category?.id)
        : [];

      if (isAllUnchecked) {
        setValue("includeCategoryIds", includeCategoryIds);
      } else {
        setValue("includeCategoryIds", []);
      }

      setExcludeIds([]);
      if ((excludeIds.length || includeCategoryIds.length) && count > 0) {
        getAllProductsForPopup(true, {
          exclude_categories_ids: !isAllUnchecked
            ? JSON.stringify(excludeIds)
            : null,
          categories_ids: isAllUnchecked
            ? JSON.stringify(includeCategoryIds)
            : null,
        });
      }
    }

    setValue("selectedCategoriesCount", count);
    setValue("showAllCategories", showAllCategories);
    setValue("isAllUncheckedCategories", isAllUnchecked);

    if ((isEdit && count > 0) || (!isEdit && !isAllUnchecked)) {
      setValue("excludeProductCategories", excludeIds, {
        shouldDirty: true,
      });
    }

    setValue("productCategories", categories, { shouldDirty: true });
    setValue("preparedCategories", preparedCategories, {
      shouldDirty: true,
    });

    const categoriesAreChanged = checkForCategoriesChange(
      preparedProductCategories
    );

    if (categoriesAreChanged) {
      //if (isEdit)
      //return setValue("showAllProducts", false, { shouldDirty: true });
      resetProductsPopup();
    }
  };

  return (
    <>
      <CustomerContext.Provider
        value={{
          isAdmin,
          isEdit,
          control,
          setValue,
          errors,
          trigger,
          handleSetCustomer,
          loadingCustomer,
          handleSetDisplayName,
          setCustomerLoading,
          territoryList,
          duplicateOpen,
          setDuplicateOpen,
          handleProceedWithDuplicate,
          popupDuplicates,
          setPopupDuplicates,
          paytermsList,
          handleFetchPayTerms,
          isOpenPaymentTermModal,
          setOpenPaymentTermModal,
          isOpenNewTerritoryModal,
          setOpenNewTerritoryModal,
          shippingDuplicates,
          setShippingDuplicates,
          resetShippingAddressCoordinates,
          handleOpenDuplicates,
          googleAddress,
          setGoogleAddress,
          toggleDetails,
          setToggleDetails,
          mapOpen,
          setMapOpen,
          customIdModal,
          setCustomIdModal,
          resetBillingAddressCoordinates,
          validationNames,
          customIdValidation,
          setCustomIdValidation,
          nameTooltipOpen,
          setNameTooltipOpen,
          nameWarnText,
          setNameWarnText,
          nameValidation,
          setNameValidation,
          billingValidation,
          setBillingValidation,
          shippingValidation,
          setShippingValidation,
          handleSubmit,
          formField,
          clearErrors,
          formChanged,
          setError,
          repPermissions,
          customerId,
          priceListData,
          isOpenCategoriesPopup,
          onOpenAllProducts,
          onCloseAllProducts,
          setIsOpenCategoriesPopup,
          openRepEditDialog,
          getAllCategoriesForPopup,
          state
        }}
      >
        <PaytermComponent
          open={isOpenPaymentTermModal}
          onClose={() => setOpenPaymentTermModal(false)}
          isQuickAdd
          setValue={setValue}
          handleFetchPayTerms={() => handleFetchPayTerms()}
        />

        <NewTerritoryDialog
          open={isOpenNewTerritoryModal}
          onClose={() => setOpenNewTerritoryModal(false)}
          setValue={setValue}
        />

        <MapPopup
          handleSetAddress={(address) => {
            if (setGoogleAddress)
              setGoogleAddress((prev) => ({
                ...prev,
                [mapOpen]: { ...address },
              }));

            if (clearErrors) clearErrors(mapOpen);
            setValue(mapOpen, address, { shouldDirty: true });
          }}
          defaultCenter={currentUser.shippingAddress}
          address={formField[mapOpen]}
          isOpen={!!mapOpen}
          customer={formField}
          hideTooltip
          hideRadius
          handleClose={() => setMapOpen(null)}
        />

        {isOpenCategoriesPopup && (
          <CustomerCategoriesPopup
            isEdit={isEdit}
            isAllUncheckedCategories={formField.isAllUncheckedCategories}
            isOpen={isOpenCategoriesPopup}
            handleClose={() => setIsOpenCategoriesPopup(false)}
            onAdd={onAddCategories}
            selectedCategoriesCount={formField.selectedCategoriesCount}
            checkedList={preparedProductCategories}
            incomingExcludeIds={formField.excludeProductCategories}
            availableAssignCategories={availableAssignCategories}
          />
        )}

        {openAllProducts && (
          <AllProductsPopup
            isPriceList
            headerTitle="Select Products"
            isEditCustomer={isEdit}
            allCheckedByDefault
            excludeIds={excludeIds}
            incomingExcludeIds={formField?.excludeProducts}
            isOpen={openAllProducts}
            handleClose={() => onCloseAllProducts()}
            handleCheckProduct={onTempProductSelect}
            handleCancelProducts={() => onCloseAllProducts()}
            setCheckState={setTempCheckedProducts}
            handleAddProducts={(data) => {
              onCloseAllProducts();
              onSaveProductsFromPopup(data);
            }}
            setExcludeIds={setExcludeIds}
            checkedProducts={tempCheckedProducts}
            addedProducts={[]}
            isAllUncheckedProducts={formField.isAllUncheckedProducts}
            allowMultipleSelect
            hideGreenNavigationBar
            isOrder
            skipExpanding
            hideAvailable
            disableOutOfStock={false}
            allowSelectAll
            fetchQuery={productsFetchQuery}
            isAllUnchecked={isAllUnchecked}
            setIsAllUnchecked={setIsAllUnchecked}
            availableAssignedProducts={availableAssignedProducts}
          />
        )}

        <DuplicatePopup
          isOpen={duplicateOpen}
          handleClose={handleCloseDuplicatePopup}
          handleProceed={handleProceedWithDuplicate}
          duplicates={popupDuplicates}
          type="shippingAddress"
          showCheckboxes={popupDuplicates.length > 1}
          checkedCustomers={checkedCustomers}
          setCheckedState={handleCheckCustomers}
          handleDelete={deleteDublicates}
          loading={deleteLoading}
          showDeleteBtn={!haveQBConnect}
          disabledActionBtn={
            popupDuplicates.length > 1 && !checkedCustomers.length
          }
        />

        <ProfileComponent
          profile={itemRepEditDialog}
          isOpenProfileDialog={isOpenRepEditDialog}
          handleCloseProfileDialog={closeRepEditDialog}
          handleSuccess={(res) => {
            const tempArr = [...formField.representatives];

            const index = tempArr.findIndex((item) => item?.id === res?.id);
            if (index !== -1) {
              tempArr[index] = res;
            }

            setValue("representatives", tempArr, { shouldDirty: true });
            success("Representative updated");
          }}
          handleError={(err) => {
            error(err?.response?.data?.message || "Something went wrong.");
          }}
        />

        <Box component="form" id="new-customer-form" onSubmit={handleSubmit}>
          <CustomerHeader />

          <Box mt={4.2} display="flex" justifyContent="center">
            <CustomerMainBody />
          </Box>
        </Box>
      </CustomerContext.Provider>
    </>
  );
};

CustomerPage.propTypes = { isEdit: bool };
CustomerPage.defaultProps = { isEdit: false };

export default CustomerPage;
