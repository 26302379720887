import { useDebounce } from "helpers/hooks";
import { createCategoryMap } from "Pages/CustomersPage/pages/CustomerPage/CustomerPage.helpers";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getCategoriesService } from "services/categories";
import { SCROLL_LIMIT } from "utils/constants";
import { error } from "utils/notifications";

const DEFAULT_STATE = {
  loading: false,
  list: [],
  count: 0, // total categories count without child categories for pagination
  countCategories: 0, // total categories count with child categories
  existData: false,
};

const SORT_FIELDS = ["sort_name"];

const DEFAULT_PARAMS = {
  page: 1,
  limit: SCROLL_LIMIT,
  sort_name: "",
  search_with_children: "",
};

export const useCustomerCategories = ({
  checkedList,
  isOpen,
  handleClose,
  onAdd,
  isEdit = false,
  incomingExcludeIds = [],
  availableAssignCategories = [],
  selectedCategoriesCount = null,
  isAllUncheckedCategories = false,
}) => {
  const [categoriesState, setCategoriesState] = useState(DEFAULT_STATE);
  const [checkedCategories, setCheckedCategories] = useState(new Map());
  const [categoryParams, setCategoryParams] = useState(DEFAULT_PARAMS);
  const [expandParentId, setExpandParentId] = useState(null);
  const [excludeIds, setExcludeIds] = useState([]);
  const [isAllUnchecked, setIsAllUnchecked] = useState(false);

  const searchInputDebounced = useDebounce(
    categoryParams.search_with_children,
    500
  );

  const preparedAvailableAssignCategories = useMemo(
    () => createCategoryMap(availableAssignCategories),
    [availableAssignCategories]
  );

  const setListSearch = (search_with_children) =>
    setCategoryParams((prev) => ({ ...prev, search_with_children }));

  const handleIsAlreadyAdded = (categoryId) => {
    return checkedCategories.some(({ id }) => id === categoryId);
  };

  useEffect(() => {
    if (isOpen) {
      setIsAllUnchecked(isAllUncheckedCategories);
      if (isAllUncheckedCategories) {
        setCheckedCategories(new Map());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllUncheckedCategories]);

  const handleCheckCategory = (category, parent, status) => {
    const updatedCategories = new Map([...checkedCategories]);

    if (parent) {
      if (!updatedCategories.has(parent.id)) {
        updatedCategories.set(parent.id, []);
      }

      const parentChildren = updatedCategories.get(parent.id);

      if (status) {
        if (!parentChildren.some((child) => child.id === category.id)) {
          updatedCategories.set(parent.id, [...parentChildren, category]);
        }
      } else {
        const filteredChildren = parentChildren.filter(
          (child) => child.id !== category.id
        );

        if (filteredChildren.length === 0) {
          updatedCategories.delete(parent.id);
        } else {
          updatedCategories.set(parent.id, filteredChildren);
        }
      }
    } else {
      if (status) {
        updatedCategories.set(
          category.id,
          category.childCategories.length
            ? category.childCategories
            : [category]
        );
      } else {
        updatedCategories.delete(category.id);
      }
    }

    if (!isEdit) {
      setExcludeIds((prevExcludeIds) => {
        const isExcluded = prevExcludeIds.includes(category.id);

        if (!isExcluded) {
          if (category.childCategories?.length) {
            return [
              ...prevExcludeIds,
              category.id,
              ...category.childCategories.map((child) => child.id),
            ];
          }
          if (
            category.parentCategoryId &&
            !updatedCategories.has(category.parentCategoryId) ===
              !prevExcludeIds.includes(category.parentCategoryId)
          )
            return [...prevExcludeIds, category.id, category.parentCategoryId];

          return [...prevExcludeIds, category.id];
        } else if (status && isExcluded) {
          if (category.childCategories?.length) {
            const childIds = category.childCategories.map((child) => child.id);
            return prevExcludeIds.filter(
              (id) => id !== category.id && !childIds.includes(id)
            );
          }

          if (category.parentCategoryId) {
            const parentCategory = categoriesState?.list?.find(
              ({ id }) => id === category.parentCategoryId
            );

            if (
              updatedCategories.get(category.parentCategoryId)?.length ===
                parentCategory.childCategories?.length &&
              prevExcludeIds.includes(category.parentCategoryId)
            ) {
              return prevExcludeIds.filter(
                (id) => id !== category.id && id !== category.parentCategoryId
              );
            }
          }

          return prevExcludeIds.filter((id) => id !== category.id);
        }

        return prevExcludeIds;
      });
    } else {
      setExcludeIds((prevExcludeIds) => {
        const isExcluded = prevExcludeIds.includes(category.id);

        if (!isExcluded && !status) {
          if (category.childCategories?.length) {
            return [
              ...prevExcludeIds,
              category.id,
              ...category.childCategories.map((child) => child.id),
            ];
          }

          if (
            category.parentCategoryId &&
            !updatedCategories.has(category.parentCategoryId) ===
              !prevExcludeIds.includes(category.parentCategoryId)
          )
            return [...prevExcludeIds, category.id, category.parentCategoryId];

          return [...prevExcludeIds, category.id];
        }

        if (category.childCategories?.length) {
          const childIds = category.childCategories.map((child) => child.id);
          return prevExcludeIds.filter(
            (id) => id !== category.id && !childIds.includes(id)
          );
        }
        if (category.parentCategoryId) {
          const parentCategory = categoriesState?.list?.find(
            ({ id }) => id === category.parentCategoryId
          );

          if (
            updatedCategories.get(category.parentCategoryId)?.length ===
              parentCategory.childCategories?.length &&
            prevExcludeIds.includes(category.parentCategoryId)
          ) {
            return prevExcludeIds.filter(
              (id) => id !== category.id && id !== category.parentCategoryId
            );
          }
        }
        return prevExcludeIds.filter((id) => id !== category.id);
      });
    }

    setCheckedCategories(updatedCategories);
  };

  const isCategorySelected = (category, parent = null) => {
    if (parent) {
      if (!checkedCategories.has(parent.id)) return false;

      const parentSelection = checkedCategories.get(parent.id);

      const childIndex = parentSelection.findIndex(
        (child) => child.id === category.id
      );

      if (childIndex === -1) return false;
      return true;
    } else {
      const children = checkedCategories.get(category.id);
      if (!children) return false;

      const allChildrenSelected = category.childCategories.every((child) =>
        children.some((checkedChild) => checkedChild.id === child.id)
      );

      if (allChildrenSelected) return true;

      return false;
    }
  };

  const isIntermediateSelected = (parent) => {
    if (!checkedCategories.has(parent.id)) return false;

    const parentChildren = checkedCategories.get(parent.id);

    if (!parent.childCategories.length) return false;

    return parentChildren.length !== parent.childCategories.length;
  };

  const setLoading = (loading) =>
    setCategoriesState((prev) => ({ ...prev, loading }));

  const getAllCategoryIds = (categories) => {
    const ids = [];

    function collectIds(categoryList) {
      categoryList.forEach((category) => {
        ids.push(category.id);
        if (category.childCategories && category.childCategories.length > 0) {
          collectIds(category.childCategories);
        }
      });
    }

    collectIds(categories);
    return ids;
  };

  const handleFetchCategories = useCallback(
    async (page) => {
      if (!page) {
        setLoading(true);
      }
      try {
        const res = await getCategoriesService({
          ...categoryParams,
          search_with_children: searchInputDebounced,
          page: page ? page : 1,
        });

        const rows = res?.rows || [];
        const list = page > 1 ? [...categoriesState.list, ...rows] : rows;
        const count = res?.count || 0;
        const existData = res?.existData || false;
        const countCategories = res?.countCategories || 0;

        setCategoriesState((prev) => ({
          ...prev,
          list,
          count: count,
          existData,
          countCategories,
        }));

        setCategoryParams((prev) => ({ ...prev, page: page ? page : 1 }));

        if (isEdit) return;

        if (isAllUnchecked || (isAllUncheckedCategories && !page)) {
          const ids = list.length ? getAllCategoryIds(list) : [];
          return setExcludeIds(ids);
        }

        if (
          selectedCategoriesCount !== 0 ||
          (selectedCategoriesCount === 0 && !isAllUnchecked)
        ) {
          const updatedCategories = new Map(checkedCategories);

          list.forEach((category) => {
            if (
              !updatedCategories.has(category.id) &&
              !incomingExcludeIds.includes(category.id) &&
              !excludeIds.includes(category.id)
            ) {
              updatedCategories.set(
                category.id,
                category.childCategories?.length
                  ? category.childCategories.filter((child) => {
                      return !incomingExcludeIds.includes(child.id);
                    })
                  : [category]
              );
            }
          });

          setCheckedCategories(updatedCategories);
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong.");
      } finally {
        setLoading(false);
      }
    },
    [
      categoryParams,
      searchInputDebounced,
      categoriesState.list,
      isEdit,
      isAllUnchecked,
      isAllUncheckedCategories,
      selectedCategoriesCount,
      checkedCategories,
      incomingExcludeIds,
      excludeIds,
    ]
  );

  const handleFetchAllCategories = useCallback(async () => {
    setLoading(true);

    try {
      const res = await getCategoriesService({
        search_with_children: searchInputDebounced,
      });

      const rows = res?.rows || [];
      const count = res?.count || 0;
      const existData = res?.existData || false;
      const countCategories = res?.countCategories || 0;

      setCategoriesState((prev) => ({
        ...prev,
        list: rows,
        count: count,
        existData,
        countCategories,
      }));

      const updatedCategories = new Map();

      rows.forEach((category) => {
        updatedCategories.set(
          category.id,
          category.childCategories?.length
            ? category.childCategories
            : [category]
        );
      });

      setCheckedCategories(updatedCategories);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    } finally {
      setLoading(false);
    }
  }, [searchInputDebounced]);

  const selectCategoryWithChildren = (isChecked) => {
    if (!categoriesState.list.length) return;

    if (!isChecked) {
      const ids = isEdit
        ? availableAssignCategories?.map(({ id }) => id)
        : getAllCategoryIds(categoriesState?.list || []);
      setExcludeIds(ids);
      setIsAllUnchecked(true);
      return setCheckedCategories(new Map());
    }

    if (isEdit) {
      if (
        availableAssignCategories?.length === categoriesState.countCategories
      ) {
        setIsAllUnchecked(false);
        setExcludeIds([]);
        return setCheckedCategories(preparedAvailableAssignCategories);
      }

      if (categoriesState.list.length !== categoriesState.count) {
        handleFetchAllCategories();
        setIsAllUnchecked(false);
        setExcludeIds([]);
        return;
      }
    }

    setExcludeIds([]);
    setIsAllUnchecked(false);

    const updatedCategories = new Map();

    categoriesState?.list?.forEach((category) => {
      updatedCategories.set(
        category.id,
        category.childCategories?.length ? category.childCategories : [category]
      );
    });
    return setCheckedCategories(updatedCategories);
  };

  const getCheckedCategories = (checkedCategories) => {
    const result = [];

    checkedCategories.forEach((values, key) => {
      const keyAsObject = { id: key, parentCategoryId: null };
      result.push(...values);

      const keyExistsInValues = values.some((item) => item.id === key);

      if (!keyExistsInValues) {
        result.push(keyAsObject);
      }
    });

    return result;
  };

  const preparedCategories = useMemo(
    () => getCheckedCategories(checkedCategories),
    [checkedCategories]
  );

  const handleSubmitCategories = () => {
    if (!checkedCategories.size)
      return onAdd({
        excludeIds,
        count: 0,
        categories: [],
        preparedCategories: [],
        showAllCategories: false,
        isAllUnchecked,
      });

    const showAllCategories = isEdit
      ? preparedCategories.length === categoriesState.countCategories
      : !excludeIds.length;

    const categories = [...checkedCategories.values()].flat();

    const selectedCount = isAllUnchecked
      ? preparedCategories.length
      : excludeIds.length
      ? categoriesState.countCategories - excludeIds.length
      : null;

    const editSelectedCount =
      checkedCategories.size == 0
        ? 0
        : categoriesState.countCategories -
          (categoriesState.countCategories - preparedCategories.length);

    onAdd({
      excludeIds,
      count: isEdit ? editSelectedCount : selectedCount,
      categories,
      preparedCategories,
      showAllCategories,
      isAllUnchecked,
    });
  };

  const handleCancel = () => {
    handleClose();
    setCheckedCategories(new Map());
    setCategoryParams(DEFAULT_PARAMS);
  };

  useEffect(() => {
    if (isOpen && incomingExcludeIds?.length) {
      setExcludeIds(incomingExcludeIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomingExcludeIds]);

  useEffect(() => {
    if (isOpen && !!checkedList) {
      setCheckedCategories(checkedList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedList, isOpen]);

  const handleSetSort = useCallback(
    (sortField) => {
      const sortFieldToDefault = Object.keys(categoryParams).filter(
        (item) => SORT_FIELDS.includes(item) && item !== sortField
      );

      setCategoryParams({
        ...categoryParams,
        [sortField]: categoryParams?.[sortField] !== "desc" ? "desc" : "asc",
        ...sortFieldToDefault.reduce(
          (acc, field) => ({ ...acc, [field]: "" }),
          {}
        ),
      });
    },
    [categoryParams]
  );

  const quickSort = useMemo(() => {
    return {
      sort_name: categoryParams?.sort_name,
    };
  }, [categoryParams?.sort_name]);

  useEffect(() => {
    if (isOpen) {
      handleFetchCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInputDebounced, isOpen, categoryParams?.sort_name]);

  const handleExpandChildrenList = (id) => {
    if (id === expandParentId) return setExpandParentId(null);
    return setExpandParentId(id);
  };

  const checkedCount = useMemo(() => {
    return Array.from(checkedCategories?.values()).reduce(
      (acc, array) => acc + array.length,
      0
    );
  }, [checkedCategories]);

  const handleCountElements = (array) => {
    return array.reduce((count, item) => {
      if (item?.childCategories?.length > 0) {
        return count + item?.childCategories?.length;
      } else {
        return count + 1;
      }
    }, 0);
  };

  const totalCountOfElements = useMemo(
    () => handleCountElements(categoriesState?.list),
    [categoriesState?.list]
  );

  return {
    handleCheckCategory,
    handleSetSort,
    quickSort,
    categoriesState,
    page: categoryParams.page,
    handleFetchCategories,
    handleIsAlreadyAdded,
    handleCancel,
    checkedCategories,
    expandParentId,
    handleExpandChildrenList,
    setListSearch,
    searchInput: categoryParams.search_with_children,
    isCategorySelected,
    isIntermediateSelected,
    selectCategoryWithChildren,
    checkedCount: isEdit ? preparedCategories?.length : checkedCount,
    totalCountOfElements: isEdit
      ? categoriesState?.countCategories
      : totalCountOfElements,
    isLoading: categoriesState.loading,
    handleSubmitCategories,
  };
};
