import { getParentCustomersService } from "services/customers";
import {
  getSubParentCustomersService,
  getCustomerByGroupIdService,
} from "../../services/parentCustomers";
import {
  CUSTOMER_STATUS_FILTERS,
  SCROLL_LIMIT_CUSTOMERS,
} from "../../utils/constants";
import { error } from "../../utils/notifications";

export const GET_GROUPS_ACTIVE = "GET_GROUPS_ACTIVE";
export const GET_GROUPS_ACTIVE_COUNT = "GET_GROUPS_ACTIVE_COUNT";

export const GET_GROUPS_INACTIVE = "GET_GROUPS_INACTIVE";
export const GET_GROUPS_INACTIVE_COUNT = "GET_GROUPS_INACTIVE_COUNT";

export const GET_FLAT_GROUPS = "GET_FLAT_GROUPS";
export const GROUPS_GET_PARAMS = "GROUPS_GET_PARAMS";
export const START_LOADING_GROUPS = "START_LOADING_GROUPS";
export const END_LOADING_GROUPS = "END_LOADING_GROUPS";
export const CREATE_GROUP = "CREATE_GROUP";
export const DELETE_GROUP = "DELETE_GROUP";
export const UPDATE_GROUP = "UPDATE_GROUP";
export const GET_FLAT_COUNT = "GET_FLAT_COUNT";
export const GET_EXIST_DATA = "GET_EXIST_DATA";
export const GET_EXIST_SORTED_DATA = "GET_EXIST_SORTED_DATA";
export const SET_CUSTOMERS_BY_ID = "SET_CUSTOMERS_BY_ID";
export const END_LOADING_SORTED_GROUPS = "END_LOADING_SORTED_GROUPS";
export const START_LOADING_SORTED_GROUPS = "START_LOADING_SORTED_GROUPS";

export const getGroupsActive = (payload) => ({
  type: GET_GROUPS_ACTIVE,
  payload,
});

export const getGroupsInactive = (payload) => ({
  type: GET_GROUPS_INACTIVE,
  payload,
});

const getFlatGroups = (payload) => ({
  type: GET_FLAT_GROUPS,
  payload,
});

export const getGroupsActiveCount = (payload) => ({
  type: GET_GROUPS_ACTIVE_COUNT,
  payload,
});

export const getGroupsInactiveCount = (payload) => ({
  type: GET_GROUPS_INACTIVE_COUNT,
  payload,
});

const getFlatCount = (payload) => ({
  type: GET_FLAT_COUNT,
  payload,
});

const getExistData = (payload) => ({
  type: GET_EXIST_DATA,
  payload,
});

const getExistSortedData = (payload) => ({
  type: GET_EXIST_SORTED_DATA,
  payload,
});

const setCustomersById = (payload, groupId, isActive) => ({
  type: SET_CUSTOMERS_BY_ID,
  groupId,
  payload,
  isActive,
});

export const groupsGetParams = (payload) => ({
  type: GROUPS_GET_PARAMS,
  payload,
});

export const startLoadingGroups = () => ({ type: START_LOADING_GROUPS });
export const endLoadingGroups = () => ({ type: END_LOADING_GROUPS });

export const startLoadingSortedGroups = () => ({
  type: START_LOADING_SORTED_GROUPS,
});
export const endLoadingSortedGroups = () => ({
  type: END_LOADING_SORTED_GROUPS,
});

export const getGroupsAction = (props, onSuccess) => {
  return (dispatch, getState) => {
    const state = getState();

    dispatch(
      getGroupsActiveAction(
        {
          ...state.customers.customersGetParams,
          ...props,
          active: undefined,
        },
        onSuccess
      )
    );
  };
};

export const getGroupsActiveAction = (props, onSuccess) => {
  return (dispatch, getState) => {
    const page = props?.page || 1;
    dispatch(startLoadingSortedGroups());
    const state = getState();

    getSubParentCustomersService({
      ...state.customers.customersGetParams,
      ...props,
      include_uncategorized: true,
      page,
    })
      .then((res) => {
        onSuccess && onSuccess(res.rows);
        dispatch(getExistSortedData(res.existData));
        dispatch(
          getGroupsActive(
            page > 1
              ? [...state.groups.groupsActive, ...res?.rows]
              : [...res?.rows]
          )
        );
        dispatch(getGroupsActiveCount(res?.count));
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => {
        dispatch(endLoadingSortedGroups());
      });
  };
};

export const getParentCustomersAction = (props, onSuccess) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(startLoadingGroups());
    getParentCustomersService({
      ...state.groups.parentCustomersParams,
      ...props,
      status:
        props.status ||
        `["${
          state.customers.showInactive
            ? CUSTOMER_STATUS_FILTERS.inactive
            : CUSTOMER_STATUS_FILTERS.active
        }"]`,
    })
      .then((res) => {
        onSuccess && onSuccess(res.rows);
        dispatch(endLoadingGroups());
        dispatch(getExistData(res.existData));
        dispatch(
          getFlatGroups(
            !props.page || props.page === 1
              ? [...res?.rows]
              : [...state.groups.flat, ...res?.rows]
          )
        );
        dispatch(getFlatCount(res.count));
      })
      .catch((err) => {
        error(err?.message);
        dispatch(endLoadingGroups());
        // eslint-disable-next-line no-console
        console.log(err?.message);
      });
  };
};

export const getCustomerByGroupIdAction = (groupId, query, onSuccess) => {
  return (dispatch) => {
    const page = query?.page || 1;
    dispatch(startLoadingGroups());

    getCustomerByGroupIdService(groupId, {
      ...query,
      page,
    })
      .then((newGroup) => {
        !!onSuccess && onSuccess(newGroup.subCustomers);
        dispatch(
          setCustomersById(
            { newGroup, isScrolling: query?.page > 1 },
            groupId,
            query?.active
          )
        );
        dispatch(endLoadingGroups());
      })
      .catch((err) => {
        error(err?.message);
        // eslint-disable-next-line no-console
        console.log(err?.message);
        dispatch(endLoadingGroups());
      });
  };
};

export const groupsGetParamsAction = (data) => {
  return (dispatch) => {
    dispatch(groupsGetParams(data));
    dispatch(
      getParentCustomersAction({
        limit: SCROLL_LIMIT_CUSTOMERS,
      })
    );
  };
};
