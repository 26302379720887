import * as React from "react";
export const ReportsIcon = (props) => (
  <svg
    width={18}
    height={16}
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.364.75c.394 0 .886.405.886 1.132v12.236c0 .727-.492 1.132-.886 1.132h-1.637c-.394 0-.886-.405-.886-1.132V1.882c0-.727.492-1.132.886-1.132zM9.818 4.515c.395 0 .886.405.886 1.132v8.47c0 .728-.492 1.133-.886 1.133H8.182c-.395 0-.887-.405-.887-1.132v-8.47c0-.728.492-1.133.887-1.133zM.75 9.882c0-.727.492-1.132.886-1.132h1.637c.394 0 .886.405.886 1.132v4.236c0 .727-.492 1.132-.886 1.132H1.636c-.394 0-.886-.405-.886-1.132z"
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
  </svg>
);
