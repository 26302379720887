/* eslint-disable react/prop-types */
import * as React from "react";

export const TrashTaskIcon = ({ color = "#707070", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12.699}
    height={14}
    viewBox="0 0 12.699 14"
    {...props}
  >
    <g
      data-name="Icon feather-trash-2"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path data-name="Path 2479" d="M.5 3.1h11.7" />
      <path
        data-name="Path 2480"
        d="M10.9 3.1v9.1a1.3 1.3 0 0 1-1.3 1.3H3.1a1.3 1.3 0 0 1-1.3-1.3V3.1m1.95 0V1.8A1.3 1.3 0 0 1 5.05.5h2.6a1.3 1.3 0 0 1 1.3 1.3v1.3"
      />
      <path data-name="Path 2481" d="M5.05 6.35v3.9" />
      <path data-name="Path 2482" d="M7.65 6.35v3.9" />
    </g>
  </svg>
);
