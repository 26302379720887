import React, { useCallback, useMemo, useState } from "react";
import { func, object } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useWatch } from "react-hook-form";
import moment from "moment";
import pluralize from "pluralize";

import { Box, Button, Grid, Typography } from "@mui/material";
import { cl } from "./PaymentDetailsBody.styles.js";
import {
  BillingCalendarIcon,
  BillingPersonIcon,
  CheckmarkIcon,
} from "components/Icons";

import {
  getDistributorPaymentCardsAction,
  updateUserFieldAction,
} from "redux/actions/auth.js";
import { error, success } from "utils/notifications.js";
import {
  setDefaultPaymentCardService,
  setDistributorUnSubscriptionService,
} from "services/stripe.js";
import { getFormattedDate, useAdmin } from "helpers/helpers.js";
import { setConfirmIsOpenAction } from "redux/actions/confirmDialogs.js";
import { handleConfirmAction } from "../../../../../../../../../../DiscountsPage/DiscountsPage.helpers.js";
import { HubspotContactForm, BillingCreditCard } from "components";

export const PaymentDetailsBody = ({
  control,
  onAddCard,
  onEditCard,
  onDeleteCard,
}) => {
  const isAdmin = useAdmin();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    loading: false,
    openContactDialog: false,
  });
  const formField = useWatch({ control });

  const distributor = useSelector(({ auth }) => auth.currentUser);
  const subscription = useSelector(
    ({ auth }) => auth.currentUser.distributorSubscription
  );
  const timeZone = useSelector(({ auth }) => auth.currentUser.timeZone);

  const countRepresentatives = useSelector(
    ({ auth }) => auth.currentUser.countRepresentatives
  );

  const detailsPlanData = useMemo(
    () => ({
      plane_name: subscription?.subscription?.name,
      terms: subscription?.subscription?.term,
      active_users: countRepresentatives,
      subscription_expires: moment(
        getFormattedDate(subscription?.currentPeriodEnd, timeZone)
      ).format("MM/DD/YY"),
      maximum_users:
        (subscription?.subscription?.allowedUsers ?? 0) +
        (subscription?.extraUser?.extraAllowedUsers ?? 0),
      isTrial: subscription?.stripeSubscriptionStatus === "trialing",
    }),
    [
      countRepresentatives,
      subscription?.currentPeriodEnd,
      subscription?.extraUser?.extraAllowedUsers,
      subscription?.stripeSubscriptionStatus,
      subscription?.subscription?.allowedUsers,
      subscription?.subscription?.name,
      subscription?.subscription?.term,
      timeZone,
    ]
  );
  const {
    plane_name,
    terms,
    active_users,
    subscription_expires,
    maximum_users,
    isTrial,
  } = detailsPlanData;

  const isCreateCardBtnHidden = useMemo(() => {
    return formField?.creditCards?.length >= 2;
  }, [formField?.creditCards?.length]);

  const hasStatusCanceled = useMemo(
    () =>
      subscription?.stripeSubscriptionStatus === "active" &&
      subscription?.endDate !== null,
    [subscription?.endDate, subscription?.stripeSubscriptionStatus]
  );

  const handleSetDefaultCard = (card) => {
    setState((prev) => ({ ...prev, loading: true }));
    setDefaultPaymentCardService({
      type: "distributorPayment",
      id: card?.id,
    })
      .then(() => {
        dispatch(getDistributorPaymentCardsAction());
      })
      .catch((err) => {
        error("Something went wrong!");
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => {
        setState((prev) => ({ ...prev, loading: false }));
      });
  };

  const handleCancelSubscription = useCallback(() => {
    dispatch(
      handleConfirmAction({
        title: "Cancel Subscription?",
        isCancel: true,
        text: (
          <Typography
            fontWeight="300"
            fontSize="15px"
            color="#707070"
            whiteSpace="pre-line"
            marginTop="4px"
            marginBottom="8px"
          >
            Are you sure you want to cancel your subscription?
          </Typography>
        ),
        text2: (
          <Typography
            fontWeight="300"
            fontSize="15px"
            color="#707070"
            whiteSpace="pre-line"
            marginTop="16px"
            marginBottom="10px"
            width="360px"
            component="span"
          >
            {`You will no longer be able to use the application after ${moment(
              getFormattedDate(subscription?.currentPeriodEnd, timeZone)
            ).format("MMM D, YYYY")}`}
          </Typography>
        ),
        dialogStyles: {
          width: "423px",
        },
        dialogButtonsStyles: {
          paddingY: "12px",
          borderTop: "1px solid #D5D9D9",
        },
        confirmColor: "confirmDelete",
        confirmText: "Cancel Subscription",
        confirmButtonStyles: { width: "146px", px: 0 },
        onConfirm: () => {
          if (distributor?.id)
            setDistributorUnSubscriptionService(
              distributor?.distributorSubscription?.id,
              { unsubscribe: true }
            ).then((res) => {
              dispatch(updateUserFieldAction("distributorSubscription", res));
              success("Subscription canceled");
            });
          dispatch(setConfirmIsOpenAction(false));
        },
      })
    );
  }, [dispatch, distributor, subscription?.currentPeriodEnd, timeZone]);

  const termsTitle = useMemo(() => {
    if (subscription?.subscription?.trialDays) {
      const nowDate = moment();
      const endDate = moment(subscription?.currentPeriodEnd);

      const duration = moment.duration(endDate.diff(nowDate));

      return pluralize("day", Math.ceil(duration.asDays()), true);
    }
    return terms ?? "-";
  }, [
    subscription?.currentPeriodEnd,
    subscription?.subscription?.trialDays,
    terms,
  ]);

  return (
    <>
      <HubspotContactForm
        // eslint-disable-next-line no-undef
        formId={process.env.REACT_APP_HUBSPOT_SALES_FORM_ID}
        description=" Please reach out to our sales team for and questions regarding your
      subscription."
        contact="Sales"
        open={state.openContactDialog}
        onClose={() =>
          setState((prev) => ({ ...prev, openContactDialog: false }))
        }
      />
      <Grid sx={cl.wrapper} container>
        {/* Plan */}
        <Grid
          sx={{ ...cl.grid, padding: "10px 22px" }}
          xs={4.28}
          item
          container
        >
          <Grid sx={cl.plan.wrapper} xs={12} item>
            <Box width="18px">
              <BillingPersonIcon />
            </Box>
            <Box sx={cl.plan.textWrapper}>
              <Typography sx={cl.plan.textLeft}>Plan: </Typography>
              <Typography sx={cl.plan.textRight} noWrap>
                {plane_name ?? "-"}
              </Typography>
            </Box>
          </Grid>
          <Grid sx={{ ...cl.plan.wrapper, mt: -1.5 }} xs={12} item>
            <Box width="18px">
              <BillingCalendarIcon />
            </Box>
            <Box sx={cl.plan.textWrapper}>
              <Typography sx={cl.plan.textLeft} noWrap>
                {subscription?.subscription?.trialDays
                  ? "Expires in:"
                  : "Terms:"}{" "}
              </Typography>
              <Typography sx={cl.plan.textRight}>{termsTitle}</Typography>
            </Box>
          </Grid>
          <Grid xs item pt="20px" display="flex" gap="14px">
            <Button
              disabled={isAdmin}
              sx={cl.plan.contactBtn}
              variant="contained"
              color="primary"
              onClick={() => {
                // window.location.href = "mailto:sales@simplydepo.com";
                setState((prev) => ({ ...prev, openContactDialog: true }));
              }}
            >
              Contact Sales
            </Button>
            {isTrial && (
              <Button
                disabled={isAdmin}
                sx={cl.plan.cancelBtn}
                variant="outlined"
                color="cancel"
                onClick={() => {
                  dispatch(
                    updateUserFieldAction("showReachedUsersLimitDialog", {
                      isOpen: true,
                      update: true,
                    })
                  );
                }}
              >
                Upgrade Now
              </Button>
            )}

            {!isTrial &&
              (hasStatusCanceled ? (
                <Button
                  disabled={isAdmin}
                  sx={{
                    height: "24px",
                    fontSize: 10,
                  }}
                  variant="outlined"
                  onClick={() => {
                    dispatch(
                      updateUserFieldAction("showReactivateDialog", true)
                    );
                  }}
                >
                  Reactivate Account
                </Button>
              ) : (
                <Button
                  disabled={isAdmin}
                  sx={cl.plan.cancelBtn}
                  variant="outlined"
                  color="cancel"
                  onClick={handleCancelSubscription}
                >
                  Cancel Subscription
                </Button>
              ))}
          </Grid>
        </Grid>
        {/* Details */}
        <Grid sx={{ ...cl.grid, pl: 3.5 }} xs={3.52} item>
          <Grid sx={{ my: 2.3, gap: 0.3 }} xs={12} item container>
            <Box sx={cl.details.wrapper}>
              <CheckmarkIcon width={13.25} height={9.73} />
              <Typography sx={cl.details.text} noWrap>
                Users: {active_users ?? "-"}
              </Typography>
            </Box>
            <Box sx={cl.details.wrapper}>
              <CheckmarkIcon width={13.25} height={9.73} />
              <Typography sx={cl.details.text} noWrap>
                Subscription Expires: {subscription_expires ?? "-"}
              </Typography>
            </Box>

            <Typography
              sx={{ fontWeight: 300, fontSize: "12px", mt: "29px" }}
              noWrap
            >
              Maximum Allowed Users: {maximum_users ?? "-"}
            </Typography>
          </Grid>
        </Grid>
        {/* Payment Method */}
        <Grid sx={{ ...cl.grid, ...cl.paymentMethod.wrapper }} xs item>
          <Box sx={cl.paymentMethod.cardWrapper}>
            {formField?.creditCards?.map((card) => (
              <BillingCreditCard
                key={card.id}
                card={card}
                onEditCard={onEditCard}
                onDeleteCard={onDeleteCard}
                onSetDefaultCard={handleSetDefaultCard}
                isLoading={state.loading}
                moreThanOneCard={formField?.creditCards?.length > 1}
              />
            ))}
          </Box>
          <Box>
            {!isCreateCardBtnHidden && (
              <Button
                disabled={isAdmin}
                sx={cl.paymentMethod.btn}
                fullWidth
                variant="outlined"
                color="cancel"
                onClick={onAddCard}
              >
                Add new credit or debit card
              </Button>
            )}
          </Box>
        </Grid>
        {/* Credits - temporarily hidden */}
        <Grid sx={{ ...cl.grid, pl: 1.3, display: "none" }} xs={2} item>
          <Typography sx={cl.credits}>$0.00</Typography>
        </Grid>
      </Grid>
    </>
  );
};

PaymentDetailsBody.propTypes = {
  control: object,
  onAddCard: func,
  onEditCard: func,
  onDeleteCard: func,
};
PaymentDetailsBody.defaultProps = { onAddCard: () => {} };
