import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  tail: {
    width: "19px",
    height: "11.36px",
    left: "32px",
    top: "-10px",
    zIndex: 10,
    backgroundColor: "#ffffff",
    clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)",
    position: "absolute",
  },

  tailBackground: {
    width: "21px",
    height: "12.36px",
    left: "31px",
    top: "-12px",
    zIndex: 10,
    backgroundColor: "#D5D9D9",
    clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)",
    position: "absolute",
  },

  menu: {
    overflow: "visible",
    transform: "translateY(10px)!important",
    "& .MuiPickerStaticWrapper-root": {
      "& .MuiTypography-subtitle1": {
        color: "#000",
        whiteSpace: "nowrap",
        fontSize: "12px",
      },

      "& .MuiTypography-caption": {
        width: "18px",
        height: "23px",
        textTransform: "lowercase",
        display: "inline-block",
        "&:first-letter": {
          textTransform: "uppercase",
        },
      },
      "& .PrivatePickersSlideTransition-root": {
        minWidth: "186px",
        width: "186px",
        minHeight: "135px",
        height: "135px",
        borderBottom: "0.5px solid #D4D4D4",
      },
      "& > div": {
        width: "374px",
        "& > div > div": {
          borderRight: "none",
          "& > div": { padding: 0 },
          "& > div:nth-of-type(2)": {
            borderTop: "0.5px solid #D4D4D4",
          },
          "&:not(:last-of-type)": {
            "& > div:nth-of-type(2)": {
              borderRight: "0.5px solid #D4D4D4",
            },
            "& .PrivatePickersSlideTransition-root": {
              borderRight: "0.5px solid #D4D4D4",
            },
          },
        },
      },
      "& .MuiDateRangePickerDay-root": {
        height: "20px",
        width: "22px",
        "& div": {
          height: "20px",
          border: "none",
          display: "flex",
        },
      },
    },
  },
}));

export const cl = {
  menuWrapper: {
    width: "fit-content",
    "& .MuiCalendarPicker-root": {
      "& > div:first-of-type": {
        position: "relative",
        "& > div:first-of-type": {
          height: "100%",
          position: "absolute",
          top: 0,
          left: "50%",
          transform: "translateX(-50%)",
          "& button": {
            display: "none",
          },
        },

        "& > div:last-of-type": {
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          "& button": {
            color: "#409A65 !important",
          },
        },
      },

      "& .MuiTypography-root": {
        fontSize: 15,
        fontWeight: 500,
        color: "#409A65",
        width: "34px",
      },
    },

    "& .PrivatePickersSlideTransition-root": {
      minHeight: "240px",
      "& .MuiPickersDay-root": {
        width: "22px",
        height: "22px",
        m: "7.6px",
        fontSize: "15px",
        fontWeight: 500,
      },
    },
  },
  newStyledRangeMenuWrapper: {
    width: "292px",
    "& .MuiIconButton-edgeStart, & .MuiIconButton-edgeEnd": {
      color: "#409A65",
    },
    "& .MuiPickerStaticWrapper-root": {
      "& > div": {
        "& > div": {
          width: "292px !important",
          "& > div": {
            width: "100%",
            "& .PrivatePickersSlideTransition-root": {
              width: "100%",
              minHeight: "200px",
              "& > div": {
                width: "292px !important",
              },
            },
          },
        },
      },
    },
    "& .MuiTypography-caption": {
      fontSize: 15,
      fontWeight: 500,
      color: "#409A65",
      width: "24px",
      mr: "6px",
      ml: "6px",
    },
    "& .MuiTypography-subtitle1": {
      fontSize: 15,
      fontWeight: 600,
      color: "#333333",
    },
    "& .MuiPickersDay-root": {
      width: "32px",
      height: "32px",
      borderRadius: "16px",
    },
    "& .MuiDateRangePickerDay-rangeIntervalDayHighlight": {
      backgroundColor: "#9fccb2",
    },
  },
};

export default useStyles;
