import { TableHeader } from "components";

import { cl } from "./TableHeaderBlock.styles";

import { Grid, Typography } from "@mui/material";

export const TableHeaderBlock = () => {
  const TABLE_DATA = [
    {
      id: 1,
      title: "CATEGORIES",
      sx: { ...cl.leftHeaderItem },
      xs: 4.55,
    },
    {
      id: 2,
      title: "PRODUCTS",
      sx: { ...cl.leftHeaderItem },
      xs: 7.45,
    },
  ];

  return (
    <TableHeader sx={cl.tableHeaderSx} columns={12}>
      {TABLE_DATA.map(({ id, sx, title, element, xs }) => (
        <Grid sx={sx} key={id} item xs={xs}>
          {title && (
            <Typography fontSize={12} color="#6A6A6A" mr={1}>
              {title}
            </Typography>
          )}
          {element && element}
        </Grid>
      ))}
    </TableHeader>
  );
};
