import { useContext } from "react";

import { StyledTabs } from "components";
import { FormViewContext } from "Pages/FormViewPage/FormViewPage";

import { cl } from "./TabsBlock.styles";

export const TabsBlock = () => {
  const { formId, tabs, handleChangeCurrentTab, currentTab } =
    useContext(FormViewContext);

  const preparedTabs = tabs.map((tab) => {
    return {
      ...tab,
      ...(tab?.label === "Responses" &&
        !formId && {
          disabled: true,
          showTooltip: true,
          tooltipText: "No Responses",
        }),
    };
  });

  return (
    <>
      {tabs && (
        <StyledTabs
          onChange={(e, newVal) => handleChangeCurrentTab(newVal)}
          value={currentTab}
          tabs={preparedTabs}
          tabProps={{ sx: cl.tabsPropsSx }}
          tooltipProps={{ isDark: true }}
          sx={cl.tabs}
        />
      )}
    </>
  );
};
