import { array, func, bool, string } from "prop-types";

import { MenuItem } from "./MenuItem";
import { MenuHeader } from "./MenuHeader";

import { cl } from "../styles";

import { List, Menu } from "@mui/material";

export const SavedFiltersMenu = ({
  loading,
  onFilterApply,
  getActionList,
  selectedFilterId,
  savedFiltersList,
  ...props
}) => {
  return (
    <Menu
      id="basic-menu"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{
        sx: cl.savedFilterMenuPaper,
      }}
      MenuListProps={{
        "aria-labelledby": "basic-button",
        sx: cl.savedFilterMenuList,
      }}
      {...props}
    >
      <MenuHeader />
      <List sx={cl.filterList}>
        {savedFiltersList?.map((filter) => (
          <MenuItem
            {...{
              filter,
              getActionList,
              disabled: loading,
              onClick: onFilterApply,
              isActive: selectedFilterId === filter.id,
            }}
            key={filter.id}
          />
        ))}
      </List>
    </Menu>
  );
};

SavedFiltersMenu.propTypes = {
  loading: bool,
  getActionList: func,
  onFilterApply: func,
  savedFiltersList: array,
  selectedFilterId: string,
};
