import {
  createPaytermService,
  deletePaytermService,
  getPaytermsService,
  updatePaytermService,
} from "../../services/payterms";
import { error, success } from "../../utils/notifications";
import { updateUserFieldAction } from "./auth";
import { getCustomersAction } from "./customers";

export const GET_PAYTERMS = "GET_PAYTERMS";
export const START_LOADING_PAYTERMS = "START_LOADING_PAYTERMS";
export const END_LOADING_PAYTERMS = "END_LOADING_PAYTERMS";
export const CREATE_PAYTERM = "CREATE_PAYTERM";
export const DELETE_PAYTERM = "DELETE_PAYTERM";
export const UPDATE_PAYTERM = "UPDATE_PAYTERM";

const getPayterms = (payload) => ({
  type: GET_PAYTERMS,
  payload,
});

// const createPayterm = (payload) => ({
//   type: CREATE_PAYTERM,
//   payload,
// });

// const updatePayterm = (payload) => ({
//   type: UPDATE_PAYTERM,
//   payload,
// });

const deletePayterm = (payload) => ({
  type: DELETE_PAYTERM,
  payload,
});

const startLoadingPayterms = () => ({ type: START_LOADING_PAYTERMS });
const endLoadingPayterms = () => ({ type: END_LOADING_PAYTERMS });

export const getPaytermsAction = () => {
  return (dispatch) => {
    dispatch(startLoadingPayterms());
    getPaytermsService()
      .then((res) => {
        dispatch(endLoadingPayterms());
        dispatch(getPayterms(res));
      })
      .catch((err) => {
        error(err.message);
        dispatch(endLoadingPayterms());
        // eslint-disable-next-line no-console
        console.log(err?.message);
      });
  };
};

export const createPaytermAction = (data) => {
  return (dispatch) => {
    dispatch(startLoadingPayterms());
    createPaytermService(data)
      .then(() => {
        dispatch(getPaytermsAction());
        // dispatch(createPayterm(res));
        dispatch(getCustomersAction());
        dispatch(endLoadingPayterms());
        success("Payment term created");
      })
      .catch((err) => {
        error(err.message);
        dispatch(endLoadingPayterms());
      });
  };
};

export const updatePaytermAction = (id, data, nav) => {
  return (dispatch) => {
    dispatch(startLoadingPayterms());
    updatePaytermService(id, data)
      .then((res) => {
        dispatch(getPaytermsAction());
        dispatch(getCustomersAction());
        dispatch(endLoadingPayterms());
        if (nav) nav();
        if (data.defaultTerm) {
          dispatch(updateUserFieldAction("defaultPaymentTerms", { ...res }));
        }
        success("Payment term updated");
      })
      .catch((err) => {
        error(err.message);
        dispatch(endLoadingPayterms());
      });
  };
};

export const deletePaytermAction = (id) => {
  return (dispatch) => {
    dispatch(startLoadingPayterms());
    deletePaytermService(id)
      .then(() => {
        dispatch(endLoadingPayterms());
        dispatch(deletePayterm(id));
        dispatch(getCustomersAction());
        success("Payment term deleted");
      })
      .catch((err) => {
        error(err.message);
        dispatch(endLoadingPayterms());
      });
  };
};
