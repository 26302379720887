import { Box, CircularProgress, ListItemText, MenuItem } from "@mui/material";
import { StyledTooltip } from "components";
import { app } from "firebase/Chat/config";
import { collection, deleteDoc, doc, getFirestore } from "firebase/firestore";
import { separateNumWithComma, useAdmin } from "helpers/helpers";
import { useRepsPermissions } from "helpers/hooks";
import { uniqBy } from "lodash";
import pluralize from "pluralize";
import { useCallback, useMemo, useState } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setCurrentUserAction } from "redux/actions/auth";
import {
  bulkArchiveOrdersAction,
  getOrderByIdAction,
} from "redux/actions/orders";
import { currentUserSelector } from "redux/selectors/auth";
import {
  bulkArchiveOrdersService,
  getPaymentIntentLinkService,
} from "services/orders";
import {
  ADMIN_ONLY_VIEW_MESSAGE,
  ADMIN_ONLY_VIEW_MESSAGE_PAGE,
} from "utils/constants";
import { error, success } from "utils/notifications";

const LIMIT_FULFILL_ORDERS = 20000;

export const useOrders = ({ setIsLoading, fetchOrder, setCursor }) => {
  const currentUser = useSelector(({ auth }) => auth?.currentUser);
  const dispatch = useDispatch();
  const isQuickBooksConnected = !!currentUser?.quickBooksTokens;

  const handleBulkArchiveOrders = async (order, onSuccess) => {
    setIsLoading(true);

    const { id, archived } = order || {};

    try {
      await bulkArchiveOrdersService({
        orderIds: [id],
        archive: !archived,
      });

      success(`Order ${archived ? "unarchived" : "archived"}`);
      if (onSuccess) return onSuccess();
      if (fetchOrder) dispatch(getOrderByIdAction(id));

      dispatch(bulkArchiveOrdersAction([id], setCursor));

      dispatch(setCurrentUserAction());
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleMultipleArchiveOrders = async (ids, archived, onSuccess) => {
    setIsLoading(true);

    try {
      await bulkArchiveOrdersService({
        orderIds: ids,
        archive: archived,
      });
      success(`Order(s) ${!archived ? "unarchived" : "archived"}`);

      if (onSuccess) return onSuccess();
      dispatch(bulkArchiveOrdersAction(ids, setCursor));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    } finally {
      setIsLoading(false);
    }
  };

  return {
    handleBulkArchiveOrders,
    isQuickBooksConnected,
    handleMultipleArchiveOrders,
  };
};

export const useOrderStatuses = () => {
  const currentUser = useSelector(currentUserSelector);

  const [statusesCollection] = useDocument(
    collection(getFirestore(app), `order-statuses/${currentUser?.id}/orders`)
  );

  const paymentStatuses = useMemo(() => {
    const statuses = {};
    statusesCollection?.docs?.forEach((d) =>
      Object.assign(statuses, { [d.id]: { ...d?.data() } })
    );
    return statuses;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusesCollection?.docs?.length]);

  const setStatusRefLink = useCallback(
    (orderId) => {
      return `order-statuses/${currentUser?.id}/orders/${orderId}`;
    },
    [currentUser?.id]
  );

  const handleReplaceFirebaseStatuses = useCallback(
    (ordersList) => {
      if (!ordersList?.length) return;
      if (!Object.keys(paymentStatuses)?.length) return ordersList;
      const newOrders = [...ordersList];
      Object.keys(paymentStatuses).forEach((key) => {
        const orderIndex = newOrders.findIndex(({ id }) => key === id);
        if (orderIndex > -1) {
          newOrders.splice(orderIndex, 1, {
            ...ordersList[orderIndex],
            paymentStatus: paymentStatuses[key]?.paymentStatus,
          });
        }
        const statusRef = doc(getFirestore(), setStatusRefLink(key));
        deleteDoc(statusRef);
      });
      return newOrders;
    },
    [paymentStatuses, setStatusRefLink]
  );

  return { paymentStatuses, handleReplaceFirebaseStatuses };
};

export const useOrdersActions = ({
  isQuickBooksConnected,
  order,
  handleChoseMenuItem,
  // distributor,
  handleClickOnAssignTags,
  checkedOrders,
  hideAssignTags,
}) => {
  const {
    id: orderId,
    orderStatus,
    archived,
    deliveryStatus,
    paymentStatus,
    type,
  } = order || {};
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const repPermissions = useRepsPermissions();
  const isAdmin = useAdmin();

  const distributor = useSelector(({ auth }) => auth.currentUser);

  const isThirdParty = useMemo(() => type === "THIRD_PARTY", [type]);

  const [loading, setLoading] = useState(false);

  const showArchive = useMemo(() => {
    if (isQuickBooksConnected) return orderStatus === "CANCELED";

    return ["PENDING", "CANCELED", "COMPLETED"].includes(orderStatus);
  }, [isQuickBooksConnected, orderStatus]);

  const handleCopyLink = async () => {
    if (orderId) {
      setLoading(true);
      const res = await getPaymentIntentLinkService(orderId);
      const { paymentIntentLink } = res || {};
      if (!paymentIntentLink) return error("Something went wrong!");
      navigator.clipboard.writeText(paymentIntentLink);
      success("Payment Link Copied");
      setLoading(false);
    }
  };

  const handleCreateRoute = useCallback(
    (checkedOrderIds, ordersList, checkedOrdersAll) => {
      if (repPermissions && !repPermissions?.routes?.create_edit) return;

      if (checkedOrderIds?.length > ordersList?.length) {
        const checkedCustomers = uniqBy(
          checkedOrdersAll.map(({ customer }) => customer),
          ({ id }) => id
        );
        navigate("/routes/new", { state: { checkedCustomers } });
        return;
      }

      const orderObjects = checkedOrderIds.map((orderId) =>
        ordersList.find(({ id }) => id === orderId)
      );

      const checkedCustomers = uniqBy(
        orderObjects.map(({ customer }) => customer),
        ({ id }) => id
      );

      navigate("/routes/new", { state: { checkedCustomers } });
    },
    [navigate, repPermissions]
  );

  const getAdditionalAction = useCallback(
    (
      checkedOrderIds,
      ordersList,
      checkedOrdersAll,
      handleFulfillOrders,
      isDirectTab = true
    ) => {
      const currentOrders =
        !ordersList.length || !checkedOrderIds.length
          ? []
          : ordersList.filter((order) => checkedOrderIds?.includes(order?.id));

      const isNotDirectOrder = !currentOrders.length
        ? false
        : currentOrders?.every(
            (item) =>
              checkedOrderIds?.includes(item?.id) && item.type !== "DIRECT"
          );

      return [
        {
          label: "Edit",
          disabled: repPermissions
            ? pathname === "/orders"
              ? !repPermissions?.orders?.create_edit
              : !repPermissions?.routes?.create_edit
            : isAdmin,
          onClick: () => navigate(`/cart/${checkedOrderIds[0]}`),
          show: checkedOrderIds.length === 1 && orderStatus !== "CANCELED",
          tooltip: {
            title: (
              repPermissions && pathname === "/orders"
                ? !repPermissions?.orders?.create_edit
                : !repPermissions?.routes?.create_edit
            )
              ? repPermissions?.role === "SALES"
                ? ADMIN_ONLY_VIEW_MESSAGE
                : ADMIN_ONLY_VIEW_MESSAGE_PAGE
              : `${separateNumWithComma(LIMIT_FULFILL_ORDERS, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })} orders max.`,
            show: repPermissions
              ? pathname === "/orders"
                ? !repPermissions?.orders?.create_edit
                : !repPermissions?.routes?.create_edit
              : checkedOrderIds.length >= LIMIT_FULFILL_ORDERS,
            props: { isDark: true },
          },
        },
        {
          label: "Create Route",
          disabled: repPermissions
            ? !repPermissions?.routes?.create_edit
            : isAdmin,
          onClick: () =>
            handleCreateRoute(checkedOrderIds, ordersList, checkedOrdersAll),
          show: true,
          tooltip: {
            title: ADMIN_ONLY_VIEW_MESSAGE,
            show: repPermissions ? !repPermissions?.routes?.create_edit : false,
            props: { isDark: true },
          },
        },
        {
          label: `Fulfill ${pluralize("Order", checkedOrderIds?.length)}`,
          disabled: repPermissions
            ? !repPermissions?.orders?.create_edit
            : checkedOrderIds.length >= LIMIT_FULFILL_ORDERS,
          onClick: () => handleFulfillOrders(),
          show: isDirectTab && !isNotDirectOrder,
          tooltip: {
            title:
              repPermissions && !repPermissions?.orders?.create_edit
                ? repPermissions?.role === "SALES"
                  ? ADMIN_ONLY_VIEW_MESSAGE
                  : ADMIN_ONLY_VIEW_MESSAGE_PAGE
                : `${separateNumWithComma(LIMIT_FULFILL_ORDERS, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })} orders max.`,
            show: repPermissions
              ? !repPermissions?.orders?.create_edit
              : checkedOrderIds.length >= LIMIT_FULFILL_ORDERS,
            props: { isDark: true },
          },
        },
        {
          label: "Assign tags",
          disabled: repPermissions
            ? pathname === "/orders"
              ? !repPermissions?.orders?.create_edit
              : !repPermissions?.routes?.create_edit
            : isAdmin,
          element: null,
          onClick: () =>
            checkedOrderIds?.length === 1
              ? handleChoseMenuItem(order, "Assign tags")
              : handleClickOnAssignTags({ checkedOrders }),
          show: !["OPEN", "CLOSED"].includes(order?.status),
          withDividerAfter: false,
          withDividerBefore: false,
          tooltip: {
            title:
              repPermissions?.role === "SALES"
                ? ADMIN_ONLY_VIEW_MESSAGE
                : ADMIN_ONLY_VIEW_MESSAGE_PAGE,
            show: repPermissions
              ? pathname === "/orders"
                ? !repPermissions?.orders?.create_edit
                : !repPermissions?.routes?.create_edit
              : false,
            props: { isDark: true },
          },
        },
      ].filter((action) => action.show);
    },
    [
      isAdmin,
      repPermissions,
      pathname,
      orderStatus,
      order,
      navigate,
      handleCreateRoute,
      handleChoseMenuItem,
      handleClickOnAssignTags,
      checkedOrders,
    ]
  );

  const handleGetOrderActions = (isDirectTab = true) => {
    if (!order || !Object.keys(order).length) return [];

    const showCopyLink =
      ["OPEN", "CLOSED"].includes(order?.status) || isThirdParty
        ? false
        : isDirectTab &&
          !["PAID"].includes(paymentStatus) &&
          distributor?.stripeAccountVerificationStatus === "VERIFIED" &&
          orderStatus !== "CANCELED";

    const showReturnItems = ["OPEN", "CLOSED"].includes(order?.status)
      ? false
      : ["FULFILLED", "PARTIALLY_FULFILLED"].includes(deliveryStatus) &&
        orderStatus !== "CANCELED";

    const showRefund = ["OPEN", "CLOSED"].includes(order?.status)
      ? false
      : isDirectTab &&
        ["PAID", "PARTIALLY_PAID"].includes(paymentStatus) &&
        orderStatus !== "CANCELED";

    const showFulfillItems =
      ["OPEN", "CLOSED"].includes(order?.status) || isThirdParty
        ? false
        : isDirectTab &&
          !["CANCELED", "COMPLETED"].includes(orderStatus) &&
          !["FULFILLED"].includes(deliveryStatus);

    const showAcceptPayment =
      ["OPEN", "CLOSED"].includes(order?.status) || isThirdParty
        ? false
        : isDirectTab &&
          !["CANCELED", "COMPLETED"].includes(orderStatus) &&
          !["PAID"].includes(paymentStatus);

    const showAssignTags = hideAssignTags
      ? false
      : order?.status
      ? !["OPEN", "CLOSED"].includes(order?.status)
      : !["COMPLETED"].includes(orderStatus);

    return [
      {
        label: "Edit order",
        element: null,
        disabledPermissions: repPermissions
          ? !repPermissions?.orders?.create_edit
          : false,
        disabled:
          isAdmin ||
          (repPermissions
            ? !repPermissions?.orders?.create_edit
            : orderStatus === "CANCELED"),
        onClick: (e) => handleChoseMenuItem(order, e.target.innerText),
        show:
          !["OPEN", "CLOSED"].includes(order?.status) &&
          orderStatus !== "CANCELED",
        withDividerAfter: false,
        withDividerBefore: false,
      },
      {
        label: "Duplicate order",
        sx: { mb: "0px" },
        disabledPermissions: repPermissions
          ? pathname === "/orders"
            ? !repPermissions?.orders?.create_edit
            : !repPermissions?.routes?.create_edit
          : false,
        disabled: isAdmin,
        element: null,
        onClick: (e) => handleChoseMenuItem(order, e.target.innerText),
        show: !["OPEN", "CLOSED"].includes(order?.status),
        withDividerAfter: !showAssignTags,
        withDividerBefore: false,
      },
      {
        label: "Assign tags",
        disabledPermissions: repPermissions
          ? !repPermissions?.orders?.create_edit
          : false,
        disabled: isAdmin,
        // sx: { mb: showReturnItems ? "0px" : "8px" },
        element: null,
        onClick: (e) => handleChoseMenuItem(order, e.target.innerText),
        show: showAssignTags,
        withDividerAfter: true,
        withDividerBefore: false,
      },
      {
        label: "Accept payment",
        disabledPermissions: repPermissions
          ? !repPermissions?.orders?.create_edit
          : false,
        disabled: isAdmin,
        element: null,
        onClick: (e) => handleChoseMenuItem(order, e.target.innerText),
        show: showAcceptPayment,
        withDividerAfter: false,
        withDividerBefore: false,
      },
      {
        label: "Refund",
        disabledPermissions: repPermissions
          ? !repPermissions?.orders?.create_edit
          : false,
        disabled: isAdmin,
        element: null,
        onClick: (e) => handleChoseMenuItem(order, e.target.innerText),
        show: showRefund,
        withDividerAfter: false,
        withDividerBefore: false,
      },
      {
        label: "Fulfill items",
        disabledPermissions: repPermissions
          ? !repPermissions?.orders?.create_edit
          : false,
        disabled: isAdmin,
        element: null,
        sx: { mb: showReturnItems ? "0px" : "8px" },
        onClick: () => handleChoseMenuItem(order, "Confirm delivery"),
        show: showFulfillItems,
        withDividerAfter: false,
        withDividerBefore: false,
      },
      {
        label: "Return Items",
        disabledPermissions: repPermissions
          ? !repPermissions?.orders?.create_edit
          : false,
        disabled: isAdmin,
        sx: { mb: "8px" },
        element: null,
        onClick: (e) => handleChoseMenuItem(order, e.target.innerText),
        show: showReturnItems,
        withDividerAfter: !showCopyLink,
        withDividerBefore: false,
      },
      {
        label: "",
        sx: { mt: "8px" },
        element: (
          <>
            <ListItemText onClick={handleCopyLink} disabled={loading}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ListItemText>Copy invoice link</ListItemText>
                {loading && (
                  <CircularProgress
                    size={15}
                    style={{ marginLeft: "10px" }}
                    sx={{ "& svg": { marginRight: "0px !important" } }}
                  />
                )}
              </Box>
            </ListItemText>
          </>
        ),
        disabledPermissions: repPermissions
          ? !repPermissions?.orders?.create_edit
          : false,
        disabled: loading || isAdmin,
        onClick: (e) => handleChoseMenuItem(order, e.target.innerText),
        show: showCopyLink,
        withDividerAfter: !["OPEN", "CLOSED"].includes(order?.status),
        withDividerBefore: ["OPEN", "CLOSED"].includes(order?.status)
          ? false
          : isDirectTab && true,
      },
      {
        label: "Print order",
        disabledPermissions: repPermissions
          ? !repPermissions?.orders?.view
          : false,
        disabled: isAdmin,
        element: null,
        sx: {
          mt: !isDirectTab || (!showCopyLink && !showReturnItems && "8px"),
        },
        onClick: (e) => handleChoseMenuItem(order, e.target.innerText),
        show: !["OPEN", "CLOSED"].includes(order?.status),
        withDividerAfter: false,
        withDividerBefore:
          !["OPEN", "CLOSED"].includes(order?.status) &&
          !showCopyLink &&
          !showReturnItems &&
          !isThirdParty &&
          orderStatus !== "CANCELED",
      },

      {
        label: "Print packing slip",
        disabledPermissions: repPermissions
          ? !repPermissions?.orders?.view
          : false,
        disabled: isAdmin,
        element: null,
        onClick: (e) => handleChoseMenuItem(order, e.target.innerText),
        show: !["OPEN", "CLOSED"].includes(order?.status),
        withDividerAfter: false,
        withDividerBefore: false,
      },
      {
        label: "Print picklist",
        disabledPermissions: repPermissions
          ? !repPermissions?.orders?.view
          : false,
        disabled: isAdmin,
        onClick: (e) => handleChoseMenuItem(order, e.target.innerText),
        show: !["OPEN", "CLOSED"].includes(order?.status),
        withDividerAfter: false,
        withDividerBefore: false,
      },
      {
        label: "Print all",
        disabledPermissions: repPermissions
          ? !repPermissions?.orders?.view
          : false,
        disabled: isAdmin,

        onClick: (e) => handleChoseMenuItem(order, e.target.innerText),
        show: !["OPEN", "CLOSED"].includes(order?.status),
        withDividerAfter:
          !["OPEN", "CLOSED"].includes(order?.status) &&
          (orderStatus !== "CANCELED" || !isQuickBooksConnected),
        withDividerBefore: false,
      },
      {
        label: archived ? "Unarchive order" : "Archive order",
        disabledPermissions: repPermissions
          ? !repPermissions?.orders?.create_edit
          : false,
        disabled: isAdmin,
        onClick: (e) => handleChoseMenuItem(order, e.target.innerText),
        show:
          ["OPEN", "CLOSED"].includes(order?.status) || isQuickBooksConnected
            ? false
            : showArchive,
        withDividerAfter: false,
        withDividerBefore: false,
      },
      {
        label: "",
        disabledPermissions: repPermissions
          ? !repPermissions?.orders?.create_edit
          : false,
        disabled: isAdmin,
        element: (
          <ListItemText
            classes={{ primary: "deleteItem" }}
            sx={{
              "& > .MuiTypography-root": {
                color: "#FF6254",
              },
            }}
            onClick={(e) => handleChoseMenuItem(order, e.target.innerText)}
          >
            Cancel order
          </ListItemText>
        ),
        onClick: (e) => handleChoseMenuItem(order, e.target.innerText),
        show: ["OPEN", "CLOSED"].includes(order?.status)
          ? false
          : orderStatus !== "CANCELED",
        withDividerAfter: false,
        withDividerBefore: false,
      },
    ];
  };

  const handleGetOrderByIdAndArchive = (ids, orders) => {
    if (!ids?.length) return;
    const order = orders.filter(({ id }) => id === ids[0])[0];

    handleChoseMenuItem(
      ids,
      order?.archived ? "Unarchive orders bulk" : "Archive orders bulk"
    );
  };

  const handleGetArchiveTitle = (ids, orders) => {
    if (!ids?.length) return;
    const order = orders.filter(({ id }) => id === ids[0])[0];

    return order?.archived ? "Unarchive order(s)" : "Archive order(s)";
  };

  const handleMultipleOrderActions = (
    handlePrintOrders,
    handlePrintPickList,
    handlePrintPackingSlips,
    checkedIds,
    orders
  ) => {
    return [
      // {
      //   label: "Assign tags",
      //   sx: { mb: "6px" },
      //   element: null,
      //   disabled: repPermissions ? !repPermissions?.routes?.create_edit : false,
      //   onClick: () => handleClickOnAssignTags({ checkedOrders }),
      //   show: !["OPEN", "CLOSED"].includes(order?.status),
      //   withDividerBefore: false,
      //   withDividerAfter: true,
      // },
      {
        label: "Print order(s)",
        disabled: checkedIds?.length > 100,
        onClick: handlePrintOrders,
        show: !["OPEN", "CLOSED"].includes(order?.status),
        hasTooltip: true,
        element: (
          <StyledTooltip
            title="100 orders max."
            placement="top"
            arrow
            disableHoverListener={!(checkedIds?.length > 100)}
            isDark
          >
            <Box>
              <MenuItem
                disabled={checkedIds?.length > 100 || isAdmin}
                onClick={handlePrintOrders}
              >
                <ListItemText>Print order(s)</ListItemText>
              </MenuItem>
            </Box>
          </StyledTooltip>
        ),
        withDividerBefore: false,
        withDividerAfter: false,
      },
      {
        label: "Print packing slip(s)",
        hasTooltip: true,
        element: (
          <StyledTooltip
            title="100 orders max."
            placement="top"
            arrow
            disableHoverListener={!(checkedIds?.length > 100)}
            isDark
          >
            <Box>
              <MenuItem
                disabled={checkedIds?.length > 100 || isAdmin}
                onClick={handlePrintPackingSlips}
              >
                <ListItemText>Print packing slip(s)</ListItemText>
              </MenuItem>
            </Box>
          </StyledTooltip>
        ),
        disabled: checkedIds?.length > 100,
        onClick: handlePrintPackingSlips,
        show: !["OPEN", "CLOSED"].includes(order?.status),
        withDividerBefore: false,
        withDividerAfter: false,
      },
      {
        label: "Print picklist(s)",
        hasTooltip: true,
        element: (
          <StyledTooltip
            title="100 orders max."
            placement="top"
            arrow
            disableHoverListener={!(checkedIds?.length > 100)}
            isDark
          >
            <Box>
              <MenuItem
                sx={{ mb: "6px" }}
                disabled={checkedIds?.length > 100 || isAdmin}
                onClick={handlePrintPickList}
              >
                <ListItemText>Print picklist(s)</ListItemText>
              </MenuItem>
            </Box>
          </StyledTooltip>
        ),
        sx: { mb: "6px" },
        disabled: checkedIds?.length > 100,
        onClick: handlePrintPickList,
        show: !["OPEN", "CLOSED"].includes(order?.status),
        withDividerBefore: false,
        withDividerAfter: false,
      },
      {
        label: handleGetArchiveTitle(checkedIds, orders),
        sx: { mt: "6px" },
        element: null,
        disabled: repPermissions
          ? !repPermissions?.routes?.create_edit
          : isAdmin,
        onClick: () => handleGetOrderByIdAndArchive(checkedIds, orders),
        show: !(
          ["OPEN", "CLOSED"].includes(order?.status) || isQuickBooksConnected
        ),
        withDividerBefore: !["OPEN", "CLOSED"].includes(order?.status),
        withDividerAfter: false,
      },
    ];
  };

  return {
    handleGetOrderActions,
    handleMultipleOrderActions,
    getAdditionalAction,
    loading,
  };
};
