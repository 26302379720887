import { useNavigate } from "react-router-dom";
import { array, bool, func, number, object } from "prop-types";
import { useSelector } from "react-redux";
import { CircularProgress, Stack } from "@mui/material";

import { OrdersIcon } from "components/Icons";
import NoActivity from "../NoActivity/NoActivity";
import { Switcher, HeaderList, ItemList, LoadingBlock } from "./components";
import { InfiniteScrollWrapper } from "components";
import { useBreakpoint } from "helpers/useBreakpoint";
import { useState } from "react";
import AssignedRepsPopper from "Pages/CustomersPage/components/CustomersTab/components/AssignedRepsPopper/AssignedRepsPopper";

export const OrdersList = ({
  isLoading,
  ordersList,
  handleFetchOrders,
  dataLength,
  hasMore,
  currentTabOrders,
  setCurrentTabOrders,
  isThirdParty,
  isMerchendiser,
}) => {
  const navigate = useNavigate();
  const breakpoint = useBreakpoint();

  const currentUser = useSelector(({ auth }) => auth.currentUser);

  const handleOpenOrder = (id) => {
    navigate(`orders/${id}`);
  };

  const [assignedAnchorEl, setAssignedAnchorEl] = useState(null);
  const [popoverRep, setPopoverRep] = useState(null);
  const [assignedPopperOpen, setAssignedPopperOpen] = useState(false);

  const handleSetAssignedPopover = (anchor, rep, open) => {
    setAssignedAnchorEl(anchor);
    setPopoverRep(rep);
    setAssignedPopperOpen(open);
  };

  return (
    <>
      <AssignedRepsPopper
        anchorEl={assignedAnchorEl}
        rep={popoverRep}
        open={assignedPopperOpen}
      />
      <Switcher {...{ currentTabOrders, setCurrentTabOrders, isThirdParty }} />

      {ordersList.length ? <HeaderList {...{ breakpoint }} /> : null}

      <Stack
        sx={{
          height: ordersList.length ? "241px" : "280px",
          maxHeight: ordersList.length ? "241px" : "280px",
          overflow: "hidden",
          borderWidth: "0px 1px 1px 1px",
          borderStyle: "solid",
          borderColor: "#D5D9D9",
          borderRadius: "0 0 4px 4px",
          background: !ordersList.length && "white",
        }}
      >
        {isLoading ? (
          <LoadingBlock />
        ) : !ordersList.length ? (
          <>
            <NoActivity
              isMerchendiser={isMerchendiser}
              activity="orders"
              icon={<OrdersIcon fill="none" stroke="#ABABAB" />}
            />
          </>
        ) : (
          <InfiniteScrollWrapper
            loading={isLoading}
            id="dashboard-orders-scroll"
            maxHeight={ordersList.length ? "241px" : "280px"}
            dataLength={dataLength}
            hasMore={hasMore}
            style={{ overflowX: "hidden" }}
            loader={
              <CircularProgress sx={{ ml: "50%", mt: "2px" }} size="20px" />
            }
            next={handleFetchOrders}
          >
            {ordersList.map((el) => {
              return (
                <ItemList
                  key={el?.id}
                  item={el}
                  timeZone={currentUser?.timeZone}
                  onOpenOrder={handleOpenOrder}
                  {...{ breakpoint, handleSetAssignedPopover }}
                />
              );
            })}
          </InfiniteScrollWrapper>
        )}
      </Stack>
    </>
  );
};
OrdersList.propTypes = {
  isLoading: bool,
  ordersList: array,
  handleFetchOrders: func,
  dataLength: number,
  hasMore: bool,
  currentTabOrders: object,
  setCurrentTabOrders: func,
  isThirdParty: bool,
  isMerchendiser: bool,
};
OrdersList.defaultProps = {
  isLoading: false,
  ordersList: [],
  handleFetchOrders: () => {},
  dataLength: 0,
  hasMore: false,
  currentTabOrders: { type: "", status: "" },
  setCurrentTabOrders: () => {},
  isThirdParty: false,
  isMerchendiser: false,
};

export default OrdersList;
