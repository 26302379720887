import { useMemo } from "react";
import { oneOf, object, bool, func } from "prop-types";
import { Box, CircularProgress, IconButton } from "@mui/material";
import { useTheme } from "@emotion/react";
import { CheckmarkIcon } from "components/Icons";

export const StatusIcon = ({
  status = "PENDING",
  sx = {},
  styles = {},
  hasClick = false,
  onClick = null,
  loading = false,
  iconProps = {},
}) => {
  const {
    palette: {
      primary: { main: mainPrimaryColor },
      inactive: { main: mainInactiveColor },
    },
  } = useTheme();

  const COLORS = useMemo(
    () => ({ PENDING: mainInactiveColor, COMPLETED: mainPrimaryColor }),
    [mainInactiveColor, mainPrimaryColor]
  );

  const color = useMemo(() => COLORS[status], [COLORS, status]);

  return (
    <Box
      sx={{
        width: "22px",
        height: "22px",
        ...(!loading && { border: `1px solid ${color}` }),
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: loading || !hasClick ? "default" : "pointer",
        ...(styles?.circleColor && {
          border: `1px solid ${styles?.circleColor}`,
        }),
        ...(styles?.circleBgColor && {
          bgcolor: styles?.circleBgColor,
        }),
        ...sx,
      }}
      onClick={(e) => {
        !loading && !!onClick && onClick(e);
      }}
    >
      {loading ? (
        <IconButton
          disableRipple
          sx={{ cursor: loading ? "default" : "pointer" }}
        >
          <CircularProgress size={18} />
        </IconButton>
      ) : (
        <CheckmarkIcon
          width="8.92"
          height="6.83"
          stroke={styles?.iconColor || color}
          strokeWidth={2.5}
          {...iconProps}
        />
      )}
    </Box>
  );
};

StatusIcon.propTypes = {
  status: oneOf(["PENDING", "COMPLETED"]),
  sx: object,
  styles: object,
  hasClick: bool,
  onClick: func,
  loading: bool,
  iconProps: object,
};
