import React from "react";
const DatePickerIcon = (props) => (
  <svg
    width={16}
    height={18}
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.5 8.579v.75h.75v-.75zm-13 0H.75v.75h.75zM5.5 1A.75.75 0 0 0 4 1zM12 1a.75.75 0 0 0-1.5 0zm-.75 11.882a.75.75 0 0 0 0 1.5zm1.3 1.5a.75.75 0 0 0 0-1.5zM4.1 4.276h7.8v-1.5H4.1zm9.65 2.619v6.737h1.5V6.895zM11.9 16.25H4.1v1.5h7.8zm-9.65-2.618V6.895H.75v6.737zM4.1 16.25c-.64 0-1.023-.003-1.298-.05-.23-.04-.284-.095-.328-.152l-1.187.917c.337.437.78.629 1.258.712.433.076.97.073 1.555.073zM.75 13.632c0 .777-.001 1.419.052 1.925.053.512.17.999.485 1.408l1.187-.917c-.064-.084-.138-.24-.18-.646-.043-.41-.044-.96-.044-1.77zm13 0c0 .81-.001 1.36-.044 1.77-.042.405-.116.562-.18.646l1.187.917c.316-.41.432-.896.485-1.408.053-.506.052-1.148.052-1.925zM11.9 17.75c.586 0 1.122.003 1.555-.073.479-.083.92-.275 1.258-.712l-1.187-.917c-.044.057-.099.112-.328.152-.275.047-.658.05-1.298.05zm0-13.474c.64 0 1.023.003 1.298.05.23.04.284.096.327.152l1.188-.917c-.337-.437-.78-.629-1.258-.712-.433-.075-.97-.073-1.555-.073zm3.35 2.619c0-.778.001-1.42-.052-1.926-.053-.512-.17-.998-.485-1.408l-1.187.917c.064.084.138.241.18.646.043.41.044.96.044 1.77zM4.1 2.776c-.586 0-1.122-.002-1.555.073-.479.083-.92.275-1.258.712l1.187.917c.044-.056.099-.111.328-.151.275-.048.658-.05 1.298-.05zM2.25 6.895c0-.81.001-1.36.044-1.77.042-.406.116-.563.18-.647L1.287 3.56c-.316.41-.432.896-.485 1.408C.749 5.476.75 6.117.75 6.895zm11.5 0v1.684h1.5V6.895zm.75.934h-13v1.5h13zm-12.25.75V6.895H.75v1.684zM4 1v2.526h1.5V1zm6.5 0v2.526H12V1zm.75 13.382h1.3v-1.5h-1.3z"
      fill="#A5A5A5"
    />
  </svg>
);
export default DatePickerIcon;
