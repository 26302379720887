export const manufacturersListSelector = (state) =>
  state.manufacturers.manufacturers;
export const manufacturersLoadingSelector = (state) =>
  state.manufacturers.loading;
export const manufacturersExistSelector = (state) =>
  state.manufacturers.existData;
export const manufacturersExistInactiveSelector = (state) =>
  state.manufacturers.existDataInactive;
export const manufacturersCountSelector = (state) => state.manufacturers.count;
export const manufacturersInactiveListSelector = (state) =>
  state.manufacturers.manufacturersInactive;
export const manufacturersInactiveCountSelector = (state) =>
  state.manufacturers.countInactive;
