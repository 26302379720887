import { Typography } from "@mui/material";
import { replaceItemById, useAdmin } from "helpers/helpers";
import { useRepsPermissions } from "helpers/hooks";
import { findItemAndSet } from "Pages/CustomersPage/components/CustomersTab/helpers";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";
import {
  bulkDeactivateRouteService,
  bulkDeleteRouteService,
  deleteRouteService,
  getRoutesService,
  updateRouteService,
} from "services/routes";
import { ADMIN_ONLY_VIEW_MESSAGE } from "utils/constants";
import { error, success } from "utils/notifications";

export const useRoutesBulk = ({
  routesState,
  setRoutesState,
  routesList,
  menuState,
  handleMenuClick,
  fetchQuery,
  setMenuState,
  currentUser,
}) => {
  const isAdmin = useAdmin();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const repPermissions = useRepsPermissions();

  const disabledActions = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.routes?.create_edit;
    }
  }, [repPermissions]);

  const [selectedRoutes, setSelectedRoutes] = useState([]);

  const handleSetLoading = (loading) =>
    setRoutesState((prev) => ({ ...prev, loading }));

  const handleCheckRoute = (route) => {
    if (!route) return;
    const checkedRoutes = findItemAndSet(selectedRoutes, route);
    setSelectedRoutes(checkedRoutes);
  };

  useEffect(() => {
    if (selectedRoutes.length === 1)
      setMenuState({
        anchorEl: null,
        open: false,
        route: selectedRoutes[0],
      });
  }, [selectedRoutes, setMenuState]);

  const handleGetAllRoutes = (
    params = {},
    saveToChecked = true,
    clearChecked = false
  ) => {
    handleSetLoading(true);
    getRoutesService(params)
      .then(({ rows: list, count, existData: routesExist }) => {
        saveToChecked && setSelectedRoutes(list);
        clearChecked && setSelectedRoutes([]);
        setRoutesState((prev) => ({
          ...prev,
          list,
          count,
          routesExist,
        }));
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err.message);
        error(err.response?.data?.message || "Something went wrong.");
      })
      .finally(() => handleSetLoading(false));
  };

  const handleFetchAndSelectAll = () => {
    const generalRoutesCount = routesState.count || 0;

    if (generalRoutesCount === routesList.length)
      return setSelectedRoutes(routesList);
    return handleGetAllRoutes({});
  };

  const handleCheckAvailableRoutes = (checkedAll) => {
    if (!checkedAll) return setSelectedRoutes([]);
    setMenuState({
      anchorEl: null,
      open: false,
      route: routesState.list[0],
    });
    setSelectedRoutes(routesState.list);
    return;
  };

  const handleCheckAllRoutes = (setFullList) => {
    if (!setFullList) return setSelectedRoutes([]);

    if (routesState.list.length === routesState.count)
      return setSelectedRoutes(routesList);

    if (routesState.list.length < routesState.count)
      return handleGetAllRoutes();
  };

  const handleWarningPopup = ({
    title,
    text,
    buttonColor,
    buttonLabel,
    onConfirm,
    isIcon,
  }) => {
    dispatch(
      openConfirmDialogAction({
        isIcon,
        title,
        text,
        propBtns: {
          left: {
            sx: {
              color: "#6A6A6A",
              borderColor: "#D4D4D4",
              fontSize: "13px",
              height: "28px",
            },
            onClick: () => {
              dispatch(setConfirmIsOpenAction(false));
            },
            variant: "outlined",
            label: "Cancel",
          },

          right: {
            sx: {
              color: "#FFFFFF",
              fontSize: "13px",
              height: "28px",
              boxShadow: "none",
            },
            color: buttonColor && buttonColor,
            onClick: () => onConfirm(),
            variant: "contained",
            label: buttonLabel,
          },
        },
      })
    );
  };

  const handleFilterState = (ids) => {
    const filteredList = routesState.list.filter(({ id }) => !ids.includes(id));
    setRoutesState((prev) => ({
      ...prev,
      list: filteredList,
      count: prev.count - ids.length > 0 ? prev.count - ids.length : 0,
    }));
  };

  const isAlreadySelected = (routeId) => {
    if (!selectedRoutes.length) return false;
    return !!selectedRoutes.filter(({ id }) => id === routeId).length;
  };

  const handleBulkRoutesDelete = () => {
    if (!selectedRoutes.length) return;
    handleSetLoading(true);
    dispatch(setConfirmIsOpenAction(false));

    const routesIds = selectedRoutes.map(({ id }) => id);

    bulkDeleteRouteService(routesIds)
      .then(() => success("Route(s) deleted"))
      .catch((err) => error(err?.response?.data?.message))
      .finally(() => {
        handleSetLoading(false);
        setSelectedRoutes([]);
        handleFilterState(routesIds);
      });
  };

  const handleBulkRoutesDeactivate = () => {
    const { active } = menuState.route;
    if (!selectedRoutes.length) return;
    handleSetLoading(true);
    dispatch(setConfirmIsOpenAction(false));

    const routesIds = selectedRoutes.map(({ id }) => id);

    bulkDeactivateRouteService({ routesIds, active: !active })
      .then(() => {
        if (fetchQuery.type !== "active" && fetchQuery.type !== "inactive") {
          const updatedList = routesState.list.map((route) => {
            if (routesIds.includes(route.id))
              return { ...route, active: !active };
            return route;
          });
          setRoutesState((prev) => ({
            ...prev,
            list: updatedList,
            loading: false,
          }));
        } else {
          setRoutesState((prev) => ({
            ...prev,
            list: prev.list.filter((route) => !routesIds.includes(route.id)),
            loading: false,
          }));
        }
        success("Route(s) deactivated");
      })
      .catch((err) => error(err?.response?.data?.message))
      .finally(() => {
        handleSetLoading(false);
        setSelectedRoutes([]);
      });
  };

  const handleDeleteRoute = () => {
    const { id } = menuState.route;
    setRoutesState((prev) => ({
      ...prev,
      loading: true,
    }));
    deleteRouteService(id)
      .then(() => {
        dispatch(setConfirmIsOpenAction(false));
        setRoutesState((prev) => ({
          ...prev,
          list: prev.list.filter((route) => route.id !== id),
          count: prev.count - 1,
          loading: false,
        }));
        success("Route deleted");
      })
      .catch((err) => {
        setRoutesState((prev) => ({
          ...prev,
          loading: false,
        }));
        error(err?.response?.data?.message);
      })
      .finally(() => setSelectedRoutes([]));
  };

  const handleDeleteWarning = () => {
    const { route } = menuState;
    handleWarningPopup({
      isIcon: true,
      title: `Delete ${route.name}?`,
      text: (
        <Typography>
          Are you sure you want to delete route{" "}
          <span
            style={{ fontWeight: "500", color: "#000" }}
          >{`"${route.name}"`}</span>
          ?<br />
          {"All data will be erased and this can't be undone."}
        </Typography>
      ),
      buttonColor: "confirmDelete",
      buttonLabel: "Delete Route",
      onConfirm: handleDeleteRoute,
    });
  };

  const handleBulkDeleteWarning = () => {
    handleWarningPopup({
      isIcon: true,
      title: `Delete route(s)?`,
      text: (
        <Typography>
          Please confirm that you would like to delete selected route(s). All
          data will be erased and this can’t be undone.
        </Typography>
      ),
      buttonLabel: "Confirm",
      onConfirm: handleBulkRoutesDelete,
    });
  };

  const handleBulkDeactivateWarning = () => {
    const { active } = menuState.route;
    const selectedFutureRoutes = selectedRoutes.some(
      (route) => route.isFutureRoute
    );

    if (selectedFutureRoutes && !active) {
      return handleWarningPopup({
        isIcon: true,
        title: "Confirm Route Activation",
        text: (
          <Typography fontSize="15px" fontWeight={300}>
            Activating this routes will set the{" "}
            <span style={{ fontWeight: "500", color: "#000" }}>
              current date
            </span>{" "}
            as the activation date. Are you sure you want to proceed?
          </Typography>
        ),
        buttonColor: "primary",
        buttonLabel: "Proceed",
        onConfirm: handleBulkRoutesDeactivate,
      });
    }

    handleWarningPopup({
      isIcon: true,
      title: active ? `Archive route(s)?` : `Activate route(s)?`,
      text: (
        <Typography>
          Are you sure you want to {active ? `аrchive` : `activate`} this
          route(s)?
          <br />
          {active &&
            "Once archived this route(s) will not be visible to the reps."}
        </Typography>
      ),
      buttonLabel: "Confirm",
      onConfirm: handleBulkRoutesDeactivate,
    });
  };

  const handleArchiveRoute = () => {
    setRoutesState((prev) => ({
      ...prev,
      loading: true,
    }));
    const { id, active } = menuState.route;
    updateRouteService({ active: !active }, id)
      .then((res) => {
        success(`Route ${active ? "archived" : "activated"}`);
        if (fetchQuery.type !== "active" && fetchQuery.type !== "inactive") {
          const newRoutes = replaceItemById(
            res,
            menuState.route,
            routesState.list
          );
          setRoutesState((prev) => ({
            ...prev,
            list: newRoutes,
            loading: false,
          }));
        } else {
          setRoutesState((prev) => ({
            ...prev,
            list: prev.list.filter((route) => route.id !== id),
            loading: false,
          }));
        }
        dispatch(setConfirmIsOpenAction(false));
      })
      .catch((err) => {
        setRoutesState((prev) => ({
          ...prev,
          loading: false,
        }));
        error(err?.response?.data?.message);
      })
      .finally(() => setSelectedRoutes([]));
  };

  const handleDeactivateWarning = () => {
    const { route } = menuState;

    const { isFutureRoute, active, name } = route || {};

    if (!active && isFutureRoute)
      return handleWarningPopup({
        isIcon: true,
        title: "Confirm Route Activation",
        text: (
          <Typography fontSize="13px" fontWeight={300}>
            Activating this route will set the{" "}
            <span style={{ fontWeight: "500", color: "#000" }}>
              current date
            </span>{" "}
            as the activation date. Are you sure you want to proceed?
          </Typography>
        ),
        buttonColor: "primary",
        buttonLabel: "Proceed",
        onConfirm: handleArchiveRoute,
      });

    handleWarningPopup({
      isIcon: false,
      title: `${active ? "Archive" : "Activate"} route?`,
      text: (
        <Typography>
          Are you sure you want to {active ? "archive" : "activate"}{" "}
          <span
            style={{ fontWeight: "500", color: "#000" }}
          >{`"${name}"`}</span>
          ?<br />
          {route.active &&
            "Once archived this route will not be visible to the reps."}
        </Typography>
      ),
      buttonColor: active ? "confirmDelete" : "primary",
      buttonLabel: active ? "Archive" : "Activate",
      onConfirm: handleArchiveRoute,
    });
  };

  const handleSingleRoutesActions = ({ handleAssign }) => {
    return [
      {
        label: "Edit route",
        element: null,
        disabled: !!repPermissions && !repPermissions?.routes?.create_edit,
        onClick: () => navigate(`edit/${menuState.route.id}`),
        show: true,
        tooltip: {
          title: ADMIN_ONLY_VIEW_MESSAGE,
          show: repPermissions ? !repPermissions?.routes?.create_edit : false,
          props: { isDark: true },
        },
      },
      {
        label: "Assign reps",
        element: null,
        disabled: !!repPermissions && !repPermissions?.routes?.assign,
        onClick: () =>
          handleMenuClick(() =>
            handleAssign(
              menuState.route.assignedRepresentatives.map(
                (r) => r.representative
              ),
              [menuState.route]
            )
          ),
        show: true,
        tooltip: {
          title: ADMIN_ONLY_VIEW_MESSAGE,
          show: repPermissions ? !repPermissions?.routes?.assign : false,
          props: { isDark: true },
        },
      },
      {
        label: "Duplicate route",
        element: null,
        disabled: !!repPermissions && !repPermissions?.routes?.create_edit,
        onClick: () =>
          navigate("new", { state: { duplicateId: menuState.route?.id } }),
        show: true,
        tooltip: {
          title: ADMIN_ONLY_VIEW_MESSAGE,
          show: repPermissions ? !repPermissions?.routes?.create_edit : false,
          props: { isDark: true },
        },
      },
      {
        label: `${menuState.route?.active ? "Deactivate" : "Activate"} route`,
        element: null,
        disabled: disabledActions || isAdmin,
        disabledPermissions: disabledActions,
        onClick: () =>
          !(disabledActions || isAdmin) &&
          handleMenuClick(handleDeactivateWarning),
        show: true,
        tooltip: {
          title: ADMIN_ONLY_VIEW_MESSAGE,
          show: disabledActions,
          props: { isDark: true },
        },
      },
      {
        label: "Delete route",
        element: null,
        sx: { color: "#FF6254" },
        disabled: disabledActions || isAdmin,
        disabledPermissions: disabledActions,
        onClick: () =>
          !(disabledActions || isAdmin) && handleMenuClick(handleDeleteWarning),
        show: true,
        tooltip: {
          title: ADMIN_ONLY_VIEW_MESSAGE,
          show: disabledActions,
          props: { isDark: true },
        },
      },
    ];
  };
  const handleMultipleRoutesActions = ({ handleOpenTransfer }) => {
    return [
      {
        label: "Assign reps",
        element: null,
        disabled: !!repPermissions && !repPermissions?.routes?.assign,
        onClick: handleOpenTransfer,
        show: true,
      },
      {
        label: `${menuState.route?.active ? "Deactivate" : "Activate"} route`,
        element: null,
        disabled: disabledActions || isAdmin,
        onClick: !(disabledActions || isAdmin) && handleBulkDeactivateWarning,
        show: true,
      },
      {
        label: "Delete route",
        element: null,
        sx: { color: "#FF6254" },
        disabled: disabledActions || isAdmin,
        onClick: !(disabledActions || isAdmin) && handleBulkDeleteWarning,
        show: !currentUser.quickBooksTokens,
      },
    ];
  };

  const handleGetQuickActions = (params) => {
    if (!selectedRoutes.length) return [];

    if (selectedRoutes.length === 1) {
      return handleSingleRoutesActions(params).slice(0, 3);
    }
    return handleMultipleRoutesActions(params).slice(0, 1);
  };

  const handleGetDropDownActions = (params) => {
    if (!selectedRoutes.length) return [];

    if (selectedRoutes.length === 1) {
      return handleSingleRoutesActions(params).slice(3, 5);
    }
    return handleMultipleRoutesActions(params).slice(1, 3);
  };

  return {
    handleWarningPopup,
    handleBulkRoutesDelete,
    handleBulkDeleteWarning,
    selectedRoutes,
    handleCheckRoute,
    isAlreadySelected,
    handleCheckAllRoutes,
    handleCheckAvailableRoutes,
    handleGetAllRoutes,
    handleSetLoading,
    setSelectedRoutes,
    handleSingleRoutesActions,
    handleGetQuickActions,
    handleGetDropDownActions,
    handleFetchAndSelectAll,
  };
};
