import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mui/material";
import pluralize from "pluralize";
import { useCallback, useMemo, useState } from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";
import {
  createContactRoleBulkService,
  deleteContactRoleService,
  updateContactRoleService,
} from "services/contactRoles";
import { error, success } from "utils/notifications";
import { validationSchema } from "./CustomersTab.validations";
import { updateUserFieldAction } from "redux/actions/auth";

export const useContactRoles = ({ currentUser }) => {
  const dispatch = useDispatch();

  const [isTyping, setIsTyping] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validationPopperOpen, setValidationPopperOpen] = useState(false);

  const onError = () => {
    setValidationPopperOpen(true);
  };

  const DEFAULT_VALUES = useMemo(() => {
    return {
      checkInCustomerRadiusEnabled:
        currentUser?.checkInCustomerRadiusEnabled || false,
      checkInCustomerRadius: currentUser?.checkInCustomerRadius || 500,
      contactRoles: currentUser?.contactRoles || [],
    };
  }, [currentUser]);

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    trigger,
    setError,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onSubmit",
    shouldFocusError: false,
    reValidateMode: "onSubmit",
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(validationSchema()),
  });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "contactRoles",
    keyName: "key",
  });

  const handleAddRole = () => {
    append({
      name: "",
      defaultRole: false,
    });
  };

  const formField = useWatch({ control });

  const checkForUniqNames = (checkName, index, shouldSetError = false) => {
    const filtredNames = fields.map(({ name }) => name);

    if (shouldSetError) {
      if (
        filtredNames.includes(checkName) &&
        fields[index].name !== checkName
      ) {
        return setError(`contactRoles.${index}.name`, {
          type: "dublicate",
          message: "Role with this name already exists",
        });
      }
      clearErrors(`contactRoles.${index}.name`);
    }

    return !!filtredNames.includes(checkName);
  };

  const onSubmit = useCallback(
    async (data) => {
      setIsLoading(true);
      const { contactRoles } = data;
      const filtredContactRoles = contactRoles.filter((item) => !item.id);

      try {
        const res = await createContactRoleBulkService({
          newContactRoles: filtredContactRoles,
        });
        dispatch(updateUserFieldAction("contactRoles", res));
        reset({ ...DEFAULT_VALUES, contactRoles: res });
        success("Contact type created");
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        error(err?.response?.data?.message || "Something went wrong.");
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser?.contactRoles, reset]
  );

  const onDeleteRole = useCallback(
    async (id) => {
      setIsLoading(true);
      const filtredRoles = formField.contactRoles.filter(
        (item) => item.id !== id
      );

      try {
        const res = await deleteContactRoleService(id);
        reset({
          ...DEFAULT_VALUES,
          contactRoles: filtredRoles,
        });
        dispatch(updateUserFieldAction("contactRoles", res));
        success("Contact type deleted");
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        error(err?.response?.data?.message || "Something went wrong.");
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formField.contactRoles, reset]
  );

  const hasDublicateError = () => {
    if (!errors || !errors.contactRoles || !errors.contactRoles.length)
      return false;

    return !!errors.contactRoles.filter((item) => {
      return item.name.type === "dublicate";
    }).length;
  };

  const handleDeleteContactRole = useCallback(
    (role, i) => {
      if (!role.id) {
        const frontPart = formField.contactRoles.slice(0, i);
        const lastPart = formField.contactRoles.slice(i + 1);

        remove(i);
        return reset({
          ...DEFAULT_VALUES,
          contactRoles: [...frontPart, ...lastPart],
        });
      }
      remove(i);
      return onDeleteRole(role.id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formField.contactRoles, onDeleteRole, reset, remove]
  );

  const handleOpenConfirm = useCallback(
    ({ title, handleConfirm, text }) => {
      dispatch(
        openConfirmDialogAction({
          title,
          text,
          buttons: (
            <>
              <Button
                sx={{
                  width: "98px",
                  color: "#6A6A6A",
                  borderColor: "#D4D4D4",
                  fontSize: "13px",
                  height: "28px",
                }}
                onClick={() => {
                  dispatch(setConfirmIsOpenAction(false));
                }}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                sx={{
                  width: "98px",
                  color: "#FFFFFF",
                  fontSize: "13px",
                  height: "28px",
                  boxShadow: "none",
                }}
                color="confirmDelete"
                onClick={() => {
                  dispatch(setConfirmIsOpenAction(false));
                  handleConfirm();
                }}
                variant="contained"
              >
                Confirm
              </Button>
            </>
          ),
        })
      );
    },
    [dispatch]
  );

  const handleDeleteRole = useCallback(
    (role, i) => {
      if (role.id && role._count?.contacts) {
        const primaryRole = formField.contactRoles.find(
          ({ defaultRole }) => !!defaultRole
        );

        return handleOpenConfirm({
          title: "Contact Type Deletion",
          text: `You currently have ${role._count?.contacts} ${pluralize(
            "contact",
            role._count?.contacts
          )} assigned with the type '${
            role.name
          }'. By proceeding, these contacts will be reassigned to the primary type '${
            primaryRole && primaryRole.name
          }' \nAre you sure you want to proceed with this action?`,
          handleConfirm: () => {
            handleDeleteContactRole(role, i);
          },
        });
      }

      handleDeleteContactRole(role, i);
      return;
    },
    [formField.contactRoles, handleDeleteContactRole, handleOpenConfirm]
  );

  const onEditRole = useCallback(
    async (index, role, skipValidation = false) => {
      const returnFromFunc =
        !role.id ||
        (!skipValidation &&
          formField.contactRoles[index].name === fields[index].name);

      if (returnFromFunc) return;

      const hasDublicateName = checkForUniqNames(role.name, index);

      if (
        hasDublicateName &&
        fields[index].name !== role.name &&
        !skipValidation
      ) {
        return setError(`contactRoles.${index}.name`, {
          type: "dublicate",
          message: "Role with this name already exists",
        });
      }

      if (!role.name) {
        return setError(`contactRoles.${index}.name`, {
          type: "custom",
          message: "Name is empty",
        });
      }

      clearErrors(`contactRoles.${index}.name`);
      const {
        id,
        distributorId,
        _count,
        createdAt,
        defaultRole,
        name,
        ...rest
      } = role;
      try {
        setIsLoading(true);
        const res = await updateContactRoleService(id, {
          ...rest,
          name: hasDublicateName ? fields[index].name : name,
          defaultRole: skipValidation ? true : defaultRole,
        });
        success("Contact type updated");

        if (res && res.length === formField.contactRoles.length) {
          update(index, {
            ...role,
            defaultRole: skipValidation ? true : defaultRole,
          });
        }
        reset({
          ...DEFAULT_VALUES,
          contactRoles: res,
        });
        dispatch(updateUserFieldAction("contactRoles", res));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        error(err?.response?.data?.message || "Something went wrong.");
      } finally {
        setIsLoading(false);
        setIsTyping(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clearErrors, fields, formField.contactRoles, reset, setError, update]
  );

  const handleChangeDefault = useCallback(
    (pos) => {
      if (formField.contactRoles[pos].defaultRole) return;
      const defIndex = formField.contactRoles.findIndex((c) => c.defaultRole);

      setValue(
        `contactRoles.${defIndex > -1 ? defIndex : 0}.defaultRole`,
        false,
        {
          shouldDirty: true,
        }
      );
      setValue(`contactRoles.${defIndex > -1 ? pos : 0}.defaultRole`, true, {
        shouldDirty: true,
      });

      if (formField.contactRoles[pos].id) {
        setIsTyping(true);
        onEditRole(pos, formField.contactRoles[defIndex > -1 ? pos : 0], true);
      }
    },
    [formField.contactRoles, onEditRole, setValue]
  );

  const handleInputChange = (e, defaultRole, role, index, field) => {
    if (e.target.value !== defaultRole.name) {
      checkForUniqNames(e.target.value, index, true);
      if (role.id) setIsTyping(true);
    } else {
      setIsTyping(false);
    }
    field.onChange(e);
  };

  return {
    onSubmit,
    validationPopperOpen,
    setValidationPopperOpen,
    onError,
    control,
    setValue,
    handleSubmit,
    reset,
    trigger,
    errors,
    isDirty,
    formField,
    DEFAULT_VALUES,
    isLoading,
    onDeleteRole,
    handleDeleteContactRole,
    fields,
    handleAddRole,
    handleChangeDefault,
    handleDeleteRole,
    onEditRole,
    isTyping,
    checkForUniqNames,
    setIsTyping,
    handleInputChange,
    hasDublicateError,
  };
};
