import { useContext, useMemo, useState } from "react";
import { error, success } from "utils/notifications";
import { CustomerProfileContext } from "../../CustomerProfilePage";
import { useDispatch } from "react-redux";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";
import { STATUSES } from "./TaskDrawer.constants";
import {
  createTaskService,
  deleteTaskService,
  updateCompletedTaskService,
  updateTaskService,
} from "services/tasks";
import { Box, Typography } from "@mui/material";
import { CheckmarkIcon, SmallHourglassIcon } from "components/Icons";
import moment from "moment-timezone";

export const useCustomerTasks = ({ setDialogState }) => {
  const {
    currentTable,
    taskState,
    handleCloseTask,
    customerId,
    handleRefetchTasks,
    handleFetchActivities,
  } = useContext(CustomerProfileContext);

  const { filterTab } = currentTable || {};

  const dispatch = useDispatch();

  const editTask = useMemo(() => !!taskState?.task, [taskState?.task]);
  const taskId = useMemo(
    () => taskState?.task?.id || taskState?.task?.task?.id,
    [taskState?.task?.id, taskState?.task?.task?.id]
  );

  const [loading, setLoading] = useState(false);

  const handleSaveTask = async (data, isRepeatedTask = false) => {
    setLoading(true);
    setDialogState((prev) => ({ ...prev, isOpen: false }));

    const {
      title,
      description,
      dueDate,
      priority,
      representative,
      status,
      repeated,
      repeatInterval,
      repeatStep,
      applyForFutureTasks,
      addTime,
      dueTime,
      formIds,
    } = data || {};

    const preparedData = {
      title,
      description,
      customerId,
      priority,
      repeated,
      ...(repeated && {
        repeatInterval: repeatInterval.toUpperCase(),
      }),
      ...(repeated &&
        repeatInterval !== "weekday" && {
          repeatStep: +repeatStep,
        }),
      ...(editTask && isRepeatedTask && { applyForFutureTasks }),
      // representativesIds: [representative],
      // assignToDistributor,
      formIds,
    };

    if (dueDate) preparedData.dueDate = dueDate;

    if (addTime && dueTime)
      preparedData.dueTime = moment(dueTime).utc().format("HH:mm");

    preparedData.assignToDistributor = representative === "distributor";
    preparedData.representativesIds =
      representative === "distributor" ? undefined : [representative];

    try {
      if (editTask && taskId) {
        preparedData.status = status;
        await updateTaskService({ taskId, data: preparedData });
        //success("Task updated");
      } else {
        await createTaskService({ data: preparedData });
      }
      success(`Task ${editTask ? "updated" : "created"}`);
      filterTab && handleFetchActivities({ page: 1 });
      handleCloseTask();
      handleRefetchTasks();
    } catch (err) {
      error(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateCompletedTask = async (data) => {
    const preparedData = {};
    try {
      if (editTask && taskId) {
        preparedData.status = data;
        await updateCompletedTaskService({ taskId, data: preparedData });
        success(data === STATUSES[1] ? "Task completed" : "Marked incomplete");
        filterTab && handleFetchActivities({ page: 1 });
      }
      handleRefetchTasks();
    } catch (err) {
      error(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmDelete = async (data, isRepeatedTask = false) => {
    try {
      setLoading(true);
      await deleteTaskService({
        tasksIds: [taskId],
        ...(editTask &&
          isRepeatedTask && {
            applyForFutureTasks: data?.applyForFutureTasks || false,
          }),
      });
      success("Task deleted");
      filterTab && handleFetchActivities({ page: 1 });
      dispatch(setConfirmIsOpenAction(false));
      setDialogState((prev) => ({ ...prev, isOpen: false }));
      handleCloseTask();
      handleRefetchTasks();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      error(err?.response?.data?.message);
    }
  };

  const handleDeleteTask = async () => {
    dispatch(
      openConfirmDialogAction({
        title: "Delete task?",
        text: "Are you sure you want to delete this task?",
        propBtns: {
          left: { label: "Cancel", color: "cancel", variant: "outlined" },
          right: {
            label: "Confirm",
            color: "error",
            variant: "contained",
            onClick: handleConfirmDelete,
          },
        },
      })
    );
  };

  const handleOnDeleteDrawer = () => {
    setDialogState({ isOpen: true, isDeleting: true });
  };

  const handleOnSaveDrawer = () => {
    setDialogState({ isOpen: true, isDeleting: false });
  };

  const handleOnCloseDrawer = () => {
    setDialogState({ isOpen: false, isDeleting: false });
  };

  return {
    handleSaveTask,
    handleConfirmDelete,
    handleCloseTask,
    open: taskState.open,
    repData: taskState.task?.representativeDuplicate,
    taskData: taskState.task,
    handleDeleteTask,
    loading,
    taskState,
    editTask,
    handleUpdateCompletedTask,
    handleOnDeleteDrawer,
    handleOnSaveDrawer,
    handleOnCloseDrawer,
  };
};

export const getPriority = ({ type, sx = {} }) => {
  const colors = {
    MEDIUM: "#F0C401",
    LOW: "#47A06D",
    CRITICAL: "#FF6969",
    HIGH: "#FF6969",
  };

  const bgColors = {
    MEDIUM: "rgba(240, 196, 1, 0.2)",
    LOW: "rgba(71, 160, 109, 0.2)",
    CRITICAL: "rgba(255, 105, 105, 0.2)",
    HIGH: "rgba(255, 105, 105, 0.2)",
  };

  return (
    <Box
      sx={{
        width: "93px",
        height: "22px",
        background: bgColors[type],
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "16px",
        ...sx,
      }}
    >
      <Typography
        sx={{ fontSize: "10px!important" }}
        color={`${colors[type]} !important`}
      >
        {type}
      </Typography>
    </Box>
  );
};

export const getStatus = ({ type, sx = {} }) => {
  const colors = {
    PENDING: "#F0C401",
    COMPLETED: "#47A06D",
  };

  const bgColors = {
    PENDING: "rgba(240, 196, 1, 0.2)",
    COMPLETED: "rgba(71, 160, 109, 0.2)",
  };

  const icons = {
    PENDING: (
      <Box mr={1}>
        <SmallHourglassIcon fill={colors[type]} />
      </Box>
    ),
    COMPLETED: (
      <>
        <CheckmarkIcon width="11.18" height="8" stroke="#52B985" />
        <CheckmarkIcon
          width="11.18"
          height="8"
          stroke="#52B985"
          style={{ marginLeft: "-7px", marginRight: "3px" }}
        />
      </>
    ),
  };

  return (
    <Box
      sx={{
        width: "93px",
        height: "22px",
        background: bgColors[type],
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "16px",
        ...sx,
      }}
    >
      {icons[type]}
      <Typography
        sx={{ fontSize: "10px!important" }}
        color={`${colors[type]} !important`}
      >
        {type}
      </Typography>
    </Box>
  );
};
