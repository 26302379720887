// The order tab names of the NAVIGATION_PATHS is IMPORTANT!
export const NAVIGATION_PATHS = [
  "",
  "orders",
  "catalog",
  "customers",
  "representatives",
  "discounts",
  "tasks",
  "routes",
  "reports",
  "cart",
  "notifications",
  "",
  "conversations",
];
export const DATA_KEYS = {
  SYNC_DATA: "simplyDepoSyncData",
  APP_CUSTOMER_ID: "app_customer_id",
  DRAFT_ACTIONS: "simplyDepoDraftActions",
};

export const TAGS_TYPE = {
  CUSTOMER: "customer",
  PRODUCT: "product",
  ORDERS: "order",
};

export const UNCATEGORIZED_GROUP = {
  name: "Uncategorized",
  id: "uncategorized-group",
};

export const UNCATEGORIZED_TERRITORY = {
  name: "Uncategorized",
  id: "uncategorized-territory",
};

export const UNCATEGORIZED_CATEGORY = {
  name: "Uncategorized",
  id: "uncategorized-category",
};

export const MANUFACTURER_STATUSES = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  INACTIVE_FOR_CUSTOMERS: "INACTIVE_FOR_CUSTOMERS",
};

export const SCROLL_LIMIT = 15;
export const SCROLL_LIMIT_GALLERY = 24;
export const SCROLL_LIMIT_CUSTOMERS = 10;
export const SCROLL_LIMIT_DISCOUNT = 15;
export const SCROLL_LIMIT_CONTACTS = 3;
export const SCROLL_LIMIT_NOTES = 3;
export const SCROLL_LIMIT_ACTIVITIES = 10;
export const SCROLL_LIMIT_INVITES = 16;
export const SCROLL_LIMIT_PRODUCT_TAGS = 10;
export const SCROLL_LIMIT_DASHBOARD_ORDERS = 15;
export const SCROLL_LIMIT_DASHBOARD_ACTIVITIES = 15;
export const SCROLL_LIMIT_BILLING_PLAN = 5;
export const SCROLL_LIMIT_TASKS = 15;
export const SCROLL_LIMIT_FORMS = 15;

export const LIMIT_UPLOAD_IMAGES = 6;

export const ROLES = [
  { SALES: "Sales Rep" },
  { DISTRIBUTOR: "Distributor representative" },
  { MERCHANDISER: "Merchandiser" },
  { DELIVERY: "Delivery Rep" },
];

export const REP_ROLES = {
  SALES: "Sales",
  MERCHANDISER: "Merchandiser",
  DELIVERY: "Delivery",
  THIRD_PARTY: "3rd Party",
};

export const ACTIVITY_TYPES = [
  { id: "note_added", name: "Note added" },
  { id: "checked_in", name: "Checked in" },
  { id: "photo_added", name: "Photo added" },
];

export const COUNTRY_LIST = [
  { value: "US", name: "USA" },
  { value: "CA", name: "Canada" },
];

export const CANADAS_TIME_ZONES = [
  {
    name: "(UTC-5) Atikokan",
    value: "America/Atikokan",
  },
  {
    name: "(UTC-4) Blanc-Sablon",
    value: "America/Blanc-Sablon",
  },
  {
    name: "(UTC-7) Cambridge Bay",
    value: "America/Cambridge_Bay",
  },
  {
    name: "(UTC-7) Creston",
    value: "America/Creston",
  },
  {
    name: "(UTC-7) Dawson",
    value: "America/Dawson",
  },
  {
    name: "(UTC-7) Dawson Creek",
    value: "America/Dawson_Creek",
  },
  {
    name: "(UTC-7) Edmonton",
    value: "America/Edmonton",
  },
  {
    name: "(UTC-7) Fort Nelson",
    value: "America/Fort_Nelson",
  },
  {
    name: "(UTC-4) Glace Bay",
    value: "America/Glace_Bay",
  },
  {
    name: "(UTC-4) Goose Bay",
    value: "America/Goose_Bay",
  },
  {
    name: "(UTC-4) Halifax",
    value: "America/Halifax",
  },
  {
    name: "(UTC-7) Inuvik",
    value: "America/Inuvik",
  },
  {
    name: "(UTC-5) Iqaluit",
    value: "America/Iqaluit",
  },
  {
    name: "(UTC-4) Moncton",
    value: "America/Moncton",
  },
  {
    name: "(UTC-5) Panama",
    value: "America/Panama",
  },
  {
    name: "(UTC-7) Phoenix",
    value: "America/Phoenix",
  },
  {
    name: "(UTC-4) Puerto Rico",
    value: "America/Puerto_Rico",
  },
  {
    name: "(UTC-6) Rankin Inlet",
    value: "America/Rankin_Inlet",
  },
  {
    name: "(UTC-6) Regina",
    value: "America/Regina",
  },
  {
    name: "(UTC-6) Resolute",
    value: "America/Resolute",
  },
  {
    name: "(UTC-3:30) St Johns",
    value: "America/St_Johns",
  },
  {
    name: "(UTC-6) Swift Current",
    value: "America/Swift_Current",
  },
  {
    name: "(UTC-5) Toronto",
    value: "America/Toronto",
  },
  {
    name: "(UTC-8) Vancouver",
    value: "America/Vancouver",
  },
  {
    name: "(UTC-7) Whitehorse",
    value: "America/Whitehorse",
  },
  {
    name: "(UTC-6) Winnipeg",
    value: "America/Winnipeg",
  },
];

export const TIME_ZONES = [
  { value: "America/Adak", name: "(UTC-10:00) Adak" },
  { value: "America/Anchorage", name: "(UTC-09:00) Anchorage" },
  { value: "America/Boise", name: "(UTC-07:00) Boise" },
  { value: "America/Chicago", name: "(UTC-06:00) Chicago" },
  { value: "America/Denver", name: "(UTC-07:00) Denver" },
  { value: "America/Detroit", name: "(UTC-05:00) Detroit" },
  {
    value: "America/Indiana/Indianapolis",
    name: "(UTC-05:00) Indiana / Indianapolis",
  },
  { value: "America/Indiana/Knox", name: "(UTC-06:00) Indiana / Knox" },
  { value: "America/Indiana/Marengo", name: "(UTC-05:00) Indiana / Marengo" },
  {
    value: "America/Indiana/Petersburg",
    name: "(UTC-05:00) Indiana / Petersburg",
  },
  {
    value: "America/Indiana/Tell_City",
    name: "(UTC-06:00) Indiana / Tell City",
  },
  { value: "America/Indiana/Vevay", name: "(UTC-05:00) Indiana / Vevay" },
  {
    value: "America/Indiana/Vincennes",
    name: "(UTC-05:00) Indiana / Vincennes",
  },
  { value: "America/Indiana/Winamac", name: "(UTC-05:00) Indiana / Winamac" },
  { value: "America/Juneau", name: "(UTC-09:00) Juneau" },
  {
    value: "America/Kentucky/Louisville",
    name: "(UTC-05:00) Kentucky / Louisville",
  },
  {
    value: "America/Kentucky/Monticello",
    name: "(UTC-05:00) Kentucky / Monticello",
  },
  { value: "America/Los_Angeles", name: "(UTC-08:00) Los Angeles" },
  { value: "America/Menominee", name: "(UTC-06:00) Menominee" },
  { value: "America/Metlakatla", name: "(UTC-09:00) Metlakatla" },
  { value: "America/New_York", name: "(UTC-05:00) New York" },
  { value: "America/Nome", name: "(UTC-09:00) Nome" },
  {
    value: "America/North_Dakota/Beulah",
    name: "(UTC-06:00) North Dakota / Beulah",
  },
  {
    value: "America/North_Dakota/Center",
    name: "(UTC-06:00) North Dakota / Center",
  },
  {
    value: "America/North_Dakota/New_Salem",
    name: "(UTC-06:00) North Dakota / New Salem",
  },
  { value: "America/Phoenix", name: "(UTC-07:00) Phoenix" },
  { value: "America/Sitka", name: "(UTC-09:00) Sitka" },
  { value: "America/Yakutat", name: "(UTC-09:00) Yakutat" },
  { value: "Pacific/Honolulu", name: "(UTC-10:00) Honolulu" },
];

export const FILTER_DATES = [
  { name: "Today" },
  { name: "Yesterday" },
  { name: "This week" },
  { name: "Last week" },
  { name: "This month" },
  { name: "Last month" },
  { name: "This year" },
  { name: "Last year" },
  { name: "All time" },
];

export const INDUSTRIES = [
  "Food & Beverage",
  "Health & Beauty",
  "General Consumer Goods",
  "Retail Services",
  "Beer, Wine, & Spirits",
  "Cannabis",
  "Broker Or Distributor",
  "Other",
];

export const TERMS_OF_SERVICE_LINK =
  "https://simplydepo.com/terms-of-service-mobile-application";
export const PRIVACY_POLICY_LINK = "https://simplydepo.com/privacy-notice";

export const COUNTRY_PHONE_CODES = [
  {
    code: "US",
    phone_code: "+1",
    label: "United States",
    format: "(###) ### ####",
    digits: 11,
  },
];

export const CANADA_PHONE_CODES = [
  {
    code: "CA",
    phone_code: "+1",
    label: "Canada",
    format: "(###) ### ####",
    digits: 11,
  },
];

export const forbiddenUrls = [
  "/users/distributors/dashboard/top-summaries",
  "/users/distributors/dashboard/orders",
  "/users/distributors/dashboard/bottom-left-part",
  //"/users/distributors/dashboard/activities",
  "/users/distributors/dashboard/chart",
  "/users/distributors/dashboard/get-active-promotions",
  "/users/distributors/dashboard/get-product-performance",
  "orders/total-summaries",
];

export const GOOGLE_PLAY_LINK =
  "https://play.google.com/store/apps/details?id=com.simplyDepoMerchandise";
export const APP_STORE_LINK =
  "https://apps.apple.com/ua/app/simplydepo-reps/id1624883055?lang=us";

export const APP_ENVS = {
  dev: "development",
  preprod: "pre-production",
  prod: "production",
};
export const MAX_UPLOAD_LICENSES_FILE_SIZE_MB = 10;

export const MAX_UPLOAD_FILE_SIZE_CHAT_MB = 20;

export const MAX_UPLOAD_FILE_SIZE_DISTRIBUTIVE_MB = 20;
export const MAX_UPLOAD_FILE_SIZE_PRODUCT_MB = 20;

export const MAX_UPLOAD_FILE_RESOLUTION_DISTRIBUTIVE_MP = 20;
export const MAX_UPLOAD_FILE_RESOLUTION_PRODUCT_MP = 20;

export const LICENSES = [
  { type: "EIN", name: "EIN (Employer Identification Number)" },
  { type: "SBL", name: "SBL (State Business License)" },
  { type: "SRL", name: "SRL (State Resell License)" },
  { type: "ABC", name: "ABC (Alcohol License - Alcohol Board Control)" },
  { type: "COI", name: "COI (Certificate of Insurance)" },
  { type: "USDOT", name: "USDOT (Department of Transportation)" },
  { type: "MCP", name: "MCP (Motor Carrier Permit)" },
  { type: "SCIN", name: "SCIN (State Carrier ID#)" },
  { type: "OTHER", name: "Other" },
];

export const shippingLinks = {
  FedEx: "https://www.fedex.com/fedextrack/?tracknumbers=",
  UPS: "https://www.ups.com/track?track=yes&trackNums=",
  USPS: "https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum=",
};

export const CUSTOMER_STATUS_FILTERS = {
  active: "ACTIVE",
  inactive: "INACTIVE",
  prospect: "PROSPECT",
};

export const PRODUCT_TYPE_INVENTORY = {
  inventory: "INVENTORY",
  non_inventory: "NON_INVENTORY",
};

export const DRAFT_ORDER_TYPES = {
  open: "OPEN",
  closed: "CLOSED",
};

export const USED_ON_TYPE_ORDER_TYPES = {
  direct: "DIRECT",
  third_party: "THIRD_PARTY",
  draft_order: "DRAFT_ORDER",
};

export const IMAGE_EXTENSION = ".jpeg";

export const GOOGLE_PLACE_LINK = "https://www.google.com/maps/place/";

export const NAME_VIEW_DIALOGS = {
  ORDERS: "orders",
  CATALOG: "catalog",
  EDIT_FILTER: "editFilter",
  GALLERY: "gallery",
  CUSTOMERS: "customers",
  TASKS: "tasks",
  FORMS: "forms",
};

export const CUSTOM_FILTERS_TYPES = {
  ORDERS: {
    Direct: "DIRECT_ORDERS",
    "3rd Party": "THIRD_PARTY_ORDERS",
    Drafts: "DRAFT_ORDERS",
  },
  CATALOG: {
    Products: "PRODUCTS",
  },
  GALLERY: "GALLERY",
  CUSTOMERS: "CUSTOMERS",
  TASKS: "TASKS",
  FORMS: "FORMS",
};

export const EMAIL_REGEXP =
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d)|(([a-z]|\d)([a-z]|\d|-|\.|_|~)*([a-z]|\d)))\.)+(([a-z])|(([a-z])([a-z]|\d|-|\.|_|~)*([a-z])))$/i; // eslint-disable-line

export const ADMIN_ONLY_VIEW_MESSAGE = (
  <span>
    You have no permission.
    <br />
    Please contact your admin
  </span>
);
export const ADMIN_ONLY_VIEW_MESSAGE_PAGE = "Only admin can view this page";
export const ADMIN_ONLY_DELETE_REPS = "Only admin can delete reps";

export const PRICE_LIST_TYPES = {
  STANDARD: "Standard",
};

export const VALIDATION_ADDRESS_ERROR =
  "Validate address by selecting from the drop down";
