/* eslint-disable no-unreachable */
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import pluralize from "pluralize";
import { useLocation } from "react-router-dom";
import { isEqual, sortBy } from "lodash";
import { validationSchema } from "./CustomerPage.validations";
import {
  CUSTOMER_STATUS_FILTERS,
  SCROLL_LIMIT,
  SCROLL_LIMIT_CUSTOMERS,
} from "utils/constants";
import { error } from "utils/notifications";
import {
  checkCustomerAddressService,
  checkCustomerInfoAvailabilityService,
  customersProceedWithDuplicates,
  deleteCustomersService,
  getCustomerByIdService,
  getCustomersService,
  getParentCustomersService,
} from "services/customers";
import { getAddressComponents } from "Pages/CustomersPage/components/AddressField/AddressField.helpers";
import { territoryListSelector } from "redux/selectors/territory";
import {
  createCustomerAction,
  updateCustomerAction,
} from "redux/actions/customers";
import { LIMIT_ADDED_TAGS, defaultValues } from "./CustomerPage.constants";
import {
  createCategoryMap,
  customerReviewImages,
  displayNameSpecialChars,
  isActiveCustomer,
  preparingData,
  setIncomingParentCustomerId,
  setIncomingReceivingHours,
  setIncomingRepresentatives,
} from "./CustomerPage.helpers";
import {
  containsEvery,
  containsSpecialChars,
  setWeekDays,
  sliceCountry,
  useAdmin,
} from "helpers/helpers";
import { useDebounce } from "helpers/hooks";
import {
  setConfirmPathAction,
  setEditTypeAction,
  setFormChangedAction,
} from "redux/actions/confirmDialogs";
import { confirmDialogFormChangedSelector } from "redux/selectors/confirmDialogs";
import { usePayTermsActions } from "helpers/usePayTermsActions";
import { getCurrentUser } from "helpers/auth";
import { setCurrentUser } from "redux/actions/auth";
import { currentUserSelector } from "redux/selectors/auth";
import { useRedirect } from "helpers/useRedirect";
import { GlobalPageContext } from "Pages/MasterPage/MasterPage";
import { getCategoriesByCustomerIdService } from "services/categories";
import { getProductsByCustomerIdService } from "services/products";

const selector = createSelector(
  territoryListSelector,
  confirmDialogFormChangedSelector,
  currentUserSelector,
  (territoryList, formChanged, currentUser) => ({
    territoryList,
    formChanged,
    currentUser,
  })
);

export const useCustomerPage = ({ customerId, state }) => {
  const adminIsAllowed = true;
  const isAdmin = useAdmin(adminIsAllowed);
  const { pathname } = useLocation();
  const { setGlobalLoading } = useContext(GlobalPageContext);

  const isEdit = !!customerId;

  const { territoryList, formChanged, currentUser } = useSelector(selector);
  const dispatch = useDispatch();
  const navigate = useRedirect();

  const [loadingCustomer, setCustomerLoading] = useState(false);

  const [isOpenPaymentTermModal, setOpenPaymentTermModal] = useState(false);
  const [isOpenNewTerritoryModal, setOpenNewTerritoryModal] = useState(false);
  const [isOpenCategoriesPopup, setIsOpenCategoriesPopup] = useState(false);
  const [shippingDuplicates, setShippingDuplicates] = useState([]);
  const [popupDuplicates, setPopupDuplicates] = useState([]);
  const [duplicateOpen, setDuplicateOpen] = useState(false);
  const [toggleDetails, setToggleDetails] = useState("");
  const [mapOpen, setMapOpen] = useState(null);
  const [nameTooltipOpen, setNameTooltipOpen] = useState(false);
  const [nameWarnText, setNameWarnText] = useState("");
  const [nameValidation, setNameValidation] = useState(false);
  const [billingValidation, setBillingValidation] = useState(false);
  const [shippingValidation, setShippingValidation] = useState(false);
  const [checkedCustomers, setCheckedCustomers] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [haveQBConnect, setHaveQBConnect] = useState(false);
  const [isDuplicateAlreadyOpen, setIsDuplicateAlreadyOpen] = useState(false);
  const [proceedCheckedCustomer, setProceedCheckedCustomer] = useState([]);
  const [availableAssignCategories, setAvailableAssignCategories] = useState(
    []
  );

  const { list: paytermsList, handleFetch: handleFetchPayTerms } =
    usePayTermsActions({
      params: {
        status: JSON.stringify(["ACTIVE", "INACTIVE_FOR_CUSTOMERS"]),
        filter_advance_payment: true,
        limit: null,
        page: null,
      },
    });

  const checkForProceedCustomer = useCallback(
    (newCustomers) => {
      if (proceedCheckedCustomer.length && newCustomers.length) {
        const proceededCustomersIds = proceedCheckedCustomer.map(
          ({ id }) => id
        );

        return newCustomers.filter(
          ({ id }) => !proceededCustomersIds.includes(id)
        );
      }
      return newCustomers;
    },
    [proceedCheckedCustomer]
  );

  const handleOpenDuplicates = useCallback(
    (duplicates) => {
      setIsDuplicateAlreadyOpen(true);
      const dublicatesList = checkForProceedCustomer(duplicates);
      setPopupDuplicates(dublicatesList);
      setDuplicateOpen(true);
    },
    [checkForProceedCustomer]
  );

  const resetShippingAddressCoordinates = () => {
    setValue("shippingAddress.lat", null);
    setValue("shippingAddress.lng", null);
  };

  const resetBillingAddressCoordinates = () => {
    setValue("billingAddress.lat", null);
    setValue("billingAddress.lng", null);
  };

  const {
    control,
    setValue,
    formState: { errors, isDirty },
    setError,
    clearErrors,
    handleSubmit,
    reset,
    trigger,
  } = useForm({
    mode: "onSubmit",
    shouldFocusError: false,
    defaultValues: { ...defaultValues },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({ control });

  const validationNames = Object.keys(validationSchema()?.fields);

  const [editRawData, setEditRawData] = useState(null);

  const preparePhone = (num) => {
    if (!num) return "";
    if (num.startsWith("(") && num.length === 14)
      return `+1${num.replace(/\s|\(|\)|-/g, "")}`;
    const clearNumber = num.replace(/[^0-9]/g, "");
    return `+${clearNumber}`;
  };

  const handleTrackChanges = useCallback(() => {
    dispatch(setFormChangedAction(isDirty));
  }, [dispatch, isDirty]);

  useEffect(handleTrackChanges, [handleTrackChanges]);

  const initConfirmDialogState = useCallback(() => {
    dispatch(setEditTypeAction("customer", !customerId));

    dispatch(
      setConfirmPathAction(
        customerId && !state?.redirectToList
          ? `/customers/${customerId}`
          : "/customers"
      )
    );
  }, [customerId, dispatch, state?.redirectToList]);

  const destroyConfirmDialogState = useCallback(() => {
    dispatch(setEditTypeAction("", false));
    dispatch(setConfirmPathAction(""));
  }, [dispatch]);

  useEffect(() => {
    initConfirmDialogState();

    return () => destroyConfirmDialogState();
  }, [destroyConfirmDialogState, initConfirmDialogState]);

  useEffect(() => {
    reset(defaultValues);
    clearErrors();
  }, [clearErrors, dispatch, pathname, reset]);

  const handleOpenDuplicateByParam = useCallback(
    (customers) => {
      if (state?.checkDuplicateInfo && !isDuplicateAlreadyOpen) {
        return handleOpenDuplicates(customers, "shippingAddress");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formField.shippingIsSame, isDuplicateAlreadyOpen, handleOpenDuplicates]
  );

  useEffect(() => {
    // After redirecting from the customers page due to missing info, the address section needs to be opened
    if (formField?.checkMissingInfo) {
      if (errors?.billingAddress && !formField?.shippingIsSame) {
        setToggleDetails("billingAddress");
        setValue("checkMissingInfo", false);
        return;
      }

      if (errors?.shippingAddress) {
        setToggleDetails("shippingAddress");
        setValue("checkMissingInfo", false);
        return;
      }
    }
  }, [
    errors?.billingAddress,
    errors?.shippingAddress,
    formField?.checkMissingInfo,
    formField?.shippingIsSame,
    setValue,
  ]);

  useEffect(() => {
    if (customerId) {
      setCustomerLoading(true);
      getCustomerByIdService(customerId)
        .then((res) => {
          setEditRawData(res);
          const {
            name,
            status,
            customId,
            displayName,
            primaryPaymentMethod,
            receivingHours,
            paymentTermsId,
            paymentTermsDuplicate,
            percentDiscount,
            territoryId,
            email,
            website,
            phone,
            businessFax,
            id,
            billingAddress,
            shippingAddress,
            parentCustomerId,
            parentCustomer,
            billToParentCustomer,
            assignedRepresentatives,
            contacts,
            documents,
            tags,
            activeOrderCount,
            countSubCustomerPendingOrders,
            proceededWithDuplicatedFields,
            productCategories,
            rating,
            price,
            categories,
            stats,
            priceList,
            _count,
            showAllCategories,
            showAllProducts,
            allActiveProductsCount,
          } = res || {};

          const selectedTagsList = tags.map(({ tag }) => tag);
          const paramUserStatus = state && state.status;
          const active = isActiveCustomer(status);

          reset({
            ...formField,
            name,
            id: id || "",
            status: paramUserStatus || status,
            statusData: {
              isDisabled:
                (!!paramUserStatus || active) &&
                (activeOrderCount > 0 || countSubCustomerPendingOrders > 0),
              message: "Customers with pending orders can't be archived.",
              showTooltip: false,
            },
            customId: customId?.customId || defaultValues.customId,
            addDisplayName: !!displayName,
            displayName: displayName || defaultValues.displayName,
            paymentTermsDuplicate: paymentTermsDuplicate,
            paymentTermsId:
              paymentTermsDuplicate?.paymentTermsId ||
              paymentTermsId ||
              defaultValues.paymentTermsId,
            percentDiscount: percentDiscount || defaultValues.percentDiscount,
            isASubBusiness:
              parentCustomerId && !parentCustomer?.isUncategorized,
            billParentGroup:
              billToParentCustomer || defaultValues.billParentGroup,
            parentCustomerId: setIncomingParentCustomerId({
              parentCustomerId,
              parentCustomer,
            }),
            disableSubCustomer: parentCustomerId === null,
            parentCustomer: parentCustomer || null,
            territoryId: territoryId || defaultValues.territoryId,
            email: email || defaultValues.email,
            website: website || "",
            phone: preparePhone(phone || defaultValues.phone),
            businessFax: preparePhone(businessFax, defaultValues.businessFax),
            shippingIsSame: isEqual(billingAddress, shippingAddress),
            billingAddress,
            shippingAddress,
            primaryPaymentMethod:
              primaryPaymentMethod || defaultValues.primaryPaymentMethod,
            weekDays: receivingHours
              ? setIncomingReceivingHours(receivingHours)
              : defaultValues.weekDays,
            representatives: assignedRepresentatives?.length
              ? setIncomingRepresentatives(assignedRepresentatives)
              : defaultValues.representatives,
            contacts: contacts?.length ? contacts : defaultValues.contacts,
            licenses: documents || defaultValues.licenses,
            selectedTagsList,
            proceededWithDuplicatedFields:
              proceededWithDuplicatedFields ||
              defaultValues.proceededWithDuplicatedFields,
            productCategories:
              productCategories || defaultValues.productCategories,
            rating: rating || defaultValues.rating,
            price: price || defaultValues.price,
            reviews: stats ? stats.totalRatings : defaultValues.reviews,
            categories: categories.length
              ? categories
              : defaultValues.categories,
            priceListId: priceList?.id
              ? priceList?.id
              : defaultValues.priceListId,
            ...(!!priceList?.id && {
              priceList,
            }),
            showAllCategories: showAllCategories || false,
            isAllUncheckedCategories: !showAllCategories || false,
            isAllUncheckedProducts: !showAllProducts || false,
            showAllProducts: showAllProducts || false,
            allActiveProductsCount: allActiveProductsCount || 0,
            selectedProductsCount: _count?.products,
            ...(!!customerId &&
              !showAllCategories && {
                selectedCategoriesCount: _count?.productCategories,
              }),
            _count,
          });

          // eslint-disable-next-line no-extra-boolean-cast
          if (!!paramUserStatus) {
            dispatch(setFormChangedAction(true));
            initConfirmDialogState();
          }
          if (state?.checkMissingInfo) {
            setValue("checkMissingInfo", true);
            trigger();
          }
        })
        .finally(() => {
          setCustomerLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, reset]);

  const defaultPaymentTerm = useMemo(
    () => paytermsList?.find((term) => term?.defaultTerm),
    [paytermsList]
  );

  useEffect(
    function setDefaultPaymentTerm() {
      if (!formField?.paymentTermsId && defaultPaymentTerm?.id)
        setValue("paymentTermsId", defaultPaymentTerm?.id);
    },
    [defaultPaymentTerm?.id, formField?.paymentTermsId, setValue]
  );

  const nameDebounced = useDebounce(formField.name, 500);
  const customIdDebounced = useDebounce(formField.customId, 500);
  const displayNameIdDebounced = useDebounce(formField.displayName, 500);

  const [customIdModal, setCustomIdModal] = useState(false);
  const [customIdValidation, setCustomIdValidation] = useState(false);

  const shippingDebounced = useDebounce(
    formField.shippingAddress?.formatted_address,
    500
  );

  const checkedCustomersIds = useCallback(() => {
    if (!checkedCustomers.length) return;
    return checkedCustomers.map(({ id }) => id);
  }, [checkedCustomers]);

  const checkDuplicateCustomers = useCallback(
    (customers) => {
      const filtredCustomers = customers.filter(
        (customer) => customer.id !== formField.id
      );

      if (checkedCustomers.length) {
        const checkedIds = checkedCustomersIds();
        return filtredCustomers.filter((item) => !checkedIds.includes(item.id));
      }
      return filtredCustomers;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkedCustomersIds, checkedCustomers.length, formField.id]
  );

  const setShippingAddressAndBillingAddressTheSame = useCallback(() => {
    setValue(
      "billingAddress",
      { ...formField?.shippingAddress },
      { shouldDirty: true }
    );
    clearErrors("billingAddress");
  }, [formField?.shippingAddress, setValue, clearErrors]);

  const [googleAddress, setGoogleAddress] = useState({
    billingAddress: null,
    shippingAddress: null,
  });

  const filteredCategoriesByName = (list) => {
    if (!list.length) return [];
    return list.filter((item) => item !== "point_of_interest").slice(0, 2);
  };

  const preparedProductCategories = useMemo(
    () => createCategoryMap(formField.productCategories),
    [formField.productCategories]
  );

  const handleSetCustomer = (customerInfo) => {
    const images = customerReviewImages(customerInfo?.photos);
    const { street, state, city, zip } = getAddressComponents(customerInfo);
    const { weekday_text } = customerInfo?.current_opening_hours || {};

    const getWeekDays = setWeekDays(weekday_text);

    setValue("weekDays", getWeekDays);

    setValue("name", customerInfo?.name, { shouldDirty: true });
    setValue("images", images);
    setValue("placeId", customerInfo?.place_id);
    setValue("rating", customerInfo?.rating, { shouldDirty: true });
    setValue("price", customerInfo?.price_level, { shouldDirty: true });
    setValue("reviews", customerInfo?.reviews?.length, { shouldDirty: true });
    setValue(
      "categories",
      customerInfo?.types?.length
        ? filteredCategoriesByName(customerInfo?.types)
        : [],
      {
        shouldDirty: true,
      }
    );

    setValue("parentCustomer", {}, { shouldDirty: true });
    setValue("parentCustomerId", "", { shouldDirty: true });

    const preparedNumber = (n) => {
      if (!n) return "";
      if (n.startsWith("(") && n.length === 14)
        return `+1${n.replace(/\s|\(|\)|-/g, "")}`;
      return n;
    };

    setValue("phone", preparedNumber(customerInfo.formatted_phone_number), {
      shouldDirty: true,
    });
    setValue("website", customerInfo.website || "", { shouldDirty: true });

    const address = {
      formatted_address: sliceCountry(customerInfo.formatted_address),
      lat: customerInfo.geometry.location.lat(),
      lng: customerInfo.geometry.location.lng(),
      street,
      state,
      city,
      zip,
    };

    if (setGoogleAddress)
      setGoogleAddress((prev) => ({
        ...prev,
        shippingAddress: { ...address },
      }));
    setValue("shippingAddress", address, { shouldDirty: true });
    if (formField.proceededWithDuplicatedFields) {
      setValue("proceededWithDuplicatedFields", false);
    }
  };

  const handleSetDisplayName = (customerInfo) => {
    setValue("displayName", customerInfo.name, { shouldDirty: true });
  };

  const handleClearErrorByField = useCallback(
    (fieldName) => {
      if (errors && errors[fieldName] && errors[fieldName].type === "custom")
        return clearErrors(fieldName);
      return;
    },
    [clearErrors, errors]
  );

  useEffect(() => {
    if (
      !displayNameIdDebounced ||
      displayNameIdDebounced?.toLowerCase() === nameDebounced?.toLowerCase()
    )
      return;

    const containsSpecial = displayNameSpecialChars(displayNameIdDebounced);

    if (containsSpecial && displayNameIdDebounced) {
      return setError("displayName", {
        type: "specialCharacter",
        message: "Invalid character used",
      });
    }

    return handleClearErrorByField("displayName");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayNameIdDebounced, handleClearErrorByField, setError]);

  useEffect(() => {
    if (!formField.addDisplayName) {
      handleClearErrorByField("displayName");
      setValue("displayName", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formField.addDisplayName]);

  useEffect(() => {
    if (!customIdDebounced) return;

    checkCustomerInfoAvailabilityService({
      custom_id: customIdDebounced,
    }).then((res) => {
      if (!res.available && res.customerWithCustomId?.id !== formField.id) {
        setCustomIdModal(true);
        setError("customId", {
          type: "custom",
          message: "Custom ID is already taken",
        });
        setCustomIdValidation(true);
        return;
      }
      setCustomIdValidation(false);
      clearErrors("customId");
      setCustomIdModal(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customIdDebounced, setError, clearErrors, setCustomIdValidation]);

  useEffect(() => {
    if (!nameDebounced) return;
    const containsSpecial = containsSpecialChars(nameDebounced);

    if (containsSpecial) {
      return setError("name", {
        type: "specialCharacter",
        message: "Invalid character used",
        valueRef: nameDebounced,
      });
    }

    checkCustomerInfoAvailabilityService({
      name: nameDebounced?.toLowerCase().trim(),
    }).then((res) => {
      if (!res?.available && res?.customerWithName?.id !== formField?.id) {
        const createdName =
          res?.customerWithName?.displayName || res?.customerWithName?.name;
        const isActive = isActiveCustomer(res?.customerWithName?.status);
        const createdId = res?.customerWithName?.customId?.customId;
        const userStatus = isActive ? "active" : "inactive";

        setError("name", {
          type: `${userStatus}_${res?.customerWithName?.id}`,
          message: `${createdName} (ID: ${createdId})`,
        });
        return;
      }
      clearErrors("name");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearErrors, nameDebounced, setError, setNameValidation]);

  const shippingAddressValidation = useCallback(
    (customersList) => {
      const customers = checkDuplicateCustomers(customersList);
      setShippingDuplicates(customers);
      if (!customers.length) {
        setShippingDuplicates([]);
        return clearErrors("shippingAddress.formatted_address");
      }

      if (!isDuplicateAlreadyOpen) {
        handleOpenDuplicateByParam(customers);
      }
      setIsDuplicateAlreadyOpen(true);
      setError("shippingAddress.formatted_address", {
        type: "duplicate",
        message: `${pluralize("duplicate", customers.length, true)} found`,
      });
      setShippingValidation(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkDuplicateCustomers, clearErrors, setError, isDuplicateAlreadyOpen]
  );

  const handleCheckShippingAddress = useCallback(() => {
    checkCustomerAddressService({
      formatted_address: shippingDebounced,
      type: "shipping",
      proceeded_with_duplicated_fields: formField.proceededWithDuplicatedFields,
    }).then((res) => {
      const customersList = checkForProceedCustomer(res.customers);

      setHaveQBConnect(!!res.quickBooksTokens);
      shippingAddressValidation(customersList);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingDebounced, checkForProceedCustomer]);

  useEffect(() => {
    if (formField?.shippingIsSame)
      return setShippingAddressAndBillingAddressTheSame();
  }, [
    formField?.shippingIsSame,
    formField?.shippingAddress?.formatted_address,
    setShippingAddressAndBillingAddressTheSame,
    setValue,
  ]);

  useEffect(() => {
    if (shippingDebounced) handleCheckShippingAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shippingDebounced,
    handleCheckShippingAddress,
    setError,
    setShippingDuplicates,
    clearErrors,
    setShippingValidation,
  ]);
  const handleSendCheckedCustomers = useCallback(async () => {
    const customerIds = checkedCustomersIds();
    if (!customerIds || !customerIds.length) return;

    await customersProceedWithDuplicates({
      customerIds,
      proceededWithDuplicatedFields: true,
    }).catch((error) =>
      // eslint-disable-next-line no-console
      console.log("customersProceedWithDuplicates", error)
    );
  }, [checkedCustomersIds]);

  useEffect(() => {
    const { billingAddress, shippingAddress } = formField;

    // if (billingAddress?.formatted_address)
    // clearErrors("billingAddress.formatted_address");
    if (billingAddress?.state) clearErrors("billingAddress.state");
    if (billingAddress?.city) clearErrors("billingAddress.city");
    if (billingAddress?.street) clearErrors("billingAddress.street");
    if (billingAddress?.zip) clearErrors("billingAddress.zip");
    if (billingAddress?.lat) clearErrors("billingAddress.lat");
    if (billingAddress?.lng) clearErrors("billingAddress.lng");

    // if (shippingAddress?.formatted_address)
    //   clearErrors("shippingAddress.formatted_address");
    if (shippingAddress?.state) clearErrors("shippingAddress.state");
    if (shippingAddress?.city) clearErrors("shippingAddress.city");
    if (shippingAddress?.street) clearErrors("shippingAddress.street");
    if (shippingAddress?.zip) clearErrors("shippingAddress.zip");
    if (shippingAddress?.lat) clearErrors("shippingAddress.lat");
    if (shippingAddress?.lng) clearErrors("shippingAddress.lng");
  }, [clearErrors, formField]);

  const handleCheckCustomers = useCallback(
    (customer) => {
      const existingItem = checkedCustomers.find(
        (item) => item.id === customer.id
      );

      if (existingItem) {
        const filtredCustomers = checkedCustomers.filter(
          ({ id }) => id !== customer.id
        );
        return setCheckedCustomers(filtredCustomers);
      }

      return setCheckedCustomers((prev) => [...prev, customer]);
    },
    [checkedCustomers]
  );

  const handleProceedWithDuplicate = useCallback(
    (type) => {
      shippingAddressValidation(popupDuplicates);
      setProceedCheckedCustomer(checkedCustomers);

      if (popupDuplicates.length === 1 || !errors.shippingAddress) {
        if (popupDuplicates.length === 1) setCheckedCustomers(popupDuplicates);
        setValue("proceededWithDuplicatedFields", true);
        setDuplicateOpen(false);
        setPopupDuplicates([]);

        clearErrors(type);

        setShippingDuplicates([]);
        return;
      }

      setDuplicateOpen(false);
    },
    [
      shippingAddressValidation,
      popupDuplicates,
      checkedCustomers,
      errors.shippingAddress,
      setValue,
      clearErrors,
    ]
  );

  const deleteDublicates = useCallback(() => {
    if (!checkedCustomers.length && popupDuplicates.length > 1) return;

    setDeleteLoading(true);
    let ids = [];

    if (popupDuplicates.length === 1) {
      ids = [popupDuplicates[0].id];
    } else {
      const checkedIds = checkedCustomersIds();
      ids = checkedIds;
    }

    deleteCustomersService(ids)
      .then(() => {
        handleCheckShippingAddress();

        if (proceedCheckedCustomer.length) {
          setValue("proceededWithDuplicatedFields", true);
        }
      })
      .catch((err) => {
        error(err?.message || "Something went wrong.");
      })
      .finally(() => {
        setDuplicateOpen(false);
        setPopupDuplicates([]);
        if (!proceedCheckedCustomer.length) {
          setCheckedCustomers([]);
        }
        setDeleteLoading(false);
      });
  }, [
    checkedCustomersIds,
    checkedCustomers.length,
    handleCheckShippingAddress,
    popupDuplicates,
    proceedCheckedCustomer.length,
    setValue,
  ]);

  const handleCloseDuplicatePopup = () => {
    setDuplicateOpen(false);
    setCheckedCustomers([]);
  };

  const handleSubmitError = useCallback(
    (err) => {
      const errMsg = "Website must contain a valid domain name";
      const isWebsiteError = err?.response?.data?.message
        ? err.response.data.message.includes("body.website")
        : false;

      error(
        isWebsiteError
          ? errMsg
          : err?.response?.data?.message || "Something went wrong."
      );
      if (isWebsiteError) setError("website", { message: errMsg });
      // eslint-disable-next-line no-console
      console.error(err?.response?.data);
    },
    [setError]
  );

  const getAllCategoriesForPopup = useCallback(
    async (skipLoading = false) => {
      if (
        formField.productCategories.length ||
        formField.selectedCategoriesCount === 0
      ) {
        return setIsOpenCategoriesPopup(true);
      }

      if (!skipLoading) setGlobalLoading(true);

      try {
        const res = await getCategoriesByCustomerIdService(customerId);
        const rows = res || [];

        setValue("productCategories", rows);
        setAvailableAssignCategories(rows);

        if (!skipLoading) setIsOpenCategoriesPopup(true);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong.");
      } finally {
        setGlobalLoading(false);
      }
    },
    [
      customerId,
      formField.productCategories.length,
      formField.selectedCategoriesCount,
      setGlobalLoading,
      setValue,
    ]
  );

  const onSaveProductsFromPopup = ({
    productExcludeIds,
    checkedProducts,
    selectedCount,
    showAllProducts,
    isAllUnchecked,
  }) => {
    //const selectedCounter = showAllProducts
    //  ? listCount
    //  : isEdit
    //  ? listCount - productExcludeIds.length
    //  : selectedCount;

    setValue("selectedProductsCount", selectedCount, {
      shouldDirty: true,
    });
    setValue("showAllProducts", isAllUnchecked ? false : showAllProducts);
    if (isEdit || (!isEdit && !isAllUnchecked)) {
      setValue("excludeProducts", productExcludeIds, {
        shouldDirty: true,
      });
    }
    setValue("categoryProducts", checkedProducts, { shouldDirty: true });
    setValue("isAllUncheckedProducts", isAllUnchecked);
  };

  const onSubmit = (data) => {
    setCustomerLoading(true);
    const preparedData = preparingData({
      data,
      editRawData,
      isEdit,
    });

    if (customerId) {
      dispatch(
        updateCustomerAction({
          data: preparedData,
          id: customerId,
          documents: formField?.licenses,
          onSuccess: () => {
            // setUpdatedCustomer(profile);
            setCustomerLoading(false);
          },
          onError: (err) => {
            handleSubmitError(err);
            setCustomerLoading(false);
          },
          navigate,
          stateParams: { state: state?.params },
        })
      );
    } else {
      const dataPath = state ? { state } : { path: "/customers" };

      const setNavigate = (customerId) => {
        if (state?.type === "onboarding") return "/";
        return customerId && !state?.redirectToList
          ? `/customers/${customerId}`
          : "/customers";
      };

      dispatch(
        createCustomerAction({
          data: preparedData,
          navState: dataPath,
          onSuccess: (customer) => {
            setCustomerLoading(false);
            if (state?.type === "onboarding") {
              getCurrentUser({
                setCurrentUser: (user) => {
                  dispatch(setCurrentUser(user));
                },
              });
            }
            navigate(setNavigate(customer?.id), { state: state?.params });
          },
          onError: (err) => {
            setCustomerLoading(false);
            handleSubmitError(err);
          },
        })
      );
    }
    handleSendCheckedCustomers();
  };

  const checkForCategoriesChange = (categories) => {
    return isEqual(
      sortBy(preparedProductCategories, "id"),
      sortBy(categories, "id")
    );
  };

  useEffect(() => {
    if (formField?._count?.productCategories) getAllCategoriesForPopup(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formField?._count?.productCategories]);

  const productsFetchQuery = useMemo(() => {
    //const categoryIds = formField.preparedCategories?.length
    //  ? formField.preparedCategories?.map((item) => item.id)
    //  : [];

    const includeCategoryIds = formField?.includeCategoryIds || [];

    const ids = includeCategoryIds.length
      ? includeCategoryIds
      : formField.preparedCategories?.length
      ? formField.preparedCategories?.map((item) => item.id)
      : formField.productCategories?.length
      ? formField.productCategories?.map((item) => item.id)
      : [];

    //temp solution
    //const ids =
    //  categoryIds.length || includeCategoryIds.length
    //    ? [...new Set([...categoryIds, ...includeCategoryIds])]
    //    : formField.productCategories.length
    //    ? formField.productCategories.map((item) => item.id)
    //    : [];

    //temp solution
    //const exclude_categories_ids =
    //  formField?.showAllCategories || isEdit
    //    ? null
    //    : JSON.stringify(formField.excludeProductCategories);

    //temp solution
    //const categories_ids = formField?.showAllCategories
    //  ? null
    //  : isEdit && ids?.length
    //  ? JSON.stringify(ids)
    //  : !isEdit && ids?.length && !formField?.excludeProductCategories?.length
    //  ? JSON.stringify(ids)
    //  : null;

    const exclude_categories_ids =
      !formField?.isAllUncheckedCategories &&
      formField?.excludeProductCategories?.length
        ? JSON.stringify(formField?.excludeProductCategories)
        : null;

    const categories_ids = formField?.isAllUncheckedCategories
      ? JSON.stringify(ids)
      : null;

    return {
      status: '["active"]',
      exclude_categories_ids,
      categories_ids,
      exclude_negative_in_parents_inventory: undefined,
    };
  }, [
    formField?.excludeProductCategories,
    formField?.includeCategoryIds,
    formField?.isAllUncheckedCategories,
    formField?.preparedCategories,
    formField?.productCategories,
  ]);

  return {
    isAdmin,
    control,
    setValue,
    errors,
    formField,
    loadingCustomer,
    setCustomerLoading,
    handleSetCustomer,
    handleSetDisplayName,
    territoryList,
    paytermsList,
    handleFetchPayTerms,
    duplicateOpen,
    setDuplicateOpen,
    handleProceedWithDuplicate,
    popupDuplicates,
    setPopupDuplicates,
    isOpenPaymentTermModal,
    setOpenPaymentTermModal,
    isOpenNewTerritoryModal,
    setOpenNewTerritoryModal,
    shippingDuplicates,
    setShippingDuplicates,
    resetShippingAddressCoordinates,
    handleOpenDuplicates,
    googleAddress,
    setGoogleAddress,
    toggleDetails,
    setToggleDetails,
    mapOpen,
    setMapOpen,
    customIdModal,
    setCustomIdModal,
    customIdValidation,
    setCustomIdValidation,
    nameTooltipOpen,
    setNameTooltipOpen,
    nameWarnText,
    setNameWarnText,
    nameValidation,
    setNameValidation,
    billingValidation,
    setBillingValidation,
    shippingValidation,
    setShippingValidation,
    handleSubmit: handleSubmit(onSubmit),
    trigger,
    clearErrors,
    formChanged,
    validationNames,
    resetBillingAddressCoordinates,
    checkedCustomers,
    deleteDublicates,
    setCheckedCustomers,
    handleCheckCustomers,
    deleteLoading,
    handleCloseDuplicatePopup,
    haveQBConnect,
    setError,
    currentUser,
    isOpenCategoriesPopup,
    setIsOpenCategoriesPopup,
    preparedProductCategories,
    productsFetchQuery,
    getAllCategoriesForPopup,
    onSaveProductsFromPopup,
    setGlobalLoading,
    availableAssignCategories,
    checkForCategoriesChange,
  };
};

export const useCustomers = (customersSearchInputDebounced) => {
  const [customersState, setCustomersState] = useState({
    loading: true,
    list: [],
    count: 0,
  });
  const [params, setParams] = useState({
    limit: SCROLL_LIMIT_CUSTOMERS,
    status: `["${CUSTOMER_STATUS_FILTERS.active}"]`,
    page: 1,
  });

  const handleFetch = async (page) => {
    try {
      // setCustomersState((prev) => ({ ...prev, loading: true }));
      const res = await getCustomersService({
        ...params,
        page: page ? page : 1,
        search: customersSearchInputDebounced,
        limit: SCROLL_LIMIT,
      });
      const { rows, countActive } = res || {};

      setCustomersState((prev) => ({
        ...prev,
        list: page ? [...prev.list, ...rows] : [...rows],
        count: countActive,
      }));

      setParams((prev) => ({ ...prev, page: page ? page : 1 }));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    } finally {
      setCustomersState((prev) => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    handleFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersSearchInputDebounced]);

  return {
    customersState: { ...customersState, params },
    handleFetch,
  };
};

export const useParentCustomers = (
  parentsSearchInputDebounced,
  showCandidates = false,
  businessName = ""
) => {
  const [parentsState, setParentsState] = useState({
    loading: false,
    list: [],
    count: 0,
  });
  const [params, setParams] = useState({
    limit: SCROLL_LIMIT_CUSTOMERS,
    page: 1,
    include_uncategorized: false,
    with_candidates: showCandidates,
  });

  const handleFilterByBusinessName = (list) => {
    return list.filter(({ name }) => name !== businessName);
  };

  const handleFilterCountByBusinessName = (list, count) => {
    const listNames = list.map(({ name }) => name);
    // eslint-disable-next-line no-extra-boolean-cast
    return !!listNames.includes(businessName) ? count - 1 : count;
  };

  const handleFetch = async (page) => {
    setParentsState((prev) => ({ ...prev, loading: true }));
    try {
      const res = await getParentCustomersService({
        ...params,
        page: page ? page : 1,
        search: parentsSearchInputDebounced,
        limit: SCROLL_LIMIT,
      });
      const { rows, count } = res || {};

      setParentsState((prev) => {
        const listData = page ? [...prev.list, ...rows] : [...rows];

        const filtredListData = businessName
          ? handleFilterByBusinessName(listData)
          : listData;

        return {
          ...prev,
          list: filtredListData,
          count: handleFilterCountByBusinessName(listData, count),
        };
      });

      setParams((prev) => ({ ...prev, page: page ? page : 1 }));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong.");
    } finally {
      setParentsState((prev) => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    handleFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentsSearchInputDebounced]);

  return {
    parentsState: { ...parentsState, params },
    handleFetch,
  };
};

export const useTags = ({ control, setValue, tagsList = [] }) => {
  const formField = useWatch({ control });
  const { selectedTagsList } = formField || {};

  const handleCreate = (tagName) => {
    if (!tagName) return;

    const newTag = {
      id: Date.now(),
      tag: tagName || formField?.tagInput,
      newTag: true,
    };
    setValue("selectedTagsList", [...selectedTagsList, newTag], {
      shouldDirty: true,
    });
  };

  const checkIsUnique = (name, arr, list) => {
    const res1 = !arr.some((t) => {
      return t?.tag?.toLowerCase() === name.toLowerCase();
    });

    const res2 = list.find((t) => {
      return t?.tag?.toLowerCase() === name.toLowerCase();
    });

    if (res1 && !res2) return { isUnique: true, tag: {} };

    if (res1 && res2) return { isUnique: false, tag: res2 };

    return { isUnique: false, tag: {} };
  };

  const handlePressEnter = (e) => {
    if (selectedTagsList?.length >= LIMIT_ADDED_TAGS) return;
    const val = e.target.value.trim();

    const { isUnique, tag } = checkIsUnique(val, selectedTagsList, tagsList);

    if (e.keyCode === 13 && val !== "") {
      if (isUnique) {
        handleCreate(val);
      }

      if (!isUnique && tag?.id) {
        handleAddTag(tag);
      }
      setValue("tagInput", "");
    }
  };

  const handleBlur = (e) => {
    if (selectedTagsList?.length >= LIMIT_ADDED_TAGS) return;
    const val = e.target.value.trim();

    const { isUnique, tag } = checkIsUnique(val, selectedTagsList, tagsList);

    if (val !== "") {
      if (isUnique) {
        handleCreate(val);
      }

      if (!isUnique && tag?.id) {
        handleAddTag(tag);
      }
      setValue("tagInput", "");
    }
  };

  const handleAddTag = (tag) => {
    if (selectedTagsList?.length >= LIMIT_ADDED_TAGS) return;
    setValue("selectedTagsList", [...selectedTagsList, tag], {
      shouldDirty: true,
    });
  };

  const handleDeleteTag = (tagId) => {
    const tempList = [...selectedTagsList];
    const index = selectedTagsList.findIndex((t) => t?.id === tagId);
    if (index > -1) {
      tempList.splice(index, 1);
      setValue("selectedTagsList", [...tempList], { shouldDirty: true });
    }
  };

  return {
    tagsList,
    selectedTagsList,
    handlePressEnter,
    handleBlur,
    handleAddTag,
    handleDeleteTag,
    handleCreate,
  };
};

export const useEditRep = () => {
  const initRepData = {
    open: false,
    item: null,
  };

  const [repData, setRepData] = useState(initRepData);

  const openRepEditDialog = (item) => {
    setRepData((prev) => ({ ...prev, open: true, item }));
  };

  const closeRepEditDialog = () => {
    setRepData(initRepData);
  };

  return { ...repData, openRepEditDialog, closeRepEditDialog };
};

export const useProductPrivacy = ({
  productsList,
  setValue,
  key = "products",
  customerId,
  categoryProducts = [],
  excludeCategoryIds = [],
  includeCategoryIds = [],
  productsCount = 0,
}) => {
  const { setGlobalLoading } = useContext(GlobalPageContext);

  const [openAllProducts, setOpenAllProducts] = useState(false);
  const [tempCheckedProducts, setTempCheckedProducts] = useState([]);
  const [excludeIds, setExcludeIds] = useState([]);
  const [isAllUnchecked, setIsAllUnchecked] = useState(false);
  const [availableAssignedProducts, setAvailableAssignedProducts] = useState(
    []
  );
  const getAllProductsForPopup = useCallback(
    async (skipLoading = false, params = {}) => {
      if (!customerId) return;

      !skipLoading && setGlobalLoading(true);

      const preparedParams = {
        exclude_categories_ids: excludeCategoryIds?.length
          ? JSON.stringify(excludeCategoryIds)
          : null,
        categories_ids: includeCategoryIds?.length
          ? JSON.stringify(includeCategoryIds)
          : null,
        ...params,
      };

      try {
        const res = await getProductsByCustomerIdService(
          customerId,
          preparedParams
        );
        const rows = res || [];

        setValue("categoryProducts", rows);
        setValue("selectedProductsCount", rows?.length || 0);
        setTempCheckedProducts(rows);

        setAvailableAssignedProducts(rows);
        if (!skipLoading) setOpenAllProducts(true);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong.");
      } finally {
        setGlobalLoading(false);
      }
    },
    [
      customerId,
      excludeCategoryIds,
      setGlobalLoading,
      setValue,
      includeCategoryIds,
    ]
  );

  const onOpenAllProducts = () => {
    if (customerId && productsCount !== 0) {
      if (categoryProducts?.length) return setOpenAllProducts(true);
      return getAllProductsForPopup();
    }

    return setOpenAllProducts(true);
  };
  const onCloseAllProducts = () => setOpenAllProducts(false);

  const onTempProductSelect = useCallback(
    (product) => {
      if (product.isMultiple) {
        const filteredChildren = product.childProducts.filter(
          (childProduct) =>
            !productsList?.some((prod) => prod.id === childProduct.id)
        );
        if (containsEvery(filteredChildren, tempCheckedProducts)) {
          const newChecked = [...tempCheckedProducts];
          product.childProducts.forEach((childProduct) => {
            const index = newChecked.findIndex(
              (checkedProduct) => checkedProduct.id === childProduct.id
            );
            newChecked.splice(index, 1);
          });

          const updatedExcludeIds = [
            ...excludeIds,
            ...product.childProducts.map((child) => child.id),
          ];
          setExcludeIds([...new Set(updatedExcludeIds)]);
          return setTempCheckedProducts([...newChecked]);
        }

        const addProducts = filteredChildren.map((childProduct) => ({
          ...childProduct,
          quantity: 1,
          parentProduct: product,
        }));
        const uniqueProducts = [
          ...new Map(
            [...tempCheckedProducts, ...addProducts].map((item) => [
              item["id"],
              item,
            ])
          ).values(),
        ];

        // Видаляємо ID доданих продуктів із excludeIds
        const updatedExcludeIds = excludeIds.filter(
          (id) => !addProducts.some((childProduct) => childProduct.id === id)
        );
        setExcludeIds(updatedExcludeIds);
        return setTempCheckedProducts(uniqueProducts);
      }

      const index = tempCheckedProducts.findIndex(
        (item) => item.id === product.id
      );

      if (index > -1) {
        // Якщо продукт видаляється, додаємо його ID до excludeIds
        setExcludeIds([...new Set([...excludeIds, product.id])]);

        const newProducts = tempCheckedProducts.filter(
          (item) => item.id !== product.id
        );
        return setTempCheckedProducts(newProducts);
      }

      // Якщо продукт додається, видаляємо його ID із excludeIds
      setExcludeIds(excludeIds.filter((id) => id !== product.id));
      setTempCheckedProducts([...tempCheckedProducts, product]);
    },
    [tempCheckedProducts, productsList, excludeIds]
  );

  const onSaveProductsFromPopup = (callback = () => {}) => {
    const productsIds = tempCheckedProducts.map(
      (product) => product.product?.id || product.id
    );

    if (!callback) return setValue(key, productsIds, { shouldDirty: true });
    callback({ productsIds, productExcludeIds: excludeIds });
  };

  const checkForProductChange = () => {
    if (!productsList?.length) return true;
    if (!tempCheckedProducts?.length && !productsList?.length) return false;
    const tempProductsIds = tempCheckedProducts?.map(
      (product) => product?.product?.id || product?.id
    );
    const productsIds = productsList?.filter((product) =>
      tempProductsIds?.includes(product.id || product.product?.id)
    );
    return !!productsIds?.length;
  };

  useEffect(() => {
    if (categoryProducts?.length !== tempCheckedProducts.length) {
      setTempCheckedProducts(categoryProducts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryProducts?.length]);

  return {
    openAllProducts,
    onOpenAllProducts,
    onCloseAllProducts,
    tempCheckedProducts,
    setTempCheckedProducts,
    onTempProductSelect,
    onSaveProductsFromPopup,
    checkForProductChange,
    excludeIds,
    setExcludeIds,
    getAllProductsForPopup,
    isAllUnchecked,
    setIsAllUnchecked,
    availableAssignedProducts,
  };
};
