import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import {
  FORM_TYPES_SELECT,
  getCurrentItem,
  handleGetIcons,
  MAX_ALLOWED_QUESTIONS,
} from "Pages/FormViewPage/FormViewPage.constants";
import { useContext, useEffect, useState } from "react";
import { cl } from "../QuestionItem/QuestionItem.styles";
import { FormViewContext } from "Pages/FormViewPage/FormViewPage";
import { StyledTooltip } from "components";
import AddIcon from "@mui/icons-material/Add";
import { useAdmin } from "helpers/helpers.js";

export const AddNewQuestionBlock = () => {
  const { appendQuestion, formField, errors, clearErrors } =
    useContext(FormViewContext);

  const errorQuestions =
    errors?.questions?.message === "Please add questions to save the form";

  const [openTooltip, setOpenTooltip] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isClickBlocked, setIsClickBlocked] = useState(false);

  const isAdmin = useAdmin();

  const FORM_ICONS = handleGetIcons();

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    if (isAdmin) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (errorQuestions && !open) setOpenTooltip(true);
    if (errorQuestions && open) setOpenTooltip(false);
  }, [errorQuestions, open]);

  return (
    <>
      <StyledTooltip
        arrow
        placement="left"
        isError={errorQuestions}
        title={
          errorQuestions
            ? errors?.questions?.message
            : `Max ${MAX_ALLOWED_QUESTIONS} questions`
        }
        open={openTooltip}
        PopperProps={{
          modifiers: [
            {
              name: "offset",
              options: { offset: [0, -5] },
            },
          ],
        }}
      >
        <Box
          sx={{
            cursor:
              formField?.questions?.length >= MAX_ALLOWED_QUESTIONS || isAdmin
                ? null
                : "pointer",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            width: "fit-content",
          }}
          onClick={(e) => {
            if (formField?.questions?.length >= MAX_ALLOWED_QUESTIONS) return;
            setIsClickBlocked(false);
            handleClick(e);
          }}
          onMouseEnter={() => {
            if (formField?.questions?.length >= MAX_ALLOWED_QUESTIONS)
              setOpenTooltip(true);
          }}
          onMouseLeave={() => {
            if (!errorQuestions) setOpenTooltip(false);
          }}
        >
          <IconButton
            sx={{
              p: 0,
              border: "1px solid #707070",
              width: "20px",
              height: "20px",
            }}
            disabled={
              formField?.questions?.length >= MAX_ALLOWED_QUESTIONS || isAdmin
            }
            id="question-button"
            aria-controls={open ? "question-button-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <AddIcon
              sx={{ fontSize: 20, color: isAdmin ? "#BCBCBC" : "#47A06D" }}
            />
          </IconButton>

          <Typography color="#5F6267" fontSize={13}>
            Add new question
          </Typography>
        </Box>
      </StyledTooltip>

      <Menu
        sx={{ zIndex: ({ zIndex }) => zIndex.tooltip + 1 }}
        id="question-button"
        aria-labelledby="question-button-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: cl.selectPaperStyles }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {FORM_TYPES_SELECT.map(({ label, value, disabled }) => (
          <MenuItem
            key={value}
            value={value}
            disabled={!!disabled || isAdmin}
            onClick={() => {
              if (
                formField?.questions?.length >= MAX_ALLOWED_QUESTIONS ||
                isClickBlocked
              )
                return;
              setIsClickBlocked(true);

              const item = getCurrentItem({
                type: value,
                orderIndex: formField?.questions?.length || 0,
                autofill: formField?.autofill,
                required: formField?.allRequired,
                allowGallery: false,
              });
              appendQuestion(item);
              handleClose();
              if (errorQuestions) clearErrors("questions");
            }}
            sx={cl.selectMenuItem}
          >
            {FORM_ICONS[value]}
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
