import { Box, CircularProgress, InputAdornment } from "@mui/material";
import { StyledButton, StyledTextField } from "components";
import React from "react";
import useStyles from "Pages/CustomersPage/pages/CustomerProfilePage/components/TaskDrawer/styles";
import { bool, string, array, func, object } from "prop-types";
import { SearchIcon } from "components/Icons";
import CustomersDropDown from "Pages/DiscountsPage/components/NewDiscountPage/components/CustomersDropDown/CustomersDropDown";
import { useAdmin } from "helpers/helpers.js";

export const CustomerSearch = ({
  customersList,
  checkedTaskCustomers,
  customersLoading,
  customersSearchInput,
  setCustomersList,
  handleCheckCustomer,
  setCheckedTaskCustomers,
  setCustomersSearchInput,
  errors,
  customers,
  setCheckedCustomers,
  adminIsAllowed,
  setAllCustomersOpen,
}) => {
  const classes = useStyles();

  const isAdmin = useAdmin(adminIsAllowed);

  return (
    <Box position="relative">
      <Box display="flex">
        <StyledTextField
          adminIsAllowed={adminIsAllowed}
          fullWidth
          value={customersSearchInput}
          onChange={(e) => setCustomersSearchInput(e.target.value)}
          error={!!errors?.selectedCustomersCount}
          size="small"
          sx={{
            "& label": {
              color: "#B1B1B1",
              opacity: 1,
            },
          }}
          InputProps={{
            className: classes.searchInput,
            style: { fontSize: 16 },
            endAdornment: (
              <>
                <InputAdornment
                  position="start"
                  sx={{ position: "absolute", left: 9 }}
                >
                  <SearchIcon />
                </InputAdornment>
                <Box position="relative">
                  {customersLoading && (
                    <InputAdornment
                      position="end"
                      sx={{
                        position: "absolute",
                        right: 0,
                      }}
                    >
                      <CircularProgress size="20px" />
                    </InputAdornment>
                  )}
                </Box>
              </>
            ),
          }}
          InputLabelProps={{ className: classes.searchLabel }}
          label="Search customers"
        />
        {setAllCustomersOpen && (
          <Box ml={1}>
            <StyledButton
              label="View All"
              variant="outlined"
              color="cancel"
              fontSize="15px"
              sx={{
                py: "1px",
                px: 0,
                height: "38px",
                width: "70px",
                borderColor: "#D5D9D9",
              }}
              onClick={() => setAllCustomersOpen(true)}
              disabled={isAdmin}
            />
          </Box>
        )}
      </Box>

      {!!customersList.length && (
        <CustomersDropDown
          customersList={customersList}
          formCustomers={customers}
          handleCheckCustomer={handleCheckCustomer}
          checkedCustomers={checkedTaskCustomers}
          onCancel={() => {
            setCustomersSearchInput("");
            setCheckedTaskCustomers([]);
            setCustomersList([]);
          }}
          onAdd={() => {
            setCustomersSearchInput("");
            setCustomersList([]);
            setCheckedCustomers(checkedTaskCustomers);
          }}
        />
      )}
    </Box>
  );
};

CustomerSearch.propTypes = {
  customersList: array,
  checkedTaskCustomers: array,
  customersLoading: bool,
  customersSearchInput: string,
  setCustomersList: func,
  handleCheckCustomer: func,
  setCheckedTaskCustomers: func,
  setCustomersSearchInput: func,
  errors: object,
  customers: array,
  setCheckedCustomers: func,
  adminIsAllowed: bool,
  setAllCustomersOpen: func,
};

CustomerSearch.defaultProps = {
  customersList: [],
  checkedTaskCustomers: [],
  customersLoading: false,
  customersSearchInput: "",
  setCustomersList: () => {},
  handleCheckCustomer: () => {},
  setCheckedTaskCustomers: () => {},
  setCustomersSearchInput: () => {},
  selectedCustomers: [],
  customers: [],
  adminIsAllowed: false,
};
